import React, { useState } from 'react';
import { MdAttachment, MdOutlinePictureAsPdf } from 'react-icons/md';
import { Button } from '../../../elements/button';


const PdfThumbnail = (props) => {
    

    const removeThumbnail = () => {
        props.removeDoc()
    };
  
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>

            {props.Thumbnail && (
                <> <Button
                    isSmall={true}
                    name="X"
                    outline="false"
                    color="red"
                    clicked={() => removeThumbnail()}
                /> <span>Document</span>
                    <MdOutlinePictureAsPdf color="#183F71" size={150} />

                </>
            )}
        </div>
    );
};

export default PdfThumbnail;
