import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import SideBarItem from "./SideBarItem";
import { settingsClicked } from "../../../../../store/actions";

const SideBar = (props) => {
  const settingsItems = props?.items;
  const f = settingsClicked;
  const dispatch = useDispatch();

  const itemClickedHandler = (actionName) => {
    dispatch(f(actionName));
    props.closed();
  };

  return (
    <Fragment>
      {settingsItems.map((item, index) => (
        <SideBarItem
          key={index}
          title={item.title}
          subTitle={item.subTitle}
          complete={item.completed}
          selected={item.selected}
          clicked={() => itemClickedHandler(item.title)}
        />
      ))}
    </Fragment>
  );
};

export default SideBar;
