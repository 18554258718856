import { useHistory } from "react-router-dom";
import { CardBody, SectionTitle } from "../../../elements/text";
import { FcGoogle } from "react-icons/fc";
import { BsTwitter, BsFacebook, BsEye, BsEyeSlash } from "react-icons/bs";
import Input from "../../../elements/input";
import { Button, OtherLinkButton } from "../../../elements/button";
import { useEffect, useState } from "react";
import image from "../../../../images/signup.png";
import * as actions from "../../../../store/actions/";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../../../constants/constants";
import { Link } from "react-router-dom";

import dotenv from "dotenv";
import { useTranslation } from "react-i18next";
import { Message } from "../../../common/messages";
import Spinner from "../../../elements/spinner";
import TwitterLogin from 'react-twitter-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin, GoogleLogin, GoogleOAuthProvider, googleLogout, hasGrantedAllScopesGoogle, hasGrantedAnyScopeGoogle, useGoogleOneTapLogin } from '@react-oauth/google';
import { useRef } from 'react';
import jwtDecode from 'jwt-decode'
const { OAuth2Client } = require('google-auth-library')


dotenv.config();

const Herosection = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [comfirmPassword, setComfirmPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [dataerror, setdataerror] = useState("");
  const [loginWithGmailOrFacebook, setLoginWithGmailOrFacebook] = useState(false);


  const isAuthenticated = useSelector((state) => state.auth.token != null);
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const twitterLoginRef = useRef(null);
  googleLogout();
  const MyGoogleButton = ({ onClick }) => {
    return (
      <span onClick={onClick}><FcGoogle size="30" /></span>
    );
  }
  /*const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse)
      const hasAccess = hasGrantedAnyScopeGoogle(
        tokenResponse,
        'google-scope-1',
        'google-scope-2',
      );
      console.log(hasAccess)
    },
  // });*/
  // const login = useGoogleLogin({
  //   onSuccess: async (credentialResponse) => {
  //     if (credentialResponse.credential != null) {
  //       const USER_CREDENTIAL = await jwtDecode(credentialResponse.credential);
  //       console.log(USER_CREDENTIAL);
  //      }
  //   },
  //   onError: () => {
  //     console.log('Login Failed');
  //   },
  // });
  const responseGoogle = async (response) => {
    //const { email, givenName, familyName, imageUrl } = response?.profileObj;
    if (response.credential != null) {
      const USER_CREDENTIAL = await jwtDecode(response.credential);
      //console.log("SIgnup", USER_CREDENTIAL);
      const object = {
        names: USER_CREDENTIAL.given_name,
        username: USER_CREDENTIAL.given_name,
        email: USER_CREDENTIAL.email,
        dateofbirth: "DEFAULT",
        gender: "DEFAULT",
        account_type: "AccType",
        otherschool: "DEFAULT",
        school_linked_id: "DEFAULT",
        section: "DEFAULT",
        combination: "DEFAULT",
        academic_level: "DEFAULT",
        account_status: "DEFAULT",
        user_type: "AccType",
        guardian_name: "DEFAULT",
        guardian_tel: "phone",
        telephone: "EMPTY",
        studentguardian_id: "DEFAULT",
        activation_code: "DEFAULT",
        province: "DEFAULT",
        district: "DEFAULT",
        sector: "DEFAULT",
        guardian_tel_guardian: "DEFAULT",
        country: "DEFAULT",
        refID: "DEFAULT",
        lastname: USER_CREDENTIAL.family_name,
        login_method: "social_gmail",
        password,
        facebook_or_google: true,
        avatarimage: USER_CREDENTIAL.picture
      };
      if (
        USER_CREDENTIAL.given_name !== "" &&
        USER_CREDENTIAL.given_name !== "" &&
        USER_CREDENTIAL.email !== ""
      ) {
        setLoginWithGmailOrFacebook(true);
        dispatch(actions.createAccount(object));
      } else {
        setdataerror("Some data are missing");
      }
    }

  }

  const dispatch = useDispatch();



  const handleFacebookLogin = (response) => {
    //console.log(response,"###$$$$---90");
    if (response?.name) {

    } else {
      return;
    }
    const object = {
      names: response.name,
      username: response.email,
      email: response.email,
      dateofbirth: "DEFAULT",
      gender: "DEFAULT",
      account_type: "AccType",
      otherschool: "DEFAULT",
      school_linked_id: "DEFAULT",
      section: "DEFAULT",
      combination: "DEFAULT",
      academic_level: "DEFAULT",
      account_status: "DEFAULT",
      user_type: "AccType",
      guardian_name: "DEFAULT",
      guardian_tel: "phone",
      telephone: "EMPTY",
      studentguardian_id: "DEFAULT",
      activation_code: "DEFAULT",
      province: "DEFAULT",
      district: "DEFAULT",
      sector: "DEFAULT",
      guardian_tel_guardian: "DEFAULT",
      country: "DEFAULT",
      refID: "DEFAULT",
      lastname: "",
      login_method: "social_facebook",
      password,
      facebook_or_google: true,
      avatarimage: null
    };
    if (
      response.name !== "" &&
      response.name !== "" &&
      response.email !== ""
    ) {
      setLoginWithGmailOrFacebook(true);
      dispatch(actions.createAccount(object));
    } else {
      setdataerror("Some data are missing");
    }
  };
  const getDecodedOAuthJwtGoogle = async token => {

    const CLIENT_ID_GOOGLE = '470934482733-ifgjmq4jhh7smrk9erfos8crirq9juh8.apps.googleusercontent.com'

    try {
      const client = new OAuth2Client(CLIENT_ID_GOOGLE)

      const ticket = await client.verifyIdToken({
        idToken: token,
        audience: CLIENT_ID_GOOGLE,
      })

      return ticket
    } catch (error) {
      return { status: 500, data: error }
    }
  }

  const renderFacebookButton = ({ onClick, disabled }) => (
    <span onClick={onClick}><BsFacebook size="30" color="#3b5998" /></span>
  );
  const enroll = () => {
    const object = {
      names: lastName + " " + firstName,
      username: email,
      email: email,
      dateofbirth: "DEFAULT",
      gender: "DEFAULT",
      account_type: "AccType",
      otherschool: "DEFAULT",
      school_linked_id: "DEFAULT",
      section: "DEFAULT",
      combination: "DEFAULT",
      academic_level: "DEFAULT",
      account_status: "DEFAULT",
      user_type: "AccType",
      guardian_name: "DEFAULT",
      guardian_tel: "phone",
      telephone: phone,
      studentguardian_id: "DEFAULT",
      activation_code: "DEFAULT",
      province: "DEFAULT",
      district: "DEFAULT",
      sector: "DEFAULT",
      guardian_tel_guardian: "DEFAULT",
      country: "DEFAULT",
      refID: "DEFAULT",
      lastname: 0,
      login_method: constants.emaillogintype,
      password,
    };
    if (
      lastName !== "" &&
      firstName !== "" &&
      object.email !== "" &&
      object.password !== null
    ) {
      dispatch(actions.createAccount(object));
    } else {
      setdataerror("Some data are missing");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (loginWithGmailOrFacebook) {
        history.push("/payment/payment");
      } else {
        history.push("/verify");
      }

    }
    //---
    window.scrollTo(0, 0);
  });

  return (
    <div className="bg-gray-200 pb-5">
      <div className="flex flex-col md:flex-row md:space-x-2 py-5 justify-center items-center">
        <SectionTitle
          name={`${t("Sign Up to")}`}
          color="blue"
          alignment="center"
        />
        <SectionTitle name="O'Genius Panda" color="blue" alignment="center" />
      </div>
      <>
        {loading ? (
          <Spinner size="32" color="blue" />
        ) : (
          <div className="lg:w-80% lg:flex flex-row justify-center m-auto lg:max-h-110">
            <div className="hidden lg:block w-1/2 max-h-110 h-auto lg:rounded-l-lg">
              <img
                className="w-full h-full object-cover object-center lg:rounded-l-lg"
                src={image}
                alt=""
              />
            </div>
            <div className="bg-white rounded-xl min-h-signup-card w-90% lg:w-1/2 lg:rounded-l-none lg:rounded-r-lg m-auto lg:m-0 py-5">
              <CardBody
                name={t("Quick Sign up with")}
                color="black"
                alignment="center"
              />
              <div className="flex justify-center space-x-4 pt-1 pb-3">
                {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}
                {<GoogleLogin
                  clientId="470934482733-ifgjmq4jhh7smrk9erfos8crirq9juh8.apps.googleusercontent.com"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  type="icon"
                  ux_mode="popup"
                  shape="circle"
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={true}
                  scope="profile"
                  render={renderProps => (
                    <MyGoogleButton onClick={renderProps.onClick} />
                  )}
                />}
                {/* { <span onClick={() => login()}><FcGoogle size="30" /></span> } */}
                {/* <GoogleLogin
                  onSuccess={async (credentialResponse) =>  {
                    //const realUserData = await getDecodedOAuthJwtGoogle(credentialResponse) 
                    //console.log(realUserData);
                    if (credentialResponse.credential != null) {
                      const USER_CREDENTIAL = await jwtDecode(credentialResponse.credential);
                      console.log(USER_CREDENTIAL);
                     }
                    
                    
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  
                  render={(renderProps) => (<button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}><span><BsFacebook size="30" color="#3b5998" /></span></button>)}
                /> */}
                {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/twitter`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}



                {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/facebook`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}
                <FacebookLogin
                  appId="1764509220385819"
                  autoLoad={false}
                  fields="name,email"
                  callback={handleFacebookLogin}
                  render={renderFacebookButton}
                />
              </div>
              <CardBody
                name={t("Sign up with your Email or Phone")}
                color="black"
                alignment="center"
              />
              <div className="p-2 lg:px-10">
                <div className="flex space-x-2">
                  <Input
                    elementType="input"
                    elementConfig={{
                      type: "text",
                      placeholder: t("First Name"),
                    }}
                    value={firstName}
                    changed={setFirstName}
                    validation={{ required: true }}
                    shouldValidate
                    error={t("First Name is required")}
                  />
                  <Input
                    elementType="input"
                    elementConfig={{
                      type: "text",
                      placeholder: t("Last Name"),
                    }}
                    value={lastName}
                    changed={setLastName}
                    validation={{ required: true }}
                    shouldValidate
                    error={t("Last Name is required")}
                  />
                </div>
                <Input
                  elementType="input"
                  elementConfig={{
                    type: "email",
                    placeholder: "Email (example@email.com)",
                  }}
                  value={email}
                  changed={setEmail}
                  validation={{ isEmail: true }}
                  shouldValidate
                  error={t("Valid email is required")}
                />
                {/* <Input
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: constants.accounttypes,
              }}
              value={accounttype}
              changed={setAccounttype}
              validation={{ required: true }}
              shouldValidate
              error="Account Type is required"
            /> */}
                <Input
                  elementType="phone"
                  value={phone}
                  changed={setPhone}
                  validation={{ isPhone: true }}
                  shouldValidate
                  error={t("Valid phone number is required")}
                />
                <Input
                  elementType="input"
                  withIcon
                  elementConfig={{
                    type: isShowPassword ? "text" : "password",
                    placeholder: t("Create password"),
                  }}
                  value={password}
                  changed={setPassword}
                  validation={{ required: true, minLength: 5 }}
                  shouldValidate
                  error={t("Password must be at least 5 characters")}
                >
                  {!isShowPassword ? (
                    <BsEye
                      className="cursor-pointer"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    />
                  ) : (
                    <BsEyeSlash
                      className="cursor-pointer"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    />
                  )}
                </Input>
                <Input
                  elementType="input"
                  withIcon
                  elementConfig={{
                    type: isShowConfirmPassword ? "text" : "password",
                    placeholder: t("Confirm password"),
                  }}
                  value={comfirmPassword}
                  changed={setComfirmPassword}
                  validation={{
                    required: true,
                    equal: true,
                    equivalence: password,
                  }}
                  shouldValidate
                  error={t("Passwords do not match")}
                >
                  {!isShowConfirmPassword ? (
                    <BsEye
                      className="cursor-pointer"
                      onClick={() =>
                        setIsShowConfirmPassword(!isShowConfirmPassword)
                      }
                    />
                  ) : (
                    <BsEyeSlash
                      className="cursor-pointer"
                      onClick={() =>
                        setIsShowConfirmPassword(!isShowConfirmPassword)
                      }
                    />
                  )}
                </Input>
                <Message
                  type={"error"}
                  viewable={true}
                  message={error || dataerror}
                />
                <div className="flex flex-col pt-3 pb-2">
                  <CardBody
                    name={t("By signing up to O'Genius Panda,")}
                    color="black"
                    alignment="center"
                  />
                  <div className="flex flex-row space-x-2 flex-wrap items-center justify-center">
                    <CardBody
                      name={t("I accept")}
                      color="black"
                      alignment="center"
                    />
                    <OtherLinkButton
                      color="blue"
                      to="/termsofservice"
                      name={t("Terms of service")}
                    />
                    <CardBody
                      name={t("and")}
                      color="black"
                      alignment="center"
                    />
                    <OtherLinkButton
                      color="blue"
                      to="/privacypolicy"
                      name={t("Privacy Policy")}
                    />
                  </div>
                </div>
                <div className="w-full flex justify-center">
                  <Button
                    name={t("Sign Up")}
                    outline="true"
                    color="blue"
                    clicked={enroll}
                    disabled={loading}
                  />
                </div>
                <div className="flex justify-center space-x-2">
                  <CardBody
                    name={t("Already have an account?")}
                    color="black"
                    alignment="center"
                  />
                  <OtherLinkButton color="blue" to="/login" name={t("Login")} />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div >
  );
};

export default Herosection;
