import parse from "react-html-parser";
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from "../../../../../../../elements/text";
import { Button, GoBackButton } from "../../../../../../../elements/button";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MatchingTypeAutoQuestion from "./autotest/MatchingTypeAutoQuestion";
import FillinTypeAutoQuestion from "./autotest/FillinTypeAutoQuestion";
import SinglechoiceAnswerOptionGroupAutoTest from "./autotest/singlechoiceSelector";
import MultichoiceAnswerOptionGroupAutoTest from "./autotest/multichoiceSelector";

const Uiautotestanswersheet = (props) => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const studentDashboardTestsSubjectsUnits = useSelector(
    (state) => state.studentDashboardTestsSubjectsUnits
  );
  const history = useHistory();
  const autoTestProgressList = useSelector(
    (state) => state.autoTestProgressList
  );
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  useEffect(() => {}, []);
  //--------------
  //console.log("auto---", props.auto);

  return (
    <div className="md:m-10 h-90 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 testcontent">
      <CardTitle
        name="Answer sheet > Automatically generated test"
        color="#FFFAFA"
      />
      <CardBody name={`Question ${currentQuestionNumber + 1}.`} />
      {props.auto[0]?.questionsmap[currentQuestionNumber].questiontype ===
      "matching" ? (
        <div>
          <MatchingTypeAutoQuestion
            questionId={
              props.auto[0]?.questionsmap[currentQuestionNumber].questiondata
                .panda_auto_test_algo_v2_id
            }
            currentQuestionNumber={currentQuestionNumber}
            key={Math.random() * 1000}
            questionMetadata={
              props.auto[0]?.questionsmap[currentQuestionNumber].questiondata
                .panda_auto_test_algo_v2_question_data
            }
            user_data={
              props.auto[0]?.questionsmap[currentQuestionNumber].questiondata
                .matching_type_user_data
            }
          />
        </div>
      ) : props.auto[0]?.questionsmap[currentQuestionNumber]?.questiontype ===
        "fillin" ? (
        <div>
          <FillinTypeAutoQuestion
            questionId={
              props.auto[0]?.questionsmap[currentQuestionNumber].questiondata
                .panda_auto_test_algo_v2_id
            }
            key={Math.random() * 1000}
            currentQuestionNumber={currentQuestionNumber}
            questionMetadata={
              props.auto[0]?.questionsmap[currentQuestionNumber].questiondata
                .panda_auto_test_algo_v2_question_data
            }
            user_data={
              props.auto[0]?.questionsmap[currentQuestionNumber].questiondata
                .fillin_type_user_data
            }
          />
        </div>
      ) : props.auto[0]?.questionsmap[currentQuestionNumber]?.questiontype ===
        "multiple_choice" ? (
        <div>
          <CardBody
            name={
              props.auto[0]?.questionsmap[currentQuestionNumber]
                ?.questiontype === "multiple_choice"
                ? "Pick one or many answers for this question"
                : ""
            }
          />
          {props.auto[0]?.questionsmap &&
            props.auto[0]?.questionsmap.length > 0 &&
            props.auto[0]?.questionsmap[currentQuestionNumber].questiontype ===
              "multiple_choice" && (
              <div>
                {props.auto[0]?.questionsmap[currentQuestionNumber]
                  ?.questiondata
                  ?.panda_questions_auto_auto_answering_technique === 0 ? (
                  <div>
                    {parse(
                      props.auto[0]?.questionsmap[currentQuestionNumber]
                        ?.questiondata?.panda_questions_auto_question_data
                    )}
                    <CardTitle name="Explanation" color="#FFFAFA" />
                    <div className="border-1 border-dashed">
                      {parse(
                        props.auto[0]?.questionsmap[currentQuestionNumber]
                          .questiondata
                          .panda_teacher_test_2020_03_answer_explanation === ""
                          ? test[currentQuestionNumber].question
                              .panda_teacher_test_2020_03_answer_explanation
                          : "<span className='border-1 border-dashed'>No explanation available!!</span>"
                      )}
                    </div>
                    <div>
                      <SinglechoiceAnswerOptionGroupAutoTest
                        key={Math.random() * 1000}
                        id={
                          props.auto[0]?.questionsmap[currentQuestionNumber]
                            .questiondata.panda_questions_auto_id
                        }
                        questionnber={currentQuestionNumber}
                        answers={
                          props.auto[0]?.questionsmap[currentQuestionNumber]
                            .questiondata.answerData
                        }
                        user_data={
                          props.auto[0]?.questionsmap[currentQuestionNumber]
                            .questiondata.useranswer
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {parse(
                      props.auto[0]?.questionsmap[currentQuestionNumber]
                        ?.questiondata?.panda_questions_auto_question_data
                    )}
                    <div>
                      <MultichoiceAnswerOptionGroupAutoTest
                        key={Math.random() * 1000}
                        id={
                          props.auto[0]?.questionsmap[currentQuestionNumber]
                            .questiondata.panda_questions_auto_id
                        }
                        questionnber={currentQuestionNumber}
                        answers={
                          props.auto[0]?.questionsmap[currentQuestionNumber]
                            .questiondata.answerData
                        }
                        user_data={
                          props.auto[0]?.questionsmap[currentQuestionNumber]
                            .questiondata.useranswer
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
      ) : (
        ""
      )}
      <div className="float-right">
        {currentQuestionNumber > 0 && (
          <Button
            name="Prev"
            outline="true"
            color="red"
            clicked={() => {
              if (currentQuestionNumber <= props.auto[0]?.questionsmap.length) {
                setCurrentQuestionNumber((number) => number - 1);
              }
            }}
          />
        )}
        {currentQuestionNumber < props.auto[0]?.questionsmap.length - 1 && (
          <Button
            name="Next"
            outline="true"
            color="red"
            clicked={() => {
              if (currentQuestionNumber <= props.auto[0]?.questionsmap.length) {
                setCurrentQuestionNumber((number) => number + 1);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Uiautotestanswersheet;
