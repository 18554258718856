import { Button } from '../../elements/button';
import Input from '../../elements/input';
import { useState } from 'react';

const Creditcard = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [names, setNames] = useState('');
  const [expDate, setExpDate] = useState(new Date());
  const [cvc, setCvc] = useState('');
  return (
    <div className="p-2">
      <div className="flex space-x-2">
        <Input
          elementType="input"
          elementConfig={{
            type: 'text',
            placeholder: 'Card Number',
          }}
          value={cardNumber}
          changed={setCardNumber}
        />
        <Input
          elementType="input"
          elementConfig={{
            type: 'text',
            placeholder: 'Names',
          }}
          value={names}
          changed={setNames}
        />
      </div>
      <div className="flex space-x-2">
        <Input
          elementType="input"
          elementConfig={{
            type: 'date',
            placeholder: 'dd-mm-yyyy',
            min: new Date(),
          }}
          value={expDate}
          changed={setExpDate}
        />
        <Input
          elementType="input"
          elementConfig={{
            type: 'text',
            placeholder: 'CVC',
          }}
          value={cvc}
          changed={setCvc}
        />
      </div>
      <Button name="Buy" outline="true" color="blue" additional="my-2" />
    </div>
  );
};

export default Creditcard;
