import { CardTitle, CardSubText } from "../../text";
import { useHistory } from "react-router-dom";
import { Button } from "../../button";

const Default = (props) => {
  const history = useHistory();
  const instant_right_now = [];
  return instant_right_now.length > 0 ? (
    <div className="flex flex-col rounded-2xl shadow-lg p-2 w-full bg-white">
      <CardTitle name={props.title} color={props.titleColor} />
      <CardSubText name={props.body} color={props.bodyColor} />
      <div className="flex justify-end">
        <Button
          name={props.action}
          outline="true"
          color="blue"
          clicked={() => history.push(props.link)}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default Default;
