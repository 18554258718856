import PageContent from "../../pageContent";
import Notification from "../../../../elements/notification/notification";
import { useHistory } from "react-router-dom";
import {
  CardTitle,
  FeatureTitle,
  PageTitle,
} from "../../../../elements/text";
import Table from "../../../../elements/table/table";
import { Button, TextButton } from "../../../../elements/button";
import { AiOutlineInfoCircle } from "react-icons/ai";

const LinkAccount = () => {
 
  const history = useHistory();
  const tableheader1 = ["Name", "User type", "Linked on", "Action"];
  const users1 = [
    {
      id: "1",
      name: "Name",
      type: "Parent",
      status: "linked",
      linkedon: "a month ago",
    },
    {
      id: "2",
      name: "LNDC",
      type: "School",
      status: "linked",
      linkedon: "2 days ago",
    },
    {
      id: "1",
      name: "Name of parent",
      type: "Parent",
      status: "pending",
      linkedon: "a month ago",
    },
  ];

  const tablebody1 = [];
  const tablebody2 = [];
  Object.values(users1.filter((user) => user.status === "linked")).forEach(
    (value) => {
      // convert object to key's array

      const keys = Object.values(value);

      // iterate over object

      // keys.forEach((key, index) => {
      const actionbutton = (
        <>
          <TextButton
            name="Unlink"
            color="red"
            clicked={() => {
              history.push(`/student/user/linkedaccounts/${keys[0]}`); //here must a test id
            }}
          />
        </>
      );
      tablebody1.push({
        td1: { data: keys[1], type: "text" },
        td2: { data: keys[2], type: "text" },
        td3: { data: keys[3], type: "text" },
        td4: { data: keys[4], type: "text" },
        td5: { data: keys[5], type: "text" },
        td6: {
          data: <>{actionbutton}</>,
          type: "component",
        },
      });
      // });
    }
  );

  Object.values(users1.filter((user) => user.status === "pending")).forEach(
    (value) => {
      // convert object to key's array

      const keys = Object.values(value);

      // iterate over object

      // keys.forEach((key, index) => {
      const actionbutton = (
        <>
          <TextButton
            name="Unlink"
            color="red"
            clicked={() => {
              history.push(`/student/user/linkedaccounts/${keys[0]}`); //here must a test id
            }}
          />
        </>
      );
      tablebody2.push({
        td1: { data: keys[1], type: "text" },
        td2: { data: keys[2], type: "text" },
        td3: { data: keys[3], type: "text" },
        td4: { data: keys[4], type: "text" },
        td5: { data: keys[5], type: "text" },
        td6: {
          data: <>{actionbutton}</>,
          type: "component",
        },
      });
      // });
    }
  );

  return (
    <PageContent>
      <div
        className={`md:grid  bg-gray-200 grid-cols-12 w-full min-h-screen-sec max-h-myscreen-sec md:max-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-9`}>
          <FeatureTitle name="Link accounts" />

          <div className="p-3 space-y-4">
            <span className="flex flex-row justify-items-center items-center">
              <CardTitle name="USER ID:" color="blue" />

              <AiOutlineInfoCircle
                color="#183F71"
                size={18}
              ></AiOutlineInfoCircle>
            </span>

            <span className="flex flex-row justify-items-center items-center space-x-4">
              <PageTitle name="432V34W4CW" color="blue" />{" "}
              <Button
                name="Copy"
                outline="true"
                color="blue"
                isSmall
                clicked={() => {}}
              />
            </span>
          </div>
          <div className="space-y-4">
            <CardTitle name="Linked accounts" color="blue" />
            <div className="bg-white relative overflow-x-auto sm:rounded-lg mb-4">
              <Table thead={tableheader1} tbody={tablebody1} />
            </div>
            <CardTitle name="Pending accounts" color="blue" />
            <div className="bg-white relative overflow-x-auto sm:rounded-lg  mb-4">
              <Table thead={tableheader1} tbody={tablebody2} />
            </div>
          </div>
        </div>
        <div className={`p-3 col-span-3`}>
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default LinkAccount;
