import { useState } from 'react';
import { TabPanel, useTabs } from 'react-headless-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { MdMenu, MdOutlineArrowBackIosNew, MdOutlineNavigateNext } from 'react-icons/md';
import { IoSettingsOutline } from 'react-icons/io5';
import { motion } from 'framer-motion/dist/framer-motion'

import PageContent from '../pageContent';
import { CardBody, CardTitle, FeatureTitle } from '../../../elements/text';
import { Button, ButtonWithIcon, TextButton } from '../../../elements/button';
import TabSelector from './TabSelector';
import Input from '../../../elements/input';
import Post from '../../../common/post/Posts';
import Modal from '../../../elements/modal/modal';
import {
  changeAskQuestionModalStatus,
  changeCommentModalStatus,
  setComment,
  setQuestion,
} from '../../../../store/actions/community';
import RichTextEditor from '../../../elements/RichTextEditor';

const Community = () => {
  const posts = [
    {
      content: 'What was the funniest thing that ever happened with your parenting when I was little?',
      related: ['Biology'],
      comments: [
        {
          content: '🤣 Can\'t mention this here!',
          creator: 'Ikuzwe Bitega',
          createdAt: '2022-05-05',
          isAnswer: true,
        },
        {
          content:
            '😂 Let talk this privately!',
          creator: 'Ikuzwe Bitega',
          createdAt: '2022-05-05',
          isAnswer: false,
        },
        {
          content:
            '👍',
          creator: 'Mugisha DAVID',
          createdAt: '2022-05-05',
          isAnswer: false,
        },
      ],
      creator: 'Ikuzwe Bitega',
      createdAt: '2022-05-05',
      answered: true,
    },
    {
      content: 'Hey my fellow parents, How do you measure your children interests?',
      related: ['Biology'],
      comments: [],
      creator: 'Ikuzwe Bitega',
      createdAt: '2022-05-05',
      answered: false,
    },
  ];
  const groups = [
    {
      name: 'All posts',
      unread: 0,
      createdBy: '',
    },
    {
      name: 'Group 1',
      unread: 0,
      createdBy: '',
    },
    {
      name: 'Another group',
      unread: 0,
      createdBy: '',
    },
    {
      name: 'Group 3',
      unread: 2,
      createdBy: '',
    },
    {
      name: 'Yet another group',
      unread: 0,
      createdBy: '',
    },
  ];

  const tabs = groups.map((group) => group.name);
  const [selectedTab, setSelectedTab] = useTabs(tabs, 'All posts');
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [related, setRelated] = useState('');
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupType, setGroupType] = useState('private');
  const [showJoiningRequestsModal, setShowJoiningRequestsModal] = useState(false); 
  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch();
  const showCommentModal = useSelector(
    (state) => state.community.showCommentModal
  );
  const showAskQuestionModal = useSelector(
    (state) => state.community.showAskQuestionModal
  );
  const comment = useSelector((state) => state.community.comment);
  const question = useSelector((state) => state.community.question);

  const requests = [
    { name: 'Oliver Karangwa', school: 'LDK', added: true },
    { name: 'Oliver Karangwa', school: 'LDK', added: false },
    { name: 'Oliver Karangwa', school: 'LDK', added: true },
    { name: 'Oliver Karangwa', school: 'LDK', added: true },
    { name: 'Oliver Karangwa', school: 'LDK', added: false },
  ];

  return (
    <PageContent>
      <Modal
        medium
        show={showCommentModal}
        modalClosed={() => dispatch(changeCommentModalStatus(false))}
      >
        <CardTitle name="Provide an answer" />
        <RichTextEditor
          value={comment}
          onChange={(text) => dispatch(setComment(text))}
          placeholder="Write your answer here"
        />
        <CardBody
          name="The code: answer politely!"
          additional="w-full pb-3 border-b border-gray-500"
        />
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => dispatch(changeCommentModalStatus(false))}
          />
          <Button
            name="Post"
            outline="false"
            color="blue"
            clicked={() => dispatch(changeCommentModalStatus(false))}
          />
        </div>
      </Modal>
      <Modal
        medium
        show={showAskQuestionModal}
        modalClosed={() => dispatch(changeAskQuestionModalStatus(false))}
      >
        <CardTitle name="Ask your question" />
        <RichTextEditor
          value={question}
          onChange={(text) => dispatch(setQuestion(text))}
          placeholder="Write your question here"
        />
        <Input
          label="Choose tag: Eg: Physics, Thermodynamics"
          elementType="input"
          elementConfig={{
            type: 'text',
            placeholder: 'What is your question related to?',
          }}
          value={related}
          changed={setRelated}
        />
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => dispatch(changeAskQuestionModalStatus(false))}
          />
          <Button
            name="Post"
            outline="false"
            color="blue"
            clicked={() => dispatch(changeAskQuestionModalStatus(false))}
          />
        </div>
      </Modal>
      <Modal
        medium
        show={showCreateGroupModal}
        modalClosed={() => setShowCreateGroupModal(false)}
      >
        <CardTitle name="Create Group" />
        <Input
          label="Name"
          elementType="input"
          elementConfig={{
            type: 'text',
            placeholder: 'Enter group name',
          }}
          value={groupName}
          changed={setGroupName}
          validation={{ required: true }}
          shouldValidate
          error="Group name is required"
        />
        <Input
          label="Description"
          elementType="textarea"
          elementConfig={{
            type: 'text',
            placeholder: 'Add description',
          }}
          value={groupDescription}
          changed={setGroupDescription}
        />
        <Input
          elementType="select"
          elementConfig={{
            startingValue: 'SELECT',
            options: [
              { value: 'private', displayValue: 'Private' },
              { value: 'public', displayValue: 'Public' },
            ],
          }}
          value={groupType}
          changed={setGroupType}
          validation={{ required: true }}
          shouldValidate
          error="Invalid type"
        />
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => setShowCreateGroupModal(false)}
          />
          <Button
            name="Save"
            outline="false"
            color="blue"
            clicked={() => setShowCreateGroupModal(false)}
          />
        </div>
      </Modal>
      <Modal
        medium
        show={showJoiningRequestsModal}
        modalClosed={() => setShowJoiningRequestsModal(false)}
      >
        <CardTitle name="Joining requests" />
        {requests.map((request, index) => (
          <div
            key={index}
            className="flex justify-between items-center py-2 mx-20"
          >
            <div className="flex flex-col">
              <CardBody
                name={request.name}
                color="blue"
                additional="font-bold"
              />
              <CardBody
                name={request.school}
                color="gray-500"
                additional="font-bold"
              />
            </div>
            <div className="flex divide-x divide-gray-500">
              {request.added ? (
                <>
                  <CardBody
                    name="Added"
                    color="blue"
                    additional="italic pr-2"
                  />
                  <TextButton
                    name="remove"
                    color="red"
                    additional="italic pl-2"
                  />
                </>
              ) : (
                <TextButton name="Add" color="blue" />
              )}
            </div>
          </div>
        ))}
        <div className="flex justify-between mx-20 py-2">
          <CardBody name="34 people" color="gray-500" additional="font-bold" />
          <TextButton name="Expand" color="blue" additional="font-bold" />
        </div>
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => setShowJoiningRequestsModal(false)}
          />
          <Button
            name="Save"
            outline="false"
            color="blue"
            clicked={() => setShowJoiningRequestsModal(false)}
          />
        </div>
      </Modal>
      <div className="flex w-full h-full">
        <div className="p-3 bg-gray-200 h-full w-30% md:h-full hidden md:block">
          <div className="flex justify-center">
            <Button
              name="+ Create group"
              outline="false"
              color="blue"
              isSquare
              clicked={() => setShowCreateGroupModal(true)}
            />
          </div>
          <div className="py-5">
            {groups.map((group, index) => (
              <TabSelector
                key={index}
                isActive={selectedTab === group.name}
                title={group.name}
                new={group.unread}
                onClick={() => setSelectedTab(group.name)}
              />
            ))}
          </div>
        </div>
        <div className='md:hidden block z-10'>
          {toggle ? '' : (
              <motion.div 
              whileInView={{ x: [-20 , 0] }} 
              whileHover={{ scale: 1.1 }}
              className="w-15 h-15 absolute bg-blue rounded-r-full"> 
              <MdOutlineNavigateNext color="white" size={30} onClick={() => setToggle(true)} />
              </motion.div>
          )}

          {toggle ? (
            <motion.div
            whileInView={{ x: [-100, 0] }}
            transition={{ duration: 0.85, ease:'easeOut'}}
            className="w-100% h-full absolute bg-gray-200 z-10"
            >
              <motion.div 
                whileInView={{ x: [-20 , 0] }}
                whileHover={{ scale: 1.1 }}
                className="w-15 h-15 absolute bg-blue rounded-r-full"> 
                <MdOutlineArrowBackIosNew color="white" size={30} onClick={() => setToggle(false)} className="p-2"/> 
              </motion.div>
              <div className="p-3 h-full">
                <div className="flex justify-center">
                <Button
                  name="+ Create group"
                  outline="false"
                  color="blue"
                  isSquare
                  clicked={() => { 
                    setShowCreateGroupModal(true);
                    setToggle(false);
                    }
                  }
                />
                </div>
                <div className="py-5">
                {groups.map((group, index) => (
                  <TabSelector
                    key={index}
                    isActive={selectedTab === group.name}
                    title={group.name}
                    new={group.unread}
                    onClick={() => { 
                      setSelectedTab(group.name);
                      setToggle(false); 
                    }}
                  />
                ))}
                </div>
              </div>
            </motion.div>
          ): ''}
        </div>
        <div className="p-3 bg-white md:w-80% w-full h-full overflow-x-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <FeatureTitle name="Community" />
            <div className="flex space-x-2">
              {groups.filter(
                (group) =>
                  group.name === selectedTab && group.createdBy === 'me'
              )[0] && (
                <ButtonWithIcon
                  name="Joining Requests"
                  outline="true"
                  color="blue"
                  onClick={() => setShowJoiningRequestsModal(true)}
                >
                  <IoSettingsOutline color="#183F71" size={26} />
                </ButtonWithIcon>
              )}
              <ButtonWithIcon
                name="Ask Question"
                outline="false"
                color="blue"
                isSquare
                onClick={() => dispatch(changeAskQuestionModalStatus(true))}
              >
                <MdMenu color="#FFF" size={26} />
              </ButtonWithIcon>
            </div>
          </div>
          {groups.map((group, index) => (
            <TabPanel key={index} hidden={selectedTab !== group.name}>
              <div className="grid w-full">
                <div className="col-span-8">
                  <div className="flex justify-between items-end flex-wrap">
                    <div className="md:w-20% 40%">
                      <Input
                        label="Show entries"
                        elementType="select"
                        elementConfig={{
                          startingValue: 'SELECT',
                          options: [
                            { value: 10, displayValue: '10' },
                            { value: 25, displayValue: '25' },
                            { value: 50, displayValue: '50' },
                            { value: 100, displayValue: '100' },
                          ],
                        }}
                        value={limit}
                        changed={setLimit}
                      />
                    </div>
                    <div className="md:w-30% w-50% p-2">
                      <Input
                        elementType="input"
                        elementConfig={{
                          placeholder: 'Search',
                          type: 'text',
                        }}
                        value={searchTerm}
                        changed={setSearchTerm}
                        additional="rounded-full"
                      />
                    </div>
                    <div className="p-3 flex flex-col items-end">
                  <Input
                    elementType="select"
                    elementConfig={{
                      startingValue: 'Search here',
                      options: [
                        { value: 'My Posts', displayValue: 'My Posts' },
                        {
                          value: 'Posts I replied to',
                          displayValue: 'Posts I replied to',
                        },
                        { value: 'Math', displayValue: 'Math' },
                        { value: 'Biology', displayValue: 'Biology' },
                      ],
                    }}
                    value={limit}
                    changed={setLimit}
                  />
                </div>
                  </div>
                  <div className='w-full md:w-90%'>
                    {posts.map((post, index) => (
                      <Post key={index} {...post} />
                    ))}
                  </div>
                </div>
                {/* <div className="col-span-4 p-3 flex flex-col items-end">
                  <Input
                    elementType="select"
                    elementConfig={{
                      startingValue: 'Search here',
                      options: [
                        { value: 'My Posts', displayValue: 'My Posts' },
                        {
                          value: 'Posts I replied to',
                          displayValue: 'Posts I replied to',
                        },
                        { value: 'Math', displayValue: 'Math' },
                        { value: 'Biology', displayValue: 'Biology' },
                      ],
                    }}
                    value={limit}
                    changed={setLimit}
                  />
                </div> */}
              </div>
            </TabPanel>
          ))}
        </div>
      </div>
    </PageContent>
  );
};

export default Community;
