import React, { useState } from 'react';
import { Button, ButtonWithIcon } from '../../../elements/button';
import { MdAttachment } from 'react-icons/md';
import axios from 'axios'; // Import Axios

const FileUploader = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file?.type?.startsWith('application/pdf')) {
            alert('Please select a PDF file.');
            return;
        }
        //setSelectedFile(file);
        handleUpload(file);
    };

    const handleUpload = async (file) => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/opanda/uploadDocs`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(uploadProgress);
                },
            });

            if (response.status !== 200) {
                throw new Error('Upload failed');
            }

            setSelectedFile(response.data.uploadedFileNames[0]);
            props.setTheDoc(response.data.uploadedFileNames[0]);
        } catch (error) {
            console.error('Upload error:', error);
            alert('Upload failed. Please try again.');
        } finally {
            setProgress(0);
        }
    };

    return (
        <div>
            <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="fileInputE"
            />
            <label htmlFor="fileInputE">
                <ButtonWithIcon
                    name="Attach PDF Document"
                    outline="true"
                    color="blue"
                    onClick={() => document.getElementById('fileInputE').click()}
                >
                    {progress > 0 ? `${progress}%` : <MdAttachment color="#183F71" size={26} />}
                </ButtonWithIcon>
            </label>
            {progress > 0 && <p>Uploading... ({progress}%)</p>}
        </div>
    );
};

export default FileUploader;
