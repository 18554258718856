import { calculateTimeFromDate } from "../../../shared/utility";
import { CardBody, CardTitle, SmallText } from "../../elements/text";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../elements/modal/modal";
import { bring_subject_image_src } from "../../../functions/programs";
import ModalSimulation from "./ModalSimulation";

const CardForSimulation = (props) => {
  const history = useHistory();
  const { type } = props;
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleClick = useCallback(() => {
    switch (type) {
      case "Video":
        setShowVideoModal(true);
        break;
      case "Simulation":
        history.push(props.mediasrc);
        break;
      default:
        break;
    }
  }, [history, type]);
  const closeTheModal = (data) => {
    setShowVideoModal(data)
  }

  return (
    <div
      className="my-2 flex flex-row-reverse align-start justify-between bg-white  filter drop-shadow-md rounded-2xl cursor-pointer"
      onClick={() => { props.showTheModal(props.unit, props.type, props.mediasrc) }}    >

      <div className={`w-20 h-28 relative rounded-2xl`}>
        <img
          src={bring_subject_image_src(props.subject).imgsrc}
          alt=""
          className="absolute inset-0 w-full h-full object-cover rounded-2xl"
        />
      </div>
      <form className="p-2 flex flex-col justify-between">
        <div className="flex flex-col flex-wrap">
          <CardTitle name={props.unit.substring(0, 20) + '...'} alignment="left" />
          <CardBody name={props.type} color="red" alignment="left" />
        </div>
        <SmallText
          name={`${calculateTimeFromDate(props.createdAt)} ago`}
          color="gray-500"
          alignment="left"
        />
      </form>
    </div>
  );
};

export default CardForSimulation;
