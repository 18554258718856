import React, { useEffect, useState } from 'react';
import Input from '../../../elements/input';
import { Button } from '../../../elements/button';
import axios from "../../../../axios-base";
import { useSelector } from "react-redux";
import Spinner from '../../../elements/spinner';

const DocumentPreviewComponent = (props) => {
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfs, setPdfs] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isSearchingSaveSimulation, setIsSearchingSaveSimulation] = useState(false);
    const userAuth = useSelector((state) => state.auth);
    const token = userAuth.token;

    const searchPdf = async () => {
        if (pdfUrl === '') {
            setSearchResults([]);
            return;
        }
        setIsSearching(true);
        axios
            .post(
                `/opanda/summarynotes/cbcsummarynotesfindDocsInTheLibrary`,
                {
                    search_query: pdfUrl,
                    subject: props.subject
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                setSearchResults(response.data.books)
                setIsSearching(false);
            })
            .catch((err) => {
                console.log(err);
                setIsSearching(false);
            });


    };

    const addPdf = () => {
        if (searchResults.length > 0) {
            setPdfs((pdfs_) => {
                const updatedPdfs = [...pdfs_];
                updatedPdfs.push(searchResults[0]); // Add the first result
                return [...updatedPdfs];
            });

            setSearchResults([]); // Clear search results
            setPdfUrl('');
        } else {
            setError('No search results to add.');
        }
    };

    const removePdf = (index) => {
        const updatedPdfs = [...pdfs];
        updatedPdfs.splice(index, 1);
        setPdfs(updatedPdfs);
    };

    useEffect(() => {
        // Your effect code here
    }, [pdfs]);

    useEffect(() => {
        axios
            .post(
                `/opanda/summarynotes/cbcsummarynotesfindMyDocsOncourse`,
                {
                    classId: props.classId
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            .then((response) => {               
                
                const updatedPdfs = response.data.books.map((book) => {
                    return { book_id: book.panda_books_id, book_name: book.panda_books_name }
                });
                setPdfs(updatedPdfs);
            })
            .catch((err) => {
            });

    }, []);
    

    const saveDocuments = () => {
        if (pdfs.length === 0) {
            alert('Please add at least one document.');
        }
        const bookIds = pdfs.map(book => book.book_id).join(',');
        setIsSearchingSaveSimulation(true);
        axios
            .post(
                `/opanda/summarynotes/cbcsummarynotesaddDocs`,
                {
                    bookIds: bookIds,
                    classId: props.classId
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                axios
                    .post(
                        `/opanda/summarynotes/cbcsummarynotesfindMyDocsOncourse`,
                        {
                            classId: props.classId
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    )
                    .then((response) => {
                        const updatedPdfs = response.data.books.map((book) => {
                            return { book_id: book.panda_books_id, book_name: book.panda_books_name }
                        });
                        setPdfs(updatedPdfs);
                        setSearchResults([]);
                        setIsSearchingSaveSimulation(false);
                        setPdfUrl('');
                        props.closePopUpViewDocuments()
                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
                console.log(err);
                setIsSearchingSaveSimulation(false);
            });
    }


    return (
        (!isSearchingSaveSimulation) ? <div className='z-20' style={{ zIndex: 100000, overflow: 'auto' }}>
            <Input
                label="Book name"
                elementType="input"
                elementConfig={{
                    type: "text",
                    placeholder: "Book name",
                }}
                value={pdfUrl}
                changed={(value) => {
                    setPdfUrl(value);
                    setError('');
                    searchPdf()
                }}
            />

            {error && <p className="text-red-500" style={{ color: "red" }}>{error}</p>}

            <div style={{ position: 'relative' }}>
                {(!isSearching) ? <Button
                    name="Search PDF"
                    outline="true"
                    color="blue"
                    clicked={searchPdf}
                /> : <Spinner size="10" color="blue" />}

                {(searchResults.length > 0 || pdfs.length > 0) && (
                    <div className="grid grid-cols-2 gap-4" style={{ position: 'relative', height: 150, top: '100%', fontSize: 11, left: 0, zIndex: 1, width: '100%', overflowY: "auto" }}>
                        <div><ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                            {searchResults.filter((searchResult) => {
                                return pdfs.filter((pdf) => pdf.book_id === searchResult.panda_books_id).length === 0;
                            }).map((result, index) => (
                                <li
                                    key={index}
                                    style={{ padding: '5px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                                >
                                    {result.panda_books_name}
                                    <Button
                                        name="Add"
                                        outline="true"
                                        color="green"
                                        isSmall={"true"}
                                        clicked={() => {
                                            const updatedPdfs = [...pdfs, { book_id: result.panda_books_id, book_name: result.panda_books_name }];
                                            setPdfs(updatedPdfs)
                                        }}
                                    />
                                </li>
                            ))}
                        </ul></div>
                        <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', overflowY: "auto" }}>
                                {pdfs.map((result, index) => (
                                    <li
                                        key={index}
                                        style={{ padding: '5px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                                    >
                                        {result.book_name}
                                        <Button
                                            name="Remove"
                                            outline="true"
                                            color="red"
                                            isSmall={"true"}
                                            clicked={(index) => {
                                                removePdf(index)
                                            }}
                                        />
                                    </li>
                                ))}
                            </div>

                            <p className='bg-blue-100'>Total PDFs: {pdfs.length}</p>
                        </div>
                    </div>
                )}
            </div>

            <Button
                name="Save the documents"
                outline="true"
                color="green"
                clicked={saveDocuments}
            />
        </div> : <Spinner size="20" color="blue" />
    );
};

export default DocumentPreviewComponent;
