import axios from "../../axios-base";

export const STUDENT_DASHBOARD_GET_REFERER_ID_INIT =
  "STUDENT_DASHBOARD_GET_REFERER_ID_INIT";
export const STUDENT_DASHBOARD_GET_REFERER_ID_SUCCESS =
  "STUDENT_DASHBOARD_GET_REFERER_ID_SUCCESS";
export const STUDENT_DASHBOARD_GET_REFERER_ID_FAILED =
  "STUDENT_DASHBOARD_GET_REFERER_ID_FAILED";

export const STUDENT_DASHBOARD_UPDATE_REFERER_ID_INIT =
  "STUDENT_DASHBOARD_UPDATE_REFERER_ID_INIT";
export const STUDENT_DASHBOARD_UPDATE_REFERER_ID_SUCCESS =
  "STUDENT_DASHBOARD_UPDATE_REFERER_ID_SUCCESS";
export const STUDENT_DASHBOARD_UPDATE_REFERER_ID_FAILED =
  "STUDENT_DASHBOARD_UPDATE_REFERER_ID_FAILED";

export const STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_INIT =
  "STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_INIT";
export const STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_SUCCESS =
  "STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_SUCCESS";
export const STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_FAILED =
  "STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_FAILED";

  export const STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_INIT =
  "STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_INIT";
export const STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_SUCCESS =
  "STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_SUCCESS";
export const STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_FAILED =
  "STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_FAILED";  

export function student_dashboard_get_referer_id_init() {
  return {
    type: STUDENT_DASHBOARD_GET_REFERER_ID_INIT,
  };
}
export function student_dashboard_get_referer_id_success(data) {
  return {
    type: STUDENT_DASHBOARD_GET_REFERER_ID_SUCCESS,
    payload: data,
  };
}
export function student_dashboard_get_referer_id_failed(error) {
  return {
    type: STUDENT_DASHBOARD_GET_REFERER_ID_FAILED,
    payload: error,
  };
}

export function student_dashboard_get_referer_id_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_get_referer_id_init());
    axios
      .post(
        `/opanda/account-link/getrefererid`,
        {
          id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          student_dashboard_get_referer_id_success(response.data.results)
        );
      })
      .catch((err) => {
        dispatch(student_dashboard_get_referer_id_failed(err.message));
      });
  };
}

export function student_dashboard_update_referer_id_init() {
  return {
    type: STUDENT_DASHBOARD_UPDATE_REFERER_ID_INIT,
  };
}
export function student_dashboard_update_referer_id_success(data) {
  return {
    type: STUDENT_DASHBOARD_UPDATE_REFERER_ID_SUCCESS,
    payload: data,
  };
}
export function student_dashboard_update_referer_id_failed(error) {
  return {
    type: STUDENT_DASHBOARD_UPDATE_REFERER_ID_FAILED,
    payload: error,
  };
}
export function student_dashboard_update_referer_id_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_update_referer_id_init());
    axios
      .put(
        `/opanda/account-link/updaterefererid`,
        {
          id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          student_dashboard_update_referer_id_success(response.data.results)
        );
      })
      .catch((err) => {
        dispatch(student_dashboard_update_referer_id_failed(err.message));
      });
  };
}

export function student_dashboard_get_linked_accounts_init() {
  return {
    type: STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_INIT,
  };
}
export function student_dashboard_get_linked_accounts_success(data) {
  return {
    type: STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_SUCCESS,
    payload: data,
  };
}
export function student_dashboard_get_linked_accounts_failed(error) {
  return {
    type: STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_FAILED,
    payload: error,
  };
}

export function student_dashboard_get_linked_accounts_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_get_linked_accounts_init());
    axios
      .post(
        `/opanda/account-link/getlinkedaccounts`, 
        {
          id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          student_dashboard_get_linked_accounts_success(response.data.results)
        );
      })
      .catch((err) => {
        dispatch(student_dashboard_get_linked_accounts_failed(err.message));
      });
  };
}


export function student_dashboard_remove_linked_account_init() {
  return {
    type: STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_INIT,
  };
}
export function student_dashboard_remove_linked_account_success(data) {
  return {
    type: STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_SUCCESS,
    payload: data,
  };
}
export function student_dashboard_remove_linked_account_failed(error) {
  return {
    type: STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_FAILED,
    payload: error,
  };
}

export function student_dashboard_remove_linked_account_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_remove_linked_account_init());
    axios
      .put(
        `/opanda/account-link/delink`, 
        {
          id: data.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          student_dashboard_remove_linked_account_success(response.data.results)
        );
      })
      .catch((err) => {
        dispatch(student_dashboard_remove_linked_account_failed(err.message));
      });
  };
}