import { connect } from 'react-redux';
import { NavList } from '../../../elements/sidebar/Navlist';
import { NavSubItem } from '../../../elements/sidebar/NavSubItem';
import { TabPanel, useTabs } from 'react-headless-tabs';
import { ButtonClose } from '../../../elements/button';
import { store } from '../../../../store/store';

const SideBar = function (props) {
  const [selectedTab, setSelectedTab] = useTabs([
    'modules',
    'extracurricular',
    'learningresources',
  ]);

  const { visibility, hidesidebar, changetab } = props;
  return (
    <div className={`${visibility} md:block h-full`}>
      {/* Sidebar starts */}
      {/* Remove class [ hidden ] and replace [ sm:flex ] with [ flex ] */}
      <div className="w-full sm:relative bg-gray-200 shadow sm:h-full flex-col justify-between hidden sm:flex">
        <div className="px-8 h-full">
          <div className="mt-6">
            <NavList
              name="Modules"
              isActive={selectedTab === 'modules'}
              onClick={() => {
                setSelectedTab('modules');
              }}
            >
              <TabPanel
                hidden={selectedTab !== 'modules'}
                className="transform transition duration-500 ease-in-out"
              >
                <NavSubItem
                  name="CBC"
                  onClick={() => {
                    store.dispatch({ type: 'CHANGE_TAB', payload: 'cbc' });
                  }}
                />
              </TabPanel>
            </NavList>
          </div>
        </div>
      </div>

      <div
        className={`w-full absolute z-40 bg-gray-200 shadow md:h-full flex-col justify-between  sm:hidden  transition duration-150 ease-in-out`}
        id="mobile-nav"
      >
        <div className="absolute right-0 m-2">
          <ButtonClose color="red" onClick={hidesidebar} />
        </div>
        <div className="px-8">
          <ul className="mt-6">
            <NavList
              name="Modules"
              isActive={selectedTab === 'modules'}
              onClick={() => {
                setSelectedTab('modules');
              }}
            >
              <TabPanel
                hidden={selectedTab !== 'modules'}
                className="transform transition duration-500 ease-in-out"
              >
                <NavSubItem
                  name="CBC"
                  onClick={() => {
                    changetab('cbc');
                  }}
                />
              </TabPanel>
            </NavList>
          </ul>
          <div className="flex justify-center mt-48 mb-4 w-full">
            <div className="relative ">
              <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4"></div>
              <input
                className=" bg-gray-700 focus:outline-none rounded w-full text-sm text-gray-500 bg-gray-100 pl-10 py-2"
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Sidebar ends */}
    </div>
  );
};
// export default SideBar;
const mapStateToProps = () => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showsidebar: () => store.dispatch({ type: 'SHOW_SIDEBAR' }),
    hidesidebar: () => store.dispatch({ type: 'HIDE_SIDEBAR' }),
    changetab: (tab) => store.dispatch({ type: 'CHANGE_TAB', payload: tab }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
