const initialReducer = {
  contentmodalstatus: "hidden",
  morepurchasemodalstatus: "hidden",
  modalcontent: null,
};

const contentmodalreducer = (state = initialReducer, action) => {
  switch (action.type) {
    case "SHOW_CONTENT_MODAL":
      return {
        ...state,
        contentmodalstatus: action.payload,
        modalcontent: action.modalcontent,
      };
    case "SHOW_MORE_PURCHASE_MODAL":
      return { ...state, morepurchasemodalstatus: action.payload };
    default:
      return state;
  }
};
export default contentmodalreducer;
