import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from "../../../../elements/text";
import { Button, GoBackButton } from "../../../../elements/button";
import { useEffect, useState } from "react";
import CountDown from "../../../../elements/CountDown";
import celebration from "../../../../../images/celebration.png";
import { FeatureItemCard } from "../../../../elements/card";
import { useHistory, useParams } from "react-router-dom";
import CompleteSentence from "../../../../common/features/tests/testtypes/completesentence/CompleteSentence";
import OpenEnded from "../../../../common/features/tests/testtypes/openended/OpenEnded";
import DragAndDrop from "../../../../common/features/tests/testtypes/draganddrop/DragAndDrop";
import Header from "../../../../common/header/header";
import Sidebar from "../sidebar";
import Topnavbar from "../topnavbar";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions/";
import Spinner from "../../../../elements/spinner";
import constants from "../../../../../constants/constants";
import TestPreviewPanda from "./test/Test";

const Test = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const test = useSelector((state) => state.testmaps.testmap);
  const testLoading = useSelector((state) => state.testmaps.testmapsLoading);
  const [answersmap, setanswersmap] = useState({});
  const [studentanswersmap, setstudentanswersmap] = useState({});

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [index, setindex] = useState(0);
  useEffect(() => {
    if (params.testId) {
      dispatch(actions.getOneTestMaps(params.testId));
    }
  }, []);
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
      <Header />
      <div  className="h-full w-full sm:flex">
        <div className="w-2/6">
          <Sidebar setindex={setindex} />
          <Topnavbar />
        </div>
        <div style={{height:"100vh"}}
          className={`relative w-full p-3 max-h-myscreen-sec md:max-h-screen-sec col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <FeatureTitle name="Tests " />
          <GoBackButton
            link="/previewpanda"
            action="Back"
            location="Preview Panda"
          />
          {(test && !testLoading) ? <TestPreviewPanda dashboard_homepage_auto_test={test} /> : <Spinner size="20" color="blue" />}
        </div>
      </div>
    </div>
  );
};

export default Test;
