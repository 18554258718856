import Layout from '../../components/common/Layout';
import CBC from '../../components/pages/teacher/programs/cbc';

const CBCContainer = () => {
  return (
    <Layout>
      <CBC />
    </Layout>
  );
};

export default CBCContainer;
