import { ContentContainer } from '../../../common/content/container';
import { PageBody, SectionTitle } from '../../../elements/text';
import Notification from '../../../elements/notification/notification';

export const CBC = (props) => {
  let testdata = [
    {
      id: 1,
      subject: 'Biology',
      imgsrc: '/images/preview/bio.svg',
      tag: 'Test',
      unit: 'unit 2',
      created_at: '2 hours ago',
    },
    {
      id: 2,
      subject: 'Physics',
      imgsrc: '/images/preview/math.svg',
      tag: 'Test',
      unit: 'unit 2',
      created_at: '2 hours ago',
    },
  ];
  const notifications = [
    {
      title: 'GUEST',
      titleColor: 'yellow',
      body: 'Access your homework/quiz',
      bodyColor: 'black',
      action: 'Enter code',
    },
    {
      title: 'NEW!',
      titleColor: 'red',
      body: 'A new lab has been added',
      bodyColor: 'black',
      action: 'Go for it',
    },
    {
      title: 'Reminder',
      titleColor: 'red',
      body: 'You have an online class Scheduled tomorrow',
      bodyColor: 'black',
      action: 'Details',
    },
  ];

  return (
    <div className="container flex flex-col items-start">
      <Notification notifications={notifications} />
      <div className="md:w-60% mb-20">
        <SectionTitle
          name="Compentence Based Curriculum"
          alignment="left"
          color="blue"
        />
        <PageBody
          name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
          alignment="left"
          color="blue"
        />
      </div>
      <ContentContainer
        guest
        name="Tests"
        data={testdata}
        slider={true}
        type="one"
      />
    </div>
  );
};
