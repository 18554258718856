import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Routes from './routes/Routes';
import isEmpty from 'lodash/isEmpty';
import { store } from './store/store';
import { authLogout, authSuccess } from './store/actions/auth';
import jwtDecode from 'jwt-decode';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import dotenv from 'dotenv';

dotenv.config();

const token = localStorage.jwt;

/* The subsequent lines are setting values in
the browser's local storage. */
localStorage.setItem('hint_landing', -1);
localStorage.setItem('h_header_options_btn', -1);
localStorage.setItem('h_xtra_units_btn', -1);


(async () => {
  try {
    if (isEmpty(token)) {
      store.dispatch(authLogout());
      return;
    }

    //decode token
    const decodedUser = jwtDecode(token);
    if (decodedUser) {
      store.dispatch(authSuccess({ token: token, user: decodedUser }));
    }
  } catch (err) {
    store.dispatch(authLogout());
    window.location.reload();
    return;
  }
})();

function App() {
  return (
    <>
      <Helmet>
        <title>O'GENIUS PANDA</title>
        <meta name="description" content="Making education exciting" />
        <meta name="description" content="Making education exciting, brought to you by O'Genius Priority ltd." />
        <meta charset="utf-8" />
        <title>O'Genius Panda</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content="" name="keywords" />
        <meta content="" name="description" />
        <meta property="og:url" content="https://www.opanda.xyz" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="O'Genius Panda" />
        <meta property="og:description" content="Making education exciting" />
        <meta
          name="keywords"
          content="elearning, teaching, school, teacher, student, Sciences in Rwanda"
        />
        <link rel="icon" href="/logo.png" />
      </Helmet>
      <ReactNotifications />
      <Router>
        <Routes />
      </Router>
    </>
  );
}

export default App;
