import { updateObject } from "../../shared/utility";
import {
  CHANGE_CHOSEN_PROGRAM,
  CHANGE_PAYMENT_DONE_MODAL_STATE,
  CHANGE_PAYMENT_MODAL_STATE,
  SET_CHOSEN_PACKAGE,
  MAKE_PAYMENT_SUCCESS,
  MAKE_PAYMENT_START,
  MAKE_PAYMENT_FAIL,
  GET_EXTRA_PACKAGES_START,
  GET_EXTRA_PACKAGES_SUCCESS,
  GET_EXTRA_PACKAGES_FAIL,
  CHANGE_PACKAGE_MODAL_STATE,
  CHANGE_PACKAGE_DONE_MODAL_STATE,
  SET_CHOSEN_EXTRA_PACKAGE,
  ACTIVATE_EXTRA_PACKAGES_SUCCESS,
  ACTIVATE_EXTRA_PACKAGES_START,
  ACTIVATE_EXTRA_PACKAGES_FAIL
} from "../actions/payment";

const initialReducer = {
  showPaymentModal: false,
  showPaymentDoneModal: false,
  chosenPackage: null,
  chosenProgram: "CBC",
  payment: null,
  paymentLoading: false,
  paymentFail: null,
  extrapackages: [],
  extrapackagesLoading: false,
  extrapackagesFail: null,
  chosenExtraPackage: null,
  showPackageModal: false,
  showPackageDoneModal: false,
  activation: null,
  activationLoading: false,
  activationFail: null,
};

const changePaymentModalState = (state, action) => {
  return updateObject(state, {
    showPaymentModal: action.payload,
  });
};

const changePaymentDoneModalState = (state, action) => {
  return updateObject(state, {
    showPaymentDoneModal: action.payload,
  });
};

const setChosenPackage = (state, action) => {
  return updateObject(state, {
    chosenPackage: action.payload,
  });
};

const changeChosenProgram = (state, action) => {
  return updateObject(state, {
    chosenProgram: action.payload,
  });
};

const makePaymentSuccess = (state, action) => {
  return updateObject(state, {
    payment: action.payload,
    paymentLoading: false,
    paymentFail: null,
  });
};

const makePaymentFail = (state, action) => {
  return updateObject(state, {
    payment: null,
    paymentLoading: false,
    paymentFail: action.payload,
  });
};

const getExtraPackagesStart = (state, action) => {
  return updateObject(state, {
    extrapackages: [],
    extrapackagesLoading: true,
    extrapackagesFail: null,
  });
};

const getExtraPackagesSuccess = (state, action) => {
  return updateObject(state, {
    extrapackages: action.payload,
    extrapackagesLoading: false,
    extrapackagesFail: null,
  });
};

const getExtraPackagesFail = (state, action) => {
  return updateObject(state, {
    extrapackages: [],
    extrapackagesLoading: false,
    extrapackagesFail: action.payload,
  });
};

const changePackageModalState = (state, action) => {
  return updateObject(state, {
    showPackageModal: action.payload,
  });
};

const changePackageDoneModalState = (state, action) => {
  return updateObject(state, {
    showPackageDoneModal: action.payload,
  });
};
const setChosenExtraPackage = (state, action) => {
  return updateObject(state, {
    chosenExtraPackage: action.payload,
  });
};

const makePaymentStart = (state, action) => {
  return updateObject(state, {
    payment: null,
    paymentLoading: true,
    paymentFail: null,
  });
};

const activateExtraPackagesSuccess = (state, action) => {
  return updateObject(state, {
    activation: action.payload,
    activationLoading: false,
    activationFail: null,
  });
};

const activateExtraPackagesFail = (state, action) => {
  return updateObject(state, {
    activation: null,
    activationLoading: false,
    activationFail: action.payload,
  });
};

const activateExtraPackagesStart = (state, action) => {
  return updateObject(state, {
    activation: null,
    activationLoading: true,
    activationFail: null,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case CHANGE_PAYMENT_MODAL_STATE:
      return changePaymentModalState(state, action);
    case CHANGE_PAYMENT_DONE_MODAL_STATE:
      return changePaymentDoneModalState(state, action);
    case SET_CHOSEN_PACKAGE:
      return setChosenPackage(state, action);
    case CHANGE_CHOSEN_PROGRAM:
      return changeChosenProgram(state, action);
    case MAKE_PAYMENT_START:
      return makePaymentStart(state, action);
    case MAKE_PAYMENT_SUCCESS:
      return makePaymentSuccess(state, action);
    case MAKE_PAYMENT_FAIL:
      return makePaymentFail(state, action);
    case GET_EXTRA_PACKAGES_START:
      return getExtraPackagesStart(state, action);
    case GET_EXTRA_PACKAGES_SUCCESS:
      return getExtraPackagesSuccess(state, action);
    case GET_EXTRA_PACKAGES_FAIL:
      return getExtraPackagesFail(state, action);
      case CHANGE_PACKAGE_MODAL_STATE:
      return changePackageModalState(state, action);
    case CHANGE_PACKAGE_DONE_MODAL_STATE:
      return changePackageDoneModalState(state, action);
    case SET_CHOSEN_EXTRA_PACKAGE:
      return setChosenExtraPackage(state, action);
      case ACTIVATE_EXTRA_PACKAGES_START:
      return activateExtraPackagesStart(state, action);
    case ACTIVATE_EXTRA_PACKAGES_SUCCESS:
      return activateExtraPackagesSuccess(state, action);
    case ACTIVATE_EXTRA_PACKAGES_FAIL:
      return activateExtraPackagesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
