import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  school_dashboard_users_count_start,
  school_dashboard_users_start,
} from "../../../../store/actions/school.dashboard.users";

import UserSummary from "./summary";

const SummaryWrapper = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth);
  const schoolDashboardUsers = useSelector(
    (state) => state.schoolDashboardUsers
  );
  useEffect(() => {
    dispatch(
      school_dashboard_users_count_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
  }, []);

  return (
    <>
      {!schoolDashboardUsers.school_dashboard_users_count_loading &&
          schoolDashboardUsers.school_dashboard_users_count.length > 0 ? (
            <>
              {schoolDashboardUsers.school_dashboard_users_count?.map(
                (value, key) => (
                  <UserSummary
                    user={value.user_type}
                    key={key}
                    number={value.number}
                  />
                )
              )}
            </>
          ) : schoolDashboardUsers.school_dashboard_users_count_loading ? (
            <>
              <UserSummary user="student" loading="true" />
              <UserSummary user="teacher" loading="true" />
              <UserSummary user="parent" loading="true" />
            </>
          ) : (
            <>
              <UserSummary user="student" number="0" />
              <UserSummary user="teacher" number="0" />
              <UserSummary user="parent" number="0" />
            </>
          )}
      
    </>
  );
};

export default SummaryWrapper;
