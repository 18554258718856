import Footer from '../components/common/footer';
import Header from '../components/common/header/header';
import ScrollToTop from '../components/common/ScrollToTop';
import PageContent from '../components/pages/landing/completeprofile/pageContent';

const Completeprofile = () => {
  return (
    <div className="">
      <Header />
      <PageContent />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Completeprofile;
