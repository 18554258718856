import React from 'react';
import Profile from '../../../components/pages/teacher/user/profile/index';
import Layout from '../../../components/common/Layout';

const ProfileContainer = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};

export default ProfileContainer;