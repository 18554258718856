import { Button } from "../../../elements/button";
import { SectionTitle } from "../../../elements/text";

const RemindAdmin = (props) => {
  return (
    <>
      <SectionTitle name="Activities" color="blue" alignment="left" />
      <div className="w-48 h-48 mx-auto mt-10">
        <img
          className="w-full h-full object-cover object-center"
          src="/images/noclass.png"
          alt="img"
        />
      </div>
      <div className="text-center">
        <SectionTitle
          name="No active class yet! Send a reminder to your admin"
          color="blue"
        />
        <Button name="+ Remind" outline="true" color="blue" />
      </div>
    </>
  );
};

export default RemindAdmin;
