import { ContentContainer } from "../../../common/content/container";
import Days from "../../../elements/days/days";
import { CardBody, CardTitle, SectionTitle } from "../../../elements/text";
import Notification from "../../../elements/notification/notification";
import Item from "./item";
import { ImLab } from "react-icons/im";
import { BiBook } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsCardList } from "react-icons/bs";
import PageContent from "../pageContent";

import React, { useEffect, useState } from "react";
import axios from "../../../../axios-base";
import today from "../../../../functions/greetingsOnDay";
import giveNameRespect from "../../../../functions/giveNameRespect";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/"
import Spinner from "../../../elements/spinner";
import { useParams, useHistory } from "react-router-dom";
import { student_new_subjects_start, student_recommended_subjects_start, student_subject_specialization_start, student_suggested_features_start, student_trending_subjects_start, teacher_new_subjects_start, teacher_recommended_subjects_start, teacher_subject_specialization_start, teacher_suggested_features_start, teacher_trending_subjects_start } from "../../../../store/actions/student.dashboard.homepage";
import { shuffleSuggestedFeatures } from "../../../../functions/suggestedFeaturesUtils";
import { subjectSpecialization } from "../../../../functions/subjectSpecializationUtils";
import { trendingSubjects } from "../../../../functions/trendingSubjects";
import { newSubjects, recommendedSubjects } from "../../../../functions/newSubjects";
import Input from "../../../elements/input";
import constants from "../../../../constants/constants";

const Dashboard = ({ studentHomeDashboard, proposedFeatures }) => {
  //---Preload suggested features on the platform--
  const userAth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.token != null);
  const [selectedAcademiclevel, setSelectedAcademiclevel] = useState(1);
  const [selectedLevel, setSelectedLevel] = useState(`Senior ${userAth.academic_level}`);
  useEffect(() => {
    //----
    if (user !== null) {
      if (user?.user_type === constants.teacherusertype) {
        if (user?.isprofilecompleted === 1) {

      
        } else {
          history.push("/teacher/user/profile");
        }

      }
    } else {
      history.push("/");
    }
    setInterval(() => {
      if (user !== null) {
        if (user?.user_type === constants.teacherusertype) {
          if (user?.isprofilecompleted === 1) {
            

          } else {
             history.push("/teacher/user/profile");
          }
        }
      } else {
        history.push("/");
      }
    }, 8000);

  }, []);
  //---
  useEffect(() => {
    dispatch(
      teacher_suggested_features_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
      })
    );
    // Academic levels from 1 to 6 for the teacher's dashboard
    // Senior 1
    dispatch(
      teacher_subject_specialization_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: selectedAcademiclevel,
      })
    );
    dispatch(
      teacher_trending_subjects_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: selectedAcademiclevel,
      })
    );
    dispatch(
      teacher_new_subjects_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: selectedAcademiclevel,
      })
    );
    dispatch(
      teacher_recommended_subjects_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: selectedAcademiclevel,
      })
    );


  }, [isAuthenticated, dispatch, selectedAcademiclevel]);

  const features = [
    {
      name: "Lab Simulation",
      Icon: <ImLab color="#183F71" size="30" />,
      link: "/teacher/features/simulations",
    },
    {
      name: "View Courses",
      Icon: <BiBook color="#183F71" size="30" />,
      link: "/teacher/features/summarynotes/0/",
    },
    {
      name: "Challenge Peers",
      Icon: <HiOutlineUserGroup color="#183F71" size="30" />,
      link: "/teacher/features/challenges",
    },
    {
      name: "Past Papers",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/teacher/features/pastpapers",
    },
    {
      name: "Library",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/teacher/features/library",
    },
    {
      name: "Online class",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/teacher/features/onlineclasses",
    },
    {
      name: "More practice",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/teacher/features/practice",
    },
    {
      name: "My Classes",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/teacher/features/myclasses",
    },
    {
      name: "Tests",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/teacher/features/tests",
    },
  ];

  const shuffledFeatured = shuffleSuggestedFeatures(
    studentDashboardhomePage.proposedFeatures.grouped_activities
      ? studentDashboardhomePage.proposedFeatures.grouped_activities
      : [],
    features
  );

  let subjects = [
    {
      id: 1,
      subject: "Agriculture",
      imgsrc: "/images/preview/Agriculture.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-01T12:05:25.032Z").toString(),
    },
    {
      id: 2,
      subject: "ART AND CRAFTS",
      imgsrc:
        "/images/preview/Arts and Crafts.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 3,
      subject: "BIOLOGY",
      imgsrc: "/images/preview/biology.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 4,
      subject: "CHEMISTRY",
      imgsrc: "/images/preview/chem.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 5,
      subject: "COMPUTER SCIENCE",
      imgsrc: "/images/preview/Computer science.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 6,
      subject: "ECONOMICS",
      imgsrc:
        "/images/preview/Economics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 7,
      subject: "ELECTRICITY",
      imgsrc: "/images/preview/Electricity.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 8,
      subject: "ELECTRICITY FOR TVET",
      imgsrc: "/images/preview/Electricity for TVET.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 9,
      subject: "ELECTRONICS",
      imgsrc: "/images/preview/Electronics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 10,
      subject: "ELECTRONICS FOR TVET",
      imgsrc: "/images/preview/Electronics for TVET.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 11,
      subject: "English",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 12,
      subject: "ENTREPRENEURSHIP",
      imgsrc: "/images/preview/enterpreneurship.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 13,
      subject: "FINE ART AND CRAFTS",
      imgsrc:
        "/images/preview/Arts and Crafts.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 14,
      subject: "FRANÇAIS",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 15,
      subject: "GEOGRAPHY",
      imgsrc: "/images/preview/Geaography.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 16,
      subject: "Geography and Environment",
      imgsrc: "/images/preview/Geography and environment.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 17,
      subject: "HISTORY",
      imgsrc: "/images/preview/History.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 18,
      subject: "ICT",
      imgsrc:
        "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 19,
      subject: "Literature in English",
      imgsrc: "/images/preview/Literature in English.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 20,
      subject: "MACHINE ASSEMBLY",
      imgsrc: "/images/preview/Machine assembly.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 21,
      subject: "MATHEMATICS",
      imgsrc: "/images/preview/Mathematics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 22,
      subject: "PHYSICS",
      imgsrc: "/images/preview/Physics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 23,
      subject: "RELIGION AND ETHICS",
      imgsrc:
        "/images/preview/Religion and ethics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
  ];

  const subjects_specialized_level = subjectSpecialization(
    studentDashboardhomePage.subjectSpecializationPool
      ? studentDashboardhomePage.subjectSpecializationPool
      : [],
    subjects
  );

  const trending_subjects = trendingSubjects(
    studentDashboardhomePage.trendingsSubjects
      ? studentDashboardhomePage.trendingsSubjects
      : [],
    subjects
  );

  const new_subjects = newSubjects(
    studentDashboardhomePage.newsSubjects
      ? studentDashboardhomePage.newsSubjects
      : [],
    subjects
  );

  const recommended_subjects = recommendedSubjects(
    studentDashboardhomePage.recommendedSubjects
      ? studentDashboardhomePage.recommendedSubjects
      : [],
    subjects
  );
  return (
    <PageContent>
      <div className="w-full h-full flex flex-col flex-col-reverse md:flex-row justify-between p-3 md:space-x-4 max-h-myscreen overflow-auto scrollbar-thin">
        <div className="md:w-70%">
          <div>
            <div className="flex justify-center md:justify-start items-center space-x-4">
              <div className="md:hidden bg-white h-20 w-52 flex-auto rounded-full border border-gray-500"></div>
              <div>
                {
                  <SectionTitle
                    name={`${today()}, ${giveNameRespect(userAth?.username)}`}
                  />
                }
                <div className="md:hidden">
                  <CardBody name="Duis mollit nostrud nisi irure ea sit mollit sit laborum aliqua eu exercitation do nulla." />
                </div>
              </div>
            </div>
            <Days />
            <div className="md:mx-4">
              <CardTitle name="What do you want to do today?" />
              {!studentDashboardhomePage.proposedFeatures_loading ? (
                <div className="flex flex-wrap">
                  {shuffledFeatured.map((feature, index) => (
                    <Item key={index} {...feature}>
                      {feature.Icon}
                    </Item>
                  ))}
                </div>
              ) : (
                <Spinner size="20" color="blue" />
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="flex justify-between items-center w-32">
              <Input
                label="Choose class level"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  // disabled: "disabled",
                  options: [
                    { value: "1", displayValue: "Senior 1" },
                    { value: "2", displayValue: "Senior 2" },
                    { value: "3", displayValue: "Senior 3" },
                    { value: "4", displayValue: "Senior 4" },
                    { value: "5", displayValue: "Senior 5" },
                    { value: "6", displayValue: "Senior 6" },
                  ],
                }}
                value={selectedLevel}
                changed={setSelectedLevel}
                setSelectedAcademiclevel={setSelectedAcademiclevel}
                validation={{ required: true }}
                shouldValidate
                error="Level is required"
              />
            </div>
            {!studentDashboardhomePage.subjectSpecializationPool_loading ? (
              subjects_specialized_level.length > 0 ? (
                <ContentContainer
                  name="Subject Specialization"
                  data={subjects_specialized_level}
                  selectedAcademiclevel={selectedAcademiclevel}
                  slider={true}
                  type="one"
                />
              ) : (
                <div></div>
              )
            ) : subjects_specialized_level.length > 0 ? (
              <Spinner size="20" color="blue" />
            ) : (
              <div></div>
            )}

            {!studentDashboardhomePage.trendingsSubjects_loading ? (
              trending_subjects.length > 0 ? (
                <ContentContainer
                  name="Trending"
                  data={trending_subjects}
                  selectedAcademiclevel={selectedAcademiclevel}
                  slider={true}
                  type="one"
                />
              ) : (
                <div></div>
              )
            ) : trending_subjects.length > 0 ? (
              <Spinner size="20" color="blue" />
            ) : (
              <div></div>
            )}
            {!studentDashboardhomePage.newSubjects_loading ? (
              new_subjects.length > 0 ? (
                <ContentContainer
                  name="New"
                  data={new_subjects}
                  selectedAcademiclevel={selectedAcademiclevel}
                  slider={true}
                  type="one"
                />
              ) : (
                <div></div>
              )
            ) : new_subjects.length > 0 ? (
              <Spinner size="20" color="blue" />
            ) : (
              <div></div>
            )}

            {!studentDashboardhomePage.recommendedSubjects_loading ? (
              recommended_subjects.length > 0 ? (
                <ContentContainer
                  name="Recommended"
                  data={recommended_subjects}
                  selectedAcademiclevel={selectedAcademiclevel}
                  slider={true}
                  type="one"
                />
              ) : (
                <div></div>
              )
            ) : recommended_subjects.length > 0 ? (
              <Spinner size="20" color="blue" />
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="md:w-30%">
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default Dashboard;
