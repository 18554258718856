import { useEffect, useState } from 'react';
import PageContent from '../pageContent';
import { FeatureTitle } from '../../../elements/text';
import { Button, GoBackButton } from '../../../elements/button';
import Input from '../../../elements/input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RichTextEditor from '../../../elements/RichTextEditor';
import date from 'date-and-time';
import constants, { validationNotification } from '../../../../constants/constants';
import { addQuestionToPastPaper, removeQuestionToPastPaper, savePastPaperAsDraft } from '../../../../store/actions/features/teacher/pastpapers';
import Spinner from '../../../elements/spinner';
import { uploadFilesInText } from '../../../../store/actions/files';
import parse from 'react-html-parser';
import Page from './Page';
import { useParams } from 'react-router-dom';

const NewPastPaper = () => {
  const [section, setSection] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [index, setIndex] = useState(0);
  const [ddd, setddd] = useState('');
  const history = useHistory();
  const [counter, setCounter] = useState(1);
  const dispatch = useDispatch();
  const pastpaper = useSelector(
    (state) => state.features.teacherpastpapers.pastpaper
  );
  const pastpapersLoading = useSelector(
    (state) => state.features.teacherpastpapers.pastpapersLoading
  );
  const [questions, setQuestions] = useState([{ question: "", answer: "" }]);
  const auth = useSelector((state) => state.auth);
  const courses_params = useParams();

  const addQuestionToArray = () => {

    if (question === "" || answer === "") {
      alert("fill question data");
    } else {
      const answerdata = constants.separateTextAndFiles("answerdata");
      console.log("answerdata: ", answerdata);
      answerdata.files.forEach((element) => {
        dispatch(uploadFilesInText(element))
      });
      const questiondata = constants.separateTextAndFiles("questiondata");
      console.log("questiondata: ", questiondata);
      questiondata.files.forEach((element) => {
        dispatch(uploadFilesInText(element))
      });
      const questionToAdd = {
        question: questiondata, answer: answerdata
      }
      // Save data to question
      const questionData = [...questions];
      questionData.push({ question: "", answer: "" });
      setQuestions(questionData);
      console.log("@#$$$$", questionToAdd)
      setQuestion("");
      setAnswer("");
    }
  }

  const saveAsDraft = () => {
    if (questions.length <= 0) {
      alert("There are no questions to save");
    } else {
      const objects = {
        pastpaper,
        questions,
        publishedValue: false,
        userId: auth?.userId,
      }
      dispatch(savePastPaperAsDraft(objects, auth?.token));
    }
  }

  const saveAndPublish = () => {
    if (questions.length <= 0) {
      alert("There are no questions to save");
    } else {
      const objects = {
        pastpaper,
        questions,
        publishedValue: false,
        userId: auth?.userId,
      }
      dispatch(savePastPaperAsDraft(objects, auth?.token));
    }
  }

  const removeQuestion = (index) => {
    if (index === "") {
      alert(`Select a question to remove`);
    } else {
      //  alert(`Index: ${index}`); 
      const kk = questions.filter(data => data.index !== index)
      console.log("REMOV: ", kk);
      console.log("REMOV2: ", kk);
      dispatch(removeQuestionToPastPaper(questions, index));
      setIndex("");
    }

  }

  const selectQuestion = (a) => {
    setAnswer(questions[a].answer);
    setQuestion(questions[a].question);
    console.log(questions[a].answer, questions[a].question)
  }

  useEffect(() => {
  }, [questions])

  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full h-full`}>
        <div
          className={`md:relative p-3 bg-white h-full min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <FeatureTitle name="Past Paper" />
          <GoBackButton
            link="/teacher/features/pastpapers"
            action="Back"
            location="Past papers"
          />
          <>
            {pastpapersLoading ? (
              <Spinner color={"blue"} size={32} />
            ) : (
              <>
                <div className="flex justify-between items-center">
                  {<Page p_id={(courses_params?.p_id) ? courses_params?.p_id : 0} key={getRandomNumber(1, 100)} pastpaper={pastpaper} />}
                </div>
              </>)}
          </>
        </div>
      </div>
    </PageContent>
  );
};

export default NewPastPaper;
