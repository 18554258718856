import { useSelector, useDispatch } from "react-redux";
import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_FAILED,
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_FAILED,

  STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_FAILED,

  STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_FAILED,

  STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_FAILED,

  STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_FAILED,

  STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_FAILED,

  STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_FAILED,

} from "../actions/student.dashboard.homepage";
const initialReducer = {
  proposedFeatures: [],
  proposedFeatures_loading: true,
  message: "",
  subjectSpecializationPool: [],
  message0: "",
  subjectSpecializationPool_loading: true,

  trendingsSubjects: [],
  message1: "",
  trendingsSubjects_loading: true,

  newsSubjects: [],
  message2: "",
  newSubjects_loading: true,

  recommendedSubjects: [],
  message3: "",
  recommendedSubjects_loading: true,

  questionOfTheDay: [],
  message4: "",
  questionOfTheDay_loading: true,

  answer_questionOfTheDay: [],
  message5: "",
  answer_questionOfTheDay_loading: false,
  questionOfTheDayAnswered: false,

  stats_of_last_week: [],
  message6: "",
  stats_of_last_week_loading: true,
};

function student_suggested_features_init(state, action) {
  return updateObject(state, {
    proposedFeatures_loading: true,
  });
}
function student_suggested_features_success(state, action) {
  return updateObject(state, {
    proposedFeatures: action.payload,
    proposedFeatures_loading: false,
    message: "success"
  });
}
function student_suggested_features_fail(state, action) {
  return updateObject(state, {
    message: action.payload,
    proposedFeatures_loading: true,
  });
}
//----Subject specialization--
function student_subject_specialization_init(state, action) {
  return updateObject(state, {
    subjectSpecializationPool_loading: true
  });
}
function student_subject_specialization_success(state, action) {

  return updateObject(state, {
    subjectSpecializationPool: action.payload,
    message0: "success",
    subjectSpecializationPool_loading: false,
  });
}
function student_subject_specialization_fail(state, action) {
  return updateObject(state, {
    message0: action.payload,
    subjectSpecializationPool_loading: true
  })
}
//----trending subjects--
function student_trending_subjects_init(state, action) {
  return updateObject(state, {
    trendingsSubjects_loading: true
  });
}
function student_trending_subjects_success(state, action) {

  return updateObject(state, {
    trendingsSubjects: action.payload,
    message1: "success",
    trendingsSubjects_loading: false,
  });
}
function student_trending_subjects_fail(state, action) {
  return updateObject(state, {
    message0: action.payload,
    trendingsSubjects_loading: true
  })
}
//----NEW subjects--
function student_new_subjects_init(state, action) {
  return updateObject(state, {
    newSubjects_loading: true
  });
}
function student_new_subjects_success(state, action) {

  return updateObject(state, {
    newsSubjects: action.payload,
    message2: "success",
    newSubjects_loading: false,
  });
}
function student_new_subjects_fail(state, action) {
  return updateObject(state, {
    message0: action.payload,
    newSubjects_loading: true
  })
}
//----recommended subjects units--
function student_recommended_subjects_init(state, action) {
  return updateObject(state, {
    recommendedSubjects_loading: true
  });
}
function student_recommended_subjects_success(state, action) {


  return updateObject(state, {
    recommendedSubjects: action.payload,
    message3: "success",
    recommendedSubjects_loading: false,
  });
}
function student_recommended_subjects_fail(state, action) {
  return updateObject(state, {
    message3: action.payload,
    recommendedSubjects_loading: true
  })
}

//----Question of the day---
function student_question_of_day_init(state, action) {
  return updateObject(state, {
    questionOfTheDay_loading: true
  });
}
function student_question_of_day_success(state, action) {


  return updateObject(state, {
    questionOfTheDay: action.payload,
    message4: "success",
    questionOfTheDay_loading: false,
  });
}
function student_question_of_day_fail(state, action) {
  return updateObject(state, {
    message4: action.payload,
    questionOfTheDay_loading: true
  })
}
//----Answer Question of the day---
function student_answer_question_of_day_init(state, action) {
  return updateObject(state, {
    answer_questionOfTheDay_loading: true,
    answer_questionOfTheDay: []
  });
}
function student_answer_question_of_day_success(state, action) {


  return updateObject(state, {
    answer_questionOfTheDay: action.payload,
    message5: "success",
    answer_questionOfTheDay_loading: false,
    questionOfTheDayAnswered: true
  });
}
function student_answer_question_of_day_fail(state, action) {
  return updateObject(state, {
    message5: action.payload,
    answer_questionOfTheDay:[],
    answer_questionOfTheDay_loading: false,
    questionOfTheDayAnswered: false
  })
}
//----Stats of the last week---
function student_stats_of_last_week_init(state, action) {
  return updateObject(state, {
    stats_of_last_week_loading: true,
  });
}
function student_stats_of_last_week_success(state, action) {


  return updateObject(state, {
    stats_of_last_week: action.payload,
    message6: "success",
    stats_of_last_week_loading: false,
  });
}
function student_stats_of_last_week_fail(state, action) {
  return updateObject(state, {
    message6: action.payload,
    stats_of_last_week_loading: false,
  })
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_INIT:
      return student_suggested_features_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_SUCCESS:
      return student_suggested_features_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_FAILED:
      return student_suggested_features_fail(state, action);
    //---subject specialization
    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_INIT:
      return student_subject_specialization_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_SUCCESS:
      return student_subject_specialization_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_FAILED:
      return student_subject_specialization_fail(state, action);
    //----TRENDING SUBJECTS---------------
    case STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_INIT:
      return student_trending_subjects_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_SUCCESS:
      return student_trending_subjects_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_FAILED:
      return student_trending_subjects_fail(state, action);
    //----NEW SUBJECTS---------------
    case STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_INIT:
      return student_new_subjects_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_SUCCESS:
      return student_new_subjects_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_FAILED:
      return student_new_subjects_fail(state, action);
    //----RECOMMENDED SUBJECTS---------------
    case STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_INIT:
      return student_recommended_subjects_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_SUCCESS:
      return student_recommended_subjects_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_FAILED:
      return student_recommended_subjects_fail(state, action);
    //----QUESTION OF THE DAY---------------
    case STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_INIT:
      return student_question_of_day_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_SUCCESS:
      return student_question_of_day_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_FAILED:
      return student_question_of_day_fail(state, action);
    //----ANSWER A QUESTION OF THE DAY---------------
    case STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_INIT:
      return student_answer_question_of_day_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_SUCCESS:
      return student_answer_question_of_day_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_FAILED:
      return student_answer_question_of_day_fail(state, action);

    //----STATS_OF_LAST_WEEK---------------
    case STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_INIT:
      return student_stats_of_last_week_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_SUCCESS:
      return student_stats_of_last_week_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_FAILED:
      return student_stats_of_last_week_fail(state, action);
    default:
      return state;
  }
};
export default reducer;
