import axios from "../../axios-base";
import { getCachedData } from "../../shared/caching";


export const STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_FAILED";

export function student_pastpapers_subjects_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_INIT
  };
}
export function student_pastpapers_subjects_success(data) {


  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_SUCCESS,
    payload: data
  };
}
export function student_pastpapers_subjects_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_FAILED,
    payload: error
  };
}

export function student_pastpapers_subjects_start(data) {


  return (dispatch) => {
    dispatch(student_pastpapers_subjects_init());
    getCachedData('pastpapers-cache', `/opanda/pastpapers`, 'POST', 10800,
     {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.token}`,
    },{
      user_id: data.user_id,
      academic_level: data.academic_level
    }
    )
      .then((response) => {

        dispatch(student_pastpapers_subjects_success(response.results));
      })
      .catch((err) => {
        dispatch(student_pastpapers_subjects_fail(err.message));
      });
  };
}

export function teacher_pastpapers_subjects_start(data) {


  return (dispatch) => {
    dispatch(student_pastpapers_subjects_init());
    getCachedData('pastpapers-cache', `/opanda/pastpapers_by_teacher`, 'POST', 10,
     {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.token}`,
    },{
      user_id: data.user_id,
      academic_level: data.academic_level
    }
    )
      .then((response) => {

        dispatch(student_pastpapers_subjects_success(response.results));
      })
      .catch((err) => {
        dispatch(student_pastpapers_subjects_fail(err.message));
      });
  };
}
//---Record viewership data for the pastpaper
export const STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_FAILED";

export function student_pastpapers_viewership_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_INIT
  };
}
export function student_pastpapers_viewership_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_SUCCESS,
    payload: data
  };
}
export function student_pastpapers_viewership_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_FAILED,
    payload: error
  };
}

export function student_pastpapers_viewership_start(data) {
  return (dispatch) => {
    dispatch(student_pastpapers_viewership_init());
    axios
      .post(
        `/opanda/pastpapers/view`,
        {
          username: data.username,
          userId: data.userId,
          user_type:data.user_type,
          academic_level:data.academic_level,
          item_id:data.item_id,
          subject:data.subject
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_pastpapers_viewership_success(response));
      })
      .catch((err) => {
        dispatch(student_pastpapers_viewership_fail(err.message));
      });
  };
}