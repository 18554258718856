import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import HamburgerMenu from "../../../../images/icons/hamburger.svg";
import { ButtonNav, ButtonWithIcon } from "../../../elements/button";
import { store } from "../../../../store/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as actions from "../../../../store/actions/";
const TopNavBar = function (props) {
  const { t } = useTranslation();
  const { showsidebar, changetab } = props;
  const modules = useSelector((state) => state.previewpanda.modules);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getOGeniusModules());
  }, [dispatch]);
  return (
    <div className="w-screen min-h-24 h-12 overflow-x-auto md:hidden">
      <div className="w-full space-x-1 flex justify-center flex-no-wrap my-2">
        <ButtonWithIcon
          name={t("Menu")}
          src={HamburgerMenu}
          outline="true"
          isSmall={true}
          color="blue"
          onClick={showsidebar}
        />
      </div>
    </div>
  );
};

// export default SideBar;
const mapStateToProps = (state) => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showsidebar: () => store.dispatch({ type: "SHOW_SIDEBAR" }),
    hidesidebar: () => store.dispatch({ type: "HIDE_SIDEBAR" }),
    changetab: (tab) => store.dispatch({ type: "CHANGE_TAB", payload: tab }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar);
