import Header from '../components/common/header/header';
import Footer from '../components/common/footer';
import Recoverpasswordquestionform from '../components/pages/landing/forgotpassword/recoverpasswordquestionsform';
import ScrollToTop from '../components/common/ScrollToTop';

const Forgotpasswordquestions = () => {
  return (
    <div>
      <Header />
      <Recoverpasswordquestionform />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Forgotpasswordquestions;
