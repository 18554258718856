import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED,
  //----Get The Selected Unit With linked labs, docs and videos---_get_selected_unit_with_labs_docs_videos----------
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_INIT,
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_SUCCESS,
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_FAILED,
  //-----Send the comment on the selected content in summary notes--
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_INIT,
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_SUCCESS,
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FAILED,
  //-----first load the comment on the selected content in summary notes--
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_INIT,
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_SUCCESS,
  STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_FAILED,


  
  
} from "../actions/student.dashboard.curriculum.cbc";
const initialReducer = {
  cbc_subjects_units: [],
  cbc_subjects_units_loading: true,
  message: "",
  //----Get The Selected Unit With linked labs, docs and videos-------------
  get_selected_unit_with_labs_docs_videos: [],
  get_selected_unit_with_labs_docs_videos_loading: false,
  message0: "",
  //-----Send the comment on the selected content in summary notes--
  get_selected_unit_with_labs_docs_videos_comment: [],
  get_selected_unit_with_labs_docs_videos_comment_loading: false,
  message1: "",
};

function student_subjects_by_academic_units_init(state, action) {
  return updateObject(state, {
    cbc_subjects_units_loading: true,
  });
}
function student_subjects_by_academic_units_success(state, action) {
  return updateObject(state, {
    cbc_subjects_units: action.payload,
    cbc_subjects_units_loading: false,
    message: "success"
  });
}
function student_subjects_by_academic_units_fail(state, action) {
  return updateObject(state, {
    message: action.payload,
    cbc_subjects_units_loading: false,
  });
}
//----Get The Selected Unit With linked labs, docs and videos-------------
function student_get_selected_unit_with_labs_docs_videos_init(state, action) {
  return updateObject(state, {
    get_selected_unit_with_labs_docs_videos_loading: true,
  });
}
function student_get_selected_unit_with_labs_docs_videos_success(state, action) {
  return updateObject(state, {
    get_selected_unit_with_labs_docs_videos: action.payload,
    get_selected_unit_with_labs_docs_videos_loading: false,
    message0: "success"
  });
}
function student_get_selected_unit_with_labs_docs_videos_fail(state, action) {
  return updateObject(state, {
    message0: action.payload,
    get_selected_unit_with_labs_docs_videos_loading: false,
  });
}
 //-----Send the comment on the selected content in summary notes--
 function student_get_selected_unit_with_labs_docs_videos_comment_init(state, action) {
  return updateObject(state, {
    get_selected_unit_with_labs_docs_videos_comment_loading: true,
  });
}
function student_get_selected_unit_with_labs_docs_videos_comment_success(state, action) {
  return updateObject(state, {
    get_selected_unit_with_labs_docs_videos_comment: action.payload,
    get_selected_unit_with_labs_docs_videos_comment_loading: false,
    message1: "success"
  });
}
function student_get_selected_unit_with_labs_docs_videos_comment_fail(state, action) {
  return updateObject(state, {
    message1: action.payload,
    get_selected_unit_with_labs_docs_videos_comment_loading: false,
  });
}
 //-----first load the comment on the selected content in summary notes--
 function student_get_selected_unit_with_labs_docs_videos_comment_first_load_init(state, action) {
  return updateObject(state, {
    get_selected_unit_with_labs_docs_videos_comment_loading: true,
  });
}
function student_get_selected_unit_with_labs_docs_videos_comment_first_load_success(state, action) {
  return updateObject(state, {
    get_selected_unit_with_labs_docs_videos_comment: action.payload,
    get_selected_unit_with_labs_docs_videos_comment_loading: false,
    message1: "success"
  });
}
function student_get_selected_unit_with_labs_docs_videos_comment_first_load_fail(state, action) {
  return updateObject(state, {
    message1: action.payload,
    get_selected_unit_with_labs_docs_videos_comment_loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT:
      return student_subjects_by_academic_units_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS:
      return student_subjects_by_academic_units_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED:
      return student_subjects_by_academic_units_fail(state, action);  
    //----Get The Selected Unit With linked labs, docs and videos-------------
    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_INIT:
      return student_get_selected_unit_with_labs_docs_videos_init(state, action);

    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_SUCCESS:
      return student_get_selected_unit_with_labs_docs_videos_success(state, action);
    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_FAILED:
      return student_get_selected_unit_with_labs_docs_videos_fail(state, action); 
    //-----Send the comment on the selected content in summary notes--
    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_INIT:
      return student_get_selected_unit_with_labs_docs_videos_comment_init(state, action);

    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_SUCCESS:
      return student_get_selected_unit_with_labs_docs_videos_comment_success(state, action);
    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FAILED:
      return student_get_selected_unit_with_labs_docs_videos_comment_fail(state, action); 
    //-----first load the comment on the selected content in summary notes--
    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_INIT:
      return student_get_selected_unit_with_labs_docs_videos_comment_first_load_init(state, action);

    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_SUCCESS:
      return student_get_selected_unit_with_labs_docs_videos_comment_first_load_success(state, action);
    case STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_FAILED:
      return student_get_selected_unit_with_labs_docs_videos_comment_first_load_fail(state, action); 
    default:
      return state;
  }
};
export default reducer;
