import Feedback from '../../../components/pages/school/user/feedback/index';
import Layout from '../../../components/common/Layout';

const TeacherFeedbackContainer = () => {
  return (
    <Layout>
      <Feedback />
    </Layout>
  );
};

export default TeacherFeedbackContainer;