import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_FAILED,
  //------------------------
  STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_FAILED,

} from "../actions/student.dashboard.pastpapers";
const initialReducer = {
  pastpapers_subjects: [],
  pastpapers_subjects_loading: false,
  message: "",
  //---
  pastpapers_viewership: [],
  pastpapers_viewership_loading: false,
  message0: "",
};

function student_pastpapers_subjects_init(state, action) {
  return updateObject(state, {
    pastpapers_subjects_loading: true,
  });
}
function student_pastpapers_subjects_success(state, action) {
  return updateObject(state, {
    pastpapers_subjects: action.payload,
    pastpapers_subjects_loading: false,
    message: "success"
  });
}
function student_pastpapers_subjects_fail(state, action) {
  return updateObject(state, {
    message: action.payload,
    pastpapers_subjects_loading: false,
  });
}
//--------------------------
function student_pastpapers_viewership_init(state, action) {
  return updateObject(state, {
    pastpapers_viewership_loading: true,
  });
}
function student_pastpapers_viewership_success(state, action) {
  return updateObject(state, {
    pastpapers_viewership: action.payload,
    pastpapers_viewership_loading: false,
    message0: "success"
  });
}
function student_pastpapers_viewership_fail(state, action) {
  return updateObject(state, {
    message0: action.payload,
    pastpapers_viewership_loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_INIT:
      return student_pastpapers_subjects_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_SUCCESS:
      return student_pastpapers_subjects_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_SUBJECTS_FAILED:
      return student_pastpapers_subjects_fail(state, action);  
    //--------
    case STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_INIT:
      return student_pastpapers_viewership_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_SUCCESS:
      return student_pastpapers_viewership_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_PASTPAPERS_VIEWERSHIP_FAILED:
      return student_pastpapers_viewership_fail(state, action); 
    
    default:
      return state;
  }
};
export default reducer;
