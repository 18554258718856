import { useState } from 'react';
import { CardTitle, SmallText } from '../../../elements/text';
import parse from 'react-html-parser';
import { CgChevronDownR, CgChevronUpR } from 'react-icons/cg';

const QuestionItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col space-x-2 p-2">
      <CardTitle name={`Question ${props.number}`} />
      {(typeof props.question === "string") ?  parse(props.question) : props.question}
      <div className="w-full flex justify-between items-center border-b border-gray-500">
        <SmallText name="Answer" additional="font-semibold" />
        {isOpen ? (
          <CgChevronUpR
            size={12}
            color="#183F71"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          />
        ) : (
          <CgChevronDownR
            size={12}
            color="#183F71"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          />
        )}
      </div>
      <div className={isOpen ? '' : 'hidden'}>{(typeof props.answer === "string") ?  parse(props.answer) : props.answer}</div>
    </div>
  );
};

export default QuestionItem;
