import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
const SinglechoiceAnswerOptionGroup = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [isAnswer, setIsAnswer] = useState([]);
    const [UpdateUI, setUpdateUI] = useState(false);

    useEffect(() => {
    }, [props.singleChoicePicks]);

    const pickAnswer = (val, qnber, questionId, event) => {
        props.markSelectedMultipleChoiceQuestion(qnber, val, "single", questionId);
        setUpdateUI(!UpdateUI);

    }

    const renderCheckedStatus = (questionNber, answerId, collectedAnswers) => {
        for (var i = 0; i < collectedAnswers.length; i++) {
            if ((parseInt(collectedAnswers[i].question_number) === parseInt(questionNber + "")) && (parseInt(collectedAnswers[i].answer_number) === parseInt(answerId + ""))) {
                return true;
            }
        }
        return false;
    }
    return (
        <div><form>
            <fieldset>
                {answers.map((item, i) => {
                    return <div>
                        <table>
                            <tr>
                                <td>{(renderCheckedStatus(props.questionnber, item.panda_tests_questions_answers_id, props.singleChoicePicks)) ? <input checked={true} onClick={(e) => pickAnswer(e.target.value, props.questionnber, props.id, e)} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type="radio" name={`answer--${item.panda_tests_questions_answers_question_id}`} value={`${item.panda_tests_questions_answers_id}`} />
                                    : <input checked={false} onClick={(e) => pickAnswer(e.target.value, props.questionnber, props.id, e)} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type="radio" name={`answer--${item.panda_tests_questions_answers_question_id}`} value={`${item.panda_tests_questions_answers_id}`} />}</td>
                                <td>{parse(item.panda_tests_questions_answers_data)}</td>
                            </tr>
                        </table>
                    </div>
                })}</fieldset>
        </form>
        </div>
    );
};
export default SinglechoiceAnswerOptionGroup;
