import { useState } from 'react';
import parse from 'react-html-parser';
import { useEffect } from 'react';
import RichTextEditor from '../../../../../../../elements/RichTextEditor';
import { CardTitle } from '../../../../../../../elements/text';
const OpenEnded = (props) => {
  const question = props?.questiondata?.question.panda_open_ended_2020_03_question_data;
  const [answer, setAnswer] = useState('');


  useEffect(() => {
    //-- Check if previous answer exists--

    setAnswer("");
  }, []);


  return (
    <>
      {question !== undefined ? parse(question) : parse('<p></p>')}
      <CardTitle name="The right answer will be provided by the teacher!" color="#FFFAFA" />
      {/*<RichTextEditor
        label="Answer"
        id={`OpenEnded${props.questionId}`}
        value={answer}
        onChange={(text) => getDataOnChange(text)}
        placeholder="Write your answer here"
  />*/}
    </>
  );
};
export default OpenEnded;
