import { useDrag } from 'react-dnd';

const MatchingAnswerCard = (props) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "anwers",
    item: { id: props.name },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  let color = 'bg-blue text-center px-2 py-1 text-white';
  if (isDragging) {
    color = 'bg-blue text-center border-4 px-2 py-1 text-white';

  }
  return (
    <span ref={drag}   id={props.id} className={color}>
      {props.name}
    </span>
  );
};
export default MatchingAnswerCard;
