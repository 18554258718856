import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getHomeCarouselsStart = () => {
  return {
    type: actionTypes.GET_HOME_CAROUSEL_START,
  };
};

export const getHomeCarouselsSuccess = (homecarousels) => {
  return {
    type: actionTypes.GET_HOME_CAROUSEL_SUCCESS,
    homecarousels: homecarousels,
  };
};

export const getOneHomeCarouselsSuccess = (homecarousel) => {
  return {
    type: actionTypes.GET_ONE_HOME_CAROUSEL_SUCCESS,
    homecarousel: homecarousel,
  };
};

export const getHomeCarouselsFail = (error) => {
  return {
    type: actionTypes.GET_HOME_CAROUSEL_FAIL,
    error: error,
  };
};

export const getHomeCarousel = () => {
  return (dispatch) => {
    dispatch(getHomeCarouselsStart());
    axios
      .get(`/opanda/home-carousels/active`)
      .then((response) => {
        dispatch(getOneHomeCarouselsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeCarouselsFail(err.message));
      });
  };
};

export const getHomeWhoIsOpandaForStart = () => {
  return {
    type: actionTypes.GET_HOME_WHO_IS_OPANDA_FOR_START,
  };
};

export const getHomeWhoIsOpandaForSuccess = (whoisopandafor) => {
  return {
    type: actionTypes.GET_HOME_WHO_IS_OPANDA_FOR_SUCCESS,
    whoisopandafor: whoisopandafor,
  };
};

export const getOneHomeWhoIsOpandaForSuccess = (whoisopanda) => {
  return {
    type: actionTypes.GET_ONE_HOME_WHO_IS_OPANDA_FOR_SUCCESS,
    whoisopanda: whoisopanda,
  };
};

export const getHomeWhoIsOpandaForFail = (error) => {
  return {
    type: actionTypes.GET_HOME_WHO_IS_OPANDA_FOR_FAIL,
    error: error,
  };
};

export const getHomeWhoIsOpandaFor = () => {
  return (dispatch) => {
    dispatch(getHomeWhoIsOpandaForStart());
    axios
      .get(`/opanda/whois-ogenius-panda-for/active`)
      .then((response) => {
        dispatch(getHomeWhoIsOpandaForSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeWhoIsOpandaForFail(err.message));
      });
  };
};

export const getHomeHowItWorksStart = () => {
  return {
    type: actionTypes.GET_HOME_HOW_IT_WORKS_START,
  };
};

export const getHomeHowItWorksSuccess = (howitworks) => {
  return {
    type: actionTypes.GET_HOME_HOW_IT_WORKS_SUCCESS,
    howitworks: howitworks,
  };
};

export const getOneHomeHowItWorksSuccess = (howitwork) => {
  return {
    type: actionTypes.GET_ONE_HOME_HOW_IT_WORKS_SUCCESS,
    howitwork: howitwork,
  };
};

export const getHomeHowItWorksFail = (error) => {
  return {
    type: actionTypes.GET_HOME_HOW_IT_WORKS_FAIL,
    error: error,
  };
};

export const getHomeHowItWork = () => {
  return (dispatch) => {
    dispatch(getHomeHowItWorksStart());
    axios
      .get(`/opanda/howitworks/active`)
      .then((response) => {
        dispatch(getOneHomeHowItWorksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeHowItWorksFail(err.message));
      });
  };
};

export const getHomePartnersStart = () => {
  return {
    type: actionTypes.GET_HOME_PARTNERS_START,
  };
};

export const getHomePartnersSuccess = (partners) => {
  return {
    type: actionTypes.GET_HOME_PARTNERS_SUCCESS,
    partners: partners,
  };
};

export const getOneHomePartnersSuccess = (partner) => {
  return {
    type: actionTypes.GET_ONE_HOME_PARTNERS_SUCCESS,
    partner: partner,
  };
};

export const getHomePartnersFail = (error) => {
  return {
    type: actionTypes.GET_HOME_PARTNERS_FAIL,
    error: error,
  };
};

export const getHomePartners = () => {
  return (dispatch) => {
    dispatch(getHomePartnersStart());
    axios
      .get(`/opanda/partners`)
      .then((response) => {
        dispatch(getHomePartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomePartnersFail(err.message));
      });
  };
};

export const getHomeImpactsStart = () => {
  return {
    type: actionTypes.GET_HOME_IMPACTS_START,
  };
};

export const getHomeImpactsSuccess = (impacts) => {
  return {
    type: actionTypes.GET_HOME_IMPACTS_SUCCESS,
    impacts: impacts,
  };
};

export const getOneHomeImpactsSuccess = (impact) => {
  return {
    type: actionTypes.GET_ONE_HOME_IMPACTS_SUCCESS,
    impact: impact,
  };
};

export const getHomeImpactsFail = (error) => {
  return {
    type: actionTypes.GET_HOME_IMPACTS_FAIL,
    error: error,
  };
};

export const getHomeImpacts = () => {
  return (dispatch) => {
    dispatch(getHomeImpactsStart());
    axios
      .get(`/opanda/impacts`)
      .then((response) => {
        dispatch(getHomeImpactsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeImpactsFail(err.message));
      });
  };
};

export const getHomeValuePropositionsStart = () => {
  return {
    type: actionTypes.GET_HOME_VALUE_PROPOSITIONS_START,
  };
};

export const getHomeValuePropositionsSuccess = (valuepropositions) => {
  return {
    type: actionTypes.GET_HOME_VALUE_PROPOSITIONS_SUCCESS,
    valuepropositions: valuepropositions,
  };
};

export const getOneHomeValuePropositionsSuccess = (valueproposition) => {
  return {
    type: actionTypes.GET_ONE_HOME_VALUE_PROPOSITIONS_SUCCESS,
    valueproposition: valueproposition,
  };
};

export const getHomeValuePropositionsFail = (error) => {
  return {
    type: actionTypes.GET_HOME_VALUE_PROPOSITIONS_FAIL,
    error: error,
  };
};

export const getHomeValuePropositions = () => {
  return (dispatch) => {
    dispatch(getHomeValuePropositionsStart());
    axios
      .get(`/opanda/value-propositions/active`)
      .then((response) => {
        dispatch(getOneHomeValuePropositionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeValuePropositionsFail(err.message));
      });
  };
};

export const getHomeTestimoniesStart = () => {
  return {
    type: actionTypes.GET_HOME_USERS_TESTIMONIES_START,
  };
};

export const getHomeTestimoniesSuccess = (testimonies) => {
  return {
    type: actionTypes.GET_HOME_USERS_TESTIMONIES_SUCCESS,
    testimonies: testimonies,
  };
};

export const getOneHomeTestimoniesSuccess = (testimony) => {
  return {
    type: actionTypes.GET_ONE_HOME_USERS_TESTIMONIES_SUCCESS,
    testimony: testimony,
  };
};

export const getHomeTestimoniesFail = (error) => {
  return {
    type: actionTypes.GET_HOME_USERS_TESTIMONIES_FAIL,
    error: error,
  };
};

export const getHomeTestimonies = () => {
  return (dispatch) => {
    dispatch(getHomeTestimoniesStart());
    axios
      .get(`/opanda/users-testimonies/active`)
      .then((response) => {
        dispatch(getHomeTestimoniesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeTestimoniesFail(err.message));
      });
  };
};
