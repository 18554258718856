import PageContent from '../pageContent';
import Notification from '../../../elements/notification/notification';
import { TabPanel, useTabs } from 'react-headless-tabs';
import { useState } from 'react';
import TabSelector from '../../../elements/tab/TabSelector';
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SmallText,
} from '../../../elements/text';
import Input from '../../../elements/input';
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import MyclassesGroupItems from './MyclassesGroupItems';

const Myclasses = () => {
  const [selectedTab, setSelectedTab] = useTabs(
    ['Activities', 'Scheduled sessions', 'Community sessions'],
    'Activities'
  );
  const [filterBy, setFilterBy] = useState('subject');

  const activities = [
    {
      name: 'Homework',
      value: 1,
      color: 'red',
      hexColor: '#BE1E2D',
    },
    {
      name: 'Courses units',
      value: 3,
      color: 'blue',
      hexColor: '#183F71',
    },
    {
      name: 'Test',
      value: 5,
      color: 'yellow',
      hexColor: '#E8AD26',
    },
  ];
  const goals = [
    {
      name: 'Pass unit tests',
      level: 'S2B',
      subjects: [
        { name: 'Math', unit: 1 },
        { name: 'Biology', unit: 5 },
        { name: 'Chemistry', unit: 6 },
        { name: 'English', unit: 3 },
        { name: 'Physics', unit: 4 },
        { name: 'Geography', unit: 2 },
      ],
      createdAt: '2022-03-10',
    },
    {
      name: 'Do all challenges',
      level: 'S2B',
      subjects: [
        { name: 'Biology', unit: 5 },
        { name: 'Math', unit: 1 },
        { name: 'Physics', unit: 4 },
        { name: 'English', unit: 3 },
        { name: 'Chemistry', unit: 6 },
        { name: 'Geography', unit: 2 },
      ],
      createdAt: '2022-03-10',
    },
  ];

  const scheduledSessions = [
    {
      title: 'Session title',
      level: '2',
      unit: '2',
      happening: 'Online',
      due: '2022-04-30',
      subject: 'Computer science',
      from: 'class',
    },
    {
      title: 'Session title',
      level: '2',
      unit: '4',
      happening: 'In-person',
      due: '2022-05-10',
      subject: 'Computer science',
      from: 'class',
    },
    {
      title: 'Session title',
      level: '2',
      unit: '4',
      happening: 'In-person',
      due: '2022-04-10',
      subject: 'Physics',
      from: 'class',
    },
    {
      title: 'Session title',
      level: '2',
      unit: '4',
      happening: 'In-person',
      due: '2022-05-10',
      subject: 'Physics',
      from: 'community',
    },
    {
      title: 'Session title',
      level: '2',
      unit: '4',
      happening: 'Online',
      due: '2022-05-10',
      subject: 'Math',
      from: 'class',
    },
    {
      title: 'Session title',
      level: '2',
      unit: '4',
      happening: 'Online',
      due: '2022-04-10',
      subject: 'Math',
      from: 'comunity',
    },
    {
      title: 'Session title',
      level: '2',
      unit: '4',
      happening: 'In-person',
      due: '2022-05-10',
      subject: 'Biology',
      from: 'community',
    },
  ];

  return (
    <PageContent>
      <div
        className={`md:grid  bg-gray-200 grid-cols-12 w-full h-full min-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-8`}>
          <FeatureTitle name="My classes" />
          <div className="md:flex justify-between items-center">
            <nav className="flex justify-between md:justify-start space-x-4">
              <TabSelector
                isActive={selectedTab === 'Activities'}
                title="Activities"
                onClick={() => setSelectedTab('Activities')}
              />
              <TabSelector
                isActive={selectedTab === 'Scheduled sessions'}
                title="Scheduled sessions"
                onClick={() => setSelectedTab('Scheduled sessions')}
              />
              <TabSelector
                isActive={selectedTab === 'Community sessions'}
                title="Community sessions"
                onClick={() => setSelectedTab('Community sessions')}
              />
            </nav>
            {selectedTab !== 'Activities' && (
              <div className="md:w-1/4">
                <Input
                  elementType="select"
                  elementConfig={{
                    startingValue: 'SELECT',
                    options: [
                      { value: 'due', displayValue: 'Filter By Due date' },
                      { value: 'subject', displayValue: 'Filter By Subject' },
                    ],
                  }}
                  value={filterBy}
                  changed={setFilterBy}
                  validation={{ required: true }}
                  shouldValidate
                  error="Invalid filter"
                />
              </div>
            )}
          </div>
          <TabPanel hidden={selectedTab !== 'Activities'}>
            <div className="bg-white w-80% md:w-60% lg:w-40% px-6 py-3 my-3 filter drop-shadow-md rounded-2xl">
              <div className="flex justify-between">
                <CardTitle name="Your Activity" />
                <CardBody name="Last week" />
              </div>
              <div className="flex justify-between items-center flex-wrap">
                <div>
                  {activities.map((activity, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div
                        className={`h-5 w-5 rounded-full bg-${activity.color}`}
                      ></div>
                      <CardBody name={`${activity.value} ${activity.name}`} />
                    </div>
                  ))}
                </div>
                <PieChart width={100} height={100}>
                  <Pie
                    data={activities}
                    cx={45}
                    cy={45}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                  >
                    {activities.map((activity, index) => (
                      <Cell key={`cell-${index}`} fill={activity.hexColor} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
            <div className="py-3">
              <CardTitle name="Goals" color="blue" />
              <div className="flex flex-row justify-around items-start space-y-2 flex-wrap">
                {goals.map((goal, index) => (
                  <div
                    key={index}
                    className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full md:w-80% lg:w-50% h-80"
                  >
                    <div className="flex justify-between items-center">
                      <CardBody name={`Goal ${index + 1}: ${goal.name}`} />
                      <SmallText name={goal.level} color="gray-500" />
                    </div>
                    <ResponsiveContainer height={250}>
                      <BarChart
                        data={goal.subjects}
                        layout="vertical"
                        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                      >
                        <XAxis type="number" />
                        <YAxis type="category" dataKey="name" />
                        <Bar
                          dataKey="unit"
                          barSize={10}
                          fill="#183F71"
                          opacity={90}
                        />
                        <Tooltip />
                        <Legend />
                      </BarChart>
                    </ResponsiveContainer>
                    <CardBody
                      name={new Date(goal.createdAt).toLocaleDateString(
                        undefined,
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        }
                      )}
                      color="gray-500"
                      additional="font-bold"
                    />
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel hidden={selectedTab !== 'Scheduled sessions'}>
            <MyclassesGroupItems
              classes={scheduledSessions.filter(
                (session) => session.from === 'class'
              )}
              filterBy={filterBy}
            />
          </TabPanel>
          <TabPanel hidden={selectedTab !== 'Community sessions'}>
            <MyclassesGroupItems
              classes={scheduledSessions.filter(
                (session) => session.from === 'community'
              )}
              filterBy={filterBy}
            />
          </TabPanel>
        </div>
        <div className={`p-3 col-span-4`}>
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default Myclasses;
