import { Button } from "../../../elements/button";
import { CardTitle, SectionTitle } from "../../../elements/text";
import { ImLab } from "react-icons/im";
import { BiBook } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsCardList } from "react-icons/bs";
import { useEffect, useState } from "react";
import axios from "../../../../axios-base";
import Item from "./item";
import Input from "../../../elements/input";

const SuggestClass = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDataSuggestedFeatures = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(
          "/student/dashboard/homepage/proposedfeaturesorder"
        );
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchDataSuggestedFeatures();
  }, []);
  return (
    <>
      <div className="flex flex-row align-center justify-between">
        <div>
          <Input
            label=""
            elementType="select"
            elementConfig={{
              startingValue: props.startingValue,
              optionsType: "minimal",
              options: props.element,
            }}
            value={props.element}
            changed={props.setElement}
            validation={{ required: false }}
          />
        </div>
      </div>
    </>
  );
};

// const selectComponent = (props) => {
//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const fetchDataSuggestedFeatures = async () => {
//       setLoading(true);
//       try {
//         const { data: response } = await axios.get(
//           "/student/dashboard/homepage/proposedfeaturesorder"
//         );
//         setData(response);
//       } catch (error) {
//         console.error(error.message);
//       }
//       setLoading(false);
//     };

//     fetchDataSuggestedFeatures();
//   }, []);
//   return (
//     <>
//       <div className="flex flex-row align-center justify-between">
//         <div>
//           <Input
//             label=""
//             elementType="select"
//             elementConfig={{
//               startingValue: props.title,
//               optionsType: "minimal",
//               options: props.classes,
//             }}
//             value={props.classes}
//             changed={props.setactiveClass}
//             validation={{ required: false }}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

export default SuggestClass;