import axios from "../../axios-base";
import { getCachedData } from "../../shared/caching";

export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_FAILED";

export function student_dashboard_homepage_simulations_get_subjects_with_select_units_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_INIT
  };
}
export function student_dashboard_homepage_simulations_get_subjects_with_select_units_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_simulations_get_subjects_with_select_units_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_simulations_get_subjects_with_select_units_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_simulations_get_subjects_with_select_units_init());

    axios
      .post(
        `/opanda/simulations/subjects_with_units`,
        {
          Level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_simulations_get_subjects_with_select_units_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_simulations_get_subjects_with_select_units_failed(err.message));
      });
  };
}

export function student_dashboard_homepage_simulations_get_subjects_with_select_units_start_with_subject_param(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_simulations_get_subjects_with_select_units_init());

    axios
      .post(
        `/opanda/simulations/subjects_with_units_with_subject_param`,
        {
          Level: data.academic_level,
          Subject: data.Subject
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_simulations_get_subjects_with_select_units_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_simulations_get_subjects_with_select_units_failed(err.message));
      });
  };
}
//---Mark simulation as viewed
export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_FAILED";

export function student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_INIT
  };
}
export function student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_init());

    axios
      .post(
        `/opanda/simulations/simulations_mark_one_as_viewed`,
        {
          academic_level: data.academic_level,
          user_id: data.user_id,
          Subject: data.Subject,
          simid: data.simid,
          unit: data.unit
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_failed(err.message));
      });
  };
}
//---Simulations documents, videos and 
export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_FAILED";

export function student_dashboard_homepage_simulations_documents_and_videos_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_INIT
  };
}
export function student_dashboard_homepage_simulations_documents_and_videos_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_simulations_documents_and_videos_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_simulations_documents_and_videos_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_simulations_documents_and_videos_init());

    axios
      .post(
        `/opanda/simulations/simulations_documents_and_videos`,
        {
          academic_level: data.academic_level,
          user_id: data.user_id,
          Subject: data.Subject,
          simid: data.simid,
          unit: data.unit
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_simulations_documents_and_videos_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_simulations_documents_and_videos_failed(err.message));
      });
  };
}
