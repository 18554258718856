import PageContent from '../pageContent';
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from '../../../elements/text';
import { Button, GoBackButton } from '../../../elements/button';
import { useState } from 'react';
import CountDown from '../../../elements/CountDown';
import celebration from '../../../../images/celebration.png';
import { FeatureItemCard } from '../../../elements/card';
import { useHistory } from 'react-router-dom';

const Challenge = () => {
  const history = useHistory();
  const testQuestions = [
    {
      question: 'What is a section of DNA that codes for a protein called?',
      answers: ['Gene', 'Chromosome', 'Allele', 'Plasmid'],
    },
    {
      question: 'What is a section of DNA that codes for a protein called?',
      answers: ['Gene', 'Chromosome', 'Allele', 'Plasmid'],
    },
    {
      question: 'What is a section of DNA that codes for a protein called?',
      answers: ['Gene', 'Chromosome', 'Allele', 'Plasmid'],
    },
    {
      question: 'What is a section of DNA that codes for a protein called?',
      answers: ['Gene', 'Chromosome', 'Allele', 'Plasmid'],
    },
    {
      question: 'What is a section of DNA that codes for a protein called?',
      answers: ['Gene', 'Chromosome', 'Allele', 'Plasmid'],
    },
    {
      question: 'What is a section of DNA that codes for a protein called?',
      answers: ['Gene', 'Chromosome', 'Allele', 'Plasmid'],
    },
  ];

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [showResults, setShowResults] = useState(false);

  return (
    <PageContent>
      <div
        className={`relative w-full p-3 max-h-myscreen-sec md:max-h-screen-sec col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <FeatureTitle name="Challenges" />
        <GoBackButton
          link="/teacher/features/challenges"
          action="Back"
          location="Challenge: Human skeleton"
        />
        {!showResults ? (
          <>
            <div className="m-10">
              <CardBody
                name={`${currentQuestionNumber + 1}. ${
                  testQuestions[currentQuestionNumber].question
                }`}
              />
              {testQuestions[currentQuestionNumber].answers.map(
                (answer, index) => (
                  <div key={index} className="flex items-center space-x-2 p-2">
                    <input type="radio" name="testradiobutton" value={answer} />
                    <CardBody name={answer} />
                  </div>
                )
              )}
            </div>
            <div className="flex flex-col absolute right-5 bottom-5">
              <CountDown seconds={300} />
              <div className="flex justify-around space-x-2 py-2">
                {currentQuestionNumber < testQuestions.length - 1 && (
                  <Button
                    name="Next"
                    outline="true"
                    color="red"
                    clicked={() => {
                      if (currentQuestionNumber <= testQuestions.length) {
                        setCurrentQuestionNumber((number) => number + 1);
                      }
                    }}
                  />
                )}
                <Button
                  name="Submit"
                  outline="true"
                  color="blue"
                  clicked={() => setShowResults(true)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <SectionTitle name="Biology - Unit2: Human skeleton" color="blue" />
            <CardBody name="Cillum pariatur cupidatat cupidatat culpa laboris aute aliqua." />
            <div className="w-full flex py-5">
              <div className="w-50%">
                <div className="flex space-x-10">
                  <div className="my-3 mr-3 p-2 rounded-xl bg-yellow">
                    <img className="w-full h-full" src={celebration} alt="" />
                  </div>
                  <div>
                    <CardTitle name="Fantastic" color="blue" />
                    <CardBody name="You got 70 % on this test" />
                    <div className="py-3 space-x-3">
                      <Button
                        name="Redo"
                        outline="true"
                        color="blue"
                        clicked={() => {}}
                      />
                      {/* <Button
                        name="Share"
                        outline="true"
                        color="blue"
                        clicked={() => {}}
                      /> */}
                    </div>
                    <Button
                      name="Challenge Others"
                      outline="true"
                      color="blue"
                      clicked={() => {}}
                    />
                  </div>
                </div>
                <Button
                  name="Check answers"
                  outline="false"
                  color="red"
                  clicked={() => {}}
                />
              </div>
              <div className="w-50%">
                <CardTitle name="Other challenges" color="blue" />
                <CardBody name="Challenge" color="blue" />
                <div className="grid grid-cols-2">
                  <FeatureItemCard
                    feature="challenge"
                    subject="Physics"
                    level="1"
                    unit="Unit 1"
                    type="launched"
                    imgsrc="/images/preview/bio.svg"
                    creator="Mugabo Cedric"
                    createdAt="2022-05-03 10:00"
                    clicked={() => {
                      history.push(`/teacher/features/challenges/${1}`); //here must a test id
                    }}
                  />
                  <FeatureItemCard
                    feature="challenge"
                    subject="Physics"
                    level="1"
                    unit="Unit 1"
                    type="launched"
                    imgsrc="/images/preview/bio.svg"
                    creator="Mugabo Cedric"
                    createdAt="2022-05-03 10:00"
                    clicked={() => {
                      history.push(`/teacher/features/challenges/${1}`); //here must a test id
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </PageContent>
  );
};

export default Challenge;
