import { CardBody } from '../../../elements/text';

const TabSelector = (props) => {
  return (
    <div
      className="flex justify-between items-center py-2 px-1 cursor-pointer"
      onClick={props.onClick}
    >
      <CardBody
        name={props.title}
        color="blue"
        additional={props.isActive ? 'font-bold' : ''}
      />
      {props.new > 0 ? (
        <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none bg-red text-white rounded-lg">
          {props.new}
        </span>
      ) : null}
    </div>
  );
};

export default TabSelector;
