import {useState} from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import { BsCheckLg } from 'react-icons/bs';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion'

import PageContent from "../../pageContent";
import Sidebar  from '../Sidebar/Sidebar';
import { CardBody, CardTitle, 
         FeatureTitle, PageBody, 
         SectionTitle, PageTitle 
} from '../../../../elements/text';
import { BookInPurchaseCard, FeatureItemCard, ParentPurchasedBookCard } from "../../../../elements/card";
import { Button, ButtonLink, TextButton } from "../../../../elements/button";
import Input from "../../../../elements/input";
import Modal from "../../../../elements/modal/modal";
import { DynamicModal } from "../../../../elements/modal/DynamicModal";
import { MdOutlineArrowBackIosNew, MdOutlineNavigateNext } from 'react-icons/md';
import { BookContentContainer, ContentContainer } from '../../../../common/content/container';

const suggestedBooks = [
  {id: 1, subject: 'Trigonometry', author: 'Cedric Diego', datePublished: 'Book, 2005', category: 'Math', price: '5000', currency: 'Rwf'},
  {id: 2, subject: 'Geography', author: 'John Doe', datePublished: 'Book, 2005', price: '5000', category: 'Geography', currency: 'Rwf'},
  {id: 3, subject: 'Physics', author: 'John Doe', datePublished: 'Book, 2005', price: '5000', category: 'Physics', currency: 'Rwf'},
  {id: 4, subject: 'Biology', author: 'Cedric Diego', datePublished: 'Book, 2005', price: '5000', category: 'Biology', currency: 'Rwf'},
  {id: 5, subject: 'Chemistry', author: 'John Doe', datePublished: 'Book, 2005', price: '5000', category: 'Math', currency: 'Rwf'},
  // {id: 6, name: 'Solar System', author: 'Cedric Diego', datePublished: 'Book, 2005', category: 'Physics', price: '5000', currency: 'Rwf'},
  // {id: 7, name: 'Forces', author: 'John Doe', datePublished: 'Book, 2005', category: 'Physics', price: '5000', currency: 'Rwf'},
  // {id: 7, name: 'Equations', author: 'Cedric Diego', datePublished: 'Book, 2005', category: 'Math', price: '5000', currency: 'Rwf'},
]

const children = [
  {id: 1, name: "Mutabazi Aime"},
  {id: 2, name: "Nikuze Anne"},
  // {id: 3, name: "MANZI John"},
  // {id: 4, name: "Mike Wilson"}
]

let purchasedBooks = [
    {id: 1, title: 'Trigonometry', author: "John Doe", datePublished: 'Book, 2005',imageSource: "/images/preview/Mathematics.svg"},
    {id: 2, title: 'Geography',  author: "John Doe", datePublished: 'Book, 2005',imageSource: "/images/preview/bio.svg"},
    {id: 3, title: 'Physics',  author: "John Doe", datePublished: 'Book, 2005',imageSource: "/images/preview/Mathematics.svg"},
    {id: 4, title: 'Physics',  author: "John Doe", datePublished: 'Book, 2005',imageSource: "/images/preview/bio.svg"},
    {id: 5, title: 'Physics',  author: "John Doe", datePublished: 'Book, 2005',imageSource: "/images/preview/Mathematics.svg"},
    {id: 6, title: 'Physics',  author: "John Doe", datePublished: 'Book, 2005',imageSource: "/images/preview/bio.svg"},
];

const PurchasedBooks = () => {
  const history = useHistory();
  const [classes, setClasses]=useState()
  const [search, setSearch]=useState()
  const [ toggle, setToggle ] = useState(false)

  return (
    <PageContent>
    <div className="flex w-full h-full">
      <div className="hidden lg:block lg:w-1/4 h-full bg-gray-100 p-4">
      <Input 
      elementType="select"
      elementConfig={{
        startingValue: "SELECT",
        options: [
          { value: "s1", displayValue: "S1"},
          { value: "s2", displayValue: "S2"},
          { value: "s3", displayValue: "S3"},
        ]
      }}
      value={classes}
      changed={setClasses}
      />
      <Input 
      elementType="text"
      elementConfig={{
        type: "text",
        placeholder: 'Search'
      }}
      value={search}
      changed={setSearch}
      additional="rounded-full h-30 my-4"
      />
      {suggestedBooks.map((book)=> (
        <div key={book.id} className="py-2">
          <CardBody name={book.category} color="blue" />
        </div>
      ))}
    </div>
    <div className="h-full bg-gray-100 relative shadow-md lg:hidden"> 
    {toggle ? '' : (
    <motion.div 
    whileInView={{ x: [-20 , 0] }} 
    whileHover={{ scale: 1.1 }}
    className="w-15 h-15 absolute bg-blue rounded-r-full"> 
    <MdOutlineNavigateNext color="white" size={30} onClick={() => setToggle(true)} />
    </motion.div>
    )}
    
    {toggle ? (
      <motion.div
      whileInView={{ x: [-100, 0] }}
      transition={{ duration: 0.85, ease:'easeOut'}}
      className="w-90% h-full"
      >
        <motion.div 
          whileInView={{ x: [-20 , 0] }}
          whileHover={{ scale: 1.1 }}
          className="w-15 h-15 absolute bg-blue rounded-r-full"> 
          <MdOutlineArrowBackIosNew color="white" size={30} onClick={() => setToggle(false)} className="p-2"/> 
        </motion.div>
      <div className='p-4'>
      <Input 
      elementType="select"
      elementConfig={{
        startingValue: "SELECT",
        options: [
          { value: "s1", displayValue: "S1"},
          { value: "s2", displayValue: "S2"},
          { value: "s3", displayValue: "S3"},
        ]
      }}
      value={classes}
      changed={setClasses}
      />
      <Input 
      elementType="text"
      elementConfig={{
        type: "text",
        placeholder: 'Search'
      }}
      value={search}
      changed={setSearch}
      additional="rounded-full h-30 my-4"
      />
      {suggestedBooks.map((book)=> (
        <div key={book.id} className="py-2">
          <CardBody name={book.category} color="blue" />
        </div>
      ))}
      </div>

      </motion.div>
    ) : ''}
    </div>
    <div className="w-full lg:w-3/4 p-4 overflow-x-auto">
      <FeatureTitle name='Purchased books' />
      <div className="flex flex-col-reverse lg:flex-row py-4">
      <div className="w-3/4">
      <TextButton
              name="All"
              color="blue"
              additional='text-blue'
              clicked={()=>{ }}
            />
        {children.map((child)=> (
               <TextButton
               key={child.id}
               name={child.name}
               color="blue"
               additional="text-blue ml-4"
               clicked={()=>{ }}
             />
        ))}
      </div>
      <div className='lg:w-1/4 flex items-start lg:justify-center'>
          <Button
              name="New Package"
              outline="false"
              color="blue"
              isSquare={true}
              clicked={()=>{
                history.push('/parent/marketplace/books/');
              }}
            />
      </div>
      </div>
      <div className="w-full p-1">
    {children.map((child, index) => (
        <BookContentContainer
        name={child.name}
        data={purchasedBooks}
        slider={true}
        type="purchasedbooks"
        />
        ))}
      </div>
    </div>
      </div>
    </PageContent>
  )}

export default PurchasedBooks
