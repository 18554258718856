import { updateObject } from "../../../shared/utility";
import * as actionsTypes from "../../actions/common/programs";

const initialprograms = {
 
  CBC: {
    accronym: "CBC",
    fullname: "Compentency based curriculum",
    structure: {
      "Ordinary level": {
        Classes: ["1", "2", "3"],
        Subjects: [{ value: 'math', label: 'Math' }, { value: 'economics', label: 'Economics' }, { value: 'geography', label: 'Geography' }, { value: 'physics', label: 'Physics' }, { value: 'computer science', label: 'Computer Science' }, { value: 'chemistry', label: 'Chemistry' }, { value: 'biology', label: 'Biology' }]
     },
      "Advanced level": {
        Classes: ["4", "5", "6"],
        Combinations: {
          MCB: {
            default: [{ value: 'math', label: 'Math' },{ value: 'chemistry', label: 'Chemistry' }, { value: 'biology', label: 'Biology' }],
          },
          PCM: {
            default: [{ value: 'physics', label: 'Physics' },{ value: 'chemistry', label: 'Chemistry' }, { value: 'math', label: 'Math' }],
       
         
          },
          PCB: {
            default: [{ value: 'physics', label: 'Physics' },{ value: 'chemistry', label: 'Chemistry' }, { value: 'biology', label: 'Biology' }],
       
        
          },
          BCG: {
            default: [{ value: 'geography', label: 'Geography' },{ value: 'chemistry', label: 'Chemistry' }, { value: 'biology', label: 'Biology' }],
          },
          MPC: {
            default: [{ value: 'physics', label: 'Physics' },{ value: 'computer science', label: 'Computer Science' }, { value: 'math', label: 'Math' }],
          },
          MEG: {
            default: [{ value: 'geography', label: 'Geography' },{ value: 'math', label: 'Math' }, { value: 'economics', label: 'Economics' }],
          },
        },
        Subjects: [{ value: 'math', label: 'Math' }, { value: 'economics', label: 'Economics' }, { value: 'geography', label: 'Geography' }, { value: 'physics', label: 'Physics' }, { value: 'computer science', label: 'Computer Science' }, { value: 'chemistry', label: 'Chemistry' }, { value: 'biology', label: 'Biology' }]
      },
    },
  },
};

const initialReducer = {
  programs: initialprograms,
  programsLoading: null,
  programsError: null,
};

const getProgramsStart = (state, action) => {
  return updateObject(state, {
    programs: [],
    programsLoading: true,
    programsError: null,
  });
};

const getProgramsSuccess = (state, action) => {
  return updateObject(state, {
    programs: action.programs,
    programsLoading: null,
    programsError: false,
  });
};

const getProgramsFail = (state, action) => {
  return updateObject(state, {
    programs: [],
    programsLoading: false,
    programsError: action.error,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_PROGRAMS_START:
      return getProgramsStart(state, action);
    case actionsTypes.GET_PROGRAMS_SUCCESS:
      return getProgramsSuccess(state, action);
    case actionsTypes.GET_PROGRAMS_FAIL:
      return getProgramsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
