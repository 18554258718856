import PageContent from "../pageContent";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardBody,
  CardSubText,
  CardTitle,
  FeatureTitle,
  PageBody,
  SectionTitle,
} from "../../../elements/text";
import { AnchorButton, Button, GoBackButton } from "../../../elements/button";
import { FaFilePdf } from "react-icons/fa";
import Modal from "../../../elements/modal/modal";
import EventCreationForm from "./forms/create";
import { BiBook } from "react-icons/bi";
import EventEditForm from "./forms/edit";

const EventDetails = () => {
  const [showEditModal, setshowEditModal] = useState(false);
  const [activeclass, setactiveClass] = useState(["S1 A", "S1 B"]);
  const [activesubject, setactiveSubject] = useState(["biology", "chemistry"]);
  const eventdetails = {
    id: 1, 
    type: "class",
    title: "Import google calendar",
    description: "Import google calendar Import google calendar",
    icon: <BiBook color="#183F71" size="30" />,
    unit: "Unit 2:gfghfgfgh gfgf",
    subject: "button",
    from: "2022-05-08",
    to: "2022-05-08",
    resources: [{name:"reere",resourcelink:"reere/bcbcb.cnc"},{name:"reere",resourcelink:"reere/bcbcb.cnc"}],
    online: true,
    link: "/school/features/schedule/1",
    class: "s1 a"
  };
  return (
    <PageContent>
      <Modal
        medium
        show={showEditModal}
        modalClosed={() => setshowEditModal(false)}
      >
        <div className="flex flex-col">
          <EventEditForm
            activeclass={activeclass}
            activesubject = {activesubject}
            editdata={eventdetails}
          />
        </div>
      </Modal>
      <div className="flex flex-col w-full py-3 px-5 h-full max-h-full overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <div className="flex flex-row align-center justify-between">
          <FeatureTitle name="Schedule" />
        </div>

        <div className="flex flex-row align-center justify-between space-x-2">
          <GoBackButton
            link="/school/features/schedule"
            action="Back"
            location={`Schedule > Event title`}
          />
          <Button
            name="Edit"
            outline="true"
            color="blue"
            additional="place-self-center"
            clicked={() => {
              setshowEditModal(true);
            }}
            isSmall="true"
          />
        </div>
        <div className="md:my-4 space-y-2">
          <SectionTitle name="Event title" color="blue" />
          <CardTitle name="Unit 3: Introduction to menengitis" color="black" />
          <PageBody name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet lectus sit amet ex finibus, at eleifend enim lobortis. Donec egestas elementum mauris sit amet varius. Maecenas rutrum molestie augue, sed dapibus tellus consectetur id." />
          <CardBody
            name="Time and Date"
            color="black"
            additional=" font-semibold"
          />
          <div className="md:mx-4">
            <CardSubText name="2022/08/01 8:00AM" /> - <CardSubText name="2022/08/01 9:00AM" />
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div>
              <CardBody
                name="Resources"
                color="black"
                additional=" font-semibold"
              />
              <div className="md:mx-4 my-2 grid space-y-4">
                <div className="flex justify-start space-x-2">
                  <FaFilePdf color="black" />
                  <AnchorButton name="Assignment about neglomania" to="/" />
                </div>
                <div className="flex justify-start space-x-2">
                  <FaFilePdf color="black" />
                  <AnchorButton name="Homework 1" to="/" />
                </div>
              </div>
            </div>
            <div>
              <CardBody
                name="Meeting link"
                color="black"
                additional=" font-semibold"
              />
              <div className="md:mx-4 my-2 grid space-y-4">
                <div className="flex justify-start space-x-2">
                  <CardSubText name="Meeting ID" color="black" />
                  <AnchorButton name="582145" to="/" />
                </div>
                <div className="flex justify-start space-x-2">
                  <CardSubText name="Meeting password" color="black" />
                  <AnchorButton name="fr32%jsu" to="/" />
                </div>
                <div className="flex justify-start space-x-2">
                  <CardSubText name="Meeting link" color="black" />
                  <AnchorButton
                    name="https://zoom.us?mid=m856582145&mpwd=582145"
                    to="/"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default EventDetails;
