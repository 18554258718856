// SubmitButton.js
import React from 'react';

const SubmitButton = ({ onSubmit }) => {
  return (
    <button onClick={onSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Submit
    </button>
  );
};

export default SubmitButton;
