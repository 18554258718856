import { BiCalendarEvent } from "react-icons/bi";
import { BsCalendarEventFill, BsFileEarmarkFill } from "react-icons/bs";
import { AnchorButton } from "../../../elements/button";
import { SmallText } from "../../../elements/text";

const EventItem = (props) => {
  return (
    <div className="bg-white shadow grid grid-cols-2 gap-2 px-4 pt-2 py-2">
      <BsCalendarEventFill color="black" />
      <div className="col-span-2">
        <AnchorButton
          to={props.link}
          name={props.title}
          additional="font-bold"
        />
        <br />
        <SmallText name={props.unit} color="black" />
      </div>
      <div className="col-span-2"></div>
      <div className="flex justify-start">
        <BsFileEarmarkFill color="black" />
        <SmallText name="2 resources" color="black" />
      </div>

      <div className="grid grid-rows-2">
        <SmallText name={`${props.from}`} color="black" alignment="right" />
        <SmallText name={`${props.to}`} color="black" alignment="right" />
      </div>
      <div className="col-span-2 border-t-2 border-gray pb-2">
        <SmallText name={`${props.from}`} color="black" alignment="right" />
      </div>
    </div>
  );
};

export default EventItem;
