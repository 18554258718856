import Layout from '../../components/common/Layout';
import ComposeMessage from '../../components/pages/teacher/communication/ComposeMessage';

const ComposeMessageContainer = () => {
  return (
    <Layout>
      <ComposeMessage />
    </Layout>
  );
};

export default ComposeMessageContainer;
