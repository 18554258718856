import Layout from '../../../components/common/Layout';
import { AddPackage } from '../../../components/pages/parent/renewpayment';

const ParentAddPackageContainer = () => {
  return (
    <Layout>
      <AddPackage />
    </Layout>
  );
};

export default ParentAddPackageContainer;
