import { CardBody, CardSubText } from '../elements/text';
import { FooterLink } from './header/links';
import ogpLogo from '../../images/ogenius_inverse.svg';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  const { t } = useTranslation();
  const isAuth = useSelector((state) => state.auth.token !== null);
  if (isAuth) {
    return (
      <div className="hidden py-3 bg-blue md:flex items-center justify-center space-x-2 flex-wrap z-50">
        <CardBody
          name={`Copyright ${new Date().getFullYear()}.`}
          color="yellow"
          additional="font-bold"
        />
        <CardBody name={t('All rights reserved.')} color="yellow" />
      </div>
    );
  }
  return (
    <div className="w-full z-50 bg-blue">
      <div className="bg-blue w-full flex flex-row">
        <div className="w-full flex flex-col md:flex-row align-center justify-between max-w-7xl mt-4 py-10 px-8 ">
          <div className="flex flex-col align-start justify-start">
            <div className="mx-2">
              <CardSubText
                name={t('Brought to you by')}
                color="white"
                alignment="left"
              />
            </div>
            <img
              src={ogpLogo}
              alt="O'Genius Priority Ltd logo"
              className="w-full"
            />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-1 gap-4 md:gap-2">
            <a href="https://ogeniuspriority.com/" className="block text-right text-white md:text-start text-sm font-thin">O'Genius Priority</a>
            {/* <FooterLink page="https://ogeniuspriority.com/" reloadDocument={true} name="O'Genius Priority" /> */}
            <FooterLink page="faq" name="FAQ's" />
            <a href="https://ogeniuspriority.com/contact-us" className="block text-right text-white md:text-start text-sm font-thin">{t('Contact')}</a>
            {/* <a href="https://www.opanda.rw/privacypolicy" className="block text-right text-white md:text-start text-sm font-thin">{t('Privacy Policy')}</a> */}
            <FooterLink page="privacypolicy" name={t('Privacy Policy')} />
            <a href="https://ogeniuspriority.com/about#careers" className="block text-right text-white md:text-start text-sm font-thin">{t('Careers')}</a>
           
            {/* <FooterLink page="https://ogeniuspriority.com/contact-us" reloadDocument={true} name={t('Contact')} />
            <FooterLink page="https://www.opanda.rw/privacypolicy.php" reloadDocument={true} name={t('Privacy Policy')} />
            <FooterLink page="https://ogeniuspriority.com/about#careers" reloadDocument={true} name={t('Careers')} /> */}
          </div>
        </div>
      </div>
      <div className="bg-yellow w-full mx-auto py-2 text-center">
        <CardSubText
          name={`Copyright ${new Date().getFullYear()}. ${t(
            'All rights reserved.'
          )}`}
          color="blue"
          alignment="center"
          mobileAlignment="center"
        />
      </div>
    </div>
  );
};
export default Footer;
