import parse from 'react-html-parser';
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from '../../../../../elements/text';
import { Button, GoBackButton } from '../../../../../elements/button';
import { useState } from 'react';
import CountDown from '../../../../../elements/CountDown';
import celebration from '../../../../../../images/celebration.png';
import { FeatureItemCard } from '../../../../../elements/card';
import { useHistory } from 'react-router-dom';
import CompleteSentence from '../../../../../common/features/tests/testtypes/completesentence/CompleteSentence';
import OpenEnded from '../../../../../common/features/tests/testtypes/openended/OpenEnded';
import DragAndDrop from '../../../../../common/features/tests/testtypes/draganddrop/DragAndDrop';
import { useParams } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { student_dashboard_create_teacher_made_challenge_init, student_dashboard_create_teacher_made_challenge_start, student_dashboard_homepage_auto_test_start, student_dashboard_homepage_get_auto_test_answer_sheet_start, student_dashboard_homepage_get_auto_test_answer_sheet_start_for_challenges, student_dashboard_homepage_get_auto_test_answer_sheet_start_for_tests_stats, student_dashboard_homepage_get_teacher_made_test_answer_sheet_start, student_dashboard_homepage_get_teacher_made_test_answer_sheet_start_for_challenges, student_dashboard_homepage_get_teacher_made_test_answer_sheet_start_for_progress_stats, student_dashboard_homepage_results_for_teacher_made_test_start, student_dashboard_homepage_results_for_teacher_made_test_start_for_challenges, student_dashboard_homepage_save_auto_made_test_start, student_dashboard_homepage_save_teacher_made_test_start, student_dashboard_homepage_teacher_made_test_start } from '../../../../../../store/actions/student.dashboard.tests.subjects.units';
import { teacher_tests_questions_data } from '../../../../../../functions/Tests';
import failed from '../../../../../../images/test-failed-svgrepo-com.svg';
import Spinner from '../../../../../elements/spinner';
import Uiteachermadesheet from './uiteachermadesheet/uiteachermadesheet';
import Uiautotestanswersheet from './autotestanswersheet/uiautotestanswersheet';
import { auto_test_questions_data_answer_sheet } from '../../../../../../functions/autoTest';


const TestResultsChallengesAnswerSheets = (props) => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const studentDashboardTestsSubjectsUnits = useSelector(
    (state) => state.studentDashboardTestsSubjectsUnits
  );
  const history = useHistory();
  const autoTestProgressList = useSelector(
    (state) => state.autoTestProgressList
  );
  //---
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const test_params = useParams();
  const [checkAnswerSheetView, setCheckAnswerSheetView] = useState(false);
  useEffect(() => {
    if ((props.challengetype + "") === "0") {
      if (props.testid && props.useranswerid) {
        dispatch(
          student_dashboard_homepage_get_auto_test_answer_sheet_start_for_tests_stats({
            token: userAth.token,
            user_id: userAth.userId,
            testid: props.testid,
            academic_level: userAth.academic_level,
            subject: props.subject
          })
        );
      } else {
        dispatch(
          student_dashboard_homepage_get_auto_test_answer_sheet_start_for_challenges({
            token: userAth.token,
            user_id: userAth.userId,
            challengeId: props.challengeId
          })
        );
      }


    } else {
      if (props.testid && props.useranswerid) {
        dispatch(
          student_dashboard_homepage_get_teacher_made_test_answer_sheet_start_for_progress_stats({
            token: userAth.token,
            user_id: userAth.userId,
            testid: props.testid,
            useranswerid: props.useranswerid
          })
        );
      } else {
        dispatch(
          student_dashboard_homepage_get_teacher_made_test_answer_sheet_start_for_challenges({
            token: userAth.token,
            user_id: userAth.userId,
            challengeId: props.challengeId
          })
        );
      }

    }
  }, []);
  //---
  const answer_sheet = studentDashboardTestsSubjectsUnits.dashboard_homepage_get_teacher_made_test_answer_sheet_test;
  const auto = auto_test_questions_data_answer_sheet(studentDashboardTestsSubjectsUnits.dashboard_homepage_get_auto_test_answer_sheet_test);

  //--------------

  return (((props.challengetype + "") === "0") ? ((!studentDashboardTestsSubjectsUnits.dashboard_homepage_get_auto_test_answer_sheet_loading) ? <Uiautotestanswersheet unitname={props.unitname} auto={auto} /> : <Spinner size="20" color="blue" />) : (studentDashboardTestsSubjectsUnits.dashboard_homepage_get_teacher_made_test_answer_sheet_loading) ? <Spinner size="20" color="blue" /> : <Uiteachermadesheet answer_sheet={answer_sheet} />

  );
};

export default TestResultsChallengesAnswerSheets;
