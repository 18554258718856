import PageContent from '../pageContent';
import Notification from '../../../elements/notification/notification';
import { TabPanel, useTabs } from 'react-headless-tabs';
import { useState } from 'react';
import TabSelector from '../../../elements/tab/TabSelector';
import { FeatureItemCard } from '../../../elements/card';
import { CardBody, CardTitle, FeatureTitle } from '../../../elements/text';
import Input from '../../../elements/input';
import { Cell, Pie, PieChart } from 'recharts';
import { BsStar } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import ChallengesGroupItem from './ChallengesGroupItem';

const Challenges = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useTabs(
    ['Ongoing', 'My challenges'],
    'Ongoing'
  );
  const [filterBy, setFilterBy] = useState('all');

  const activities = [
    {
      name: 'Challenges',
      value: 3,
      color: 'red',
      hexColor: '#BE1E2D',
    },
    {
      name: 'Attempted',
      value: 1,
      color: 'blue',
      hexColor: '#183F71',
    },
    {
      name: 'Participants',
      value: 10,
      color: 'yellow',
      hexColor: '#E8AD26',
    },
  ];

  const topPerformers = ['Mugabo Cedric', 'Mugabo Cedric', 'Mugabo Cedric'];

  const mostPassed = {
    subject: 'Math',
    level: '1',
    unit: 'Unit 1',
    type: '',
    imgsrc: '/images/preview/bio.svg',
    creator: 'Mugabo Cedric',
    createdAt: '2022-05-03 10:00',
  };

  const mychallenges = [
    {
      subject: 'Math',
      level: '1',
      unit: 'Unit 1',
      type: 'launched',
      imgsrc: '/images/preview/bio.svg',
      creator: 'Mugabo Cedric',
      createdAt: '2022-05-03 10:00',
    },
    {
      subject: 'Biology',
      level: '1',
      unit: 'Unit 1',
      type: 'attempted',
      imgsrc: '/images/preview/bio.svg',
      creator: 'Mugabo Cedric',
      createdAt: '2022-05-03 10:00',
    },
    {
      subject: 'Biology',
      level: '1',
      unit: 'Unit 1',
      type: 'launched',
      imgsrc: '/images/preview/bio.svg',
      creator: 'Mugabo Cedric',
      createdAt: '2022-05-03 10:00',
    },
    {
      subject: 'Physics',
      level: '1',
      unit: 'Unit 1',
      type: 'launched',
      imgsrc: '/images/preview/bio.svg',
      creator: 'Mugabo Cedric',
      createdAt: '2022-05-03 10:00',
    },
    {
      subject: 'Biology',
      level: '1',
      unit: 'Unit 1',
      type: 'attempted',
      imgsrc: '/images/preview/bio.svg',
      creator: 'Mugabo Cedric',
      createdAt: '2022-05-03 10:00',
    },
    {
      subject: 'Physics',
      level: '1',
      unit: 'Unit 1',
      type: 'attempted',
      imgsrc: '/images/preview/bio.svg',
      creator: 'Mugabo Cedric',
      createdAt: '2022-05-03 10:00',
    },
  ];

  return (
    <PageContent>
      <div
        className={`md:grid  bg-gray-200 grid-cols-12 w-full min-h-screen-sec max-h-myscreen-sec md:max-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-9`}>
          <FeatureTitle name="Challenges" />
          <div className="md:flex justify-between items-center">
            <nav className="flex justify-between md:justify-start space-x-4">
              <TabSelector
                isActive={selectedTab === 'Ongoing'}
                title="Ongoing"
                onClick={() => setSelectedTab('Ongoing')}
              />
              <TabSelector
                isActive={selectedTab === 'My challenges'}
                title="My challenges"
                onClick={() => setSelectedTab('My challenges')}
              />
            </nav>
            {selectedTab !== 'Ongoing' && (
              <div className="md:w-1/4">
                <Input
                  elementType="select"
                  elementConfig={{
                    startingValue: 'SELECT',
                    options: [
                      { value: 'all', displayValue: 'All' },
                      { value: 'Math', displayValue: 'Math' },
                      { value: 'Biology', displayValue: 'Biology' },
                      { value: 'Physics', displayValue: 'Physics' },
                      { value: 'Economics', displayValue: 'Economics' },
                      { value: 'Geography', displayValue: 'Geography' },
                    ],
                  }}
                  value={filterBy}
                  changed={setFilterBy}
                  validation={{ required: true }}
                  shouldValidate
                  error="Invalid filter"
                />
              </div>
            )}
          </div>
          <TabPanel hidden={selectedTab !== 'Ongoing'}>
            <div className="py-3 flex space-x-4">
              <div className="bg-white w-40% px-6 py-3 my-3 filter drop-shadow-md rounded-2xl">
                <div className="flex justify-between">
                  <CardTitle name="Your charts" />
                  <CardBody name="Last week" />
                </div>
                <div className="flex justify-between items-center">
                  <div>
                    {activities.map((activity, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <div
                          className={`h-5 w-5 rounded-full bg-${activity.color}`}
                        ></div>
                        <CardBody name={`${activity.value} ${activity.name}`} />
                      </div>
                    ))}
                  </div>
                  <PieChart width={100} height={100}>
                    <Pie
                      data={activities}
                      cx={45}
                      cy={45}
                      innerRadius={30}
                      outerRadius={50}
                      fill="#8884d8"
                      paddingAngle={2}
                      dataKey="value"
                    >
                      {activities.map((activity, index) => (
                        <Cell key={`cell-${index}`} fill={activity.hexColor} />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
              </div>

              <div className="bg-white w-40% px-6 py-3 my-3 filter drop-shadow-md rounded-2xl">
                <div className="flex justify-between">
                  <CardTitle name="Top performers" />
                  <CardBody name="Last week" />
                </div>
                <div className="">
                  {topPerformers.map((performer, index) => (
                    <div key={index} className="">
                      <CardBody name={performer} />
                      <div className="flex space-x-2">
                        <BsStar color="#E8AD26" />
                        <BsStar color="#E8AD26" />
                        <BsStar color="#E8AD26" />
                        <BsStar color="#E8AD26" />
                        <BsStar color="#E8AD26" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <CardTitle name="Most placed" color="blue" />
            <div className="grid grid-cols-3">
              <FeatureItemCard
                {...mostPassed}
                feature="challenge"
                clicked={() => history.push(`/teacher/features/challenges/${1}`)}
              />
            </div>
          </TabPanel>
          <TabPanel hidden={selectedTab !== 'My challenges'}>
            <ChallengesGroupItem
              type="Launched"
              challenges={mychallenges
                .filter((challenge) => challenge.type === 'launched')
                .filter((challenge) =>
                  filterBy === 'all' ? true : challenge.subject === filterBy
                )}
              filterBy={filterBy}
            />
            <ChallengesGroupItem
              type="Attempted"
              challenges={mychallenges
                .filter((challenge) => challenge.type === 'attempted')
                .filter((challenge) =>
                  filterBy === 'all' ? true : challenge.subject === filterBy
                )}
              filterBy={filterBy}
            />
          </TabPanel>
        </div>
        <div className={`p-3 col-span-3`}>
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default Challenges;
