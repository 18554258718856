import { Button } from '../elements/button';
import { CardTitle, PageTitle } from '../elements/text';
import Header from './header/header';
import { useHistory } from 'react-router-dom';

const PageNotFound = () => {
  const history = useHistory();
  return (
    <div>
      <Header />
      <div className="w-60% m-auto flex flex-col items-center justify-center">
        <img className="m-auto" src="/images/404.svg" alt="" />
        <PageTitle
          name="We found no resources under this url"
          color="blue"
          additional="font-bold text-center"
        />
        <CardTitle
          name="Navigate back to home page"
          color="blue"
          additional="font-bold text-center"
        />
        <Button
          name="Go home"
          outline="true"
          color="red"
          clicked={() => history.replace('/')}
        />
      </div>
    </div>
  );
};

export default PageNotFound;
