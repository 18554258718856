import { forwardRef, useRef, useState, createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../constants/constants";
import { school_dashboard_create_user_start, school_dashboard_users_count_start, school_dashboard_users_start } from "../../../../store/actions/school.dashboard.users";
import { Button, ButtonClose } from "../../../elements/button";
import Input from "../../../elements/input";
import { combinations } from "../../../../functions/combinationsAndSubjects";

const CustomButton = forwardRef((props, ref) => (
  <div onClick={props.onCancel} ref={ref}>
    <Button name="Cancel" outline="true" color="blue" />
  </div>
));

const NewUserForm = (props) => {
  const [usertype, setUsertype] = useState();

  const userAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [academicLevel, setAcademicLevel] = useState("");

  const [section, setSection] = useState("");

  const [combination, setCombination] = useState("");
  const [stream, setStream] = useState("");
  const cbccombinations = useSelector((state) => state.profile.cbccombinations);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const buttonRef = useRef();

  // return true if email is valid
  const validateThisEmail = (Email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(Email);
  }
  const handleSubmit = () => {

    dispatch(
      school_dashboard_create_user_start({
        token: userAuth.token,
        user_id: userAuth.userId,
        fname: firstName + `${(validateThisEmail(email) && !props.isForStudentsOnly === true) ? "(" + email + ")" : ""}`,
        lname: lastName,
        user_type: usertype,
        classlevel: (props.isForStudentsOnly === true) ? academicLevel : "",
        combination: (academicLevel > 4) ? combination : "",
        stream: stream,
      })
    );
    // submit form data here
    dispatch(
      school_dashboard_users_count_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
    dispatch(
      school_dashboard_users_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
    setFirstName("");
    setLastName("");
    setEmail("");

    buttonRef.current.click();
  };
  useEffect(()=>{
    if (props?.isForStudentsOnly === true) {
      setUsertype("student");
    } else {
    }
  },[]);

  return (
    <>
      <div className="md:grid grid-cols-3 gap-2">
        <Input
          label="First Name"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "First Name",
          }}
          value={firstName}
          changed={setFirstName}
          validation={{ required: true, notEmpty: true }}
          shouldValidate
          error="First Name is required"
        />
        <Input
          label="Last Name"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Last Name",
          }}
          value={lastName}
          changed={setLastName}
          validation={{ required: true, notEmpty: true }}
          shouldValidate
          error="Last Name is required"
        />
        {(!props?.isForStudentsOnly === true) ? <Input
          label="Email"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Email",
          }}
          value={email}
          changed={setEmail}
          validation={{ required: true, notEmpty: true }}
          shouldValidate
          error="Email is required"
        /> : ""}
        <Input
          label="User type"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: (props?.isForStudentsOnly === true) ? [
              { value: "student", displayValue: "Student" }
            ] : [
              { value: "teacher", displayValue: "Teacher" },
              //{ value: "school", displayValue: "School" },
              { value: "parent", displayValue: "Parent" }],
          }}
          value={usertype}
          changed={setUsertype}
          validation={{ required: true }}
          shouldValidate
          error="Gender is required"
        />
      </div>
      <div
        className={`${usertype === "student" ? "md:grid" : "hidden"
          } grid-cols-3 gap-2`}
      >
        <Input
          label="Section"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: constants.cbcsection,
          }}
          value={section}
          changed={setSection}
          validation={{ required: true }}
          shouldValidate
          error="Section is required"
        />

        <Input
          label="Academic level"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options:
              section === "olevel"
                ? constants.cbcsectionlevels.olevel
                : constants.cbcsectionlevels.alevel,
          }}
          value={academicLevel}
          changed={setAcademicLevel}
          validation={{ required: true }}
          shouldValidate
          error="Academic level is required"
        />

        <Input
          label="Combination"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: combinations,
            disabled: section === "olevel" ? true : false,
          }}
          value={combination}
          changed={setCombination}
          validation={{ required: false }}
          shouldValidate
          error="Combination is required"
        />
        <Input
          label="Stream"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "stream",
          }}
          value={stream}
          changed={setStream}
          validation={{ required: true, notEmpty: true }}
          shouldValidate
          error="Stream is required"
        />
      </div>
      <div
        className={`${usertype === "teacher" ? "md:grid" : "hidden"
          } grid-cols-3 gap-2`}
      ></div>
      <div className="flex space-x-2 justify-center py-5">
        <CustomButton onCancel={props.onCancel} ref={buttonRef} />

        <Button
          name="Save"
          outline="false"
          color="blue"
          clicked={handleSubmit}
        />
      </div>
    </>
  );
};

export default NewUserForm;
