import { updateObject } from '../../../shared/utility';
import { CHANGE_CHOSEN_UNIT_SIMULATIONS } from './../../actions/features/simulations';
// new simulation 
import {CHOOSE_SIMULATION_SUBJECT_INIT} from './../../actions/features/simulations'
import {CHOOSE_SIMULATION_SUBJECT_FAIL} from './../../actions/features/simulations'
import {CHOOSE_SIMULATION_SUBJECT_SUCCESS} from './../../actions/features/simulations'

import {CHOOSE_SIMULATION_UNITS_INIT} from './../../actions/features/simulations'
import {CHOOSE_SIMULATION_UNITS_FAIL} from './../../actions/features/simulations'
import {CHOOSE_SIMULATION_UNITS_SUCCESS} from './../../actions/features/simulations'

import {CHOOSE_SIMULATIONS_PER_UNIT_INIT} from './../../actions/features/simulations'
import {CHOOSE_SIMULATIONS_PER_UNIT_FAIL} from './../../actions/features/simulations'
import {CHOOSE_SIMULATIONS_PER_UNIT_SUCCESS} from './../../actions/features/simulations'


import {CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_INIT} from './../../actions/features/simulations'
import {CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_FAIL} from './../../actions/features/simulations'
import {CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_SUCCESS} from './../../actions/features/simulations'



const initialReducer = {
  chosenUnit: null,
  // subjects
  simulations_subjects: [],
  simulation_subjects_loading: true,
  simulation_subjects_message: '',
  // units
  simulations_units: [],
  simulation_units_loading: true,
  simulation_units_message: '',
  // simulations per units
  simulations_per_unit: [],
  simulations_per_unit_loading: true,
  simulations_per_unit_message: '',
  // simulations with units per subject
  units_with_simulations_per_subject: [],
  units_with_simulations_per_subject_loading: true,
  units_with_simulations_per_subject_message: '',

};

const changeChosenUnit = (state, action) => {
  return updateObject(state, {
    chosenUnit: action.payload,
  });
};
// my new function for getting subjects with simulations
const choose_simulation_subject_init = (state, action) => {
  return updateObject(state, {
    simulation_subjects_loading: true,
    simulation_subjects_message: 'loading initialized',
  });
};
const choose_simulation_subject_fail = (state, action) => {
  return updateObject(state, {
    simulation_subjects_message: 'the loading failed',
    simulation_subjects_loading: false
  });
};
const choose_simulation_subject_success = (state, action) => {
  return updateObject(state, {
    simulation_subjects_loading: false,
    simulations_subjects: action.payload
  });
};

// my new function for getting units with simulations according to the subject

const choose_simulation_units_init = (state, action) => {
  return updateObject(state, {
    simulation_units_loading: true,
    simulation_units_message: 'loading initialized',
  });
};
const choose_simulation_units_fail = (state, action) => {
  return updateObject(state, {
    simulation_units_message: 'the loading failed',
    simulation_units_loading: false
  });
};
const choose_simulation_units_success = (state, action) => {
  return updateObject(state, {
    simulations_units_loading: false,
    simulation_units: action.payload
  });
};


// function for getting  simulations per unit with simulations according to the subject

const choose_simulations_per_unit_init = (state, action) => {
  return updateObject(state, {
    simulations_per_unit_loading: true,
    simulations_per_unit_message: 'loading initialized',
  });
};
const choose_simulations_per_unit_fail = (state, action) => {
  return updateObject(state, {
    simulations_per_unit_message: 'the loading failed',
    simulations_per_unit_loading: false
  });
};
const choose_simulations_per_unit_success = (state, action) => {
  return updateObject(state, {
    simulations_per_unit_loading: false,
    simulations_per_unit: action.payload
  });
};



// function for getting  units with simulations per subject 

const units_with_simulations_per_subject_init = (state, action) => {
  return updateObject(state, {
    units_with_simulations_per_subject_loading: true,
    units_with_simulations_per_subject_message: 'loading initialized',
  });
};
const units_with_simulations_per_subject_fail = (state, action) => {
  return updateObject(state, {
    simulations_per_unit_units_with_simulations_per_subject_message: 'the loading failed',
    units_with_simulations_per_subject_loading: false
  });
};
const units_with_simulations_per_subject_success = (state, action) => {
  return updateObject(state, {
    units_with_simulations_per_subject_loading: false,
    units_with_simulations_per_subject: action.payload
  });
};


//############################################
const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case CHANGE_CHOSEN_UNIT_SIMULATIONS:
      return changeChosenUnit(state, action);

    // subjects with simulations
    case CHOOSE_SIMULATION_SUBJECT_INIT:
      return choose_simulation_subject_init(state, action);

    case CHOOSE_SIMULATION_SUBJECT_FAIL:
        return choose_simulation_subject_fail(state, action);

    case CHOOSE_SIMULATION_SUBJECT_SUCCESS:
        return choose_simulation_subject_success(state, action);

    // units with simulations according to subjects
    case CHOOSE_SIMULATION_UNITS_INIT:
        return choose_simulation_units_init(state, action);
    
    case CHOOSE_SIMULATION_UNITS_FAIL:
        return choose_simulation_units_fail(state, action);
    
    case CHOOSE_SIMULATION_UNITS_SUCCESS:
        return choose_simulation_units_success(state, action);
    
    // units with simulations per unit according to subjects
    case CHOOSE_SIMULATIONS_PER_UNIT_INIT:
        return choose_simulations_per_unit_init(state, action);
    
    case CHOOSE_SIMULATIONS_PER_UNIT_FAIL:
        return choose_simulations_per_unit_fail(state, action);
    
    case CHOOSE_SIMULATIONS_PER_UNIT_SUCCESS:
        return choose_simulations_per_unit_success(state, action);

    // units with simulations per subjects
    case CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_INIT:
        return units_with_simulations_per_subject_init(state, action);
    
    case CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_FAIL:
        return units_with_simulations_per_subject_fail(state, action);
    
    case CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_SUCCESS:
        return units_with_simulations_per_subject_success(state, action);
        
    default:
      return state;
  }
};

export default reducer;
