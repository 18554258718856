import { CardSubText } from '../../../elements/text';

const ProgressBar = (props) => {
  return (
    <div className={`pt-6 pb-2 ${props.className}`}>
      <div className="flex justify-between">
        <CardSubText name="Profile completion" color="blue" />
        <CardSubText name={`${props.progress}%`} color="blue" />
      </div>
      <div className="rounded-full w-full h-2.5 border border-blue">
        <div
          className={`h-full w-${props.progress}% bg-blue rounded-full`}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
