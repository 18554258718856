import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaRegCircle } from 'react-icons/fa';
import { CardBody, CardTitle } from '../../../../elements/text';

const SideBarItem = (props) => {
  return (
    <div
      className={`w-full flex items-center p-2 cursor-pointer rounded space-x-3 bg-${
        props.selected ? 'yellow' : ''
      }`}
      onClick={props.clicked}
    >
      {props.complete ? (
        <BsFillCheckCircleFill color="#E8AD26" size={30} />
      ) : (
        <FaRegCircle size={30} />
      )}
      <div className="flex flex-col">
        <CardTitle name={props.title} color="blue" />
        <CardBody name={props.subTitle} color="blue" />
      </div>
    </div>
  );
};

export default SideBarItem;
