import CommunicationLayout from './CommunicationLayout/CommunicationLayout'
import {
  CardBody,
  CardTitle,
  SectionTitle,
} from '../../../elements/text';
import { Button, ButtonWithIcon, GoBackButton } from '../../../elements/button';
import { useTabs } from 'react-headless-tabs';
import Input from '../../../elements/input';
import { useState } from 'react';
import { BsForward, BsReply } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import { ParentMessageCard } from '../../../elements/card';
import Notification from '../../../elements/notification/notification';
import {
  MdArchive,
  MdAttachFile,
  MdCalendarToday,
  MdDelete,
  MdDownload,
  MdFormatUnderlined,
  MdImage,
  MdFormatAlignLeft,
  MdOutlineFormatAlignJustify,
  MdOutlineFormatColorText,
  MdFormatAlignRight
} from 'react-icons/md';
import Attachment from './Attachment';

const Communication = () => {

  const messages = [
    {
      content:
        'Deserunt nulla sit aliquip irure veniam sint culpa do incididunt quis. Dolore ea adipisicing veniam culpa eu dolor non adipisicing consectetur irure tempor velit sunt. Veniam deserunt adipisicing veniam fugiat ea veniam id quis id. Magna esse tempor aute enim cillum ad labore incididunt mollit ad.',
      sender: 'Teacher 1',
      time: 'Just now',
      new: true,
    },
    {
      content:
        'Lorem sint excepteur ex enim culpa sint id nostrud proident. Velit consectetur elit pariatur minim qui excepteur esse. Exercitation quis ea officia minim adipisicing ullamco culpa Lorem. Nisi eu exercitation non irure laboris nisi minim deserunt laborum ipsum do amet nulla in. Occaecat adipisicing nisi ullamco eu cillum nisi consequat cillum cillum magna in amet. Duis labore sit dolor proident quis laboris eu sunt irure consectetur sunt cillum est. Officia amet magna culpa laborum proident qui consequat in reprehenderit nisi ad.',
      sender: 'Teacher 2',
      time: 'Oct-2022',
    },
    {
      content:
        'Lorem sint excepteur ex enim culpa sint id nostrud proident. Velit consectetur elit pariatur minim qui excepteur esse. Exercitation quis ea officia minim adipisicing ullamco culpa Lorem. Nisi eu exercitation non irure laboris nisi minim deserunt laborum ipsum do amet nulla in. Occaecat adipisicing nisi ullamco eu cillum nisi consequat cillum cillum magna in amet. Duis labore sit dolor proident quis laboris eu sunt irure consectetur sunt cillum est. Officia amet magna culpa laborum proident qui consequat in reprehenderit nisi ad.',
      sender: 'School',
      time: 'Nov-2022',
    },
  ];
  const groups = [
    { name: 'All' },
    { name: 'Teachers' },
    { name: 'School' },
  ];

  const tab = groups.map((group) => group.name);
  const [selectedTabs, setSelectedTabs] = useTabs(tab, 'All');
  const [searchTerm, setSearchTerm] = useState('');
  const [readMessage, setReadMessage] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [reply, setReply] = useState('');
  const [showForwardModal, setShowForwardModal] = useState(false);
  const [showCompose, setShowCompose] = useState(false);
  const [recipient, setRecipient] = useState(['teacher', 'school']);
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [message, setMessage] = useState('')
  const [medium, setMedium] = useState(['email', 'inapp', 'sms']);

  return (
    <CommunicationLayout>
      <div className="bg-white-blue h-full w-full flex">
          <div
            className={`bg-gray-200 ${
              readMessage ? 'col-span-5' : 'col-span-7'
            } overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 overflow-x-auto`}
          >
            <div className="flex justify-between items-end">
            <div className='flex flex-row w-full items-end justify-center p-2 mb-2 border-b-2 border-gray-500'>
            <div className='w-2/4 mr-4'>
            <Input
                elementType="input"
                additional="shadow-md rounded-full"
                elementConfig={{
                type: "text",
                placeholder: "Search",
                }}
                value={searchTerm}
                changed={setSearchTerm}
            />
            </div> 
            <div>
              <Button
                name="NEW"
                outline="false"
                color="blue"
                isSquare={true}
                clicked={() =>  {
                  setShowCompose(true);
                  setReadMessage(false);
                }}
              />
              </div>
          </div>
            </div>
            {groups.map((item, index) => (
              <div className='w-full' key={index} hidden={selectedTabs !== item.name}>
                <div className='mt-2 h-full overflow-x-auto'>
                {messages.map((message, index) => (
                  <ParentMessageCard
                    key={index}
                    {...message}
                    onClick={() => {
                      setReadMessage(true);
                      setShowCompose(false);
                    } 
                    }
                  />
                ))}
                </div>
              </div>
            ))}
          </div>

          {readMessage ? (
            <div className="w-full h-full p-3 bg-white md:col-span-5 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 shadow absolute md:relative">
              <div className="border-b-2 border-gray-500 mb-2">
              <span className='md:hidden' 
                    onClick={() => { 
                      setReadMessage(false);
                      }}>
              <GoBackButton
                action="Back"
                location="Message list"
              />
              </span> 
              <div className="flex justify-between items-center">
                <div className="flex space-x-2 items-center">
                  <MdDelete color="#839098" size={24} />
                  <MdArchive color="#839098" size={24} />
                </div>
                <div className="flex space-x-2  items-center px-2 bg-white rounded shadow">
                  <MdCalendarToday color="#183F71" />
                  <CardBody name="April 4, 2022" color="blue" />
                </div>
              </div>
              <CardTitle additional="py-2" name="Teacher Eric" />
              <CardBody additional="py-2" name="Subject" />
              <CardBody
                additional="py-2"
                name="Pariatur deserunt fugiat dolore nostrud proident commodo proident tempor veniam sit pariatur laboris Lorem exercitation. Voluptate nisi Lorem quis commodo non culpa laborum ullamco quis aliquip aliqua cupidatat. Officia aliqua dolore veniam elit ex amet do sit incididunt. Lorem voluptate ea dolor do occaecat. Do amet in enim fugiat labore id proident. Irure ullamco nisi aliqua veniam et et ad."
              />
              </div>
              <div className="flex flex-wrap">
                <Attachment thumbnail="/images/thumbnail.png" />
                <Attachment thumbnail="/images/thumbnail.png" />
                <Attachment thumbnail="/images/thumbnail.png" />
                <Attachment thumbnail="/images/thumbnail.png" />
              </div>
              {showReply ? (
                <div>
                  <Input
                    elementType="textarea"
                    elementConfig={{
                      type: 'text',
                      placeholder: 'Message ...',
                      row: 3,
                    }}
                    value={reply}
                    changed={setReply}
                  />
                  <div className="py-2 flex space-x-2 items-center">
                    <Button
                      name="Send"
                      outline="false"
                      color="blue"
                      clicked={() => {}}
                    />
                    <MdImage color="#839098" />
                    <MdAttachFile color="#839098" />
                    <MdFormatUnderlined color="#839098" />
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap justify-between items-center py-5">
                  <div className="flex space-x-2">
                    <ButtonWithIcon
                      name="Reply"
                      outline="false"
                      color="blue"
                      isSquare
                      onClick={() => setShowReply(true)}
                    >
                      <BsReply color="#FFF" />
                    </ButtonWithIcon>
                    <ButtonWithIcon
                      name="Forward"
                      outline="false"
                      color="blue"
                      isSquare
                      onClick={() => setShowForwardModal(true)}
                    >
                      <BsForward color="#FFF" />
                    </ButtonWithIcon>
                  </div>
                  <ButtonWithIcon
                    name="Download All"
                    outline="true"
                    color="blue"
                    isSquare
                    onClick={() => {}}
                  >
                    <MdDownload color="#183F71" size={26} />
                  </ButtonWithIcon>
                </div>
              )}
            </div>
          ) : (
            <div className={`${showCompose === true && readMessage === false ? 'md:hidden' : ''} hidden md:w-full md:h-full md:flex flex-col items-center justify-center p-3 bg-white right-0 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 shadow`}>
              <div className="md:w-full flex flex-col items-center justify-center">
            <SectionTitle name="Select a user to see the messages" color="blue" />
            <FaSearch size={50} color='#E8AD26' />
              </div>
           </div>
          )}

          {showCompose === true && readMessage === false ? (
               <div className="w-full h-full flex flex-col bg-white p-2 shadow  overflow-x-auto absolute md:relative">
                <span className='md:hidden mb-2' onClick={() => setShowCompose(false)}>
                  <GoBackButton
                    action="Back"
                    location="Message list"
                  />
                </span> 
                <SectionTitle name="Compose" color="gray-700"/>
                <div className='w-full flex flex-row items-end justify-between p-2'>
                  <div className='w-40%'>
                <Input
                        label="Medium"
                        elementType="select"
                        elementConfig={{
                          startingValue: 'SELECT',
                          options: [
                            { value: 'email', displayValue: 'Email' },
                            { value: 'inapp', displayValue: 'In-app' },
                            { value: 'sms', displayValue: 'SMS' },
                          ],
                        }}
                        value={medium}
                        changed={setMedium}
                      />
                  </div>
                  <div className='w-40%'> 
                 <Input
                        label="Recipient"
                        elementType="select"
                        elementConfig={{
                          startingValue: 'SELECT',
                          options: [
                            { value: 'teacher', displayValue: 'Teacher' },
                            { value: 'school', displayValue: 'School' },
                          ],
                        }}
                        value={recipient}
                        changed={setRecipient}
                      />
                  </div>
                </div>
                <div className="p-2">
                {(medium === 'email' && recipient !== 'school') || medium === 'inapp' || medium === 'sms' ? (<Input
                 label="Sub group"
                 elementType="textarea"
                 elementConfig={{
                   type: 'text',
                   placeholder: '',
                   rows: 3,
                   disabled: true,
                 }}
                 value={text}
                 changed={setText}
                 additional="border-gray-100 resize-none"
               />
                ) : (
                <Input
                 label="Sub group"
                 elementType="textarea"
                 elementConfig={{
                   type: 'text',
                   placeholder: '',
                   rows: 3,
                 }}
                 value={text}
                 changed={setText}
               />)}
               
               {/* <SubgroupCard 
               bgColor="water justify-between"
               additionalStyles='w-40'
               > Father Abraham <AiOutlineClose size={15}/></SubgroupCard> */}
               {/* const search = 'Emile  Mucyo'
                  const name = search.substring(0, search.lastIndexOf("  "))
                  console.log(name); */}
                </div>
               <div className="w-50% p-2">
                {medium === 'sms' ? (
                   <Input
                   label="Subject"
                   elementType="input"
                   elementConfig={{
                     type: 'text',
                     placeholder: 'Add subject',
                     disabled: true,
                   }}
                   additional="border-gray-100"
                 />
                ) : (
                <Input
                   label="Subject"
                   elementType="input"
                   elementConfig={{
                     type: 'text',
                     placeholder: 'Add subject',
                   }}
                   value={subject}
                   changed={setSubject}
                 /> )}
               </div>
               <Input
                 label="Body"
                 elementType="textarea"
                 elementConfig={{
                   type: 'text',
                   placeholder: 'Message ...',
                   rows: 10,
                 }}
                 value={message}
                 changed={setMessage}
               />
               <div className="py-2 flex space-x-2 items-center">
                 <Button
                   name="Send"
                   outline="false"
                   color="blue"
                   clicked={() => {}}
                 />
                 <MdImage color="#839098" />
                 <MdAttachFile color="#839098" />
                 <MdFormatUnderlined color="#839098" />
                 <MdOutlineFormatAlignJustify color="#839098" />
                 <MdFormatAlignRight color="#839098" />
                 <MdFormatAlignLeft color="#839098" />
                 <MdOutlineFormatColorText color="#839098" />
               </div>
             </div>
          ) : ''}
      </div>
    </CommunicationLayout>
  );
};

export default Communication;
