import Layout from '../../../components/common/Layout';
import Courses from '../../../components/pages/teacher/summarynotes/SummaryNotes';

const SummaryNotes = () => {
  return (
    <Layout>
      <Courses />
    </Layout>
  );
};

export default SummaryNotes;
