import Layout from "../../../components/common/Layout";
import PaymentOptions from "../../../components/pages/student/user/payment/paymentOptions";

 const StudentPaymentOptions = () => {
    return (
      <Layout>
        <PaymentOptions />
      </Layout>
    );
  };

  export default StudentPaymentOptions;