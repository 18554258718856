import React, { useState, useRef } from 'react';
import { Button } from '../../elements/button';
import Spinner from '../../elements/spinner';
import { CardTitle } from '../../elements/text';

function ModalSimulation(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const iframe = useRef(null);
  const openModal = () => {
  };

  const closeModal = () => {
    props.closeTheModal()
  };
  const style = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100vw",
    height: "100%",
    zIndex: 1000,
    backgroundColor: "white"
  };
  const handleIframeLoad = () => {
    // The iframe has finished loading
    setLoading(false);
    const iframeDocument = iframe.current.contentDocument;

    const iframeWindow = iframe.contentWindow;

    // Send a message from the web application to the iframe
    // Enable the pointer-events property on the ifra
  };
  return (
    <div style={style}>
      <>{(
        <div>
          <CardTitle color="black" name={props.title} alignment="left" />
          <div style={{ float: "right" }}><Button
            name="Close"
            outline="true"
            color="red"
            clicked={() => {
              closeModal()
            }}
          /></div>
          {loading ? <Spinner size="40" color="blue" /> : ""}
          <iframe ref={iframe}
            onLoad={handleIframeLoad}
            allowfullscreen
            sandbox="allow-same-origin allow-scripts" className="w-full h-myscreen-sec" width={"100%"} height={"70vh"} key={props.mediasrc} src={props.mediasrc} title={props.type}></iframe>
        </div>
      )}</>
    </div>
  );
}


export default ModalSimulation;