import { connect } from "react-redux";
import { store } from "../../../store/store";
import { ButtonLink } from "../button";
import { HiOutlineExclamation } from "react-icons/hi";
import { CardSubText, CardTitle, SectionTitle } from "../text";
import { useTranslation } from "react-i18next";

const ContentModal = function (props) {
  const { t } = useTranslation();
  // const { contentmodalstatus } = store.getState().contentmodalreducer;
  const { contentmodalstatus, showcontentmodal, modalcontent } = props;
  return (
    <div
      style={{ margin: 20, top: 20 }}
      className={`${contentmodalstatus} fixed z-10 inset-0 overflow-y-auto`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >

      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start relative">
              <button
                className="absolute right-0 flex items-center justify-center h-12 w-12 rounded-full text-red text-xl bg-gray-100 hover:bg-red hover:text-white sm:h-10 sm:w-10"
                onClick={() => {
                  showcontentmodal("hidden");
                }}
              >
                &times;
              </button>
              {((modalcontent?.id > 0 || modalcontent?.panda_books_id)) ? <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left space-y-4">
                <SectionTitle
                  name={modalcontent?.subject || modalcontent?.panda_books_name}
                  alignment="left"
                  color="blue"
                />
                <div className="flex flex-col items-start space-y-2">
                  <CardTitle
                    name={t("Summary")}
                    alignment="left"
                    color="blue"
                  />
                  <CardSubText
                    name={
                      modalcontent?.name || modalcontent?.panda_books_subject ||  modalcontent?.topicarea
                    }
                    alignment="left"
                    color="gray-500"
                  />
                  <CardTitle
                    name={t("Contents")}
                    alignment="left"
                    color="blue"
                  />
                  <CardSubText
                    name={
                      modalcontent?.description ||
                      modalcontent?.panda_books_summary || modalcontent?.units 
                    }
                    alignment="left"
                    color="gray-500"
                  />
                </div>
                {(props?.modalcontent?.coursestructure_id > 0 || modalcontent?.units ) ? <div className="w-64 sm:w-100 mx-auto my-4 space-x-2 flex items-center justify-left">
                  <div className="flex items-center justify-left">
                    <HiOutlineExclamation className="text-lg" />
                    <CardSubText
                      name={t("You need to be logged in read the course")}
                      color="blue"
                      alignment="left"
                    />
                  </div>
                  <ButtonLink
                    page="enroll"
                    name={t("Enroll")}
                    outline="true"
                    color="red"
                  />

                </div> : ""}
              </div> : <div className="w-64 sm:w-100 mx-auto my-4 space-x-2 flex items-center justify-left">
                <div className="flex items-center justify-left">
                  <HiOutlineExclamation className="text-lg" />
                  <CardSubText
                    name={t("You need to be logged in read the course")}
                    color="blue"
                    alignment="left"
                  />
                </div>
                <ButtonLink
                  page="enroll"
                  name={t("Enroll")}
                  outline="true"
                  color="red"
                />

              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default SideBar;
const mapStateToProps = () => {
  return {
    contentmodalstatus: store.getState().contentmodalreducer.contentmodalstatus,
    modalcontent: store.getState().contentmodalreducer.modalcontent,
  };
};
const mapDispatchToProps = () => {
  return {
    showcontentmodal: (status) =>
      store.dispatch({
        type: "SHOW_CONTENT_MODAL",
        payload: status,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContentModal);
