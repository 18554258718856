import axios from "../../axios-base";
import { getCachedData } from "../../shared/caching";


//----Get ongoing challenges-----------
export const STUDENT_DASHBOARD_ONGOING_CHALLENGES_INIT =
  "STUDENT_DASHBOARD_ONGOING_CHALLENGES_INIT";
export const STUDENT_DASHBOARD_ONGOING_CHALLENGES_SUCCESS =
  "STUDENT_DASHBOARD_ONGOING_CHALLENGES_SUCCESS";
export const STUDENT_DASHBOARD_ONGOING_CHALLENGES_FAILED =
  "STUDENT_DASHBOARD_ONGOING_CHALLENGES_FAILED";

export function student_dashboard_ongoing_challenges_init() {
  return {
    type: STUDENT_DASHBOARD_ONGOING_CHALLENGES_INIT
  };
}
export function student_dashboard_ongoing_challenges_success(data) {
  return {
    type: STUDENT_DASHBOARD_ONGOING_CHALLENGES_SUCCESS,
    payload: data
  };
}
export function student_dashboard_ongoing_challenges_failed(error) {
  return {
    type: STUDENT_DASHBOARD_ONGOING_CHALLENGES_FAILED,
    payload: error
  };
}

export function student_dashboard_ongoing_challenges_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_ongoing_challenges_init());
    axios
      .post(
        `/opanda/tests/get_on_going_challenges`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_ongoing_challenges_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_ongoing_challenges_failed(err.message));
      });
  };
}
//----my launched challenges-----------
export const STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_INIT =
  "STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_INIT";
export const STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_SUCCESS =
  "STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_SUCCESS";
export const STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_FAILED =
  "STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_FAILED";

export function student_dashboard_my_launched_challenges_init() {
  return {
    type: STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_INIT
  };
}
export function student_dashboard_my_launched_challenges_success(data) {
  return {
    type: STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_SUCCESS,
    payload: data
  };
}
export function student_dashboard_my_launched_challenges_failed(error) {
  return {
    type: STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_FAILED,
    payload: error
  };
}

export function student_dashboard_my_launched_challenges_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_my_launched_challenges_init());
    axios
      .post(
        `/opanda/tests/get_launched_challenges`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_my_launched_challenges_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_my_launched_challenges_failed(err.message));
      });
  };
}
//----my attempted challenges-----------
export const STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_INIT =
  "STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_INIT";
export const STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_SUCCESS =
  "STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_SUCCESS";
export const STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_FAILED =
  "STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_FAILED";

export function student_dashboard_attempted_challenges_init() {
  return {
    type: STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_INIT
  };
}
export function student_dashboard_attempted_challenges_success(data) {
  return {
    type: STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_SUCCESS,
    payload: data
  };
}
export function student_dashboard_attempted_challenges_failed(error) {
  return {
    type: STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_FAILED,
    payload: error
  };
}

export function student_dashboard_attempted_challenges_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_attempted_challenges_init());
    axios
      .post(
        `/opanda/tests/get_attempted_challenges`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_attempted_challenges_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_attempted_challenges_failed(err.message));
      });
  };
}
//----Attempt a challenge-----------
export const STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_INIT =
  "STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_INIT";
export const STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_SUCCESS =
  "STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_SUCCESS";
export const STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_FAILED =
  "STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_FAILED";

export function student_dashboard_attempt_a_challenge_init() {
  return {
    type: STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_INIT
  };
}
export function student_dashboard_attempt_a_challenge_success(data) {
  return {
    type: STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_SUCCESS,
    payload: data
  };
}
export function student_dashboard_attempt_a_challenge_failed(error) {
  return {
    type: STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_FAILED,
    payload: error
  };
}

export function student_dashboard_attempt_a_challenge_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_attempt_a_challenge_init());
    axios
      .post(
        `/opanda/tests/attempt_a_challenge`,
        {
          user_id: data.user_id,
          challengeId: data.challengeId,
          academic_level:data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_attempt_a_challenge_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_attempt_a_challenge_failed(err.message));
      });
  };
}
//----Get My launched attempt answersheet
export const STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_INIT =
  "STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_INIT";
export const STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_SUCCESS =
  "STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_SUCCESS";
export const STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_FAILED =
  "STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_FAILED";

export function student_dashboard_my_launched_challenges_answer_sheet_init() {
  return {
    type: STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_INIT
  };
}
export function student_dashboard_my_launched_challenges_answer_sheet_success(data) {
  return {
    type: STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_SUCCESS,
    payload: data
  };
}
export function student_dashboard_my_launched_challenges_answer_sheet_failed(error) {
  return {
    type: STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_FAILED,
    payload: error
  };
}
export function student_dashboard_my_launched_challenges_answer_sheet_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_my_launched_challenges_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/get_my_launched_challenges_answer_sheet`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_my_launched_challenges_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_my_launched_challenges_answer_sheet_failed(err.message));
      });
  };
}
//----Get challenge attempt results--
export const STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_INIT =
  "STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_INIT";
export const STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_SUCCESS =
  "STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_SUCCESS";
export const STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_FAILED =
  "STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_FAILED";

export function student_dashboard_my_attempted_challenges_results_init() {
  return {
    type: STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_INIT
  };
}
export function student_dashboard_my_attempted_challenges_results_success(data) {
  return {
    type: STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_my_attempted_challenges_results_failed(error) {
  return {
    type: STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_FAILED,
    payload: error
  };
}
export function student_dashboard_my_attempted_challenges_results_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_my_attempted_challenges_results_init());
    axios
      .post(
        `/opanda/tests/get_my_attempted_challenges_results`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_my_attempted_challenges_results_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_my_attempted_challenges_results_failed(err.message));
      });
  };
}
//----Get challenge attempt answer sheet
export const STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_INIT =
  "STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_INIT";
export const STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_SUCCESS =
  "STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_SUCCESS";
export const STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_FAILED =
  "STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_FAILED";

export function student_dashboard_my_attempted_challenge_answer_sheet_init() {
  return {
    type: STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_INIT
  };
}
export function student_dashboard_my_attempted_challenge_answer_sheet_success(data) {
  return {
    type: STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_SUCCESS,
    payload: data
  };
}
export function student_dashboard_my_attempted_challenge_answer_sheet_failed(error) {
  return {
    type: STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_FAILED,
    payload: error
  };
}
export function student_dashboard_my_attempted_challenge_answer_sheet_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_my_attempted_challenge_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/get_my_attempted_challenge_answer_sheet`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_my_attempted_challenge_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_my_attempted_challenge_answer_sheet_failed(err.message));
      });
  };
}