import { BsCardList } from "react-icons/bs";
import Spinner from "../../../elements/spinner";
import { CardTitle } from "../../../elements/text";
import Item from "./item";
import { useSelector } from "react-redux";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiBook } from "react-icons/bi";
import { ImLab } from "react-icons/im";
import { shuffleSuggestedFeatures } from "../../../../functions/suggestedFeaturesUtils";
import { useMemo } from "react";

const FeaturesProposed = () => {
  //---Preload suggested features on the platform-- 
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );
  
  const shuffledFeatured = useMemo(() => {
    const features = [
      {
        name: "Lab Simulation",
        Icon: <ImLab color="#183F71" size="30" />,
        link: "/student/features/simulations",
      },
      {
        name: "View Courses",
        Icon: <BiBook color="#183F71" size="30" />,
        link: "/student/features/summarynotes/0/",
      },
      {
        name: "Challenge Peers",
        Icon: <HiOutlineUserGroup color="#183F71" size="30" />,
        link: "/student/features/challenges",
      },
      {
        name: "Past Papers",
        Icon: <BsCardList color="#183F71" size="30" />,
        link: "/student/features/pastpapers",
      },
      {
        name: "Library",
        Icon: <BsCardList color="#183F71" size="30" />,
        link: "/student/features/library",
      },
      {
        name: "Online class",
        Icon: <BsCardList color="#183F71" size="30" />,
        link: "/student/features/onlineclasses",
      },
      {
        name: "More practice",
        Icon: <BsCardList color="#183F71" size="30" />,
        link: "/student/features/practice",
      },
      {
        name: "My Classes",
        Icon: <BsCardList color="#183F71" size="30" />,
        link: "/student/features/myclasses",
      },
      {
        name: "Tests",
        Icon: <BsCardList color="#183F71" size="30" />,
        link: "/student/features/tests",
      },
    ];
    return shuffleSuggestedFeatures(
      studentDashboardhomePage.proposedFeatures.grouped_activities
        ? studentDashboardhomePage.proposedFeatures.grouped_activities
        : [],
      features
    );
  }, [studentDashboardhomePage.proposedFeatures.grouped_activities]);
 
  return (
    <div className="md:mx-4">
      <CardTitle name="What do you want to do today?" />
      {!studentDashboardhomePage.proposedFeatures_loading ? (
        <div className="flex flex-wrap">
          {shuffledFeatured.map((feature, index) => (
            <Item key={index} {...feature}>
              {feature.Icon}
            </Item>
          ))}
        </div>
      ) : (
        <Spinner size="20" color="blue" />
      )}
    </div>
  );
};

export default FeaturesProposed;
