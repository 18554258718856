import PageContent from "../pageContent";
import Notification from "../../../elements/notification/notification";

import { useHistory } from "react-router-dom";
import {
  CardTitle,
  FeatureTitle,
  PageBody,
  SectionTitle, 
} from "../../../elements/text";
import { BiBook } from "react-icons/bi";
import { BsCalendarPlus, BsCardList } from "react-icons/bs";
import Item from "../dashboard/item";
import { useEffect, useState } from "react";
import { Button } from "../../../elements/button";
import Modal from "../../../elements/modal/modal";
import EventCreationForm from "./forms/create";
import Input from "../../../elements/input";
import ScheduleList from "./schedulelist";

const ScheduleContainer = () => {
  const history = useHistory();
  const [activeclass, setactiveClass] = useState(["S1 A"]);
  const [events, setEvents] = useState([]);

  const [showCreateModal, setshowCreateModal] = useState(false);
  useEffect(() => {
    // events must sorted by date in a descending order
    setTimeout(() => {
      setEvents([
        {
          id: 1,
          type: "class",
          title: "Import google calendar",
          description: "Import google calendar Import google calendar",
          icon: <BiBook color="#183F71" size="30" />,
          unit: "Unit 2:gfghfgfgh gfgf",
          subject: "button",
          from: "2022-05-08",
          to: "2022-05-08",
          resources: ["", ""],
          online: false,
          link: "/teacher/features/schedule/1",
        },

        {
          id: 1,
          type: "class",
          title: "Import google calendar",
          description: "Import google calendar Import google calendar",
          icon: <BiBook color="#183F71" size="30" />,
          unit: "Unit 2:gfghfgfgh gfgf",
          subject: "button",
          from: "2022-05-08",
          to: "2022-05-08",
          resources: ["", ""],
          online: false,
          link: "/teacher/features/schedule/1",
        },
        {
          id: 4,
          type: "class",
          title: "Import google calendar",
          description: "Import google calendar Import google calendar",
          icon: <BiBook color="#183F71" size="30" />,
          unit: "Unit 2:gfghfgfgh gfgf",
          subject: "button",
          from: "2022-05-08",
          to: "2022-05-08",
          resources: ["", ""],
          online: true,
          meetingpassword: "sfsdfds",
          meetinglink: "https://kjdfjkd/mf.com",
          meetingid: "8755sss",
          link: "/teacher/features/schedule/4",
        },
        {
          id: 4,
          type: "class",
          title: "Import google calendar",
          description: "Import google calendar Import google calendar",
          icon: <BiBook color="#183F71" size="30" />,
          unit: "Unit 2:gfghfgfgh gfgf",
          subject: "button",
          from: "2022-05-08",
          to: "2022-05-08",
          resources: ["", ""],
          online: false,
          link: "/teacher/features/schedule/4",
        },
      ]);
    }, 500);
  });

  let actions = [
    {
      name: "Import google calendar",
      Icon: <BiBook color="#183F71" size="30" />,
      type: "button",
      link: "/link",
    },

    {
      name: "Schedule an activity",
      Icon: <BsCardList color="#183F71" size="30" />,
      type: "button",
      link: () => setshowCreateModal(true),
    },
  ];

  if (activeclass.length !== 0) {
    actions.push({
      name: "Import school calendar",
      Icon: <BsCalendarPlus color="#183F71" size="30" />,
      type: "button",
      link: "/link",
    });
  }

  return (
    <PageContent>
      <Modal
        medium
        show={showCreateModal}
        modalClosed={() => setshowCreateModal(false)}
      >
        <div className="flex flex-col">
          <EventCreationForm activeclass={activeclass} type="create"/>
        </div>
      </Modal>
      <div className="flex flex-col w-full py-3 px-5 h-full max-h-full overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <div className="flex flex-row align-center justify-between">
          <FeatureTitle name="Schedule" />

          {activeclass.length !== 0 ? (
            <div className="flex flex-col align-center justify-center space-x-2">
              <div className="place-self-center">
                <Button
                  name="Create"
                  outline="true"
                  color="blue"
                  additional="place-self-center"
                  clicked={() => {
                    setshowCreateModal(true);
                  }}
                  isSmall="true"
                />
              </div>
              <Input
                label=""
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  optionsType: "minimal",
                  options: activeclass,
                }}
                value={activeclass}
                changed={setactiveClass}
                validation={{ required: false }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        {activeclass.length === 0 || events.length === 0 ? (
          <div className="">
            <SectionTitle
              name="You do not have any scheduled activity yet"
              color="blue"
              alignment="center"
            />
            <CardTitle
              alignment="center"
              name="Here's a few things you can start with"
            />
            <div className="flex flex-wrap justify-center items-center">
              {actions.map((feature, index) => (
                <Item key={index} {...feature}>
                  {feature.Icon}
                </Item>
              ))}
              {activeclass.length === 0 ? (
                <>
                  <div className="flex flex-col justify-items-center space-y-2">
                    <PageBody
                      alignment="center"
                      color="blue"
                      name="You need to manage a class or subject to import your school calendar"
                    />
                    <Button
                      name="+ CREATE"
                      outline="true"
                      color="blue"
                      clicked={() => {
                        history.push(`/teacher/features/classes/create`);
                      }}
                      isSmall="true"
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="">
            <ScheduleList event={events} />
          </div>
        )}
      </div>
      <div className="w-40% h-full max-h-full overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <Notification auth />
      </div>
    </PageContent>
  );
};

export default ScheduleContainer;
