import { CardTitle } from '../../../elements/text';
import { FeatureItemCard } from '../../../elements/card';
import { useHistory } from 'react-router-dom';
import { changeChosenSubject } from '../../../../store/actions/features/pastpaper';
import { useDispatch } from 'react-redux';
import { bring_subject_image_src } from '../../../../functions/programs';

const PastpapersGroupItem = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <>
      {props.subject.pastpapers.filter(
        (pastpaper) =>
          pastpaper.type === props.type && pastpaper.from === props.from
      ).length > 0 && <CardTitle name={props.from} color="blue" />}
      <div className="md:grid grid-cols-3">
        {props.subject.pastpapers
          .filter(
            (pastpaper) =>
              pastpaper.type === props.type && pastpaper.from === props.from
          )
          .map((pastpaper, index) => (
            <FeatureItemCard
              key={index}
              {...pastpaper}
              title={props.subject.name}
              imgsrc = {bring_subject_image_src(props.subject.name).imgsrc}
              feature="past paper"
              clicked={() => {
               // dispatch(changeChosenSubject(props.subject.name));
                history.push(`/teacher/features/pastpapers/${pastpaper.id}?subject=${props.subject.name}`); //here must a paper id not subject
              }}
            />
          ))}
      </div>
    </>
  );
};

export default PastpapersGroupItem;
