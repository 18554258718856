import Layout from '../../../components/common/Layout';
import Communication from '../../../components/pages/school/communication/Communication';

const TeacherCommunicationContainer = () => {
  return (
    <Layout>
      <Communication />
    </Layout>
  );
};

export default TeacherCommunicationContainer;
