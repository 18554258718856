import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "../pageContent";
import { useTabs, TabPanel } from "react-headless-tabs";
import TabSelector from "./TabSelector";
import { useHistory } from "react-router-dom";
import { SectionTitle } from "../../../elements/text";
import { Button } from "../../../elements/button";
import Notification from "../../../elements/notification/notification";
import ClassList from "./classlist";
import RemindClass from "./remindadmin";
import ClassSummary, {
  ActivityChart as ClassActivityChart,
  AssessmentSummary as ClassAssessmentSummary,
  GoalSummary as ClassGoalSummary,
  StudentSession,
} from "./classsummary";
import SubjectList from "./subjects/list";
import SubjectSummary, {
  ActivityChart,
  AssessmentSummary,
  GoalSummary,
} from "./subjects/summary";
import RemindAdmin from "./subjects/remindadmin";
import { school_dashboard_class_count_start } from "../../../../store/actions/school.dashboard.classes";

const ClassesContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth);
  const activeTab = useSelector((state) => state.classtab.activetab);
  const tabs = useSelector((state) => state.classtab.tabs);
  const [selectedTab, setSelectedTab] = useTabs(tabs);
  const schoolDashboardClasses = useSelector(
    (state) => state.schoolDashboardClasses
  );
  useEffect(() => {
    dispatch(
      school_dashboard_class_count_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
  }, []);
  // setSelectedTab(activeTab);
  const classesData = [
    { key: "Classes", number: 2 },
    { key: "Students", number: 30 },
    { key: "Goal acheiving", number: 0 },
  ];
  const [classes, setClasses] = useState([
    ["1", "S2 B", "Pending", "Mucyo Eric", "Mucyo Eric"],
    ["2", "S1 A", "Active", "Mucyo Eric", "Mucyo Eric"],
    ["3", "S2 B", "Active", "Mucyo Eric", "Mucyo Eric"],
    ["4", "S5 MCB", "Pending", "Mucyo Eric", "DOS"],
  ]);
  const [activeclass, setactiveClass] = useState(["S2 B", "S1 A"]);
  const [showlist, setShowlist] = useState(false);

  const [subjects, setSubjects] = useState([
    ["1", "Math", "S2 B", "Assigned to you", "DOS"],
    ["2", "Biology", "S2 B", "Waiting approval", "Mucyo Eric"],
  ]);
  const [activesubject, setactiveSubject] = useState(["Math"]);
  const [showsubjectlist, setShowsubjectlist] = useState(false);
  const subjectsData = [
    { key: "Subjects", number: 2 },
    { key: "Goal acheiving", number: 10 },
  ];

  return (
    <PageContent>
      <div className="flex flex-col w-full py-3 px-5 h-full max-h-full overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <nav className="flex justify-start space-x-4">
          <TabSelector
            isActive={selectedTab === "Class"}
            title="Class"
            new={4}
            onClick={() => setSelectedTab("Class")}
          />
          <TabSelector
            isActive={selectedTab === "Subjects"}
            title="Subjects"
            new={9}
            onClick={() => setSelectedTab("Subjects")}
          />
        </nav>
        <div className="flex-1 w-full flex justify-between">
          <TabPanel hidden={selectedTab !== "Class"} className="w-full h-full">
            {classes.length > 0 ? (
              <div className="w-full flex flex-col items-start">
                <div className="space-y-2 w-full">
                  <div className="float-right flex flex-row items-center justify-center">
                    <Button
                      name="List"
                      outline="false"
                      color="blue"
                      clicked={() => {
                        showlist ? setShowlist(false) : setShowlist(true);
                      }}
                      isSmall="true"
                    />
                    <Button
                      name="Create"
                      outline="true"
                      color="blue"
                      clicked={() => {
                        history.push(`/school/features/classes/create`);
                      }}
                      isSmall="true"
                    />
                  </div>

                  <ClassList data={classes} show={showlist} />

                  {activeclass.length > 0 ? (
                    <>
                      <ClassSummary data={classesData} show={!showlist} />
                      {/* <SuggestClass
                        classes={activeclass}
                        setactiveclass={setactiveClass}
                      /> */}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <ClassActivityChart
                          title="My activities"
                          color="blue"
                          titleColor="blue"
                          activities={[
                            {
                              name: "Simulations",
                              value: 3,
                              color: "red",
                              hexColor: "#BE1E2D",
                            },
                            {
                              name: "Courses",
                              value: 2,
                              color: "blue",
                              hexColor: "#183F71",
                            },
                            {
                              name: "Test",
                              value: 5,
                              color: "yellow",
                              hexColor: "#E8AD26",
                            },
                          ]}
                          time="This week"
                        />

                        <ClassAssessmentSummary
                          title="Assessments"
                          color="blue"
                          titleColor="blue"
                          assessments={[
                            {
                              name: "Quiz 1",
                              subject: "Math",
                              submissions: 0,
                              value: 3,
                              duedate: "2021-06-01",
                            },
                            {
                              name: "Quiz 1",
                              subject: "Math",
                              submissions: 1,
                              value: 3,
                              duedate: "2021-06-01",
                            },
                          ]}
                          time="This week"
                        />

                        <ClassGoalSummary
                          title="Goal 1: Pass all unit tests"
                          color="blue"
                          titleColor="blue"
                          goaldata={[
                            {
                              name: "Page A",
                              uv: 4000,
                            },
                            {
                              name: "Page B",
                              uv: 3000,
                            },
                            {
                              name: "Page C",
                              uv: 2000,
                            },
                            {
                              name: "Page D",
                              uv: 2780,
                            },
                            {
                              name: "Page E",
                              uv: 1890,
                            },
                            {
                              name: "Page F",
                              uv: 239,
                            },
                            {
                              name: "Page G",
                              uv: 3490,
                            },
                          ]}
                          time="This week"
                        />

                        <GoalSummary
                          title="Goal 2: Do all end unit tests"
                          color="blue"
                          titleColor="blue"
                          goaldata={[
                            {
                              name: "Page A",
                              uv: 4000,
                            },
                            {
                              name: "Page B",
                              uv: 3000,
                            },
                            {
                              name: "Page C",
                              uv: 2000,
                            },
                            {
                              name: "Page D",
                              uv: 2780,
                            },
                            {
                              name: "Page E",
                              uv: 1890,
                            },
                            {
                              name: "Page F",
                              uv: 239,
                            },
                            {
                              name: "Page G",
                              uv: 3490,
                            },
                          ]}
                          time="This week"
                        />
                        <StudentSession
                          title="Student's session"
                          color="blue"
                          titleColor="blue"
                          data={[
                            { name: "Page A", uv: 200, pv: 2400, amt: 2400 },
                            { name: "Page A", uv: 300, pv: 2400, amt: 2400 },
                            { name: "Page A", uv: 100, pv: 2400, amt: 2400 },
                          ]}
                          time="This week"
                        />
                      </div>
                    </>
                  ) : (
                    <RemindClass />
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col items-start">
                <div className="w-full flex flex-col items-center">
                  <div className="w-64 h-64 mx-auto mt-20">
                    <img
                      className="w-full h-full object-cover object-center"
                      src="/images/noclass.png"
                      alt="img"
                    />
                  </div>
                  <div className="text-center">
                    <SectionTitle
                      name="You do not manage any class yet"
                      color="blue"
                    />
                    <Button
                      name="+ CREATE"
                      outline="true"
                      color="blue"
                      clicked={() => {
                        history.push(`/school/features/classes/create`);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel
            hidden={selectedTab !== "Subjects"}
            className="w-full h-full"
          >
            {activeclass.length > 0 ? (
              subjects.length > 0 ? (
                <div className="w-full flex flex-col items-start">
                  <div className="space-y-2 w-full">
                    <div className="float-right flex flex-row items-center justify-center">
                      <Button
                        name="List"
                        outline="false"
                        color="blue"
                        clicked={() => {
                          showsubjectlist
                            ? setShowsubjectlist(false)
                            : setShowsubjectlist(true);
                        }}
                        isSmall="true"
                      />
                      <Button
                        name="Create"
                        outline="true"
                        color="blue"
                        clicked={() => {
                          history.push(`/school/features/subjects/create`);
                        }}
                        isSmall="true"
                      />
                    </div>

                    <SubjectList data={subjects} show={showsubjectlist} />

                    {activesubject.length > 0 ? (
                      <>
                        <SubjectSummary
                          data={subjectsData}
                          show={!showsubjectlist}
                        />
                        {/* <SuggestActivity
                          classes={activeclass}
                          subjects={activesubject}
                          setactiveclass={setactiveSubject}
                        /> */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                          <ActivityChart
                            title="My activities"
                            color="blue"
                            titleColor="blue"
                            activities={[
                              {
                                name: "Simulations",
                                value: 3,
                                color: "red",
                                hexColor: "#BE1E2D",
                              },
                              {
                                name: "Courses",
                                value: 2,
                                color: "blue",
                                hexColor: "#183F71",
                              },
                              {
                                name: "Test",
                                value: 5,
                                color: "yellow",
                                hexColor: "#E8AD26",
                              },
                            ]}
                            time="This week"
                          />

                          <AssessmentSummary
                            title="Assessments"
                            color="blue"
                            titleColor="blue"
                            assessments={[
                              {
                                name: "Quiz 1",
                                subject: "Math",
                                submissions: 0,
                                value: 3,
                                duedate: "2021-06-01",
                              },
                              {
                                name: "Quiz 1",
                                subject: "Math",
                                submissions: 1,
                                value: 3,
                                duedate: "2021-06-01",
                              },
                            ]}
                            time="This week"
                          />

                          <GoalSummary
                            title="Goal 1: Pass all unit tests"
                            color="blue"
                            titleColor="blue"
                            goaldata={[
                              {
                                name: "Page A",
                                uv: 4000,
                              },
                              {
                                name: "Page B",
                                uv: 3000,
                              },
                              {
                                name: "Page C",
                                uv: 2000,
                              },
                              {
                                name: "Page D",
                                uv: 2780,
                              },
                              {
                                name: "Page E",
                                uv: 1890,
                              },
                              {
                                name: "Page F",
                                uv: 239,
                              },
                              {
                                name: "Page G",
                                uv: 3490,
                              },
                            ]}
                            time="This week"
                          />

                          <GoalSummary
                            title="Goal 2: Do all end unit tests"
                            color="blue"
                            titleColor="blue"
                            goaldata={[
                              {
                                name: "Page A",
                                uv: 4000,
                              },
                              {
                                name: "Page B",
                                uv: 3000,
                              },
                              {
                                name: "Page C",
                                uv: 2000,
                              },
                              {
                                name: "Page D",
                                uv: 2780,
                              },
                              {
                                name: "Page E",
                                uv: 1890,
                              },
                              {
                                name: "Page F",
                                uv: 239,
                              },
                              {
                                name: "Page G",
                                uv: 3490,
                              },
                            ]}
                            time="This week"
                          />
                        </div>
                      </>
                    ) : (
                      <RemindAdmin />
                    )}
                  </div>
                </div>
              ) : (
                <div className="w-full flex flex-col items-start">
                  <div className="w-full flex flex-col items-center">
                    <div className="w-64 h-64 mx-auto mt-20">
                      <img
                        className="w-full h-full object-cover object-center"
                        src="/images/noclass.png"
                        alt="img"
                      />
                    </div>
                    <div className="text-center">
                      <SectionTitle
                        name="You do not have any subjects yet"
                        color="blue"
                      />
                      <Button
                        name="+ CREATE"
                        outline="true"
                        color="blue"
                        clicked={() => {
                          history.push(`/school/features/subjects/create`);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            ) : (
              <RemindClass />
            )}
          </TabPanel>
        </div>
      </div>
    </PageContent>
  );
};

export default ClassesContainer;
