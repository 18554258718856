import { Fragment } from 'react';
import Backdrop from '../backdrop';
import { IoClose } from 'react-icons/io5';

export const CustomModal = (props) => {
  let classes =
    'fixed bg-white border border-solid border-gray-500 shadow p-4 box-border transition-all duration-300 ease-out rounded-lg';
  if (props.show) {
    classes =
      'w-90% fixed z-50 bg-white border border-solid border-gray-500 shadow p-4 box-border transition-all duration-300 ease-out rounded-lg';
  }
  return (
    <Fragment>
      <Backdrop show={props.show} clicked={props.modalClosed} />
      <div
        className={classes + ' hidden md:block'}
        style={{
          top: props.top,
          left: props.left,
          right: props.right,
          width: props.width,
          transform: props.show ? 'translate(0,0)' : 'translate(-1000%,-1000%)',
          opacity: props.show ? '1' : '0',
        }}
      >
        <div
          className="absolute top-1 right-1 cursor-pointer"
          onClick={props.modalClosed}
        >
          <IoClose size={28} />
        </div>
        {props.children}
      </div>
      <div
        className={classes + ' w-90% m-auto md:hidden'}
        style={{
          left: 0,
          right: 0,
          top: props.top,
          transform: props.show ? 'translate(0,0)' : 'translate(-1000%,-1000%)',
          opacity: props.show ? '1' : '0',
        }}
      >
        <div
          className="absolute top-1 right-1 cursor-pointer"
          onClick={props.modalClosed}
        >
          <IoClose size={28} />
        </div>
        {props.children}
      </div>
    </Fragment>
  );
};
