import {
  CardBody,
  CardTitle,
  SectionTitle,
} from '../../../../elements/text';
import { Button } from '../../../../elements/button';
import { useState } from 'react';
// import CountDown from '../../../../elements/CountDown';
import celebration from '../../../../../images/celebration.png';
// import { FeatureItemCard } from '../../../../elements/card';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { student_dashboard_create_teacher_made_challenge_start, student_dashboard_homepage_get_teacher_made_test_answer_sheet_start, student_dashboard_homepage_results_for_teacher_made_test_start } from '../../../../../store/actions/student.dashboard.tests.subjects.units';
import failed from '../../../../../images/test-failed-svgrepo-com.svg';
import Spinner from '../../../../elements/spinner';
import Uiteachermadesheet from './uiteachermadesheet/uiteachermadesheet';


const TestResults = (props) => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const studentDashboardTestsSubjectsUnits = useSelector(
    (state) => state.studentDashboardTestsSubjectsUnits
  );
  const history = useHistory();
  // const autoTestProgressList = useSelector(
  //   (state) => state.autoTestProgressList
  // );
  //---
  const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  const test_params = useParams();
  const [checkAnswerSheetView, setCheckAnswerSheetView] = useState(false);
  useEffect(() => {
    dispatch(
      student_dashboard_homepage_results_for_teacher_made_test_start({
        token: userAth.token,
        test_id: props.data1,
        user_id: userAth.userId,
      })
    );
  }, []);
  //---
  const get_teacher_made_tests_results = studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_teacher_made_test;
  const answer_sheet = studentDashboardTestsSubjectsUnits.dashboard_homepage_get_teacher_made_test_answer_sheet_test;
  //--------------

  return (
    (!checkAnswerSheetView && get_teacher_made_tests_results[0]) ? <>
      <SectionTitle name={(get_teacher_made_tests_results[0][0])} color="blue" />
      {<CardBody name={(get_teacher_made_tests_results[0][1])} />}
      <div className="w-full flex flex-col md:flex-row py-5">
        <div className="md:w-50%">
          <div className="flex space-x-2 md:space-x-10">
            <div className="w-28 h-28 my-3 mr-3 p-2 rounded-xl bg-yellow">
              {(!studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_teacher_made_test_loading) ? ((get_teacher_made_tests_results[0][2]) ? <img className="w-full h-full" src={celebration} alt="" /> : <img className="w-full h-full" src={failed} alt="" />) : ""}
            </div>
            <div>
              <CardTitle name={`You have got ${(get_teacher_made_tests_results[0][5]).toFixed(2)}%`} color="blue" />
              {(!studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_teacher_made_test_loading) ? <CardBody name={"Marks: " + get_teacher_made_tests_results[0][6].toFixed(2) + "/" + get_teacher_made_tests_results[0][7]} /> : ""}
              <div className="py-3 flex space-x-2">
                <Button
                  name="Redo"
                  outline="true"
                  color="blue"
                  clicked={() => {
                    history.replace(`/`);
                    setTimeout(() => history.replace(`/student/features/tests/${test_params.testId}?scheduled=false&redo=true`), 10);
                  
                  }}
                />
                {/* <Button
                  name="Share"
                  outline="true"
                  color="blue"
                  clicked={() => { }}
                /> */}
              </div>
              {(!studentDashboardTestsSubjectsUnits.dashboard_create_teacher_made_challenge_launched && !studentDashboardTestsSubjectsUnits.dashboard_create_teacher_made_challenge_loading && !studentDashboardTestsSubjectsUnits.dashboard_create_teacher_made_challenge_launched_attempt) ? <Button
                name="Challenge Others"
                outline="true"
                color="blue"
                clicked={() => {
                  dispatch(
                    student_dashboard_create_teacher_made_challenge_start({
                      token: userAth.token,
                      test_id_by_user: test_params.testId,
                      user_id: userAth.userId,
                      answering_map_id: get_teacher_made_tests_results[1],
                      userMarks: (get_teacher_made_tests_results[0][5]).toFixed(2)
                    })
                  );
                }}
              /> : (!studentDashboardTestsSubjectsUnits.dashboard_create_teacher_made_challenge_launched && studentDashboardTestsSubjectsUnits.dashboard_create_teacher_made_challenge_loading) ? <Spinner size="12" color="blue" /> : <div>{(studentDashboardTestsSubjectsUnits.dashboard_create_teacher_made_challenge.length > 0) ? <span style={{ color: "green" }}>Challenge launched successfully! Hooray!!</span> : <span style={{ color: "red" }}>This challenge cannot be launched!! Try again later!!</span>}</div>}
            </div>
          </div>
          <Button
            name="Check answers"
            outline="false"
            color="red"
            clicked={() => {
              dispatch(
                student_dashboard_homepage_get_teacher_made_test_answer_sheet_start({
                  token: userAth.token,
                  test_id: props.data1,
                  user_id: userAth.userId,
                  answering_map_id: get_teacher_made_tests_results[1]
                })
              );
              setCheckAnswerSheetView(true);
            }}
          />
        </div>
        <div className="md:w-50%">
          {/*<CardTitle name="Other work" color="blue" />*/}
          {/*<CardBody name="Tests" color="blue" />*/}
          <div className="md:grid grid-cols-2">
            {(!studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_teacher_made_test_loading) ? <div>
              {/*get_teacher_made_tests_results[1]*/}

            </div> : <Spinner size="12" color="blue" />}
            {/*<FeatureItemCard
                  feature="test"
                  title="Test title"
                  level={6}
                  unit="Unit 1"
                  imgsrc="/images/preview/bio.svg"
                  type="Test"
                  due="Today"
                  isMyTest={false}
                  subject="Biology"
                  clicked={() => {
                    history.push(`/student/features/tests/${1}`); //here must a test id
                  }}
                />
                <FeatureItemCard
                  feature="test"
                  title="Test title"
                  level={6}
                  unit="Unit 1"
                  imgsrc="/images/preview/bio.svg"
                  type="Quiz"
                  due="Today"
                  isMyTest={false}
                  subject="Biology"
                  clicked={() => {
                    history.push(`/student/features/tests/${1}`); //here must a test id
                  }}
                />*/}
          </div>
        </div>
      </div></> : (checkAnswerSheetView && studentDashboardTestsSubjectsUnits.dashboard_homepage_get_teacher_made_test_answer_sheet_loading) ? <Spinner size="12" color="blue" /> : checkAnswerSheetView && <Uiteachermadesheet answer_sheet={answer_sheet} />
  );
};

export default TestResults;
