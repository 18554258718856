import { CardBody, CardTitle, FeatureTitle } from "../../../../elements/text";
import Input from "../../../../elements/input";
import { Button, ButtonLink } from "../../../../elements/button";
import { useState } from "react";
import { useSelector } from "react-redux";

const Notification = () => {
  const userEmail = useSelector((state) => state.auth.user);
  const [email, setEmail] = useState(userEmail);

  return (
    <div className="pt-2 px-2 bg-white md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
      <FeatureTitle name="Account information" color="black" />

      <div className="relative overflow-x-auto sm:rounded-lg">
        <CardTitle name="Account settings" color="black" />
        <div class="grid md:grid-cols-4 gap-4 mb-2">
          <div>
            <Input
              label="Email"
              elementType="input"
              elementConfig={{
                type: "email",
                placeholder: "Email address",
              }}
              value={email}
              changed={setEmail}
              validation={{ required: true }}
              shouldValidate
              error="Email address is required"
            />
          </div>

          <div className="flex align-end flex-end mt-auto">
            <ButtonLink
              page="whoisitfor"
              name="Save"
              outline="false"
              color="red"
            />
          </div>
        </div>

        <CardTitle name="Change password" color="black" />
        <div class="grid md:grid-cols-4 gap-4 mb-2">
          <div>
            <Input
              label="Password"
              elementType="password"
              elementConfig={{
                type: "email",
                placeholder: "Password",
              }}
              value={null}
              validation={{ required: true }}
              shouldValidate
              error="Email address is required"
            />
          </div>
          <div>
            <Input
              label="Confirm password"
              elementType="password"
              elementConfig={{
                type: "email",
                placeholder: "Password",
              }}
              value={null}
              validation={{ required: true }}
              shouldValidate
              error="Email address is required"
            />
          </div>

          <div className="flex align-end flex-end mt-auto">
            <ButtonLink
              page="whoisitfor"
              name="Save"
              outline="false"
              color="red"
            />
          </div>
        </div>

        <CardTitle name="Account deletion" color="black" />
        <CardBody
          name="By consenting to delete your account, you consent to the following:
1. Lorem ipsum skjfskdfjsd fkdjfa ;sjfkjdkf askdj d
2. sfkjskdfj laskdjf sdl fkdjfla kjd 
3. sdflsjfdsk sd kdsjflsalkdjf asld

If you agree, type yes here below"
          color="black"
        />
        <div class="grid md:grid-cols-4 gap-4">
          <div>
            <Input
              label="If you agree, type yes here below"
              elementType="password"
              elementConfig={{
                type: "email",
                placeholder: "Password",
              }}
              value={null}
              validation={{ required: true }}
              shouldValidate
              error="Email address is required"
            />
          </div>
          <div className="flex align-end flex-end mt-auto">
            <ButtonLink
              page="whoisitfor"
              name="Delete"
              outline="false"
              color="red"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
