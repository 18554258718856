import PageContent from '../../pageContent';
import parse from 'react-html-parser';
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from '../../../../elements/text';
import { Button, GoBackButton } from '../../../../elements/button';
import { useState } from 'react';
import CountDown from '../../../../elements/CountDown';
import celebration from '../../../../../images/celebration.png';
import { FeatureItemCard } from '../../../../elements/card';
import { useHistory } from 'react-router-dom';
import CompleteSentence from '../../../../common/features/tests/testtypes/completesentence/CompleteSentence';
import OpenEnded from '../../../../common/features/tests/testtypes/openended/OpenEnded';
import DragAndDrop from '../../../../common/features/tests/testtypes/draganddrop/DragAndDrop';
import { useParams } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { student_dashboard_homepage_auto_test_start, student_dashboard_homepage_save_auto_made_test_start, student_dashboard_homepage_save_auto_made_test_start_auto, student_dashboard_homepage_save_teacher_made_test_start, student_dashboard_homepage_save_teacher_made_test_start_for_challenges, student_dashboard_homepage_save_teacher_made_test_start_for_challenges_auto, student_dashboard_homepage_save_teacher_made_test_start_for_challenges_start, student_dashboard_homepage_teacher_made_test_start } from '../../../../../store/actions/student.dashboard.tests.subjects.units';
import { teacher_tests_questions_data } from '../../../../../functions/Tests';
import Spinner from '../../../../elements/spinner';
import SinglechoiceAnswerOptionGroup from './components/singlechoiceSelector';
import MultichoiceAnswerOptionGroup from './components/multichoiceSelector';
import { auto_test_questions_data } from '../../../../../functions/autoTest';
import NotFound from '../../../../common/features/NotFound';
import SinglechoiceAnswerOptionGroupAutoTest from './components/autotest/singlechoiceSelector';
import MultichoiceAnswerOptionGroupAutoTest from './components/autotest/multichoiceSelector';
import MatchingTypeAutoQuestion from './components/autotest/MatchingTypeAutoQuestion';
import FillinTypeAutoQuestion from './components/autotest/FillinTypeAutoQuestion';
import TestResults from './testsresults/TestResults';
import TestResultsAuto from './testsresults/TestResultsAuto';
import eventBus from '../../../../../utils/eventBus';
const { v4: uuidv4 } = require("uuid")
const TestTeacherMade = (props) => {
  const test_params = useParams();
  const history = useHistory();
  const userAth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  //---
  const [testType, setTestType] = useState("");
  const [backButtonActive, setBackButtonActive] = useState(false);
  const [timeSet, setTimeSet] = useState(true);
  const backButton = useRef(null);

  //---
  //const [multipleChoicePicks, setMultipleChoicePicks] = useState([]);
  const [singleChoicePicks, setsingleChoicePicks] = useState([]);
  const [multipleChoicePicks, setmultipleChoicePicks] = useState([]);
  const [dragAndDrop, setdragAndDrop] = useState([]);
  const [fillin, setFillin] = useState([]);
  const [openEnded, setOpenEnded] = useState([]);
  const [matchingTypeAuto, setMatchingTypeAuto] = useState([]);
  const [fillinTypeAuto, setFillinTypeAuto] = useState([]);
  //const singleChoicePicks = [];
  const studentDashboardTestsSubjectsUnits = useSelector(
    (state) => state.studentDashboardTestsSubjectsUnits
  );
  const autoClick = useRef(null);
  const teacherMadeClick = useRef(null);

  useEffect(() => {
    //---Auto test
    if (props.testId === "0") {
      //----------------
      setTestType("auto");
    } else {
      //-------------------
      setTestType("byteacher");

    }

    const eventListenerAuto = () => {
        saveAutoMadeTestAnswer_auto() 
    };
    const eventListenerTeacherMade = () => {
      saveTeacherMadeTestAnswer_auto() 
    };
    const finalizeTestSave = () => {
      setShowResults(true);
    }
    eventBus.on('saveAutomatedTestWhenTimeOut', eventListenerAuto);
    eventBus.on('saveTeacherMadeTestWhenTimeOut', eventListenerTeacherMade);
    eventBus.on('showResultsAfterTestSave', finalizeTestSave);


    return () => {
      // Unsubscribe from the event when the component unmounts
      eventBus.off('saveAutomatedTestWhenTimeOut', eventListenerAuto);
      eventBus.off('saveTeacherMadeTestWhenTimeOut', eventListenerTeacherMade);
      eventBus.off('showResultsAfterTestSave', finalizeTestSave);
    };
  }, []);
  //----
  //-----
  const auto = auto_test_questions_data([]);
  const auto_nber_of_questions = auto_test_questions_data([])[2];
  const auto_total_marks = auto_test_questions_data([])[3];
  const auto_test = auto_test_questions_data([])[0];
  const test = teacher_tests_questions_data(props.dashboard_homepage_teacher_made_test)[0];
  const testid = teacher_tests_questions_data(props.dashboard_homepage_teacher_made_test)[2];
  const total_time = ((testType !== "auto")) ? teacher_tests_questions_data(props.dashboard_homepage_teacher_made_test)[1] : auto_test_questions_data([])[1];
  if (timeSet && total_time > 0) {
    setTimeSet(false);
  }
  const Test = {
    panda_test_map_2020_03_id: 7,
    uuid: '4d040124-21ed-4519-a334-d6a90d9c90f8',
    panda_test_map_2020_03_flow_of_questions_answers_other_answers: 'llllllll',
    panda_test_map_2020_03_flow_of_questions_answers_track:
      'multiple_choice~~1~~12020CyumaIsTheBest2020multiple_choice~~2~~22020CyumaIsTheBest2020complete_sentence~~3~~32020CyumaIsTheBest2020open_ended~~4~~42020CyumaIsTheBest2020drag_and_drop~~5~~5',
    panda_test_map_2020_03_flow_of_questions:
      '1~#AllCyumaBases~#multiple_choice~#$~#$5#~2~#AllCyumaBases~#multiple_choice~#$~#$5#~3~#AllCyumaBases~#complete_sentence~#$~#$5#~4~#AllCyumaBases~#open_ended~#$~#$5#~5~#AllCyumaBases~#drag_and_drop~#$',
    panda_test_map_2020_03_access_code: 'ACCESS CODE',
    panda_test_map_2020_03_creator: 11,
    panda_test_map_2020_03_instructions: '<p>Test with all questions</p>',
    panda_test_map_2020_03_nber_of_questions: 5,
    panda_test_map_2020_03_title: 'Test with all questions',
    panda_test_map_2020_03_class: '4',
    panda_test_map_2020_03_unit: 'Test with all questions',
    panda_test_map_2020_03_academic_level: '4',
    panda_test_map_2020_03_subject: 'Test with all questions',
    panda_test_map_2020_03_visibility: 0,
    panda_test_map_2020_03_regdate: '2022-05-25T06:56:47.000Z',
    panda_test_map_2020_03_test_id: 0,
    panda_test_map_2020_03_test_type: 'quiz',
    questionsmap: [
      {
        questiondata: {
          panda_fill_in_2020_03_id: 8,
          uuid: 'ef4874f7-f060-4444-8073-1bdbc418a048',
          panda_fill_in_2020_03_access_code: 'CODE',
          panda_fill_in_2020_03_creator: 0,
          panda_fill_in_2020_03_regdate: '2022-05-25T06:56:47.000Z',
          panda_fill_in_2020_03_question_data_answers:
            'developer ~----~OGenius',
          panda_fill_in_2020_03_question_data:
            "<p>I'm a software developer at OGenius</p>",
          panda_fill_in_2020_03_duration_of_test: 0,
          panda_fill_in_2020_03_test_difficulty: 'DIF',
          panda_fill_in_2020_03_unit: 'UNit 3',
          panda_fill_in_2020_03_marks: '2',
          panda_fill_in_2020_03_duration: '2',
          panda_fill_in_2020_03_test_id: '7',
        },
        questiontype: 'complete_sentence',
      },
      {
        questiondata: {
          panda_open_ended_2020_03_id: 5,
          uuid: '550005e8-6b0f-4e50-a250-5e824ee5492a',
          panda_open_ended_2020_03_marked: 'Marked',
          panda_open_ended_2020_03_regdate: '2022-05-25T06:56:47.000Z',
          panda_open_ended_2020_03_test_id: 7,
          panda_open_ended_2020_03_teacher_answer:
            "<p>It's an open ended answer</p>",
          panda_open_ended_2020_03_time_in_minutes: 2,
          panda_open_ended_2020_03_marks: 2,
          panda_open_ended_2020_03_question_data:
            "<p>It's an open ended question</p>",
        },
        questiontype: 'open_ended',
      },
      {
        questiondata: {
          panda_drag_and_drop_2020_03_id: 8,
          uuid: '46d13658-b269-4bd4-a8aa-530b48f3c962',
          panda_drag_and_drop_2020_03_access_code: 'CODE',
          panda_drag_and_drop_2020_03_creator: 0,
          panda_drag_and_drop_2020_03_regdate: '2022-05-25T06:56:47.000Z',
          panda_drag_and_drop_2020_03_question_data_answers:
            'ended ~----~question',
          panda_drag_and_drop_2020_03_question_data:
            "<p>It's an open ended question</p>",
          panda_drag_and_drop_2020_03_duration_of_test: 20,
          panda_drag_and_drop_2020_03_test_difficulty: 'DIFF',
          panda_drag_and_drop_2020_03_unit: 'UNit 4',
          panda_drag_and_drop_2020_03_marks: '2',
          panda_drag_and_drop_2020_03_duration: '2',
          panda_drag_and_drop_2020_03_test_id: '7',
        },
        questiontype: 'drag_and_drop',
      },
    ],
  };

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [teacherMadeTestSaveInit, setTeacherMadeTestSaveInit] = useState(false);
  //---Check the multichoice questions
  const markSelectedMultipleChoiceQuestion = (question_number, answer_number, type, questionId) => {
    const newAnswer = { question_number: question_number, answer_number: answer_number, questionId: questionId }
    if (type === "multiple") {
      const newArr = multipleChoicePicks;
      for (var i = 0; i < multipleChoicePicks.length; i++) {
        if ((parseInt(multipleChoicePicks[i].question_number) === parseInt(question_number)) && (parseInt(answer_number) === parseInt(multipleChoicePicks[i].answer_number))) {
          //---Pop the array--
          const newArr1 = [];
          for (var n = 0; n < newArr.length; n++) {
            if (multipleChoicePicks[i] !== newArr[n]) {
              newArr1.push(multipleChoicePicks[n])
            }

          }
          setmultipleChoicePicks(newArr1);
          return;
        }
      }
      newArr.push(newAnswer);
      setmultipleChoicePicks(newArr);
      return;
    } else {
      const newArr = singleChoicePicks;
      for (var i = 0; i < singleChoicePicks.length; i++) {
        if (singleChoicePicks[i].question_number === question_number) {
          newArr[i] = newAnswer;
          setsingleChoicePicks(newArr);
          return;
        }
      }
      newArr.push(newAnswer);
      setsingleChoicePicks(newArr);
      return;

    }
  }
  const callDragAndDrop = (newEntry, questionNber, questionId, spaceNber) => {
    const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, spaceNber: spaceNber }
    const newArr = dragAndDrop;
    let checkMeIn = false;
    for (var i = 0; i < newArr.length; i++) {
      if (parseInt(newArr[i].question_number) === parseInt(questionNber) && parseInt(newArr[i].questionId) === parseInt(questionId) && parseInt(newArr[i].spaceNber) === parseInt(spaceNber)) {
        newArr[i] = newAnswer;
        checkMeIn = true;
      }
    }
    if (newArr.length == 0) {
      newArr.push(newAnswer);
    } else {
      if (checkMeIn) {

      } else {
        newArr.push(newAnswer);
      }
    }
    setdragAndDrop(newArr)
  }
  const getFilteredWordFromUI = (data, oneData, currentQuestionNumber) => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].newEntry === oneData && parseInt(currentQuestionNumber) === parseInt(data[i].question_number)) {
        return true;
      }
    }
    return false;
  }
  const callMatchingTypeAuto = (newEntry, questionNber, questionId, column, metaClean, remove = "no") => {
    if (remove === "no") {
      const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, column: column, metaClean: metaClean }
      const newArr = matchingTypeAuto;
      newArr.push(newAnswer);
      setMatchingTypeAuto(newArr)
      return;
    } else {
      const newArr = matchingTypeAuto;
      let newArr_ = [];
      if (getFilteredWordFromUI(newArr, newEntry, questionNber)) {
        for (var i = 0; i < newArr.length; i++) {
          if (newArr[i].newEntry === newEntry && parseInt(questionNber) === parseInt(newArr[i].question_number)) {
          } else {
            newArr_.push(newArr[i])
          }
        }
      } else {
        newArr_ = newArr;
      }
      setMatchingTypeAuto(newArr_)
      return;
    }
  }
  const callFillinTypeAuto = (newEntry, questionNber, questionId, questionPlace) => {
    const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, questionPlace: questionPlace }

    const newArr = fillinTypeAuto;
    let checkMeIn = false;
    for (var i = 0; i < newArr.length; i++) {
      if (parseInt(newArr[i].question_number) === parseInt(questionNber) && parseInt(newArr[i].questionId) === parseInt(questionId) && parseInt(newArr[i].questionPlace) === parseInt(questionPlace)) {
        newArr[i] = newAnswer;
        checkMeIn = true;
      }
    }
    if (newArr.length == 0) {
      newArr.push(newAnswer);
    } else {
      if (checkMeIn) {

      } else {
        newArr.push(newAnswer);
      }
    }
    setFillinTypeAuto(newArr)
    console.log(newArr)
  }


  const callFillIn = (newEntry, questionNber, questionId, spaceNber) => {
    const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, spaceNber: spaceNber }
    const newArr = fillin;
    let checkMeIn = false;
    for (var i = 0; i < newArr.length; i++) {
      if (parseInt(newArr[i].question_number) === parseInt(questionNber) && parseInt(newArr[i].questionId) === parseInt(questionId) && parseInt(newArr[i].spaceNber) === parseInt(spaceNber)) {
        newArr[i] = newAnswer;
        checkMeIn = true;
      }
    }
    if (newArr.length == 0) {
      newArr.push(newAnswer);
    } else {
      if (checkMeIn) {

      } else {
        newArr.push(newAnswer);
      }
    }
    setFillin(newArr)
  }
  const callComplete = (newEntry, questionNber, questionId) => {
    const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId }
    const newArr = openEnded;
    let checkMeIn = false;
    for (var i = 0; i < newArr.length; i++) {
      if (parseInt(newArr[i].question_number) === parseInt(questionNber) && parseInt(newArr[i].questionId) === parseInt(questionId)) {
        newArr[i] = newAnswer;
        checkMeIn = true;
      }
    }
    if (newArr.length == 0) {
      newArr.push(newAnswer);
    } else {
      if (checkMeIn) {

      } else {
        newArr.push(newAnswer);
      }
    }
    setOpenEnded(newArr)
  }
  const getSavedTeacherMadeTestMade = studentDashboardTestsSubjectsUnits.dashboard_homepage_save_teacher_made_test;
  const getSavedAutoMadeTestMade = studentDashboardTestsSubjectsUnits.dashboard_homepage_save_auto_made_test;
  const open_teacher_made_test_results = (data) => {
    const data1 = data?.results;
    if (data1 && data1 !== undefined) {
      if (data1.length > 0) {
        const idOfTestAnswer = data1[0]?.panda_2020_03_03_students_answering_map;
        return idOfTestAnswer;
      }
    }

    return "----";
  }
  const open_auto_made_test_results = (data) => {
    const data1 = data?.data?.results;
    if (data1 && data1 !== undefined) {
      if (data1.length > 0) {
        const idOfTestAnswer = data1[0]?.autoTestid
        return idOfTestAnswer;
      }
    }

    return "----";
  }
  //---After test made submit
  const saveTeacherMadeTestAnswer = () => {
    //---
    const idOfTest = testid;
    //---prepare multiple choice
    //const newAnswer = { question_number: question_number, answer_number: answer_number, questionId: questionId };
    let dataMap = "";
    for (var i = 0; i < multipleChoicePicks.length; i++) {
      if (dataMap === "") {
        dataMap = `multiple_choice#~#${multipleChoicePicks[i].questionId}#~#${multipleChoicePicks[i].answer_number}#~#checkbox`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `multiple_choice#~#${multipleChoicePicks[i].questionId}#~#${multipleChoicePicks[i].answer_number}#~#checkbox`;
      }
    }
    //---prepare for single choice
    //console.log(singleChoicePicks);
    for (var i = 0; i < singleChoicePicks.length; i++) {
      if (dataMap === "") {
        dataMap = `multiple_choice#~#${singleChoicePicks[i].questionId}#~#${singleChoicePicks[i].answer_number}#~#radio`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `multiple_choice#~#${singleChoicePicks[i].questionId}#~#${singleChoicePicks[i].answer_number}#~#radio`;
      }
    }
    //---prepare drag and drop
    //const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, spaceNber: spaceNber }    
    for (var i = 0; i < dragAndDrop.length; i++) {
      if (dataMap === "") {
        dataMap = `drag_and_drop#~#${dragAndDrop[i].questionId}#~#${dragAndDrop[i].spaceNber}#~#${dragAndDrop[i].newEntry}`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `drag_and_drop#~#${dragAndDrop[i].questionId}#~#${dragAndDrop[i].spaceNber}#~#${dragAndDrop[i].newEntry}`;
      }
    }
    //---Fillin Data
    for (var i = 0; i < fillin.length; i++) {
      if (dataMap === "") {
        dataMap = `complete_sentence#~#${fillin[i].questionId}#~#${fillin[i].spaceNber}#~#${fillin[i].newEntry}`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `complete_sentence#~#${fillin[i].questionId}#~#${fillin[i].spaceNber}#~#${fillin[i].newEntry}`;
      }
    }
    //---Prepare open ended
    for (var i = 0; i < openEnded.length; i++) {
      if (dataMap === "") {
        dataMap = `open_ended#~#${openEnded[i].questionId}#~#${openEnded[i].spaceNber}#~#${openEnded[i].newEntry}`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `open_ended#~#${openEnded[i].questionId}#~#${0}#~#${openEnded[i].newEntry}`;
      }
    }
    const userAnsweringMap = dataMap;
    if (userAnsweringMap !== "") {
      if (window.confirm("Are you sure, you want to save the test?!!")) {
        dispatch(
          student_dashboard_homepage_save_teacher_made_test_start_for_challenges({
            token: userAth.token,
            idOfTest: idOfTest,
            academic_level: userAth.academic_level,
            names: userAth.displayName,
            userAnsweringMap: userAnsweringMap,
            user_id: userAth.userId,
          })
        );
        return true;
      } else {
        return false;
      }
    } else {
      alert("No answer provided, please answer at least one question!!");
      return false;
    }
  };
  const saveTeacherMadeTestAnswer_auto = () => {
    //---
    const idOfTest = testid;
    //---prepare multiple choice
    //const newAnswer = { question_number: question_number, answer_number: answer_number, questionId: questionId };
    let dataMap = "";
    for (var i = 0; i < multipleChoicePicks.length; i++) {
      if (dataMap === "") {
        dataMap = `multiple_choice#~#${multipleChoicePicks[i].questionId}#~#${multipleChoicePicks[i].answer_number}#~#checkbox`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `multiple_choice#~#${multipleChoicePicks[i].questionId}#~#${multipleChoicePicks[i].answer_number}#~#checkbox`;
      }
    }
    //---prepare for single choice
    //console.log(singleChoicePicks);
    for (var i = 0; i < singleChoicePicks.length; i++) {
      if (dataMap === "") {
        dataMap = `multiple_choice#~#${singleChoicePicks[i].questionId}#~#${singleChoicePicks[i].answer_number}#~#radio`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `multiple_choice#~#${singleChoicePicks[i].questionId}#~#${singleChoicePicks[i].answer_number}#~#radio`;
      }
    }
    //---prepare drag and drop
    //const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, spaceNber: spaceNber }    
    for (var i = 0; i < dragAndDrop.length; i++) {
      if (dataMap === "") {
        dataMap = `drag_and_drop#~#${dragAndDrop[i].questionId}#~#${dragAndDrop[i].spaceNber}#~#${dragAndDrop[i].newEntry}`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `drag_and_drop#~#${dragAndDrop[i].questionId}#~#${dragAndDrop[i].spaceNber}#~#${dragAndDrop[i].newEntry}`;
      }
    }
    //---Fillin Data
    for (var i = 0; i < fillin.length; i++) {
      if (dataMap === "") {
        dataMap = `complete_sentence#~#${fillin[i].questionId}#~#${fillin[i].spaceNber}#~#${fillin[i].newEntry}`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `complete_sentence#~#${fillin[i].questionId}#~#${fillin[i].spaceNber}#~#${fillin[i].newEntry}`;
      }
    }
    //---Prepare open ended
    for (var i = 0; i < openEnded.length; i++) {
      if (dataMap === "") {
        dataMap = `open_ended#~#${openEnded[i].questionId}#~#${openEnded[i].spaceNber}#~#${openEnded[i].newEntry}`;
      } else {
        dataMap = dataMap + "~~#~#~~" + `open_ended#~#${openEnded[i].questionId}#~#${0}#~#${openEnded[i].newEntry}`;
      }
    }
    const userAnsweringMap = dataMap;
    if (userAnsweringMap !== "") {
      if (true) {
        dispatch(
          student_dashboard_homepage_save_teacher_made_test_start_for_challenges_auto({
            token: userAth.token,
            idOfTest: idOfTest,
            academic_level: userAth.academic_level,
            names: userAth.displayName,
            userAnsweringMap: userAnsweringMap,
            user_id: userAth.userId,
          })
        );
        return true;
      } else {
        return false;
      }
    } else {
      alert("No answer provided, please answer at least one question!!");
      return false;
    }
  };

  const saveAutoMadeTestAnswer = () => {
    //---
    //---prepare singleChoicePicks 
    //const newAnswer = { question_number: question_number, answer_number: answer_number, questionId: questionId };
    let autoListOfQuestions = "";
    let autoSingleChoiceQuestionPool = "";
    let autoQuestionPool = [];
    for (var i = 0; i < singleChoicePicks.length; i++) {
      if (autoSingleChoiceQuestionPool === "") {
        autoSingleChoiceQuestionPool = `${singleChoicePicks[i].answer_number}####${singleChoicePicks[i].questionId}`;
      } else {
        autoSingleChoiceQuestionPool = autoSingleChoiceQuestionPool + "cyuma" + `${singleChoicePicks[i].answer_number}####${singleChoicePicks[i].questionId}`;
      }
      if (autoQuestionPool.length === 0) {
        autoQuestionPool.push(`${singleChoicePicks[i].questionId}~~1`);
      } else if (!autoQuestionPool.includes(`${singleChoicePicks[i].questionId}~~1`)) {
        autoQuestionPool.push(`${singleChoicePicks[i].questionId}~~1`);
      }

    }
    let autoMultipleChoiceQuestionPool = "";
    //---prepare for multipleChoicePicks    
    //console.log(multipleChoicePicks);
    for (var i = 0; i < multipleChoicePicks.length; i++) {
      if (autoMultipleChoiceQuestionPool === "") {
        autoMultipleChoiceQuestionPool = `${multipleChoicePicks[i].answer_number}##-#${multipleChoicePicks[i].questionId}`;
      } else {
        autoMultipleChoiceQuestionPool = autoMultipleChoiceQuestionPool + "----" + `${multipleChoicePicks[i].answer_number}##-#${multipleChoicePicks[i].questionId}`;
      }
      if (autoQuestionPool.length === 0) {
        autoQuestionPool.push(`${multipleChoicePicks[i].questionId}~~1`);
      } else if (!autoQuestionPool.includes(`${multipleChoicePicks[i].questionId}~~1`)) {
        autoQuestionPool.push(`${multipleChoicePicks[i].questionId}~~1`);
      }
    }
    //---prepare matchingTypeAuto
    //const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, column: column }   
    let matchingQuestions = "";
    let matching_algov2 = [];
    for (var i = 0; i < matchingTypeAuto.length; i++) {
      if (matchingQuestions === "") {
        matchingQuestions = `1~#~#~^--${matchingTypeAuto[i].metaClean[matchingTypeAuto[i].column]}~#~#~^--${matchingTypeAuto[i].newEntry}~#~#~^--~#~#~^--${matchingTypeAuto[i].questionId}`;
      } else {
        matchingQuestions = matchingQuestions + "~!$*Cyuma~~~" + `1~#~#~^--${matchingTypeAuto[i].metaClean[matchingTypeAuto[i].column]}~#~#~^--${matchingTypeAuto[i].newEntry}~#~#~^--~#~#~^--${matchingTypeAuto[i].questionId}`;
      }
      if (matching_algov2.length === 0) {
        matching_algov2.push(matchingTypeAuto[i].questionId);
      } else if (!matching_algov2.includes(matchingTypeAuto[i].questionId)) {
        matching_algov2.push(matchingTypeAuto[i].questionId);
      }
    }
    //---fillinTypeAuto Data
    let fillinQuestions = "";
    let mapfillin_algov2 = [];
    //--const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, questionPlace: questionPlace }
    for (var i = 0; i < fillinTypeAuto.length; i++) {
      if (fillinQuestions === "") {
        fillinQuestions = `${fillinTypeAuto[i].newEntry}-~--autoDraggga---${fillinTypeAuto[i].questionPlace}+-~--${fillinTypeAuto[i].questionId}`;
      } else {
        fillinQuestions = fillinQuestions + `${fillinTypeAuto[i].newEntry}-~--autoDraggga---${fillinTypeAuto[i].questionPlace}+-~--${fillinTypeAuto[i].questionId}`;
      }
      if (mapfillin_algov2.length === 0) {
        mapfillin_algov2.push(fillinTypeAuto[i].questionId);
      } else if (!mapfillin_algov2.includes(fillinTypeAuto[i].questionId)) {
        mapfillin_algov2.push(fillinTypeAuto[i].questionId);
      }
    }
    const difficulty = props.difficulty;
    if (fillinQuestions !== "" || matchingQuestions !== "" || autoSingleChoiceQuestionPool !== "" || autoMultipleChoiceQuestionPool !== "") {
      if (window.confirm("Are you sure, you want to save the test?!!")) {
        dispatch(
          student_dashboard_homepage_save_auto_made_test_start({
            token: userAth.token,
            academic_level: userAth.academic_level,
            user_id: userAth.userId,
            //----Algo test v2 type questions
            fillinQuestions: fillinQuestions,
            matchingQuestions: matchingQuestions,
            mapfillin_algov2: auto[4][2],//Ex--"73,74,81,80,72,82"
            matching_algov2: auto[4][1],//--EX 	"128"
            //---Auto test algo v1
            radiosSingleData: autoSingleChoiceQuestionPool,
            checkboxMultipleData: autoMultipleChoiceQuestionPool,
            //-----
            difficulty_level: difficulty,
            time_leftOn_test: parseInt(localStorage.getItem("timingTestCountDown")),
            total_time_oftest: total_time,
            allTheTestAiuniqId: uuidv4(),
            autoQuestionPool: auto[4][0],
            unitsUsed: props.unit,
            total_nberOf_questions: auto_nber_of_questions,
            auto_total_marks: auto_total_marks
          })
        );
        return true;
      } else {
        return false;
      }
    } else {
      alert("No answer provided, please answer at least one question!!");
      return false;
    }
  };
  const saveAutoMadeTestAnswer_auto = () => {
    //---
    //---prepare singleChoicePicks 
    //const newAnswer = { question_number: question_number, answer_number: answer_number, questionId: questionId };
    let autoListOfQuestions = "";
    let autoSingleChoiceQuestionPool = "";
    let autoQuestionPool = [];
    for (var i = 0; i < singleChoicePicks.length; i++) {
      if (autoSingleChoiceQuestionPool === "") {
        autoSingleChoiceQuestionPool = `${singleChoicePicks[i].answer_number}####${singleChoicePicks[i].questionId}`;
      } else {
        autoSingleChoiceQuestionPool = autoSingleChoiceQuestionPool + "cyuma" + `${singleChoicePicks[i].answer_number}####${singleChoicePicks[i].questionId}`;
      }
      if (autoQuestionPool.length === 0) {
        autoQuestionPool.push(`${singleChoicePicks[i].questionId}~~1`);
      } else if (!autoQuestionPool.includes(`${singleChoicePicks[i].questionId}~~1`)) {
        autoQuestionPool.push(`${singleChoicePicks[i].questionId}~~1`);
      }

    }
    let autoMultipleChoiceQuestionPool = "";
    //---prepare for multipleChoicePicks    
    //console.log(multipleChoicePicks);
    for (var i = 0; i < multipleChoicePicks.length; i++) {
      if (autoMultipleChoiceQuestionPool === "") {
        autoMultipleChoiceQuestionPool = `${multipleChoicePicks[i].answer_number}##-#${multipleChoicePicks[i].questionId}`;
      } else {
        autoMultipleChoiceQuestionPool = autoMultipleChoiceQuestionPool + "----" + `${multipleChoicePicks[i].answer_number}##-#${multipleChoicePicks[i].questionId}`;
      }
      if (autoQuestionPool.length === 0) {
        autoQuestionPool.push(`${multipleChoicePicks[i].questionId}~~1`);
      } else if (!autoQuestionPool.includes(`${multipleChoicePicks[i].questionId}~~1`)) {
        autoQuestionPool.push(`${multipleChoicePicks[i].questionId}~~1`);
      }
    }
    //---prepare matchingTypeAuto
    //const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, column: column }   
    let matchingQuestions = "";
    let matching_algov2 = [];
    for (var i = 0; i < matchingTypeAuto.length; i++) {
      if (matchingQuestions === "") {
        matchingQuestions = `1~#~#~^--${matchingTypeAuto[i].metaClean[matchingTypeAuto[i].column]}~#~#~^--${matchingTypeAuto[i].newEntry}~#~#~^--~#~#~^--${matchingTypeAuto[i].questionId}`;
      } else {
        matchingQuestions = matchingQuestions + "~!$*Cyuma~~~" + `1~#~#~^--${matchingTypeAuto[i].metaClean[matchingTypeAuto[i].column]}~#~#~^--${matchingTypeAuto[i].newEntry}~#~#~^--~#~#~^--${matchingTypeAuto[i].questionId}`;
      }
      if (matching_algov2.length === 0) {
        matching_algov2.push(matchingTypeAuto[i].questionId);
      } else if (!matching_algov2.includes(matchingTypeAuto[i].questionId)) {
        matching_algov2.push(matchingTypeAuto[i].questionId);
      }
    }
    //---fillinTypeAuto Data
    let fillinQuestions = "";
    let mapfillin_algov2 = [];
    //--const newAnswer = { question_number: questionNber, newEntry: newEntry, questionId: questionId, questionPlace: questionPlace }
    for (var i = 0; i < fillinTypeAuto.length; i++) {
      if (fillinQuestions === "") {
        fillinQuestions = `${fillinTypeAuto[i].newEntry}-~--autoDraggga---${fillinTypeAuto[i].questionPlace}+-~--${fillinTypeAuto[i].questionId}`;
      } else {
        fillinQuestions = fillinQuestions + `${fillinTypeAuto[i].newEntry}-~--autoDraggga---${fillinTypeAuto[i].questionPlace}+-~--${fillinTypeAuto[i].questionId}`;
      }
      if (mapfillin_algov2.length === 0) {
        mapfillin_algov2.push(fillinTypeAuto[i].questionId);
      } else if (!mapfillin_algov2.includes(fillinTypeAuto[i].questionId)) {
        mapfillin_algov2.push(fillinTypeAuto[i].questionId);
      }
    }
    const difficulty = props.difficulty;
    if (fillinQuestions !== "" || matchingQuestions !== "" || autoSingleChoiceQuestionPool !== "" || autoMultipleChoiceQuestionPool !== "") {
      if (true) {
        dispatch(
          student_dashboard_homepage_save_auto_made_test_start_auto({
            token: userAth.token,
            academic_level: userAth.academic_level,
            user_id: userAth.userId,
            //----Algo test v2 type questions
            fillinQuestions: fillinQuestions,
            matchingQuestions: matchingQuestions,
            mapfillin_algov2: auto[4][2],//Ex--"73,74,81,80,72,82"
            matching_algov2: auto[4][1],//--EX 	"128"
            //---Auto test algo v1
            radiosSingleData: autoSingleChoiceQuestionPool,
            checkboxMultipleData: autoMultipleChoiceQuestionPool,
            //-----
            difficulty_level: difficulty,
            time_leftOn_test: parseInt(localStorage.getItem("timingTestCountDown")),
            total_time_oftest: total_time,
            allTheTestAiuniqId: uuidv4(),
            autoQuestionPool: auto[4][0],
            unitsUsed: props.unit,
            total_nberOf_questions: auto_nber_of_questions,
            auto_total_marks: auto_total_marks
          })
        );
        return true;
      } else {
        return false;
      }
    } else {
      alert("No answer provided, please answer at least one question!!");
      return false;
    }
  };
  return (
    <>{!showResults ? (
      <>{(testType === "auto") ? <div className="md:m-10">
        <div className="flex space-x-2">{(auto[0] && auto[0]?.questionsmap && auto[0]?.questionsmap.length > 0) ? <div>{((auto[2] === 10) || (auto[2] === 15)) ? <div>
          <CardBody name={`Question ${currentQuestionNumber + 1}.`} />
          {(auto[0]?.questionsmap[currentQuestionNumber].questiontype === "matching") ?
            <div>
              <MatchingTypeAutoQuestion matchingTypeAuto={matchingTypeAuto} questionId={auto[0]?.questionsmap[currentQuestionNumber].questiondata.panda_auto_test_algo_v2_id} currentQuestionNumber={currentQuestionNumber} callMatchingTypeAuto={callMatchingTypeAuto} key={Math.random() * 1000} questionMetadata={auto[0]?.questionsmap[currentQuestionNumber].questiondata.panda_auto_test_algo_v2_question_data} />
            </div> : (auto[0]?.questionsmap[currentQuestionNumber]?.questiontype === "fillin") ?
              <div>
                <FillinTypeAutoQuestion fillinTypeAuto={fillinTypeAuto} questionId={auto[0]?.questionsmap[currentQuestionNumber].questiondata.panda_auto_test_algo_v2_id} callFillinTypeAuto={callFillinTypeAuto} key={Math.random() * 1000} currentQuestionNumber={currentQuestionNumber} questionMetadata={auto[0]?.questionsmap[currentQuestionNumber].questiondata.panda_auto_test_algo_v2_question_data} />
              </div> : (auto[0]?.questionsmap[currentQuestionNumber]?.questiontype === "multiple_choice") ?
                <div><span ref={autoClick} onClick={() => {
                  saveTeacherMadeTestAnswer_auto() && setShowResults(true);
                }}></span><span ref={teacherMadeClick} onClick={() => {
                  saveAutoMadeTestAnswer_auto() && setShowResults(true);
                }}></span>
                  <CardBody
                    name={(auto[0]?.questionsmap[currentQuestionNumber]?.questiontype === 'multiple_choice'
                      ? 'Pick one or many answers for this question'
                      : '')}
                  />
                  {auto[0]?.questionsmap && auto[0]?.questionsmap.length > 0 && auto[0]?.questionsmap[currentQuestionNumber].questiontype ===
                    'multiple_choice' && (
                      <div>
                        {(auto[0]?.questionsmap[currentQuestionNumber]?.questiondata?.panda_questions_auto_auto_answering_technique === 0) ?
                          <div>
                            {parse(auto[0]?.questionsmap[currentQuestionNumber]?.questiondata?.panda_questions_auto_question_data)}
                            <div>
                              <SinglechoiceAnswerOptionGroupAutoTest key={Math.random() * 1000} id={auto[0]?.questionsmap[currentQuestionNumber].questiondata.panda_questions_auto_id} singleChoicePicks={singleChoicePicks} markSelectedMultipleChoiceQuestion={markSelectedMultipleChoiceQuestion} questionnber={currentQuestionNumber} answers={auto[0]?.questionsmap[currentQuestionNumber].questiondata.answerData} />
                            </div>
                          </div> :
                          <div>
                            {parse(auto[0]?.questionsmap[currentQuestionNumber]?.questiondata?.panda_questions_auto_question_data)}
                            <div>
                              <MultichoiceAnswerOptionGroupAutoTest key={Math.random() * 1000} id={auto[0]?.questionsmap[currentQuestionNumber].questiondata.panda_questions_auto_id} multipleChoicePicks={multipleChoicePicks} markSelectedMultipleChoiceQuestion={markSelectedMultipleChoiceQuestion} questionnber={currentQuestionNumber} answers={auto[0]?.questionsmap[currentQuestionNumber].questiondata.answerData} />
                            </div>
                          </div>}
                      </div>
                    )}
                </div> : ""}
        </div> : <NotFound feature="Test matching your inputs " />}</div> : ""}</div></div> : <div>

        {(test.questionsmap && test.questionsmap.length > 0) ? <div className="md:m-10">
          <div className="flex space-x-2">
            <CardBody name={`Question ${currentQuestionNumber + 1}.`} />
            <CardBody
              name={
                (test.questionsmap[currentQuestionNumber].questiontype ===
                  'complete_sentence')
                  ? ('Complete the sentence below')
                  : (test.questionsmap[currentQuestionNumber]
                    .questiontype === 'drag_and_drop'
                    ? 'Drag and drop to match the sentence below'
                    : (test.questionsmap[currentQuestionNumber]
                      .questiontype === 'multiple_choice'
                      ? 'Pick one or many answers for this question'
                      : (test.questionsmap[currentQuestionNumber]
                        .questiontype === 'open_ended'
                        ? 'Write you answer below(To be corrected by the teacher)'
                        : '')))
              }
            />
          </div>
          {test.questionsmap && test.questionsmap.length > 0 && test.questionsmap[currentQuestionNumber].questiontype ===
            'multiple_choice' && (
              <div>
                {(test.questionsmap[currentQuestionNumber].questiondata.panda_teacher_test_2020_03_answering_guidelines === 0) ?
                  <div>
                    {parse(test.questionsmap[currentQuestionNumber].questiondata.panda_teacher_test_2020_03_question_data)}
                    <div>
                      <SinglechoiceAnswerOptionGroup key={Math.random() * 1000} id={test.questionsmap[currentQuestionNumber].questiondata.panda_teacher_test_2020_03_id} singleChoicePicks={singleChoicePicks} markSelectedMultipleChoiceQuestion={markSelectedMultipleChoiceQuestion} questionnber={currentQuestionNumber} answers={test.questionsmap[currentQuestionNumber].questiondata.answers} />
                    </div>
                  </div> :
                  <div>
                    {parse(test.questionsmap[currentQuestionNumber].questiondata.panda_teacher_test_2020_03_question_data)}
                    <div>
                      <MultichoiceAnswerOptionGroup key={Math.random() * 1000} id={test.questionsmap[currentQuestionNumber].questiondata.panda_teacher_test_2020_03_id} multipleChoicePicks={multipleChoicePicks} markSelectedMultipleChoiceQuestion={markSelectedMultipleChoiceQuestion} questionnber={currentQuestionNumber} answers={test.questionsmap[currentQuestionNumber].questiondata.answers} />
                    </div>
                  </div>}
              </div>
            )}
          {test.questionsmap[currentQuestionNumber].questiontype ===
            'complete_sentence' && (
              <CompleteSentence key={Math.random() * 1000} currentQuestionNumber={currentQuestionNumber} questionId={test.questionsmap[currentQuestionNumber].questiondata.panda_fill_in_2020_03_id} callFillIn={callFillIn} fillin={fillin}
                questiondata={test.questionsmap[currentQuestionNumber]}
              />
            )}
          {test.questionsmap[currentQuestionNumber].questiontype ===
            'open_ended' && (
              <OpenEnded key={Math.random() * 1000} questionId={test.questionsmap[currentQuestionNumber].questiondata.panda_open_ended_2020_03_id} currentQuestionNumber={currentQuestionNumber} callComplete={callComplete} openEnded={openEnded} questiondata={test.questionsmap[currentQuestionNumber]} />
            )}
          {test.questionsmap[currentQuestionNumber].questiontype ===
            'drag_and_drop' && (
              <DragAndDrop key={Math.random() * 1000} callDragAndDrop={callDragAndDrop} questionId={test.questionsmap[currentQuestionNumber].questiondata.panda_drag_and_drop_2020_03_id} currentQuestionNumber={currentQuestionNumber} dragAndDrop={dragAndDrop} questiondata={test.questionsmap[currentQuestionNumber]} />
            )}
        </div> : <Spinner size="20" color="blue" />}
      </div>}
        <div className="right-5 bottom-5 w-24 float-right w-80">
          {(!timeSet) ? <CountDown setShowResults={setShowResults} testType={(testType === "auto") ? "automade" : "teachermade"} seconds={total_time} /> : <></>}
          <div className="flex justify-around space-x-2 py-2">
            {(testType !== "auto") && currentQuestionNumber <= test.questionsmap.length - 1 && (
              <>{(currentQuestionNumber > 0) && (<Button
                name="Prev"
                outline="true"
                color="red"
                clicked={() => {
                  if (currentQuestionNumber <= test.questionsmap.length) {
                    setCurrentQuestionNumber((number) => number - 1);
                  }
                }}
              />)}
                {(currentQuestionNumber < (test.questionsmap.length - 1)) && (<Button
                  name="Next"
                  outline="true"
                  color="red"
                  clicked={() => {
                    if (currentQuestionNumber <= test.questionsmap.length) {
                      setCurrentQuestionNumber((number) => number + 1);
                    }
                  }}
                />)}</>
            )}
            {(testType === "auto") && currentQuestionNumber <= auto[0]?.questionsmap.length - 1 && (
              <>{(currentQuestionNumber > 0) && (<Button
                name="Prev"
                outline="true"
                color="red"
                clicked={() => {
                  if (currentQuestionNumber <= auto[0]?.questionsmap.length) {
                    setCurrentQuestionNumber((number) => number - 1);
                  }
                }}
              />)}
                {(currentQuestionNumber < (auto[0]?.questionsmap.length - 1)) && (<Button
                  name="Next"
                  outline="true"
                  color="red"
                  clicked={() => {
                    if (currentQuestionNumber <= auto[0]?.questionsmap.length) {
                      setCurrentQuestionNumber((number) => number + 1);
                    }
                  }}
                />)}</>
            )}
            {(((auto[2] === 10) || (auto[2] === 15)) && (testType === "auto")) ? <Button
              name="Submit"
              outline="true"
              color="blue"
              clicked={() => { 
                saveAutoMadeTestAnswer() && setShowResults(true);
                //setShowResults(true)
              }}
            /> : (testType !== "auto") ? (true) ? <Button
              name="Submit"
              outline="true"
              color="blue"
              clicked={() => {

                saveTeacherMadeTestAnswer() && setShowResults(true)

              }}
            /> : <Spinner size="20" color="blue" /> : <></>}
          </div>
        </div>
      </>
    ) : (
      (!studentDashboardTestsSubjectsUnits.dashboard_homepage_save_teacher_made_test_loading && getSavedTeacherMadeTestMade?.results?.length > 0 && testType === "byteacher") ? <>
        <TestResults challengeId={props.challengeId} type={"teachermade"} data1={open_teacher_made_test_results(getSavedTeacherMadeTestMade)} />
      </> : (!studentDashboardTestsSubjectsUnits.dashboard_homepage_save_auto_made_test_loading && getSavedAutoMadeTestMade?.data?.results?.length > 0 && testType === "auto") ? <TestResultsAuto type={"auto"} data={open_auto_made_test_results(getSavedAutoMadeTestMade)} /> : <Spinner size="20" color="blue" />)}
    </>);
};

export default TestTeacherMade;
