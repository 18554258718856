import { useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import Input from '../../../elements/input';
import PageContent from '../pageContent';
import { CardBody, FeatureTitle, SectionTitle } from '../../../elements/text';
import { useSelector } from 'react-redux';
import { Button, GoBackButton } from '../../../elements/button';
import QuestionItem from './QuestionItem';
import { changeChosenSubject } from '../../../../store/actions/features/pastpaper';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTabs } from 'react-headless-tabs';
import Spinner from '../../../elements/spinner';
import { replaceAllLInksWithRealSource } from '../../../../functions/commonfunctions';
import parse from 'react-html-parser';
import { useParams } from 'react-router-dom';
import { student_pastpapers_subjects_start, student_pastpapers_viewership_start, teacher_pastpapers_subjects_start } from '../../../../store/actions/student.dashboard.pastpapers';
import { getOneTeachersPaspapersSuccess, setQuestionsFromDB } from '../../../../store/actions/features/teacher/pastpapers';
import axios from "../../../../axios-base";
import { convertBufferToString } from '../../../../functions/trendingSubjects';
const Pastpaper = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState('S1');
  const [searchterm, setSearchterm] = useState('');
  //const [papertype, setPapertype] = useState('');
  let papertype = "";
  const userAth = useSelector((state) => state.auth);
  const studentDashboardPastpapers = useSelector(
    (state) => state.studentDashboardPastpapers
  );
  const chosenSubject = useSelector(
    (state) => state.features.pastpapers.chosenSubject
  );
  let question_number = 1;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const courses_params = useParams();



  useEffect(() => {
    dispatch(
      teacher_pastpapers_subjects_start({
        user_id: userAth.userId,
        academic_level: userAth.academic_level,
        token: userAth.token,
      })
    );
    //---
    setSelectedSubject(params.subject);
    //--Mark past pastpaper as viewed--
    dispatch(
      student_pastpapers_viewership_start({
        userId: userAth.userId,
        username: userAth.username,
        user_type: 'teacher',
        academic_level: "0",
        item_id: courses_params.paperId,
        subject: params.subject,
        token: userAth.token,
      })
    );
  }, []);


  const subjects = [
    'Biology',
    'Math',
    'History',
    'Geography',
    'Physics',
    'English',
  ];
  //---
  const getDataFromBlob = (data) => {
    return parse(replaceAllLInksWithRealSource(data));
  };
  const pastpaperQuestionsPool = [];
  const examTitle = [];
  const subjects_pool_check = (data) => {
    const subjects_data = [];
    const chosen_pastpaper = [];
    if (data?.length > 0 && data[0] && data[0].length > 0) {
      for (var i = 0; i < data[0].length; i++) {
        subjects_data.push({
          name: data[0][i].pastpapers_subject,
          pastpapers_academic_level: data[0][i].pastpapers_academic_level,
          pastpapers: [],
        });
        //-----
      }
      //----Collect the pages--
      for (var i = 0; i < data[1].length; i++) {
        if (
          data[1][i].name === params.subject &&
          data[1][i].id + '' === courses_params.paperId
        ) {
          for (var n = 0; n < data[1][i].pastpapers.length; n++) {
            papertype = data[1][i].pastpapers[n]?.type;
            let h = n + 1;
            pastpaperQuestionsPool.push({
              section: 'C',
              questions: [
                {
                  number: h,
                  question: getDataFromBlob(
                    data[1][i].pastpapers[n].past_papers_questions_data
                  ),
                  answer: getDataFromBlob(
                    data[1][i].pastpapers[n].past_papers_questions_answer
                  ),
                },
              ],
            });
          }
          //--
          examTitle.push(data[1][i].pastpapers_summary);
        }
      }

      return subjects_data;
    }
    return subjects_data;
  };
  const subjects_pool = subjects_pool_check(
    studentDashboardPastpapers.pastpapers_subjects
  );

  //---
  const tabs = subjects_pool.map((subject) => subject.name);
  tabs.unshift('initial page');
  const [selectedSubject, setSelectedSubject] = useTabs(
    tabs,
    chosenSubject || 'initial page'
  );

  const editPastpaper = () => {
    // let arrayofquestions = [];
    // for (let i = 0; i < pastpaperQuestionsPool.length; i++) {
    //   const quest = pastpaperQuestionsPool[i].questions[0];
    //   console.log(`quest: `, quest);
    //   const data = { ...quest, index: i };
    //   arrayofquestions.push({
    //     question: quest.question,
    //     answer: quest.answer,
    //     number: quest.number,
    //     index: i,
    //   });
    // }
    // dispatch(setQuestionsFromDB(arrayofquestions));
    // history.push(`/teacher/features/pastpapers/new/${courses_params.paperId}`);
    axios.put(`/opanda/past-papers/findMyPastPaperWithID`, {
      user_id: userAth.userId,
      paperId: courses_params.paperId

    },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userAth.token}`
        }
      })
      .then((response) => {
        const pastpp = {
          level: response.data.results.pastpapers_academic_level,
          subject: response.data.results.pastpapers_subject,
          type: "",
          academicYear: response.data.results.pastpapers_examination_year,
          name: "",
          description: String.fromCharCode(...response.data.results.pastpapers_summary.data),
        };
       
        dispatch(getOneTeachersPaspapersSuccess(pastpp));
        history.push(`/teacher/features/pastpapers/new?id_p=${courses_params.paperId}`);


      })
      .catch((err) => {
      });

  }

  



  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full min-h-screen-sec`}>
        <div
          className={`p-3 bg-gray-200 ${showSubjects ? 'h-screen-sec' : ''
            } md:h-full col-span-3`}
        >
          <div className="flex justify-between items-center">
            <Input
              elementType="select"
              elementConfig={{
                startingValue: 'SELECT',
                options: [
                  { value: 'olevel', displayValue: 'Ordinary Level' },
                  { value: 'alevel', displayValue: 'Advanced Level' },
                ],
              }}
              value={level}
              changed={setLevel}
              validation={{ required: true }}
              shouldValidate
              error="Level is required"
            />
            {showSubjects ? (
              <BiChevronUp
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <BiChevronDown
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )}
          </div>
          <div className={`${showSubjects ? '' : 'hidden'} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Search...',
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            <ul className="py-5">
              {subjects_pool.map((subject, index) =>
                searchterm === '' ||
                  subject.name
                    .toLowerCase()
                    .includes(searchterm.toLowerCase()) ? (
                  <li key={index}>
                    <div
                      className="py-2 cursor-pointer"
                      onClick={() => {
                        dispatch(changeChosenSubject(subject.name));
                        history.push(`/teacher/features/pastpapers/`);
                      }}
                    >
                      <CardBody
                        name={subject.name}
                        color="blue"
                        additional={
                          subject.name === selectedSubject ? 'font-bold' : ''
                        }
                      />
                    </div>
                  </li>
                ) : (
                  <></>
                )
              )}
            </ul>
          </div>
        </div>
        <div
          className={`${showSubjects ? 'hidden md:block' : ''
            } md:relative p-3 bg-white md:h-full max-h-myscreen-sec min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <FeatureTitle name="Past Paper" />
          <div className='flex justify-between space-x-5'>
            <GoBackButton
              link="/teacher/features/pastpapers"
              action="Back"
              location={parse(
                replaceAllLInksWithRealSource(
                  examTitle.length > 0 ? examTitle[0] : ''
                )
              )}
            />
            <div className={(papertype === "mypapers") ? "flex justify-end" : "hidden"}>
              <Button
                name="Edit"
                outline="true"
                color="blue"
                clicked={editPastpaper}
              />
            </div>
          </div>
          {pastpaperQuestionsPool.map((section, index) => (
            <div className="py-3" key={index}>
              {/*  <SectionTitle  name={`Section ${section.section}`} /> */}
              {section.questions.map((question, index1) => (
                <QuestionItem key={question_number} {...question} number={question_number++} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </PageContent>
  );
};

export default Pastpaper;
