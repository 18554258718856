import Notification from "../../../elements/notification/notification";

const NotificationPanel = () => {
  return (
    <div className="md:w-30%">
      <Notification auth />
    </div>
  );
};

export default NotificationPanel;
