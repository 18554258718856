import React, { useState } from 'react';
import { MdAttachment, MdOutlinePictureAsPdf } from 'react-icons/md';
import { Button } from '../../../elements/button';


const PdfThumbnailImage = (props) => {
  
    const removeThumbnail = () => {
        props.removeImage()
    };
  

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>

            {props.Thumbnail && (
                <> <Button
                    name="X"
                    isSmall={true}
                    outline="false"
                    color="red"
                    clicked={() => removeThumbnail()}
                /><span>Image</span>
                    <img
                        src={props.Thumbnail}
                        alt="PDF thumbnail"
                        width="150"
                        height="150"
                    />

                </>
            )}
        </div>
    );
};

export default PdfThumbnailImage;
