import axios from "../../../axios-base";

// Get list of automated tests
export const GET_AUTOMATED_TEST_RESULT_INIT = "GET_AUTOMATED_TEST_RESULT_INIT";
export const GET_AUTOMATED_TEST_RESULT_SUCCESS =
  "GET_AUTOMATED_TEST_RESULT_SUCCESS";
export const GET_AUTOMATED_TEST_RESULT_FAIL = "GET_AUTOMATED_TEST_RESULT_FAIL";

export const getAutomatedTestResultInit = () => {
  return {
    type: GET_AUTOMATED_TEST_RESULT_INIT,
  };
};
export const getAutomatedTestResultSuccess = (result) => {
  return {
    type: GET_AUTOMATED_TEST_RESULT_SUCCESS,
    payload: result,
  };
};
export const getAutomatedTestResultFail = (error) => {
  return {
    type: GET_AUTOMATED_TEST_RESULT_FAIL,
    error: error,
  };
};

export const getAutomatedTestResultStart = (data) => {
  return (dispatch) => {
    dispatch(getAutomatedTestResultInit());
    axios
      .post(`/opanda/panda-test-maps/`)
      .then((response) => {
        dispatch(getAutomatedTestResultSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAutomatedTestResultFail(err));
      });
  };
};


// // Get one automated test
// export const GET_ONE_AUTOMATED_TEST_RESULT_START =
//   "GET_ONE_AUTOMATED_TEST_RESULT_START";
// export const GET_ONE_AUTOMATED_TEST_RESULT_SUCCESS =
//   "GET_ONE_AUTOMATED_TEST_RESULT_SUCCESS";
// export const GET_ONE_AUTOMATED_TEST_RESULT_FAIL =
//   "GET_ONE_AUTOMATED_TEST_RESULT_FAIL";


// export const GET_OTHER_TEST_LIST_START = "GET_OTHER_TEST_LIST_START";
// export const GET_OTHER_TEST_LIST_SUCCESS = "GET_OTHER_TEST_LIST_SUCCESS";
// export const GET_OTHER_TEST_LIST_FAIL = "GET_OTHER_TEST_LIST_FAIL";

// export const GET_ONE_OTHER_TEST_RESULT_START =
//   "GET_ONE_OTHER_TEST_RESULT_START";
// export const GET_ONE_OTHER_TEST_RESULT_SUCCESS =
//   "GET_ONE_OTHER_TEST_RESULT_SUCCESS";
// export const GET_ONE_OTHER_TEST_RESULT_FAIL = "GET_ONE_OTHER_TEST_RESULT_FAIL";



// export const getOneAutomatedTestResultStart = () => {
//   return {
//     type: GET_ONE_AUTOMATED_TEST_RESULT_START,
//   };
// };
// export const getOneAutomatedTestResultSuccess = (testresult) => {
//   return {
//     type: GET_ONE_AUTOMATED_TEST_RESULT_SUCCESS,
//     payload: testresult,
//   };
// };
// export const getOneAutomatedTestResultFail = (error) => {
//   return {
//     type: GET_ONE_AUTOMATED_TEST_RESULT_FAIL,
//     error: error,
//   };
// };

// export const getOneOtherTestResultStart = () => {
//   return {
//     type: GET_ONE_OTHER_TEST_RESULT_START,
//   };
// };
// export const getOneOtherTestResultSuccess = (testresult) => {
//   return {
//     type: GET_ONE_OTHER_TEST_RESULT_SUCCESS,
//     payload: testresult,
//   };
// };
// export const getOneOtherTestResultFail = (error) => {
//   return {
//     type: GET_ONE_OTHER_TEST_RESULT_FAIL,
//     error: error,
//   };
// };

// export const getOtherTestListStart = () => {
//   return {
//     type: GET_OTHER_TEST_LIST_START,
//   };
// };
// export const getOtherTestListSuccess = (testlist) => {
//   return {
//     type: GET_OTHER_TEST_LIST_SUCCESS,
//     payload: testlist,
//   };
// };
// export const getOtherTestListFail = (error) => {
//   return {
//     type: GET_OTHER_TEST_LIST_FAIL,
//     error: error,
//   };
// };


// export const getOneAutomatedTest = () => {
//   return (dispatch) => {
//     dispatch(getOneAutomatedTestResultStart());
//     axios
//       .get(`/opanda/panda-test-maps`)
//       .then((response) => {
//         dispatch(getOneAutomatedTestResultSuccess(response.data.results));
//       })
//       .catch((err) => {
//         dispatch(getOneAutomatedTestResultFail(err.message));
//       });
//   };
// };
// export const getOtherTestList = () => {
//   return (dispatch) => {
//     dispatch(getOneAutomatedTestResultStart());
//     axios
//       .get(`/opanda/panda-test-maps`)
//       .then((response) => {
//         dispatch(getOneAutomatedTestResultSuccess(response.data.results));
//       })
//       .catch((err) => {
//         dispatch(getOneAutomatedTestResultFail(err.message));
//       });
//   };
// };

// export const getOneOtherTest = () => {
//     return (dispatch) => {
//       dispatch(getOneOtherTestResultStart());
//       axios
//         .get(`/opanda/panda-test-maps`)
//         .then((response) => {
//           dispatch(getOneOtherTestResultSuccess(response.data.results));
//         })
//         .catch((err) => {
//           dispatch(getOneAutomatedTestResultFail(err.message));
//         });
//     };
//   };
  