import Input from "../../../elements/input";
import { CardTitle } from "../../../elements/text";
import {
  MdOutlineAddCircleOutline,
  MdOutlineRemoveCircleOutline,
} from "react-icons/md";
import { forwardRef, useRef, useState, useImperativeHandle, useEffect } from "react";

const PhonePicker = forwardRef((props, ref) => {
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");


  let guardianPhones = "";

  if (phone2.length > 5) {
    guardianPhones = phone + "," + phone2;
  } else {
    guardianPhones = phone;
  }

  useImperativeHandle(ref, () => ({
    guardianPhones,
  }));
  useEffect(() => {
    setPhone((props?.telephone && !props?.telephone?.includes("EMPTY")) ? props?.telephone : "");
  }, []);

  return (

    <Input
      label="Phone Number"
      elementType="phone"
      defaultCountry={"RW"}
      country={"RW"}
      value={phone}
      changed={setPhone}
    />
  );
});

export default PhonePicker;
