import Layout from '../../components/common/Layout';
import Community from '../../components/pages/parent/community/Community';

const ParentCommunityContainer = () => {
  return (
    <Layout>
      <Community />
    </Layout>
  );
};

export default ParentCommunityContainer;
