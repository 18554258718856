import { combineReducers } from "redux";
import pastpapersReducer from "./pastpaper";
import summarynotesReducer from "./summarynotes";
import simulationsReducer from "./simulations";
import featuresReducers from "./features";
import testsReducer from "./tests";
import classesReducer from "./teacher/classes";
import programsReducer from "./teacher/programs";
import teacherpastpapersReducer from "./teacher/pastpapers";

const featuresReducer = combineReducers({
  pastpapers: pastpapersReducer,
  summarynotes: summarynotesReducer,
  simulations: simulationsReducer,
  features: featuresReducers,
  tests: testsReducer,
  classes: classesReducer,
  programs: programsReducer,
  teacherpastpapers:teacherpastpapersReducer,
});

export default featuresReducer;
