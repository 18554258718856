import { useState } from 'react';
import parse from 'react-html-parser';
import RichTextEditor from '../../../../../elements/RichTextEditor';
import { useEffect } from 'react';
const OpenEnded = (props) => {
  const question = props?.questiondata?.questiondata.panda_open_ended_2020_03_question_data;
  const [answer, setAnswer] = useState('');

  const getDataOnChange = (text) => {
    props.callComplete(text, props.currentQuestionNumber, props.questionId);
    setAnswer(text);
  };
  useEffect(() => {
    //-- Check if previous answer exists--
    const newArr = props.openEnded;
    let dataIn = "";
    for (var i = 0; i < newArr?.length; i++) {
      if (parseInt(newArr[i].question_number) === parseInt(props.currentQuestionNumber) && parseInt(newArr[i].questionId) === parseInt(props.questionId)) {
        dataIn = (newArr[i].newEntry);
      }
    }
    setAnswer(dataIn);
  }, []);


  return (
    <>
      {question !== undefined ? parse(question) : parse('<p></p>')}
      <RichTextEditor
        label="Answer"
        id={`OpenEnded${props.questionId}`}
        value={answer}
        onChange={(text) => getDataOnChange(text)}
        placeholder="Write your answer here"
      />
    </>
  );
};
export default OpenEnded;
