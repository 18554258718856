import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import checkAuthorized from './checkAuthorized';

const UserRoute = ({ component: Component, allowedUser, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const userType = useSelector((state) => state.auth.user?.user_type);
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthorized = checkAuthorized(allowedUser, userType);
        if (!isAuthenticated)
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
        if (isAuthorized) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default UserRoute;
