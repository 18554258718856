import { Button } from "../../../../../elements/button";
import { CardTitle } from "../../../../../elements/text";
import { completeSignup } from "../../../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../../elements/spinner";
import Input from "../../../../../elements/input";
import {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import * as actions from "../../../../../../store/actions/";
import { Message } from "../../../../../common/messages";

const RecoveryQuestion = forwardRef((props, ref) => {
  const [question, setquestion] = useState("");
  const [questions, setquestions] = useState([]);
  const [answer, setanswer] = useState("");
  //SELECTION BUTTON HANDLER
  const [selectedQuestionValue, setSelectedQuestionValue] = useState("");
  const handleQuestionChange = (value) => {
    setSelectedQuestionValue(value);
    setquestion(value);
  };

  const number = props.number;
  useImperativeHandle(ref, () => ({
    recoveryquestion: { question, answer, number },
  }));
  const data = props.options;
  return (

    data.length > 0 ? <div className="md:flex space-x-2">
      <Input
        label={`Question ${props.number}`}
        elementType="select"
        elementConfig={{
          startingValue: "SELECT",
          options: [props.options[props.number - 1]],
        }}
        value={question}
        changed={handleQuestionChange}
      />
      <Input
        label="Answer"
        elementType="input"
        elementConfig={{
          type: "text",
          placeholder: "Answer here",
        }}
        value={answer}
        changed={setanswer}
      />
    </div> : ""

  );
});

const RecoveryInformation = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const userId = useSelector((state) => state.auth.userId);
  const recoveryQuestions = new Array(4).fill("");
  const questOneRef = useRef("");
  const questTwoRef = useRef("");
  const questThreeRef = useRef("");
  const questFourRef = useRef("");
  const [dataerror, setdataerror] = useState("");
  const recoveryquestions = useSelector(
    (state) => state.auth.recoveryquestions
  );
  const recoveryquestionsLoading = useSelector(
    (state) => state.auth.recoveryquestionsLoading
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const profile = useSelector((state) => state.profile.profile);
  const submitrecoveryinfo = () => {
    const userObject = {
      recovery_info_questionone:
        questOneRef.current?.recoveryquestion?.question,
      recovery_info_questiontwo:
        questTwoRef.current?.recoveryquestion?.question,
      recovery_info_questionthree:
        questThreeRef.current?.recoveryquestion?.question,
      recovery_info_questionfour:
        questTwoRef.current?.recoveryquestion?.question,
      recovery_info_answerone: questOneRef.current?.recoveryquestion?.answer,
      recovery_info_answertwo: questTwoRef.current?.recoveryquestion?.answer,
      recovery_info_answerthree:
        questThreeRef.current?.recoveryquestion?.answer,
      recovery_info_answerfour: questFourRef.current?.recoveryquestion?.answer,
      userId,
    };
    if (
      userObject?.recovery_info_questionone !== "" &&
      userObject?.recovery_info_questiontwo !== "" &&
      userObject?.recovery_info_questionthree !== "" &&
      userObject?.recovery_info_questionfour !== "" &&
      userObject?.userObject?.recovery_info_answerone !== "" &&
      userObject?.recovery_info_answertwo !== "" &&
      userObject?.recovery_info_answerthree !== "" &&
      userObject?.recovery_info_answerfour !== ""
    ) {
      dispatch(actions.completeprofileinformation(userObject));
      ///------
      dispatch(completeSignup("Recovery Information"));
      dispatch(completeSignup("Academic Information"));
    } else {
      setIsSubmitted(true);
      setdataerror("Some data are missing");
    }
  };

  useEffect(() => {
    dispatch(actions.getRecoveryQuestions());
  }, []);

  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div style={{ height: "80vh" }} className="bg-white drop-shadow-md mt-5 p-2  md:px-10 md:h-tab-screen-recovery md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Recovery Information" color="black" />

          <RecoveryQuestion
            ref={questOneRef}
            number={1}
            options={recoveryquestions}
            questionChanged={() => { }}
            answerChanged={() => { }}
          />
          <RecoveryQuestion
            ref={questTwoRef}
            number={2}
            options={recoveryquestions}
            questionChanged={() => { }}
            answerChanged={() => { }}
          />
          <RecoveryQuestion
            ref={questThreeRef}
            number={3}
            options={recoveryquestions}
            questionChanged={() => { }}
            answerChanged={() => { }}
          />
          <RecoveryQuestion
            ref={questFourRef}
            number={4}
            options={recoveryquestions}
            questionChanged={() => { }}
            answerChanged={() => { }}
          />
          <Message type={"error"} viewable={true} message={dataerror} />
          <div className="flex space-x-2 justify-center py-5">
            {/* <Button name="Previous" outline="true" color="blue" /> */}
            <Button
              name="Next"
              outline="true"
              color="blue"
              clicked={submitrecoveryinfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RecoveryInformation;
