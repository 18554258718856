import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
import { CardTitle } from '../../../../../../elements/text';
const SinglechoiceAnswerOptionGroup = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [isAnswer, setIsAnswer] = useState([]);
    const [UpdateUI, setUpdateUI] = useState(false);
    const answer_by_user = props.answer_by_user;
    useEffect(() => {
    }, []);

    const pickAnswer = (val, qnber, questionId, event) => {
        props.markSelectedMultipleChoiceQuestion(qnber, val, "single", questionId);
        setUpdateUI(!UpdateUI);

    }
    const find_me_in_user_answers = (answers_by_user, answerId, check) => {
        for (var i = 0; i < answers_by_user.length; i++) {
            if (parseInt(answers_by_user[i]) === (answerId) && check) {
                return <span className="text-green" style={{color:"green", fontSize:10}}>You chose this answer!!!! Hooray!!</span>
            } else if (parseInt(answers_by_user[i]) === (answerId)) {
                return <span className="text-red-600" style={{color:"red", fontSize:10}}>You chose this answer!!!! Sorry!!</span>
            }
        }
        return "";
    };


    return (
        <div>
            <form>
                <fieldset>
                    {answers.map((item, i) => {
                        return <div>
                            <table>
                                <tr>
                                    <td> <input checked={(parseInt(item.panda_tests_questions_answers_is_answer) === parseInt("1")) ? true : false} disabled={true} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  bg-gray-500' type="radio" name={`answer--${item.panda_tests_questions_answers_question_id}`} value={`${item.panda_tests_questions_answers_id}`} /></td>
                                    <td>{parse(item.panda_tests_questions_answers_data)}</td><td>{find_me_in_user_answers(answer_by_user, item.panda_tests_questions_answers_id, (parseInt(item.panda_tests_questions_answers_is_answer) === parseInt("1")))}</td>
                                </tr>
                            </table>
                        </div>
                    })}</fieldset>
            </form>
        </div>
    );
};
export default SinglechoiceAnswerOptionGroup;
