import { useState } from "react";
import { Button, ButtonLink, TextButton } from "../../../../elements/button";
import Modal from "../../../../elements/modal/modal";
import Table from "../../../../elements/table/table";
import { PageBody, SectionTitle } from "../../../../elements/text";

import shakeIcon from "../../../../../images/icons/handshake.svg";

const SubjectList = (props) => {
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const tableheader1 = ["Subject","Class", "Status", "Teacher", "Action"];

  const tablebody1 = [];

  props.data.map((value, index) => {
    let actionbutton = <></>;
    if (value[3] === "Approved") {
      actionbutton = (
        <>
          {" "}
          <ButtonLink name="Complete" page={`subjects/complete/${value[0]}`} />| 
          <TextButton name="Remove" color="red" /> 
        </>
      );
    } else if (value[3] === "Assigned to you") {
      actionbutton = <TextButton name="Accept" color="blue" clicked={()=>setshowSuccessModal(true)}/>;
    } else if (value[3] === "Active") {
      actionbutton = (
        <>
          <ButtonLink name="Edit" page={`subjects/edit/${value[0]}`} />| 
          <TextButton name="Remove" color="red" />
        </>
      );
    } else {
      actionbutton = <TextButton name="Remove" color="red"/>;
    }

    tablebody1.push({
      td1: { data: value[1], type: "text" },
      td2: { data: value[2], type: "text" },
      td3: { data: value[3], type: "text" },
      td4: { data: value[4], type: "text" },
      td5: {
        data: <>{actionbutton}</>,
        type: "component",
      },
    });
  });
  return (
    <div
      className={`${
        props.show ? `block` : `hidden`
      } pt-2 px-2 md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100 w-full`}
    >
      <Modal
        show={showSuccessModal}
        modalClosed={() => setshowSuccessModal(false)}
        small
      >
        <div className="flex flex-col">
          <SectionTitle name="Success!!" color="blue" alignment="center" />
          <PageBody
            name="You've successfully activated this subject. Click on manage class to continue with more actions"
            color="black"
            alignment="center"
          />
          <div className="m-4">
            <img
              alt={props.user}
              className="h-36 w-auto m-auto"
              src={shakeIcon}
            />
          </div>
          <div className="flex flex-row items-center justify-center">
            <ButtonLink
              name="Manage subject"
              outline="true"
              color="blue"
              page="/school/features/subjects/complete/1"
            />
          </div>
        </div>
      </Modal>

      <div className="relative overflow-x-auto sm:rounded-lg">
        <Table thead={tableheader1} tbody={tablebody1} />
      </div>
    </div>
  );
};

export default SubjectList;
