import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import FillinTypeAutoQuestionItemOneViewPopOverAnswer from './FillinTypeAutoQuestionItemOneViewPopOverAnswer';

const FillinTypeAutoQuestionItemOneViewPopOverItem = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);


    useEffect(() => {
    }, []);
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            // Swap array[i] and array[j]
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    return (
        <span onClick={() => { props.setHideme(true) }} > <span className='text-red'  ><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg></span>{
                shuffleArray(props.data).map((item, i) => {
                    return <FillinTypeAutoQuestionItemOneViewPopOverAnswer  currentQuestionNumber={props.currentQuestionNumber} questionId={props.questionId} callFillinTypeAuto={props.callFillinTypeAuto} setAnswerChoice={props.setAnswerChoice} item={item} key={i} />
                })
            }</span >
    );
};
export default FillinTypeAutoQuestionItemOneViewPopOverItem;
