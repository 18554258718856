import Layout from '../../../components/common/Layout';
import Test from '../../../components/pages/student/test/Test';

const TestContainer = () => {
  return (
    <Layout>
      <Test />
    </Layout>
  );
};

export default TestContainer;
