import { CardBody, SectionTitle } from "../../../elements/text";
import student from "../../../../images/signup.png";
import { useState, useEffect, useRef } from "react";
import Otp from "../enroll/otp";
import { Button, OtherLinkButton } from "../../../elements/button";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/";
import Spinner from "../../../elements/spinner";

const Verify = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [minutes, setminutes] = useState("00");
  const [seconds, setseconds] = useState("00");
  const auth = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);
  const changeOtpInputHandler = (event, index) => {
    if (isNaN(event.target.value)) return false;
    setOtp([
      ...otp.map((num, idx) => (idx === index ? event.target.value : num)),
    ]);
    if (event.target.nextSibling) {
      event.target.nextSibling.focus();
    }
  };

  function verification() {
    const otpjoin = otp.join("");
    if (otpjoin === auth?.otp) {
      history.push("/reset");
    } else {
      alert(`The password reset code is incorrect`);
    }
  }
  const resendotp = () => {
    const secondPhone = localStorage.getItem("secondPhone");
    const phone = localStorage.getItem("phone");
    const object = { email: auth?.email, secondPhone, phone };
    localStorage.removeItem("secondPhone");
    localStorage.removeItem("phone");
    dispatch(actions.resendotp(object));
    setTimer();
    return () => {
      clearInterval(interval.current);
    };
  };
  let interval = useRef();
  const setTimer = () => {
    var countDownDate = new Date(new Date().getTime() + 3 * 60000).getTime();
    interval = setInterval(() => {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var secs = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setminutes(mins);
        setseconds(secs);
      }
    }, 1000);
  };

  useEffect(() => {
    setTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return (
    <div>
      <div className="hidden md:block text-center w-30% m-auto">
        <SectionTitle name="Enter Code" color="blue" />
        <CardBody
          name="We use this to verify that this email or phone number is yours"
          color="blue"
        />
      </div>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div className="flex md:h-60vh justify-center items-center md:space-x-10 md:m-20 xl:m-40">
          <div className="md:w-50% md:h-full hidden md:block">
            <img
              className="w-full h-full object-cover rounded-3xl"
              src={student}
              alt=""
            />
          </div>
          <div className="md:w-50% md:h-full bg-gray-200 rounded-3xl text-center m-4 md:m-auto p-4">
            <div className="md:hidden">
              <SectionTitle name="Enter Code" color="blue" />
            </div>
            <CardBody
              name={(auth?.telephone) ? `We sent a code and link were sent to ${auth.telephone} and ${auth?.username}.
            Please enter the code below to reset your password`: `We sent a code and link were sent to your phone number and ${auth?.username}.
            Please enter the code below to reset your password`}
              color=""
            />
            <div className="py-3">
              <Otp otp={otp} changeOtpInputHandler={changeOtpInputHandler} />
            </div>
            <div className="flex flex-wrap justify-center">
              <CardBody name="Didn't receive the code &nbsp;" color="black" />
              {(minutes == 0 && seconds == 0) ? <Button
                clicked={resendotp}
                name="Resend"
                color="blue"
              //  to="/resend"
              // name="Resend"
              /> : ""}
              <CardBody name={`?  ${minutes}:${(seconds > 9) ? seconds : "0" + seconds} `} color="black" />
            </div>
            <div className="w-full flex items-center flex-col space-y-3 py-3">
              {(minutes > 0 || seconds > 0) ? <Button
                name="Reset"
                outline="true"
                color="blue"
                clicked={verification}
              /> : ""}
              {(minutes == 0 && seconds == 0) ? <div className="flex flex-wrap">
                <CardBody name="Didn't receive the code?&nbsp;" color="black" />
                <OtherLinkButton
                  color="blue"
                  // to="/resend"
                  name="Resend"
                  onClick={resendotp}
                />
              </div> : ""}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Verify;
