import axios from "../../axios-base";
import ValidatingToken from "../../functions/validatingtoken.js";
import * as actionTypes from "./actionTypes.js";
import { useHistory } from "react-router-dom";
export const AUTHENTICATE = "AUTHENTICATE";

export const authenticate = () => {
  return {
    type: AUTHENTICATE,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTHENTICATION_START,
  };
};

export const authSuccess = (authData) => {
  localStorage.setItem("jwt", authData.token);
  return {
    type: actionTypes.AUTHENTICATION_SUCCESS,
    token: authData.token,
    userId: authData.user.id,
    username: authData.user?.names?.split(" ")[0],
    displayName: authData.user.names,
    user: authData.user,
    telephone: authData.user.telephone,
    usertype: authData.user.user_type,
    academic_level: authData.user.academic_level,
    passwordReset: authData?.passwordReset ? true : false,
  };
};

export const userCreatedSuccess = (authData) => {
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    token: authData.token,
    username: authData.user.email,
    telephone: authData.user.telephone,
    userId: authData.user.id,
    user: authData.user,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTHENTICATION_FAIL,
    error: error.message,
  };
};
export const authLogout = () => {
  localStorage.removeItem("jwt");
  return {
    type: actionTypes.AUTHENTICATION_LOGOUT,
    token: null,
    userId: null,
    username: null,
    displayName: null,
    user: null,
    telephone: null,
    usertype: null,
    academic_level: null,
  };
};

export const getUsersStart = () => {
  return {
    type: actionTypes.GET_USERS_START,
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    users: users,
  };
};

export const getOneUsersSuccess = (user) => {
  return {
    type: actionTypes.GET_ONE_USERS_SUCCESS,
    user: user,
  };
};

export const getUsersFail = (error) => {
  return {
    type: actionTypes.GET_USERS_FAIL,
    error: error,
  };
};

export const getUsers = () => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .get(`/opanda/panda-users`)
      .then((response) => {
        dispatch(getUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const updateSchoolInfo = (object, token) => {
  // const token = localStorage.getItem("jwt");
  // const token = object.token;
  ValidatingToken(token);
  return (dispatch) => {
    axios
      .put(`/opanda/panda-users/update-user-account-info`, object, {
        headers: { authorization: token },
      })
      .then((response) => {
        const authData = { token, user: response.data.results };
        dispatch(authSuccess(authData));
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};

export const updateUserAccountInfo = (object) => {
  const token = localStorage.getItem("jwt");
  //const token = object.token;
  ValidatingToken(token);
  return (dispatch) => {
    axios
      .put(`/opanda/panda-users/update-user-account-info`, object, {
        headers: { authorization: token },
      })
      .then((response) => {
        const authData = { token, user: response.data.results };
        dispatch(authSuccess(authData));
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};
export const updateUserAccountInfoFinal = (object) => {
  const token = localStorage.getItem("jwt");
  ValidatingToken(token);
  return (dispatch) => {
    axios
      .put(`/opanda/panda-users/update-user-account-info-final`, object, {
        headers: { authorization: token },
      })
      .then((response) => {
        const authData = { token, user: response.data.results };
        dispatch(authSuccess(authData));
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};

export const updateUserAccountPassword = (object) => {
  const token = localStorage.getItem("jwt");
  ValidatingToken(token);
  return (dispatch) => {
    axios
      .put(`/opanda/panda-users/update-user-account-password`, object, {
        headers: { authorization: token },
      })
      .then((response) => {
        const authData = {
          token,
          passwordReset: true,
          user: response.data.results,
        };
        dispatch(authSuccess(authData));
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};

export const getOneUsers = (username) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .get(`/opanda/panda-users/find-user/${username}`)
      .then((response) => {
        dispatch(getOneUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const getOneUsersByOtp = (otp) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .get(`/opanda/panda-users/find-user-otp/${otp}`)
      .then((response) => {
        dispatch(userCreatedSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const createAccount = (object) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(`/opanda/panda-users/create-account`, object)
      .then((response) => {
        dispatch(userCreatedSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.response.data));
      });
  };
};

export const login = (email, password = "", type = false) => {
  return (dispatch) => {
    dispatch(authStart());
    let authData = {
      email,
      password,
      type,
    };
    axios
      .post("/opanda/login", authData)
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.response ? err.response.data : err));
        setTimeout(() => {
          dispatch(authFail(""));
        }, 8000);
      });
  };
};

export const loginwithtoken = (token, type = false) => {
  return (dispatch) => {
    dispatch(authStart());
    let authData = {
      token,
      type,
    };
    axios
      .post("/opanda/loginwithtoken", authData)
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.response ? err.response.data : err));
        setTimeout(() => {
          dispatch(authFail(""));
        }, 8000);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(authLogout());
  };
};

export const socialLogin = (userId) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(`/opanda/social-login/${userId}`)
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.response ? err.response.data : err));
      });
  };
};

export const resendotp = (object) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .put(`/opanda/resend-otp`, object)
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.response ? err.response.data : err));
      });
  };
};

export const passwordResetRequest = (object) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .put(`/opanda/panda-users/reset-password-request`, object)
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.response ? err.response.data : err));
      });
  };
};

export const resetpassword = (object) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .put(`/opanda/panda-users/reset-password`, object)
      .then((response) => {
        dispatch(
          authSuccess({ passwordReset: true, ...response.data.results })
        );
      })
      .catch((err) => {
        dispatch(authFail(err.response ? err.response.data : err));
      });
  };
};

export const getRecoveryQuestionsStart = () => {
  return {
    type: actionTypes.GET_RECOVERY_QUESTIONS_START,
  };
};

export const getRecoveryQuestionsSuccess = (recoveryquestions) => {
  return {
    type: actionTypes.GET_RECOVERY_QUESTIONS_SUCCESS,
    recoveryquestions: recoveryquestions,
  };
};

export const getOneRecoveryQuestionsSuccess = (recoveryquestion) => {
  return {
    type: actionTypes.GET_ONE_RECOVERY_QUESTIONS_SUCCESS,
    recoveryquestion: recoveryquestion,
  };
};

export const getRecoveryQuestionsFail = (error) => {
  return {
    type: actionTypes.GET_RECOVERY_QUESTIONS_FAIL,
    error: error,
  };
};

export const getRecoveryQuestions = () => {
  return (dispatch) => {
    dispatch(getRecoveryQuestionsStart());
    axios
      .get(`/opanda/recovery-questions`)
      .then((response) => {
        dispatch(getRecoveryQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getRecoveryQuestionsFail(err.message));
      });
  };
};

export const passwordResetRequestWIthQuestions = (object) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .put(`/opanda/panda-users/reset-password-request-with-questions`, object)
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};

export const getSchoolsStart = () => {
  return {
    type: actionTypes.GET_SCHOOLS_START,
  };
};

export const getSchoolsSuccess = (schools) => {
  return {
    type: actionTypes.GET_SCHOOLS_SUCCESS,
    schools: schools,
  };
};

export const getOneSchoolsSuccess = (school) => {
  return {
    type: actionTypes.GET_ONE_SCHOOLS_SUCCESS,
    school: school,
  };
};

export const getSchoolsFail = (error) => {
  return {
    type: actionTypes.GET_SCHOOLS_FAIL,
    error: error,
  };
};

export const getSchools = (token) => {
  return (dispatch) => {
    dispatch(getSchoolsStart());
    axios
      .get(`/opanda/schools`, null, {
        headers: { authorization: token },
      })
      .then((response) => {
        dispatch(getSchoolsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSchoolsFail(err.message));
      });
  };
};

export const createSchoolByStudent = (object, token) => {
  ValidatingToken(token);
  return (dispatch) => {
    dispatch(getSchoolsStart());
    axios
      .post(`/opanda/schools/create-by-student`, object, {
        headers: { authorization: token },
      })
      .then((response) => {
        dispatch(getSchoolsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSchoolsFail(err.message));
      });
  };
};

export const getDistrictSchools = (
  district,
  token,
  schoolStatus = "Public"
) => {
  return (dispatch) => {
    dispatch(getSchoolsStart());
    axios
      .post(
        `/opanda/schools/district`,
        { district: district, status: schoolStatus },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        dispatch(getSchoolsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSchoolsFail(err.message));
      });
  };
};

export const getDecodingToken = (token) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(`/opanda/decoding`, { token })
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};

export const getDecoding = (token) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(`/opanda/decoding/token`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};
