

export const scheduledNotifications = (data1: any) => {
  const schedules = []

  if (data1.length > 0 && data1[0]) {
    const data = data1[0];
    for (var i = 0; i < data.length; i++) {
      schedules.push({
        name: data[i].name,
        subjects: [data[i].subject],
        type: 'schedule',
        uploader: data[i].uploader,
        schedules: []
      });
      //---  
      for (var n = 0; n < data[i].schedules.length; n++) {

        schedules[i].schedules.push({
          subjects: data[i].schedules[n].subject,
          unit: data[i].schedules[n].feature_name,
          createdAt: data[i].schedules[n].createdAt,
          date: new Date(data[i].schedules[n].dateschedule).getFullYear() + '-' + (((new Date(data[i].schedules[n].dateschedule).getMonth() + 1) < 10) ? ("0" + (new Date(data[i].schedules[n].dateschedule).getMonth() + 1)) : (new Date(data[i].schedules[n].dateschedule).getMonth() + 1)) + '-' + (((new Date(data[i].schedules[n].dateschedule).getDate() + 1) < 10) ? ("0" + (new Date(data[i].schedules[n].dateschedule).getDate() + 1)) : (new Date(data[i].schedules[n].dateschedule).getDate() + 1)),
          now: new Date(),
          time: `${data[i].schedules[n].begintime}-${data[i].schedules[n].endtime}`,
          test_id: data[i].schedules[n].test_id,
        });
      }

    }
  }


  return schedules;

};


