import Footer from "../components/common/footer";
import Header from "../components/common/header/header";
import ScrollToTop from "../components/common/ScrollToTop";
import Herosection from "../components/pages/landing/enroll/herosection";
import PaymentOptions from "../components/pages/landing/enroll/paymentOptions";
import Verification from "../components/pages/landing/enroll/verification";
import Pricing from "../components/pages/landing/enroll/pricing";
import Layout from "../components/common/Layout";
export const Enroll = (props) => {
  return (
    <div className="scrollbar-hidden">
      <Header />
      <Herosection />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export const Verify = (props) => {
  return (
    <div className="h-screen flex flex-col justify-between">
      <Header />
      <Verification />
      <Footer />
    </div>
  );
};

export const EnrollPayment = (props) => {
  return (
    <Layout>
      <Pricing />
    </Layout>
  );
};

export const EnrollPaymentOptions = () => {
  return (
    <Layout>
      <PaymentOptions />
    </Layout>
  );
};

export const PaymentSuccess = (props) => {
  return (
    <Layout>
      <div>
        <label>PAYMENT SUCCESS!!</label>
      </div>
    </Layout>
  );
};
