import React from 'react';
import Settings from '../../../components/pages/teacher/user/settings/index';
import Layout from '../../../components/common/Layout';

const SettingsContainer = () => {
  return (
    <Layout>
      <Settings />
    </Layout>
  );
};

export default SettingsContainer;