import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../../../constants/constants";
import { convertToRoman } from "../../../../../../shared/utility";
import {
  addMultipleQuestionOption,
  removeMultipleQuestionOption,
  setCurrentQuestion,
  setMulitpleQuestionOptions,
} from "../../../../../../store/actions/features/tests";
import { Button } from "../../../../../elements/button";
import Input from "../../../../../elements/input";
import RichTextEditor from "../../../../../elements/RichTextEditor";
import { CardBody } from "../../../../../elements/text";
import AnswerOption from "./AnswerOption";

const AddMultipleQuestion = (props) => {
  const dispatch = useDispatch();
  const [marks, setMarks] = useState(props?.question?.marks);
  const [duration, setDuration] = useState(props?.question?.duration);
  const [question, setQuestion] = useState(props?.question?.question);
  const [explanation, setExplanation] = useState(props?.question?.explanation);
  const [options, setOptions] = useState(props?.question?.answers);
  const [currentQuestion, setCurrentQuestion] = useState(props?.questionNber);

  // useEffect(() => {
  //   if (Object.keys(currentQuestion).length !== 0) {
  //     setMarks(currentQuestion.marks);
  //     setDuration(currentQuestion.duration);
  //     setQuestion(currentQuestion.question);
  //     setExplanation(currentQuestion.explanation);
  //     dispatch(setMulitpleQuestionOptions(currentQuestion.options));
  //   }
  // }, []);

  // useEffect(() => {
  //   dispatch(
  //     setCurrentQuestion({
  //       type: constants.multiple_choice,
  //       marks: marks,
  //       duration: duration,
  //       question: question,
  //       explanation: explanation,
  //       options: options,
  //     })
  //   );
  // }, [dispatch, marks, duration, question, explanation, options]);

  const addOption = () => {
    // dispatch(addMultipleQuestionOption({ id: options.length }));
    //setOptions([...options, ""]);
    const newOptions = props?.question?.answers;
    props.updateMultipleChoiceQuestionAnswers(props?.questionNber, [...newOptions, { value: "", isAnswer: false }]);
  };

  const removeAnswerOption = (val, i) => {
    if (window.confirm("Are you sure you want to remove this option?")) {
      const newOptions = props?.question?.answers.filter((option, index) => index !== i);
      props.updateMultipleChoiceQuestionAnswers(props?.questionNber, [...newOptions]);
    }
  };


  const updateAnswersOptionsIn = (id, option) => {
    const newOptions = props?.question?.answers;
    newOptions[id].value = option;
    props.updateMultipleChoiceQuestionAnswers(props?.questionNber, [...newOptions]);
  }

  const updateAnswersOptionsInStatus = (id, isAnswer) => {
    const newOptions = props?.question?.answers;
    newOptions[id].isAnswer = isAnswer;
    props.updateMultipleChoiceQuestionAnswers(props?.questionNber, [...newOptions]);
  }


  return (
    <div className="w-70% border-t border-gray-500">
      <div className="flex space-x-2">
        <Input
          label="Marks"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Enter question marks here",
          }}
          value={props?.question?.marks}
          changed={(mark) => {
            props.updateMultipleChoiceQuestionTotalmarks(props.questionNber, mark);
          }}
        />
        <Input
          label="Duration"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Enter test duration here",
          }}
          value={props?.question?.duration}
          changed={(time) => {
            props.updateMultipleChoiceQuestionTotaltime(props.questionNber, time);
          }}
        />
      </div>
      <RichTextEditor
        label="Question"
        value={props?.question?.question}
        onChange={(text) => {
          //setQuestion(text);
          props.updateMultipleChoiceQuestion(props.questionNber, text);
        }}
        placeholder="Write your question here"
      />
      <RichTextEditor
        label="Explanation"
        value={props?.question?.explanation}
        onChange={(text) => {
          //setExplanation(text)
          props.updateMultipleChoiceQuestionExplanation(props.questionNber, text);
        }}
        placeholder="Write your explanation here"
      />
      <CardBody name="List Of Answers" additional="font-semibold" />
      {props?.question?.answers.map((option, i) => (
        <>
          <CardBody name={`Option ${convertToRoman(i + 1)}.`} />
          <AnswerOption
            key={i}
            id={i}
            option={option}
            updateAnswersOptionsIn={updateAnswersOptionsIn}
            updateAnswersOptionsInStatus={updateAnswersOptionsInStatus}
            removeAnswerOption={() => {
              removeAnswerOption(option, i);
            }}
          />
        </>
      ))}
      <Button
        name="Add Answer Option"
        isSquare
        outline="false"
        color="blue"
        additional="mb-5"
        clicked={addOption}
      />
    </div>
  );
};

export default AddMultipleQuestion;
