import { Button } from "../../button";
import { CardBody, SmallText } from "../../text";
import { useHistory } from "react-router-dom";
import { calculateTimeFromDate } from "../../../../shared/utility";
import { useEffect, useState } from "react";
import axios from "../../../../axios-base";
import { useSelector } from 'react-redux';
const MainNotificationCard = (props) => {
  const history = useHistory();
  const { id, user_type } = useSelector((state) => state.auth.user);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {


  }, []);
  return (
    (!props.MarkedNotifications.includes(props.id)) ? <div
      className={`rounded p-3 my-1 ${props.viewed ? "bg-gray-100" : "bg-white-blue"
        }`}
    >
      <div className="flex justify-between items-center">
        <CardBody name={props.title} additional="font-bold" />
        <SmallText
          name={`${calculateTimeFromDate(props.createdAt)} ago`}
          color="gray-500"
        />
      </div>
      <CardBody name={props.body} />
      <div className="flex justify-between items-center">
        <SmallText name={`By: ${props.creator}`} color="red" />
        <Button
          name={"X"}
          outline="true"
          color="red"
          clicked={() => {
            axios.post(
              `/opanda/panda-users/markThisNotificationAsViewed`,
              {
                user_id: id,
                notifId: props.id
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`
                }
              }
            )
              .then((response) => {
                props.getMarkedNotifications(props.id);

              })
              .catch((err) => {

              });
          }}
        />
      </div>
    </div> : ""
  );
};

export default MainNotificationCard;
