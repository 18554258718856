import Layout from '../../../components/common/Layout';
import NewPastPaper from '../../../components/pages/teacher/pastpaper/NewPastPaper';

const NewPaperContainer = (props) => {
  return (
    <Layout>
      <NewPastPaper {...props} />
    </Layout>
  );
};

export default NewPaperContainer;
