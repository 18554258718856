import React from 'react';

const ConfirmModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50" >
      {/* Full-screen transparent backdrop */}
      <div className="fixed inset-0 bg-black bg-opacity-50 z-0"></div>

      {/* Centered modal */}
      <div className="bg-white rounded-lg shadow-lg w-full md:w-80 z-10">
        <div className="flex justify-between items-center mb-4 p-4">
          <h2 className="text-xl font-semibold">Confirm</h2>
          <button
            className="text-gray-600 hover:text-gray-800 transition duration-300"
            onClick={onCancel}
          >
            &times;
          </button>
        </div>
        <div className="mb-4 p-4">
          <p>{message}</p>
        </div>
        <div className="flex justify-end p-4">
          <button
            className="bg-blue-500 border border-gray-300 border border-gray-300 px-4 py-2  rounded mr-2 hover:border-gray-500 transition duration-300"
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            className="border border-gray-300 px-4 py-2 rounded hover:border-gray-500 transition duration-300"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
