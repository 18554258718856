import axios from "../../axios-base";
import { getCachedData, ignoreCachedData } from "../../shared/caching";


export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED";
//----Get The Selected Unit With linked labs, docs and videos---_get_selected_unit_with_labs_docs_videos----------
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_INIT =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_INIT";
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_SUCCESS =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_SUCCESS";
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_FAILED =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_FAILED";
//-----Send the comment on the selected content in summary notes--
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_INIT =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_INIT";
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_SUCCESS =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_SUCCESS";
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FAILED =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FAILED";
//-----first load the comment on the selected content in summary notes--
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_INIT =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_INIT";
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_SUCCESS =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_SUCCESS";
export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_FAILED =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_FAILED";
export function student_subjects_by_academic_units_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT
  };
}
export function student_subjects_by_academic_units_success(data) {


  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS,
    payload: data
  };
}
export function student_subjects_by_academic_units_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED,
    payload: error
  };
}

export function student_subjects_by_academic_units_start(data) {


  return (dispatch) => {
    dispatch(student_subjects_by_academic_units_init());
    getCachedData('cbcsubject-cache-subjects', `/opanda/summarynotes/cbcsubject`, 'POST', 10800,
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      }, {
      academic_level: data.academic_level,
      subject: data.subject,
      user_id: data.user_id,
      subject: data.subject
    })
      .then((response) => {

        dispatch(student_subjects_by_academic_units_success(response.results));
      })
      .catch((err) => {
        dispatch(student_subjects_by_academic_units_fail(err.message));
      });
  };
}
export function teacher_subjects_by_academic_units_start(data) {


  return (dispatch) => {
    dispatch(student_subjects_by_academic_units_init());
    getCachedData('cbcsubject-cache-subjects' + data.academic_level, `/opanda/summarynotes/cbcsubject`, 'POST', 10800,
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      }, {
      academic_level: data.academic_level,
      subject: data.subject,
      user_id: data.user_id,
      subject: data.subject
    })
      .then((response) => {

        dispatch(student_subjects_by_academic_units_success(response.results));
      })
      .catch((err) => {
        dispatch(student_subjects_by_academic_units_fail(err.message));
      });
  };
}

export function teacher_subjects_by_academic_units_no_cache_start(data) {


  return (dispatch) => {
    dispatch(student_subjects_by_academic_units_init());
    ignoreCachedData('cbcsubject-cache-subjects' + data.academic_level, `/opanda/summarynotes/cbcsubject`, 'POST', 10,
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      }, {
      academic_level: data.academic_level,
      subject: data.subject,
      user_id: data.user_id,
      subject: data.subject
    })
      .then((response) => {

        dispatch(student_subjects_by_academic_units_success(response.results));
      })
      .catch((err) => {
        dispatch(student_subjects_by_academic_units_fail(err.message));
      });
  };
}
//----Get The Selected Unit With linked labs, docs and videos-------------
export function student_get_selected_unit_with_labs_docs_videos_init() {
  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_INIT
  };
}
export function student_get_selected_unit_with_labs_docs_videos_success(data) {


  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_SUCCESS,
    payload: data
  };
}
export function student_get_selected_unit_with_labs_docs_videos_fail(error) {
  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_FAILED,
    payload: error
  };
}

export function student_get_selected_unit_with_labs_docs_videos_start(data) {
  return (dispatch) => {
    dispatch(student_get_selected_unit_with_labs_docs_videos_init());
    getCachedData('cbcsubject-cache', `/opanda/summarynotes/getselectedunitwithmoredata`, 'POST', 10800,
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      }, {
      unit_id: data.unit_id,
      subject: data.subject,
      academic_level: data.academic_level,
      user_type: data.user_type,
      day: new Date().getTime(),
      user_id: data.user_id,
    })
      .then((response) => {

        dispatch(student_get_selected_unit_with_labs_docs_videos_success(response.results));
      })
      .catch((err) => {
        dispatch(student_get_selected_unit_with_labs_docs_videos_fail(err.message));
      });


  };
}
export function teacher_get_selected_unit_with_labs_docs_videos_start(data) {
  return (dispatch) => {
    dispatch(student_get_selected_unit_with_labs_docs_videos_init());
    getCachedData('cbcsubject-cache' + data.academic_level, `/opanda/summarynotes/getselectedunitwithmoredata`, 'POST', 10800,
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      }, {
      unit_id: data.unit_id,
      subject: data.subject,
      academic_level: data.academic_level,
      user_type: data.user_type,
      day: new Date().getTime(),
      user_id: data.user_id,
    })
      .then((response) => {

        dispatch(student_get_selected_unit_with_labs_docs_videos_success(response.results));
      })
      .catch((err) => {
        dispatch(student_get_selected_unit_with_labs_docs_videos_fail(err.message));
      });


  };
}
//-----Send the comment on the selected content in summary notes--
export function student_get_selected_unit_with_labs_docs_videos_comment_init() {
  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_INIT
  };
}
export function student_get_selected_unit_with_labs_docs_videos_comment_success(data) {


  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_SUCCESS,
    payload: data
  };
}
export function student_get_selected_unit_with_labs_docs_videos_comment_fail(error) {
  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FAILED,
    payload: error
  };
}

export function student_get_selected_unit_with_labs_docs_videos_comment_start(data) {


  return (dispatch) => {
    dispatch(student_get_selected_unit_with_labs_docs_videos_comment_init());

    axios
      .post(
        `/opanda/summarynotes/getselectedunitwithmoredatacommentonunit`,
        {
          commentor_id: data.commentor_id,
          commentor_names: data.commentor_names,
          comment: data.comment,
          comment_header: data.comment_header,
          summary_note_commend_id: data.summary_note_commend_id

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_success(response));
      })
      .catch((err) => {
        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_fail(err.message));
      });


  };
}
export function student_get_comments_onABook_start(data) {


  return (dispatch) => {
    dispatch(student_get_selected_unit_with_labs_docs_videos_comment_init());

    axios
      .post(
        `/opanda/summarynotes/get_comments_onABook`,
        {
          commentor_id: data.commentor_id,
          commentor_names: data.commentor_names,
          comment: data.comment,
          comment_header: data.comment_header,
          bookId: data.bookId

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_success(response));
      })
      .catch((err) => {
        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_fail(err.message));
      });


  };
}
export function student_comment_on_a_simulation_start(data) {


  return (dispatch) => {
    dispatch(student_get_selected_unit_with_labs_docs_videos_comment_init());

    axios
      .post(
        `/opanda/summarynotes/comment_on_a_simulation`,
        {
          commentor_id: data.commentor_id,
          commentor_names: data.commentor_names,
          comment: data.comment,
          comment_header: data.comment_header,
          simid: data.simid

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_success(response));
      })
      .catch((err) => {
        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_fail(err.message));
      });


  };
}
//-----first load the comment on the selected content in summary notes--
export function student_get_selected_unit_with_labs_docs_videos_comment_first_load_init() {
  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_INIT
  };
}
export function student_get_selected_unit_with_labs_docs_videos_comment_first_load_success(data) {


  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_SUCCESS,
    payload: data
  };
}
export function student_get_selected_unit_with_labs_docs_videos_comment_first_load_fail(error) {
  return {
    type: STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_COMMENT_FIRST_LOAD_FAILED,
    payload: error
  };
}

export function student_get_selected_unit_with_labs_docs_videos_comment_first_load_start(data) {


  return (dispatch) => {
    dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_init());

    axios
      .post(
        `/opanda/summarynotes/getselectedunitwithmoredatacommentonunitfirstload`,
        {
          commentor_id: data.commentor_id,
          commentor_names: data.commentor_names,
          comment: data.comment,
          comment_header: data.comment_header,
          summary_note_commend_id: data.summary_note_commend_id

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_success(response));
      })
      .catch((err) => {
        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_fail(err.message));
      });


  };
}
export function student_get_selected_book_and_comment_first_load_start(data) {


  return (dispatch) => {
    dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_init());

    axios
      .post(
        `/opanda/summarynotes/commentdOnAbook`,
        {
          commentor_id: data.commentor_id,
          commentor_names: data.commentor_names,
          comment: data.comment,
          comment_header: data.comment_header,
          bookId: data.bookId

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_success(response));
      })
      .catch((err) => {
        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_fail(err.message));
      });


  };
}
export function student_get_comments_onsimulations_first_load_start(data) {


  return (dispatch) => {
    dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_init());

    axios
      .post(
        `/opanda/summarynotes/get_comments_onsimulations`,
        {
          commentor_id: data.commentor_id,
          commentor_names: data.commentor_names,
          comment: data.comment,
          comment_header: data.comment_header,
          simid: data.simid

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_success(response));
      })
      .catch((err) => {
        dispatch(student_get_selected_unit_with_labs_docs_videos_comment_first_load_fail(err.message));
      });


  };
}