import StudentBaselineInformation from "./student/baselineInformation";
import TeacherBaselineInformation from "./teacher/baselineInformation";
import SchoolBaselineInformation from "./school/baselineInformation";
import ParentBaselineInformation from "./parent/baselineInformation";

const BaselineInformation = (props) => {
  
  let userType = props.user;
  let Tab = StudentBaselineInformation;
  switch (userType) {
    case "student":
      Tab = StudentBaselineInformation;
      break;
    case "teacher":
      Tab = TeacherBaselineInformation;
      break;
    case "school":
      Tab = SchoolBaselineInformation;
      break;
    case "parent":
      Tab = ParentBaselineInformation;
      break;
    default:
      Tab = StudentBaselineInformation;
      break;
  }
  return (
    <div>
      <Tab />
    </div>
  );
};

export default BaselineInformation;
