import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
import FillinTypeAutoQuestionItemOneViewPopOver from './FillinTypeAutoQuestionItemOneViewPopOver';

const FillinTypeAutoQuestionItem = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    useEffect(() => {

    }, []);

    const getDatain = (data) => {
        const rnd_name = "name" + (Math.random() * 1000);
        return <FillinTypeAutoQuestionItemOneViewPopOver b={props.b} wordsCleaned={props.wordsCleaned} fillinTypeAuto={props.fillinTypeAuto} questionPlace={props.questionPlace} currentQuestionNumber={props.currentQuestionNumber} questionId={props.questionId} callFillinTypeAuto={props.callFillinTypeAuto} data={data} rnd_name={rnd_name} />;
    }
    return (
        <mark className='cursor-default p-2 bg-blue text-center px-1 py-1 text-white m-1 text-sm space-y-1.5'>
            {getDatain(props.wordsPool)}
        </mark>
    );
};
export default FillinTypeAutoQuestionItem;
