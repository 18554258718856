import {
  CardBody,
  CardTitle,
  FeatureTitle,
  PageBody,
  PageTitle,
  SectionTitle,
} from "../../../../../elements/text";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../elements/input";
import Spinner from "../../../../../elements/spinner";
import { Button, GoBackButton, ButtonLink } from "../../../../../elements/button";
import PageContent from "../../../pageContent";
import Notification from "../../../../../elements/notification/notification";
import { useHistory } from "react-router-dom";
import Modal from "../../../../../elements/modal/modal";
import SuccessIcon from "../../../../../../images/icons/success.svg";

const CreateForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.programs.programsLoading);
  const programs = useSelector((state) => state.programs.programs);
  const [program, setProgram] = useState();
  const [section, setSection] = useState();
  const [activeclass, setActiveclass] = useState();
  const [combination, setCombinations] = useState();
  const [stream, setStream] = useState("");
  const [subjects, setSubjects] = useState();
  const [showSuccessModal, setshowSuccessModal] = useState(false);

  const programName = getProgramNameList(programs);
  const sections = getSectionListByProgram(programs, program);
  const classlevels = getclassesList(programs, program, section);
  const combinations = getcombinationsList(programs, program, section);
  const subjectlist = getsubjectsList(programs, program, section, combination);

  const [activeclasses, setactiveClasses] = useState(["S2 B", "S1 A"]);

  return (
    <PageContent>
      <Modal
        show={showSuccessModal}
        modalClosed={() => setshowSuccessModal(false)} small
      >
        <div className="flex flex-col">
          <SectionTitle name="Created!!" color="blue" alignment="center" />
          <PageBody
            name="You've successfully created a subject, click on manage subject to complete it"
            color="black"
            alignment="center"
          />
          <div className="m-4">
            <img
              alt={props.user}
              className="h-36 w-auto m-auto"
              src={SuccessIcon}
            />
          </div>
          <div className="flex flex-row items-center justify-center">
            <ButtonLink name="OKAY" outline="true" color="blue" page="/school/features/subjects" />
            <ButtonLink
              name="Manage"
              outline="true"
              color="blue"
              page="/school/features/subjects/complete/3/students"
            />
          </div>
        </div>
      </Modal>
      <div
        className={`relative w-full p-3 col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <GoBackButton
          link="/school/features/subjects"
          action="Back"
          location="Classes"
        />
        {loading ? (
          <Spinner size="32" color="blue" />
        ) : (
          <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
            <SectionTitle name="Create subject" color="blue" />
            <>
              <Input
                label="Program"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  optionsType: "minimal",
                  options: programName,
                }}
                value={program}
                changed={setProgram}
                validation={{ required: true }}
                shouldValidate
                error="Program is required"
              />
              <div className="flex space-x-2">
             
                <Input
                  label="Classes"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    optionsType: "minimal",
                    options: activeclasses,
                  }}
                  value={activeclass}
                  changed={setActiveclass}
                  validation={{ required: true }}
                  shouldValidate
                  error="Class is required"
                />
              

                
              </div>
              <div className="flex space-x-2">
                <Input
                  label="Subjects"
                  elementType="singleselect"
                  elementConfig={{
                    startingValue: "SELECT",
                    optionsType: "minimal",
                    options: subjectlist,
                  }}
                  value={subjects}
                  changed={setSubjects}
                  validation={{ notEmpty: true }}
                  shouldValidate
                  error="Subject is required"
                />
              </div>
              <div className="flex space-x-2 justify-center py-5">
                <Button name="Cancel" outline="true" color="blue" />
                <Button
                  name="Create"
                  outline="false"
                  color="blue"
                  clicked={() => setshowSuccessModal(true)}
                />
              </div>
            </>
          </div>
        )}
      </div>
      <div className="w-35% mt-5">
        <Notification auth />
      </div>
    </PageContent>
  );
};

const getProgramNameList = (programObject) => {
  let names = Object.keys(programObject);
  return names;
};
const getSectionListByProgram = (programObject, program = "CBC") => {
  let sections = Object.keys(programObject[program].structure);
  return sections;
};
const getclassesList = (
  programObject,
  program = "CBC",
  section = "Ordinary level"
) => {
  let classes = programObject[program]["structure"][section]["Classes"];
  return classes;
};
const getcombinationsList = (
  programObject,
  program = "CBC",
  section = "Advanced level"
) => {
  let combinations = [];
  if (section.includes("Ordinary level")) {
  } else {
    combinations = Object.keys(
      programObject[program]["structure"][section].Combinations
    );
  }
  return combinations;
};

const getsubjectsList = (
  programObject,
  program = "CBC",
  section = "Ordinary level",
  combination = ""
) => {
  let subjects = [];
  if (section.includes("Ordinary level")) {
    subjects = programObject[program]["structure"][section].Subjects;
  } else {
    if (combination !== undefined && combination !== "") {
      console.log(combination);
      subjects =
        programObject[program]["structure"][section]["Combinations"][
          combination
        ]["default"];
      subjects.push(programObject[program]["structure"][section]["Subjects"]);
    } else {
      subjects.push(programObject[program]["structure"][section].Subjects);
    }
  }

  return subjects;
};

export default CreateForm;
