import Header from '../components/common/header/header';
import Hero from '../components/common/hero';
import ScrollToTop from '../components/common/ScrollToTop';
import Spinner from '../components/elements/spinner';
import PageContent from '../components/pages/landing/faq/pagecontent';
import { useRef, useState } from "react";
export const Teacherquiz = (props) => {
  const [loading, setLoading] = useState(true);
  const iframe = useRef(null);

  const handleIframeLoad = () => {
    // The iframe has finished loading
    setLoading(false);
    const iframeDocument = iframe.current.contentDocument;

    const iframeWindow = iframe.contentWindow;

    // Send a message from the web application to the iframe
    // Enable the pointer-events property on the ifra
  };
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
      <Header />
      <div className='p-5'>
        {loading ? <Spinner size="40" color="blue" /> : ""}
        <iframe
          onLoad={handleIframeLoad}
          src={`${process.env.REACT_APP_V2_URL}/learnall.php`}
          title="Example Website"
          allowfullscreen
          width={"100%"}
          style={{
            display: !loading ? "block" : "none",
            minHeight: "100%",
            minWidth: "100%",
            height:"100vh"
          }}
          height={"100%"}
          ref={iframe}
          sandbox="allow-same-origin allow-scripts allow-modals allow-popups"
          loading="eager"
          frameborder="0"
        />
      </div>
      <ScrollToTop />
    </div>
  );
};
