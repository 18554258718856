import { useTranslation } from 'react-i18next';
import { ButtonLink, AnchorButton } from '../../../elements/button';
import { PageBody, PageTitle, CardSubText } from '../../../elements/text';

const Enroll = (props) => {
  const { t } = useTranslation();
  let imgsrc = '/images/enroll/1.png';
  return (
    <div className="bg-white">
      <div className="w-full flex flex-col align-center  md:flex-row max-w-7xl border-b-2 border-gray  mx-auto py-10 space-y-10 md:space-y-0 space-x-4 px-8 mx-5 md:py-16">
        <div
          className="flex-shrink-0  aspect-w-1 aspect-h-1 w-full md:aspect-none md:w-96 md:h-96 mx-auto mb-6 md:mb-0 bg-no-repeat bg-cover bg-center"
          style={{ backgroundImage: `url("${imgsrc}")` }}
        ></div>
        <div className="md:w-1/2 flex flex-col justify-center md:align-start space-y-6">
          <PageTitle name={t('Enroll today')} color="blue" alignment="left" />
          {/* <PageBody
            name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet lectus sit amet ex finibus, at eleifend enim lobortis. Donec egestas elementum mauris sit amet varius. "
            color="blue"
            alignment="left"
          /> */}
          <div>
            <ButtonLink
              page="enroll"
              name={t('Enroll')}
              outline="true"
              color="red"
            />
          </div>
          <div className="flex flex-row align-start justify-start">
            <CardSubText
              name={t('Already a member?')}
              color="blue"
              alignment="left"
            />
            <AnchorButton
              to="login"
              name={t('Login here')}
              color="blue"
              alignment="left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Enroll;
