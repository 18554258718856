import Layout from '../../../components/common/Layout';
import Myclass from '../../../components/pages/student/myclasses/Myclass';

const MyclassContainer = () => {
  return (
    <Layout>
      <Myclass />
    </Layout>
  );
};

export default MyclassContainer;
