import Layout from '../../components/common/Layout';
import Communication from '../../components/pages/parent/communication/Communication';

const ParentCommunicationContainer = () => {
  return (
    <Layout>
      <Communication />
    </Layout>
  );
};

export default ParentCommunicationContainer;
