import { CgMoreVertical } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Actionlist from "../../../elements/table/actionlist";
import {
  school_dashboard_users_delete_start,
  school_dashboard_users_reset_otp_start,
} from "../../../../store/actions/school.dashboard.users";

const UsersActionlistRecent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.auth);

  return (
    <Actionlist
      onClick={props.onClick}
      showinglist={props.showinglist}
      k={props.k}
      list={[       
        {
          onClick: () =>
            dispatch(
              school_dashboard_users_reset_otp_start({
                token: userAuth.token,
                id: props.row_id,
                school_id: userAuth.userId,
              })
            ),
          classname:
            "flex justify-between items-center space-x-2 p-3 cursor-pointer",
          name: "Reset OTP",
        },
        // {
        //   onClick: () => {
        //     if (window.confirm("Are you sure you want to delete this user?")) {
        //       dispatch(
        //         school_dashboard_users_delete_start({
        //           token: userAuth.token,
        //           id: props.row_id,
        //           school_id: userAuth.userId,
        //         })
        //       )
        //     }
        //   },
        //   classname:
        //     "flex justify-between items-center space-x-2 p-3 cursor-pointer",
        //   name: "Remove",
        // },
      ]}
    />
  );
};

export default UsersActionlistRecent;
