import { Button } from '../../elements/button';
import { SectionTitle } from '../../elements/text';

const NotFound = (props) => {
  let title = `We found no ${props.feature} here`;
  if (props.isCreate) {
    title = `You haven't created any ${props.feature} yet`;
  }
  return (
    <div
      className={`m-auto mt-10 w-80% flex ${
        props.isCreate ? 'flex-col-reverse' : 'flex-col'
      } justify-around items-center text-center`}
    >
      <div>
        <SectionTitle name={title} color="blue" />
        {props.isCreate && (
          <Button
            name="+ Create"
            outline="true"
            color="blue"
            clicked={() => props.create()}
          />
        )}
      </div>
      {!props.hideImage && (
        <div className="w-64 h-64 mx-auto mt-10">
          <img
            className="w-64 h-auto object-cover object-center"
            src="/images/noclass.png"
            alt="img"
          />
        </div>
      )}
    </div>
  );
};

export default NotFound;
