import { CardTitle, CardSubText } from "../../text";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewAndReminder = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const instant_new_and_reminder = [];

    return instant_new_and_reminder.length > 0 ? (
      <div className="flex flex-row-reverse justify-between bg-white filter drop-shadow-md rounded-2xl w-full">
        <div className={`w-124px relative rounded-2xl`}>
          <img
            src={props.image}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          />
          <div className="absolute bottom-0 w-full text-center p-2">
            <button
              className="cursor-pointer px-2 py-1 transition ease-in duration-200 rounded-full text-center hover:text-white bg-white hover:bg-transparent text-blue hover:text-white border-2 border-white hover:border-white focus:outline-none text-xs font-bold"
              onClick={() => history.push(props.link)}
            >
              {t("Go for it!")}
            </button>
          </div>
        </div>
        <div className="p-6 flex flex-col justify-between">
          <div className="flex flex-col flex-wrap">
            <CardTitle name={props.title} color={props.titleColor} />
            <CardSubText name={props.body} color={props.bodyColor} />
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
};

export default NewAndReminder;
