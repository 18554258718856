import { useState, useEffect } from "react";
import { SectionTitle, CardBody, SmallText } from "../../../elements/text";
import Input from "../../../elements/input";
import { Button } from "../../../elements/button";
import { OtherLinkButton } from "../../../elements/button";
import { useHistory } from "react-router-dom";
import student from "../../../../images/signup.png";
import * as actions from "../../../../store/actions/";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "../../../common/messages";
import Spinner from "../../../elements/spinner";

const Recoverpasswordform = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [secondPhone, setSecondPhone] = useState("");
  const [isShowSecondPhone, setIsShowSecondPhone] = useState(false);
  const auth = useSelector((state) => state.auth.user);
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const submitpasswordrestrequest = (e) => {
    e.preventDefault();
    localStorage.setItem("secondPhone", secondPhone);
    localStorage.setItem("phone", phone);
    const obj = { email, phone, secondPhone };
    dispatch(actions.passwordResetRequest(obj));
  };

  useEffect(() => {
    if (auth !== null) {
      history.push("/forgot/verify");
    }
    //---
    window.scrollTo(0, 0);
  }, [auth]);

  return (
    <div>
      <div className="hidden md:block text-center w-30% m-auto">
        <SectionTitle name="Recover Account" color="blue" />
        <CardBody
          name="Enter the email or phone number associated with your account and we will send the password recovery instruction to you."
          color="blue"
        />
      </div>
      <div className="flex md:h-60vh justify-center items-center md:space-x-10 md:m-20 xl:m-40">
        <div className="md:w-50% md:h-full hidden md:block">
          <img
            className="w-full h-full object-cover rounded-3xl"
            src={student}
            alt=""
          />
        </div>
        {loading ? (
          <Spinner size="32" color="blue" />
        ) : (
          <form
            onSubmit={submitpasswordrestrequest}
            className="md:w-50% md:h-full md:flex flex-col justify-around bg-gray-200 rounded-3xl text-center m-4 md:m-auto p-4"
          >
            <div className="md:hidden">
              <SectionTitle name="Recover Account" color="blue" />
              <CardBody
                name="Enter the email or phone number associated with your account and we will send the password recovery instruction to you."
                color=""
              />
            </div>
            <div className="py-3">
              <Input
                elementType="input"
                elementConfig={{
                  type: "email",
                  placeholder: "Email (example@email.com)",
                }}
                value={email}
                changed={setEmail}
                validation={{ isEmail: true }}
                shouldValidate
                error="Valid email is required"
              />
              <Input elementType="phone" value={phone} changed={setPhone} />
            </div>
            <Message type={"error"} viewable={true} message={error} />
            <div className="flex items-center space-x-3">
              <input
                type="checkbox"
                value={isShowSecondPhone}
                onChange={(event) => setIsShowSecondPhone(event.target.checked)}
              />
              <CardBody
                name="I would like to receive the recovery code on an alternate number"
                color=""
              />
            </div>
            {isShowSecondPhone ? (
              <Input
                elementType="phone"
                value={secondPhone}
                changed={setSecondPhone}
              />
            ) : null}
            <div className="py-3">
              <Button
                type="submitbtn"
                name="Send"
                outline="true"
                color="blue"
              //  clicked={submitpasswordrestrequest}
              />
            </div>
            <div className="flex justify-center py-3">
              <SmallText
                name="This method not working for you?&nbsp;"
                color="black"
                alignment="center"
              />
              <OtherLinkButton
                color="blue"
                to="/forgot/question"
                name="Try another method."
                size="xs"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Recoverpasswordform;
