import { useState } from "react";
import PageContent from "../pageContent";
import AutoTestForm from "./autotestform";
import { Button, GoBackButton } from "../../../elements/button";
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from "../../../elements/text";

import celebration from "../../../../images/celebration.png";
import { useHistory } from "react-router-dom";
import { FeatureItemCard } from "../../../elements/card";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
const ProgressTable = (props) => {
  // const [showSuccessModal, setshowSuccessModal] = useState(false);
  const history = useHistory();
  const [showSelectBox, setShowSelectBox] = useState(false);

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full min-h-screen-sec`}>
      {!showSelectBox ? <AutoTestForm /> : ""}
        <div
          className={`${
            showSelectBox ? "hidden md:block" : ""
          } md:relative p-3 bg-white md:h-full h-screen-second mb-16 pb-16 md:max-h-screen-sec col-span-8 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <div className="relative overflow-x-auto sm:rounded-lg h-full">
            <div className="pb-4">
            <FeatureTitle name="Tests & Assessments" />
            <GoBackButton
              link="/student/features/testprogress/"
              action="Back"
              location="Progress: Unit2: Human skeleton"
            />
            </div>
            <SectionTitle name="Biology - Unit2: sdfsHuman skeleton" color="blue" />
            <CardBody name="Cillum pariatur cupidatat cupidatat culpa laboris aute aliqua." />
            <div className="w-full flex flex-col md:flex-row py-5">
              <div className="md:w-50% space-y-4">
                <div className="flex space-x-2 md:space-x-10">
                  <div className="w-28 h-28 my-3 mr-3 p-2 rounded-xl bg-yellow">
                    <img className="w-full h-full" src={celebration} alt="" />
                  </div>
                  <div>
                    <CardTitle name="Fantastic" color="blue" />
                    <CardBody name="You got 70 % on this test" />
                    <div className="py-3 flex space-x-2">
                      <Button
                        name="Redo"
                        outline="true"
                        color="blue"
                        clicked={() => {}}
                      />
                      {/* <Button
                        name="Share"
                        outline="true"
                        color="blue"
                        clicked={() => {}}
                      /> */}
                    </div>
                    <Button
                      name="Challenge Others"
                      outline="true"
                      color="blue"
                      clicked={() => {}}
                    />
                  </div>
                </div>
                <Button
                  name="Check answers"
                  outline="false"
                  color="red"
                  clicked={() => {}}
                />
              </div>
              <div className="md:w-50%">
                <CardTitle name="Other work" color="blue" />
                <CardBody name="Tests" color="blue" />
                <div className="md:grid grid-cols-2">
                  <FeatureItemCard
                    feature="test"
                    title="Test title"
                    level={6}
                    unit="Unit 1"
                    imgsrc="/images/preview/bio.svg"
                    type="Test"
                    due="Today"
                    isMyTest={false}
                    subject="Biology"
                    clicked={() => {
                      history.push(`/student/features/tests/${1}`); //here must a test id
                    }}
                  />
                  <FeatureItemCard
                    feature="test"
                    title="Test title"
                    level={6}
                    unit="Unit 1"
                    imgsrc="/images/preview/bio.svg"
                    type="Quiz"
                    due="Today"
                    isMyTest={false}
                    subject="Biology"
                    clicked={() => {
                      history.push(`/student/features/tests/${1}`); //here must a test id
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${
            showSelectBox ? "block" : ""
          } fixed w-full md:w-auto md:relative p-3 bg-gray-200 bottom-0 flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10">
            {showSelectBox ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden animate-pulse"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default ProgressTable;
