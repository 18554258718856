import axios from "../../axios-base";
import { getCachedData } from "../../shared/caching";
import { changeAskQuestionModalStatus, changeCommentModalStatus, setComment, setQuestion } from "./community";

export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_FAILED";

export function student_dashboard_homepage_get_n_community_posts_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_INIT
  };
}
export function student_dashboard_homepage_get_n_community_posts_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_n_community_posts_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_n_community_posts_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_n_community_posts_init());

    axios
      .post(
        `/opanda/community/get_n_community_posts`,
        {
          academic_level: data.academic_level,
          user_id: data.userId,
          limit: data.limit,
          lastId: data.lastId,
          tag: data.tag,
          group_id: data.group_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_failed(err.message));
      });
  };
}

export function student_dashboard_homepage_get_n_community_posts_new_post_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_n_community_posts_init());

    axios
      .post(
        `/opanda/community/get_n_community_posts_new_post`,
        {
          academic_level: data.academic_level,
          user_id: data.userId,
          limit: data.limit,
          lastId: data.lastId,
          tag: data.tag,
          post: data.post,
          group_id: data.group_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_success(response));
        dispatch(changeAskQuestionModalStatus(false))
        dispatch(setQuestion(""))

      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_get_n_community_posts_new_comment_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_n_community_posts_load_more_init());

    axios
      .post(
        `/opanda/community/get_n_community_posts_new_comment`,
        {
          academic_level: data.academic_level,
          user_id: data.userId,
          limit: data.limit,
          lastId: data.lastId,
          tag: data.tag,
          comment: data.comment,
          group_id: data.group_id,
          postid: data.postid
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_load_more_success(response));
        dispatch(changeCommentModalStatus(false))
        dispatch(setComment(""))

      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_load_more_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_get_n_community_posts_with_tag_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_n_community_posts_init());

    axios
      .post(
        `/opanda/community/get_n_community_posts_with_tag`,
        {
          academic_level: data.academic_level,
          user_id: data.userId,
          limit: data.limit,
          lastId: data.lastId,
          tag: data.tag,
          group_id: data.group_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_failed(err.message));
      });
  };
}
//---
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_FAILED";

export function student_dashboard_homepage_get_n_community_posts_load_more_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_INIT
  };
}
export function student_dashboard_homepage_get_n_community_posts_load_more_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_n_community_posts_load_more_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_n_community_posts_load_more_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_n_community_posts_load_more_init());

    axios
      .post(
        `/opanda/community/get_n_community_posts_load_more`,
        {
          academic_level: data.academic_level,
          user_id: data.userId,
          limit: data.limit,
          lastId: data.lastId,
          tag: data.tag,
          group_id: data.group_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_load_more_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_load_more_failed(err.message));
      });
  };
}
//---Comments Updates--
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_ADD_COMMENT_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_FAILED";

export function student_dashboard_homepage_get_n_community_posts_add_comment_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_INIT
  };
}
export function student_dashboard_homepage_get_n_community_posts_add_comment_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_n_community_posts_add_comment_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_n_community_posts_add_comment_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_n_community_posts_add_comment_init());

    axios
      .post(
        `/opanda/community/get_n_community_posts_add_comment`,
        {
          academic_level: data.academic_level,
          user_id: data.userId,
          comment: data.comment,
          postid: data.postid
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_add_comment_success(response));

      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_n_community_posts_add_comment_failed(err.message));
      });
  };
}
//---Create a new group
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_FAILED";

export function student_dashboard_homepage_get_n_community_create_a_group_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_INIT
  };
}
export function student_dashboard_homepage_get_n_community_create_a_group_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_n_community_create_a_group_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_n_community_create_a_group_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_n_community_create_a_group_init());

    axios
      .post(
        `/opanda/community/get_n_community_create_a_group`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level,
          groupName: data.groupName,
          groupDescription: data.groupDescription,
          groupVisibilityStatus: data.groupVisibilityStatus
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_n_community_create_a_group_success(response));

      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_n_community_create_a_group_failed(err.message));
      });
  };
}
 //--Discover groups 
 export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_FAILED";

export function student_dashboard_homepage_get_n_community_discover_groups_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_INIT
  };
}
export function student_dashboard_homepage_get_n_community_discover_groups_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_n_community_discover_groups_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_n_community_discover_groups_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_n_community_discover_groups_init());

    axios
      .post(
        `/opanda/community/get_n_community_discover_groups`,
        {
          user_id: data.userId,
          academic_level: data.academic_level,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_n_community_discover_groups_success(response));

      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_n_community_discover_groups_failed(err.message));
      });
  };
}
//--Request to join a group
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_INIT =
"STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_SUCCESS =
"STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_FAILED =
"STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_FAILED";

export function student_dashboard_homepage_get_n_community_join_a_group_init() {
return {
  type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_INIT
};
}
export function student_dashboard_homepage_get_n_community_join_a_group_success(data) {
return {
  type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_SUCCESS,
  payload: data
};
}
export function student_dashboard_homepage_get_n_community_join_a_group_failed(error) {
return {
  type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_FAILED,
  payload: error
};
}

export function student_dashboard_homepage_get_n_community_join_a_group_start(data) {
return (dispatch) => {
  dispatch(student_dashboard_homepage_get_n_community_join_a_group_init());

  axios
    .post(
      `/opanda/community/get_n_community_join_a_group`,
      {
        user_id: data.user_id,
        academic_level: data.academic_level,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${data.token}`
        }
      }
    )
    .then((response) => {
      dispatch(student_dashboard_homepage_get_n_community_join_a_group_success(response));

    })
    .catch((err) => {
      dispatch(student_dashboard_homepage_get_n_community_join_a_group_failed(err.message));
    });
};
}
//--See requests on my groups
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_INIT =
"STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_SUCCESS =
"STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_FAILED =
"STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_FAILED";

export function student_dashboard_homepage_get_n_community_see_requests_on_my_group_init() {
return {
  type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_INIT
};
}
export function student_dashboard_homepage_get_n_community_see_requests_on_my_group_success(data) {
return {
  type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_SUCCESS,
  payload: data
};
}
export function student_dashboard_homepage_get_n_community_see_requests_on_my_group_failed(error) {
return {
  type: STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_FAILED,
  payload: error
};
}

export function student_dashboard_homepage_get_n_community_see_requests_on_my_group_start(data) {
return (dispatch) => {
  dispatch(student_dashboard_homepage_get_n_community_see_requests_on_my_group_init());

  axios
    .post(
      `/opanda/community/get_n_community_see_requests_on_my_group`,
      {
        user_id: data.user_id,
        academic_level: data.academic_level,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${data.token}`
        }
      }
    )
    .then((response) => {
      dispatch(student_dashboard_homepage_get_n_community_see_requests_on_my_group_success(response));

    })
    .catch((err) => {
      dispatch(student_dashboard_homepage_get_n_community_see_requests_on_my_group_failed(err.message));
    });
};
}
//--Accept or deny invitation to group 
//--See members of a group
//--Remove a member from a group
//--Delete a group