import axios from "../../../axios-base";

export const CHANGE_CHOSEN_UNIT_SIMULATIONS = 'CHANGE_CHOSEN_UNIT_SIMULATIONS';


export const CHOOSE_SIMULATION_SUBJECT_INIT = 'CHOOSE_SIMULATION_SUBJECT_INIT'
export const CHOOSE_SIMULATION_SUBJECT_FAIL = 'CHOOSE_SIMULATION_SUBJECT_FAIL'
export const CHOOSE_SIMULATION_SUBJECT_SUCCESS = 'CHOOSE_SIMULATION_SUBJECT_SUCCESS'

export const CHOOSE_SIMULATION_UNITS_INIT = 'CHOOSE_SIMULATION_UNITS_INIT'
export const CHOOSE_SIMULATION_UNITS_FAIL = 'CHOOSE_SIMULATION_UNITS_FAIL'
export const CHOOSE_SIMULATION_UNITS_SUCCESS = 'CHOOSE_SIMULATION_UNITS_SUCCESS'


export const CHOOSE_SIMULATIONS_PER_UNIT_INIT = 'CHOOSE_SIMULATIONS_PER_UNIT_INIT'
export const CHOOSE_SIMULATIONS_PER_UNIT_FAIL = 'CHOOSE_SIMULATIONS_PER_UNIT_FAIL'
export const CHOOSE_SIMULATIONS_PER_UNIT_SUCCESS = 'CHOOSE_SIMULATIONS_PER_UNIT_SUCCESS'


export const CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_INIT = 'CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_INIT'
export const CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_FAIL = 'CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_FAIL'
export const CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_SUCCESS = 'CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_SUCCESS'


export const STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_INIT =
  "STUDENT_DASHBOARD_SELECTED_UNIT_WITH_LINKED_LABS_DOCS_VIDEOS_INIT";

export const changeChosenUnitSimulations = (unit) => {
  return {
    type: CHANGE_CHOSEN_UNIT_SIMULATIONS,
    payload: unit,
  };
};



export function choose_simulation_subject_init() {
  return {
    type: CHOOSE_SIMULATION_SUBJECT_INIT
  };
}
export function choose_simulation_subject_success(data) {


  return {
    type: CHOOSE_SIMULATION_SUBJECT_SUCCESS,
    payload: data
  };
}
export function choose_simulation_subject_fail(error) {
  return {
    type: CHOOSE_SIMULATION_SUBJECT_FAIL,
    payload: error
  };
}

export function choose_simulation_subject_start(Data) {
  return (dispatch) => {
    dispatch(choose_simulation_subject_init());

    axios
      .post(
        `/opanda/simulations/subjects/`,
        {
          Level: Data.academic_level
        },
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Authorization": `Bearer ${data.token}`
        //   }
        // }
      )
      .then((response) => {

        dispatch(choose_simulation_subject_success(response));
      })
      .catch((err) => {
        dispatch(choose_simulation_subject_fail(err.message));
      });
   
     

  };
}


export function choose_simulation_units_init() {
  return {
    type: CHOOSE_SIMULATION_UNITS_INIT
  };
}
export function choose_simulation_units_success(data) {


  return {
    type: CHOOSE_SIMULATION_UNITS_SUCCESS,
    payload: data
  };
}
export function choose_simulation_units_fail(error) {
  return {
    type: CHOOSE_SIMULATION_UNITS_FAIL,
    payload: error
  };
}

export function choose_simulation_units_start(Data) {
  return (dispatch) => {
    dispatch(choose_simulation_units_init());

    axios
      .post(
        `opanda/simulations/subjects/units/`,
        {
          Level: Data.academic_level,
          Subject: Data.subject
        },
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Authorization": `Bearer ${data.token}`
        //   }
        // }
      )
      .then((response) => {

        dispatch(choose_simulation_units_success(response));
      })
      .catch((err) => {
        dispatch(choose_simulation_units_fail(err.message));
      });
   
     

  };
}


export function choose_simulations_per_unit_init() {
  return {
    type: CHOOSE_SIMULATIONS_PER_UNIT_INIT
  };
}
export function choose_simulations_per_unit_success(data) {


  return {
    type: CHOOSE_SIMULATIONS_PER_UNIT_SUCCESS,
    payload: data
  };
}
export function choose_simulations_per_unit_fail(error) {
  return {
    type: CHOOSE_SIMULATIONS_PER_UNIT_FAIL,
    payload: error
  };
}

export function choose_simulations_per_unit_start(unitId) {
  return (dispatch) => {
    dispatch(choose_simulations_per_unit_init());

    axios
      .get(
      `opanda/simulations/unit/find/${unitId}`,
        // {
        //   id: unitId
        // },
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Authorization": `Bearer ${data.token}`
        //   }
        // }
      )
      .then((response) => {

        dispatch(choose_simulations_per_unit_success(response));
      })
      .catch((err) => {
        dispatch(choose_simulations_per_unit_fail(err.message));
      });

  };
}



export function choose_units_with_simulations_per_subject_init() {
  return {
    type: CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_INIT
  };
}
export function choose_units_with_simulations_per_subject_success(data) {


  return {
    type: CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_SUCCESS,
    payload: data
  };
}
export function choose_units_with_simulations_per_subject_fail(error) {
  return {
    type: CHOOSE_UNITS_WITH_SIMULATIONS_PER_SUBJECT_FAIL,
    payload: error
  };
}

export function choose_units_with_simulations_per_subject_start(Data) {
  return (dispatch) => {
    dispatch(choose_units_with_simulations_per_subject_init());

    axios
      .post(
        `opanda/simulations/subjects/units/simulations`,
        {
          Level: Data.academic_level,
          Subject: Data.subject
        },
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Authorization": `Bearer ${data.token}`
        //   }
        // }
      )
      .then((response) => {

        dispatch(choose_units_with_simulations_per_subject_success(response));
      })
      .catch((err) => {
        dispatch(choose_units_with_simulations_per_subject_fail(err.message));
      });


  };
}