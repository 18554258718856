import { useEffect, useState } from "react";
import Input from "../../../../../elements/input";
import { CardSubText, CardTitle } from "../../../../../elements/text";
import { Button } from "../../../../../elements/button";
import { completeSignup } from "../../../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../../elements/spinner";
import * as actions from "../../../../../../store/actions/";
import { Message } from "../../../../../common/messages";

const BaselineInformation = () => {
  const loading = useSelector((state) => state.completeSignup.loading);
  const userId = useSelector((state) => state.auth.userId);
  const profile = useSelector((state) => state.profile.profile);
  const [lastTermGrades, setLastTermGrades] = useState();
  const [digitalProficiency, setDigitalProficiency] = useState("");
  const [aggregate, setAggregate] = useState(
    profile?.baseline_info_nationalexamaggregate !== null
      ? profile?.baseline_info_nationalexamaggregate
      : ""
  );
  const [description, setDescription] = useState("");
  const [dataerror, setdataerror] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const submitbaselineinfo = () => {
    const userObject = {
      baseline_info_digitalprofiency: digitalProficiency,
      baseline_info_qualitativeassesmentquestion: "N/A",
      baseline_info_lasttermgrades: lastTermGrades,
      baseline_info_nationalexamaggregate: aggregate,
      userId,
    };
    if (
      userObject?.baseline_info_digitalprofiency !== "" &&
      userObject?.baseline_info_qualitativeassesmentquestion !== "" &&
      userObject?.baseline_info_lasttermgrades !== "" &&
      userObject?.baseline_info_nationalexamaggregate !== "" &&
      userObject?.userId !== ""
    ) {
      dispatch(actions.completeprofileinformation(userObject));
      dispatch(completeSignup("Baseline Information"));
    } else {
      setIsSubmitted(true);
      setdataerror("Some data are missing");
    }
  };

  useEffect(() => {
    dispatch(completeSignup("Baseline Information"));
  }, []);

  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div style={{height:"100vh"}} className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Baseline Information" color="black" />
          <div className="md:grid grid-cols-3 gap-2">
            <Input
              label="Grades of the last term"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "A", displayValue: "A" },
                  { value: "B", displayValue: "B" },
                  { value: "C", displayValue: "C" },
                  { value: "D", displayValue: "D" },
                  { value: "E", displayValue: "E" },
                  { value: "F", displayValue: "F" },
                ],
              }}
              value={lastTermGrades}
              changed={setLastTermGrades}
              validation={{ required: true }}
              shouldValidate
              error="Last term grades are required"
            />
            <Input
              label="Digital Proficiency"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "Excellent", displayValue: "Excellent" },
                  { value: "Good", displayValue: "Good" },
                  { value: "Average", displayValue: "Average" },
                  { value: "Bad", displayValue: "Bad" },
                  { value: "Worst", displayValue: "Worst" },
                ],
              }}
              value={digitalProficiency}
              changed={setDigitalProficiency}
              validation={{ required: true }}
              shouldValidate
              error="Digital proficiency is required"
            /> 
            {/* <Input
              label="Aggregate in last National Exams"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Aggregate",
              }}
              value={aggregate}
              changed={setAggregate}
              validation={{ required: true }}
              shouldValidate
              error="Aggregate is required"
            /> */}
          </div>
          {/* <CardSubText name="A way to assess" color="black" />
          <ul className="py-3">
            <li>
              <CardSubText name="Critical thinking" color="black" />
            </li>
            <li>
              <CardSubText name="Analytical thinking" color="black" />
            </li>
            <li>
              <CardSubText name="Problem Solving" color="black" />
            </li>
            <li>
              <CardSubText name="Creative thinking" color="black" />
            </li>
            <li>
              <CardSubText name="Behavior change/attitude" color="black" />
            </li>
            <li>
              <CardSubText name="Confidence" color="black" />
            </li>
            <li>
              <CardSubText
                name="Teacher, Parents, students engagement"
                color="black"
              />
            </li>
            <li>
              <CardSubText
                name="Questions that assesses qualitative traits"
                color="black"
              />
            </li>
          </ul> */}
          {/* <Input
            elementType="textarea"
            elementConfig={{
              placeholder: "Write",
              rows: 3,
            }}
            value={description}
            changed={setDescription}
            validation={{ required: true }}
            shouldValidate
            error="Description is required"
          /> */}
          <Message type={"error"} viewable={true} message={dataerror} />
          <div className="flex space-x-2 justify-center py-5">
            {/* <Button name="Previous" outline="true" color="blue" /> */}
            <Button
              name="Next"
              outline="true"
              color="blue"
              clicked={submitbaselineinfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BaselineInformation;
