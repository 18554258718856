import { TabPanel, useTabs } from 'react-headless-tabs';
import TabSelector from './TabSelector';
import { useSelector } from 'react-redux';
import MainNotificationCard from './MainNotificationCard';
import { Button } from '../../button';
import { useEffect, useState } from "react";
import io from 'socket.io-client';
import dotenv from "dotenv";
import { useDispatch } from 'react-redux';
import { student_dashboard_notification_count_start } from '../../../../store/actions/notificationsCountUpdates';
const fs = require('fs').promises;
dotenv.config();
// Read .env file
// Load environment variables
let envData = {};
let envConfig = {};
(async function () {
  // envData = await fs.readFile("../../../../../.env");
  // envConfig = dotenv.parse(envData);
  // //--
  // for (const key in envConfig) {
  //   process.env[key] = envConfig[key];
  // }

})();

const MainNotification = () => {
  const [selectedTab, setSelectedTab] = useTabs(
    ['primary', 'myactions', 'others'],
    'primary'
  );
  const notifications__ = useSelector(
    (state) => state.notifications.mainNotifications
  );
  const { id, user_type } = useSelector((state) => state.auth.user);
  const { token } = useSelector((state) => state.auth);
  const [showScroll, setShowScroll] = useState(false);
  const [notificationsInTabsCount, setNotificationsInTabsCount] = useState([null, null, null]);
  const [notifications, setNotifications] = useState([[], [], []]);
  const [MarkedNotifications, setMarkedNotifications] = useState([]);
  const dispatch = useDispatch();


  const getNotificationsData = (data) => {
    const arr1 = [];
    const arr2 = [];
    const arr3 = [];
    if (data?.message?.data?.length > 0) {
      for (var i = 0; i < data?.message?.data[0]?.length; i++) {
        arr1.push({
          title: data?.message?.data[0][i].type,
          body: data?.message?.data[0][i].notif_description,
          creator: data?.message?.data[0][i].names,
          createdAt: data?.message?.data[0][i].date_created,
          cont_id: data?.message?.data[0][i].cont_id,
          id: data?.message?.data[0][i].id,
          action: 'Open',
          viewed: false,
          type: 'primary',
        });
      }
      for (var i = 0; i < data?.message?.data[1]?.length; i++) {
        arr2.push({
          title: data?.message?.data[1][i].type,
          body: data?.message?.data[1][i].notif_description,
          creator: data?.message?.data[1][i].names,
          createdAt: data?.message?.data[1][i].date_created,
          cont_id: data?.message?.data[1][i].cont_id,
          id: data?.message?.data[1][i].id,
          action: 'View Answer',
          viewed: false,
          type: 'myactions',
        });
      }
      for (var i = 0; i < data?.message?.data[2]?.length; i++) {
        arr3.push({
          title: data?.message?.data[2][i].type,
          body: data?.message?.data[2][i].notif_description,
          creator: data?.message?.data[2][i].names,
          createdAt: data?.message?.data[2][i].date_created,
          cont_id: data?.message?.data[2][i].cont_id,
          id: data?.message?.data[2][i].id,
          action: 'View Answer',
          viewed: false,
          type: 'others',
        });
      }
    }

    return [...arr1, ...arr2, ...arr3]

  }
  //--
  let ido = "";
  useEffect(() => {
    //---
    const socket = io(`${process.env.REACT_APP_SOCKETIO_URL}`);
    //--Socketio
    socket.on('connect', () => {
      //console.log('Connected to server');
      socket.emit('startConversion', {
        message: ido
      });
    });
    socket.on('disconnect', () => {
      //console.log('Disconnected from server');
    });
    socket.on('conversionStarted', (data) => {
      //console.log('Converse', data);
      //--collecting the user id on the network--socket.emit('chat message', 'Hello, server!');
      ido = data.message;
    });
    socket.on('notifyChannel', (data) => {
      //console.log('Transcribe received1:', data);
      //localStorage.setItem('notificationcount', data?.message?.data);
    });
    socket.on('notifyDataChannel', (data) => {
      //console.log('Transcribe received2:', data);
      //console.log(data?.message?.data[0].length != notificationsInTabsCount[0] || data?.message?.data[1].length != notificationsInTabsCount[1] || data?.message?.data[2].length != notificationsInTabsCount[2])
      try {
        if (data?.message?.data[0]?.length != notificationsInTabsCount[0] || data?.message?.data[1].length != notificationsInTabsCount[1] || data?.message?.data[2].length != notificationsInTabsCount[2]) {
          setNotificationsInTabsCount([data?.message?.data[0].length, data?.message?.data[1].length, data?.message?.data[2].length])
        } else {

        }
        if (
          data?.message?.data?.length !== notifications.length ||
          data?.message?.data.some((item, index) => item.join(', ') !== notifications[index]?.join(',')) ||
          MarkedNotifications.length !== 0
        ) {
          setNotifications(getNotificationsData(data));
          setMarkedNotifications([]);
        }

        localStorage.setItem('notificationcount', (data?.message?.data[0].length + data?.message?.data[1].length + data?.message?.data[2].length) === 0 ? "" : (data?.message?.data[0].length + data?.message?.data[1].length + data?.message?.data[2].length));
        dispatch(student_dashboard_notification_count_start((data?.message?.data[0].length + data?.message?.data[1].length + data?.message?.data[2].length) === 0 ? "" : (data?.message?.data[0].length + data?.message?.data[1].length + data?.message?.data[2].length)));
        //console.log('Transcribe received2:', data);
      } catch (err) {

      }
    });
    const intervalId = setInterval(() => {
      socket.emit('userSendToNotifyChannel', {
        message: { ido: ido, id: id, user_type: user_type, token: token }
      });
      //---
      socket.emit('userSendToNotifyDataChannel', {
        message: { ido: ido, id: id, user_type: user_type, token: token }
      });
    }, 20000);
    return () => { clearInterval(intervalId); socket.disconnect(); }
  }, [notifications]);

  const getMarkedNotifications = (id) => {
    setMarkedNotifications([id, ...MarkedNotifications]);
  }



  return (
    <div className="rounded">
      <nav className="flex justify-between md:justify-start border-b border-gray-500">
        <TabSelector
          isActive={selectedTab === 'primary'}
          title="Primary"
          new={(notificationsInTabsCount[0]) ? notificationsInTabsCount[0] : ""}
          onClick={() => setSelectedTab('primary')}
        />
        <TabSelector
          isActive={selectedTab === 'myactions'}
          title="My Actions"
          new={(notificationsInTabsCount[1]) ? notificationsInTabsCount[1] : ""}
          onClick={() => setSelectedTab('myactions')}
        />
        <TabSelector
          isActive={selectedTab === 'others'}
          title="Others"
          new={(notificationsInTabsCount[2]) ? notificationsInTabsCount[2] : ""}
          onClick={() => setSelectedTab('others')}
        />
      </nav>
      <div className="py-3 max-h-50vh md:max-h-70vh overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <TabPanel hidden={selectedTab !== 'primary'}>
          {notifications
            .filter((notification) => notification.type === 'primary')
            .map((notification, index) => (
              <MainNotificationCard getMarkedNotifications={getMarkedNotifications} MarkedNotifications={MarkedNotifications} key={index} {...notification} />
            ))}
        </TabPanel>
        <TabPanel hidden={selectedTab !== 'myactions'}>
          {notifications
            .filter((notification) => notification.type === 'myactions')
            .map((notification, index) => (
              <MainNotificationCard getMarkedNotifications={getMarkedNotifications} MarkedNotifications={MarkedNotifications} key={index} {...notification} />
            ))}
        </TabPanel>
        <TabPanel hidden={selectedTab !== 'others'}>
          {notifications
            .filter((notification) => notification.type === 'others')
            .map((notification, index) => (
              <MainNotificationCard getMarkedNotifications={getMarkedNotifications} MarkedNotifications={MarkedNotifications} key={index} {...notification} />
            ))}
        </TabPanel>
      </div>
      {/*<div className="text-center">
        <Button
          name="View More"
          outline="true"
          color="blue"
          clicked={() => { }}
        />
            </div>*/}
    </div>
  );
};

export default MainNotification;
