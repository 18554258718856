// import Footer from "../components/common/footer";
import Footer from '../components/common/footer';
import Header from '../components/common/header/header';
import ScrollToTop from '../components/common/ScrollToTop';
import NotificationBar from '../components/pages/landing/preview/notificationbar';
import PageContent from '../components/pages/landing/preview/pagecontent';
export const Previewpanda = (props) => {
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
     <Header />
      <NotificationBar />
       <PageContent />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
