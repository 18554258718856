import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import MatchingTypeAutoQuestionDropTargetItem from './MatchingTypeAutoQuestionDropTargetItem';
import { stringSimilarity } from "string-similarity-js";
//--stringSimilarity(realAnswer, answerPool[i].answer) >= 0.85---item
const MatchingTypeAutoQuestionDropTarget = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false);
    const [mappedAnswers, setMappedAnswers] = useState([]);
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "autoDragAndDrop",
        item: { id: props.name },
        drop: (item) => dropSelectedWord(item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));
    useEffect(() => {

    }, []);
    //console.log("answers_pool", props.answers_pool);
    const dropSelectedWord = (item) => {
        props.callMatchingTypeAuto(item.id, props.currentQuestionNumber, props.questionId, props.i, props.metaClean);
        props.reloadAfterDone(item);
        setReload(!reload);
    }
    const findAnswerInArray = (answerByColumn, answer) => {
        for (var i = 0; i < answerByColumn.length; i++) {
            const answerNew = ((answer.trim()).toLowerCase()).replace(/ /g, '')
            const answerPoolItem = ((answerByColumn[i].trim()).toLowerCase()).replace(/ /g, '')
            if ((stringSimilarity(answerNew, answerPoolItem) >= 0.85)) {
                return true;
            }

        }
        return false;

    }
    //----Keep record of all printed user answers--    
    const renderUserAnswer = (answerPool, column, questionId, answerByColumn) => {
        const filterDisplayOfAnswers = [];
        for (var i = 0; i < answerPool.length; i++) {
            const item_data = ((answerPool[i].column).replace(/ /g, '')).toLowerCase();
            if ((stringSimilarity((column.replace(/ /g, '')).toLowerCase(), item_data) >= 0.85) && parseInt(questionId) === parseInt(answerPool[i].questionId)) {
                filterDisplayOfAnswers.push([answerPool[i].word, findAnswerInArray(answerByColumn, answerPool[i].word)]);
                //return [answerPool[i].word, findAnswerInArray(answerByColumn, answerPool[i].word)];
            }
        }
        return filterDisplayOfAnswers;

    };

    
    //console.log('user_data', props.user_data);
    const geTMappedWords = (answers_pool) => {
        //--Get All Answers for this column--item is column
        const answersMap = answers_pool[0].split("#.#.#");
        const answerByColumn = [];
        for (var i = 1; i < answersMap?.length; i++) {
            const answerData = answersMap[i].trim();
            //console.log("answersMap", answerData);
            const columnData = (((answerData.split("#A.N.S")[1]).trim()).toLowerCase()).replace(/ /g, '');
            const answer1 = answerData.split("#A.N.S")[0];
            //-----Check if answer belongs to selected column-
            const item_data = (((props.item).trim()).toLowerCase()).replace(/ /g, '');
            if (stringSimilarity(columnData, item_data) >= 0.85) {
                answerByColumn.push(answer1);
            }

        }
        return <span>{answerByColumn?.map((item, i) => {
            return <MatchingTypeAutoQuestionDropTargetItem  key={i} data={`${item}`} data_state={true} by_user={false} />
        })}{
                renderUserAnswer(props.user_data, props.item, props.questionId, answerByColumn).map((item, i) => {
                    return <MatchingTypeAutoQuestionDropTargetItem key={i} data={`${item[0]}`} data_state={item[1]} by_user={true}  />
                })
            }</span>
    }
    return (
        <div ref={drop} className='h-40 p-1 border-2 border-orange-600 overflow-auto'>{geTMappedWords(props.answers_pool)}</div>
    );

};
export default MatchingTypeAutoQuestionDropTarget;
