import Layout from '../../components/common/Layout';
import NewRecipient from '../../components/pages/parent/communication/NewRecipient';

const ParentNewRecipientContainer = () => {
  return (
    <Layout>
      <NewRecipient />
    </Layout>
  );
};

export default ParentNewRecipientContainer;
