import Layout from '../../../components/common/Layout';
import Myclasses from '../../../components/pages/student/myclasses/Myclasses';

const MyclassesContainer = () => {
  return (
    <Layout>
      <Myclasses />
    </Layout>
  );
};

export default MyclassesContainer;
