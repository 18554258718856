import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getTestAIAnswersStart = () => {
  return {
    type: actionTypes.GET_TEST_AI_USER_ANSWERS_START,
  };
};

export const getTestAIAnswersSuccess = (testaianswers) => {
  return {
    type: actionTypes.GET_TEST_AI_USER_ANSWERS_SUCCESS,
    testaianswers: testaianswers,
  };
};

export const getOneTestAIAnswersSuccess = (aianswer) => {
  return {
    type: actionTypes.GET_ONE_TEST_AI_USER_ANSWERS_SUCCESS,
    aianswer: aianswer,
  };
};

export const getTestAIAnswersFail = (error) => {
  return {
    type: actionTypes.GET_TEST_AI_USER_ANSWERS_FAIL,
    error: error,
  };
};

export const getTestAIAnswers = () => {
  return (dispatch) => {
    dispatch(getTestAIAnswersStart());
    axios
      .get(`/opanda/panda-test-ai-users-answers`)
      .then((response) => {
        dispatch(getTestAIAnswersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestAIAnswersFail(err.message));
      });
  };
};

export const submitTestAIUsersAnswers = (object) => {
  return (dispatch) => {
    dispatch(getTestAIAnswersStart());
    axios
      .post(
        `/opanda/panda-test-ai-users-answers/register-questions`,
        object
      )
      .then((response) => {
        dispatch(getTestAIAnswersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestAIAnswersFail(err.message));
      });
  };
};
