import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { OtherLinkButton } from "../../../elements/button";
import { ClassDataCard } from "../../../elements/card";
import {
  CardBody,
  CardTitle,
  PageBody,
  SectionTitle,
  SmallText,
} from "../../../elements/text";

const ClassSummary = (props) => {
  return (
    <div
      className={`${
        props.show ? `block` : `hidden`
      } pt-2 px-2 space-x-2 w-full flex flex-row items-stretch space-around`}
    >
      {props.data.map((value, index) => (
        <ClassDataCard number={value.number} label={value.key} key={index} />
      ))}
    </div>
  );
};

export default ClassSummary;

export const ActivityChart = function (props) {
  return (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <div className="flex justify-between">
        <CardTitle name={props.title} color={props.titleColor} />
        <CardBody name={props.time} />
      </div>
      <div className="flex justify-between items-center">
        <div>
          {props.activities.map((activity, index) => (
            <div key={index} className="flex items-center space-x-2">
              <div
                className={`h-5 w-5 rounded-full bg-${activity.color}`}
              ></div>
              <CardBody name={`${activity.value} ${activity.name}`} />
            </div>
          ))}
        </div>
        <PieChart width={100} height={100}>
          <Pie
            data={props.activities}
            cx={45}
            cy={45}
            innerRadius={30}
            outerRadius={50}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
          >
            {props.activities.map((activity, index) => (
              <Cell key={`cell-${index}`} fill={activity.hexColor} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export const AssessmentSummary = function (props) {
  return (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <div className="flex justify-between">
        <CardTitle name={props.title} color={props.titleColor} />
        <CardBody name={props.time} />
      </div>
      <div className="flex flex-col items-start w-full divide-y space-y-2">
        {props.assessments.map((assessment, index) => (
          <div
            key={index}
            className="w-full pt-2 flex flex-row items-start justify-between"
          >
            <div className="rounded-full bg-white">
              <CardBody name={`${assessment.subject} - ${assessment.name}`} />
              <SmallText name={`${assessment.submissions} submissions`} />
            </div>
            <div className="px-4 border-2 rounded border-red">
              <SmallText name={assessment.duedate} />
            </div>
          </div>
        ))}
      </div>

      <OtherLinkButton color="blue" to="/tests" name={"More"} />
    </div>
  );
};

export const GoalSummary = function (props) {
  return (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <div className="flex justify-between">
        <CardTitle name={props.title} color={props.titleColor} />
        <CardBody name={props.time} />
      </div>
      <div className="flex flex-col items-start w-full divide-y space-y-2">
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={props.goaldata}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="uv" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export const StudentSession = function (props) {
  const data = props.data;
  return (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <div className="flex justify-between">
        <CardTitle name={props.title} color={props.titleColor} />
        <CardBody name={props.time} />
      </div>
      <div className="flex flex-col items-start w-full divide-y space-y-2">
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            width={600}
            height={300}
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line type="monotone" dataKey="uv" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
