import { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import Input from '../../../elements/input';
import PageContent from '../pageContent';
import { useTabs } from 'react-headless-tabs';
import { CardBody, FeatureTitle } from '../../../elements/text';
import { TabPanel } from 'react-headless-tabs';
import GetStarted from '../../../common/features/GetStarted';
import NotFound from '../../../common/features/NotFound';
import { FeatureItemCard } from '../../../elements/card';
import { useHistory } from 'react-router-dom';
import TabSelector from '../../../elements/tab/TabSelector';
import { GrClose } from 'react-icons/gr';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { student_dashboard_homepage_get_books_start } from '../../../../store/actions/student.dashboard.library';
import { bring_subject_image_src } from '../../../../functions/programs';
import { crypt } from '../../../../functions/cyphers';
import Spinner from '../../../elements/spinner';
const Library = () => {
  const history = useHistory();
  const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState('S1');
  const [searchterm, setSearchterm] = useState('');
  const userAuth = useSelector((state) => state.auth);
  const studentDashboardLibrary = useSelector((state) => state.studentDashboardLibrary);



  const dispatch = useDispatch();
  useEffect(() => {
    setLevel(`S${userAuth.academic_level}`)
    //-
    dispatch(
      student_dashboard_homepage_get_books_start({
        token: userAuth.token,
        user_id: userAuth.userId,
        academic_level: userAuth.academic_level
      })
    );
  }, []);
  const getSubjects = (pool) => {
    if (pool?.data?.results) {
      const clean_pool = pool?.data?.results;
      for (var i = 0; i < clean_pool?.length; i++) {
        const image = bring_subject_image_src(clean_pool[i].name);
        clean_pool[i].imgsrc = (image !== undefined) ? image?.imgsrc : "/images/preview/book.png"
        //-----
        for (var n = 0; n < clean_pool[i]?.books?.length; n++) {
          clean_pool[i].books[n].imgsrc = clean_pool[i].imgsrc;
        }
      }
      return clean_pool;
    } else {
      return [];
    }
  }

  const subjects = [
    {
      name: 'Biology',
      books: [
        {
          title: 'Book Title',
          imgsrc: '/images/preview/bio.svg',
          author: 'Cedric',
          type: 'opanda',
          bookType: "doc or text"
        },
        {
          title: 'Book Title',
          imgsrc: '/images/preview/math.svg',
          author: 'Paccy',
          type: 'opanda',
          bookType: "doc or text"
        },
        {
          title: 'Book Title',
          imgsrc: '/images/preview/bio.svg',
          author: 'Cedric',
          type: 'opanda',
          bookType: "doc or text"
        },
      ],
    },
    {
      name: 'Math',
      books: [
        {
          title: 'Book Title',
          imgsrc: '/images/preview/bio.svg',
          author: 'Cedric',
          type: 'opanda',
          bookType: "doc or text"
        },
        {
          title: 'Book Title',
          imgsrc: '/images/preview/bio.svg',
          author: 'Paccy',
          type: 'community',
          bookType: "doc or text"
        },
        {
          title: 'Book Title',
          imgsrc: '/images/preview/math.svg',
          author: 'Cedric',
          type: 'community',
          bookType: "doc or text"
        },
        {
          title: 'Book Title',
          imgsrc: '/images/preview/bio.svg',
          author: 'Cedric',
          type: 'community',
          bookType: "doc or text"
        },
      ],
    },
    {
      name: 'History',
      books: [
        {
          title: 'Book Title',
          imgsrc: '/images/preview/bio.svg',
          author: 'Cedric',
          type: 'opanda',
          bookType: "doc or text"
        },
        {
          title: 'Book Title',
          imgsrc: '/images/preview/bio.svg',
          author: 'Cedric',
          type: 'opanda',
          bookType: "doc or text"
        },
        {
          title: 'Book Title',
          imgsrc: '/images/preview/math.svg',
          author: 'Paccy',
          type: 'opanda',
          bookType: "doc or text"
        },
        {
          title: 'Book Title',
          imgsrc: '/images/preview/bio.svg',
          author: 'Cedric',
          type: 'opanda',
          bookType: "doc or text"
        },
      ],
    },
    {
      name: 'Geography',
      books: [],
    },
    {
      name: 'Physics',
      books: [],
    },
    {
      name: 'English',
      books: [],
    },
  ];

  const tabs = getSubjects(studentDashboardLibrary.student_dashboard_homepage_get_books).map((subject) => subject.name);
  tabs.unshift('initial page');
  const [selectedSubject, setSelectedSubject] = useTabs(tabs, 'initial page');

  const [selectedTab, setSelectedTab] = useTabs(
    ['All', "O'Genius Panda", 'Community'],
    "All"
  );

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full md:h-full min-h-screen-sec`}>
        <div
          className={`p-3 bg-gray-200 ${showSubjects ? 'h-screen-sec' : ''
            } md:h-full col-span-3`}
        >
          <div className="flex justify-between items-center">
            <Input
              elementType="select"
              elementConfig={{
                startingValue: 'SELECT',
                options: [...[userAuth.academic_level].map((item, index) => { return { value: 'S' + item, displayValue: 'Senior ' + item } })
                  // { value: 'S1', displayValue: 'Senior 1' },
                  // { value: 'S2', displayValue: 'Senior 2' },
                  // { value: 'S3', displayValue: 'Senior 3' },
                  // { value: 'S4', displayValue: 'Senior 4' },
                  // { value: 'S5', displayValue: 'Senior 5' },
                  // { value: 'S6', displayValue: 'Senior 6' },
                ],
              }}
              value={level}
              changed={setLevel}
              validation={{ required: true }}
              shouldValidate
              error="Level is required"
            />

          </div>
          <div className={`${showSubjects ? '' : 'hidden'} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Search...',
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            {(getSubjects(studentDashboardLibrary.student_dashboard_homepage_get_books).length > 0) ? <ul className="py-5 p-5  h-90 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
              {getSubjects(studentDashboardLibrary.student_dashboard_homepage_get_books)?.filter((subject, index) => (subject.name).toLowerCase().includes(searchterm.toLowerCase()) || searchterm === "")?.sort((a, b) => {
                // Assuming 'name' is a string property of each subject
                // and you want to sort the subjects alphabetically by their names
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
                .map((subject, index) => (
                <li key={index}>
                  <div
                    className="py-2 cursor-pointer"
                    onClick={() => {
                      setSelectedSubject(subject.name);
                      setShowSubjects(false);
                    }}
                  >
                    <CardBody
                      name={subject.name}
                      color="blue"
                      additional={
                        subject.name === selectedSubject ? 'font-bold' : ''
                      }
                    />
                  </div>
                </li>
              ))}
            </ul> : <Spinner size="20" color="blue" />}
          </div>
        </div>
        <div
          className={`${showSubjects ? 'hidden md:block' : ''
            } md:relative p-3 bg-white md:h-full h-screen-second mb-16 pb-16 md:max-h-screen-sec col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <TabPanel
            hidden={selectedSubject !== 'initial page'}
            className="transform transition duration-500 ease-in-out"
          >
            <FeatureTitle name="Library" />
            <>
              <nav className="flex justify-between md:justify-start space-x-4">
                <TabSelector
                  isActive={selectedTab === 'All'}
                  title="All"
                  onClick={() => setSelectedTab('All')}
                />
                <TabSelector
                  isActive={selectedTab === "O'Genius Panda"}
                  title="O'Genius Panda"
                  onClick={() => setSelectedTab("O'Genius Panda")}
                />
                <TabSelector
                  isActive={selectedTab === 'Community'}
                  title="Community"
                  onClick={() => setSelectedTab('Community')}
                />
              </nav>
              <TabPanel hidden={false}>
                <GetStarted type="a subject" feature="library" />
              </TabPanel>
            </>
          </TabPanel>
          {getSubjects(studentDashboardLibrary.student_dashboard_homepage_get_books).map((subject, index) => (
            <TabPanel
              key={index}
              hidden={selectedSubject !== subject.name}
              className="transform transition duration-500 ease-in-out"
            >
              <FeatureTitle name="Library" />
              <>
                <nav className="flex justify-between md:justify-start space-x-4">
                  <TabSelector
                    isActive={selectedTab === 'All'}
                    title="All"
                    onClick={() => setSelectedTab('All')}
                  />
                  <TabSelector
                    isActive={selectedTab === "O'Genius Panda"}
                    title="O'Genius Panda"
                    onClick={() => setSelectedTab("O'Genius Panda")}
                  />
                  <TabSelector
                    isActive={selectedTab === 'Community'}
                    title="Community"
                    onClick={() => setSelectedTab('Community')}
                  />
                </nav>
                <TabPanel hidden={selectedTab !== 'All'}>
                  {subject && (!subject.books || subject.books.length === 0) ? (
                    <NotFound feature="book" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {subject.books.map((book, index) => (
                        <FeatureItemCard
                          key={index}
                          {...book}
                          feature="library"
                          clicked={() =>
                            history.push(
                              `/student/features/library/${book.id}?bookType=${book.bookType}&dataUrl=${crypt(
                                "O'Genius Panda Library",
                                book.mediasrc
                              )}`
                            )
                          }
                        />
                      ))}
                    </div>
                  )}
                </TabPanel>
                <TabPanel hidden={selectedTab !== "O'Genius Panda"}>
                  {subject && subject.books && subject.books.filter((book) => book.type === 'opanda')
                    .length === 0 ? (
                    <NotFound feature="book" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {subject && subject.books && subject.books
                        .filter((book) => book.type === 'opanda')
                        .map((book, index) => (
                          <FeatureItemCard
                            key={index}
                            {...book}
                            feature="library"
                            clicked={() =>
                              history.push(
                                `/student/features/library/${book.id}?bookType=${book.bookType}&dataUrl=${crypt(
                                  "O'Genius Panda Library",
                                  book.mediasrc
                                )}`
                              )
                            }
                          />
                        ))}
                    </div>
                  )}
                </TabPanel>
                <TabPanel hidden={selectedTab !== 'Community'}>
                  {subject && subject.books && subject.books.filter((book) => book.type === 'community')
                    .length === 0 ? (
                    <NotFound feature="book" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {subject && subject.books && subject.books
                        .filter((book) => book.type === 'community')
                        .map((book, index) => (
                          <FeatureItemCard
                            key={index}
                            {...book}
                            feature="library"
                            clicked={() =>
                              history.push(
                                `/student/features/library/${book.id}?bookType=${book.bookType}&dataUrl=${crypt(
                                  "O'Genius Panda Library",
                                  book.mediasrc
                                )}`
                              )
                            }
                          />
                        ))}
                    </div>
                  )}
                </TabPanel>
              </>
            </TabPanel>
          ))}
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${showSubjects ? "block" : ""
            } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSubjects ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Library;
