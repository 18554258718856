import React, { useEffect, useState } from 'react';
import Input from '../../../elements/input';
import { Button } from '../../../elements/button';
import { useSelector } from "react-redux";
import axios from "../../../../axios-base";
import Spinner from '../../../elements/spinner';

const SimulationPreviewComponent = (props) => {
    const [simulationData, setSimulationData] = useState('');
    const [simulations, setSimulations] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isSearchingSaveSimulation, setIsSearchingSaveSimulation] = useState(false);
    const userAuth = useSelector((state) => state.auth);
    const token = userAuth.token;

    const searchPdf = async () => {
        if (simulationData === '') {
            setSearchResults([]);
            return;
        }
        setIsSearching(true);
        axios
            .post(
                `/opanda/summarynotes/cbcsummarynotesfindSimulationsInTheLibrary`,
                {
                    search_query: simulationData,
                    subject: props.subject
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                setSearchResults(response.data.simulations)
                setIsSearching(false);
            })
            .catch((err) => {
                console.log(err);
                setIsSearching(false);
            });


    };

    const addSimulation = () => {
        if (searchResults.length > 0) {
            setSimulations((simulation_) => {
                const updatedSimulations = [...simulation_];
                updatedSimulations.push(searchResults[0]); // Add the first result
                return [...updatedSimulations];
            });

            setSearchResults([]); // Clear search results
            setSimulationData('');
        } else {
            setError('No search results to add.');
        }
    };

    const removeSimulation = (index) => {
        const updatedSimulations = [...simulations];
        updatedSimulations.splice(index, 1);
        setSimulations(updatedSimulations);
    };

    useEffect(() => {
        axios
            .post(
                `/opanda/summarynotes/cbcsummarynotesfindMySimulationsOncourse`,
                {
                    classId: props.classId
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                const updatedSimulations = [];
                response.data.simulations.forEach((result) => {
                    updatedSimulations.push({ id: result.id, name: result.name });
                    setSimulations(updatedSimulations)
                })
               
                setSimulations(updatedSimulations);
                setIsSearchingSaveSimulation(false);
                setSearchResults([]);
                setSimulationData('');
            }).catch((err) => {
                setIsSearchingSaveSimulation(false);
                setSearchResults([]);
                setSimulationData('');
            });

    }, []);

    useEffect(() => {
        // Your effect code here
    }, [simulations]);

    const saveSimulations = () => {
        if (simulations.length === 0) {
            alert('Please add at least one document.');
        }
        const ids = simulations.map(item => item.id).join(',');
        setIsSearchingSaveSimulation(true);
        axios
            .post(
                `/opanda/summarynotes/cbcsummarynotesaddSimulations`,
                {
                    simulationIds: ids,
                    classId: props.classId
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            .then((response) => {

                // Refresh my simulations
                axios
                    .post(
                        `/opanda/summarynotes/cbcsummarynotesfindMySimulationsOncourse`,
                        {
                            classId: props.classId
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    )
                    .then((response) => {
                        const updatedSimulations = [];
                        response.data.simulations.forEach((result) => {
                            updatedSimulations.push({ id: result.id, name: result.name });
                            setSimulations(updatedSimulations)
                        })
                        
                        setSimulations(updatedSimulations);
                        setIsSearchingSaveSimulation(false);
                        setSearchResults([]);
                        setSimulationData('');
                        props.closePopUpView();
                    }).catch((err) => {
                        setIsSearchingSaveSimulation(false);
                        setSearchResults([]);
                        setSimulationData('');
                        props.closePopUpView();
                    });
            })
            .catch((err) => {
                console.log(err);
                setIsSearchingSaveSimulation(false);
            });

    }

    return (
        (!isSearchingSaveSimulation) ? <div className='z-20' style={{ zIndex: 100000, overflow: 'auto' }}>
            <Input
                label="Simulation name"
                elementType="input"
                elementConfig={{
                    type: "text",
                    placeholder: "Simulation name",
                }}
                value={simulationData}
                changed={(value) => {
                    setSimulationData(value);
                    setError('');
                    searchPdf()
                }}
            />

            {error && <p className="text-red-500" style={{ color: "red" }}>{error}</p>}

            <div style={{ position: 'relative' }}>
                {(!isSearching) ? <Button
                    name="Search Simulation"
                    outline="true"
                    color="blue"
                    clicked={searchPdf}
                /> : <Spinner size="10" color="blue" />}

                {(searchResults.length > 0 || simulations.length > 0) && (
                    <div className="grid grid-cols-2 gap-4" style={{ position: 'relative', height: 150, top: '100%', fontSize: 11, left: 0, zIndex: 1, width: '100%', overflowY: "auto" }}>
                        <div><ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                            {searchResults.filter((searchResult) => {
                                return simulations.filter((simulation) => simulation.id === searchResult.id).length === 0;
                            }).map((result, index) => (
                                <li
                                    key={index}
                                    style={{ padding: '5px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                                >
                                    {result.name}
                                    <Button
                                        name="Add"
                                        outline="true"
                                        color="green"
                                        isSmall={"true"}
                                        clicked={() => {
                                            const updatedSimulations = [...simulations, { id: result.id, name: result.name }];
                                            setSimulations(updatedSimulations)
                                        }}
                                    />
                                </li>
                            ))}
                        </ul></div>
                        <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', overflowY: "auto" }}>
                                {simulations.map((result, index) => (
                                    <li
                                        key={index}
                                        style={{ padding: '5px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                                    >
                                        {result.name}
                                        <Button
                                            name="Remove"
                                            outline="true"
                                            color="red"
                                            isSmall={"true"}
                                            clicked={(index) => {
                                                removeSimulation(index)
                                            }}
                                        />
                                    </li>
                                ))}
                            </div>

                            <p className='bg-blue-100'>Total Simulations: {simulations.length}</p>
                        </div>
                    </div>
                )}
            </div>

            <Button
                name="Save  simulations"
                outline="true"
                color="green"
                clicked={saveSimulations}
            />
        </div> : <Spinner size="20" color="blue" />
    );
};

export default SimulationPreviewComponent;
