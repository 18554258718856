import PageContent from '../../../../pageContent';
import parse from 'react-html-parser';
import {
    CardBody,
    CardTitle,
    FeatureTitle,
    SectionTitle,
} from '../../../../../../elements/text';
import { Button, GoBackButton } from '../../../../../../elements/button';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { teacher_tests_questions_data } from '../../../../../../../functions/Tests';
import failed from '../../../../../../../images/test-failed-svgrepo-com.svg';
import Spinner from '../../../../../../elements/spinner';
import CompleteSentence from './completesentence/CompleteSentence';
import DragAndDrop from './draganddrop/DragAndDrop';
import OpenEnded from './openended/OpenEnded';
import SinglechoiceAnswerOptionGroup from './singlechoiceSelector';
import MultichoiceAnswerOptionGroup from './multichoiceSelector';


const Uiteachermadesheet = (props) => {
    const dispatch = useDispatch();
    const userAth = useSelector((state) => state.auth);
    const studentDashboardTestsSubjectsUnits = useSelector(
        (state) => state.studentDashboardTestsSubjectsUnits
    );
    const history = useHistory();
    const autoTestProgressList = useSelector(
        (state) => state.autoTestProgressList
    );
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    //---
    const test = props.answer_sheet;
    useEffect(() => {

    }, []);
    //--------------

    return (
        <>
            <div className='p-8 overflow-y-auto h-90 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 testcontent'>
                <CardTitle name="Answer sheet > Teacher made test" color="#FFFAFA" />
                <CardBody name={`Question number: ${currentQuestionNumber + 1}`} />
                {test && test.length > 0 && test[currentQuestionNumber].question_type ===
                    'multiple_choice' && (
                        <div>
                            {(test[currentQuestionNumber].question.panda_teacher_test_2020_03_answering_guidelines === 0) ?
                                <div>
                                    {parse(test[currentQuestionNumber].question.panda_teacher_test_2020_03_question_data)}
                                    <CardTitle name="Explanation" color="#FFFAFA" />
                                    <div className='border-1 border-dashed'>{parse((test[currentQuestionNumber].question.panda_teacher_test_2020_03_answer_explanation) === "" ? test[currentQuestionNumber].question.panda_teacher_test_2020_03_answer_explanation : "<span className='border-1 border-dashed'>No explanation available!!</span>")}</div>
                                    <CardTitle name="Answers" color="#FFFAFA" />
                                    <div >
                                        <SinglechoiceAnswerOptionGroup key={Math.random() * 1000} id={test[currentQuestionNumber].question.panda_teacher_test_2020_03_id} questionnber={currentQuestionNumber} answers={test[currentQuestionNumber].answers} answer_by_user={test[currentQuestionNumber].answer_by_user} />
                                    </div>
                                </div> :
                                <div>
                                    {parse(test[currentQuestionNumber].question.panda_teacher_test_2020_03_question_data)}
                                    <CardTitle name="Explanation" color="#FFFAFA" />
                                    <div className='border-1 border-dashed'>{parse((test[currentQuestionNumber].question.panda_teacher_test_2020_03_answer_explanation) === "" ? test[currentQuestionNumber].question.panda_teacher_test_2020_03_answer_explanation : "<span className='border-1 border-dashed'>No explanation available!!</span>")}</div>
                                    <CardTitle name="Answers" color="#FFFAFA" />
                                    <div>
                                        <MultichoiceAnswerOptionGroup key={Math.random() * 1000} id={test[currentQuestionNumber].question.panda_teacher_test_2020_03_id} questionnber={currentQuestionNumber} answers={test[currentQuestionNumber].answers} answer_by_user={test[currentQuestionNumber].answer_by_user} />
                                    </div>
                                </div>}
                        </div>
                    )}
                {test && test.length > 0 && test[currentQuestionNumber].question_type ===
                    'complete_sentence' && (
                        <CompleteSentence key={Math.random() * 1000} currentQuestionNumber={currentQuestionNumber} questionId={test[currentQuestionNumber].question.panda_fill_in_2020_03_id}
                            questiondata={test[currentQuestionNumber]} answer_by_user={test[currentQuestionNumber].answer_by_user}
                        />
                    )}
                {test && test.length > 0 && test[currentQuestionNumber].question_type ===
                    'open_ended' && (
                        <OpenEnded key={Math.random() * 1000} questionId={test[currentQuestionNumber].question.panda_open_ended_2020_03_id} currentQuestionNumber={currentQuestionNumber} questiondata={test[currentQuestionNumber]} answer_by_user={test[currentQuestionNumber].answer_by_user} />
                    )}
                {test && test.length > 0 && test[currentQuestionNumber].question_type ===
                    'drag_and_drop' && (
                        <DragAndDrop key={Math.random() * 1000} questionId={test[currentQuestionNumber].question.panda_drag_and_drop_2020_03_id} currentQuestionNumber={currentQuestionNumber} questiondata={test[currentQuestionNumber]} answer_by_user={test[currentQuestionNumber].answer_by_user} />
                    )}
            </div>
            <div className='float-right'>
                {(currentQuestionNumber > 0) && (<Button
                    name="Prev"
                    outline="true"
                    color="red"
                    clicked={() => {
                        if (currentQuestionNumber <= test.length) {
                            setCurrentQuestionNumber((number) => number - 1);
                        }
                    }}
                />)}
                {(currentQuestionNumber < (test.length - 1)) && (<Button
                    name="Next"
                    outline="true"
                    color="red"
                    clicked={() => {
                        if (currentQuestionNumber <= test.length) {
                            setCurrentQuestionNumber((number) => number + 1);
                        }
                    }}
                />)}

            </div>
        </>
    );
};

export default Uiteachermadesheet;
