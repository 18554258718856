import PageContent from '../pageContent';
import { FeatureTitle } from '../../../elements/text';
import { Button, ButtonWithIcon } from '../../../elements/button';
import { useTabs } from 'react-headless-tabs';
import TabSelector from './TabSelector';
import Input from '../../../elements/input';
import { useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import Notification from '../../../elements/notification/notification';
import { MdAttachFile, MdFormatAlignLeft, MdFormatAlignRight, MdFormatUnderlined, MdImage, MdOutlineFormatAlignJustify, MdOutlineFormatColorText } from 'react-icons/md';

const ComposeMessage = () => {
  const groups = [
    { name: 'All messages' },
    { name: 'Parents' },
    { name: 'Teachers' },
    { name: 'Students' },
    { name: 'Announcements' },
    { name: 'SMS' },
  ];

  const tabs = groups.map((group) => group.name);
  const [selectedTab, setSelectedTab] = useTabs(tabs, '');
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');

  return (
    <PageContent>
      <div className="p-3 bg-white-blue">
        <FeatureTitle name="Communication" />
        <div className="md:grid grid-cols-12 w-full min-h-screen-sec">
          <div className="md:h-full col-span-2">
            <div className="flex justify-center">
              <ButtonWithIcon
                name="Compose"
                outline="true"
                color="blue"
                isSquare
                onClick={() => {}}
              >
                <BsPencilSquare color="#183F71" size={26} />
              </ButtonWithIcon>
            </div>
            <div className="py-5 bg-white h-full m-2 rounded">
              {groups.map((group, index) => (
                <TabSelector
                  key={index}
                  isActive={selectedTab === group.name}
                  title={group.name}
                  new={group.unread}
                  onClick={() => setSelectedTab(group.name)}
                />
              ))}
            </div>
          </div>
          <div className="bg-white p-2 m-2 md:h-full max-h-myscreen-sec col-span-7">
            <div className="w-50%">
              <Input
                elementType="input"
                elementConfig={{
                  type: 'text',
                  placeholder: 'Add recipient',
                }}
                value={recipient}
                changed={setRecipient}
              />
            </div>
            <div className="w-50%">
              <Input
                elementType="input"
                elementConfig={{
                  type: 'text',
                  placeholder: 'Add subject',
                }}
                value={subject}
                changed={setSubject}
              />
            </div>
            <Input
              elementType="textarea"
              elementConfig={{
                type: 'text',
                placeholder: 'Add text',
              }}
              value={text}
              changed={setText}
              additional="h-screen-third"
            />
            <div className="py-2 flex space-x-2 items-center">
              <Button
                name="Send"
                outline="false"
                color="blue"
                clicked={() => {}}
              />
              <MdImage color="#839098" />
              <MdAttachFile color="#839098" />
              <MdFormatUnderlined color="#839098" />
              <MdOutlineFormatAlignJustify color="#839098" />
              <MdFormatAlignRight color="#839098" />
              <MdFormatAlignLeft color="#839098" />
              <MdOutlineFormatColorText color="#839098" />
            </div>
          </div>
          <div className="py-2 md:h-full max-h-myscreen-sec col-span-3 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
            <Notification auth />
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default ComposeMessage;
