import { ContentContainer } from "../../../common/content/container";
import StudentIcon from "../../../../images/icons/students.svg";
import TeacherIcon from "../../../../images/icons/teachers.svg";
import ClassesIcon from "../../../../images/icons/classes.svg";
import Days from "../../../elements/days/days";
import {
  CardBody,
  CardSubText,
  CardTitle,
  PageTitle,
  SmallText,
} from "../../../elements/text";
import Notification from "../../../elements/notification/notification";
import { ImLab } from "react-icons/im";
import { BiBook, BiRectangle } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsCardList } from "react-icons/bs";
import PageContent from "../pageContent";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/";
import Spinner from "../../../elements/spinner";
import { useParams, useHistory } from "react-router-dom";
import {
  student_new_subjects_start,
  student_recommended_subjects_start,
  student_subject_specialization_start,
  student_suggested_features_start,
  student_trending_subjects_start,
} from "../../../../store/actions/student.dashboard.homepage";
import { shuffleSuggestedFeatures } from "../../../../functions/suggestedFeaturesUtils";
import { subjectSpecialization } from "../../../../functions/subjectSpecializationUtils";
import { trendingSubjects } from "../../../../functions/trendingSubjects";
import {
  newSubjects,
  recommendedSubjects,
} from "../../../../functions/newSubjects";
import Table from "../../../elements/table/table";
import { Button, ButtonWithIcon, TextButton } from "../../../elements/button";
import { SchoolEventCard } from "../../../elements/card";
import { Pie, PieChart, Tooltip } from "recharts";
import Modal from "../../../elements/modal/modal";
import Input from "../../../elements/input";
import axios from "../../../../axios-base";
import { school_dashboard_users_count_start, school_dashboard_users_start } from "../../../../store/actions/school.dashboard.users";
import UsersActionlist from "../users/usersactionlist";
import UsersTable from "../users/userstable";
import UsersTableRecent from "../users/userstableRecent";

const Dashboard = () => {
  //---Preload suggested features on the platform--
  const userAuth = useSelector((state) => state.auth);
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );
  const schoolDashboardUsers = useSelector(
    (state) => state.schoolDashboardUsers
  );
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const isAuthenticated = useSelector((state) => state.auth.token != null);
  const [showAcademicModal, setShowAcademicModal] = useState(false);
  const [academicyear, setAcademicyear] = useState();
  const [term, setTerm] = useState();
  const [termId, setTermId] = useState("");
  const [loading, setLoading] = useState(false);

  const [terms, setTerms] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);



  // Find active package information
  useEffect(() => {
    axios.post(
      `/opanda/schoolDashboard/getMySchoolPackage`,
      {
        school_id: userAuth.user.id
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userAuth.token}`
        }
      }
    )
      .then((response) => {
        // Check if response.data.terms is defined and is an array
        const terms = Array.isArray(response?.data?.terms) ? response.data.terms : [];

        // Filter active terms
        const activeTerms = terms.filter((item) => item.isActive !== 0);

        // Get the first active academic year
        const activeAcademicYear = activeTerms.length > 0 ? activeTerms[0].academic_year : null;
        setAcademicyear(activeAcademicYear);

        // Get the first active term
        const activeTerm = activeTerms.length > 0 ? activeTerms[0].term : null;
        setTerm(activeTerm);

        // Get the first active school ID
        const activeTermId = activeTerms.length > 0 ? activeTerms[0].school_id : null;
        setTermId(activeTermId);

        // Map terms to value and displayValue
        const mappedTerms = terms.map((item) => ({ value: item.term, displayValue: item.term }));
        setTerms(mappedTerms);

        // Map academic years and get the first one
        const mappedAcademicYears = terms.map((item) => ({ value: item.academic_year, displayValue: item.academic_year }));
        setAcademicYears([mappedAcademicYears[0] || {}]);


      })
      .catch((err) => {
        console.log(err)

      });

  }, []);


  const features = [
    {
      name: "Lab Simulation",
      Icon: <ImLab color="#183F71" size="30" />,
      link: "/school/features/simulations",
    },
    {
      name: "View Courses",
      Icon: <BiBook color="#183F71" size="30" />,
      link: "/school/features/summarynotes/0/",
    },
    {
      name: "Challenge Peers",
      Icon: <HiOutlineUserGroup color="#183F71" size="30" />,
      link: "/school/features/challenges",
    },
    {
      name: "Past Papers",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/school/features/pastpapers",
    },
    {
      name: "Library",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/school/features/library",
    },
    {
      name: "Online class",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/school/features/onlineclasses",
    },
    {
      name: "More practice",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/school/features/practice",
    },
    {
      name: "My Classes",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/school/features/myclasses",
    },
    {
      name: "Tests",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/school/features/tests",
    },
  ];



  let subjects = [
    {
      id: 1,
      subject: "Agriculture",
      imgsrc: "/images/preview/Agriculture.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-01T12:05:25.032Z").toString(),
    },
    {
      id: 2,
      subject: "ART AND CRAFTS",
      imgsrc: "/images/preview/Arts and Crafts.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 3,
      subject: "BIOLOGY",
      imgsrc: "/images/preview/biology.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 4,
      subject: "CHEMISTRY",
      imgsrc: "/images/preview/chem.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 5,
      subject: "COMPUTER SCIENCE",
      imgsrc: "/images/preview/Computer science.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 6,
      subject: "ECONOMICS",
      imgsrc: "/images/preview/Economics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 7,
      subject: "ELECTRICITY",
      imgsrc: "/images/preview/Electricity.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 8,
      subject: "ELECTRICITY FOR TVET",
      imgsrc: "/images/preview/Electricity for TVET.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 9,
      subject: "ELECTRONICS",
      imgsrc: "/images/preview/Electronics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 10,
      subject: "ELECTRONICS FOR TVET",
      imgsrc: "/images/preview/Electronics for TVET.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 11,
      subject: "English",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 12,
      subject: "ENTREPRENEURSHIP",
      imgsrc: "/images/preview/enterpreneurship.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 13,
      subject: "FINE ART AND CRAFTS",
      imgsrc: "/images/preview/Arts and Crafts.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 14,
      subject: "FRANÇAIS",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 15,
      subject: "GEOGRAPHY",
      imgsrc: "/images/preview/Geaography.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 16,
      subject: "Geography and Environment",
      imgsrc: "/images/preview/Geography and environment.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 17,
      subject: "HISTORY",
      imgsrc: "/images/preview/History.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 18,
      subject: "ICT",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 19,
      subject: "Literature in English",
      imgsrc: "/images/preview/Literature in English.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 20,
      subject: "MACHINE ASSEMBLY",
      imgsrc: "/images/preview/Machine assembly.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 21,
      subject: "MATHEMATICS",
      imgsrc: "/images/preview/Mathematics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 22,
      subject: "PHYSICS",
      imgsrc: "/images/preview/Physics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 23,
      subject: "RELIGION AND ETHICS",
      imgsrc: "/images/preview/Religion and ethics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
  ];


  const tableheader1 = [
    "Names",
    "User type",
    "Joined",
    "phone number",
    "Action",
  ];

  const tablebody2 = [
    {
      td1: { data: "Nahimana Eric", type: "text" },
      td2: { data: "Student", type: "text" },
      td3: { data: "2022-08-16", type: "text" },

      td4: { data: "0786654321", type: "text" },
      td5: {
        data: (
          <>
            <TextButton name="Remove" color="red" />
          </>
        ),
        type: "component",
      },
    },
    {
      td1: { data: "Nahimana Eric", type: "text" },
      td2: { data: "Student", type: "text" },
      td3: { data: "2022-08-16", type: "text" },

      td4: { data: "0786654321", type: "text" },
      td5: {
        data: (
          <>
            <TextButton name="Remove" color="red" />
          </>
        ),
        type: "component",
      },
    },
    {
      td1: { data: "Nahimana Eric", type: "text" },
      td2: { data: "Student", type: "text" },
      td3: { data: "2022-08-16", type: "text" },

      td4: { data: "0786654321", type: "text" },
      td5: {
        data: (
          <>
            <TextButton name="Remove" color="red" />
          </>
        ),
        type: "component",
      },
    },
    {
      td1: { data: "Nahimana Eric", type: "text" },
      td2: { data: "Student", type: "text" },
      td3: { data: "2022-08-16", type: "text" },

      td4: { data: "0786654321", type: "text" },
      td5: {
        data: (
          <>
            <TextButton name="Remove" color="red" />
          </>
        ),
        type: "component",
      },
    },
    {
      td1: {
        data: "Nahimanasfdfsffffs Ericfhdfghdfhfhfhdfhdfhghdfgh Ericfhdfghdfhfhfhdfhdfhghdfgh",
        type: "text",
      },
      td2: { data: "Student", type: "text" },
      td3: { data: "2022-08-16", type: "text" },

      td4: { data: "0786654321", type: "text" },
      td5: {
        data: (
          <>
            <TextButton name="Remove" color="red" />
          </>
        ),
        type: "component",
      },
    },
    {
      td1: { data: "Nahim", type: "text" },
      td2: { data: "Student", type: "text" },
      td3: { data: "2022-08-16", type: "text" },

      td4: { data: "0786654321", type: "text" },
      td5: {
        data: (
          <>
            <TextButton name="Remove" color="red" />
          </>
        ),
        type: "component",
      },
    },
  ];
  const tablebody1 = [];

  useEffect(() => {
    dispatch(
      school_dashboard_users_count_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
  }, []);



  return (
    <>
      <Modal
        medium
        show={showAcademicModal}
        modalClosed={() => setShowAcademicModal(false)}
      >
        <div className="flex flex-col">
          <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
            <CardTitle name="Academic term" color="blue" />
            <div className="md:grid grid-cols-2 gap-2">
              <Input
                label="Specify the academic year you want to manage data for"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [
                    ...academicYears
                  ],
                }}
                value={academicyear}
                changed={(val) => {
                  if (val != "SELECT") {
                    setAcademicyear(val)
                  } else {
                  }
                }}
                validation={{ required: false }}
                error="Academic year is required"
              />
              <Input
                label="Specify the academic term you want to manage data for "
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [
                    ...terms
                  ],
                }}
                value={term}
                changed={(val) => {
                  if (val != "SELECT") {
                    setTerm(val)
                  } else {

                  }
                }}
                validation={{ required: true }}
                shouldValidate
                error="Academic term is required"
              />
            </div>



            <div className="hidden md:grid grid-cols-3 gap-2">
              <div></div>
            </div>
            <div className="flex space-x-2 justify-center py-5">
              <Button name="Cancel" outline="true" color="blue" clicked={() => setShowAcademicModal(false)} />
              {(!loading) ? <Button name="Set Academic term" outline="false" clicked={() => {
                if (term != "SELECT" && academicyear != "SELECT") {
                  //---
                  setLoading(true)
                  axios.post(
                    `/opanda/schoolDashboard/setMySchoolPackageAcademicTerm`,
                    {
                      school_id: termId,
                      term: term,
                      academicyear: academicyear

                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${userAuth.token}`
                      }
                    }
                  )
                    .then((response) => {
                      axios.post(
                        `/opanda/schoolDashboard/getMySchoolPackage`,
                        {
                          school_id: userAuth.user.id
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${userAuth.token}`
                          }
                        }
                      )
                        .then((response) => {
                          setLoading(false)
                          setAcademicyear(response.data.terms.filter((item) => item.isActive !== 0).map((item) => { return item.academic_year })[0])
                          setTerm(response.data.terms.filter((item) => item.isActive !== 0).map((item) => { return item.term })[0])
                          setTermId(response.data.terms.filter((item) => item.isActive !== 0).map((item) => { return item.id })[0])
                          setTerms(response.data.terms.map((item) => { return { value: item.term, displayValue: item.term } }))
                          setAcademicYears([response.data.terms.map((item, index) => {
                            return { value: item.academic_year, displayValue: item.academic_year }
                          })
                          [0]])

                        })
                        .catch((err) => {
                          console.log(err)

                        });
                    })
                    .catch((err) => {
                      console.log(err)

                    });

                } else {
                  alert("Please select the academic year and term")
                }
              }} color="blue" /> : <Spinner size="20" color="blue" />}
            </div>
          </div>
        </div>
      </Modal>
      <PageContent>

        <div className="w-full h-full flex flex-col flex-col-reverse md:flex-row justify-between p-3 md:space-x-4 max-h-myscreen overflow-auto scrollbar-thin">
          <div className="md:w-70%">
            <div className="space-y-2">
              {(!schoolDashboardUsers?.school_dashboard_users_count_loading && schoolDashboardUsers?.school_dashboard_users_count) ? <div className="pt-2 px-2 space-x-2 w-full flex flex-row items-stretch space-around">
                <div className="bg-green rounded-lg h-36 p-4 cursor-pointer flex flex-col items-start justify-center">
                  <CardTitle name="Students" color="white" />
                  <div className="grid grid-cols-2 gap-2 justify-items-center items-center">
                    <PageTitle name={`${(schoolDashboardUsers?.school_dashboard_users_count[0]?.number) ? schoolDashboardUsers?.school_dashboard_users_count[0]?.number : 0}`} color="white" />
                    <div className="rounded-full mx-auto">
                      <img
                        src={StudentIcon}
                        alt="student"
                        className="h-36px w-auto m-auto"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-yellow rounded-lg h-36 p-4 cursor-pointer flex flex-col items-start justify-center">
                  <CardTitle name="Teachers" color="white" />
                  <div className="grid grid-cols-2 gap-2 justify-items-center items-center">
                    <PageTitle name={`${(schoolDashboardUsers?.school_dashboard_users_count[1]?.number) ? schoolDashboardUsers?.school_dashboard_users_count[1]?.number : 0}`} color="white" />
                    <div className="rounded-full mx-auto">
                      <img
                        src={TeacherIcon}
                        alt="teacher"
                        className="h-36px w-auto m-auto"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-blue rounded-lg h-36 p-4 cursor-pointer flex flex-col items-start justify-center">
                  <CardTitle name="Parents" color="white" />
                  <div className="grid grid-cols-2 gap-2 justify-items-center items-center">
                    <PageTitle name={`${(schoolDashboardUsers?.school_dashboard_users_count[2]?.number) ? schoolDashboardUsers?.school_dashboard_users_count[2].number : 0}`} color="white" />
                    <div className="bg-white rounded-full mx-auto">
                      <img
                        src={ClassesIcon}
                        alt="classes"
                        className="h-36px w-auto m-auto "
                      />
                    </div>
                  </div>
                </div>
              </div> : <Spinner size="20" color="blue" />}
              <div className="md:mx-4">
                <CardTitle name="Recent Users registration" />
                <div className="w-full flex flex-wrap">
                  <div className="w-full h-48 min-h-full bg-white relative overflow-x-auto sm:rounded-lg scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
                    <UsersTableRecent />

                  </div>
                </div>


              </div>
              <div className="md:mx-4 grid grid-cols-2 gap-2">
                <div className="col-span-2 md:col-span-1 md:mx-4 space-y-2">
                  <CardTitle name="Weekly progress" />
                  <div class="grid grid-cols-2 gap-2 bg-white px-2 py-2">
                    <div>
                      <CardTitle
                        name="Activity report"
                        color="blue"
                        alignment="left"
                      />

                      <PieChart width={200} height={100}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={[
                            { name: "Group A", value: 400 },
                            { name: "Group B", value: 300 },
                            { name: "Group C", value: 300 },
                          ]}
                          cx={45}
                          cy={45}
                          innerRadius={30}
                          outerRadius={50}
                          fill="#8884d8"
                          paddingAngle={5}
                        />
                        <Tooltip />
                      </PieChart>
                    </div>
                    <div className="space-y-2">
                      <CardBody name="Top Activities" color="blue" />
                      <div className="space-y-2">
                        <div className="flex flex-row items-start space-x-2"><BiRectangle className="bg-blue h-24px w-24px" /> <CardSubText name="Tests" /></div>
                        <div className="flex flex-row items-start space-x-2"><BiRectangle className="bg-red h-24px w-24px" /> <CardSubText name="Summary notes" /></div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1 md:mx-4 space-y-2">
                  <CardTitle name="Approaching events" />
                  <div class="grid grid-cols-2 gap-2 bg-white px-2 py-2">
                    <SchoolEventCard
                      title="National exams"
                      createdAt="2012-02-02"
                      date="2051-01-01"
                      time="08:00"
                      onClick={() => {
                        history.push(`/student/features/tests/1?scheduled=true`);
                      }}
                    />

                    <SchoolEventCard
                      title="National exams"
                      createdAt="2012-02-02"
                      date="2015-01-01"
                      time="08:00"
                      onClick={() => {
                        history.push(`/student/features/tests/1?scheduled=true`);
                      }}
                    />

                    <SchoolEventCard
                      title="National exams"
                      createdAt="2012-02-02"
                      date="2015-01-01"
                      time="08:00"
                      onClick={() => {
                        history.push(`/student/features/tests/1?scheduled=true`);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">

            </div>
          </div>
          <div className="md:w-30% flex flex-col align-end justify-start">
            <div> <SmallText onClick={() => { setShowAcademicModal(true) }} name={(academicyear) ? `${academicyear}--${term}` : ""} color="white" additional="cursor-pointer uppercase font-bold rounded-lg bg-gray-500 py-2 px-3" /></div>
            {/* <Notification auth /> */}
          </div>
        </div>
      </PageContent></>
  );
};

export default Dashboard;
