import * as constants from "./constants";

export const getCompleteSentenceAnswers = (id, questionId) => {
  const result =
    constants.complete_sentence + "#~#" + questionId + "#~#" + 5 + "#~#";
  let data = document.getElementById(id).getElementsByClassName("answers-div");
  let resvalue;
  let i = 0;
  for (i = 0; i < data.length; i++) {
    resvalue = data[i].querySelector("input[name='answer']").value; //getElementsByTagName("input").value);
  }
 
  return result + resvalue;
};

export const getDragAndDropAnswers = (answer, questionId) => {
  var responsedata = "";
  if (answer != "___________") {
    responsedata =
      constants.drag_and_drop + "#~#" + questionId + "#~#" + 5 + "#~#" + answer;
  }
  // console.log(responsedata);
  return responsedata;
};

export const getOpenEndedAnswers = (questionId, data) => {
  const answer = data.replace("<p>", "").replace("</p>", "");
  let responsedata =
    constants.open_ended + "#~#" + questionId + "#~#" + 5 + "#~#" + answer;
  return responsedata;
};

export default {
  getCompleteSentenceAnswers,
  getDragAndDropAnswers,
  getOpenEndedAnswers,
};
