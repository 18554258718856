import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import {logout} from "../store/actions/auth";


const ValidatingToken=(token)=>{
const decodedUser = jwtDecode(token);
    if (decodedUser) {
      if(decodedUser?.expireDate){
         const today = new Date().getTime();
         const expireDate = new Date(decodedUser.expireDate).getTime();
         const days = (expireDate-today) / (1000*60*60*24);
       if(days > 0){
        return;
       }else{
      window.location.href="/unauthorized";
      return;
       }
    }
  }else{
    logout();
    window.location.href="/unauthorized";
    return;
  }
}

export default ValidatingToken;