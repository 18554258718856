import constants, {
  completesentence,
  draganddrop,
  openended,
} from "./constants";

export const getAutoQuestionV1 = () => {
  let qa = [];
  let qs = [];
  let qsand = [];
  let qsandGetQuest = [];
  let q = [];
  let a = [];
  let og = "#O.G ";
  let answerseparator = "-***-";
  let answerSymbol = "#A.N.S";
  let emptySpace = "";
  let mulSymbol = "#M.U.L";
  let ogs = "#O.G.S";
  let ogss = " #O.G.S";
  let ogq = "#O.G.Q ";
  let n = 2;
  let i = 0;
  let regOGS = new RegExp(ogs);
  let regOG = new RegExp(og + "" + "(.+)");
  let regOGQ = new RegExp(ogq);
  let regAnOG = new RegExp(og);
  let regAnOGS = new RegExp(ogss);
  let regAnswer = /#A.N.S(\s)*/;
  let regAnswergGlobal = /#A.N.S/g;

  //  let questions = document.getElementById("data").value;
  let questions = document
    .getElementById("data")
    .querySelector(".ql-editor").innerText;
  if (questions) {
    // spliting data to get questions
    qs = questions.split(regOGQ);
    qs.forEach((element) => {
      // spliting each questions to get two parts of question [questio data & explanation], [answers]
      qsand.push(element.split(regOG));
    });
    let i = 0,
      j = 0;
    let an = [];
    for (i = 1; i < qsand.length; i++) {
      for (j = 0; j < 2; j++) {
        if (j == 1) {
          let finalAnswersObjects = [];
          //spliting to store question data [0] and explanation[1]
          an = qsand[i][j].split(regAnOG);
          qsand[i][j] = an;
        } else {
          //spliting to store answers in array
          an = qsand[i][j].split(regAnOGS);
          // removing the last element that was an emty string
          an.pop();
          qsand[i][j] = an;
        }
      }
    }
  }
  let singleanswerquestion = {};
  let singleanswerquestions = [];
  for (var l = 1; l < qsand.length; l++) {
    var questiondata = qsand[l][0][0].replace(ogs, emptySpace);
    var correctanswer = qsand[l][1]
      .find((value) => regAnswer.test(value))
      .replace(answerSymbol, emptySpace);
    if (questiondata.includes(mulSymbol)) {
      qsand[l][0][0] = qsand[l][0][0].replace(ogs, emptySpace);
      questiondata = qsand[l][0][0].replace(mulSymbol, emptySpace);
      correctanswer = qsand[l][1]
        .filter((value) => regAnswer.test(value))
        .join(answerseparator)
        .replace(regAnswergGlobal, emptySpace);
    }
    var explanation = qsand[l][0][1];
    var answerslist = [];
    for (var f = 0; f < qsand[l][1].length; f++) {
      var answer = qsand[l][1][f];
      var isanswer =
        regAnswer.test(answer) == true ? constants.ONE : constants.ZERO;
      var answer_data = answer.replace(regAnswer, "").trim();
      answerslist.push({
        answer_data,
        isanswer,
        visibility: 1,
        creator: 22,
        regdate: "regdate",
      });
    }
    questiondata = questiondata.trim();
    explanation = explanation.trim();
    // correctanswer = correctanswer.trim();
    singleanswerquestion = {
      question_data: questiondata,
      explanation,
      answerslist,
      creator_id: 2,
      diffculty: 1,
      finalized: 1,
      creator_names: "Jimmy",
      visibility: 1,
      regdate: "regdate",
      auto_answering_technique: 4,
      uploader_names: "Jimmy",
      creator: 33,
      time_assigned_in_seconds: "60",
      total_marks: 5,
      academic_units: "Blessed",
      academic_level: 4,
      subject: "relogion",
    };
    singleanswerquestions.push(singleanswerquestion);
  }
  // console.log(`Questions to be Saved`);
  // console.log(singleanswerquestions);
  return singleanswerquestions;
};

export const getFillInQuestions = () => {
  let qs = [];
  let ogq = "#O.G.Q ";
  let regOGQ = new RegExp(ogq);
  let listofquestionstobesaved = [];
  let questions = document
    .getElementById("fillin-data")
    .querySelector(".ql-editor").innerText;
  if (questions) {
    // spliting data to get questions
    qs = questions.split(regOGQ);
    // checking if the first element is empty string and remove it
    if (qs[0] == "") {
      qs.shift();
    }

    qs.forEach((element) => {
      let questiontobesaved = {
        creator_id: 2,
        difficulty_level: 2,
        question_data: element,
        finalized: 2,
        creator_names: "JImmy",
        visibility: 2,
        regdate: "regdate",
        uploader_names: "JImmy",
        auto_creator: "Jimmy",
        time_assigned_in_minutes: "60",
        total_marks: 2,
        academic_unit: "Geography",
        academic_level: 2,
        academic_subject: "GEO",
        test_type: constants.fillin,
        explanation: "Explanation",

        // questiondata,
        // explanation,
        // correctanswers,
        // listOfAnswers,
      };
      listofquestionstobesaved.push(questiontobesaved);
    });
  }

  return listofquestionstobesaved;
};

export const getMatchingQuestions = () => {
  let qa = [];
  let qs = [];
  let qsand = [];
  let qsandGetQuest = [];
  let q = [];
  let a = [];
  let ogc = "#O.G.C";
  let og = "#O.G ";
  let answerseparator = "-***-";
  let answerSymbol = "#A.N.S";
  let emptySpace = "";
  let mulSymbol = "#M.U.L";
  let ogs = "#O.G.S ";
  let ogss = " #O.G.S";
  let ogq = "#O.G.Q ";
  // let reg = new RegExp("ab+c", "i");
  let n = 2;
  let i = 0;
  let regOGS = /#O.G.S(\s)*/; // new RegExp(ogs);
  let regOG = new RegExp(og + "" + "(.+)");
  let regOGQ = new RegExp(ogq);
  let regAnOG = new RegExp(og);
  let regAnOGS = new RegExp(ogss);
  let regAnswer = /.* #A.N.S/;
  let regEmptySpace = /(\s)*/;
  let listofquestionstobesaved = [];
  // let questions = document.getElementById("matching-data").value;
  let questions = document
    .getElementById("quillo-matching-data")
    .querySelector(".ql-editor").innerText;
  if (questions) {
    let helper = [];
    // spliting data to get questions
    qs = questions.split(regOGQ);
    if (qs[0] == "") {
      qs.shift();
    }
    qs.forEach((element) => {
      // adding question in array of questions to be saved
      let questiontobesaved = {
        creator_id: 2,
        difficulty_level: 3,
        question_data: element,
        finalized: 2,
        creator_names: "jimmy",
        visibility: 2,
        regdate: "regdate",
        uploader_names: "james",
        auto_creator: "JImmy",
        time_assigned_in_minutes: "60",
        total_marks: 5,
        academic_unit: "Capital CIties",
        academic_level: 4,
        academic_subject: "GEO",
        test_type: constants.matching,
        explanation: "Explanation",
      };
      listofquestionstobesaved.push(questiontobesaved);
    });
  }
  // THE CODES WILL SEREVE FOR ANOTHER PURPOSE

  // ::::::::::::::START::::::::::::::::::

  // qs.forEach((element) => {
  //   // spliting each questions to get three parts of question
  //   qsand.push(element.split(regOGS));
  //   // qsand = helper;
  // });
  // removing empty objects in the array
  // for (var k = 0; k < qsand.length; k++) {
  //   helper = qsand[k].filter(function (el) {
  //     if (el.length > 5) return el;
  //   });
  //   qsand[k] = helper;

  // console.log(`QSAND HHHH`);
  // console.log(qsand);
  // ::::::::::::::START::::::::::::::::::

  // console.log(`list of questions to be saved final`);
  // console.log(listofquestionstobesaved);
  return listofquestionstobesaved;
};

export const getMatchingQuestionParts = (givenquestiondata) => {
  let qa = [];
  let qs = [];
  let qsand = [];
  let qsandGetQuest = [];
  let q = [];
  let a = [];
  let ogc = "#O.G.C";
  let og = "#O.G ";
  let answerseparator = "-***-";
  let answerSymbol = "#A.N.S";
  let emptySpace = "";
  let mulSymbol = "#M.U.L";
  let ogs = "#O.G.S ";
  let ogss = " #O.G.S";
  let ogq = "#O.G.Q ";
  let n = 2;
  let i = 0;
  let regOGS = /#O.G.S(\s)*(\n)*/; // new RegExp(ogs);
  let regOG = new RegExp(og + "" + "(.+)");
  let regOGQ = new RegExp(/#O.G.Q(\s)*/);
  let regOGC = new RegExp(/#O.G.C(\s)*/);
  let regAnOG = new RegExp(og);
  let regAnOGS = new RegExp(ogss);
  let regAnswer = /.* #A.N.S/;
  let regEmptySpace = /(\s)*/;
  let listofquestionstobesaved = [];
  let data = [];
  if (givenquestiondata) {
    data = givenquestiondata
      .replace(regOGQ, "")
      .trim()
      .replace("\n", "")
      .trim()
      .split(regOGS);
  }

  // removing empty objects in the array
  for (var k = 0; k < data.length; k++) {
    data = data.filter(function (el) {
      if (el != undefined && el.length > constants.ONE) return el;
    });
  }

  let an,
    answers = [];
  let j = 0;
  for (j = 0; j < data.length; j++) {
    if (j == 1) {
      //spliting to store options to use in matching
      an = data[j]
        .replace(regOGC, "")
        .trim()
        .split(regOGC)
        .filter(function (el) {
          if (el != undefined && el.length > constants.ONE) return el;
        });
      data[j] = an;
    } else if (j == 2) {
      //spliting to store options to use in matching
      let dataj = data[j].replace().replace("#O.G", "").trim();
      let answerslist = dataj.split("#.#.#").filter(function (el) {
        if (el != undefined && el.length > constants.ONE) return el;
      });
      answerslist.forEach((element) => {
        // replacing answer by #RESPONSE# for better replacing on ui with a drag and drop component
        answers.push(
          element
            .split("#A.N.S")[0]
            .concat(constants.responseSeparator)
            .concat(constants.responseSymbol)
        );
      });
      data[j] = answers;
    }
  }
  const matchingquestiondata = {
    questiondata: data[0],
    listofoptions: data[1],
    listofanswers: data[2],
  };
  return matchingquestiondata;
};

export const getFillInQuestionParts = (givenquestiondata) => {
  let og = "#O.G ";
  let answerSymbol = "#A.N.S";
  let emptySpace = "";
  let ogs = "#O.G.S";
  let ogss = " #O.G.S";
  let ogq = "#O.G.Q ";
  let regOG = new RegExp(og + "" + "(.+)");
  let regOGQ = new RegExp(ogq);
  let regAnOG = new RegExp(og);
  let regAnOGS = new RegExp(ogss);
  let regAnswer = /#A.N.S(\s)*/;
  let fillinquestiondata = {};
  if (givenquestiondata) {
    //removing OGQ and separating based on OG to get first part that has questiondata and explanation and the second that has answers
    let partss = givenquestiondata.replace(regOGQ, "").trim().split(regOG);
    let j = 0;
    let an = [];
    let questiondata = "";
    let explanation = "";
    let insideAnswers = [];
    let correctanswers = [];
    let listofanswers = [];
    for (j = 0; j < 2; j++) {
      if (j == 1) {
        //spliting to get answers
        an = partss[j].split(regAnOG);
        partss[j] = an;
        for (var l = 0; l < partss[j].length; l++) {
          //  spliting to store answers in array
          insideAnswers = partss[j][l].split("#.#.#");
          if (insideAnswers[0] == "") {
            insideAnswers.shift();
          }
          correctanswers.push(
            insideAnswers
              .find((value) => regAnswer.test(value))
              .replace(answerSymbol, emptySpace)
              .trim()
          );
          listofanswers.push(insideAnswers);
        }
      } else {
        // spliting each questions to get two parts of question [questio data & explanation], [answers]
        an = partss[j].split(regAnOGS);
        // removing the last element that was an empty string in array of Question data and explanation
        an.pop();
        partss[j] = an;
        questiondata = partss[j][0].replace(ogs, emptySpace).trim();
        explanation = partss[j][1].trim();
      }
    }
    fillinquestiondata = {
      questiondata,
      explanation,
      regexArray: correctanswers,
      listofanswers,
    };
  }
  return fillinquestiondata;
};

export const getFillInQuestionSpanned = () => {
  // let data = document.getElementsByClassName("spanned-now");
  // console.log(typeof data);
  // console.log(data);
  // let i = 0;
  // for (i = 0; i < data.length; i++) {
  //   data[i].querySelector("input[name='instant']").value = i;
  //   console.log(data[i].querySelector("input[name='instant']").value); //getElementsByTagName("input").value);
  // }
  // data.forEach((element) => {
  //   console.log(element);
  // });
};

export const getFillInQuestionsSingleQUestionDataTobeRevised = () => {
  let qa = [];
  let qs = [];
  let qsand = [];
  let qsandGetQuest = [];
  let q = [];
  let a = [];
  let og = "#O.G ";
  let answerseparator = "-***-";
  let answerSymbol = "#A.N.S";
  let emptySpace = "";
  let mulSymbol = "#M.U.L";
  let ogs = "#O.G.S";
  let ogss = " #O.G.S";
  let ogq = "#O.G.Q ";
  // let reg = new RegExp("ab+c", "i");
  let n = 2;
  let i = 0;
  let regOGS = new RegExp(ogs);
  let regOG = new RegExp(og + "" + "(.+)");
  let regOGQ = new RegExp(ogq);
  let regAnOG = new RegExp(og);
  let regAnOGS = new RegExp(ogss);
  let regAnswer = /.* #A.N.S/;
  let listofquestionstobesaved = [];
  // let questions = document.getElementById("fillin-data").value;
  let questions = document
    .getElementById("fillin-data")
    .querySelector(".ql-editor").innerText;
  if (questions) {
    // spliting data to get questions
    qs = questions.split(regOGQ);
    // checking if the first element is empty string and remove it
    if (qs[0] == "") {
      qs.shift();
    }
    console.log(`QSSS`);
    console.log(qs);
    qs.forEach((element) => {
      // spliting each questions to get two parts of question [questio data & explanation], [answers]
      qsand.push(element.split(regOG));
    });
    console.log(`QSAND`);
    console.log(qsand);
    let i = 0,
      j = 0;
    let an = [];
    let questiontobesaved = {};
    let questiondata = "";
    let explanation = "";
    let insideAnswers = [];
    let correctanswers = [];
    let listOfAnswers = [];
    for (i = 1; i < qsand.length; i++) {
      for (j = 0; j < 2; j++) {
        if (j == 1) {
          //spliting to store question data [0] and explanation[1]
          an = qsand[i][j].split(regAnOG);
          qsand[i][j] = an;
          // console.log(qsand[i][j]);
          for (var l = 0; l < qsand[i][j].length; l++) {
            //  spliting to store answers in array
            insideAnswers = qsand[i][j][l].split("#.#.#");
            if (insideAnswers[0] == "") {
              insideAnswers.shift();
            }
            correctanswers.push(
              insideAnswers
                .find((value) => regAnswer.test(value))
                .replace(answerSymbol, emptySpace)
                .trim()
            );
            listOfAnswers.push(
              insideAnswers.join(answerseparator).replace(/#A.N.S/g, "")
            );
          }
          // console.log(`Correct Answers`);
          // console.log(correctanswers);
          // console.log(`List Answers`);
          // console.log(listOfAnswers);
          // console.log(`QSAND Length ${qsand[i][j].length}`);
          // console.log(`QSAND I: ${i} J: OUT KKK`);
          // console.log(qsand[i][j][0].split("#.#.#"));
        } else {
          //spliting to store answers in array
          an = qsand[i][j].split(regAnOGS);
          // removing the last element that was an empty string in array of Question data and explanation
          an.pop();
          qsand[i][j] = an;
          questiondata = qsand[i][j][0].replace(ogs, emptySpace).trim();
          explanation = qsand[i][j][1].trim();
        }
      }
      questiontobesaved = {
        questiondata,
        explanation,
        correctanswers,
        listOfAnswers,
      };
      listofquestionstobesaved.push(questiontobesaved);
    }
  }
  console.log(`list of questions to be saved final`);
  console.log(listofquestionstobesaved);
};

// export const getMatchingQuestions2 = () => {
//   let qa = [];
//   let qs = [];
//   let qsand = [];
//   let qsandGetQuest = [];
//   let q = [];
//   let a = [];
//   let ogc = "#O.G.C";
//   let og = "#O.G ";
//   let answerseparator = "-***-";
//   let answerSymbol = "#A.N.S";
//   let emptySpace = "";
//   let mulSymbol = "#M.U.L";
//   let ogs = "#O.G.S";
//   let ogss = " #O.G.S";
//   let ogq = "#O.G.Q ";
//   // let reg = new RegExp("ab+c", "i");
//   let n = 2;
//   let i = 0;
//   let regOGS = new RegExp(ogs);
//   let regOG = new RegExp(og + "" + "(.+)");
//   let regOGQ = /(\s|\n)*#O.G.Q(\s|\n)*/; // new RegExp(ogq);
//   let regAnOG = new RegExp(og);
//   let regAnOGS = new RegExp(ogss);
//   let regAnswer = /.* #A.N.S/;
//   let listofquestionstobesaved = [];
//   let questions = document
//     .getElementById("quillo")
//     .querySelector(".ql-editor").innerText;
//   // ("#O.G.Q #O.G.S Question 1 Data #O.G.S hhhd hdhExplanation 1 #O.G.S #O.G Answer 1  #A.N.S #O.G Answer 2 #O.G Answer 3 #O.G.Q #O.G.S Question2 Data #O.G.S hdhd dhd hshsyr Explanation2 #O.G.S #O.G Answer 21  #A.N.S #O.G Answer 2 #O.G Answer 3");
//   console.log("questions");
//   console.log(questions);
// };

export const getDragAndDropQuestionData = (
  selectedwords,
  questionmarks,
  questionduration
) => {
  const wordsjoiner = "~----~";
  let questiondata = document
    .getElementById("drag-and-drop-data")
    .querySelector(".ql-editor").innerHTML;
  let questiontobesaved = {};
  if (questiondata) {
    questiontobesaved = {
      panda_drag_and_drop_2020_03_access_code: "CODE",
      panda_drag_and_drop_2020_03_creator: 0,
      panda_drag_and_drop_2020_03_regdate: "2021-01-20T11:22:54.000Z",
      panda_drag_and_drop_2020_03_question_data_answers:
        selectedwords.join(wordsjoiner),
      panda_drag_and_drop_2020_03_question_data: questiondata,
      panda_drag_and_drop_2020_03_duration_of_test: 20,
      panda_drag_and_drop_2020_03_test_difficulty: "DIFF",
      panda_drag_and_drop_2020_03_unit: "UNit 4",
      panda_drag_and_drop_2020_03_marks: questionmarks,
      panda_drag_and_drop_2020_03_duration: questionduration,
      panda_drag_and_drop_2020_03_test_id: "47",
      questiontype: constants.drag_and_drop,
    };
  }

  return questiontobesaved;
};

export const getCompleteSentenceQuestionData = (
  selectedwords,
  questionmarks,
  questionduration
) => {
  const wordsjoiner = "~----~";
  let questiondata = document
    .getElementById("complete-sentence-data")
    .querySelector(".ql-editor").innerHTML;
  let questiontobesaved = {};
  if (questiondata) {
    questiontobesaved = {
      panda_fill_in_2020_03_access_code: "CODE",
      panda_fill_in_2020_03_creator: 0,
      panda_fill_in_2020_03_regdate: "2021-01-20T11:22:54.000Z",
      panda_fill_in_2020_03_question_data_answers:
        selectedwords.join(wordsjoiner),
      panda_fill_in_2020_03_question_data: questiondata,
      panda_fill_in_2020_03_duration_of_test: 0,
      panda_fill_in_2020_03_test_difficulty: "DIF",
      panda_fill_in_2020_03_unit: "UNit 3",
      panda_fill_in_2020_03_marks: questionmarks,
      panda_fill_in_2020_03_duration: questionduration,
      panda_fill_in_2020_03_test_id: "47",
      questiontype: constants.complete_sentence,
    };
  }

  return questiontobesaved;
};

export const getOpenEndedQuestionData = (questionmarks, questionduration) => {
  let questiondata = document
    .getElementById("open-ended-data")
    .querySelector(".ql-editor").innerHTML;
  let teacheranswer = document
    .getElementById("open-ended-teacher-answer")
    .querySelector(".ql-editor").innerHTML;
  let questiontobesaved = {};
  if (questiondata) {
    questiontobesaved = {
      panda_open_ended_2020_03_marked: "Marked",
      panda_open_ended_2020_03_regdate: "Date",
      panda_open_ended_2020_03_test_id: "Id",
      panda_open_ended_2020_03_teacher_answer: teacheranswer,
      panda_open_ended_2020_03_time_in_minutes: questionduration,
      panda_open_ended_2020_03_marks: questionmarks,
      panda_open_ended_2020_03_question_data: questiondata,
      questiontype: constants.open_ended,
    };
  }

  return questiontobesaved;
};

export const getMultipleChoiceData = (
  questiondata,
  explanation,
  answerslist,
  questionmarks,
  questionduration
) => {
  return {
    question_data: questiondata,
    explanation,
    answerslist,
    creator_id: 2,
    diffculty: 1,
    finalized: 1,
    creator_names: "Jimmy",
    visibility: 1,
    regdate: "regdate",
    auto_answering_technique: 4,
    uploader_names: "Jimmy",
    creator: 33,
    time_assigned_in_seconds: questionduration,
    total_marks: questionmarks,
    academic_units: "Blessed",
    academic_level: 4,
    subject: "religion",
    questiontype: constants.multiple_choice,
  };
};

export const getSelectedOption = (id) => {
  let checked = document.getElementById(id);
  let result;
  if (checked.checked == true) {
    result = constants.ONE; //true
  } else {
    result = constants.ZERO; //false, when not the answer
  }
  return result;
};

export default {
  getAutoQuestionV1,
  getMatchingQuestions,
  //  getMatchingQuestions2,
  getFillInQuestions,
  getFillInQuestionParts,
  getMatchingQuestionParts,
  getFillInQuestionSpanned,
  getDragAndDropQuestionData,
  getCompleteSentenceQuestionData,
  getOpenEndedQuestionData,
  getSelectedOption,
  getMultipleChoiceData,
};
