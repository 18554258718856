import CBC from './cbc';
import SideBar from './sidebar';
import TopNavBar from './topnavbar';

import ContentModal from '../../../elements/modal/contentmodal';
import { connect } from 'react-redux';
import { TabPanel } from '../../../elements/tab/tabpanel';
import { store } from '../../../../store/store';

const PageContent = (props) => {
  const { tabselected } = props;

  return (
    <div className="bg-white h-full w-full grid grid-cols-3 md:grid-cols-4 relative">
      <ContentModal />
      <SideBar />
      <TopNavBar />
      <div className="bg-white col-span-3 md:col-span-3 py-10 px-6">
        <TabPanel panel="cbc" tabselected={tabselected}>
          <CBC />
        </TabPanel>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
  };
};
const mapDispatchToProps = () => {
  return {
    changetab: (tab) => store.dispatch({ type: 'CHANGE_TAB', payload: tab }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
