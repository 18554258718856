;


export const getPrograms = (mapRes: any, usertype: any) => {
  const reslt = [];
  for (var i = 0; i < mapRes.length; i++) {
    reslt.push({ name: mapRes[i]?.abbreviatedname.toUpperCase(), tabName: mapRes[i]?.abbreviatedname.toLowerCase(), path: `/${usertype}/curriculum/${mapRes[i]?.abbreviatedname.toLowerCase()}` });
  }

  return reslt;

};
const courses = [
  { name: "MATHEMATICS", image: "/images/preview/math.svg" },
  { name: "PHYSICS", image: "/images/preview/bio.svg" },
  { name: "BIOLOGY", image: "/images/preview/bio.svg" },
  { name: "CHEMISTRY", image: "/images/preview/bio.svg" },
  { name: "ECONOMY", image: "/images/preview/math.svg" },
  { name: "GEOGRAPH", image: "/images/preview/bio.svg" },
];
export function bring_subject_image_src(subject: any) {

  if (subject === "Agriculture") {

    return { imgsrc: "/images/preview/Agriculture.svg" }

  } else if (subject === "ART AND CRAFTS") {
    return {
      imgsrc:
        "/images/preview/Arts and Crafts.svg"
    }
  } else if (subject === "BIOLOGY") {
    return { imgsrc: "/images/preview/biology.svg" }
  } else if (subject === "CHEMISTRY") {
    return { imgsrc: "/images/preview/chem.svg" }
  } else if (subject === "COMPUTER SCIENCE") {
    return { imgsrc: "/images/preview/Computer science.svg", }
  } else if (subject === "ECONOMICS") {
    return {
      imgsrc:
        "/images/preview/Economics.svg"
    }
  } else if (subject === "ELECTRICITY") {
    return { imgsrc: "/images/preview/TVET Electronics.svg", }
  } else if (subject === "ELECTRICITY FOR TVET") {
    return { imgsrc: "/images/preview/TVET Electronics.svg" }
  } else if (subject === "ELECTRICITY FOR TVET") {
    return { imgsrc: "/images/preview/TVET Electronics.svg" }
  } else if (subject === "ELECTRONICS") {
    return { imgsrc: "/images/preview/Electronics.svg" }
  } else if (subject === "ELECTRONICS") {
    return { imgsrc: "/images/preview/Electronics.svg" }
  } else if (subject === "ELECTRONICS FOR TVET") {
    return { imgsrc: "/images/preview/Electronics for TVET.svg" }
  } else if (subject === "English") {
    return { imgsrc: "/images/preview/Literature in English.svg" }
  } else if (subject === "ENTREPRENEURSHIP") {
    return { imgsrc: "/images/preview/enterpreneurship.svg" }
  } else if (subject === "FINE ART AND CRAFTS") {
    return { imgsrc: "/images/preview/Arts and Crafts.svg" }
  } else if (subject === "FRANÇAIS") {
    return { imgsrc: "/images/preview/French.svg" }
  } else if (subject === "GEOGRAPHY") {
    return { imgsrc: "/images/preview/Geaography.svg" }
  } else if (subject === "Geography and Environment") {
    return { imgsrc: "/images/preview/Geography and environment.svg" }
  } else if (subject === "HISTORY") {
    return { imgsrc: "/images/preview/History.svg" }
  } else if (subject === "ICT") {
    return {
      imgsrc:
        "/images/preview/Geography and environment.svg"
    }
  } else if (subject === "Literature in English") {
    return {
      imgsrc:
        "/images/preview/Literature in English.svg"
    }
  } else if (subject === "MACHINE ASSEMBLY") {
    return { imgsrc: "/images/preview/Machine assembly.svg" }
  } else if (subject === "MATHEMATICS") {
    return { imgsrc: "/images/preview/Mathematics.svg" }
  } else if (subject === "PHYSICS") {
    return { imgsrc: "/images/preview/Physics.svg" }
  } else if (subject === "RELIGION AND ETHICS") {
    return {
      imgsrc:
        "/images/preview/Religion and ethicsclear.svg"
    }
  }
}

export const getProgramsScientificObjects = (mapRes: any, subjects: any) => {
  const reslt = [];
  if (mapRes.length > 0) {
    for (var i = 0; i < mapRes[0].length; i++) {
      reslt.push({ name: mapRes[0][i].subject, image: bring_subject_image_src(mapRes[0][i].subject).imgsrc })
    }
  }

  return reslt;

};

export const getProgramsExtracurriculars = (mapRes: any, subjects: any) => {
  const reslt = [];
  if (mapRes.length > 0) {
    for (var i = 0; i < mapRes[1].length; i++) {
      reslt.push({ value: mapRes[1][i].abbreviatedname, displayValue: mapRes[1][i].abbreviatedname.toUpperCase() },)

    }
  }
  return reslt
};
export const getModulesFormatted = (mapRes: any) => {
  const reslt = [];
  if (mapRes.length > 0) {
    for (var i = 0; i < mapRes.length; i++) {
      reslt.push({ name: mapRes[i].name, image: mapRes[i].thumbnail == "thumbnail" ? "/images/preview/art-maths.jpg" : mapRes[i].thumbnail, description: mapRes[i].summary });
    }
  }


  return reslt;

};

