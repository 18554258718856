import Profile from '../../../components/pages/student/user/profile/index';
import Layout from '../../../components/common/Layout';

const StudentProfileContainer = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};

export default StudentProfileContainer;