import PageContent from '../pageContent';
import {
  CardBody,
  CardSubText,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from '../../../elements/text';
import { Button, GoBackButton } from '../../../elements/button';
import { FaFileAlt, FaRegClock } from 'react-icons/fa';
import { BsCalendar2 } from 'react-icons/bs';
import Notification from '../../../elements/notification/notification';

const Myclass = () => {
  const myClass = {
    subject: 'Computer Science',
    unit: 'Unit 3: Incididunt elit non irure commodo',
    summary:
      'Tempor dolor aliqua id Lorem ipsum commodo enim pariatur. Qui laborum reprehenderit nostrud minim ipsum irure nulla. Ipsum pariatur excepteur exercitation duis eiusmod aute. Consectetur aliqua nisi et esse ullamco nisi laborum. Eu excepteur Lorem duis dolor sint elit consectetur eiusmod tempor. Enim esse reprehenderit occaecat esse incididunt non sint.',
    time: { from: '10:00 am', to: '12:30 am' },
    date: '2022-05-10',
    happening: 'Online',
    meeting: {
      id: '24524513',
      password: 'EW543G5CW3',
      link: 'https://zoom.us/j/94018678647?pwd=Mm8wSkRNcHZDaDJGM1duQ1pNaWx2QT09',
    },
    resources: ['Assignment about Neglomania', 'Homework'],
  };

  return (
    <PageContent>
      <div
        className={`md:grid  bg-gray-200 grid-cols-12 w-full min-h-screen-sec max-h-myscreen-sec md:max-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-9`}>
          <FeatureTitle name="Past Paper" />
          <GoBackButton
            link="/student/features/pastpapers"
            action="Back"
            location="Exam Title"
          />
          <SectionTitle name={myClass.subject} color="blue" />
          <CardTitle name={myClass.unit} />
          <CardBody name={myClass.summary} />
          <CardTitle name="Time and Date" />
          <div className="p-3">
            <div className="flex items-center space-x-2">
              <FaRegClock />
              <CardBody name="From:" />
              <CardBody name={myClass.time.from} />
              <CardBody name="To:" />
              <CardBody name={myClass.time.to} />
            </div>
            <div className="flex items-center space-x-2">
              <BsCalendar2 />
              <CardBody
                name={`Date: ${new Date(myClass.date).toLocaleDateString(
                  undefined,
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )}`}
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-50%">
              <CardTitle name="Time and Date" />
              {myClass.resources.map((resource, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2  cursor-pointer"
                >
                  <FaFileAlt />
                  <CardSubText
                    name={resource}
                    color="blue"
                    additional="underline"
                  />
                </div>
              ))}
            </div>
            <div className="w-50%">
              <CardTitle name={`Meeting (${myClass.happening})`} />
              <CardBody name={`ID: ${myClass.meeting.id}`} />
              <CardBody name={`Password: ${myClass.meeting.password}`} />
              <CardBody name={`LINK: ${myClass.meeting.link}`} />
              <Button
                name="Join"
                outline="true"
                color="blue"
                clicked={() => {
                  window.location.href = myClass.meeting.link;
                }}
              />
            </div>
          </div>
        </div>
        <div className={`p-3 col-span-3`}>
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default Myclass;
