import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getTestMapsStart = () => {
  return {
    type: actionTypes.GET_PANDA_TEST_MAPS_START,
  };
};

export const getTestMapsSuccess = (testmaps) => {
  return {
    type: actionTypes.GET_PANDA_TEST_MAPS_SUCCESS,
    testmaps: testmaps,
  };
};

export const getOneTestMapsSuccess = (testmap) => {
  return {
    type: actionTypes.GET_ONE_PANDA_TEST_MAPS_SUCCESS,
    testmap: testmap,
  };
};

export const getTestMapsFail = (error) => {
  return {
    type: actionTypes.GET_PANDA_TEST_MAPS_FAIL,
    error: error,
  };
};

export const getTestMaps = () => {
  return (dispatch) => {
    dispatch(getTestMapsStart());
    axios
      .get(`/opanda/panda-test-maps`)
      .then((response) => {
        dispatch(getTestMapsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestMapsFail(err.message));
      });
  };
};

export const getOneTestMaps = (testId) => {
  return (dispatch) => {
    dispatch(getTestMapsStart());
    axios
      .get(`/opanda/panda-test-maps/test/${testId}`)
      .then((response) => {
        dispatch(getOneTestMapsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestMapsFail(err.message));
      });
  };
};

export const createTestMaps = (object, token) => {
  return (dispatch) => {
    dispatch(getTestMapsStart());
    axios
      .post(`/opanda/panda-test-maps/create-test`, object, {
        headers: { Authorization: token }
      })
      .then((response) => {
        dispatch(getOneTestMapsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestMapsFail(err.message));
      });
  };
};

export const getSubjectLevelTestMaps = (subject, level) => {
  return (dispatch) => {
    dispatch(getTestMapsStart());
    axios
      .get(`/opanda/panda-test-maps/subject/${subject}/level/${level}`)
      .then((response) => {
        const data = response.data.results[0];
        dispatch(getTestMapsSuccess(data[0][1]));
      })
      .catch((err) => {
        dispatch(getTestMapsFail(err.message));
      });
  };
};
