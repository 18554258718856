import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WhoisitforCard } from "../../../elements/card";
import * as actions from "../../../../store/actions/";
import Spinner from "../../../elements/spinner";

// Import Swiper React components

const PageContent = (props) => {
  const dispatch = useDispatch();
  const whoisitfor = useSelector((state) => state.home.whoisopandafor);
  const whoisitforLoading = useSelector(
    (state) => state.home.whoisopandaforLoading
  );
  useEffect(() => {
    dispatch(actions.getHomeWhoIsOpandaFor());
  }, []);
  return (
    <div className="bg-white my-6">
      {whoisitforLoading ? (
        <div className="flex flex-row justify-center w-full">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <div className="max-w-7xl w-full mx-auto px-8">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 mx-auto">
            {whoisitfor?.map((whoisitfor, index) => (
              <WhoisitforCard
                key={index}
                user={whoisitfor?.usertype}
                body={whoisitfor?.valuepropositiontotheuser}
                benefitlist={whoisitfor?.benefitslist?.split(",")}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default PageContent;
