import React from 'react';
import parse from "react-html-parser";
import axios from "../../../../axios-base";
import { useSelector } from 'react-redux';
import Tests_users_item from './Tests_users_item';

const Tests_users = (props) => {
  const userAuth = useSelector((state) => state.auth);


  const extractOpenEndedQuestions = (questionsPool, answerSheet, userId, metadata) => {
    const answersList = answerSheet.split("~~#~#~~");
    const questionsIds_userAnswers = answersList.map((item) => {
      const q = item.split("#~#");
      if (q[0].includes("open_ended")) {
        return { question_id: q[1], useranswer: q[3], user_id: userId }
      } else {
        return null;
      }
    }).filter((item) => item !== null);
    const questionsData_Marks_Ids = questionsPool.map((item) => {
      return { question_id: item.panda_open_ended_2020_03_id, marks: item.panda_open_ended_2020_03_marks, question: item.panda_open_ended_2020_03_question_data }
    });





    return <div style={{ borderRadius: 3, borderBottom: "2px solid #cdcdcd", minWidth: "150px", color: "#333" }}>{questionsData_Marks_Ids.map((item) => {
      return <div style={{ borderRadius: "5px", borderTop: "3px solid #333", borderLeft: "3px solid #333" }}>Question: <div style={{ background: "#cdcdcd", borderRadius: 3, margin: "5px", borderBottom: "2px solid #cdcdcd", borderLeft: "2px solid #cdcdcd", borderTop: "2px solid #cdcdcd", minWidth: "150px" }}>{parse(item.question)}</div><div>
        <h6>User's answer:</h6>{questionsIds_userAnswers.map((item2) => {
          if (parseInt(item2.question_id) === parseInt(item.question_id)) {
            return <><div style={{ background: "#cdcdcd", borderRadius: 3, margin: "5px", borderBottom: "2px solid #cdcdcd", borderLeft: "2px solid #cdcdcd", borderTop: "2px solid #cdcdcd", minWidth: "150px" }}>{parse(item2.useranswer)}
              <div style={{ borderRadius: 3, borderBottom: "2px solid #cdcdcd" }}><Tests_users_item key={item.question_id} setUserAnswersLoading={props.setUserAnswersLoading} marks={item.marks} question_id={item2.question_id} test_id={props.selectedTestId} user_id={userId} test_signature={metadata} /></div></div></>
          } else {
            return "";
          }
        })}
      </div></div>
    })}</div>
  }


  return (
    <div className="overflow-x-auto relative">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
              User names
            </th>
            <th scope="col" className="py-3 px-6">
              User's marks(Corrected by opanda system)
            </th>
            <th scope="col" className="py-3 px-6">
              Total marks of the test
            </th>
            <th scope="col" className="py-3 px-6">
              User type
            </th>
            <th scope="col" className="py-3 px-6">
              Question/User's answer(Corrected manually by teacher):
            </th>
          </tr>
        </thead>
        <tbody>
          {props.usersAnswers.map((item, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="py-4 px-6">{item.names}</td>
              <td className="py-4 px-6">{item.marks}</td>
              <td className="py-4 px-6">{item.totalMarks}</td>
              <td className="py-4 px-6">{item.userType}</td>
              <td className="py-4 px-6">{extractOpenEndedQuestions(item.testMetadata, item.answerSheet, item.user_id, item.metadata)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tests_users;
