export const PAYMENT_ACTION_START = 'PAYMENT_ACTION_START';
export const PAYMENT_ACTION_CLICKED = 'PAYMENT_ACTION_CLICKED';

export const paymentStart = () => {
  return {
    type: PAYMENT_ACTION_START,
  };
};

export const paymentClicked = (actionName) => {
  return {
    type: PAYMENT_ACTION_CLICKED,
    action: actionName,
  };
};

export const completePayment = (actionName) => {
  return (dispatch) => {
    dispatch(paymentStart());
    setTimeout(() => {
      dispatch(paymentClicked(actionName));
    }, 1000); //wait for 1 second
  };
};
