import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonWithIcon,
  TextButton,
} from "../../../../elements/button";
// import ical2json from "ical2json";

import countryOptions from "../../../../../utils/countries";
import Input from "../../../../elements/input";
import Spinner from "../../../../elements/spinner";
import { CardBody, CardTitle } from "../../../../elements/text";
import ParentInfo from "../../../landing/completeprofile/parentInfo";
import { Provinces, Districts, Sectors } from "rwanda";
import { completeSignup } from "../../../../../store/actions";
import * as actions from "../../../../../store/actions/";
import { MdAttachment, MdPlusOne } from "react-icons/md";
import { BsFileMinus, BsPlus, BsPlusCircle } from "react-icons/bs";
import parse from "react-html-parser";
import { BiMinus } from "react-icons/bi";
import Checkbox from "../../../../elements/checkbox";

const EventCreationForm = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const user = useSelector((state) => state.auth.user);
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventtype, setEventtype] = useState("class");
  const [description, setDescription] = useState("");
  const [classname, setclassname] = useState("");
  const [subject, setSubject] = useState("");
  const [unit, setUnit] = useState("");
  const [online, setOnline] = useState(false);

  const onOnlineChange = () => {
    setOnline(!online);
  };

  const onEventChange = () => {
    setEventtype(eventtype);
  };
  const [resourceName, setResourceName] = useState("");

  const [meetingPassword, setMeetingPassword] = useState("");
  const [meetingCode, setMeetingCode] = useState("");
  const [meetingURL, setMeetingURL] = useState("");

  const [resourceInputList, setResourceInputList] = useState([]);

  const onAddChild = (event) => {
    setResourceInputList(
      resourceInputList.concat([
        <ResourceInputElement key={resourceInputList.length} />,
      ])
    );
  };
  const onRemoveChild = (event) => {
    resourceInputList.pop();
    setResourceInputList(resourceInputList);
  };

  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Create event" color="blue" />
          <div className="md:grid grid-cols-2 gap-2">
            <Input
              label="Event type"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "class", displayValue: "Class session" },
                  { value: "custom", displayValue: "Custom" },
                ],
              }}
              value={eventtype}
              changed={setEventtype}
              validation={{ required: false }}
              error="Event type is required"
            />
            <Input
              label="Title"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Event title",
              }}
              value={title}
              changed={setTitle}
              validation={{ required: true }}
              shouldValidate
              error="Event title is required"
            />
          </div>
          <div
            className={`${
              eventtype === "class" ? "md:grid" : "hidden"
            } grid-cols-2 gap-2`}
          >
            <Input
              label="Class level"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "s1a", displayValue: "S1 A" },
                  { value: "s2b", displayValue: "S2 B" },
                ],
              }}
              value={classname}
              changed={setclassname}
              validation={{ required: false }}
              error="Class is required"
            />
            <Input
              label="Subject"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "biology", displayValue: "Biology" },
                  { value: "chemistry", displayValue: "Chemistry" },
                ],
              }}
              value={subject}
              changed={setSubject}
              validation={{ required: false }}
              error="Subject is required"
            />

            <Input
              label="Unit"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "1", displayValue: "Unit 1: sfsdf" },
                  { value: "2", displayValue: "Unit 2: sfsdf" },
                ],
              }}
              value={unit}
              changed={setUnit}
              validation={{ required: false }}
              error="Unit is required"
            />
          </div>
          <div className="md:grid grid-cols-2 gap-2">
            <Input
              label="From"
              elementType="input"
              elementConfig={{
                type: "date",
                placeholder: "dd-mm-yyyy",
                max: new Date(),
              }}
              value={startDate}
              changed={setStartDate}
              validation={{ required: true, olderThan10: true }}
              shouldValidate
              error="Date of Birth is required"
            />
            <Input
              label="To"
              elementType="input"
              elementConfig={{
                type: "date",
                placeholder: "dd-mm-yyyy",
                max: new Date(),
              }}
              value={endDate}
              changed={setEndDate}
              validation={{ required: true, olderThan10: true }}
              shouldValidate
              error="Date of Birth is required"
            />
            <Input
              label="Description"
              elementType="textarea"
              value={description}
              changed={setDescription}
            />

            <Checkbox
              label="The session shall be conducted online"
              elementType="checkbox"
              value={online}
              onChange={onOnlineChange}
            />
          </div>
          <div className={`${online ? "md:grid" : "hidden"} grid-cols-1`}>
            <CardTitle name="Meeting link" color="blue" alignment="left" />
            <div className="md:grid grid-cols-2 gap-2">
              <Input
                label="Meeting code"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter code",
                }}
                value={meetingCode}
                changed={setMeetingCode}
                validation={{ required: false }}
              />
              <Input
                label="Meeting password"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter code",
                }}
                value={meetingPassword}
                changed={setMeetingPassword}
                validation={{ required: false }}
              />
              <Input
                label="Meeting URL"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter URL",
                }}
                value={meetingURL}
                changed={setMeetingURL}
                validation={{ required: false }}
              />
            </div>
          </div>
          <CardTitle name="Resources" color="blue" alignment="left" />
          <div className="md:grid grid-cols-1" id="resourceDiv">
            {resourceInputList}
          </div>
          <TextButton
            name="+ Add a resource"
            additional="float-right"
            color="blue"
            clicked={() => {
              onAddChild();
            }}
          />
          {/* {resourceInputList.length > 0 ? (
            <ButtonWithIcon
              name=""
              outline="true"
              color="blue"
              onClick={() => {
                onRemoveChild();
              }}
            >
              <BiMinus size={26} />
            </ButtonWithIcon>
          ) : (
            <></>
          )} */}
          <div className="md:hidden">
            <div className="flex space-x-2"></div>
            <div className="flex space-x-2"></div>
          </div>
          <div className="hidden md:grid grid-cols-3 gap-2">
            <div></div>
          </div>
          <div className="flex space-x-2 justify-center py-5">
            <Button name="Cancel" outline="true" color="blue" />
            <Button name="Save" outline="false" color="blue" />
          </div>
        </div>
      )}
    </>
  );
};

export default EventCreationForm;

const ResourceInputElement = (props) => {
  const [name, setName] = useState("");
  const [file, setFile] = useState();

  return (
    <div className="md:grid grid-cols-2 gap-2">
      <Input
        label="Resource name"
        elementType="input"
        elementConfig={{
          type: "text",
          placeholder: "Enter name",
        }}
        value={name}
        changed={setName}
      />
      <div className="flex flex-col justify-end">
        <Input
          label="Attach resource"
          elementType="input"
          elementConfig={{
            type: "file",
            placeholder: "Attach",
          }}
          value={file}
          changed={setFile}
        />
        {/* <ButtonWithIcon
          name="Attach"
          outline="true"
          color="blue"
          onClick={() => {}}
        >
          <MdAttachment size={26} />
        </ButtonWithIcon> */}
      </div>
    </div>
  );
};

export const CalendarImportForm = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const user = useSelector((state) => state.auth.user);
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventtype, setEventtype] = useState("class");
  const [description, setDescription] = useState("");
  const [classname, setclassname] = useState("");
  const [subject, setSubject] = useState("");
  const [unit, setUnit] = useState("");
  const [online, setOnline] = useState(false);

  const onOnlineChange = () => {
    setOnline(!online);
  };

  const onEventChange = () => {
    setEventtype(eventtype);
  };
  const [resourceName, setResourceName] = useState("");

  const [meetingPassword, setMeetingPassword] = useState("");
  const [meetingCode, setMeetingCode] = useState("");
  const [meetingURL, setMeetingURL] = useState("");

  const [resourceInputList, setResourceInputList] = useState([]);

  const onAddChild = (event) => {
    setResourceInputList(
      resourceInputList.concat([
        <ResourceInputElement key={resourceInputList.length} />,
      ])
    );
  };
  const onRemoveChild = (event) => {
    resourceInputList.pop();
    setResourceInputList(resourceInputList);
  };

  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Create event" color="blue" />
          <div className="md:grid grid-cols-2 gap-2">
            <Input
              label="Event type"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "class", displayValue: "Class session" },
                  { value: "custom", displayValue: "Custom" },
                ],
              }}
              value={eventtype}
              changed={setEventtype}
              validation={{ required: false }}
              error="Event type is required"
            />
            <Input
              label="Title"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Event title",
              }}
              value={title}
              changed={setTitle}
              validation={{ required: true }}
              shouldValidate
              error="Event title is required"
            />
          </div>
          <div
            className={`${
              eventtype === "class" ? "md:grid" : "hidden"
            } grid-cols-2 gap-2`}
          >
            <Input
              label="Class level"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "s1a", displayValue: "S1 A" },
                  { value: "s2b", displayValue: "S2 B" },
                ],
              }}
              value={classname}
              changed={setclassname}
              validation={{ required: false }}
              error="Class is required"
            />
            <Input
              label="Subject"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "biology", displayValue: "Biology" },
                  { value: "chemistry", displayValue: "Chemistry" },
                ],
              }}
              value={subject}
              changed={setSubject}
              validation={{ required: false }}
              error="Subject is required"
            />

            <Input
              label="Unit"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "1", displayValue: "Unit 1: sfsdf" },
                  { value: "2", displayValue: "Unit 2: sfsdf" },
                ],
              }}
              value={unit}
              changed={setUnit}
              validation={{ required: false }}
              error="Unit is required"
            />
          </div>
          <div className="md:grid grid-cols-2 gap-2">
            <Input
              label="From"
              elementType="input"
              elementConfig={{
                type: "date",
                placeholder: "dd-mm-yyyy",
                max: new Date(),
              }}
              value={startDate}
              changed={setStartDate}
              validation={{ required: true, olderThan10: true }}
              shouldValidate
              error="Date of Birth is required"
            />
            <Input
              label="To"
              elementType="input"
              elementConfig={{
                type: "date",
                placeholder: "dd-mm-yyyy",
                max: new Date(),
              }}
              value={endDate}
              changed={setEndDate}
              validation={{ required: true, olderThan10: true }}
              shouldValidate
              error="Date of Birth is required"
            />
            <Input
              label="Description"
              elementType="textarea"
              value={description}
              changed={setDescription}
            />

            <Checkbox
              label="The session shall be conducted online"
              elementType="checkbox"
              value={online}
              onChange={onOnlineChange}
            />
          </div>
          <div className={`${online ? "md:grid" : "hidden"} grid-cols-1`}>
            <CardTitle name="Meeting link" color="blue" alignment="left" />
            <div className="md:grid grid-cols-2 gap-2">
              <Input
                label="Meeting code"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter code",
                }}
                value={meetingCode}
                changed={setMeetingCode}
                validation={{ required: false }}
              />
              <Input
                label="Meeting password"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter code",
                }}
                value={meetingPassword}
                changed={setMeetingPassword}
                validation={{ required: false }}
              />
              <Input
                label="Meeting URL"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter URL",
                }}
                value={meetingURL}
                changed={setMeetingURL}
                validation={{ required: false }}
              />
            </div>
          </div>
          <CardTitle name="Resources" color="blue" alignment="left" />
          <div className="md:grid grid-cols-1" id="resourceDiv">
            {resourceInputList}
          </div>
          <TextButton
            name="+ Add a resource"
            additional="float-right"
            color="blue"
            clicked={() => {
              onAddChild();
            }}
          />
          {/* {resourceInputList.length > 0 ? (
            <ButtonWithIcon
              name=""
              outline="true"
              color="blue"
              onClick={() => {
                onRemoveChild();
              }}
            >
              <BiMinus size={26} />
            </ButtonWithIcon>
          ) : (
            <></>
          )} */}
          <div className="md:hidden">
            <div className="flex space-x-2"></div>
            <div className="flex space-x-2"></div>
          </div>
          <div className="hidden md:grid grid-cols-3 gap-2">
            <div></div>
          </div>
          <div className="flex space-x-2 justify-center py-5">
            <Button name="Cancel" outline="true" color="blue" />
            <Button name="Save" outline="false" color="blue" />
          </div>
        </div>
      )}
    </>
  );
};
