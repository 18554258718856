import { ContentContainer } from "../content/container.js";
import { CardBody, CardTitle } from "../../elements/text.js";
import { bring_subject_image_src } from "../../../functions/programs";
const adjust_extra_resources=(data)=>{
  const data_new=[];
  for(var i=0;i<data?.length;i++){
    data_new.push({
      title: data[i].title,
      unit: data[i].unit,
      imgsrc: bring_subject_image_src(data[i].subject).imgsrc,
      createdAt: data[i].createdAt,
      type: data[i].type,
      watched: data[i].watched,
    });
  }

  return data_new;

}

const EndOfResources = (props) => {
  /*const relatedMaterial = [
    {
      title: "Human Skeleton",
      unit: "1",
      imgsrc: "/images/preview/bio.svg",
      createdAt: "2022-03-29",
      type: "Simulation",
      watched: true,
    },
    {
      title: "Math",
      unit: "1",
      imgsrc: "/images/preview/math.svg",
      createdAt: "2022-02-27",
      type: "Test",
      watched: false,
    },
    {
      title: "Officia elit duis",
      unit: "1",
      imgsrc: "/images/preview/math.svg",
      createdAt: "2022-02-27",
      type: "Video",
      watched: true,
    },
  ];
   
  console.log("@333##",props.otherresources);*/
  const relatedMaterial=[...adjust_extra_resources(props.otherresources)];

  return (
    <div>
      <CardTitle name="End Of Resources" color="blue" />
      <CardBody name="" />
      <ContentContainer
        data={relatedMaterial}
        slider={true}
        type="endofrsc"
        page="endofrsc"
      />
    </div>
  );
};

export default EndOfResources;
