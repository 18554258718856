import { Fragment } from 'react';
import Backdrop from '../backdrop';

export const DynamicModal = (props) => {
  let classes =
    'fixed bg-white border border-solid border-gray-500 shadow p-4 box-border transition-all duration-300 ease-out rounded-3xl py-20';
  if (props.show) {
    classes =
      'flex flex-col justify-center items-center w-90% fixed z-50 bg-white border border-solid border-gray-500 shadow p-4 box-border transition-all duration-300 ease-out rounded-3xl py-20';
  }
  return (
    <Fragment>
      <Backdrop show={props.show} clicked={props.modalClosed} />
      <div
        className={classes + ' hidden md:block'}
        style={{
          top: props.top,
          left: props.left,
          right: props.right,
          width: props.width,
          height: props.height,
          transform: props.show ? 'translate(0,0)' : 'translate(-1000%,-1000%)',
          opacity: props.show ? '1' : '0',
        }}
      >
        {props.children}
      </div>
      <div
        className={classes + 'm-auto md:hidden'}
        style={{
          top: props.top,
          transform: props.show ? 'translate(0,0)' : 'translate(-1000%,-1000%)',
          opacity: props.show ? '1' : '0',
        }}
      >
        {props.children}
      </div>
    </Fragment>
  );
};
