export const singleanswer = "singleanswer";
export const multianswer = "multianswer";
export const fillin = "fillin";
export const matching = "matching";
export const multiplechoice = "multiplechoice";
export const openended = "openended";
export const draganddrop = "draganddrop";
export const completesentence = "completesentence";
export const ONE = 1;
export const ZERO = 0;
export const TWO = 2;
export const responseSymbol = "#RESPONSE#";
export const responseSeparator = "#SEPARATOR#";
export const singleQuestionIdAnswerIdSep = "~~~~";
export const machbydropping = "MACH BY DROPPING";
export const oneEmptySpace = " ";

export function responseComponent(arrayOfAnswers, oquestionId, questiontype) {
  var givenAnswers = [];
  var answers = document
    .getElementById(oquestionId)
    .getElementsByClassName("answers");
  for (var i = 0; i < answers.length; i++) {
    if (questiontype == fillin) {
      
      givenAnswers.push(answers[i].value);
    } else if (questiontype == matching) {
    
      givenAnswers.push(answers[i].innerText);
    }
  }
  var result = {
    givenAnswers,
    marks: comparaArays(arrayOfAnswers, givenAnswers),
    arrayOfAnswers,
  };
  return result;
}

export function multiplechoiceResponseSingle(
  answer,
  givenAnswer,
  questionid,
  questionmarks
) {
  // making a string that has question id, delimeter and answerId  `questId~~~~ansId`
  var responsedata = questionid + singleQuestionIdAnswerIdSep + givenAnswer;
  var marks = questionid + "~~" + questionmarks;
  var result = {};
  result = {
    responsedata,
    marks,
    questiontype: multiplechoice,
    numberofanswers: singleanswer,
  };
  return result;
}

export function multiplechoiceResponseMany(
  answers,
  givenanswers,
  questionid,
  questionmarks
) {
  // making a string that has question id, delimeter and answerIds  `questId~~~~ansId`
  var data = [];
  givenanswers.forEach((element) => {
    data.push(element + "##-#" + questionid);
  });
  var responsedata = data.join("~~~~cyuma");
  // console.log("TESTING:");
  var result = {};
  var marks = questionid + "~~" + questionmarks;

  result = {
    givenanswers,
    responsedata,
    // marks: `${marks}/${answers.length}`,
    marks,
    questiontype: multiplechoice,
    numberofanswers: multianswer,
  };
  return result;
}

export function matchingResponse(givenanswer, questionword, questId) {
  // making a string that has number, given answer, delimeter and questionId  `1~#~#~^--givenanswer~#~#~^--questionword`
  var responsedata = "";
  if (givenanswer != machbydropping) {
    responsedata =
      1 +
      "~#~#~^--" +
      givenanswer +
      "~#~#~^--" +
      questionword +
      "~#~#~^--~#~#~^--" +
      questId;
  }
  return responsedata;
}

export function fillinResponse(givenanswer, questId) {
  // making a string that has number, given answer, delimeter and questionId  `1~#~#~^--givenanswer~#~#~^--questionword`
  var responsedata = "";
  if (givenanswer != machbydropping) {
    var theOtherel = Math.floor(1000 + Math.random() * 9000);
    responsedata =
      givenanswer.trim() + "-~--autoDraggga---" + theOtherel + "-~--" + questId;
  }
  return responsedata;
}

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

function comparaArays(array, array2) {
  var marks = array2.length;
  if (!array || !array2) return 0;

  if (array2.length != array.length) return 0;

  for (var i = 0; i < array2.length; i++) {
    if (array2[i].toUpperCase() != array[i].toUpperCase()) {
      marks--;
    }
  }
  return `${marks}/${array2.length}`;
}

function comparaAraysMultipleChoice(array, array2) {
  var marks = array2.length;
  if (!array || !array2) return 0;

  if (array2.length != array.length) return 0;

  for (var i = 0; i < array2.length; i++) {
    if (array2[i].toUpperCase() != array[i].toUpperCase()) {
      marks--;
    }
  }
  return `${marks}/${array2.length}`;
}

export const multiple_choice = "multiple_choice";
export const complete_sentence = "complete_sentence";
export const drag_and_drop = "drag_and_drop";
export const open_ended = "open_ended";
export const questiontypes = [
  {
    type: multiple_choice,
    name: "Multiple Choice",
  },
  { type: complete_sentence, name: "Complete Sentence" },
  { type: open_ended, name: "Open Ended" },
  { type: drag_and_drop, name: "Drag and Drop" },
];

export default {
  singleanswer,
  multianswer,
  responseComponent,
  multiplechoiceResponseSingle,
  multiplechoiceResponseMany,
  matchingResponse,
  fillinResponse,
  fillin,
  matching,
  multiplechoice,
  openended,
  ONE,
  ZERO,
  TWO,
  responseSymbol,
  responseSeparator,
  singleQuestionIdAnswerIdSep,
  machbydropping,
  oneEmptySpace,
  questiontypes,
  multiple_choice,
  complete_sentence,
  drag_and_drop,
  open_ended,
};
