import parse from 'react-html-parser';
import CompleteSentenceItem from './CompleteSentenceItem';

const CompleteSentence = (props) => {
  const questiondata = props?.questiondata?.question.panda_fill_in_2020_03_question_data;
  const answers =
    props?.questiondata?.question.panda_fill_in_2020_03_question_data_answers;
  let answersArray = [];
  let regWord;
  if (answers !== undefined) {
    answersArray = answers.split('~----~');
    //regWord = new RegExp(answersArray.join('|'), 'i');
    //console.log("answersArray",answersArray);
    regWord = answersArray;
  }

  let displayabledata;
  let questionparts = [];
  const data =
    questiondata !== undefined
      ? (questiondata)
      : ('<p></p>').toString();
  if (data !== undefined) {
    const quest = data;
    questionparts = quest.split(' ');
  }

  const matchTexts = (arr, two) => {
    let decision = false;
    for (var n = 0; n < arr.length; n++) {
      const one = (arr[n]).trim()
      if ((one).toLowerCase() === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || (one).toLowerCase() + "." === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || (one).toLowerCase() + "!" === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || (one).toLowerCase() + "?" === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || (one).toLowerCase() + "," === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || (one).toLowerCase() + ";" === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "")) {
        decision = true;
      }
    }
    return [decision, (two).trim()]
  }
  const getSanitizedQuestion = (questionparts) => {
    let counter1 = -1;
    const all_special_components = [];
    const dataIn = [];

    for (var i = 0; i < questionparts.length; i++) {
      if (matchTexts(regWord, questionparts[i])[0]) {
        counter1 = (counter1 + 1);
      }

      /*<CompleteSentenceResponceCard
          key={i}
          listofanswers={answersArray[counter1]}
          index={counter1}
          questionId={questionId}
        />*/
      dataIn.push(questionparts[i]);
      questionparts[i] = matchTexts(regWord, questionparts[i])[0] ? (
        `<mark ondrop="drop(event)" ondragover="allowDrop(event)"  style='background:#03b2cb;padding:2px;border-radius:6px;white-space: nowrap;'><span  id=word--${i}>......................</span></mark> `
      ) : (
        ((questionparts[i]).trim()) + " "
      );

      all_special_components.push((matchTexts(regWord, questionparts[i])[0] ? (
        <mark ondrop='drop(event)' ondragover='allowDrop(event)' style='background:#03b2cb;padding:2px;border-radius:6px;white-space: nowrap;'><span id={`word--${i}`}>......................</span></mark>
      ) : (
        ((questionparts[i]).trim()) + " "
      )))
    }
    var tobeparsed = "";
    for (var n = 0; n < questionparts.length; n++) {
      tobeparsed = tobeparsed + questionparts[n];
    }
    tobeparsed = (`${tobeparsed}`);
    let spaceNber = 0;
    for (var i = 0; i < all_special_components.length; i++) {
      const item = all_special_components[i];
      if (typeof item === 'string' && item.includes("<p>")) {
        all_special_components[i] = <span> &nbsp;{(item.replace("<p>", ""))}&nbsp;</span>
      } else if (typeof item === 'string' && item.includes("</p>")) {
        all_special_components[i] = <span>&nbsp;{item.replace("</p>", "")}&nbsp;</span>
      } else if (typeof item === 'string' && !item.includes("<mark")) {
        all_special_components[i] = <span>&nbsp;{item}&nbsp;</span>
      } else {
        spaceNber++;
        all_special_components[i] = <CompleteSentenceItem answer_by_user={props.answer_by_user} dataIn={dataIn[i]} spaceNber={spaceNber} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} />;
      }
    }
    return all_special_components;
  }
  return (
    <>
      <label>
        {getSanitizedQuestion(questionparts)}
      </label>
    </>
  );
};

export default CompleteSentence;
