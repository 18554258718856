import { useDispatch, useSelector } from 'react-redux';
import { NavList, ParentNavList } from '../../elements/sidebar/auth/Navlist';
import { NavSubItem } from '../../elements/sidebar/auth/NavSubItem';
import { TabPanel, useTabs } from 'react-headless-tabs';
import { BiBook } from 'react-icons/bi';
import { FaDesktop, FaRegUser } from 'react-icons/fa';
import { AiOutlineWechat } from 'react-icons/ai';
import { HiUserGroup } from 'react-icons/hi';
import { MdPayment } from 'react-icons/md';
import { MdOutlineForum, MdOutlineSpaceDashboard } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import {
  changeStudentDashboardMobileVisibility,
  changeStudentDashboardtab,
  changeStudentDashboardMenu,
  changeSideBarCollapseState
} from '../../../store/actions/sidebaractiontypes';
import Spinner from '../../elements/spinner';
import { getPrograms } from '../../../functions/programs';
import { student_programs_start, student_student_programs_cbc_start } from '../../../store/actions/student.dashboard.programs';

export const tabs = [
  {
    name: 'Dashboard',
    tab: 'dashboard',
    icon: <MdOutlineSpaceDashboard color="#183F71" size="24" />,
    mainPanel: 'dashboard',
    subTabs: [],
    mainPath: '/parent/',
  },
  {
    name: 'Marketplace',
    tab: 'marketplace',
    icon: <BiBook color="#183F71" size="24" />,
    mainPanel: 'marketplace',
    mainPath: '/parent/marketplace',
    subTabs: [
      {
        name: 'Extra Curricular',
        tabName: 'extraCurricular',
        path: '/parent/marketplace/extracurricular/',
      },
      {
        name: 'Content',
        tabName: 'content',
        path: '/parent/marketplace/content',
      },
      {
        name: 'Caoching',
        tabName: 'coaching',
        path: '/parent/marketplace/coaching',
      },
      {
        name: 'Partner services',
        tabName: 'partnerServices',
        path: '/parent/marketplace/partnerservices',
      },
      {
        name: 'Workshops',
        tabName: 'workshops',
        path: '/parent/marketplace/workshops',
      },
      {
        name: 'Opportunities',
        tabName: 'opportunities',
        path: '/parent/marketplace/opportunities',
      },
      {
        name: 'Books',
        tabName: 'books',
        path: '/parent/marketplace/books',
      },
    ],
  },
  {
    name: 'Communication',
    tab: 'communication',
    icon: <AiOutlineWechat color="#183F71" size="24" />,
    mainPanel: 'Communication',
    mainPath: '/parent/communication/',
    subTabs: [],
  },
  {
    name: 'Community',
    tab: 'community',
    icon: <HiUserGroup color="#183F71" size="24" />,
    mainPanel: 'community',
    mainPath: '/parent/community',
    subTabs: [],
  },
  {
    name: 'Renew payment',
    tab: 'renewPayment',
    icon: <MdPayment color="#183F71" size="24" />,
    mainPanel: 'community',
    mainPath: '/parent/renewpayment',
    subTabs: [],
  },
 
  {
    name: 'User',
    tab: 'user',
    icon: <FaRegUser color="#183F71" size="24" />,
    mainPanel: 'profile',
    mainPath: '/parent/user/profile',
    subTabs: [
      { name: 'Profile', 
      tabName: 'profile', 
      path: '/parent/user/profile' 
    },
      { name: 'Settings', 
      tabName: 'settings', 
      path: '/parent/user/settings' 
    },
    ],
  },
];

const SideBar = function (props) {
  const studentdashboardMenuselected = useSelector(
    (state) => state.reducer.studentdashboardMenuselected
  );
  const [selectedTab, setSelectedTab] = useTabs(
    ['dashboard', 'marketplace', 'communication', 'community', 'renewPayment', 'user'],
    studentdashboardMenuselected
  );
  const userAth = useSelector((state) => state.auth);
  const studentDashboardPrograms = useSelector(
    (state) => state.studentDashboardPrograms
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const changetab = (tab) => {
    dispatch(changeStudentDashboardtab(tab));
  };
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );

  useEffect(() => {
    dispatch(
      student_programs_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
      })
    );
  }, []);
  //---Update the data of programs from the backend---
  const usertype = useSelector((state)=>state.auth.user.user_type);
  // tabs[1].subTabs = [...getPrograms(studentDashboardPrograms.programs, usertype)];
  
  return (
    <div className="w-full relative bg-gray-700 shadow h-full flex-col justify-between flex overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      <div className={`mt-10 ${isSidebarCollapsed ? '' : 'px-1 md:px-2 lg:px-8'} h-full`}>
        {(!studentDashboardPrograms.programs_loading) ? tabs.map((item, index) => (
          <ParentNavList
            key={index}
            name={item.name}
            color="white"
            isActive={selectedTab === item.tab}
            icon={item.icon}
            subTabs={item.subTabs}
            onClick={() => {
              dispatch(changeStudentDashboardMenu(item.tab));
              dispatch(changeSideBarCollapseState(false));
              dispatch(changeStudentDashboardMobileVisibility('hidden'));
              setSelectedTab(item.tab);
              changetab(item.mainPanel);
              if (item.subTabs.length === 0) {
                history.push(item.mainPath);
              }
            }}
          >
            <TabPanel
              hidden={selectedTab !== item.tab}
              className="transform transition duration-500 ease-in-out"
            >
              {item.subTabs.map((tab, index) => (
                <NavSubItem
                  key={index}
                  name={tab.name}
                  tab={tab.tabName}
                  onClick={() => {
                    changetab(tab.tabName);
                    dispatch(changeStudentDashboardMobileVisibility('hidden'));
                    history.push(tab.path); 
                  }}
                />
              ))}
            </TabPanel>
          </ParentNavList>
        )) : <Spinner size="20" color="blue" />}
      </div>
    </div>
  );
};
export default SideBar;
