import React from 'react'
import Layout from '../../../components/common/Layout';
import { ExtraCurricular } from '../../../components/pages/parent/marketplace';


const ExtraCurricularContainer = () => {
  return (
  <Layout>
    <ExtraCurricular />
  </Layout>
  )
}

export default ExtraCurricularContainer