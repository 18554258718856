import TabSelector from "./TabSelector";
import { useTabs, TabPanel } from "react-headless-tabs";
import Card from "./Card";
import { useHistory } from "react-router-dom";
import { bring_subject_image_src } from "../../../functions/programs";

const VideosAndSimulations = (props) => {
  const [selectedTab, setSelectedTab] = useTabs(props.tabs, props.tab);
  const adjust_extra_resources = (data) => {
    const data_new = [];
    for (var i = 0; i < data.length; i++) {
      data_new.push({
        title: data[i].title,
        unit: data[i].unit,
        imgsrc: bring_subject_image_src(data[i].subject).imgsrc,
        createdAt: data[i].createdAt,
        type: data[i].type,
        watched: data[i].watched,
        metadata: data[i]
      });
    }

    return data_new;

  }
  const videos = [...adjust_extra_resources(props.otherresources)];


  return (
    <div className="p-3 mb-8 absolute top-0 bottom-14 md:bottom-0 left-0 md:-left-72 w-full md:w-72 bg-gray-200 border-r border-l border-gray-500 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      <nav className="flex justify-between md:justify-start border-b border-gray-500">
        {props.tabs.map((tab, index) => (
          <TabSelector
            key={index}
            isActive={selectedTab === tab}
            title={tab}
            onClick={() => setSelectedTab(tab)}
          />
        ))}
      </nav>
      <div className="h-full flex flex-col justify-between">
        <div className="">
          <TabPanel hidden={selectedTab !== "Videos"}>
            {videos.map((video, index) => (
              (video.type === "Video") ? <Card
                key={index}
                {...video}
                type="Video"
              /> : <div></div>
            ))}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "Simulations"}>
            {videos.map((video, index) => (
              (video.type === "Simulation") ? <Card
                key={index}
                {...video}
                type="Simulation"
              /> : <div></div>
            ))}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "Documents"}>
            {videos.map((video, index) => (
              (video.type === "Document") ? <Card key={index} {...video} type="Document" /> : <div></div>
            ))}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "Quiz"}>
            {videos.map((video, index) => (
              (video.type === "Test") ? <Card key={index} {...video} type="Quiz" /> : <div></div>
            ))}
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default VideosAndSimulations;
