import { SectionTitle } from "../../../elements/text";
import EventItem from "./eventitem";
import date from 'date-and-time';

const ScheduleList = (props) => {
  const events = props.event;
  let html_ = events.map((event, index) => (
    <>
      {events[index].from !== events[index - 1]?.from ? (
        <SectionTitle name={event.from} color="blue" additional="col-span-2 md:col-span-3" />
      ) : (
        " "
      )}
      <EventItem key={index} {...event} />
    </>
  ));
  return (
    <div className="flex flex-col w-full py-3 h-full max-h-full overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
    <div className="grid grid-cols-2 md:grid-cols-3 gap-2">{html_}</div>
    </div>
  );
};

export default ScheduleList;
