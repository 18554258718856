import { useEffect, useState } from "react";
import { SectionTitle, CardBody, SmallText } from "../../../elements/text";
import Input from "../../../elements/input";
import { Button } from "../../../elements/button";
import { OtherLinkButton } from "../../../elements/button";
import { useHistory } from "react-router-dom";
import student from "../../../../images/signup.png";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/";
import { Message } from "../../../common/messages";
import Spinner from "../../../elements/spinner";

const Recoverpasswordquestionform = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [questionOne, setQuestionOne] = useState("");
  const [questionTwo, setQuestionTwo] = useState("");
  const [questionThree, setQuestionThree] = useState("");
  const [answerOne, setanswerOne] = useState("");
  const [answerTwo, setanswerTwo] = useState("");
  const [answerThree, setanswerThree] = useState("");
  const [email, setEmail] = useState("");

  const [recoveryQuestionsChecked, setRecoveryQuestionsChecked] = useState(false); 

  const recoveryquestions = useSelector(
    (state) => state.auth.recoveryquestions
  );
  const recoveryquestionsLoading = useSelector(
    (state) => state.auth.recoveryquestionsLoading
  );
  const auth = useSelector((state) => state.auth.user);
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);

  const submitpasswordrestrequest = () => {
    const obj = {
      email,
      questionOne,
      questionThree,
      questionTwo,
      answerOne,
      answerThree,
      answerTwo,
    };
    setRecoveryQuestionsChecked(true);
    dispatch(actions.passwordResetRequestWIthQuestions(obj));
  };

  useEffect(() => {
    dispatch(actions.getRecoveryQuestions());
    if (auth !== null) {
      history.push("/reset");
    }
  }, [auth]);

  return (
    <div>
      <div className="hidden md:block text-center w-30% m-auto">
        <SectionTitle name="Recover Account" color="blue" />
        <CardBody
          name="Answer these security questions to recover your account"
          color="blue"
        />
      </div>
      <div className="flex md:h-60vh justify-center items-center md:space-x-10 md:m-20 xl:m-40">
        <div className="md:w-50% md:h-full hidden md:block">
          <img
            className="w-full h-full object-cover rounded-3xl"
            src={student}
            alt=""
          />
        </div>
        {loading ? (
          <Spinner size="32" color="blue" />
        ) : (
          <div className="md:w-50% md:h-full md:flex flex-col justify-around bg-gray-200 rounded-3xl text-center m-4 md:m-auto p-4">
            <div className="md:hidden">
              <SectionTitle name="Recover Account" color="blue" />
              <CardBody
                name="Answer these security questions to recover your account"
                color=""
              />
            </div>
            <div className="py-3">
              <Input
                elementType="input"
                elementConfig={{
                  type: "email",
                  placeholder: "Email (example@email.com)",
                }}
                value={email}
                changed={setEmail}
                validation={{ isEmail: true }}
                shouldValidate
                error="Valid email is required"
              />
              {recoveryquestions?.length > 0 ? <Input
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [recoveryquestions[0]],
                }}
                value={questionOne}
                changed={setQuestionOne}
              /> : ""}
              <Input
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Answer here",
                }}
                value={answerOne}
                changed={setanswerOne}
              />
              {recoveryquestions?.length > 0 ? <Input
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [recoveryquestions[1]],
                }}
                value={questionTwo}
                changed={setQuestionTwo}
              /> : ""}
              <Input
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Answer here",
                }}
                value={answerTwo}
                changed={setanswerTwo}
              />
              {recoveryquestions?.length > 0 ? <Input
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [recoveryquestions[2]],
                }}
                value={questionThree}
                changed={setQuestionThree}
              /> : ""}
              <Input
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Answer here",
                }}
                value={answerThree}
                changed={setanswerThree}
              />
            </div>
            <Message type={"error"} viewable={true} message={(recoveryQuestionsChecked && !auth)?"User not found, Invalid credentials!!!":""} />
            <div className="py-3">
              <Button
                name="Reset"
                outline="true"
                color="blue"
                clicked={submitpasswordrestrequest}
              />
            </div>
            <div className="flex justify-center py-3">
              <SmallText
                name="This method not working for you?&nbsp;"
                color="black"
                alignment="center"
              />
              <OtherLinkButton
                color="blue"
                to="/forgot"
                name="Try another method."
                size="xs"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Recoverpasswordquestionform;
