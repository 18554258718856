import { TabIcon } from "../../../elements/tab/tabicon";
import axios from "../../../../axios-base";
// import { TabContent, TabContentItem } from "../../../elements/tab/tabcontent";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../pricing/Tabselector";
import { PricingCard } from "../pricing/pricingcard";
import { PricingCardGroup } from "../pricing/pricingcardgroup";
import {
  AnchorButton,
  Button,
  ButtonLink,
  ButtonWithIcon,
  TextButton,
} from "../../../elements/button";
import PrevNext from "./PrevNext";
import Modal from "../../../elements/modal/modal";
import { useSelector, useDispatch } from "react-redux";
import {
  changePaymentModalState,
  setChosenPackage,
} from "../../../../store/actions/payment";
import { CardSubText, CardTitle, SectionTitle } from "../../../elements/text";
import { CardBody, SmallText } from "./../../../elements/text";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as actions from "../../../../store/actions/";
import * as functions from "../../../../functions/commonfunctions";
import Spinner from "../../../elements/spinner";
import Otp from "./otp";
import { Message } from "../../../common/messages";
import { verifySchoolOTPStart } from "../../../../store/actions/token";
import SchoolSelect from "./schoolselect";
import Input from "../../../elements/input";
import { authSuccess, getOneUsersSuccess } from "../../../../store/actions/auth";
import constants from "../../../../constants/constants";

const Pricing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [showSponsorshipModal, setShowSponsorshipModal] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otperror, setotperror] = useState("");

  const loadtoken = useSelector((state) => state.usertoken);
  const userAuth = useSelector((state) => state.auth);

  const token = useSelector((state) => state.auth.token)
  // const userAuth = useSelector((state) => state.auth);
  const { tokenLoading, status } = loadtoken;
  const [userRadio, setUserRadio] = useState("school");
  const user = useSelector((state) => state.auth.user);
  const [schoolId, setSchoolId] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const changeOtpInputHandler = (event, index) => {
    // if (isNaN(event.target.value)) return false;
    setOtp([
      ...otp.map((num, idx) => (idx === index ? event.target.value : num)),
    ]);
    if (event.target.nextSibling) {
      event.target.nextSibling.focus();
    }
  };

  function verification() {
    // console.log(otp,"otp");
    const otpjoin = otp.join("");
    // console.log(otpjoin, "otp");
    // console.log(userAuth.user.id, "params");
    // console.log("schoolId", schoolId)
    // compare otp with school token
    if (otpjoin === "" || otpjoin.length < 6 || otpjoin === "000000") {
      setotperror(`The OTP code is incorrect`);
      return "";
    }
    if (schoolId === "") {
      setotperror(`Please select a school`);
      return "";
    }
    setIsVerifying(true);
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/opanda/profile-information/user-profile/validateSchoolSponsorship`,
      { user_type: "student", userId: userAuth.user.id, otpjoin: otpjoin, schoolId: schoolId }, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.data.message === "Success") {
          alert("Your token has been verified successfully");
          setIsVerifying(false);
          // Dispatch auth success 
          dispatch(authSuccess(response.data.results))
          if(response.data.results.user?.isprofilecompleted === 1){
            history.push("/student");
          }else{
            history.push("/complete");
          }
          

        } else {
          alert(response.data.message)
          setIsVerifying(false);
        }
      })
      .catch((err) => {
        alert(err.message)
        setIsVerifying(false);

      });
    //dispatch(verifySchoolOTPStart({ id: userAuth.user.id, token_id: otpjoin, token: token }));


  }
  useEffect(() => {
    //----
    if (loadtoken.status === 1) {
      // Login user
      history.push("/payment/payment");
    } else {
      //setotperror(`The OTP code is incorrect`);
      //   alert(`The OTP code is incorrect`);
    }

  }, [history, loadtoken.status]);

  const [selectedTab, setSelectedTab] = useTabs([
    "student",
    "teacher",
    "parent",
    "school",
    //"creator",
  ]);

  const showPaymentModal = useSelector(
    (state) => state.payment.showPaymentModal
  );
  const chosenPackage = useSelector((state) => state.payment.chosenPackage);
  const chosenProgram = useSelector((state) => state.payment.chosenProgram);
  const whoisitfor = useSelector((state) => state.home.whoisopandafor);
  const whoisitforLoading = useSelector(
    (state) => state.home.whoisopandaforLoading
  );
  let studentspackages = [];
  let parentspackages = [];
  let teacherspackages = [];
  let schoolspackages = [];
  for (var i = 0; i < whoisitfor?.length; i++) {
    switch (whoisitfor[i]?.usertype) {
      case "student":
        studentspackages = whoisitfor[i]?.userspricings;
        break;
      case "teacher":
        teacherspackages = whoisitfor[i]?.userspricings;
        break;
      case "parent":
        parentspackages = whoisitfor[i]?.userspricings;
        break;
      case "school":
        schoolspackages = whoisitfor[i]?.userspricings;
        break;
      default:
        break;
    }
  }
  const isAuthenticated = useSelector((state) => state.auth.token != null);

  useEffect(() => {
    //----
    if (user !== null) {
      if (user?.paymentvalid !== true) {

      } else if (user?.paymentvalid === true) {
        if (user?.user_type === constants.studentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/student");
          } else {
            history.push("/complete");
          }
          if (user?.isprofilecompleted === 1) {
            history.push("/login");
          } else {
            history.push("/parent/complete/profile");
          }
        } else if (user?.user_type === constants.teacherusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/login");
          } else {
            history.push("/teacher/user/profile");
          }
        } else if (user?.user_type === constants.schoolusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/login");
          } else {
            history.push("/school/complete/profile");
          }
        } else if (user?.user_type === constants.parentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/login");
          } else {
            history.push("/parent/complete/profile");
          }
        }
      }

      //-----
      dispatch(actions.getHomeWhoIsOpandaFor());
      if (user.user_type == null) {
        axios
          .post(`/opanda/payment-call-backs/setusertype`, { user_type: "student", userId: userAuth.user.id }, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((response) => {
            dispatch(authSuccess(response.data.results))
            setSelectedTab("student")
          })
          .catch((err) => {

          });
      } else {
        setSelectedTab(user.user_type)
      }
    } else {
      history.push("/");
    }
    if (params.userId) {
      dispatch(actions.socialLogin(params.userId));
      if (isAuthenticated) {
        history.push("/payment/payment");
      }
    }

  }, []);
  const onchangevalueHandler = (event) => {
    setUserRadio(event.target.value);
  };

  return (
    <div className="h-full bg-gray-200 pb-5">
      <Modal
        medium
        show={showPaymentModal}
        modalClosed={() => {
          dispatch(setChosenPackage(null));
          dispatch(changePaymentModalState(false));
        }}
      >
        <div className="w-80% m-auto py-5">
          <SectionTitle name="Here's what you selected" alignment="center" />
          <div className="flex justify-between items-center py-3">
            <div className="text-center">
              <CardTitle name={chosenProgram} color="blue" />
              <div className="flex space-x-2">
                <CardTitle name={`Package ${chosenPackage?.number} `} />
                <CardBody name={chosenPackage?.pricingname} />
              </div>
              <div className="flex space-x-2">
                <CardTitle
                  name={`${functions.numberWithCommas(
                    chosenPackage?.pricingamount
                  )} ${chosenPackage?.pricingcurrency?.toUpperCase()}`}
                />
                <CardBody name={chosenPackage?.pricingfrequency} />
              </div>
            </div>
            <div className="flex flex-col">
              {chosenPackage?.pricingfeatures
                ?.split(",")
                .map((feature, index) => (
                  <SmallText name={feature} color="blue" key={index} />
                ))}
            </div>
          </div>
          <div className="flex justify-around">
            <Button
              name="Cancel"
              outline="true"
              color="blue"
              clicked={() => {
                dispatch(setChosenPackage(null));
                dispatch(changePaymentModalState(false));
              }}
            />
            <Button
              name="Continue"
              outline="false"
              color="blue"
              clicked={() => history.push("/payment/options")}
            />
          </div>
        </div>
      </Modal>
      <Modal
        medium
        show={showSponsorshipModal}
        modalClosed={() => {
          setShowSponsorshipModal(false);
        }}
      >
        <div style={{
          height: '80vh',
          overflowY: 'auto', padding: "10px", zIndex: 1000
        }} className="w-100% m-auto py-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
          <SectionTitle name="Verify your OTP" alignment="center" />
          <div className="flex flex-col justify-start items-center py-1">
            <CardBody name="Are you sponsored by your school or parent, choose one here below:" />
          </div>
          {/* <div
            className={`w-full box-border mt-1 space-x-2 flex flex-row justify-around align-center`}
          >
            <div
              className={`w-full box-border mt-2 space-x-2 flex flex-row justify-center align-start`}
            >
              <input
                type="radio"
                value="school"
                name="School"
                checked={userRadio === "school"}
                onChange={onchangevalueHandler}
              />
              <CardSubText name={`School`} color="black" />
            </div>
            <div
              className={`w-full box-border mt-2 space-x-2 flex flex-row justify-center align-start`}
            >
              <input
                type="radio"
                value="parent"
                name="Parent"
                checked={userRadio === "parent"}
                onChange={onchangevalueHandler}
              />
              <CardSubText name={`Parent`} color="black" />
            </div>
          </div> */}
          <div className="flex justify-around">

            {userRadio === "parent" ? <></> : <SchoolSelect setSchoolId={setSchoolId} />}
          </div>
          <div className="flex flex-col py-1 justify-around items-center p-10">
            <div className="flex flex-col justify-start items-center ">
              <CardBody name={`Ask your ${userRadio === "parent" ? "parent" : "school administrator"} for the OTP(One Time Password) and type it down here:`} />
            </div>
            <Otp otp={otp} changeOtpInputHandler={changeOtpInputHandler} />
            <Message type={"error"} viewable={true} message={otperror} />
          </div>
          <div className="w-full flex justify-center py-3">
            {(!isVerifying) ? <Button
              name="Verify"
              outline="true"
              color="blue"
              clicked={() => {
                verification();
                // history.push("");
              }}
            /> : <Spinner size="10" color="blue" />}
          </div>
        </div>
      </Modal>
      <Modal
        medium
        show={showTrialModal}
        modalClosed={() => {
          setShowTrialModal(false);
        }}
      >
        <div className="w-80% m-auto py-5">
          <SectionTitle
            name="What is included in the free trial?"
            alignment="center"
          />
          <div className="flex flex-col justify-start items-center py-3">
            <CardTitle name={`Sample content`} />
            <CardBody name="We want to you to be able to have a feel of the experience that our platform offers. Some features you might see in the free trial are: Lab simulations, summary notes, tests and quizzes, and a community. You won't be able to add users to your platform, but you can preview all the functionalities with dummy databse. " />
            <CardTitle name={`Payment portal`} />
            <CardBody name="You will be able to purchase your subscription in your dashboard through the payment modal." />
          </div>
          <div className="flex justify-around">
            <Button
              name="Continue"
              outline="false"
              color="blue"
              clicked={() => history.push("/payment/options")}
            />
          </div>
        </div>
      </Modal>
      {/* <PrevNext
        showProgram
        px="5"
        previousLink="verify"
        previousName="Verification"
        pageName={`${selectedTab} Payment`}
        nextLink="payment/options"
        nextName="Payment Options"
      /> */}
      <nav className="flex justify-center overflow-y-auto scrollbar-hidden p-10">
        <TabSelector
          isActive={selectedTab === "student"}
          onClick={() => {
            if (window.confirm(`Are you sure you want to register as student`)) {
            } else {
              return;
            }
            axios
              .post(`/opanda/payment-call-backs/setusertype`, { user_type: "student", userId: userAuth.user.id }, {
                headers: {
                  authorization: `Bearer ${token}`
                }
              })
              .then((response) => {
                dispatch(authSuccess(response.data.results))
                setSelectedTab("student")
              })
              .catch((err) => {

              });

          }}
          tab="green"
        >
          <TabIcon>Student</TabIcon>
        </TabSelector>
        <TabSelector
          isActive={selectedTab === "teacher"}
          onClick={() => {
            if (window.confirm(`Are you sure you want to register as a teacher`)) {
            } else {
              return;
            }
            axios
              .post(`/opanda/payment-call-backs/setusertype`, { user_type: "teacher", userId: userAuth.user.id }, {
                headers: {
                  authorization: `Bearer ${token}`
                }
              })
              .then((response) => {
                dispatch(authSuccess(response.data.results))
                setSelectedTab("teacher")
                setTimeout(() => {
                  if (user?.isprofilecompleted === 1) {
                    //history.push("/teacher");
                    history.push("/");
                  } else {
                    history.push("/teacher/user/profile");
                  }
                }, 1000);
              })
              .catch((err) => {

              });

          }}
          tab="yellow"
        >
          <TabIcon>Teacher</TabIcon>
        </TabSelector>
        {<TabSelector
          isActive={selectedTab === "parent"}
          onClick={() => {
            if (window.confirm(`Are you sure you want to register as a parent`)) {
            } else {
              return;
            }
            axios
              .post(`/opanda/payment-call-backs/setusertype`, { user_type: "parent", userId: userAuth.user.id }, {
                headers: {
                  authorization: `Bearer ${token}`
                }
              })
              .then((response) => {
                dispatch(authSuccess(response.data.results))
                setSelectedTab("parent")
                setTimeout(() => {
                  if (user?.isprofilecompleted === 1) {
                    history.push("/parent");
                  } else {
                    history.push("/parent/complete/profile");
                  }
                }, 1000);
              })
              .catch((err) => {

              });

          }}
          tab="gray-500"
        >
          <TabIcon>Parent</TabIcon>
        </TabSelector>}
        <TabSelector
          isActive={selectedTab === "school"}
          onClick={() => {
            if (window.confirm(`Are you sure you want to register as a school`)) {
            } else {
              return;
            }
            axios
              .post(`/opanda/payment-call-backs/setusertype`, { user_type: "school", userId: userAuth.user.id }, {
                headers: {
                  authorization: `Bearer ${token}`
                }
              })
              .then((response) => {
                dispatch(authSuccess(response.data.results))
                setSelectedTab("school")
              })
              .catch((err) => {

              });

          }}
          tab="blue"
        >
          <TabIcon>School</TabIcon>
        </TabSelector>
        {/*<TabSelector
          isActive={selectedTab === "creator"}
          onClick={() => setSelectedTab("creator")}
          tab="red"
        >
          <TabIcon>Creator</TabIcon>
            </TabSelector>*/}
      </nav>
      {whoisitforLoading ? (
        <div className="flex flex-row justify-center">
          <Spinner size="32" color="blue" />
        </div>
      ) : (
        <div className="p-4  w-screen w-96 sm:w-full overflow-x-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 p-3 bg-gray-200 p-l">
          <TabPanel hidden={selectedTab !== "student"}>
            <PricingCardGroup>
              <TextButton
                name="If sponsored by your school/Parent(Click here)"
                color="blue"
                clicked={() => setShowSponsorshipModal(true)}
              />
              <PricingCard>{studentspackages}</PricingCard>
            </PricingCardGroup>
          </TabPanel>
          <TabPanel hidden={selectedTab !== "teacher"}>
            <PricingCardGroup>
              <PricingCard>{teacherspackages}</PricingCard>
            </PricingCardGroup>
          </TabPanel>
          {<TabPanel hidden={selectedTab !== "parent"}>
            <PricingCardGroup>
              <PricingCard>{parentspackages}</PricingCard>
            </PricingCardGroup>
          </TabPanel>}
          <TabPanel hidden={selectedTab !== "school"}>
            <PricingCardGroup>
              <PricingCard>{schoolspackages}</PricingCard>
            </PricingCardGroup>
          </TabPanel>
          {/* <TabPanel hidden={selectedTab !== "creator"}>
            <PricingCardGroup>
              <PricingCard>{parentspackages}</PricingCard>
            </PricingCardGroup>
          </TabPanel> */}
        </div>
      )}
      <div className="flex justify-center">
        {/* <ButtonWithIcon
          page="Continue trial"
          outline="true"
          color="blue"
          isSmall={true}
          onClick={() => setShowTrialModal(true)}
          name="Continue free trial"
        /> */}
        {/* <ButtonLink
          page="apply-for-sponsorship"
          outline="true"
          color="red"
          isSmall={true}
          name="Apply for a sponsorship"
        /> */}
      </div>

    </div>
  );
};

export default Pricing;
