import { useRef, useState } from "react";
import TextSelector from "text-selection-react";
import { FaComments, FaLock, FaLockOpen } from "react-icons/fa";
import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { MdEditNote, MdFullscreen, MdMovie } from "react-icons/md";
import PageContent from "../pageContent";
import { CardBody } from "../../../elements/text";
import Input from "../../../elements/input";
import CommentsOnSimulations from "../../../common/features/CommentsOnSimulations";
import VideosAndSimulations from "../../../common/features/VideosAndSimulations";
import useFullscreenStatus from "../../../../shared/useFullscreenStatus";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeChosenUnitSimulations } from "../../../../store/actions/features/simulations";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  student_dashboard_homepage_simulations_documents_and_videos_start,
  student_dashboard_homepage_simulations_get_subjects_with_select_units_start,
  student_dashboard_homepage_simulations_get_subjects_with_select_units_start_with_subject_param,
  student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_start,
} from "../../../../store/actions/student.dashboard.simulations";
import { decrypt } from "../../../../functions/cyphers";
import { sortAlphaNum } from "../../../../functions/sortAlphaNumeric";
import Spinner from "../../../elements/spinner";
import { createPortal } from "react-dom";
import ReactPlayer from "react-player/lazy";
import VideosAndSimulationsSimFeature from "../../../common/features/VideosAndSimulationsSimFeature";
import { Button, GoBackButton } from "../../../elements/button";
const Simulation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [commentsCount, setcommentsCount] = useState(0);
  const [searchterm, setSearchterm] = useState("");
  const divRef = useRef();
  const [academic_level_pool, setAcademic_level_pool] = useState([
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
  ]);
  //--
  const userAuth = useSelector((state) => state.auth);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  //-----
  const studentDashboardSimulations = useSelector(
    (state) => state.studentDashboardSimulations
  );
  const [iframeUrl, setIframeUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [fullscreenOn, setFullscreenOn] = useState(false);
  const iframe = useRef(null);
  const simulation_params = useParams();
  useEffect(() => {
    setSubject(params.subject);
    setIframeUrl(decrypt("O'Genius Panda Simulations", params.url));
    dispatch(
      student_dashboard_homepage_simulations_get_subjects_with_select_units_start_with_subject_param(
        {
          token: userAuth.token,
          academic_level: userAuth.academic_level,
          user_id: userAuth.userId,
          Subject: params.subject,
        }
      )
    );
    //--Mark simulation as viewed--
    dispatch(
      student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_start({
        token: userAuth.token,
        academic_level: userAuth.academic_level,
        user_id: userAuth.userId,
        Subject: params.subject,
        simid: simulation_params.simulationId,
        unit: params.unit,
      })
    );
    //---Load videos and documents related to the simulations
    dispatch(
      student_dashboard_homepage_simulations_documents_and_videos_start({
        token: userAuth.token,
        academic_level: userAuth.academic_level,
        user_id: userAuth.userId,
        Subject: params.subject,
        simid: simulation_params.simulationId,
        unit: params.unit,
      })
    );
  }, [iframeUrl]);
  const handleFullscreen = () => {
    setFullscreenOn(true);
    divRef.current.requestFullscreen();
  };
  const comments_counter_pull = (newComments) => {
    setcommentsCount(newComments);
  };
  const handleIframeLoad = () => {
    // The iframe has finished loading
    setLoading(false);
    const iframeDocument = iframe.current.contentDocument;

    const iframeWindow = iframe.contentWindow;

    // Send a message from the web application to the iframe
    // Enable the pointer-events property on the ifra
  };
  const getFormattedSimulationsData = (sims) => {
    const newSims = sims.map((item) => {
      return {
        id: item.simid,
        title: item.name,
        imgsrc:
          process.env.REACT_APP_BACKEND_URL +
          "/simulation_thumbnails/" +
          item.thumbnail,
        createdAt: item.uploaded_at,
        url: process.env.REACT_APP_BACKEND_URL + item.link,
      };
    });

    return newSims;
  };
  const getUnitsFromSelectSubject = (unitsData) => {
    const units = [];
    if (unitsData?.data?.results.length > 0) {
      const data = unitsData?.data?.results[1];
      for (let unitdata in data) {
        const unitdata_ = data[unitdata];
        units.push({
          name: unitdata_.name,
          subject: unitdata_.subject,
          viewed: unitdata_.viewed,
          simulations: [...getFormattedSimulationsData(unitdata_.simulations)],
        });
      }
      return units;
    } else {
      return units;
    }
  };
  const getSubjects = (unitsData) => {
    const subjects = [];
    if (unitsData?.data?.results.length > 0) {
      const data = unitsData?.data?.results[0];
      for (let unitdata in data) {
        subjects.push({
          value: data[unitdata].subject,
          displayValue: data[unitdata].subject,
        });
      }
      return subjects;
    } else {
      return subjects;
    }
  };
  const getVideosAndDocs = (data) => {
    if (data?.data?.results) {
      return data?.data?.results;
    } else {
      return [];
    }
  };
  const units = [
    { name: "Deserunt laboris", viewed: true },
    { name: "Adipisicing ", viewed: false },
    { name: "Dolore minim", viewed: false },
    { name: "Dolore ex ", viewed: false },
    { name: "Sint eiusmod fugiat.", viewed: false },
    { name: "Adipisicing occaecat", viewed: false },
    { name: "Dolore minimum", viewed: false },
    { name: "Dolore ex enim", viewed: false },
  ];
  const chosenUnit = useSelector(
    (state) => state.features.simulations.chosenUnit
  );
  const [showComments, setShowComments] = useState(false);
  const [showvideos, setShowvideos] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [vidQuizDoc, setVidQuizDoc] = useState("Videos");
  const [showUnits, setShowUnits] = useState(false);
  const [subject, setSubject] = useState("Biology");

  const [isFullscreen, setIsFullscreen] = useFullscreenStatus(iframe);
  const chooseAnotherSubject = (data) => {
    dispatch(
      student_dashboard_homepage_simulations_get_subjects_with_select_units_start_with_subject_param(
        {
          token: userAuth.token,
          academic_level: userAuth.academic_level,
          user_id: userAuth.userId,
          Subject: data,
        }
      )
    );
  };

  return (
    <PageContent>
      <div
        className={`${
          showComments || showvideos ? "relative" : ""
        } md:grid grid-cols-12 w-full h-full`}
      >
        <div className="col-span-12 md:grid h-full grid-cols-12">
          <div
            className={`${
              showUnits ? "hidden md:block" : ""
            }  md:relative p-3 bg-white  h-screen-second mb-16 pb-2 md:h-full max-h-myscreen-sec min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-11 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
          >
            <TextSelector
              events={[
                {
                  text: "Comment",
                  handler: (text) => {
                    setSelectedText(text.innerHTML);
                  },
                },
              ]}
            />
            <div
              style={{ width: "100%", height: "100%", position: "relative" }}
              ref={divRef}
              onMouseDown={() => {}}
            >
              {fullscreenOn ? (
                <div style={{ float: "right" }}>
                  <Button
                    name="Exit"
                    outline="true"
                    color="red"
                    clicked={() => {
                      setFullscreenOn(false);
                      document.exitFullscreen();
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="m-3 p-3 bg-white absolute block rounded-md">
                <GoBackButton
                  link="/teacher/features/simulations"
                  action="Back"
                  location={`Simulations`}
                />
              </div>
              {loading ? <Spinner size="40" color="blue" /> : ""}
             
              <>
                <iframe
                  onLoad={handleIframeLoad}
                  allowfullscreen
                  width={"100%"}
                  style={{ display: !loading ? "block" : "none", minHeight: "100%", minWidth: "100%" }}
                  height={"100%"}
                  sandbox="allow-same-origin allow-scripts"
                  loading="eager"
                  ref={iframe}
                  
                  src={iframeUrl}
                  key={iframeUrl}
                  title="arithmetic_en"
                  frameborder="0"
                ></iframe>
              </>
            </div>
          </div>
          <div
            className={`${
              showUnits ? "block" : ""
            } fixed w-full md:w-auto md:relative p-3 bg-gray-200 bottom-0 flex flex-row md:flex-col justify-between items-center`}
          >
            <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10">
              {showUnits ? (
                <GrClose
                  className="md:hidden"
                  size={28}
                  color="#183F71"
                  onClick={() => setShowUnits(!showUnits)}
                />
              ) : (
                <GiHamburgerMenu
                  className="md:hidden"
                  size={28}
                  color="#183F71"
                  onClick={() => setShowUnits(!showUnits)}
                />
              )}
              {!showComments ? (
                <MdFullscreen
                  className="cursor-pointer"
                  color="#183F71"
                  size={36}
                  onClick={() => {
                    handleFullscreen();
                  }}
                />
              ) : (
                ""
              )}
              <div className="relative cursor-pointer">
                <FaComments
                  color="#183F71"
                  size={36}
                  onClick={() => {
                    setShowComments(!showComments);
                    setShowvideos(false);
                  }}
                />
                {commentsCount > 0 ? (
                  <span
                    style={{ fontWeight: "bold !important" }}
                    className="absolute top-1 left-5 inline-flex items-center justify-center px-1 py-px text-xs font-bold leading-none bg-white rounded-full"
                  >
                    {commentsCount}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <MdMovie
                className="cursor-pointer"
                size={36}
                color="#183F71"
                onClick={() => {
                  setVidQuizDoc("Videos");
                  setShowvideos(!showvideos);
                  setShowComments(false);
                }}
              />
              <MdEditNote
                className="cursor-pointer"
                size={36}
                color="#183F71"
                onClick={() => {
                  setVidQuizDoc("Documents");
                  setShowvideos(!showvideos);
                  setShowComments(false);
                }}
              />
            </div>
            {showComments || showvideos ? (
              <AiOutlineRightCircle
                className="cursor-pointer hidden md:block"
                size={36}
                color="#183F71"
                onClick={() => {
                  setShowComments(false);
                  setShowvideos(false);
                }}
              />
            ) : (
              <AiOutlineLeftCircle
                className="cursor-pointer hidden md:block"
                size={36}
                color="#183F71"
                onClick={() => setShowComments(true)}
              />
            )}
            {showComments ? (
              <CommentsOnSimulations
                getcomments={comments_counter_pull}
                selected={selectedText}
                setSelected={setSelectedText}
                simid={simulation_params.simulationId}
              />
            ) : null}
            {showvideos ? (
              <VideosAndSimulationsSimFeature
                tabs={["Videos", "Documents"]}
                tab={vidQuizDoc}
                otherresources={[
                  ...getVideosAndDocs(
                    studentDashboardSimulations.student_dashboard_homepage_simulations_documents_and_videos
                  ),
                ]}
              />
            ) : null}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Simulation;
