import { SectionTitle } from '../../elements/text';
import { FaSearch } from 'react-icons/fa';

const GetStarted = (props) => {
  return (
    <div className="m-auto w-50% h-40vh flex flex-col justify-around items-center text-center">
      <SectionTitle
        name={`Select ${props.type} to start browse through ${props.feature}`}
        color="blue"
      />
      <FaSearch size="100" color="#E8AD26" />
    </div>
  );
};

export default GetStarted;
