import axios from "../../axios-base";

import { getCachedData } from "../../shared/caching";
import fileDownload from "js-file-download";
export const SCHOOL_DASHBOARD_USERS_COUNT_INIT =
  "SCHOOL_DASHBOARD_USERS_COUNT_INIT";
export const SCHOOL_DASHBOARD_USERS_COUNT_SUCCESS =
  "SCHOOL_DASHBOARD_USERS_COUNT_SUCCESS";
export const SCHOOL_DASHBOARD_USERS_COUNT_FAILED =
  "SCHOOL_DASHBOARD_USERS_COUNT_FAILED";

export const SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_INIT =
  "SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_INIT";
export const SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_SUCCESS =
  "SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_SUCCESS";
export const SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_FAILED =
  "SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_FAILED";

export const SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_INIT =
  "SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_INIT";
export const SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_SUCCESS =
  "SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_SUCCESS";
export const SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_FAILED =
  "SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_FAILED";

export const SCHOOL_DASHBOARD_USERS_INIT = "SCHOOL_DASHBOARD_USERS_INIT";
export const SCHOOL_DASHBOARD_USERS_SUCCESS = "SCHOOL_DASHBOARD_USERS_SUCCESS";
export const SCHOOL_DASHBOARD_USERS_FAILED = "SCHOOL_DASHBOARD_USERS_FAILED";

export const SCHOOL_DASHBOARD_USERS_RESET_OTP_INIT =
  "SCHOOL_DASHBOARD_USERS_RESET_OTP_INIT";
export const SCHOOL_DASHBOARD_USERS_RESET_OTP_SUCCESS =
  "SCHOOL_DASHBOARD_USERS_RESET_OTP_SUCCESS";
export const SCHOOL_DASHBOARD_USERS_RESET_OTP_FAILED =
  "SCHOOL_DASHBOARD_USERS_RESET_OTP_FAILED";

export const SCHOOL_DASHBOARD_USERS_DELETE_INIT =
  "SCHOOL_DASHBOARD_USERS_DELETE_INIT";
export const SCHOOL_DASHBOARD_USERS_DELETE_SUCCESS =
  "SCHOOL_DASHBOARD_USERS_DELETE_SUCCESS";
export const SCHOOL_DASHBOARD_USERS_DELETE_FAILED =
  "SCHOOL_DASHBOARD_USERS_DELETE_FAILED";

export const SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT =
  "SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT";
export const SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS =
  "SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS";
export const SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED =
  "SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED";

export const SCHOOL_DASHBOARD_CREATE_USER_INIT =
  "SCHOOL_DASHBOARD_CREATE_USER_INIT";
export const SCHOOL_DASHBOARD_CREATE_USER_SUCCESS =
  "SCHOOL_DASHBOARD_CREATE_USER_SUCCESS";
export const SCHOOL_DASHBOARD_CREATE_USER_FAILED =
  "SCHOOL_DASHBOARD_CREATE_USER_FAILED";

export function school_dashboard_users_count_init() {
  return {
    type: SCHOOL_DASHBOARD_USERS_COUNT_INIT,
  };
}
export function school_dashboard_users_count_success(data) {
  return {
    type: SCHOOL_DASHBOARD_USERS_COUNT_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_users_count_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_USERS_COUNT_FAILED,
    payload: error,
  };
}
export function school_dashboard_users_count_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_users_count_init());
    axios
      .get(
        `/opanda/manage-users/findCount?id=${data.school_id}&status=active`,

        {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(school_dashboard_users_count_success(response.data.results));
      })
      .catch((err) => {
        dispatch(school_dashboard_users_count_failed(err.message));
      });
  };
}



export function school_dashboard_download_template_init() {
  return {
    type: SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_INIT,
  };
}
export function school_dashboard_download_template_success(data) {
  return {
    type: SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_download_template_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_FAILED,
    payload: error,
  };
}

export function school_dashboard_download_template_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_download_template_init());
    axios
      .get(`/opanda/manage-users/download-template/${(data?.isForStudentsOnly) ? 1 : 0}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "blob",
        },
        responseType: "blob",
      })
      .then((response) => {
        if (data?.isForStudentsOnly == true) {
          const outputFilename = "template_for_students.xlsx";
          fileDownload(response.data, outputFilename);
          dispatch(school_dashboard_download_template_success(outputFilename));
        } else if (data?.isForStudentsOnly == false) {
          const outputFilename = "template_for_teachers_or_parents.xlsx";
          fileDownload(response.data, outputFilename);
          dispatch(school_dashboard_download_template_success(outputFilename));
        }
      })
      .catch((err) => {
        dispatch(school_dashboard_download_template_failed(err.message));
      });
  };
}

export function school_dashboard_upload_template_init() {
  return {
    type: SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_INIT,
  };
}
export function school_dashboard_upload_template_success(data) {
  return {
    type: SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_upload_template_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_UPLOAD_TEMPLATE_FAILED,
    payload: error,
  };
}

export function school_dashboard_upload_template_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_upload_template_init());
    var data_ = new FormData();
    data_.append("excel", data.excel, data.excel.name);
    data_.append("packageId", data.packageId);
    data_.append("userId", data.userId);

    var config = {
      method: "post",
      url: `/opanda/manage-users/importexcel`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${data.token}`,
      },
      data: data_,
    };

    axios(config)
      .then(function (response) {
        dispatch(school_dashboard_upload_template_success(response));
        dispatch(school_dashboard_users_start({ school_id: data.userId, token: data.token }));
        dispatch(school_dashboard_users_count_start({ school_id: data.userId, token: data.token }));
      })
      .catch(function (error) {
        dispatch(school_dashboard_upload_template_failed(error.message));
      });
  };
}

export function school_dashboard_users_init() {
  return {
    type: SCHOOL_DASHBOARD_USERS_INIT,
  };
}
export function school_dashboard_users_success(data) {
  return {
    type: SCHOOL_DASHBOARD_USERS_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_users_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_USERS_FAILED,
    payload: error,
  };
}

export function school_dashboard_users_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_users_init());
    if (data?.recentUsers) {
      axios
        .get(
          `/opanda/manage-users/findBySchoolRecentUsers?id=${data.school_id}&status=active`,

          {
            headers: {
              Authorization: `Bearer ${data.token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {

          dispatch(school_dashboard_users_success(response.data.results));
        })
        .catch((err) => {
          dispatch(school_dashboard_users_failed(err.message));
        });
    } else {
      axios
        .get(
          `/opanda/manage-users/findBySchool?id=${data.school_id}&status=active`,

          {
            headers: {
              Authorization: `Bearer ${data.token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {

          dispatch(school_dashboard_users_success(response.data.results));
        })
        .catch((err) => {
          dispatch(school_dashboard_users_failed(err.message));
        });
    }

  };
}


export function school_dashboard_users_reset_otp_init() {
  return {
    type: SCHOOL_DASHBOARD_USERS_RESET_OTP_INIT,
  };
}
export function school_dashboard_users_reset_otp_success(data) {
  return {
    type: SCHOOL_DASHBOARD_USERS_RESET_OTP_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_users_reset_otp_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_USERS_RESET_OTP_FAILED,
    payload: error,
  };
}

export function school_dashboard_users_reset_otp_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_users_reset_otp_init());
    axios
      .get(
        `/opanda/manage-users/resetotp/${data.id}`,

        {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(
          school_dashboard_users_reset_otp_success(response.data.results)
        );
        dispatch(school_dashboard_users_start({ school_id: data.school_id, token: data.token }));
        dispatch(school_dashboard_users_count_start({ school_id: data.school_id, token: data.token }));
      })
      .catch((err) => {
        dispatch(school_dashboard_users_reset_otp_failed(err.message));
      });
  };
}

export function school_dashboard_users_delete_init() {
  return {
    type: SCHOOL_DASHBOARD_USERS_DELETE_INIT,
  };
}
export function school_dashboard_users_delete_success(data) {
  return {
    type: SCHOOL_DASHBOARD_USERS_DELETE_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_users_delete_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_USERS_DELETE_FAILED,
    payload: error,
  };
}

export function school_dashboard_users_delete_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_users_delete_init());
    axios
      .delete(
        `/opanda/manage-users/delete/${data.id}`,

        {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(school_dashboard_users_delete_success(response.data.results));
        dispatch(school_dashboard_users_start({ school_id: data.school_id, token: data.token }));
        dispatch(school_dashboard_users_count_start({ school_id: data.school_id, token: data.token }));
      })
      .catch((err) => {
        dispatch(school_dashboard_users_delete_failed(err.message));
      });
  };
}

export function school_users_lastweek_sessionduration_init() {
  return {
    type: SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT,
  };
}
export function school_users_lastweek_sessionduration_success(data) {
  return {
    type: SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS,
    payload: data,
  };
}
export function school_users_lastweek_sessionduration_failed(error) {
  return {
    type: SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED,
    payload: error,
  };
}

export function school_users_lastweek_sessionduration_start(data) {
  return (dispatch) => {
    dispatch(school_users_lastweek_sessionduration_init());

    axios
      .post(`/opanda/student/lastweeksessionduration`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(
          school_users_lastweek_sessionduration_success(response.data.results)
        );
      })
      .catch((err) => {
        dispatch(school_users_lastweek_sessionduration_failed(err.message));
      });
  };
}

export function school_dashboard_create_user_init() {
  return {
    type: SCHOOL_DASHBOARD_CREATE_USER_INIT,
  };
}
export function school_dashboard_create_user_success(data) {
  return {
    type: SCHOOL_DASHBOARD_CREATE_USER_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_create_user_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_CREATE_USER_FAILED,
    payload: error,
  };
}

export function school_dashboard_create_user_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_create_user_init());
    axios
      .post(
        `/opanda/manage-users/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(school_dashboard_create_user_success(response.data.results));
        dispatch(school_dashboard_users_start({ school_id: data.user_id, token: data.token }));
        dispatch(school_dashboard_users_count_start({ school_id: data.user_id, token: data.token }));
      })
      .catch((err) => {
        dispatch(school_dashboard_create_user_failed(err.message));
      });
  };
}
