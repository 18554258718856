import Header from '../components/common/header/header';
import Footer from '../components/common/footer';
import Verify from '../components/pages/landing/forgotpassword/verify';
import ScrollToTop from '../components/common/ScrollToTop';

const Forgotpasswordverify = () => {
  return (
    <div>
      <Header />
      <Verify />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Forgotpasswordverify;
