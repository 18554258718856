import Input from "../../../elements/input";
import { CardTitle } from "../../../elements/text";
import {
  MdOutlineAddCircleOutline,
  MdOutlineRemoveCircleOutline,
} from "react-icons/md";
import { forwardRef, useRef, useState, useImperativeHandle, useEffect } from "react";

const ParentInfo = forwardRef((props, ref) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [name2, setName2] = useState("");
  const [phone2, setPhone2] = useState("");
  const [showSecondParents, setShowSecondParents] = useState(false);

  let guardianNames = "";
  let guardianPhones = "";
  if (name2.length > 3) {
    guardianNames = name + "," + name2;
  } else {
    guardianNames = name;
  }
  if (phone2.length > 5) {
    guardianPhones = phone + "," + phone2;
  } else {
    guardianPhones = phone;
  }

  useImperativeHandle(ref, () => ({
    guardianNames,
    guardianPhones,
  }));
  useEffect(() => {
    setPhone((props?.guardian_tel && !props?.guardian_tel?.includes("phone")) ? props?.guardian_tel : "");
    setName((!props?.guardian_name?.includes("DEFAULT")) ? props?.guardian_name : "");
  }, []);

  return (
    <div className="pt-5">
      <CardTitle name="Parents/Guardians" color="black" />
      <div className="md:flex space-x-2">
        <Input
          label="Parent's/Guardian's name"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Name",
          }}
          value={name}
          changed={setName}
          validation={{ required: true }}
          shouldValidate
          error="Parent's/Guardian's name is required"
        />
        <Input
          label="Parent's/Guardian's Number"
          elementType="phone"
          value={phone}
          changed={setPhone}
        />
      </div>
      <div className={showSecondParents ? "block md:flex space-x-2" : "hidden"}>
        <Input
          label="Second Parent's/Guardian's name"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Name",
          }}
          value={name2}
          changed={setName2}
          validation={{ required: true }}
          shouldValidate
          error="Second Parent's/Guardian's name is required"
        />
        <Input
          label="Second Parent's/Guardian's Number"
          elementType="phone"
          value={phone2}
          changed={setPhone2}
        />
      </div>
      {/* <div
        className="flex items-center justify-end my-2 cursor-pointer"
        onClick={() => setShowSecondParents(!showSecondParents)}
      >
        <CardTitle
          name={
            showSecondParents
              ? "Remove another parent/guardian"
              : "Add another parent/guardian"
          }
          color="black"
        />
        {showSecondParents ? (
          <MdOutlineRemoveCircleOutline />
        ) : (
          <MdOutlineAddCircleOutline />
        )}
      </div> */}
    </div>
  );
});

export default ParentInfo;
