import React, { useRef } from 'react';
import { SectionTitle, CardBody, SmallText } from "../../../elements/text";
import { FcGoogle } from "react-icons/fc";
import { BsTwitter, BsFacebook, BsEye, BsEyeSlash } from "react-icons/bs";
import Input from "../../../elements/input";
import { useEffect, useState } from "react";
import { Button } from "../../../elements/button";
import { useHistory } from "react-router-dom";
import { OtherLinkButton } from "../../../elements/button";
import student from "../../../../images/signup.png";
import * as actions from "../../../../store/actions/";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as constants from "../../../../constants/constants";
import { Message } from "../../../common/messages";
import { store } from "../../../../store/store";
import Spinner from "../../../elements/spinner";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin, GoogleLogin, GoogleOAuthProvider, googleLogout, hasGrantedAllScopesGoogle, hasGrantedAnyScopeGoogle, useGoogleOneTapLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode'
import axios from "../../../../axios-base";
import dotenv from "dotenv";
const { OAuth2Client } = require('google-auth-library')
dotenv.config();


export const LoginSection = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const dispatch = useDispatch();
  const login = (e) => {
    e.preventDefault();
    dispatch(actions.login(email, password));
  };
  const loginemail = useRef(null);


  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const user_id = useSelector((state) => state.auth.userId);
  const error = useSelector((state) => state.auth.error);

  const loading = useSelector((state) => state.auth.loading);
  const handleFacebookLogin = (response) => {

    if (response?.name) {

    } else {
      return;
    }
    const object = {
      names: response.name,
      username: response.email,
      email: response.email
    }
    dispatch(actions.login(response.email, "", true));
  };
  googleLogout();
  const MyGoogleButton = ({ onClick }) => {
    return (
      <span onClick={onClick}><FcGoogle size="30" /></span>
    );
  }
  const getDecodedOAuthJwtGoogle = async token => {

    const CLIENT_ID_GOOGLE = '470934482733-ifgjmq4jhh7smrk9erfos8crirq9juh8.apps.googleusercontent.com'

    try {
      const client = new OAuth2Client(CLIENT_ID_GOOGLE)

      const ticket = await client.verifyIdToken({
        idToken: token,
        audience: CLIENT_ID_GOOGLE,
      })

      return ticket
    } catch (error) {
      return { status: 500, data: error }
    }
  }
  const responseGoogle = async (response) => {
    //const { email, givenName, familyName, imageUrl } = response?.profileObj;
    if (response.credential != null) {
      const USER_CREDENTIAL = await jwtDecode(response.credential);
      const object = {
        names: USER_CREDENTIAL.given_name,
        username: USER_CREDENTIAL.given_name,
        email: USER_CREDENTIAL.email
      }
      dispatch(actions.login(USER_CREDENTIAL.email, "", true));
    }

  }
  const renderFacebookButton = ({ onClick, disabled }) => (
    <span onClick={onClick}><BsFacebook size="30" color="#3b5998" /></span>
  );


  useEffect(() => {
    if (user !== null) {
      if (user?.paymentvalid !== true) {
        if (user?.user_type === constants.teacherusertype) {
          if (user?.isprofilecompleted === 1) {
            //---
            // axios
            //   .post(
            //     `/opanda/register-login-token`,
            //     {
            //       email: user.email,
            //       user_id: user_id
            //     },
            //     {
            //       headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": `Bearer ${token}`
            //       }
            //     }
            //   )
            //   .then((response) => {
            //     window.location.replace(`${process.env.REACT_APP_V2_URL}/login_panda_user_with_token.php?id=${response.data.data.url_token}`);

            //   })
            //   .catch((err) => {
            //   });
            history.push("/teacher");
          } else {
            history.push("/teacher/user/profile");
          }
        } else if (user?.user_type === constants.parentusertype) {
          if (user?.isprofilecompleted === 1) {
            axios
              .post(
                `/opanda/register-login-token`,
                {
                  email: user.email,
                  user_id: user_id
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  }
                }
              )
              .then((response) => {
                window.location.replace(`${process.env.REACT_APP_V2_URL}/login_panda_user_with_token.php?id=${response.data.data.url_token}`);
              })
              .catch((err) => {
              });
            //history.push("/parent");
          } else {
            history.push("/parent/complete/profile");
          }
        } else {
          history.push("/payment/payment");
        }
      } else if (user?.paymentvalid === true) {
        if (user?.user_type === constants.studentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/student");
          } else {
            history.push("/complete");
          }
        } else if (user?.user_type === constants.teacherusertype) {
          if (user?.isprofilecompleted === 1) {
            // axios
            //   .post(
            //     `/opanda/register-login-token`,
            //     {
            //       email: user.email,
            //       user_id: user_id
            //     },
            //     {
            //       headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": `Bearer ${token}`
            //       }
            //     }
            //   )
            //   .then((response) => {
            //     window.location.replace(`${process.env.REACT_APP_V2_URL}/login_panda_user_with_token.php?id=${response.data.data.url_token}`);
            //   })
            //   .catch((err) => {
            //   });
            history.push("/teacher");
          } else {
            history.push("/teacher/user/profile");
          }
        } else if (user?.user_type === constants.schoolusertype) {
          if (user?.paymentvalid === true) {
            if (user?.isprofilecompleted === 1) {
              // axios
              //   .post(
              //     `/opanda/register-login-token`,
              //     {
              //       email: user.email,
              //       user_id: user_id
              //     },
              //     {
              //       headers: {
              //         "Content-Type": "application/json",
              //         "Authorization": `Bearer ${token}`
              //       }
              //     }
              //   )
              //   .then((response) => {
              //     window.location.replace(`${process.env.REACT_APP_V2_URL}/login_panda_user_with_token.php?id=${response.data.data.url_token}`);
              //   })
              //   .catch((err) => {
              //   });

              history.push("/school");
            } else {
              history.push("/school/complete/profile");
            }
          }else{
            history.push("/payment/payment");

          }
        } else if (user?.user_type === constants.parentusertype) {
          if (user?.isprofilecompleted === 1) {
            axios
              .post(
                `/opanda/register-login-token`,
                {
                  email: user.email,
                  user_id: user_id
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  }
                }
              )
              .then((response) => {
                window.location.replace(`${process.env.REACT_APP_V2_URL}/login_panda_user_with_token.php?id=${response.data.data.url_token}`);
              })
              .catch((err) => {
              });
            history.push("/parent");
          } else {
            history.push("/parent/complete/profile");
          }
        }
      }
    }
  }, [user]);

  return (
    <div id="login" className="">
      <div className="flex justify-center space-x-4">
        <SectionTitle name={t("Logging in as:")} color="blue" />
        <SectionTitle name={props.userType} color="green" />
      </div>
      <div id="loginView" className="flex md:h-60vh justify-center items-center md:space-x-10 md:m-20 xl:m-40">
        <div className="md:w-50% md:h-full hidden md:block">
          <img
            className="w-full h-full object-cover rounded-3xl"
            src={student}
            alt=""
          />
        </div>
        <>
          {loading ? (
            <Spinner size="32" color="blue" />
          ) : (
            <form
              onSubmit={login}
              className="md:w-50% md:h-full md:flex flex-col justify-around bg-gray-200 rounded-3xl text-center m-4 md:m-auto p-4"
            >
              <div className="flex flex-col md:flex-col-reverse justify-between">
                <div className="flex justify-center space-x-4 pt-1 pb-3">
                  {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}
                  {<GoogleLogin
                    clientId="470934482733-ifgjmq4jhh7smrk9erfos8crirq9juh8.apps.googleusercontent.com"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    type="icon"
                    ux_mode="popup"
                    shape="circle"
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={true}
                    scope="profile"
                    render={renderProps => (
                      <MyGoogleButton onClick={renderProps.onClick} />
                    )}
                  />}
                  {/* { <span onClick={() => login()}><FcGoogle size="30" /></span> } */}
                  {/* <GoogleLogin
                  onSuccess={async (credentialResponse) =>  {
                    //const realUserData = await getDecodedOAuthJwtGoogle(credentialResponse) 
                    //console.log(realUserData);
                    if (credentialResponse.credential != null) {
                      const USER_CREDENTIAL = await jwtDecode(credentialResponse.credential);
                      console.log(USER_CREDENTIAL);
                     }
                    
                    
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  
                  render={(renderProps) => (<button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}><span><BsFacebook size="30" color="#3b5998" /></span></button>)}
                /> */}
                  {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/twitter`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}



                  {/*<Link
                  to={{
                    pathname: `${process.env.REACT_APP_BACKEND_URL}/auth/facebook`,
                  }}
                  target="_parent"
                >
                  
                </Link>*/}
                  <FacebookLogin
                    appId="1764509220385819"
                    autoLoad={false}
                    fields="name,email"
                    callback={handleFacebookLogin}
                    render={renderFacebookButton}
                  />
                </div>
                <CardBody name={t("Or login with:")} color="" />
                <div >
                  <div className="py-3">
                    <Input
                      ref={loginemail}
                      elementType="input"
                      elementConfig={{
                        type: "email",
                        placeholder: "Email (example@email.com)",
                      }}
                      value={email}
                      changed={setEmail}
                      validation={{ isEmail: true }}
                      shouldValidate
                      error={t("Valid email is required")}
                    />
                    <Input
                      elementType="input"
                      withIcon
                      elementConfig={{
                        type: isShowPassword ? "text" : "password",
                        placeholder: t("Enter password"),
                      }}
                      value={password}
                      changed={setPassword}
                    >
                      {!isShowPassword ? (
                        <BsEye
                          className="cursor-pointer"
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        />
                      ) : (
                        <BsEyeSlash
                          className="cursor-pointer"
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        />
                      )}
                    </Input>
                  </div>
                  <Message type={"error"} viewable={true} message={error} />
                  <div className="py-3">
                    <Button
                      type="submitbtn"
                      name={t("Log in")}
                      outline="true"
                      color="blue"
                    //  clicked={login}
                    />
                  </div>
                  <OtherLinkButton
                    color="blue"
                    to="/forgot"
                    name={t("Forgot Password?")}
                  />
                </div>
              </div>
              <div className="flex justify-center space-x-2 py-3">
                <SmallText
                  name={t("Don't have an account?")}
                  color="black"
                  alignment="center"
                />
                <OtherLinkButton
                  color="blue"
                  to="/enroll"
                  name={t("Sign up")}
                  size="xs"
                />
              </div>
            </form>
          )}
        </>
      </div>
    </div>
  );
};
