import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import PageContent from "../../pageContent";
import {
  Button,
  ButtonLink,
  GoBackButton,
  TextButton,
} from "../../../../elements/button";
import Spinner from "../../../../elements/spinner";
import Notification from "../../../../elements/notification/notification";
import Table from "../../../../elements/table/table";
import Accordion from "../../../../elements/accordion";
import Layout from "../../../../common/Layout";
import SuccessIcon from "../../../../../images/icons/success.svg";
import Modal from "../../../../elements/modal/modal";
import { PageBody, SectionTitle } from "../../../../elements/text";

const CreateForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.programs.programsLoading);
  const [showSuccessModal, setShowSuccessModal] = useState();
  const tableheader1 = ["", "Goal"];
  const tablebody1 = [
    {
      td1: { data: true, type: "checkbox" },
      td2: {
        data: (
          <Accordion
            title="Pass this class above 65% average Marks"
            content={[
              "1. Do at least one unit quiz for every student",
              "2. Do at least one unit quiz for every student",
              "3. Do at least one unit quiz for every student",
            ]}
          />
        ),
        type: "component",
      },
    },
    {
      td1: { data: false, type: "checkbox" },
      td2: {
        data: (
          <Accordion
            title="Pass this class above 65% average Marks"
            content="1. Do at least one unit quiz for every student"
          />
        ),
        type: "component",
      },
    },
    {
      td1: { data: false, type: "checkbox" },
      td2: {
        data: (
          <Accordion
            title="Pass this class above 65% average Marks"
            content="1. Do at least one unit quiz for every student"
          />
        ),
        type: "component",
      },
    },
  ];

  return (
    <Layout>
      <PageContent>
      <Modal
        show={showSuccessModal}
        modalClosed={() => setShowSuccessModal(false)}
        small
      >
        <div className="flex flex-col">
          <SectionTitle name="Created!!" color="blue" alignment="center" />
          <PageBody
            name="Class is completed, Click on finish to go back to your class list"
            color="black"
            alignment="center"
          />
          <div className="m-4">
            <img
              alt={props.user}
              className="h-18 w-auto m-auto"
              src={SuccessIcon}
            />
          </div>
          <div className="flex flex-row items-center justify-center">
            <ButtonLink
              name="Finish"
              outline="true"
              color="blue"
              page="/school/features/classes"
            />
          </div>
        </div>
      </Modal>
        <div
          className={`relative w-full p-3 col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <GoBackButton
            link="/school/features/classes"
            action="Add goals"
            location="Classes"
          />
          {loading ? (
            <Spinner size="32" color="blue" />
          ) : (
            <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
              <div className="relative overflow-x-auto sm:rounded-lg">
                <Table thead={tableheader1} tbody={tablebody1} />
              </div>
              <div className="flex space-x-2 justify-center py-5">
                <ButtonLink
                  name="Cancel"
                  outline="true"
                  color="blue"
                  page="/school/features/classes"
                />
                <Button
                  name="Finish"
                  outline="false"
                  color="blue"
                  clicked={() => setShowSuccessModal(true)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-35% mt-5">
          <Notification auth />
        </div>
      </PageContent>
    </Layout>
  );
};

export default CreateForm;
