import { NavLink } from "react-router-dom";
export const Link = function (props) {
  const checkActive = (match, location) => {
    if (match) {
      const { pathname } = location;
      const { url } = match;
      return pathname === url || props.indicator === url;
    } else {
      if (props.indicator) return true;
    }
  };
  return (
    <NavLink
      to={props.page}
      isActive={checkActive}
      activeClassName="border-b-4 border-blue text-blue"
      className="text-center font-bold md:font-medium md:text-start text-gray-300  hover:text-blue px-3 py-2 text-sm font-medium"
    >
      {props.name}
    </NavLink>
  );
};

export const FooterLink = function (props) {
  const checkActive = (match, location) => {
    if (!match) return false;
    const { pathname } = location;
    const { url } = match;
    return pathname === url;
  };
  return (
    <NavLink
      to={props.page}
      isActive={checkActive}
      reloadDocument= {props.reloadDocument ? props.page: ""}
      activeClassName="box-content border-b-4 border-blue text-gray underline"
      className="block text-right text-white md:text-start text-sm font-thin"
      
    >
      {props.name}
    </NavLink>
  );
};
