import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
const MatchingTypeAutoQuestionDropTargetItem = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();

    useEffect(() => {

    }, []);   

    return (
        <div>
            <span className={`cursor-default p-2 ${(!props.by_user) ? "bg-blue" :(props.data_state)? "bg-green":"bg-red"} text-center px-1 py-1 text-white m-1 text-sm space-y-1.5`} style={{ textDecoration: (props.data_state) ? "none" : "line-through" }}>{props.data}</span>
        </div>
    );

};
export default MatchingTypeAutoQuestionDropTargetItem;
