import Layout from '../../components/common/Layout';
import DAP from '../../components/pages/teacher/programs/dap';

const DAPContainer = () => {
  return (
    <Layout>
      <DAP />
    </Layout>
  );
};

export default DAPContainer;
