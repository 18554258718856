import React from 'react';
import Layout from '../../../components/common/Layout';
import Simulation from '../../../components/pages/teacher/simulations/Simulation';

const SimulationContainer = () => {
  return (
    <Layout>
      <Simulation/>
    </Layout>
  );
};

export default SimulationContainer;
