import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED,

} from "../actions/student.dashboard.curriculum.cambrige";
const initialReducer = {
  cbc_subjects_units: [],
  cbc_subjects_units_loading: true,
  message: "",
};

function student_subjects_by_academic_units_init(state, action) {
  return updateObject(state, {
    cbc_subjects_units_loading: true,
  });
}
function student_subjects_by_academic_units_success(state, action) {
  return updateObject(state, {
    cbc_subjects_units: action.payload,
    cbc_subjects_units_loading: false,
    message: "success"
  });
}
function student_subjects_by_academic_units_fail(state, action) {
  return updateObject(state, {
    message: action.payload,
    cbc_subjects_units_loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT:
      return student_subjects_by_academic_units_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS:
      return student_subjects_by_academic_units_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED:
      return student_subjects_by_academic_units_fail(state, action);  
    default:
      return state;
  }
};
export default reducer;
