import ProfilePageContent from '../../../landing/completeprofile/pageContent';
import PageContent from '../../pageContent';
const Profile = () => {
  return (
    <PageContent>
      <ProfilePageContent selectedColor="yellow" />
    </PageContent>
  );
};

export default Profile;
