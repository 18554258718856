import Days from "../../../elements/days/days";
import FeaturesProposed from "./features";
import Greetings from "./greetings";
import Layout from "./layout";
import NotificationPanel from "./notification";
import { useSelector } from "react-redux";
import Recommendations from "./recommendations";
const Dashboard = () => {
  const userAth = useSelector((state) => state.auth);
  return (
    <Layout
      greetings={<Greetings username={userAth?.username} avatar={userAth?.user.avatarimage}/>}
      days={<Days />}
      featureproposed={<FeaturesProposed />}
      recommendation={<Recommendations userAth={userAth}/>}
      notification={<NotificationPanel />}
    />
  );
};

export default Dashboard;
