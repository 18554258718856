import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { replaceReact } from "replace-react";
import { FcAnswers } from "react-icons/fc";
import parse from "react-html-parser";
import "./test.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as constants from "./constants";
import * as qconstants from "./qconstants";
import { hidesidebar } from "../store/actions/sidebaractiontypes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions/";
import answerstobesumittedtodb from "./answerstobesumittedtodb";

export const Algorithms = () => {
  const [studentvisible, setstudentvisible] = useState(false);
  const [teachervisible, setteachervisible] = useState(true);

  return (
    <div className="px-10">
      <ul class="flex flex-wrap">
        <li
          class="mr-2"
          onClick={() => {
            setteachervisible(!teachervisible);
            setstudentvisible(!studentvisible);
          }}
        >
          <a
            href="#"
            class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 bg-blue-600 rounded-lg active"
            aria-current="page"
          >
            Teacher
          </a>
        </li>
        <li
          class="mr-2"
          onClick={() => {
            setteachervisible(!teachervisible);
            setstudentvisible(!studentvisible);
          }}
        >
          <a
            href="#"
            class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
          >
            Student
          </a>
        </li>
      </ul>
      <div className="container">
        <div className={teachervisible == true ? "block" : "hidden"}>
          <TeacherAlgorithms />
        </div>
        <div className={studentvisible == true ? "block" : "hidden"}>
          <StudentAlgorithms />
        </div>
      </div>
    </div>
  );
};

export const TeacherAlgorithms = () => {
  const [studentvisible, setstudentvisible] = useState(false);
  const [teachervisible, setteachervisible] = useState(true);
  return (
    <div>
      <div className="flex flex-col">
        <ul class="flex flex-wrap">
          <li
            class="mr-2"
            onClick={() => {
              setteachervisible(!teachervisible);
              setstudentvisible(!studentvisible);
            }}
          >
            <a
              href="#"
              class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 bg-blue-600 rounded-lg active"
              aria-current="page"
            >
              Create Test
            </a>
          </li>
          <li
            class="mr-2"
            onClick={() => {
              setteachervisible(!teachervisible);
              setstudentvisible(!studentvisible);
            }}
          >
            <a
              href="#"
              class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
            >
              Take Test
            </a>
          </li>
        </ul>
        <div className="container">
          <div className={teachervisible == true ? "block" : "hidden"}>
            CREATE TEST
          </div>
          <div className={studentvisible == true ? "flex flex-col" : "hidden"}>
            TEACHER TAKE TEST
            <TakeTest />
          </div>
        </div>
      </div>
    </div>
  );
};

export const StudentAlgorithms = () => {
  const [studentvisible, setstudentvisible] = useState(false);
  const [teachervisible, setteachervisible] = useState(true);
  return (
    <div>
      <ul class="flex flex-wrap">
        <li
          class="mr-2"
          onClick={() => {
            setteachervisible(!teachervisible);
            setstudentvisible(!studentvisible);
          }}
        >
          <a
            href="#"
            class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 bg-blue-600 rounded-lg active"
            aria-current="page"
          >
            Add Questions to Bank
          </a>
        </li>
        <li
          class="mr-2"
          onClick={() => {
            setteachervisible(!teachervisible);
            setstudentvisible(!studentvisible);
          }}
        >
          <a
            href="#"
            class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
          >
            Take Test
          </a>
        </li>
      </ul>
      <div className="container">
        <div className={teachervisible == true ? "block" : "hidden"}>
          <AddQuestionsToBank />
        </div>
        <div className={studentvisible == true ? "block" : "hidden"}>
          <MultipleChoiceQuestion />
          <FillInQuestions />
          <OpenEndedQuestions />
          <MatchingQuestions />
        </div>
      </div>
    </div>
  );
};

export const AddQuestionsToBank = () => {
  const dispatch = useDispatch();
  const [studentvisible, setstudentvisible] = useState(false);
  const [teachervisible, setteachervisible] = useState(true);
  const [textValue, settextValue] = useState("");

  useEffect(() => {
    dispatch(actions.getQuestionsAlgoV2());
    dispatch(actions.getQuestions());
  }, [dispatch]);

  const onSubmit = () => {
    const questions = qconstants.getAutoQuestionV1();
    dispatch(actions.registerAutoQuestions(questions));
  };
  const onSubmitFillIn = () => {
    const questions = qconstants.getFillInQuestions();
    dispatch(actions.registerAutoQuestionsAlgoV2(questions));
  };
  const onSubmitMatching = () => {
    const questions = qconstants.getMatchingQuestions();
    dispatch(actions.registerAutoQuestionsAlgoV2(questions));
  };
  return (
    <div>
      <ul class="flex flex-wrap">
        <li
          class="mr-2"
          onClick={() => {
            setteachervisible(!teachervisible);
            setstudentvisible(!studentvisible);
          }}
        >
          <a
            href="#"
            class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 bg-blue-600 rounded-lg active"
            aria-current="page"
          >
            Add Multiple Choice Questions
          </a>
        </li>
        <li
          class="mr-2"
          onClick={() => {
            setteachervisible(!teachervisible);
            setstudentvisible(!studentvisible);
          }}
        >
          <a
            href="#"
            class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
          >
            Add Fill In Questions
          </a>
        </li>
        <li
          class="mr-2"
          onClick={() => {
            setteachervisible(!teachervisible);
            setstudentvisible(!studentvisible);
          }}
        >
          <a
            href="#"
            class="inline-block py-3 px-4 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white"
          >
            Add Matching Questions
          </a>
        </li>
      </ul>
      <div className={teachervisible ? "flex flex-col" : "hidden"}>
        Add Multiple choice Questions
        <div className="w-100">
          <div id="data">
            <InputTextArea
              value={textValue}
              onChange={(event) => {
                settextValue(event);
              }}
            />
          </div>
          <input type={"submit"} onClick={onSubmit} />
        </div>
      </div>
      <div className={studentvisible ? "flex flex-col" : "hidden"}>
        Add Fill In Questions
        <div className="w-100">
          <div id="fillin-data">
            <InputTextArea
              value={textValue}
              onChange={(event) => {
                settextValue(event);
              }}
            />
          </div>
          <input type={"submit"} onClick={onSubmitFillIn} />
        </div>
      </div>
      {/*  <div className={studentvisible ? "flex flex-col" : "hidden"}>
        Add Matching Questions
        <div className="w-100">
          comment from here (--> <textarea cols={60} rows={10} id="matching-data" />
           to here <--)
          <div id="quillo-matching-data">
            <InputTextArea
              value={textValue}
              onChange={(event) => {
                settextValue(event);
              }}
            />
          </div>
          <input type={"submit"} onClick={onSubmitMatching} />
        </div> 
      </div> */}
    </div>
  );
};

export const TakeTest = () => {
  const dispatch = useDispatch();
  const questionsalgo1 = useSelector((state) => state.autoquestions.questions);
  const questionsalgo2 = useSelector(
    (state) => state.autoquestionsalgov2.questions
  );
  useEffect(() => {
    dispatch(actions.getQuestionsAlgoV2());
    dispatch(actions.getQuestions());
  }, [dispatch]);
  const allquestions = questionsalgo1 //.concat(questionsalgo2); // questionsalgo2;  //
  const [fromChill, setfromChill] = useState();
  const ref = useRef();
  const questionsRef = useRef([]);
  questionsRef.current = [];

  const addToRefs = (qRef) => {
    if (qRef && !questionsRef.current.includes(qRef)) {
      questionsRef.current.push(qRef);
    }
  };
  let multiplechoicesingleanswers = [];
  let multiplechoicemultianswers = [];
  let multiplechoicemarks = [];
  // multi stores answers for multianswer, single stores answers for singleanswers and marks stores marks string
  let multi,
    single,
    marks = "",
    matching = "",
    fillin = "";
  let questionpulldatamatching = "";
  let questionIdsmatching = [];
  let questionpulldatafillin = "";
  let questionIdsfillin = [];
  const onSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to submit?")) {
      questionsRef.current.forEach((element) => {
        if (element.submit().questiontype == constants.multiplechoice) {
          if (element.submit().numberofanswers == constants.singleanswer) {
            multiplechoicesingleanswers.push(element.submit().responsedata);
            single = multiplechoicesingleanswers.join("cyuma");
          } else {
            multiplechoicemultianswers.push(element.submit().responsedata);
            multi = multiplechoicemultianswers.join("##-#");
          }
          if (marks == "") {
            marks = element.submit().marks;
          } else {
            marks = marks + "##" + element.submit().marks;
          }
        } else if (element.submit().questiontype == constants.matching) {
          // Adding id to the question pull
          questionIdsmatching.push(element.submit().questionId);
          if (matching == "") {
            matching = element.submit().responsedata;
          } else {
            matching = matching + "-----" + element.submit().responsedata;
          }
        } else if (element.submit().questiontype == constants.fillin) {
          // Adding id to the question pull
          questionIdsfillin.push(element.submit().questionId);
          if (fillin == "") {
            fillin = element.submit().responsedata;
          } else {
            fillin = fillin + "-----" + element.submit().responsedata;
          }
        }
      });

      questionpulldatamatching = questionIdsmatching.join(",");
      questionpulldatafillin = questionIdsfillin.join(",");
      // console.log(`Single ${single}`);
      // console.log(`Multiple ${multi}`);
      // console.log(`Matching ${matching}`);
      // console.log(`Fillin ${fillin}`);
      // console.log(`Marks ${marks}`);
      // console.log(`PULL DATA ${questionpulldata}`);
      // constring objects to be sent to DB
      // #1 Multiple choice tobe saved in TestAIUsersAnswers
      // dispatch(
      //   actions.submitTestAIUsersAnswers(
      //     answerstobesumittedtodb.testaiusersanswers(single, multi, marks)
      //   )
      // );
      // #2 Algo V2 ::  Matching
      dispatch(
        actions.submitTestAlgoV2UsersAnswers(
          answerstobesumittedtodb.testalgov2aiusersanswers(
            matching,
            questionpulldatamatching
          )
        )
      );
      // #2 Algo V2 ::  Fillin
      dispatch(
        actions.submitTestAlgoV2UsersAnswers(
          answerstobesumittedtodb.testalgov2aiusersanswers(
            fillin,
            questionpulldatafillin
          )
        )
      );
      // Emptying response after submitting
      multiplechoicesingleanswers = [];
      multiplechoicemultianswers = [];
      questionIdsfillin = [];
      questionIdsmatching = [];
      questionpulldatamatching = "";
      questionpulldatafillin = "";
      marks = "";
      multi = "";
      single = "";
    } else {
    }
  };
  return (
    <div className="flex flex-col space-y-10 pb-10">
      {allquestions.map((question, key) => (
        <QuestionGeneralFormat
          key={key}
          ref={addToRefs}
          id={key}
          questiontype={
            question.panda_auto_test_algo_v2_test_type != null
              ? question.panda_auto_test_algo_v2_test_type
              : constants.multiplechoice
          }
          questiondata={question}
          questionmarks={question.panda_questions_auto_total_marks}
        />
      ))}
      <input
        type={"submit"}
        value="submit"
        onClick={onSubmit}
        className="w-28"
      />
    </div>
  );
};

export const MultipleChoiceQuestion = forwardRef((props, ref) => {
  const [SelectedAnswer, setSelectedAnswer] = useState("");
  const [checked, setchecked] = useState(false);
  const [index, setindex] = useState(-1);
  const [indeces, setindeces] = useState([]);
  const [responses, setresponses] = useState([]);
  const [oneResponse, setoneResponse] = useState(["staff"]);
  const [manyResponses, setmanyResponses] = useState(["staff", "developer"]);
  const [questionid, setquestionid] = useState("");
  // const [questiomarks, setquestiomarks] = useState(0);
  let answers = [];
  let question = "";
  let oquestionId = "";
  let numberofanswers = constants.singleanswer;
  const matchingRef = useRef([]);
  matchingRef.current = [];
  const multiplechoiceRef = useRef([]);
  multiplechoiceRef.current = [];
  let questionmarks = 0;

  if (props.questiondata !== undefined) {
    question = props.questiondata.question;
    answers = props.questiondata.answers;
    oquestionId = props.questiondata.oquestionId;
    numberofanswers = props.questiondata.numberofanswers;
    questionmarks = props.questiondata.questionmarks;
  }
  let classes = "flex flex-col border-2 px-3 py-2 space-x-2 w-5/12 mt-2 ";
  let selectedClasses =
    "flex flex-col border-2 px-3 py-2 space-x-2 w-5/12 mt-2 bg-twitter";
  const selectOne = (val, i, questId) => {
    setSelectedAnswer(val);
    setindex(i);
    setquestionid(questId);
  };

  const selectMany = (val, i, questId) => {
    setSelectedAnswer(val);
    isInArray(i, val);
    setquestionid(questId);
  };

  const isInArray = (i, val) => {
    if (indeces.includes(i) == false) {
      setindeces([...indeces, i]);
    } else {
      indeces.pop(i);
    }
    if (responses.includes(val) == false) {
      setresponses([...responses, val]);
    } else {
      responses.pop(val);
    }
  };

  const addToRefs = (qRef) => {
    if (qRef && !matchingRef.current.includes(qRef)) {
      matchingRef.current.push(qRef);
    }
  };
  const addToMultiRefs = (qRef) => {
    if (qRef && !multiplechoiceRef.current.includes(qRef)) {
      multiplechoiceRef.current.push(qRef);
    }
  };
  let multiplechoiceresult;
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
      matchingRef.current.forEach((element) => {
        if (element.checked) {
          multiplechoiceresult = constants.multiplechoiceResponseSingle(
            oneResponse,
            SelectedAnswer,
            questionid,
            questionmarks
          );
        }
      });

      multiplechoiceRef.current.forEach((element) => {
        if (element.checked == true) {
          multiplechoiceresult = constants.multiplechoiceResponseMany(
            manyResponses,
            responses,
            questionid,
            questionmarks
          );
        }
      });
      return multiplechoiceresult;
    },
    result: multiplechoiceresult,
  }));
  return (
    <div className="flex flex-col">
      <div>Question: {question}</div>
      <div
        className={
          numberofanswers == constants.multianswer ? "block" : "hidden"
        }
      >
        {answers.map((answer, i) => (
          <div className={indeces.includes(i) ? selectedClasses : classes}>
            <label>{answer.panda_auto_questions_answers_ans_data}</label>
            <input
              type={"checkbox"}
              name={"answer" + oquestionId}
              ref={addToMultiRefs}
              id={i}
              value={answer.panda_auto_questions_answers_id}
              onChange={(e) =>
                selectMany(
                  e.target.value,
                  i,
                  answer.panda_auto_questions_answers_question_id
                )
              }
            />
          </div>
        ))}
      </div>
      <div
        className={
          numberofanswers == constants.singleanswer ? "block" : "hidden"
        }
      >
        {answers.map((answer, i) => (
          <div className={index == i ? selectedClasses : classes}>
            <label>{answer.panda_auto_questions_answers_ans_data}</label>
            <input
              type={"radio"}
              ref={addToRefs}
              name={"answer" + oquestionId}
              id={i}
              value={answer.panda_auto_questions_answers_id}
              onChange={(e) =>
                selectOne(
                  e.target.value,
                  i,
                  answer.panda_auto_questions_answers_question_id
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export const MultipleQuestionAnswerFormat = forwardRef((props, ref) => {
  const [isChecked, setisChecked] = useState(false);
  const optionRef = useRef();
  let result = "";
  let classes = "flex flex-col border-2 px-3 py-2 space-x-2 w-5/12 mt-2 ";
  const selectAnswer = (e) => {
    setisChecked(!isChecked);
  };
  if (isChecked) {
    classes =
      "flex flex-col border-2 px-3 py-2 space-x-2 w-5/12 mt-2 bg-twitter";
  }
  const checking = () => {
    result = props.answer;
  };
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
    },
    result,
  }));
  if (props.answertype == constants.multianswer) {
    return (
      <div className={classes}>
        <label>{props.answer}</label>
        <input
          id={props.id}
          type="checkbox"
          name="answer"
          // checked={checked}
          onChange={props.onChange}
          onClick={checking}
          value={props.answer}
        />
      </div>
    );
  } else {
    return (
      <div className={classes} onChange={props.onChange}>
        <label>{props.answer}</label>
        <input
          id={props.id}
          type="radio"
          name="answer"
          // checked={checked}
          // onChange={props.onChange}
          onClick={checking}
          value={props.answer}
        />
      </div>
    );
  }
});

export const AnswerSelectionType = forwardRef((props, ref) => {
  const [checked, setchecked] = useState(false);
  const checking = () => {
    setchecked(true);
  };
  // let checked = false;
  let classes = "flex flex-col border-2 px-3 py-2 space-x-2 w-5/12 mt-2 ";
  // let checked = false;
  // if (props.checked == true) {
  //   checked = true;
  // }
  if (checked == true) {
    classes =
      "flex flex-col border-2 px-3 py-2 space-x-2 w-5/12 mt-2 bg-twitter";
  }
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
      if (checked == true) {
        console.log(props.value);
      }
    },
  }));
  // multianswer stand for many answers while singleanswer stand for single answer
  if (props.answertype === constants.multianswer) {
    return (
      <div className={classes}>
        <div>{props.answer}</div>
        <div>
          <input
            id={props.id}
            type="checkbox"
            name="answer"
            checked={checked}
            onChange={props.onChange}
          />
        </div>
      </div>
    );
  } else if (props.answertype === constants.singleanswer) {
    return (
      <div className={classes}>
        <div>{props.answer}</div>
        <div>
          <input
            id={props.id}
            type="radio"
            name="answer"
            checked={checked}
            onChange={props.onChange}
            onClick={checking}
            value={props.value}
          />
          <input
            id={props.id}
            type="radio"
            name="answer"
            checked={checked}
            onChange={props.onChange}
            onClick={checking}
            value={props.value}
          />
          <input
            id={props.id}
            type="radio"
            name="answer"
            checked={checked}
            onChange={props.onChange}
            onClick={checking}
            value={props.value}
          />
        </div>
      </div>
    );
  }
});

export const FillInQuestions = forwardRef((props, ref) => {
  let answers = [];
  let question = "";
  let oquestionId = "";
  let questId = "";
  if (props.questiondata !== undefined) {
    question = props.questiondata.question;
    oquestionId = props.questiondata.oquestionId;
    questId = props.questiondata.questId;
  }
  let questiondata;
  let questionparts = [];
  let questionword = `<p></p>`;
  let listofanswers = [];
  // Calling a function that gives fillin question parts
  questiondata = qconstants.getFillInQuestionParts(question);
  answers = questiondata.regexArray;
  question = questiondata.questiondata;
  listofanswers = questiondata.listofanswers;
  // breaking the question data into an array of strings
  if (question != undefined) {
    questionparts = question.split(" ");
  }
  let regWord = {};
  // making regex that will help replacing words that will be filled in
  if (answers != undefined) {
    regWord = new RegExp(answers.join("|"), "i");
  }

  const fillincardsref = useRef([]);
  fillincardsref.current = [];
  const addToRefs = (qRef) => {
    if (qRef && !fillincardsref.current.includes(qRef)) {
      fillincardsref.current.push(qRef);
      // setinstance(instance + 1);
    }
  };
  let fillinresponse = [];
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
      fillincardsref.current.forEach((element) => {
        fillinresponse.push(element.result);
      });
      let uniqueresponse = [...new Set(fillinresponse)];
      let fillinresponsedata = uniqueresponse.join("~~#$!~~");
      return {
        responsedata: fillinresponsedata,
        questiontype: constants.fillin,
        questionId: questId,
      };
    },
  }));
  useEffect(() => {
    qconstants.getFillInQuestionSpanned();
  });

  let counter = -1;
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col">
        <h5>Fill In Question</h5>
        {parse(questionword)}
        <h4>New Fillin Format</h4>
        <span className="flex">
          {questionparts.map((part, i) => {
            if (part.match(regWord)) {
              counter = counter + 1;
            }
            return part.match(regWord) ? (
              <FillInResponceCard
                key={i}
                listofanswers={listofanswers[counter]}
                index={counter}
                ref={addToRefs}
                questId={questId}
              />
            ) : (
              part + constants.oneEmptySpace
            );
          })}
        </span>
      </div>
    </DndProvider>
  );
});

export const MatchingQuestions = forwardRef((props, ref) => {
  let answers = [];
  let question = "";
  let oquestionId = "";
  let listofoptions = [];
  let listofanswers = [];
  let questId = 0;
  const matchingRef = useRef([]);
  matchingRef.current = [];
  if (props.questiondata !== undefined) {
    question = props.questiondata.question;
    // answers = props.questiondata.answers;
    oquestionId = props.questiondata.oquestionId;
    questId = props.questiondata.questId;
  }

  let questiondata = qconstants.getMatchingQuestionParts(question);
  if (questiondata.listofoptions != undefined) {
    listofoptions = questiondata.listofoptions;
  }
  if (questiondata.listofanswers != undefined) {
    listofanswers = questiondata.listofanswers;
  }
  let answerstodisplay = [];
  listofanswers.forEach((element) => {
    answerstodisplay.push(element.split(constants.responseSeparator));
  });
  const regWord = new RegExp(answers.join("|"), "i");
  const regResponse = new RegExp(constants.responseSymbol, "i");
  const questionparts = question.split(" ");

  const addToRefs = (qRef) => {
    if (qRef && !matchingRef.current.includes(qRef)) {
      matchingRef.current.push(qRef);
    }
  };
  let matchingresponsedata = [];
  let matchingresponse;
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
      matchingRef.current.forEach((element) => {
        if (element.result.length > constants.ONE) {
          matchingresponsedata.push(element.result);
        }
      });
      let uniqueresponse = [...new Set(matchingresponsedata)];
      matchingresponse = uniqueresponse.join();
      matchingresponsedata = [];
      return {
        responsedata: matchingresponse,
        questiontype: constants.matching,
        questionId: questId,
      };
    },
  }));

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col space-y-5">
        <span>Matching Question {questiondata.questiondata}</span>
        <div className="flex">
          <div className="w-2/5 space-y-2 flex flex-col">
            {listofoptions.map((option, id) => (
              <MatchingAnswerCard color={option} id={id} key={id} />
            ))}
          </div>
          <div className="w-3/5 space-y-2 flex flex-col" id={oquestionId}>
            {answerstodisplay.map((answer, key) => (
              <div className="flex space-x-3">
                {answer.map((word, key) =>
                  word.match(regResponse) ? (
                    <MatchingResponceCard
                      ref={addToRefs}
                      id={key}
                      questionword={answer[0]}
                      questId={questId}
                    />
                  ) : (
                    <span>{word}</span>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
  );
});

export const MatchingAnswerCard = (props) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "answer",
    item: { id: props.color },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  let color = "bg-blue text-center  h-6 px-3 text-white w-44";
  if (isDragging) {
    color = "bg-blue text-center  h-6 border-4 px-3 text-white w-44";
  }
  return (
    <span ref={drag} className={color}>
      {props.color}
    </span>
  );
};

export const MatchingResponceCard = forwardRef((props, ref) => {
  const [answer, setanswer] = useState(constants.machbydropping);
  const [isanswered, setisanswered] = useState(false);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "answer",
    drop: (item) => dropAnswer(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const dropAnswer = (id) => {
    setanswer(id);
    setisanswered(true);
  };
  let questionword = props.questionword;
  let questId = props.questId;
  useImperativeHandle(ref, () => ({
    result: constants.matchingResponse(answer, questionword, questId),
  }));
  return (
    <span
      ref={drop}
      className={
        isanswered == false
          ? "bg-twitter text-white text-center px-3 w-44 ml-1 mr-1"
          : "bg-blue text-white text-center px-3 w-44"
      }
    >
      <span class="answers">{answer}</span>
    </span>
  );
});

export const FillInResponceCard = forwardRef((props, ref) => {
  const [answer, setanswer] = useState(constants.machbydropping);
  const [showanswers, setshowanswers] = useState(false);
  const [isanswered, setisanswered] = useState(false);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "answer",
    drop: (item) => fillAnswer(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  let listofanswers = props.listofanswers;
  const fillAnswer = (id) => {
    setanswer(id);
    setisanswered(true);
    setshowanswers(!showanswers);
  };
  let questionword = props.questionword;
  let questId = props.questId;
  useImperativeHandle(ref, () => ({
    result: constants.fillinResponse(answer, questId),
  }));
  const showAnswers = () => {
    setshowanswers(!showanswers);
  };
  return (
    <span className="flex">
      <span
        ref={drop}
        className={
          isanswered == false
            ? "bg-twitter text-white text-center px-3 ml-1 h-6"
            : "bg-blue text-white text-center px-3 ml-1 h-6"
        }
      >
        <span class="answers">{answer}</span>
      </span>{" "}
      <span>
        <span
          className="ml-1 mr-1 h-6 text-white text-lg bg-green flex flex-col z-30"
          onMouseEnter={showAnswers}
          // onMouseLeave={showAnswers}
        >
          +
        </span>
        <span
          className={
            showanswers == true
              ? "flex flex-col space-y-1 bg-gray-200 px-5"
              : " hidden"
          }
        >
          {listofanswers.map((givenanswer, i) => (
            <span
              key={i}
              className="bg-green px-3 text-white"
              onClick={() => {
                fillAnswer(givenanswer.replace(/#A.N.S/g, ""));
              }}
            >
              {givenanswer.replace(/#A.N.S/g, "")}
            </span>
          ))}
        </span>
      </span>
    </span>
  );
});

export const MatchingAnswerSentencesCard = (props) => {
  const [answer, setanswer] = useState("yellow");
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "answer",
    drop: (item) => dropAnswer(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const dropAnswer = (id) => {
    setanswer(id);
  };
  return (
    <span
      ref={drop}
      className={
        isOver ? "flex space-x-2" : "border-4 w-80 h-32 flex space-x-2"
      }
    >
      <p>
        HEllo friends <MatchingResponceCard color={answer} /> here is the test
      </p>
    </span>
  );
};

export const OpenEndedQuestions = (props) => {
  let question = "";
  if (props.questiondata !== undefined) {
    question = props.questiondata.question;
  }
  return (
    <div>
      <h5 className="">Question: {question}</h5>
      <textarea rows={6} cols={70} />
    </div>
  );
};

const QuestionGeneralFormat = forwardRef((props, ref) => {
  const reftoQ = useRef();
  let result = [];
  let answers = [];
  let question = "";
  let oquestionId = "";
  let questionmarks = props.questionmarks;
  let numberofanswers = constants.ONE;
  let questId = "";
  if (props.questiondata !== undefined) {
    if (
      props.questiontype === constants.matching ||
      props.questiontype === constants.fillin
    ) {
      question = props.questiondata.panda_auto_test_algo_v2_question_data;
      questId = props.questiondata.panda_auto_test_algo_v2_id;
    } else {
      question = props.questiondata.panda_questions_auto_question_data;
    }
    answers = props.questiondata.quetionanswers;
    oquestionId = props.questiondata.uuid;
    numberofanswers = props.questiondata.numberofanswers;
    questionmarks = questionmarks;
  }
  const questiondata = {
    question,
    answers,
    oquestionId,
    numberofanswers,
    questionmarks,
    questId,
  };

  useImperativeHandle(ref, () => ({
    submit() {
      //let data =
      //reftoQ.current.submitingAnswer();
      return reftoQ.current.submitingAnswer();
    },
    questionId: "questId",
  }));

  if (props.questiontype === constants.fillin) {
    return <FillInQuestions questiondata={questiondata} ref={reftoQ} />;
  } else if (props.questiontype === constants.matching) {
    return <MatchingQuestions questiondata={questiondata} ref={reftoQ} />;
  } else if (props.questiontype === constants.multiplechoice) {
    return <MultipleChoiceQuestion questiondata={questiondata} ref={reftoQ} />;
  } else if (props.questiontype === constants.openended) {
    return <OpenEndedQuestions questiondata={questiondata} ref={reftoQ} />;
  }
});

export const InputTextArea = (props) => {
  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  /*
   * PropType validation
   */
  const propTypes = {
    placeholder: "Write something",
  };

  let editorValue = "";


  return (
    <div id={"id"} className="flex flex-col pt-2">
      <div className={""} onMouseUpCapture={props.onMouseUpCapture}>
        <ReactQuill
          theme="snow"
          editorHtml="html"
          value={props.value}
          onChange={props.onChange}
          modules={modules}
          formats={formats}
          placeholder={""}
        />
      </div>
    </div>
  );
};

export const InputText = (props) => {
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }

  return (
    <div className="flex flex-col pt-2">
      <label className="input-title">{props.title}</label>
      <input
        className={
          "text-left shadow appearance-none border-2  rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        }
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      />
    </div>
  );
};
