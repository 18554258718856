import axios from "../../axios-base";
import { getCachedData } from "../../shared/caching";

export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_FAILED";

export function student_dashboard_homepage_get_books_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_INIT
  };
}
export function student_dashboard_homepage_get_books_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_books_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_books_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_books_init());

    axios
      .post(
        `/opanda/studentsibrary/subjects_with_books`,
        {
          academic_level: data.academic_level,
          user_id: (data?.user_id) ? data?.user_id : 0
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_books_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_books_failed(err.message));
      });
  };
}
//--------------------------
export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_FAILED";

export function student_dashboard_homepage_get_book_page_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_INIT
  };
}
export function student_dashboard_homepage_get_book_page_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_book_page_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_book_page_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_book_page_init());

    axios
      .post(
        `/opanda/studentsibrary/subjects_with_get_book_page`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level,
          bookId: data.bookId,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_books_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_books_failed(err.message));
      });
  };
}
//-----Save book Page views-
export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_FAILED";

export function student_dashboard_homepage_get_book_page_mark_as_viewed_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_INIT
  };
}
export function student_dashboard_homepage_get_book_page_mark_as_viewed_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_book_page_mark_as_viewed_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_book_page_mark_as_viewed_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_book_page_mark_as_viewed_init());

    axios
      .post(
        `/opanda/studentsibrary/get_book_mark_as_viewed`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level,
          bookId: data.bookId,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_book_page_mark_as_viewed_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_book_page_mark_as_viewed_failed(err.message));
      });
  };
}