// import Footer from "../components/common/footer";
import Header from '../components/common/header/header';
import NotificationBar from '../components/pages/landing/preview/notificationbar';
import PageContent from '../components/pages/guest/previewguest/pagecontent';
const Guestpreview = (props) => {
  return (
    <div className=" scrollbar-hidden font-poppins h-screen">
      <Header />
      <NotificationBar />
      <PageContent />
      {/* <Footer /> */}
    </div>
  );
};

export default Guestpreview;
