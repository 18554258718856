import { CardTitle } from '../../../elements/text';
import { FeatureItemCard } from '../../../elements/card';
import { useHistory } from 'react-router-dom';
import { groupBy } from '../../../../shared/utility';
import { useEffect, useState } from 'react';

const TestsGroupItems = (props) => {
  const history = useHistory();
  const [transformedTests, setTransformedTests] = useState({});
  const { tests, filterBy } = props;
  useEffect(() => {
    groupBy(tests, filterBy)
      .then((res) => setTransformedTests(res))
      .catch((err) => console.log(err));
  }, [tests, filterBy]);

  const groups = Object.keys(transformedTests);

  return (
    <>
      {groups.map((sort) => (
        <>
          <CardTitle name={sort} color="blue" />
          <div className="md:grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
            {transformedTests[sort].map((test, index) => (
              <FeatureItemCard
                key={index}
                {...test}
                feature="test"
                clicked={() => {
                  history.push(`/teacher/features/tests/${test.id}?scheduled=false`); //here must a test id
                }}
              />
            ))}
          </div>
        </>
      ))}
    </>
  );
};

export default TestsGroupItems;
