import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_FAILED,

} from "../actions/student.dashboard.tests";
const initialReducer = {
  dashboard_homepage_tests_notifs: [],
  dashboard_homepage_tests_notifs_loading: true,
  message: "",
};

function student_dashboard_homepage_tests_notifs_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_tests_notifs_loading: true,
  });
}
function student_dashboard_homepage_tests_notifs_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_tests_notifs: action.payload,
    dashboard_homepage_tests_notifs_loading: false,
    message: "success"
  });
}
function student_dashboard_homepage_tests_notifs_fail(state, action) {
  return updateObject(state, {
    message: action.payload,
    dashboard_homepage_tests_notifs_loading: false,
  });
}


const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_INIT:
      return student_dashboard_homepage_tests_notifs_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_SUCCESS:
      return student_dashboard_homepage_tests_notifs_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_FAILED:
      return student_dashboard_homepage_tests_notifs_fail(state, action);

    default:
      return state;
  }
};
export default reducer;
