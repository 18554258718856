import axios from "../../axios-base";
import { getCachedData } from "../../shared/caching";
import eventBus from "../../utils/eventBus";

export const STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_FAILED";

export function student_dashboard_homepage_tests_subjects_units_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_INIT
  };
}
export function student_dashboard_homepage_tests_subjects_units_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_tests_subjects_units_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_tests_subjects_units_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_tests_subjects_units_init());

    getCachedData('gettestswithunitsautotests', `/opanda/tests/gettestswithunitsautotests`, 'POST', 300,
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      }, {
      academic_level: data.academic_level
    })
      .then((response) => {
        dispatch(student_dashboard_homepage_tests_subjects_units_success(response.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_tests_subjects_units_failed(err.message));
      });
  };
}
//---Get teacher made test--
export const STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_FAILED";

export function student_dashboard_homepage_teacher_made_test_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_INIT
  };
}
export function student_dashboard_homepage_teacher_made_test_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_teacher_made_test_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_teacher_made_test_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_teacher_made_test_init());

    axios
      .post(
        `/opanda/tests/getteachermadetest`,
        {
          academic_level: data.academic_level,
          test_id: data.test_id,
          user_id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_teacher_made_test_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_teacher_made_test_failed(err.message));
      });
  };
}
//---Get  auto test--
export const STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_FAILED";

export function student_dashboard_homepage_auto_test_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_INIT
  };
}
export function student_dashboard_homepage_auto_test_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_auto_test_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_auto_test_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_auto_test_init());
    axios
      .post(
        `/opanda/tests/getautotest`,
        {
          academic_level: data.academic_level,
          subject: data.subject,
          test_level: data.test_level,
          difficulty: data.difficulty,
          units: data.units,
          academic_level: data.academic_level,
          user_id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_auto_test_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_auto_test_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_auto_test_start_for_redo(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_auto_test_init());
    axios
      .post(
        `/opanda/tests/getautotest_for_redo`,
        {
          academic_level: data.academic_level,
          subject: data.subject,
          test_level: data.test_level,
          difficulty: data.difficulty,
          units: data.units,
          academic_level: data.academic_level,
          user_id: data.user_id,
          testid: data.testid
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_auto_test_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_auto_test_failed(err.message));
      });
  };
}
//---Save teacher made test--
export const STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_FAILED";

export function student_dashboard_homepage_save_teacher_made_test_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_INIT
  };
}
export function student_dashboard_homepage_save_teacher_made_test_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_save_teacher_made_test_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_save_teacher_made_test_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_save_teacher_made_test_init());
    axios
      .post(
        `/opanda/tests/saveteachermadetest`,
        {
          idOfTest: data.idOfTest,
          academic_level: data.academic_level,
          names: data.names,
          userAnsweringMap: data.userAnsweringMap,
          user_id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_save_teacher_made_test_success(response.data));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_save_teacher_made_test_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_save_teacher_made_test_start_auto(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_save_teacher_made_test_init());
    axios
      .post(
        `/opanda/tests/saveteachermadetest`,
        {
          idOfTest: data.idOfTest,
          academic_level: data.academic_level,
          names: data.names,
          userAnsweringMap: data.userAnsweringMap,
          user_id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_save_teacher_made_test_success(response.data));
        eventBus.emit('showResultsAfterTestSave');
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_save_teacher_made_test_failed(err.message));
        alert("Network Error!!")
      });
  };
}
export function student_dashboard_homepage_save_teacher_made_test_start_for_challenges(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_save_teacher_made_test_init());
    axios
      .post(
        `/opanda/tests/saveteachermadetest_for_challenges`,
        {
          idOfTest: data.idOfTest,
          academic_level: data.academic_level,
          names: data.names,
          userAnsweringMap: data.userAnsweringMap,
          user_id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_save_teacher_made_test_success(response.data));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_save_teacher_made_test_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_save_teacher_made_test_start_for_challenges_auto(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_save_teacher_made_test_init());
    axios
      .post(
        `/opanda/tests/saveteachermadetest_for_challenges`,
        {
          idOfTest: data.idOfTest,
          academic_level: data.academic_level,
          names: data.names,
          userAnsweringMap: data.userAnsweringMap,
          user_id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_save_teacher_made_test_success(response.data));
        eventBus.emit('showResultsAfterTestSave');
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_save_teacher_made_test_failed(err.message));
        alert("Network Error!!")
      });
  };
}
//---Save auto made test--
export const STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_FAILED";

export function student_dashboard_homepage_save_auto_made_test_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_INIT
  };
}
export function student_dashboard_homepage_save_auto_made_test_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_save_auto_made_test_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_save_auto_made_test_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_save_auto_made_test_init());
    axios
      .post(
        `/opanda/tests/saveautomadetest`,
        {
          academic_level: data.academic_level,
          user_id: data.user_id,
          //----Algo test v2 type questions
          fillinQuestions: data.fillinQuestions,
          matchingQuestions: data.matchingQuestions,
          mapfillin_algov2: data.mapfillin_algov2,//Ex--"73,74,81,80,72,82"
          matching_algov2: data.matching_algov2,//--EX 	"128"
          //---Auto test algo v1
          radiosSingleData: data.radiosSingleData,
          checkboxMultipleData: data.checkboxMultipleData,
          //-----
          difficulty_level: data.difficulty_level,
          time_leftOn_test: data.time_leftOn_test,
          total_time_oftest: data.total_time_oftest,
          allTheTestAiuniqId: data.allTheTestAiuniqId,
          autoQuestionPool: data.autoQuestionPool,
          unitsUsed: data.unitsUsed,
          total_nberOf_questions: data.total_nberOf_questions,
          auto_total_marks: data.auto_total_marks

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_save_auto_made_test_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_save_auto_made_test_failed(err.message));
      });
  };
}

export function student_dashboard_homepage_save_auto_made_test_start_auto(data) {  
  return (dispatch) => {
    dispatch(student_dashboard_homepage_save_auto_made_test_init());
    axios
      .post(
        `/opanda/tests/saveautomadetest`,
        {
          academic_level: data.academic_level,
          user_id: data.user_id,
          //----Algo test v2 type questions
          fillinQuestions: data.fillinQuestions,
          matchingQuestions: data.matchingQuestions,
          mapfillin_algov2: data.mapfillin_algov2,//Ex--"73,74,81,80,72,82"
          matching_algov2: data.matching_algov2,//--EX 	"128"
          //---Auto test algo v1
          radiosSingleData: data.radiosSingleData,
          checkboxMultipleData: data.checkboxMultipleData,
          //-----
          difficulty_level: data.difficulty_level,
          time_leftOn_test: data.time_leftOn_test,
          total_time_oftest: data.total_time_oftest,
          allTheTestAiuniqId: data.allTheTestAiuniqId,
          autoQuestionPool: data.autoQuestionPool,
          unitsUsed: data.unitsUsed,
          total_nberOf_questions: data.total_nberOf_questions,
          auto_total_marks: data.auto_total_marks

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        alert("Test was automatically saved!!")
        eventBus.emit('showResultsAfterTestSave');
        dispatch(student_dashboard_homepage_save_auto_made_test_success(response));        
      })
      .catch((err) => {       
        dispatch(student_dashboard_homepage_save_auto_made_test_failed(err.message));
        alert(err)
      });
  };
}
export function student_dashboard_homepage_save_auto_made_test_start_for_challenges(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_save_auto_made_test_init());
    axios
      .post(
        `/opanda/tests/saveautomadetest_for_challenges`,
        {
          academic_level: data.academic_level,
          user_id: data.user_id,
          //----Algo test v2 type questions
          fillinQuestions: data.fillinQuestions,
          matchingQuestions: data.matchingQuestions,
          mapfillin_algov2: data.mapfillin_algov2,//Ex--"73,74,81,80,72,82"
          matching_algov2: data.matching_algov2,//--EX 	"128"
          //---Auto test algo v1
          radiosSingleData: data.radiosSingleData,
          checkboxMultipleData: data.checkboxMultipleData,
          //-----
          difficulty_level: data.difficulty_level,
          time_leftOn_test: data.time_leftOn_test,
          total_time_oftest: data.total_time_oftest,
          allTheTestAiuniqId: data.allTheTestAiuniqId,
          autoQuestionPool: data.autoQuestionPool,
          unitsUsed: data.unitsUsed,
          total_nberOf_questions: data.total_nberOf_questions,
          auto_total_marks: data.auto_total_marks

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_save_auto_made_test_success(response));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_save_auto_made_test_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_save_auto_made_test_start_for_challenges_auto(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_save_auto_made_test_init());
    axios
      .post(
        `/opanda/tests/saveautomadetest_for_challenges`,
        {
          academic_level: data.academic_level,
          user_id: data.user_id,
          //----Algo test v2 type questions
          fillinQuestions: data.fillinQuestions,
          matchingQuestions: data.matchingQuestions,
          mapfillin_algov2: data.mapfillin_algov2,//Ex--"73,74,81,80,72,82"
          matching_algov2: data.matching_algov2,//--EX 	"128"
          //---Auto test algo v1
          radiosSingleData: data.radiosSingleData,
          checkboxMultipleData: data.checkboxMultipleData,
          //-----
          difficulty_level: data.difficulty_level,
          time_leftOn_test: data.time_leftOn_test,
          total_time_oftest: data.total_time_oftest,
          allTheTestAiuniqId: data.allTheTestAiuniqId,
          autoQuestionPool: data.autoQuestionPool,
          unitsUsed: data.unitsUsed,
          total_nberOf_questions: data.total_nberOf_questions,
          auto_total_marks: data.auto_total_marks

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_save_auto_made_test_success(response));
        eventBus.emit('showResultsAfterTestSave');
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_save_auto_made_test_failed(err.message));
        alert("Network Error!!");
      });
  };
}
//--Get auto made test results and answers
export const STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_FAILED";

export function student_dashboard_homepage_results_for_auto_made_test_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_INIT
  };
}
export function student_dashboard_homepage_results_for_auto_made_test_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_results_for_auto_made_test_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_results_for_auto_made_test_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_results_for_auto_made_test_init());
    axios
      .post(
        `/opanda/tests/getautomadetestinstantresults`,
        {
          test_id: data.test_id,
          user_id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_results_for_auto_made_test_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_results_for_auto_made_test_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_results_for_auto_made_test_start_for_challenges(data) {
  console.log(data)
  return (dispatch) => {
    dispatch(student_dashboard_homepage_results_for_auto_made_test_init());
    axios
      .post(
        `/opanda/tests/getautomadetestinstantresults_for_challenges`,
        {
          test_id: data.test_id,
          user_id: data.user_id,
          challengeId: data.challengeId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_results_for_auto_made_test_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_results_for_auto_made_test_failed(err.message));
      });
  };
}
//--Get teacher made test results and answers
export const STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_FAILED";

export function student_dashboard_homepage_results_for_teacher_made_test_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_INIT
  };
}
export function student_dashboard_homepage_results_for_teacher_made_test_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_results_for_teacher_made_test_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_results_for_teacher_made_test_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_results_for_teacher_made_test_init());
    axios
      .post(
        `/opanda/tests/getteachermadetestinstantresults`,
        {
          test_id: data.test_id,
          user_id: data.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_results_for_teacher_made_test_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_results_for_teacher_made_test_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_results_for_teacher_made_test_start_for_challenges(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_results_for_teacher_made_test_init());
    axios
      .post(
        `/opanda/tests/getteachermadetestinstantresults_for_challenges`,
        {
          test_id: data.test_id,
          user_id: data.user_id,
          challengeId: data.challengeId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_results_for_teacher_made_test_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_results_for_teacher_made_test_failed(err.message));
      });
  };
}
//----Get teacher made test answer sheet-----------
export const STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_FAILED";

export function student_dashboard_homepage_get_teacher_made_test_answer_sheet_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_INIT
  };
}
export function student_dashboard_homepage_get_teacher_made_test_answer_sheet_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_teacher_made_test_answer_sheet_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_teacher_made_test_answer_sheet_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/getTacherMadeTestAnswerSheetOfUser`,
        {
          test_id: data.test_id,
          user_id: data.user_id,
          answering_map_id: data.answering_map_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_get_teacher_made_test_answer_sheet_start_for_challenges(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/getTacherMadeTestAnswerSheetOfUser_for_challenges`,
        {
          user_id: data.user_id,
          challengeId: data.challengeId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_get_teacher_made_test_answer_sheet_start_for_progress_stats(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/getTacherMadeTestAnswerSheetOfUser_for_progress_stats`,
        {
          user_id: data.user_id,
          testid: data.testid,
          useranswerid: data.useranswerid
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_teacher_made_test_answer_sheet_failed(err.message));
      });
  };
}
//----Get auto made test answer sheet-----------
export const STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_FAILED";

export function student_dashboard_homepage_get_auto_test_answer_sheet_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_INIT
  };
}
export function student_dashboard_homepage_get_auto_test_answer_sheet_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_get_auto_test_answer_sheet_failed(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_get_auto_test_answer_sheet_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/getAutoMadeTestAnswerSheetOfUser`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level,
          units: data.units,
          subject: data.subject,
          difficulty: data.difficulty
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_get_auto_test_answer_sheet_start_for_challenges(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/getAutoMadeTestAnswerSheetOfUser_for_challenges`,
        {
          user_id: data.user_id,
          challengeId: data.challengeId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_failed(err.message));
      });
  };
}
export function student_dashboard_homepage_get_auto_test_answer_sheet_start_for_tests_stats(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/getAutoMadeTestAnswerSheetOfUser_for_tests_stats`,
        {
          user_id: data.user_id,
          testid: data.testid,
          academic_level: data.academic_level,
          subject: data.subject
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_get_auto_test_answer_sheet_failed(err.message));
      });
  };
}
//----create a challenge from existing teacher made test-----------
export const STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_INIT =
  "STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_INIT";
export const STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_SUCCESS =
  "STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_SUCCESS";
export const STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_FAILED =
  "STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_FAILED";

export function student_dashboard_create_teacher_made_challenge_init() {
  return {
    type: STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_INIT
  };
}
export function student_dashboard_create_teacher_made_challenge_success(data) {
  return {
    type: STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_SUCCESS,
    payload: data
  };
}
export function student_dashboard_create_teacher_made_challenge_failed(error) {
  return {
    type: STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_FAILED,
    payload: error
  };
}

export function student_dashboard_create_teacher_made_challenge_start(data) {

  return (dispatch) => {
    dispatch(student_dashboard_create_teacher_made_challenge_init());
    axios
      .post(
        `/opanda/tests/create_teacher_made_test_challenge`,
        {
          user_id: data.user_id,
          test_id: data.answering_map_id,
          test_id_by_user: data.test_id_by_user,
          userMarks: data.userMarks
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_create_teacher_made_challenge_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_create_teacher_made_challenge_failed(err.message));
      });
  };
}
//----create a challenge from existing auto made test-----------
export const STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_INIT =
  "STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_INIT";
export const STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_SUCCESS =
  "STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_SUCCESS";
export const STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_FAILED =
  "STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_FAILED";

export function student_dashboard_create_auto_made_challenge_init() {
  return {
    type: STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_INIT
  };
}
export function student_dashboard_create_auto_made_challenge_success(data) {
  return {
    type: STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_SUCCESS,
    payload: data
  };
}
export function student_dashboard_create_auto_made_challenge_failed(error) {
  return {
    type: STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_FAILED,
    payload: error
  };
}

export function student_dashboard_create_auto_made_challenge_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_create_auto_made_challenge_init());
    axios
      .post(
        `/opanda/tests/create_auto_made_test_challenge`,
        {
          test_id: data.test_id,
          user_id: data.user_id,
          userMarks: data.userMarks,
          level: data.level,
          subject: data.subject,
          units: data.units
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_create_auto_made_challenge_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_create_auto_made_challenge_failed(err.message));
      });
  };
}
//----challenge stats-----------
export const STUDENT_DASHBOARD_CHALLENGES_STATS_INIT =
  "STUDENT_DASHBOARD_CHALLENGES_STATS_INIT";
export const STUDENT_DASHBOARD_CHALLENGES_STATS_SUCCESS =
  "STUDENT_DASHBOARD_CHALLENGES_STATS_SUCCESS";
export const STUDENT_DASHBOARD_CHALLENGES_STATS_FAILED =
  "STUDENT_DASHBOARD_CHALLENGES_STATS_FAILED";

export function student_dashboard_challenges_stats_init() {
  return {
    type: STUDENT_DASHBOARD_CHALLENGES_STATS_INIT
  };
}
export function student_dashboard_challenges_stats_success(data) {
  return {
    type: STUDENT_DASHBOARD_CHALLENGES_STATS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_challenges_stats_failed(error) {
  return {
    type: STUDENT_DASHBOARD_CHALLENGES_STATS_FAILED,
    payload: error
  };
}

export function student_dashboard_challenges_stats_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_challenges_stats_init());
    axios
      .post(
        `/opanda/tests/challenges_stats`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_challenges_stats_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_challenges_stats_failed(err.message));
      });
  };
}
//--Tests and assessments progress--
export const STUDENT_DASHBOARD_ASSESSMENTS_STATS_INIT =
  "STUDENT_DASHBOARD_ASSESSMENTS_STATS_INIT";
export const STUDENT_DASHBOARD_ASSESSMENTS_STATS_SUCCESS =
  "STUDENT_DASHBOARD_ASSESSMENTS_STATS_SUCCESS";
export const STUDENT_DASHBOARD_ASSESSMENTS_STATS_FAILED =
  "STUDENT_DASHBOARD_ASSESSMENTS_STATS_FAILED";

export function student_dashboard_assessments_stats_init() {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_STATS_INIT
  };
}
export function student_dashboard_assessments_stats_success(data) {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_STATS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_assessments_stats_failed(error) {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_STATS_FAILED,
    payload: error
  };
}

export function student_dashboard_assessments_stats_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_assessments_stats_init());
    axios
      .post(
        `/opanda/tests/tests_assessments_stats`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_assessments_stats_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_assessments_stats_failed(err.message));
      });
  };
}
//--Tests and assessments progress item results--
export const STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_INIT =
  "STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_INIT";
export const STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_SUCCESS =
  "STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_SUCCESS";
export const STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_FAILED =
  "STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_FAILED";

export function student_dashboard_assessments_progress_item_results_init() {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_INIT
  };
}
export function student_dashboard_assessments_progress_item_results_success(data) {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_assessments_progress_item_results_failed(error) {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_FAILED,
    payload: error
  };
}

export function student_dashboard_assessments_progress_item_results_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_assessments_progress_item_results_init());
    axios
      .post(
        `/opanda/tests/test_assessments_progress_item_results`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_assessments_progress_item_results_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_assessments_progress_item_results_failed(err.message));
      });
  };
}
//--Tests and assessments progress item answer sheet--
export const STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_INIT =
  "STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_INIT";
export const STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_SUCCESS =
  "STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_SUCCESS";
export const STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_FAILED =
  "STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_FAILED";

export function student_dashboard_assessments_progress_item_answer_sheet_init() {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_INIT
  };
}
export function student_dashboard_assessments_progress_item_answer_sheet_success(data) {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_SUCCESS,
    payload: data
  };
}
export function student_dashboard_assessments_progress_item_answer_sheet_failed(error) {
  return {
    type: STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_FAILED,
    payload: error
  };
}

export function student_dashboard_assessments_progress_item_answer_sheet_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_assessments_progress_item_answer_sheet_init());
    axios
      .post(
        `/opanda/tests/test_assessments_progress_item_answer_sheet`,
        {
          user_id: data.user_id,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_assessments_progress_item_answer_sheet_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_assessments_progress_item_answer_sheet_failed(err.message));
      });
  };
}