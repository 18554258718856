import { updateObject } from '../../shared/utility';
import {
  CHANGE_LANGUAGE,
  CHANGE_SELECTED_COMMENT_TEXT,
} from '../actions/languages';
import EN from '../../images/en.svg';
import FR from '../../images/fr.svg';

const initialReducer = {
  selectedLanguage: 'en',
  languages: [
    { name: 'English', flag: EN, abbreviation: 'en' },
    { name: 'French', flag: FR, abbreviation: 'fr' },
  ],
  languagesError: null,
  languagesLoading: false,
  language: null,
  selectedCommentText: '',
};

const changeLanguage = (state, action) => {
  return updateObject(state, {
    language: action.language,
  });
};

const changeSelectedCommenttext = (state, action) => {
  return updateObject(state, {
    selectedCommentText: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return changeLanguage(state, action);
    case CHANGE_SELECTED_COMMENT_TEXT:
      return changeSelectedCommenttext(state, action);
    default:
      return state;
  }
};

export default reducer;
