import { useHistory } from "react-router-dom";
import PrevNext from "./PrevNext";
import image from "../../../../images/signup.png";
import { CardBody } from "../../../elements/text";
import Otp from "./otp";
import { useEffect, useRef, useState } from "react";
import { Button, OtherLinkButton } from "../../../elements/button";
import { authenticate } from "../../../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/";
import Spinner from "../../../elements/spinner";
import { Message } from "../../../common/messages";
import constants from "../../../../constants/constants";

const Verification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [minutes, setminutes] = useState("00");
  const [seconds, setseconds] = useState("00");
  const [otperror, setotperror] = useState("");
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);
  const changeOtpInputHandler = (event, index) => {
    if (isNaN(event.target.value)) return false;
    setOtp([
      ...otp.map((num, idx) => (idx === index ? event.target.value : num)),
    ]);
    if (event.target.nextSibling) {
      event.target.nextSibling.focus();
    }
  };

  function verification() {
    const otpjoin = otp.join("");
    if (otpjoin === auth?.user?.otp) {
      // dispatch(actions.login(auth?.user?.email, "", true));       
      history.push("/login");
    } else {
      setotperror(`The OTP code is incorrect`);
      //   alert(`The OTP code is incorrect`);
    }
  }


  let interval = useRef();
  const loading = useSelector((state) => state.auth.loading);
  const setTimer = () => {
    var countDownDate = new Date(new Date().getTime() + 3 * 60000).getTime();
    interval = setInterval(() => {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var secs = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setminutes(mins);
        setseconds(secs);
      }
    }, 1000);
  };
  const secondPhone = localStorage.getItem("secondPhone");
  const resendotp = (e) => {
    const object = { email: user?.email, secondPhone, phone: user?.telephone };
    //localStorage.removeItem("secondPhone");
    dispatch(actions.resendotp(object));
    setotperror("")
    setTimer();
    return () => {
      clearInterval(interval.current);
    };
  };

  useEffect(() => {
    setTimer();
    return () => {
      clearInterval(interval.current);
    };

  }, [auth]);

  return (
    <div className="h-full bg-gray-200 pb-5">
      {/*<PrevNext
        px="5"
        previousLink="enroll"
        previousName="Registration"
        pageName="Verification"
        nextLink="payment"
        nextName="Pricing Package"
  />*/}

      <div className="lg:w-80% lg:flex flex-row justify-center m-auto lg:max-h-96">
        <div className="hidden lg:block w-1/2 max-h-110 h-auto lg:rounded-l-lg">
          <img
            className="w-full h-full object-cover object-center lg:rounded-l-lg"
            src={image}
            alt=""
          />
        </div>
        {loading ? (
          <div className="w-full justify-center flex">
            {" "}
            <Spinner size="32" color="blue" />
          </div>
        ) : (
          <div className="bg-white rounded-xl w-90% lg:w-1/2 lg:rounded-l-none lg:rounded-r-lg m-auto lg:m-0 px-3 py-5 lg:p-10">
            <CardBody
              name={`The verification code and link were sent to ${user?.telephone} and ${user?.username}.
            Please enter the code below or click the link through your email.`}
              color="black"
            />
            <Otp otp={otp} changeOtpInputHandler={changeOtpInputHandler} />
            <Message type={"error"} viewable={true} message={otperror} />
            <div className="flex flex-wrap">
              <CardBody name={`Didn't receive the code? ${(minutes > 0 || seconds > 0) ? " Resend in " : " "}`} color="black" />
              {(minutes == 0 && seconds == 0) ? <OtherLinkButton
                color="blue"
                to="#"
                name="Resend"
                onClick={resendotp}
              /> : ""}
              {(minutes > 0 || seconds > 0) ? <div className="text-center px-5"><CardBody name={` ${(minutes > 9) ? " " + minutes : " " + 0 + "" + minutes}:${(seconds > 9) ? seconds : 0 + "" + seconds} `} color={(minutes == 0 && seconds <= 30) ? "red" : "black"} /></div> : ""}
            </div>

            {(minutes > 0 || seconds > 0) ? <div className="w-full flex justify-center py-3">
              <Button
                name="Verify"
                outline="true"
                color="blue"
                clicked={() => {
                  verification();
                  // history.push("");
                }}
              />
            </div> : ""}
            {/*<div className="flex flex-row flex-wrap">
              <CardBody
                name="Have an issue with the contact provided?&nbsp;"
                color="black"
              />
              <OtherLinkButton color="blue" to="/edit" name="Edit" />
            </div>*/}
          </div>
        )}
      </div>
    </div>
  );
};

export default Verification;
