import { CBC } from './cbc';
import SideBar from './sidebar';
import TopNavBar from './topnavbar';

import ContentModal from '../../../elements/modal/contentmodal';
import { connect } from 'react-redux';
import { TabPanel } from '../../../elements/tab/tabpanel';
import { store } from '../../../../store/store';
import Input from '../../../elements/input';
import { CardBody, CardTitle, SectionTitle } from '../../../elements/text';
import Modal from '../../../elements/modal/modal';
import { useState } from 'react';
import { Button } from '../../../elements/button';
import { useHistory } from 'react-router-dom';

const PageContent = (props) => {
  const { tabselected } = props;
  const [code, setCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);

  const history = useHistory();

  const doasguest=()=>{
    if(code!==""){
  setIsModalOpen(false);
    history.push(`/previewpanda/tests/${code}`);
    }else{
      alert(`enter the test code first`);
    }
  }

  return (
    <div className="bg-white h-full w-full grid grid-cols-3 md:grid-cols-4 relative">
      <ContentModal />
      <SideBar />
      <TopNavBar />
      <Modal small show={isModalOpen} modalClosed={() => {}}>
        <SectionTitle name="You're accessing in as:  Guest" color="blue" />
        <CardTitle name="Instruction" color="blue" />
        <CardBody
          name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet lectus sit amet ex finibus, at eleifend enim lobortis. Donec egestas elementum mauris sit amet varius. Maecenas rutrum molestie augue, sed dapibus tellus consectetur id. "
          color="black"
        />
        <div className="flex flex-col space-y-4 items-center">
          <Input
            elementType="input"
            elementConfig={{
              type: 'text',
              placeholder: 'XXXX',
            }}
            value={code}
            changed={setCode}
          />
          <Button
            name="Enter"
            outline="true"
            color="blue"
            clicked={doasguest}
          />
        </div>
      </Modal>
      <div className="bg-white col-span-3 md:col-span-3 py-10 px-6">
        <TabPanel panel="cbc" tabselected={tabselected}>
          <CBC />
        </TabPanel>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
  };
};
const mapDispatchToProps = () => {
  return {
    changetab: (tab) => store.dispatch({ type: 'CHANGE_TAB', payload: tab }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
