import Payment from '../../../components/pages/school/user/payment/index';
import Layout from '../../../components/common/Layout';

const SchoolPaymentContainer = () => {
  return (
    <Layout>
      <Payment />
    </Layout>
  );
};

export default SchoolPaymentContainer;