import { useEffect, useState } from "react";
import axios from "../../../axios-base";
import { useSelector } from "react-redux";
import Spinner from "../spinner";
import UserCard from "./UserCard ";

const UserRegistrationStatus = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const userAuth = useSelector((state) => state.auth);
  const [status, setStatus] = useState("");
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`/opanda/manage-users/registration-status`, {
        otp: props.token
      },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userAuth.token}`
          }
        })
      .then((response) => {
        if (response.data.message === "OTP used") {
          setStatus(response.data.message);
          setUserData(response.data.results);
        } else {
          setStatus(response.data.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err)
      });

  }, []);

  return (
    (!isLoading) ? (status === "OTP used") ? <span><UserCard userData={userData} /></span> : <span style={{fontWeight:"bold"}}>{status}</span> : <Spinner size="5" color="blue" />
  );
};

export default UserRegistrationStatus;

