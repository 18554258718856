import Footer from "../components/common/footer";
import Header from "../components/common/header/header";
import ScrollToTop from "../components/common/ScrollToTop";
import PageContent from "../components/pages/landing/sponsorship/pageContent";

const Sponsorship = () => {
  return (
    <div className="scrollbar-hidden">
      <Header />
      <PageContent />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Sponsorship;
