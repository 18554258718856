import axios from "../../axios-base";


export const STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION__INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION__SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_DATACOLLECTION__FAILED";

export function student_datacollection_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_INIT
  };
}
export function student_datacollection_success(data) { 
  
  
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_SUCCESS,
    payload: data
  };
}
export function student_datacollection_fail(error) {
    return {
    type: STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_FAILED,
    payload: error
  };
}

export function student_datacollection_start(data) {

  
  return (dispatch) => {
    dispatch(student_datacollection_init());

    axios
      .post(
        `/opanda/metadata/summarynotes/collect`,
        {
          userId : "992",
          username : "cyumacedro",
          day : "1651744058975 ",
         // "gender" : "male",
         // "age" : "18",
          scientific_subject :"BIOLOGY",
          //"user_type" : "student",
          //"school_name" : "GS Gashora",
          //"school_province" : "Kigali",
          //"school_district" : "Gasabo",
          //"school_sector" : "Kinyinya",
          //"school_legal_status" : "private",
          user_gps_coords : "lat,lng",
          viewership_metadata : "Unit 7: Food nutrients and diet.",
          academic_level : "1",
          activity_item_id : "107"
          
     },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        
        dispatch(student_datacollection_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_datacollection_fail(err.message));
      });
  };
}

