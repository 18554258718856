import TableBody from "./tbodyv1";
import NewTableBody from "./tableBody";
import TableHead, {TableHeader} from "./thead";

const Table = (props) => {

  return (
    <table className="w-full table-auto text-sm text-left text-gray-500 dark:text-gray-400 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      <TableHead thead ={props.thead} />
      <TableBody thead={props.thead} tbody={props.tbody}  colspan={props.colspan}/>
    </table>
  );
};

export default Table;

export const LinkedStudentTable = (props) => {

  return (
    <table className="w-150 sm:w-full table-auto text-sm text-left text-gray-500 dark:text-gray-400">
      <TableHeader thead ={props.thead} />
      <TableBody tbody={props.tbody}  colspan={props.colspan}/>
    </table>
  );
};

export const PaymentsTable = (props) => {

  return (
    <table className="w-150 sm:w-full text-sm text-left text-gray-500 dark:text-gray-400 table-auto">
      <TableHeader thead ={props.thead} />
      <NewTableBody tbody={props.tbody}  colspan={props.colspan}/>
    </table>
  );
};
