import PageContent from '../pageContent';

const Cambridge = () => {
  return (
    <PageContent>
      <div>Cambridge</div>
    </PageContent>
  );
};

export default Cambridge;
