import { CardBody } from "../../../elements/text";
import { Link } from "react-router-dom";

const Item = (props) => {
  let itemtype = "link";
  if ("type" in props) {
    itemtype = props.type;
  }
 const item = itemtype === "link" ?  <Link to={props.link} className="flex flex-col space-y-2 items-center m-5">
  <div className="w-16 h-16 rounded-full bg-yellow flex justify-center items-center">
    {props.children}
  </div>
  <CardBody name={props.name} />
</Link> : <div  className="flex flex-col space-y-2 items-center m-5 cursor-pointer" onClick={props.link}>
<div className="w-16 h-16 rounded-full bg-yellow flex justify-center items-center">
    {props.children}
  </div>
  <CardBody name={props.name} />
</div>
  return (
   item
  );
};

export default Item;
