import { useState } from 'react';
import PageContent from "../../pageContent";
import Notification from "../../../../elements/notification/notification";
import { useHistory } from "react-router-dom";
import {
  CardTitle,
  FeatureTitle,
  PageTitle,
  SmallText,
} from "../../../../elements/text";
import Table, {LinkedStudentTable} from "../../../../elements/table/table";
import { Button, TextButton } from "../../../../elements/button";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { PopupWindow } from '../../../../elements/popupWindow';

const LinkNewAccount = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const linkedTableHeader = ["Name", "User type", "status", "Linked on"];
  const pendingTableHeader = ["Name", "User type", "status", "Added on", "Action"];
  const users = [
    {
      id: "1",
      name: "ISHIMWE Diane",
      type: "Student",
      status: "linked",
      addedon: "a month ago",
    },
    {
      id: "1",
      name: "MANZI Kevin",
      type: "Student",
      status: "pending",
      linkedon: "a week ago",
    },
  ];

  const linkedStudents = [];
  const pendingStudentsTable = [];
  Object.values(users.filter((user) => user.status === "linked")).forEach(
    (value) => {

      const keys = Object.values(value);
      linkedStudents.push({
        td1: { data: keys[1], type: "text" },
        td2: { data: keys[2], type: "text" },
        td3: { data: keys[3], type: "text" },
        td4: { data: keys[4], type: "text" },
      });
    }
  );

  Object.values(users.filter((user) => user.status === "pending")).forEach(
    (value) => {
      const keys = Object.values(value);
      const unLinkButton = (
        <>
          <TextButton
            name="Cancel"
            color="red"
            clicked={() => {
              history.push(`/parent/user/linkedaccounts/${keys[0]}`); 
            }}
          />
        </>
      );
      pendingStudentsTable.push({
        td1: { data: keys[1], type: "text" },
        td2: { data: keys[2], type: "text" },
        td3: { data: keys[3], type: "text" },
        td4: { data: keys[4], type: "text" },
        td5: {
          data: <>{unLinkButton}</>,
          type: "component",
        },
      });
    }
  );

  return (
    <PageContent>
      <div
        className={`md:grid  bg-white-blue grid-cols-12 w-full h-full md:max-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-9`}>
          <FeatureTitle name="Link accounts" />
          <div className="p-3 space-y-4">
            <span className="flex flex-col justify-start items-start sm:justify-items-center sm:items-center sm:flex-row">
              <div className='flex items-center '>
              <CardTitle name="USER ID:" color="blue" />
              <AiOutlineInfoCircle
                color="#183F71"
                size={18}
              ></AiOutlineInfoCircle>
              </div>
              <div className="flex flex-row items-center p-2 bg-white relative rounded-lg mb-2 sm:h-10 sm:w-80 shadow-md sm:ml-4">
                <SmallText name='This user-id is used to link your account to your child' color='blue'/>
              </div>
            </span>
            <span className="flex flex-col sm:flex-row sm:justify-items-center sm:items-center sm:space-x-4">
            <div className='flex flex-col-reverse md:flex-col'>
            <input type="text"  placeholder="Add ID" className="bg-white relative overflow-x-auto rounded-lg sm:h-20 sm:w-80 shadow-md border-0 items-start" />
            <SmallText name='Add your child`s User ID' color='blue'/>
            </div>
            <div className="w-50 sm:full my-2"> 
              <Button
                name="Add"
                outline="true"
                color="blue"
                isSmall
                data-modal-toggle="popup-modal"
                clicked={() => {
                  setIsOpen(true)
              }}
              />
              </div>
            </span>
          </div>
          <div className="space-y-4">
            <CardTitle name="Linked accounts" color="blue" />
            <div className="relative overflow-x-auto sm:rounded-lg mb-2 h-40">
              {linkedStudents.length > 0 ? (
                <LinkedStudentTable thead={linkedTableHeader} tbody={linkedStudents} />
                ):(
                  ''
                )}
            </div>
            <CardTitle name="Pending requests" color="blue" />
            <div className="relative overflow-x-auto sm:rounded-lg  mb-2 h-40">
            {pendingStudentsTable.length > 0 ? (
              <LinkedStudentTable thead={pendingTableHeader} tbody={pendingStudentsTable} />
            ):(
                  ''
                )}
            </div>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default LinkNewAccount;
