import { useSelector } from "react-redux";
import Spinner from "../../../elements/spinner";
import { PageBody, PageTitle } from "../../../elements/text";

const Impact = (props) => {
  const impacts = useSelector((state) => state.home.impacts);
  const impactsLoading = useSelector((state) => state.home.impactsLoading);
  return (
    <div className="bg-blue">
      {impactsLoading ? (
        <div className="flex flex-row justify-center">
          <Spinner size="20" color="white" />
        </div>
      ) : (
        <div className="w-full flex flex-col md:flex-row max-w-7xl mx-auto py-20 space-y-10 md:space-y-0 space-x-4 px-8 mx-5 md:py-40">
          <div className="flex-1">
            <PageTitle
              name={impacts[0]?.studentsandteachersnumbers}
              color="yellow"
              alignment="center"
              mobileAlignment="center"
            />
            <PageBody
              name={impacts[0]?.quoteaboutstudentsandteachersnumbers}
              color="white"
              alignment="center"
              mobileAlignment="center"
            />
          </div>
          <div className="flex-1">
            <PageTitle
              name={
                impacts[0]?.academicperformanceincreasesnumbersforstudents + "%"
              }
              color="yellow"
              alignment="center"
              mobileAlignment="center"
            />
            <PageBody
              name={
                impacts[0]
                  ?.quoteaboutacademicperformanceincreasesnumbersforstudents
              }
              color="white"
              alignment="center"
              mobileAlignment="center"
            />
          </div>
          <div className="flex-1">
            <PageTitle
              name={impacts[0]?.schoolsnumbers + "+"}
              color="yellow"
              alignment="center"
              mobileAlignment="center"
            />
            <PageBody
              name={impacts[0]?.quoteaboutschoolsnumbers}
              color="white"
              alignment="center"
              mobileAlignment="center"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Impact;
