import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../constants/constants";
import { Button, ButtonWithIcon } from "../../../elements/button";
import Input from "../../../elements/input";
import { CardSubText } from "../../../elements/text";
import Layout from "./layout";
import SummaryWrapper from "./summarywrapper";
import UsersTable from "./userstable";
import { FaFileExcel } from "react-icons/fa";

import {
  school_dashboard_users_count_start,
  school_dashboard_download_template_start,
  school_dashboard_upload_template_start,
} from "../../../../store/actions/school.dashboard.users";
import FileInput from "./FileInput";
import eventBus from "../../../../utils/eventBus";
const ImportForm = (props) => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.auth);
  const [fileuploaded, setFileuploaded] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  return (
    <>
      <div className="md:grid grid-cols-2 justify-items-stretch gap-2 items-start">
        <div>
          <CardSubText name={`Download excel template for ${(props?.isForStudentsOnly===true)?"students":"teachers or parents"}`} color="black" />
          <ButtonWithIcon
            name="Download"
            outline="true"
            color="blue"
            onClick={() =>
              dispatch(
                school_dashboard_download_template_start({
                  token: userAuth.token,
                  isForStudentsOnly:props.isForStudentsOnly
                })
              )
            }
          />
        </div>

        {/* <Input
          label="Upload excel file"
          elementType="fileupload"
          elementConfig={{
            type: "file",
          }}
          value={fileuploaded}
          changed={setFileuploaded}
          validation={{ required: true }}
          shouldValidate
          error="File is required"
        /> */}
        <FileInput isForStudentsOnly={props?.isForStudentsOnly} setIsProcessing={setIsProcessing} onCancel={props.onCancel} key={1} />
      </div>
      <div className="flex space-x-2 justify-center py-5">
        {(!isProcessing) ? <Button
          name="Cancel"
          outline="true"
          color="blue"
          clicked={() => {
            eventBus.emit('clearUploadExcelListOfUsersFileEvent');
            props.onCancel();
          }}
        /> : ""}
        {(!isProcessing) ? <Button
          name="Upload"
          outline="false"
          color="blue"
          clicked={(e) => {
            eventBus.emit('uploadExcelListOfUsersEvent');
          }}
        /> : ""}
      </div>
    </>
  );
};

export default ImportForm;
