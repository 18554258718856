import React, { useEffect, useState, useRef } from 'react';
import axios from "../../../../axios-base";
import { useSelector } from 'react-redux';
import Spinner from '../../../elements/spinner';
import AddMultipleQuestionFromQuestionBank from '../../../common/features/tests/testtypes/multipleChoice/AddMultipleQuestionFromQuestionBank';
import parse from "react-html-parser";
import NotFound from '../../../common/features/NotFound';
import { Button } from '../../../elements/button';
function TestEditorMultipleChoiceQuestionBank(props) {
    const [questions, setQuestions] = useState([]);
    const [questionsPicked, setQuestionsPicked] = useState([]);
    const [questionNber, setQuestionNber] = useState(-1);
    const [question_type, setQuestionType] = useState("");
    const [isShowMultipleChoiceModalShowing, setisShowMultipleChoiceModalShowing] = useState(false);
    const userAuth = useSelector((state) => state.auth);
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [InitialPage, setInitialPage] = useState(0);
    const scrollRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    // Function to convert base64 to Blob
    function base64ToBlob(base64Data) {
        const byteString = atob(base64Data.split(',')[1]);
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }
    // Function to upload base64 images as JPGs with enumerated names
    async function uploadImagesWithNames(images) {
        const uploadPromises = images.map(async (base64Data, index) => {
            const blob = base64ToBlob(base64Data);

            // Create a unique filename with timestamp and index
            const timestamp = Date.now();
            const fileName = `${timestamp}-teacher_made_tests_${Date.now()}_${index}.jpg`;

            // Create a FormData object and append the blob
            const formData = new FormData();
            formData.append('image', blob, fileName);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/opanda/uploadImages`, {
                    method: 'POST',
                    body: formData
                    // Add headers if needed, like authorization or content-type
                });

                if (response.ok) {
                    // Once the image is uploaded, return an object with base64 data and filename
                    return { base64Data, fileName };
                } else {
                    throw new Error('Upload failed');
                }
            } catch (error) {
                console.error('Error:', error);
                return null;
            }
        });

        return Promise.all(uploadPromises);
    }
    function reactToHtmlString(reactObject) {
        if (typeof reactObject === 'object' && reactObject["props"] && reactObject["props"]["children"]) {
            return (reactObject["props"]["children"].length > 0 ? reactObject["props"]["children"] : "");
        } else {
            return reactObject;
        }
    }
    const uploadMyImages = async (images_data, content) => {
        // Call the function to upload images and save key-value pairs after upload               
        const results = await uploadImagesWithNames(images_data)
        if (results.length <= 0) return content;
        const imageKeyValuePairs = {}; // Object to store image base64 and filenames

        results.forEach(result => {
            // Process each result from the backend
            if (result) {
                imageKeyValuePairs[result.fileName] = [result.base64Data, result.fileName];

            }
        });
        //--Replace all images
        let newPageContent = content
        Object.keys(imageKeyValuePairs).forEach(key => {
            newPageContent = newPageContent.replaceAll(imageKeyValuePairs[key][0], `${process.env.REACT_APP_BACKEND_URL}/simulation_thumbnails/${key}`);
        });
        return newPageContent;



    }
    const getAllImages = (html) => {
        const regex = /<img[^>]+src="([^">]+)"/g;
        const images = [];
        let match;

        while ((match = regex.exec(html))) {
            const imageUrl = match[1];
            if (imageUrl.startsWith('data:image')) {
                images.push(imageUrl);
            }
        }
        return images;
    };

    async function processQuestions(response) {
        const questions_ = response.data.data.questions;

        // Using Promise.all to handle an array of promises generated by map
        const multiple_choice_questions = await Promise.all(questions_.map(async (question) => {
            const questionImage = await uploadMyImages(getAllImages(question[0].panda_questions_auto_question_data), question[0].panda_questions_auto_question_data);
            const parsedQuestion = parse(questionImage);

            const explanationImages = await uploadMyImages(getAllImages(question[0].panda_answer_explanation), question[0].panda_answer_explanation);
            const parsedExplanation = parse(explanationImages) || "";

            // Handling answers as promises
            const answers = await Promise.all(question[1].map(async (answer) => {
                const answerImages = await uploadMyImages(getAllImages(answer.panda_auto_questions_answers_ans_data), answer.panda_auto_questions_answers_ans_data);
                const parsedAnswer = parse(answerImages);
                return {
                    value: parsedAnswer,
                    isAnswer: answer.panda_auto_questions_answers_isanswer == 1
                };
            }));
            var explanation = reactToHtmlString(parsedExplanation)
            // check if explanation is array
            if (Array.isArray(explanation)) {
                explanation = explanation[0]
            }

            return {
                question: parsedQuestion,
                answers: answers,
                marks: question[0].panda_questions_auto_total_marks,
                duration: question[0].panda_questions_auto_time_assigned_in_seconds,
                explanation: explanation,
                type: 'multiple_choice'
            };
        }));

        return multiple_choice_questions;
    }



    useEffect(() => {
        setIsQuestionsLoading(true);
        axios
            .post(
                `/opanda/tests/getPaginatedQuestionsFromOpandaStorage`,
                {
                    unit: props.unit,
                    level: props.level,
                    subject: props.subject,
                    pageNumber: pageNumber,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userAuth.token}`,
                    },
                }
            )
            .then(async (response) => {
                // Call the function and handle the result
                processQuestions(response).then(questions_ => {
                    setIsQuestionsLoading(false);
                    setPageNumber((questions_.filter((question_, i) => question_.answers.filter((answer) => answer.value !== "").length > 0).length > 0 && questions_.filter((question_, i) => question_.answers.filter((answer) => answer.value !== "").length > 0).length >= 5) ? response.data.data.questions[4][0].panda_questions_auto_id : 0);
                    setInitialPage((questions_.filter((question_, i) => question_.answers.filter((answer) => answer.value !== "").length > 0).length > 0 && questions_.filter((question_, i) => question_.answers.filter((answer) => answer.value !== "").length > 0).length >= 5) ? response.data.data.questions[4][0].panda_questions_auto_id : 0)
                    setQuestions(questions_.filter((question_, i) => question_.answers.filter((answer) => answer.value !== "").length > 0));

                }).catch(error => {
                    console.error('Error processing questions:', error);
                });
            })
            .catch((err) => {
                console.log(err)
                setIsQuestionsLoading(false);
            });
    }, []);

    // Effect to add and remove the scroll event listener
    useEffect(() => {
        // This function gets called whenever the user scrolls
        const handleScroll = () => {
            if (scrollRef.current) {
                setScrollPosition(scrollRef.current.scrollTop);  // Update scroll position state
            }
        };

        // Check if the ref is attached and then add the event listener
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', handleScroll);
        }

        // Cleanup function to remove the event listener when the component unmounts or updates
        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef]);  // Empty dependency array means this effect runs only on mount and unmount

    // Effect to restore scroll position after component updates
    useEffect(() => {
        if (scrollRef.current && scrollPosition !== undefined) {
            scrollRef.current.scrollTop = scrollPosition;  // Restore scroll position
        }
    }, [questions]);



    const loadMoreQuestions = () => {
        setIsQuestionsLoading(true);
        axios
            .post(
                `/opanda/tests/getPaginatedQuestionsFromOpandaStorage`,
                {
                    unit: props.unit,
                    level: props.level,
                    subject: props.subject,
                    pageNumber: pageNumber,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userAuth.token}`,
                    },
                }
            )
            .then((response) => {
                // Call the function and handle the result
                processQuestions(response).then(questions_ => {
                    setIsQuestionsLoading(false);
                    setPageNumber((questions_.filter((question_, i) => question_.answers.filter((answer) => answer.value !== "").length >= 5).length > 0 && questions_.filter((question_, i) => question_.answers.filter((answer) => answer.value !== "").length > 0).length >= 5) ? response.data.data.questions[4][0].panda_questions_auto_id : 0);


                    
                    const newQuestions = questions_.map((question_) => {
                        // get question_ and answer as first element of array from question_
                        const question = question_.question[0]
                        const answer = question_.answers.map((answer_) => {
                            return { value: answer_.value[0], isAnswer: answer_.isAnswer }
                        })
                        return {
                            question: question,
                            answers: answer,
                            marks: question_.marks,
                            duration: question_.duration,
                            explanation: question_.explanation,
                            type: 'multiple_choice'
                        }
                    });
                    const newA = [...newQuestions];
                    setQuestions(newA);



                }).catch(error => {
                    console.error('Error processing questions:', error);
                });
            })
            .catch((err) => {
                console.log(err)
                setIsQuestionsLoading(false);
            });
    }

    

    const selectQuestion = (questionNumber, value) => {

        if (value) {
            setQuestionsPicked([...questionsPicked, questions[questionNumber]]);
        } else {
            setQuestionsPicked([...questionsPicked.filter((question, i) => i !== questionNumber)]);
        }
    }



    return (
        (!isQuestionsLoading) ? <div className="p-4 ">
            <Button
                name="Add selected questions to your test"
                outline="true"
                color="blue"
                clicked={() => {
                    props.addQuestionsToUi(questionsPicked);
                }}
            />
            <div ref={scrollRef} style={{ height: '60vh', overflowY: "scroll" }} onScroll={(e) => {
                setScrollPosition(e.target.scrollTop)
            }}>
                <div className='p-4' style={{ borderBottom: "3px solid #cdcdcd", borderRadius: "4px" }}>{questions.map((qu, i) => {
                    return <AddMultipleQuestionFromQuestionBank selectQuestion={selectQuestion} questionNber={i} key={i} question={qu} />
                })}</div>

            </div>
            {(questions.length <= 0) ? <NotFound feature="questions" /> : <div className="flex justify-center mt-2">
                <Button
                    name="Get Another 5 Questions"
                    outline="true"
                    color="blue"
                    clicked={() => {
                        loadMoreQuestions();
                    }}
                /></div>}

        </div> : <div className="p-4"><Spinner size="32" color="blue" /></div>
    );
}

export default TestEditorMultipleChoiceQuestionBank;
