import { useState, useEffect } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import Input from "../../../elements/input";
import PageContent from "../pageContent";
import { useTabs } from "react-headless-tabs";
import { CardBody, FeatureTitle } from "../../../elements/text";
import { TabPanel } from "react-headless-tabs";
import GetStarted from "../../../common/features/GetStarted";
import NotFound from "../../../common/features/NotFound";
import TabSelector from "../../../elements/tab/TabSelector";
import PastpapersGroupItem from "./PastpapersGroupItem";
import { useSelector, useDispatch } from "react-redux";
import { student_pastpapers_subjects_start } from "../../../../store/actions/student.dashboard.pastpapers";
import Spinner from "../../../elements/spinner";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";

const Pastpapers = () => {
  const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState("S1");
  const [searchterm, setSearchterm] = useState("");
  const userAth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const studentDashboardPastpapers = useSelector(
    (state) => state.studentDashboardPastpapers
  );

  useEffect(() => {
    dispatch(
      student_pastpapers_subjects_start({
        user_id: userAth.userId,
        academic_level: userAth.academic_level,
        token: userAth.token,
      })
    );
  }, []);
  const subjects = [
    {
      name: "Biology",
      pastpapers: [
        {
          imgsrc: "/images/preview/bio.svg",
          type: "opanda",
          from: "National Exams",
          level: "A Level",
          time: "2021-2022",
          createdAt: "2022-02-10",
        },
        {
          imgsrc: "/images/preview/math.svg",
          type: "opanda",
          from: "District Exams",
          level: "Senior 2",
          time: "Term 1 2020",
          createdAt: "2022-02-10",
        },
        {
          imgsrc: "/images/preview/bio.svg",
          type: "opanda",
          from: "National Exams",
          level: "O Level",
          time: "2021-2022",
          createdAt: "2022-02-10",
        },
      ],
    },
    {
      name: "Math",
      pastpapers: [
        {
          imgsrc: "/images/preview/bio.svg",
          type: "opanda",
          from: "National Exams",
          level: "O Level",
          time: "2021-2022",
          createdAt: "2022-02-10",
        },
        {
          imgsrc: "/images/preview/bio.svg",
          type: "community",
          from: "District Exams",
          level: "Senior 5",
          time: "Term 2 2021",
          createdAt: "2022-02-10",
        },
        {
          imgsrc: "/images/preview/math.svg",
          type: "community",
          from: "National Exams",
          level: "E Level",
          time: "2021-2022",
          createdAt: "2022-02-10",
        },
        {
          imgsrc: "/images/preview/bio.svg",
          type: "community",
          from: "Mock National Exams",
          level: "A Level",
          time: "Term 3 2020",
          createdAt: "2022-02-10",
        },
      ],
    },
    {
      name: "History",
      pastpapers: [
        {
          imgsrc: "/images/preview/bio.svg",
          type: "opanda",
          from: "District Exams",
          level: "Senior 3",
          time: "Term 3 2020",
          createdAt: "2022-02-10",
        },
        {
          imgsrc: "/images/preview/bio.svg",
          type: "opanda",
          from: "District Exams",
          level: "Senior 4",
          time: "Term 1 2021",
          createdAt: "2022-02-10",
        },
        {
          imgsrc: "/images/preview/math.svg",
          type: "opanda",
          from: "National Exams",
          level: "E Level",
          time: "2021-2022",
          createdAt: "2022-02-10",
        },
        {
          imgsrc: "/images/preview/bio.svg",
          type: "opanda",
          from: "Mock National Exams",
          level: "Senior 3",
          time: "Term 2 2020",
          createdAt: "2022-02-10",
        },
      ],
    },
    {
      name: "Geography",
      pastpapers: [],
    },
    {
      name: "Physics",
      pastpapers: [],
    },
    {
      name: "English",
      pastpapers: [],
    },
  ];

  const chosenSubject = useSelector(
    (state) => state.features.pastpapers.chosenSubject
  );

  const [selectedTab, setSelectedTab] = useTabs(
    ["O'Genius Panda", "Community"],
    "O'Genius Panda"
  );

  const subjects_pool_check = (data) => {
    const subjects_data = [];
    const chosen_pastpaper = [];
    if (data?.length>0 && data[0] && data[0].length > 0) {
      for (var i = 0; i < data[0].length; i++) {
        subjects_data.push({
          name: data[0][i].pastpapers_subject,
          pastpapers_academic_level: data[0][i].pastpapers_academic_level,
          pastpapers: [],
        });
        //-----
        if (data[1] && data[1].length > 0) {
          for (var n = 0; n < data[1].length; n++) {
            if (data[1][n].name === data[0][i].pastpapers_subject) {
              //---issue here
              for (var b = 0; b < data[1][n].pastpapers.length; b++) {
                if (!chosen_pastpaper.includes(data[1][n].pastpapers[b].id)) {
                  subjects_data[i].pastpapers.push(data[1][n].pastpapers[b]);
                  chosen_pastpaper.push(data[1][n].pastpapers[b].id);
                }
              }
            }
          }
        }
      }
      return subjects_data;
    }
    return subjects_data;
  };
  const subjects_pool = subjects_pool_check(
    studentDashboardPastpapers.pastpapers_subjects
  );
  const tabs = subjects_pool.map((subject) => subject.name);
  tabs.unshift("initial page");
  const [selectedSubject, setSelectedSubject] = useTabs(
    tabs,
    chosenSubject || "initial page"
  );
  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full min-h-screen-sec`}>
        <div
          className={`p-3 bg-gray-200 ${
            showSubjects ? "h-screen-sec" : ""
          } md:h-full col-span-3`}
        >
          <div className="flex justify-between items-center">
            <Input
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "olevel", displayValue: "Ordinary Level" },
                  { value: "alevel", displayValue: "Advanced Level" },
                ],
              }}
              value={level}
              changed={setLevel}
              validation={{ required: true }}
              shouldValidate
              error="Level is required"
            />
            {/* {showSubjects ? (
              <BiChevronUp
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <BiChevronDown
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )} */}
          </div>
          <div className={`${showSubjects ? "" : "hidden"} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Search...",
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            <ul className="py-5">
              {!studentDashboardPastpapers.pastpapers_subjects_loading &&
              studentDashboardPastpapers?.pastpapers_subjects?.length > 0 ? (
                subjects_pool.map((subject, index) =>
                  searchterm === "" ||
                  subject.name
                    .toLowerCase()
                    .includes(searchterm.toLowerCase()) ? (
                    <li key={index}>
                      <div
                        className="py-2 cursor-pointer"
                        onClick={() => {
                          setSelectedSubject(subject.name);
                          setShowSubjects(false);
                        }}
                      >
                        <CardBody
                          name={subject.name}
                          color="blue"
                          additional={
                            subject.name === selectedSubject ? "font-bold" : ""
                          }
                        />
                      </div>
                    </li>
                  ) : (
                    <></>
                  )
                )
              ) : (
                <Spinner size="20" color="blue" />
              )}
            </ul>
          </div>
        </div>
        <div
          className={`${
            showSubjects ? "hidden md:block" : ""
          } md:relative p-3 bg-white md:h-full max-h-myscreen-sec min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <TabPanel
            hidden={selectedSubject !== "initial page"}
            className="transform transition duration-500 ease-in-out"
          >
            <FeatureTitle name="Past Paper" />
            <>
              <nav className="flex justify-between md:justify-start space-x-4">
                <TabSelector
                  isActive={selectedTab === "O'Genius Panda"}
                  title="O'Genius Panda"
                  onClick={() => setSelectedTab("O'Genius Panda")}
                />
                <TabSelector
                  isActive={selectedTab === "Community"}
                  title="Community"
                  onClick={() => setSelectedTab("Community")}
                />
              </nav>
              <TabPanel hidden={false}>
                <GetStarted type="a subject" feature="past papers" />
              </TabPanel>
            </>
          </TabPanel>
          {!studentDashboardPastpapers.pastpapers_subjects_loading &&
          studentDashboardPastpapers?.pastpapers_subjects?.length > 0 ? (
            subjects_pool.map((subject, index) => (
              <TabPanel
                key={index}
                hidden={selectedSubject !== subject.name}
                className="transform transition duration-500 ease-in-out"
              >
                <FeatureTitle name="Past Paper" />
                <>
                  <nav className="flex justify-between md:justify-start space-x-4">
                    <TabSelector
                      isActive={selectedTab === "O'Genius Panda"}
                      title="O'Genius Panda"
                      onClick={() => setSelectedTab("O'Genius Panda")}
                    />
                    <TabSelector
                      isActive={selectedTab === "Community"}
                      title="Community"
                      onClick={() => setSelectedTab("Community")}
                    />
                  </nav>
                  <TabPanel hidden={selectedTab !== "O'Genius Panda"}>
                    {subject.pastpapers.filter(
                      (pastpaper) => pastpaper.type === "opanda"
                    ).length === 0 ? (
                      <NotFound feature="past paper" />
                    ) : level === subject.pastpapers_academic_level ||
                      level === "S1" ? (
                      <>
                        <PastpapersGroupItem
                          subject={subject}
                          level={level}
                          type="opanda"
                          from="National Exams"
                        />
                        <PastpapersGroupItem
                          subject={subject}
                          level={level}
                          type="opanda"
                          from="District Exams"
                        />
                        <PastpapersGroupItem
                          subject={subject}
                          level={level}
                          type="opanda"
                          from="Mock National Exams"
                        />
                      </>
                    ) : (
                      <NotFound feature="past paper" />
                    )}
                  </TabPanel>
                  <TabPanel hidden={selectedTab !== "Community"}>
                    {!studentDashboardPastpapers.pastpapers_subjects_loading &&
                    studentDashboardPastpapers?.pastpapers_subjects?.length >
                      0 ? (
                      subject.pastpapers.filter(
                        (pastpaper) => pastpaper.type === "community"
                      ).length === 0 ? (
                        <NotFound feature="past paper" />
                      ) : level === subject.pastpapers_academic_level ||
                        level === "S1" ? (
                        <>
                          <PastpapersGroupItem
                            subject={subject}
                            level={level}
                            type="community"
                            from="National Exams"
                          />
                          <PastpapersGroupItem
                            subject={subject}
                            level={level}
                            type="community"
                            from="District Exams"
                          />
                          <PastpapersGroupItem
                            subject={subject}
                            level={level}
                            type="community"
                            from="Mock National Exams"
                          />
                        </>
                      ) : (
                        <NotFound feature="past paper" />
                      )
                    ) : (
                      <Spinner size="20" color="blue" />
                    )}
                  </TabPanel>
                </>
              </TabPanel>
            ))
          ) : (
            <></>
          )}
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${
            showSubjects ? "block" : ""
          } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSubjects ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden animate-pulse"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Pastpapers;
