import TabSelector from "./TabSelector";
import { useTabs, TabPanel } from "react-headless-tabs";
import Card from "./Card";
import { useHistory } from "react-router-dom";
import { bring_subject_image_src } from "../../../functions/programs";
import CardForSimulation from "./CardForSimulation";
import NotFound from "./NotFound";
import ModalSimulation from "./ModalSimulation";
import { useRef, useState } from "react";

const VideosAndSimulationsSimFeature = (props) => {
  const [selectedTab, setSelectedTab] = useTabs(props.tabs, props.tab);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaType, setmediaType] = useState("");
  const [unit, setUnit] = useState("");
  const adjust_extra_resources = (data) => {
    const data_new = [];
    for (var i = 0; i < data.length; i++) {
      data_new.push({
        title: data[i].title,
        unit: data[i].unit,
        mediasrc: data[i].mediasrc,
        createdAt: data[i].createdAt,
        type: data[i].type,
        watched: data[i].watched,
        subject: data[i].subject,
      });
    }

    return data_new;
  };
  const videos = [...adjust_extra_resources(props.otherresources)];
  // const closeTheModalX = (mediaUrl, mediaTitle, unit) => {

  // }

  const closeTheModal = () => {
    setShowVideoModal(false);
  };
  const showTheModal = (unit, type1, src) => {
    setShowVideoModal(true);
    setMediaUrl(src);
    setMediaTitle(unit);
    setmediaType(type1);
  };

  return (
    <div className="flex flex-col p-3 mb-8 bg-white absolute z-50 top-0 bottom-14 md:bottom-0 left-0 md:-left-72 w-full h-screen-second md:w-72 bg-gray-200 border-r border-l border-gray-500 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      {/* <div className="p-3 mb-8 absolute top-0 bottom-14 md:bottom-0 left-0 md:-left-72 w-full md:w-72 bg-gray-200 border-r border-l border-gray-500 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100"> */}
      <nav className="flex justify-between md:justify-start border-b border-gray-500">
        {props.tabs.map((tab, index) => (
          <TabSelector
            key={index}
            isActive={selectedTab === tab}
            title={tab}
            onClick={() => setSelectedTab(tab)}
          />
        ))}
      </nav>
      <div className="h-full flex flex-col justify-between">
        {showVideoModal ? (
          <ModalSimulation
            mediasrc={mediaUrl}
            title={mediaTitle}
            unit={unit}
            closeTheModal={closeTheModal}
          />
        ) : (
          ""
        )}
        <div className="">
          <TabPanel hidden={selectedTab !== "Videos"}>
            {videos.filter((video) => video.type === "Video").length > 0 ? (
              ""
            ) : (
              <NotFound feature="Videos" />
            )}
            {videos.map((video, index) =>
              video.type === "Video" ? (
                <CardForSimulation
                  showTheModal={showTheModal}
                  key={index}
                  {...video}
                  type="Video"
                />
              ) : (
                <div></div>
              )
            )}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "Simulations"}>
            {videos.map((video, index) =>
              video.type === "Simulation" ? (
                <CardForSimulation
                  showTheModal={showTheModal}
                  key={index}
                  {...video}
                  type="Simulation"
                />
              ) : (
                <div></div>
              )
            )}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "Documents"}>
            {videos.filter((video) => video.type === "Document").length > 0 ? (
              ""
            ) : (
              <NotFound feature="Documents" />
            )}
            {videos.map((video, index) =>
              video.type === "Document" ? (
                <CardForSimulation
                  showTheModal={showTheModal}
                  key={index}
                  {...video}
                  type="Document"
                />
              ) : (
                <div></div>
              )
            )}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "Quiz"}>
            {videos.map((video, index) =>
              video.type === "Test" ? (
                <CardForSimulation
                  showTheModal={showTheModal}
                  key={index}
                  {...video}
                  type="Quiz"
                />
              ) : (
                <div></div>
              )
            )}
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default VideosAndSimulationsSimFeature;
