import Layout from '../../../components/common/Layout';
import SummaryNotes from '../../../components/pages/student/summarynotes/SummaryNotes';

const SummarynotesContainer = () => {
  return (
    <Layout>
      <SummaryNotes />
    </Layout>
  );
};

export default SummarynotesContainer;
