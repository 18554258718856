import axios from "../../../axios-base";

export const ADD_QUESTION = "ADD_QUESTION";
export const SET_QUESTION = "SET_QUESTION";
export const ADD_MULTIPLE_QUESTION_OPTION = "ADD_MULTIPLE_QUESTION_OPTION";
export const REMOVE_MULTIPLE_QUESTION_OPTION =
  "REMOVE_MULTIPLE_QUESTION_OPTION";
export const CHANGE_MULTIPLE_QUESTION_OPTION =
  "CHANGE_MULTIPLE_QUESTION_OPTION";
export const SET_MULTIPLE_QUESTION_OPTION = "SET_MULTIPLE_QUESTION_OPTION";
export const SUBMIT_QUESTIONS = "SUBMIT_QUESTIONS";

export const GET_TEACHER_TEST_MAPS_START = "GET_TEACHER_TEST_MAPS_START";
export const GET_TEACHER_TEST_MAPS_SUCCESS = "GET_TEACHER_TEST_MAPS_SUCCESS";
export const GET_ONE_TEACHER_TEST_MAPS_SUCCESS =
  "GET_ONE_TEACHER_TEST_MAPS_SUCCESS";
export const GET_TEACHER_TEST_MAPS_FAIL = "GET_TEACHER_TEST_MAPS_FAIL";

export const setCurrentQuestion = (question) => {
  return {
    type: SET_QUESTION,
    payload: question,
  };
};

export const addQuestion = (question) => {
  return {
    type: ADD_QUESTION,
    payload: question,
  };
};

export const addMultipleQuestionOption = (question) => {
  return {
    type: ADD_MULTIPLE_QUESTION_OPTION,
    payload: question,
  };
};

export const removeMultipleQuestionOption = (id) => {
  return {
    type: REMOVE_MULTIPLE_QUESTION_OPTION,
    payload: id,
  };
};

export const submitQuestions = () => {
  return {
    type: SUBMIT_QUESTIONS,
  };
};

export const changeMultipleQuestionOption = (question) => {
  return {
    type: CHANGE_MULTIPLE_QUESTION_OPTION,
    payload: question,
  };
};

export const setMulitpleQuestionOptions = (options) => {
  return {
    type: SET_MULTIPLE_QUESTION_OPTION,
    payload: options,
  };
};

export const getTeacherTestMapsStart = () => {
  return {
    type: GET_TEACHER_TEST_MAPS_START,
  };
};

export const getTeacherTestMapsSuccess = (testmaps) => {
  return {
    type: GET_TEACHER_TEST_MAPS_SUCCESS,
    payload: testmaps,
  };
};

export const getOneTeacherTestMapsSuccess = (testmap) => {
  return {
    type: GET_ONE_TEACHER_TEST_MAPS_SUCCESS,
    payload: testmap,
  };
};

export const getTeacherTestMapsFail = (error) => {
  return {
    type: GET_TEACHER_TEST_MAPS_FAIL,
    error: error,
  };
};

export const getTeacherTestMaps = (token) => {
  return (dispatch) => {
    dispatch(getTeacherTestMapsStart());
    axios
      .get(`/opanda/panda-test-maps`, {headers:{Authorization:token}})
      .then((response) => {
        dispatch(getTeacherTestMapsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherTestMapsFail(err.message));
      });
  };
};

export const getOneTeacherTestMaps = (testId) => {
  return (dispatch) => {
    dispatch(getTeacherTestMapsStart());
    axios
      .get(`/opanda/panda-test-maps/test/${testId}`)
      .then((response) => {
        dispatch(getOneTeacherTestMapsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherTestMapsFail(err.message));
      });
  };
};

export const createTeacherTestMaps = (object, token) => {
  return (dispatch) => {
    dispatch(getTeacherTestMapsStart());
    axios
      .post(`/opanda/panda-test-maps/create-test`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getTeacherTestMapsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherTestMapsFail(err.message));
      });
  };
};

export const initiateTestCreation = (object) => {
  return (dispatch) => {
    dispatch(getOneTeacherTestMapsSuccess(object));
  };
};
