import Layout from '../../../components/common/Layout';
import ProgressContainer from '../../../components/pages/student/test/SingleTestProgress';

const SingleTestProgress = () => {
  return (
    <Layout>
      <ProgressContainer />
    </Layout>
  );
};

export default SingleTestProgress;
