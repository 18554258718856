import PageContent from '../components/pages/guest/guesttest/pageContent';
import Header from '../components/common/header/header';
import NotificationBar from '../components/pages/landing/preview/notificationbar';
import ScrollToTop from '../components/common/ScrollToTop';

const Guesttest = () => {
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
      <Header />
      <NotificationBar />
      <PageContent />
      <ScrollToTop />
    </div>
  );
};

export default Guesttest;
