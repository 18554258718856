import PageContent from "../../pageContent";
import { FeatureTitle } from "../../../../elements/text";
import Notification from "../../../../elements/notification/notification";
import YoutubeEmbed from "../../../../elements/YoutubeEmbed";
import Input from "../../../../elements/input";
import { useState } from "react";
import { Button } from "../../../../elements/button";
import { useDispatch, useSelector } from "react-redux";
import { school_users_send_feedback_start } from "../../../../../store/actions/student.dashboard.user.feedback";
const Help = () => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const [suggestion, setSuggestion] = useState("");

  const videos = [
    {
      embedId: "Qzlix009O9s",
    },
    {
      embedId: "y3cZQxbtrX8",
    },
    {
      embedId: "5t2bEx9tjjI",
    },
    {
      embedId: "eB50P-FmuGo",
    },
    {
      embedId: "JDLuex_srzs",
    },
    {
      embedId: "6vrz00nC5I4",
    },
  ];

  const sendStatus = useSelector(
    (state) => state.userFeedback.school_user_feedback_loading
  );
  const statusMessage = useSelector(
    (state) => state.userFeedback.message
  );
  const submitfeedback = () => {
    dispatch(
      school_users_send_feedback_start({
        user_id: userAth.userId,
        email: userAth.user.email,
        subject: "feedback",
        feedbackdata: suggestion,
        datecreated: Date.now(),
        username: userAth.username,
        notif_status: 0,
        token: userAth.token,
      })
    );
    setSuggestion("");
  };

  return (
    <PageContent>
      <div
        className={`md:grid bg-gray-200 grid-cols-12 w-full min-h-screen-sec max-h-myscreen-sec md:max-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-9`}>
          <FeatureTitle name="Help center" />

          <div className="grid grid-cols-3 gap-3 relative overflow-x-auto sm:rounded-lg mb-4">
            {videos.map((embedId, index) => (
              <YoutubeEmbed key={index} embedId={embedId} />
            ))}
          </div>
          <div className="space-y-4 md:w-3/4">
            <Input
              label="Do you have a specific help video you need?"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Enter suggestion here",
              }}
              value={suggestion}
              changed={setSuggestion}
              validation={{ required: false }}
              shouldValidate
              error={statusMessage}
            />
            {sendStatus ? (
              <Button
                name="Submit"
                outline="true"
                color="blue"
                disabled={true}
              />
            ) : (
              <Button
                name="Submit"
                outline="true"
                color="blue"
                clicked={() => {
                  submitfeedback();
                }}
              />
            )}
          </div>
        </div>

        <div className={`p-3 col-span-3`}>
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default Help;
