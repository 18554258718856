import * as React from "react";

export const TabSelector = ({ isActive, children, onClick, tab }) => (
  <button
    className={`md:mr-8 group inline-flex items-center px-2 py-4 border-b-2 font-medium text-sm leading-5 cursor-pointer whitespace-nowrap ${
      isActive
        ? "border-" +
          tab +
          " text-" +
          tab +
          " focus:outline-none focus:text-" +
          tab +
          " focus:border-" +
          tab
        : "border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-300 focus:text-gray-600 focus:border-gray-300"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);

export const NavSelector = ({ isActive, children, onClick, tab }) => (
  <button
    className={`md:mr-8 group inline-flex items-center px-2 py-4 border-b-2 font-medium text-sm leading-5 cursor-pointer whitespace-nowrap ${
      isActive
        ? "border-" +
          tab +
          " text-" +
          tab +
          " focus:outline-none focus:text-" +
          tab +
          " focus:border-" +
          tab
        : "border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-300 focus:text-gray-600 focus:border-gray-300"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);
