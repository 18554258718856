import Layout from '../../../components/common/Layout';
import ExistingAccounts from '../../../components/pages/school/users/existingaccounts';

const ImportExisting = () => {
  return (
    <Layout>
      <ExistingAccounts />
    </Layout>
  );
};
 
export default ImportExisting; 
