import { useEffect, useState } from "react";
import PageContent from "../pageContent";
import {
  CardBody,
  CardSubText,
  CardTitle,
  SmallText,
} from "../../../elements/text";
import { Button, GoBackButton, TextButton } from "../../../elements/button";
import Input from "../../../elements/input";
import { BsCheckCircle } from "react-icons/bs";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { groupBy } from "../../../../shared/utility";
import { useHistory, useParams } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";

const TestStats = () => {
  const history = useHistory();
  const params = useParams();
  const [level, setLevel] = useState("S1");
  const [subject, setSubject] = useState("");
  const [unit, setUnit] = useState("");
  const [studentsPerScore, setStudentsPerScore] = useState({});
  const [showMarks, setShowMarks] = useState(false);

  const [showSelectBox, setShowSelectBox] = useState(true);
  const testmap = useSelector((state) => state.features.tests.testmap);
  const attempts = [
    {
      name: "Oliver Karangwa",
      status: true,
      reason: "",
      date: "2022-05-20",
      marks: 90,
    },
    {
      name: "Oliver Karangwa",
      status: true,
      reason: "",
      date: "2022-05-20",
      marks: 80,
    },
    {
      name: "Oliver Karangwa",
      status: true,
      reason: "",
      date: "2022-05-20",
      marks: 75,
    },
    {
      name: "Oliver Karangwa",
      status: true,
      reason: "",
      date: "2022-05-20",
      marks: 60,
    },
    {
      name: "Oliver Karangwa",
      status: false,
      reason: "Late submission",
      date: "2022-05-20",
      marks: 90,
    },
    {
      name: "Oliver Karangwa",
      status: false,
      reason: "Late",
      date: "2022-05-20",
      marks: 80,
    },
    {
      name: "Oliver Karangwa",
      status: false,
      reason: "Late",
      date: "2022-05-20",
      marks: 80,
    },
    {
      name: "Oliver Karangwa",
      status: false,
      reason: "Late",
      date: "2022-05-20",
      marks: 70,
    },
  ];
  useEffect(() => {
    groupBy(attempts, "marks")
      .then((res) => setStudentsPerScore(res))
      .catch((err) => console.log(err));
  }, []);

  const data = Object.keys(studentsPerScore).map((key) => {
    return { mark: key, students: studentsPerScore[key].length };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (params?.testId) {
      dispatch(actions.getOneTeacherTestMaps(params.testId));
    }
  }, []);

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full md:h-full min-h-screen-sec`}>
        {showSelectBox ? (
          <>
            <div className="p-3 bg-gray-200 md:h-full col-span-4 border-l border-gray-500">
              <div>
                <CardBody
                  name="Select a subject, academic level and unit to start browser through question bank"
                  alignment="center"
                />
                <div className="flex flex-col md:flex-row md:justify-between md:items-center md:space-x-2">
                  <Input
                    label="Subject"
                    elementType="select"
                    elementConfig={{
                      startingValue: "SELECT",
                      options: [
                        { value: "Biology", displayValue: "Biology" },
                        { value: "Chemistry", displayValue: "Chemistry" },
                        { value: "Physics", displayValue: "Physics" },
                        { value: "Math", displayValue: "Math" },
                        { value: "Geography", displayValue: "Geography" },
                      ],
                    }}
                    value={subject}
                    changed={setSubject}
                    validation={{ required: true }}
                    shouldValidate
                    error="Subject is required"
                  />
                  <Input
                    label="Level"
                    elementType="select"
                    elementConfig={{
                      startingValue: "SELECT",
                      options: [
                        { value: "S1", displayValue: "Senior 1" },
                        { value: "S2", displayValue: "Senior 2" },
                        { value: "S3", displayValue: "Senior 3" },
                        { value: "S4", displayValue: "Senior 4" },
                        { value: "S5", displayValue: "Senior 5" },
                        { value: "S6", displayValue: "Senior 6" },
                      ],
                    }}
                    value={level}
                    changed={setLevel}
                    validation={{ required: true }}
                    shouldValidate
                    error="Level is required"
                  />
                </div>
              </div>
              <Input
                label="Unit"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [
                    { value: "Unit 1", displayValue: "Unit 1" },
                    { value: "Unit 2", displayValue: "Unit 2" },
                    { value: "Unit 3", displayValue: "Unit 3" },
                    { value: "Unit 4", displayValue: "Unit 4" },
                  ],
                }}
                value={unit}
                changed={setUnit}
                validation={{ required: true }}
                shouldValidate
                error="Unit is required"
              />
              <Button
                name="Find"
                outline="true"
                color="blue"
                additional="my-5"
                clicked={() => {}}
              />
            </div>
          </>
        ) : (
          ""
        )}
        <div className="w-full col-span-8 p-3 max-h-myscreen-sec md:max-h-screen-sec overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
          <div className="flex justify-between items-end">
            <GoBackButton
              link="/teacher/features/tests"
              action="Back"
              location="Tests & Assessments"
            />
            <div className="flex space-x-2">
              <Button
                name="Preview"
                isSquare
                outline="true"
                color="blue"
                clicked={() =>
                  history.push({
                    pathname: `/teacher/features/tests/${testmap?.panda_test_map_2020_03_id}`,
                    state: { isOwner: true },
                  })
                }
              />
              <Button
                name="Mark"
                isSquare
                outline="false"
                color="blue"
                clicked={() => history.push(`/teacher/features/tests/mark/1`)}
              />
            </div>
          </div>
          <div className="flex space-x-2  my-5">
            <div className="w-50% bg-white rounded-lg shadow-lg p-3">
              <div>
                <div className="flex justify-between items-center">
                  <CardTitle
                    name="70% submission"
                    color="blue"
                    additional="font-bold"
                  />
                  <TextButton
                    additional="font-bold"
                    name="Show marks"
                    color="blue"
                    clicked={() => setShowMarks(!showMarks)}
                  />
                </div>
                <div className="rounded-full w-full h-2.5 border border-tiffany-blue">
                  <div
                    className={`h-full w-70% bg-tiffany-blue rounded-full`}
                  ></div>
                </div>
              </div>
              {attempts.map((attempt, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center py-2"
                >
                  <div className="flex flex-col">
                    <CardBody
                      name={attempt.name}
                      color="blue"
                      additional="font-semibold"
                    />
                    <SmallText
                      name={new Date(attempt.date).toLocaleDateString(
                        undefined,
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                      color="gray-500"
                      additional="font-bold"
                    />
                  </div>
                  {showMarks ? (
                    <CardBody
                      name={`${attempt.marks}%`}
                      color="blue"
                      additional="font-semibold"
                    />
                  ) : (
                    <div className="flex items-center space-x-2">
                      <SmallText
                        name={attempt.reason}
                        color={attempt.status ? "green" : "red"}
                      />
                      {attempt.status ? (
                        <BsCheckCircle color="#3AB54A" />
                      ) : (
                        <IoMdCloseCircleOutline color="#BE1E2D" size={18} />
                      )}
                    </div>
                  )}
                </div>
              ))}
              <div className="flex justify-between items-center py-2">
                <CardSubText
                  name="34 students"
                  color="gray-500"
                  additional="font-bold"
                />
                <TextButton
                  additional="font-bold"
                  name="Expand"
                  color="blue"
                  clicked={() => {}}
                />
              </div>
            </div>
            <div className="w-50% flex flex-col space-y-4">
              <div className="bg-white rounded-lg shadow-lg p-3">
                <CardTitle name="Scores/Number of students" />
                <LineChart width={400} height={200} data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="mark" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="students" stroke="#82ca9d" />
                </LineChart>
              </div>
              <div className="bg-white rounded-lg shadow-lg p-3">
                <CardTitle name="Question/Number of students" />
                <LineChart width={400} height={200} data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="mark" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="students" stroke="#82ca9d" />
                </LineChart>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${
            showSelectBox ? "block" : ""
          } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSelectBox ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default TestStats;
