export const CHANGE_CHOSEN_UNIT_SUMMARY_NOTES = 'CHANGE_CHOSEN_UNIT_SUMMARY_NOTES';

export const changeChosenUnitSummarynotes = (unit) => {
  return {
    type: CHANGE_CHOSEN_UNIT_SUMMARY_NOTES,
    payload: unit,
  };
};

export const CHANGE_SUMMARY_NOTES_SUBJECT = 'CHANGE_SUMMARY_NOTES_SUBJECT';

export const changeChosenSubjectSummarynotes = (subject) => {
  return {
    type: CHANGE_SUMMARY_NOTES_SUBJECT,
    payload: subject,
  };
};

export const ADD_PAGE_TO_COURSE = 'ADD_PAGE_TO_COURSE';

export const addPageToCourse = (page) => {
  return {
    type: ADD_PAGE_TO_COURSE,
    payload: page,
  };
};

export const SET_CURRENT_COURSE_PAGE = 'SET_CURRENT_COURSE_PAGE';

export const setCurrentCoursePage = (page) => {
  return {
    type: SET_CURRENT_COURSE_PAGE,
    payload: page,
  };
};

export const SET_COURSE_PAGE_INIT = 'SET_COURSE_PAGE_INIT';

export const setCoursePageInit = () => {
  return {
    type: SET_COURSE_PAGE_INIT,
  };
};


