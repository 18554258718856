import PaymentPageContent from "./pageContent";
import PageContent from '../../pageContent';
const Payment = () => {
  return (
    <PageContent>
        <PaymentPageContent />
    </PageContent>
  );
};

export default Payment;
