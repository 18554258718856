export const GET_MULTIPLE_CHOICE_START = "GET_MULTIPLE_CHOICE_START";
export const GET_MULTIPLE_CHOICE_SUCCESS = "GET_MULTIPLE_CHOICE_SUCCESS";
export const GET_ONE_MULTIPLE_CHOICE_SUCCESS =
  "GET_ONE_MULTIPLE_CHOICE_SUCCESS";
export const GET_MULTIPLE_CHOICE_FAIL = "GET_MULTIPLE_CHOICE_FAIL";

export const GET_AUTO_QUESTIONS_ALGO2_START = "GET_AUTO_QUESTIONS_ALGO2_START";
export const GET_AUTO_QUESTIONS_ALGO2_SUCCESS =
  "GET_AUTO_QUESTIONS_ALGO2_SUCCESS";
export const GET_ONE_AUTO_QUESTIONS_ALGO2_SUCCESS =
  "GET_ONE_AUTO_QUESTIONS_ALGO2_SUCCESS";
export const GET_AUTO_QUESTIONS_ALGO2_FAIL = "GET_AUTO_QUESTIONS_ALGO2_FAIL";

export const GET_TEST_AI_USER_ANSWERS_START = "GET_TEST_AI_USER_ANSWERS_START";
export const GET_TEST_AI_USER_ANSWERS_SUCCESS =
  "GET_TEST_AI_USER_ANSWERS_SUCCESS";
export const GET_ONE_TEST_AI_USER_ANSWERS_SUCCESS =
  "GET_ONE_TEST_AI_USER_ANSWERS_SUCCESS";
export const GET_TEST_AI_USER_ANSWERS_FAIL = "GET_TEST_AI_USER_ANSWERS_FAIL";

export const GET_TEST_ALGOV2_USER_ANSWERS_START =
  "GET_TEST_ALGOV2_USER_ANSWERS_START";
export const GET_TEST_ALGOV2_USER_ANSWERS_SUCCESS =
  "GET_TEST_ALGOV2_USER_ANSWERS_SUCCESS";
export const GET_ONE_TEST_ALGOV2_USER_ANSWERS_SUCCESS =
  "GET_ONE_TEST_ALGOV2_USER_ANSWERS_SUCCESS";
export const GET_TEST_ALGOV2_USER_ANSWERS_FAIL =
  "GET_TEST_ALGOV2_USER_ANSWERS_FAIL";

export const GET_DRAG_AND_DROP_TEACHER_QUESTIONS_START =
  "GET_DRAG_AND_DROP_TEACHER_QUESTIONS_START";
export const GET_DRAG_AND_DROP_TEACHER_QUESTIONS_SUCCESS =
  "GET_DRAG_AND_DROP_TEACHER_QUESTIONS_SUCCESS";
export const GET_ONE_DRAG_AND_DROP_TEACHER_QUESTIONS_SUCCESS =
  "GET_ONE_DRAG_AND_DROP_TEACHER_QUESTIONS_SUCCESS";
export const GET_DRAG_AND_DROP_TEACHER_QUESTIONS_FAIL =
  "GET_DRAG_AND_DROP_TEACHER_QUESTIONS_FAIL";

export const GET_FILLIN_TEACHER_QUESTIONS_START =
  "GET_FILLIN_TEACHER_QUESTIONS_START";
export const GET_FILLIN_TEACHER_QUESTIONS_SUCCESS =
  "GET_FILLIN_TEACHER_QUESTIONS_SUCCESS";
export const GET_ONE_FILLIN_TEACHER_QUESTIONS_SUCCESS =
  "GET_ONE_FILLIN_TEACHER_QUESTIONS_SUCCESS";
export const GET_FILLIN_TEACHER_QUESTIONS_FAIL =
  "GET_FILLIN_TEACHER_QUESTIONS_FAIL";

export const GET_OPEN_ENDED_TEACHER_QUESTIONS_START =
  "GET_OPEN_ENDED_TEACHER_QUESTIONS_START";
export const GET_OPEN_ENDED_TEACHER_QUESTIONS_SUCCESS =
  "GET_OPEN_ENDED_TEACHER_QUESTIONS_SUCCESS";
export const GET_ONE_OPEN_ENDED_TEACHER_QUESTIONS_SUCCESS =
  "GET_ONE_OPEN_ENDED_TEACHER_QUESTIONS_SUCCESS";
export const GET_OPEN_ENDED_TEACHER_QUESTIONS_FAIL =
  "GET_OPEN_ENDED_TEACHER_QUESTIONS_FAIL";

export const GET_PANDA_TEST_MAPS_START = "GET_PANDA_TEST_MAPS_START";
export const GET_PANDA_TEST_MAPS_SUCCESS = "GET_PANDA_TEST_MAPS_SUCCESS";
export const GET_ONE_PANDA_TEST_MAPS_SUCCESS =
  "GET_ONE_PANDA_TEST_MAPS_SUCCESS";
export const GET_PANDA_TEST_MAPS_FAIL = "GET_PANDA_TEST_MAPS_FAIL";

export const GET_PANDA_STUDENTS_ANSWERING_MAPS_START =
  "GET_PANDA_STUDENTS_ANSWERING_MAPS_START";
export const GET_PANDA_STUDENTS_ANSWERING_MAPS_SUCCESS =
  "GET_PANDA_STUDENTS_ANSWERING_MAPS_SUCCESS";
export const GET_ONE_PANDA_STUDENTS_ANSWERING_MAPS_SUCCESS =
  "GET_ONE_PANDA_STUDENTS_ANSWERING_MAPS_SUCCESS";
export const GET_PANDA_STUDENTS_ANSWERING_MAPS_FAIL =
  "GET_PANDA_STUDENTS_ANSWERING_MAPS_FAIL";

export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_ONE_USERS_SUCCESS = "GET_ONE_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const GET_ONE_USERS_BY_ID_START = "GET_ONE_USERS_BY_ID_START";
export const GET_ONE_USERS_BY_ID_SUCCESS = "GET_ONE_USERS_BY_ID_SUCCESS";
export const GET_ONE_USERS_BY_ID_FAIL = "GET_ONE_USERS_BY_ID_FAIL";

export const AUTHENTICATION_START = "AUTHENTICATION_START";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAIL = "AUTHENTICATION_FAIL";
export const AUTHENTICATION_LOGOUT = "AUTHENTICATION_LOGOUT";

export const GET_PROFILE_INFORMATION_START = "GET_PROFILE_INFORMATION_START";
export const GET_PROFILE_INFORMATION_SUCCESS =
  "GET_PROFILE_INFORMATION_SUCCESS";
export const GET_ONE_PROFILE_INFORMATION_SUCCESS =
  "GET_ONE_PROFILE_INFORMATION_SUCCESS";
export const GET_PROFILE_INFORMATION_FAIL = "GET_PROFILE_INFORMATION_FAIL";

export const GET_OGENIUS_MODULES_START = "GET_OGENIUS_MODULES_START";
export const GET_OGENIUS_MODULES_SUCCESS = "GET_OGENIUS_MODULES_SUCCESS";
export const GET_ONE_OGENIUS_MODULES_SUCCESS =
  "GET_ONE_OGENIUS_MODULES_SUCCESS";
export const GET_OGENIUS_MODULES_FAIL = "GET_OGENIUS_MODULES_FAIL";

export const GET_EXTRA_CURRICULARS_START = "GET_EXTRA_CURRICULARS_START";
export const GET_EXTRA_CURRICULARS_SUCCESS = "GET_EXTRA_CURRICULARS_SUCCESS";
export const GET_ONE_EXTRA_CURRICULARS_SUCCESS =
  "GET_ONE_EXTRA_CURRICULARS_SUCCESS";
export const GET_EXTRA_CURRICULARS_FAIL = "GET_EXTRA_CURRICULARS_FAIL";

export const GET_LEARNING_RESOURCES_START = "GET_LEARNING_RESOURCES_START";
export const GET_LEARNING_RESOURCES_SUCCESS = "GET_LEARNING_RESOURCES_SUCCESS";
export const GET_ONE_LEARNING_RESOURCES_SUCCESS =
  "GET_ONE_LEARNING_RESOURCES_SUCCESS";
export const GET_LEARNING_RESOURCES_FAIL = "GET_LEARNING_RESOURCES_FAIL";

//---/student>/dashboard>/homepage--
export const GET_TODAY_SUGGESTED_FEATURES = "GET_TODAY_SUGGESTED_FEATURES";
export const GET_TODAY_SUGGESTED_FEATURES_SUCCESS =
  "GET_TODAY_SUGGESTED_FEATURES_SUCCESS";
export const GET_TODAY_SUGGESTED_FEATURES_FAIL =
  "GET_TODAY_SUGGESTED_FEATURES_FAIL";
export const GET_OPANDA_BOOKS_START = "GET_OPANDA_BOOKS_START";
export const GET_OPANDA_BOOKS_SUCCESS = "GET_OPANDA_BOOKS_SUCCESS";
export const GET_ONE_OPANDA_BOOKS_SUCCESS = "GET_ONE_OPANDA_BOOKS_SUCCESS";
export const GET_OPANDA_BOOKS_FAIL = "GET_OPANDA_BOOKS_FAIL";

export const GET_HOME_CAROUSEL_START = "GET_HOME_CAROUSEL_START";
export const GET_HOME_CAROUSEL_SUCCESS = "GET_HOME_CAROUSEL_SUCCESS";
export const GET_ONE_HOME_CAROUSEL_SUCCESS = "GET_ONE_HOME_CAROUSEL_SUCCESS";
export const GET_HOME_CAROUSEL_FAIL = "GET_HOME_CAROUSEL_FAIL";

export const GET_HOME_WHO_IS_OPANDA_FOR_START =
  "GET_HOME_WHO_IS_OPANDA_FOR_START";
export const GET_HOME_WHO_IS_OPANDA_FOR_SUCCESS =
  "GET_HOME_WHO_IS_OPANDA_FOR_SUCCESS";
export const GET_ONE_HOME_WHO_IS_OPANDA_FOR_SUCCESS =
  "GET_ONE_HOME_WHO_IS_OPANDA_FOR_SUCCESS";
export const GET_HOME_WHO_IS_OPANDA_FOR_FAIL =
  "GET_HOME_WHO_IS_OPANDA_FOR_FAIL";

export const GET_HOME_HOW_IT_WORKS_START = "GET_HOME_HOW_IT_WORKS_START";
export const GET_HOME_HOW_IT_WORKS_SUCCESS = "GET_HOME_HOW_IT_WORKS_SUCCESS";
export const GET_ONE_HOME_HOW_IT_WORKS_SUCCESS =
  "GET_ONE_HOME_HOW_IT_WORKS_SUCCESS";
export const GET_HOME_HOW_IT_WORKS_FAIL = "GET_HOME_HOW_IT_WORKS_FAIL";

export const GET_HOME_PARTNERS_START = "GET_HOME_PARTNERS_START";
export const GET_HOME_PARTNERS_SUCCESS = "GET_HOME_PARTNERS_SUCCESS";
export const GET_ONE_HOME_PARTNERS_SUCCESS = "GET_ONE_HOME_PARTNERS_SUCCESS";
export const GET_HOME_PARTNERS_FAIL = "GET_HOME_PARTNERS_FAIL";

export const GET_HOME_IMPACTS_START = "GET_HOME_IMPACTS_START";
export const GET_HOME_IMPACTS_SUCCESS = "GET_HOME_IMPACTS_SUCCESS";
export const GET_ONE_HOME_IMPACTS_SUCCESS = "GET_ONE_HOME_IMPACTS_SUCCESS";
export const GET_HOME_IMPACTS_FAIL = "GET_HOME_IMPACTS_FAIL";

export const GET_HOME_VALUE_PROPOSITIONS_START =
  "GET_HOME_VALUE_PROPOSITIONS_START";
export const GET_HOME_VALUE_PROPOSITIONS_SUCCESS =
  "GET_HOME_VALUE_PROPOSITIONS_SUCCESS";
export const GET_ONE_HOME_VALUE_PROPOSITIONS_SUCCESS =
  "GET_ONE_HOME_VALUE_PROPOSITIONS_SUCCESS";
export const GET_HOME_VALUE_PROPOSITIONS_FAIL =
  "GET_HOME_VALUE_PROPOSITIONS_FAIL";

export const GET_HOME_USERS_TESTIMONIES_START =
  "GET_HOME_USERS_TESTIMONIES_START";
export const GET_HOME_USERS_TESTIMONIES_SUCCESS =
  "GET_HOME_USERS_TESTIMONIES_SUCCESS";
export const GET_ONE_HOME_USERS_TESTIMONIES_SUCCESS =
  "GET_ONE_HOME_USERS_TESTIMONIES_SUCCESS";
export const GET_HOME_USERS_TESTIMONIES_FAIL =
  "GET_HOME_USERS_TESTIMONIES_FAIL";

export const GET_COURSE_STRUCTURES_START = "GET_COURSE_STRUCTURES_START";
export const GET_COURSE_STRUCTURES_SUCCESS = "GET_COURSE_STRUCTURES_SUCCESS";
export const GET_ONE_COURSE_STRUCTURES_SUCCESS =
  "GET_ONE_COURSE_STRUCTURES_SUCCESS";
export const GET_COURSE_STRUCTURES_FAIL = "GET_COURSE_STRUCTURES_FAIL";

export const GET_TESTS_START = "GET_TESTS_START";
export const GET_TESTS_SUCCESS = "GET_TESTS_SUCCESS";
export const GET_ONE_TESTS_SUCCESS = "GET_ONE_TESTS_SUCCESS";
export const GET_TESTS_FAIL = "GET_TESTS_FAIL";

export const GET_CLASS_COURSES_START = "GET_CLASS_COURSES_START";
export const GET_CLASS_COURSES_SUCCESS = "GET_CLASS_COURSES_SUCCESS";
export const GET_ONE_CLASS_COURSES_SUCCESS = "GET_ONE_CLASS_COURSES_SUCCESS";
export const GET_CLASS_COURSES_FAIL = "GET_CLASS_COURSES_FAIL";

export const GET_RECOVERY_QUESTIONS_START = "GET_RECOVERY_QUESTIONS_START";
export const GET_RECOVERY_QUESTIONS_SUCCESS = "GET_RECOVERY_QUESTIONS_SUCCESS";
export const GET_ONE_RECOVERY_QUESTIONS_SUCCESS =
  "GET_ONE_RECOVERY_QUESTIONS_SUCCESS";
export const GET_RECOVERY_QUESTIONS_FAIL = "GET_RECOVERY_QUESTIONS_FAIL";

export const GET_SCHOOLS_START = "GET_SCHOOLS_START";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_ONE_SCHOOLS_SUCCESS = "GET_ONE_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAIL = "GET_SCHOOLS_FAIL";

export const GET_CLASS_SUBJECTS_NAMES_START = "GET_CLASS_SUBJECTS_NAMES_START";
export const GET_CLASS_SUBJECTS_NAMES_SUCCESS =
  "GET_CLASS_SUBJECTS_NAMES_SUCCESS";
export const GET_ONE_CLASS_SUBJECTS_NAMES_SUCCESS =
  "GET_ONE_CLASS_SUBJECTS_NAMES_SUCCESS";
export const GET_CLASS_SUBJECTS_NAMES_FAIL = "GET_CLASS_SUBJECTS_NAMES_FAIL";

export const GET_CBC_COMBINATIONS_START = "GET_CBC_COMBINATIONS_START";
export const GET_CBC_COMBINATIONS_SUCCESS = "GET_CBC_COMBINATIONS_SUCCESS";
export const GET_ONE_CBC_COMBINATIONS_SUCCESS =
  "GET_ONE_CBC_COMBINATIONS_SUCCESS";
export const GET_CBC_COMBINATIONS_FAIL = "GET_CBC_COMBINATIONS_FAIL";

export const GET_COURSE_PAGES_START = "GET_COURSE_PAGES_START";
export const GET_COURSE_PAGES_SUCCESS = "GET_COURSE_PAGES_SUCCESS";
export const GET_ONE_COURSE_PAGES_SUCCESS =
  "GET_ONE_COURSE_PAGES_SUCCESS";
export const GET_COURSE_PAGES_FAIL = "GET_COURSE_PAGES_FAIL";

export const GET_PAYMENT_OPTIONS_START = "GET_PAYMENT_OPTIONS_START";
export const GET_PAYMENT_OPTIONS_SUCCESS = "GET_PAYMENT_OPTIONS_SUCCESS";
export const GET_ONE_PAYMENT_OPTIONS_SUCCESS =
  "GET_ONE_PAYMENT_OPTIONS_SUCCESS";
export const GET_PAYMENT_OPTIONS_FAIL = "GET_PAYMENT_OPTIONS_FAIL";

export const GET_PAYMENT_METHODS_START = "GET_PAYMENT_METHODS_START";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_ONE_PAYMENT_METHODS_SUCCESS =
  "GET_ONE_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL";


