import { useEffect, useState } from 'react';
import { CardTitle } from './text';
import eventBus from '../../utils/eventBus';

const CountDown = (props) => {
  const initialSeconds = props.seconds;
  const [seconds, setSeconds] = useState(initialSeconds);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [testType, setTestType] = useState(props?.testType);



  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingMinutes(Math.floor(seconds / 60));
      setRemainingSeconds(seconds % 60);
      seconds !== 0 && setSeconds((seconds) => seconds - 1);
      if ((seconds === 2) && props?.testType && testType === "automade") {
        eventBus.emit('saveAutomatedTestWhenTimeOut');
        clearInterval(interval);
      }
      if ((seconds === 2) && props?.testType && testType === "teachermade") {
        eventBus.emit('saveTeacherMadeTestWhenTimeOut');
        clearInterval(interval);

      }
      localStorage.setItem("timingTestCountDown", (initialSeconds-seconds));

    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div
      className={`bg-${seconds < initialSeconds / 4 ? 'red' : 'yellow'
        } w-full rounded-xl py-2 px-5 text-center`}
    >
      <CardTitle
        name={`${(remainingMinutes >= 10 ? remainingMinutes : "0" + remainingMinutes)}:${(remainingSeconds >= 10) ? remainingSeconds : "0" + remainingSeconds}`}
        color="white"
      />
    </div>
  );
};

export default CountDown;
