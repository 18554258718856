import { connect, useDispatch, useSelector } from "react-redux";
import { NavList } from "../../../elements/sidebar/Navlist";
import { NavSubItem } from "../../../elements/sidebar/NavSubItem";
import { TabPanel, useTabs } from "react-headless-tabs";
import { ButtonClose } from "../../../elements/button";
import { store } from "../../../../store/store";
import { useEffect, useState } from "react";
import * as actions from "../../../../store/actions/";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

const SideBar = function (props) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useTabs([
    "modules",
    "extracurricular",
    "learningresources",
  ]);
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const [search, setSearch] = useState("");
  const {
    visibility,
    hidesidebar,
    changetab,
    previewpanda_search_keyword,
    previewpandaSearchKeyword,
  } = props;

  const modules = useSelector((state) => state.previewpanda.modules);
  const extracurriculars = useSelector(
    (state) => state.previewpanda.extracurriculars
  );
  const learningresources = useSelector(
    (state) => state.previewpanda.learningresources
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const isDesiredRoute = location.pathname === "/previewpanda";
  const history = useHistory();
  useEffect(() => {
    dispatch(actions.getOGeniusModules());
    dispatch(actions.getExtraCurriculars());
    dispatch(actions.getLearningResources());
    //dispatch(actions.getTestMaps());
    //dispatch(actions.getOpandaBooks());
    dispatch(actions.getClassCourses());
  }, [dispatch]);
  function handleTextChange(event) {
    previewpandaSearchKeyword(event.target.value);
  }

  return (
    <div className={`${visibility} md:block h-full`}>
      {/* Sidebar starts */}
      {/* Remove class [ hidden ] and replace [ sm:flex ] with [ flex ] */}
      <div className="w-full sm:relative bg-gray-200 shadow sm:h-full flex-col justify-between hidden sm:flex">
        <div className="px-8 h-full">
          <div className="mt-6">
            <NavList
              name={t("Modules")}
              isActive={selectedTab === "modules"}
              onClick={() => {
                setSelectedTab("modules");
              }}
            >
              <TabPanel
                hidden={selectedTab !== "modules"}
                className="transform transition duration-500 ease-in-out"
              >
                {modules?.map((module, key) => (
                  <NavSubItem
                    key={key}
                    name={module.abbreviatedname}
                    picked={
                      module.id + module.abbreviatedname === selectedSubMenu
                    }
                    onClick={() => {
                      if (!isDesiredRoute) {
                        history.push("/previewpanda");
                      }
                      store.dispatch({ type: "CHANGE_TAB", payload: "cbc" });
                      props.setindex(key);
                      setSelectedSubMenu(module.id + module.abbreviatedname);
                    }}
                  />
                ))}
              </TabPanel>
            </NavList>
            {/* <NavList
              name={t("Extra Curricular")}
              isActive={selectedTab === "extracurricular"}
              onClick={() => {
                setSelectedTab("extracurricular");
              }}
            >
              <TabPanel
                hidden={selectedTab !== "extracurricular"}
                className="transform transition duration-500 ease-in-out"
              >
                {extracurriculars?.map((curricular, key) => (
                  <NavSubItem
                    key={key}
                    picked={(curricular.id + curricular.abbreviatedname) === selectedSubMenu}
                    name={curricular.abbreviatedname}
                    onClick={() => {
                      if(!isDesiredRoute){
                        history.push("/previewpanda")
                      }
                      changetab("dap");
                      props.setindex(key);
                      setSelectedSubMenu(curricular.id + curricular.abbreviatedname)
                    }}
                  />
                ))}
              </TabPanel>
            </NavList> */}
            {/* <NavList
              name={t("Learning Resources")}
              isActive={selectedTab === "learningresources"}
              onClick={() => {
                setSelectedTab("learningresources");
              }}
            >
              <TabPanel
                hidden={selectedTab !== "learningresources"}
                className="transform transition duration-500 ease-in-out"
              >
                {learningresources?.map((resource, key) => (
                  <NavSubItem
                    key={key}
                    name={resource.abbreviatedname}
                    picked={(resource.id + resource.abbreviatedname) === selectedSubMenu}
                    onClick={() => {
                      if(!isDesiredRoute){
                        history.push("/previewpanda")
                      }
                      changetab("books");
                      props.setindex(key);
                      dispatch(actions.getOpandaBooks(resource.id, resource.abbreviatedname));
                      setSelectedSubMenu(resource.id + resource.abbreviatedname)
                    }}
                  />
                ))}
              </TabPanel>
            </NavList> */}
          </div>
          <div className="flex justify-center border-t-4 mb-4 w-full">
            <div className="relative ">
              <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4"></div>
              <input
                className=" bg-gray-700 focus:outline-none rounded w-full text-sm text-gray-500 bg-gray-100 pl-10 py-2"
                type="text"
                placeholder={t("Search")}
                value={previewpanda_search_keyword}
                onChange={handleTextChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full absolute z-40 bg-gray-200 shadow md:h-full flex-col justify-between  sm:hidden  transition duration-150 ease-in-out`}
        id="mobile-nav"
      >
        <div className="absolute right-0 m-2">
          <ButtonClose color="red" onClick={hidesidebar} />
        </div>
        <div className="px-8">
          <ul className="mt-6">
            <NavList
              name={t("Modules")}
              isActive={selectedTab === "modules"}
              onClick={() => {
                setSelectedTab("modules");
              }}
            >
              <TabPanel
                hidden={selectedTab !== "modules"}
                className="transform transition duration-500 ease-in-out"
              >
                {" "}
                {modules?.map((module, key) => (
                  <NavSubItem
                    key={key}
                    picked={
                      module.id + module.abbreviatedname === selectedSubMenu
                    }
                    name={module.abbreviatedname}
                    onClick={() => {
                      if (!isDesiredRoute) {
                        history.push("/previewpanda");
                      }
                      store.dispatch({ type: "CHANGE_TAB", payload: "cbc" });
                      props.setindex(key);
                      store.dispatch({ type: "HIDE_SIDEBAR" });
                      setSelectedSubMenu(module.id + module.abbreviatedname);
                    }}
                  />
                ))}
              </TabPanel>
            </NavList>
            {/* <NavList
              name={t("Extra Curricular")}
              isActive={selectedTab === "extracurricular"}
              onClick={() => {
                setSelectedTab("extracurricular");
              }}
            >
              <TabPanel
                hidden={selectedTab !== "extracurricular"}
                className="transform transition duration-500 ease-in-out"
              >
                {extracurriculars?.map((curricular, key) => (
                  <NavSubItem
                    key={key}
                    picked={
                      curricular.id + curricular.abbreviatedname ===
                      selectedSubMenu
                    }
                    name={curricular.abbreviatedname}
                    onClick={() => {
                      if (!isDesiredRoute) {
                        history.push("/previewpanda");
                      }
                      changetab("dap");
                      props.setindex(key);
                      store.dispatch({ type: "HIDE_SIDEBAR" });
                      setSelectedSubMenu(
                        curricular.id + curricular.abbreviatedname
                      );
                    }}
                  />
                ))}
              </TabPanel>
            </NavList>
            <NavList
              name={t("Learning Resources")}
              isActive={selectedTab === "learningresources"}
              onClick={() => {
                setSelectedTab("learningresources");
              }}
            >
              <TabPanel
                hidden={selectedTab !== "learningresources"}
                className="transform transition duration-500 ease-in-out"
              >
                {learningresources?.map((resource, key) => (
                  <NavSubItem
                    key={key}
                    name={resource.abbreviatedname}
                    picked={
                      resource.id + resource.abbreviatedname === selectedSubMenu
                    }
                    onClick={() => {
                      if (!isDesiredRoute) {
                        history.push("/previewpanda");
                      }
                      changetab("books");
                      store.dispatch({ type: "HIDE_SIDEBAR" });
                      props.setindex(key);
                      dispatch(
                        actions.getOpandaBooks(
                          resource.id,
                          resource.abbreviatedname
                        )
                      );
                      setSelectedSubMenu(
                        resource.id + resource.abbreviatedname
                      );
                    }}
                  />
                ))}
              </TabPanel>
            </NavList> */}
          </ul>
          <div className="flex justify-center mt-48 mb-4 w-full">
            <div className="relative ">
              <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4"></div>
              <input
                className=" bg-gray-700 focus:outline-none rounded w-full text-sm text-gray-500 bg-gray-100 pl-10 py-2"
                type="text"
                placeholder={t("Search")}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Sidebar ends */}
    </div>
  );
};
// export default SideBar;
const mapStateToProps = () => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
    previewpanda_search_keyword:
      store.getState().reducer.previewpanda_search_keyword,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showsidebar: () => store.dispatch({ type: "SHOW_SIDEBAR" }),
    hidesidebar: () => store.dispatch({ type: "HIDE_SIDEBAR" }),
    changetab: (tab) => store.dispatch({ type: "CHANGE_TAB", payload: tab }),
    previewpandaSearchKeyword: (search) =>
      store.dispatch({ type: "PREVIEWPANDA_SEARCH_KEYWORD", payload: search }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
