export const SETTINGS_ACTION_START = 'SETTINGS_ACTION_START';
export const SETTINGS_ACTION_CLICKED = 'SETTINGS_ACTION_CLICKED';

export const settingsStart = () => {
  return {
    type: SETTINGS_ACTION_START,
  };
};

export const settingsClicked = (actionName) => {
  return {
    type: SETTINGS_ACTION_CLICKED,
    action: actionName,
  };
};

export const completeSettings = (actionName) => {
  return (dispatch) => {
    dispatch(settingsStart());
    setTimeout(() => {
      dispatch(settingsClicked(actionName));
    }, 1000); //wait for 1 second
  };
};
