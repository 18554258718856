import { CardBody, SectionTitle } from "../../../elements/text";
import today from "../../../../functions/greetingsOnDay";
import giveNameRespect from "../../../../functions/giveNameRespect";
const Greetings = (props) => {
  return (
    <>
      <div className="md:hidden bg-white h-20 w-52 flex justify-center rounded-full border border-gray-500">
        <img
          className="inline object-cover w-auto h-full rounded-full"
          src={
            props?.avatar !== null
              ? props?.avatar
              : "/images/avatars/profileavatar.png"
          }
          alt={"Avatar"}
        />
      </div>
      <div>
        {
          <SectionTitle
            name={`${today()}, ${giveNameRespect(props.username)}`}
          />
        }
        <div className="hidden">
          <CardBody name="Duis mollit nostrud nisi irure ea sit mollit sit laborum aliqua eu exercitation do nulla." />
        </div>
      </div>
    </>
  );
};

export default Greetings;
