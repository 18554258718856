import axios from "../../axios-base";

import { getCachedData } from "../../shared/caching";
import fileDownload from "js-file-download";

export const SCHOOL_DASHBOARD_CLASS_COUNT_INIT =
  "SCHOOL_DASHBOARD_CLASS_COUNT_INIT";
export const SCHOOL_DASHBOARD_CLASS_COUNT_SUCCESS =
  "SCHOOL_DASHBOARD_CLASS_COUNT_SUCCESS";
export const SCHOOL_DASHBOARD_CLASS_COUNT_FAILED =
  "SCHOOL_DASHBOARD_CLASS_COUNT_FAILED";

export const SCHOOL_DASHBOARD_CLASS_LIST_INIT =
  "SCHOOL_DASHBOARD_CLASS_LIST_INIT";
export const SCHOOL_DASHBOARD_CLASS_LIST_SUCCESS =
  "SCHOOL_DASHBOARD_CLASS_LIST_SUCCESS";
export const SCHOOL_DASHBOARD_CLASS_LIST_FAILED =
  "SCHOOL_DASHBOARD_CLASS_LIST_FAILED";

export const SCHOOL_DASHBOARD_CLASS_STUDENTS_INIT =
  "SCHOOL_DASHBOARD_CLASS_STUDENTS_INIT";
export const SCHOOL_DASHBOARD_CLASS_STUDENTS_SUCCESS =
  "SCHOOL_DASHBOARD_CLASS_STUDENTS_SUCCESS";
export const SCHOOL_DASHBOARD_CLASS_STUDENTS_FAILED =
  "SCHOOL_DASHBOARD_CLASS_STUDENTS_FAILED";

  export const SCHOOL_DASHBOARD_CLASS_ADMIN_INIT =
  "SCHOOL_DASHBOARD_CLASS_ADMIN_INIT";
export const SCHOOL_DASHBOARD_CLASS_ADMIN_SUCCESS =
  "SCHOOL_DASHBOARD_CLASS_ADMIN_SUCCESS";
export const SCHOOL_DASHBOARD_CLASS_ADMIN_FAILED =
  "SCHOOL_DASHBOARD_CLASS_ADMIN_FAILED";  

export function school_dashboard_class_count_init() {
  return {
    type: SCHOOL_DASHBOARD_CLASS_COUNT_INIT,
  };
}
export function school_dashboard_class_count_success(data) {
  return {
    type: SCHOOL_DASHBOARD_CLASS_COUNT_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_class_count_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_CLASS_COUNT_FAILED,
    payload: error,
  };
}
export function school_dashboard_class_count_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_class_count_init());
    axios
      .get(`/opanda/manage-classes/findcount`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(school_dashboard_class_count_success(response.data.results));
      })
      .catch((err) => {
        dispatch(school_dashboard_class_count_failed(err.message));
      });
  };
}

export function school_dashboard_class_list_init() {
  return {
    type: SCHOOL_DASHBOARD_CLASS_LIST_INIT,
  };
}
export function school_dashboard_class_list_success(data) {
  return {
    type: SCHOOL_DASHBOARD_CLASS_LIST_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_class_list_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_CLASS_LIST_FAILED,
    payload: error,
  };
}
export function school_dashboard_class_list_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_class_list_init());
    axios
      .get(`/opanda/manage-class/findclasscount`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(school_dashboard_class_list_success(response.data.results));
      })
      .catch((err) => {
        dispatch(school_dashboard_class_list_failed(err.message));
      });
  };
}

export function school_dashboard_class_students_init() {
  return {
    type: SCHOOL_DASHBOARD_CLASS_STUDENTS_INIT,
  };
}
export function school_dashboard_class_students_success(data) {
  return {
    type: SCHOOL_DASHBOARD_CLASS_STUDENTS_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_class_students_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_CLASS_STUDENTS_FAILED,
    payload: error,
  };
}
export function school_dashboard_class_students_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_class_students_init());
    axios
      .get(`/opanda/manage-classes/findstudent`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(school_dashboard_class_students_success(response.data.results));
      })
      .catch((err) => {
        dispatch(school_dashboard_class_students_failed(err.message));
      });
  };
}

export function school_dashboard_class_admin_init() {
  return {
    type: SCHOOL_DASHBOARD_CLASS_ADMIN_INIT,
  };
}
export function school_dashboard_class_admin_success(data) {
  return {
    type: SCHOOL_DASHBOARD_CLASS_ADMIN_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_class_admin_failed(error) {
  return {
    type: SCHOOL_DASHBOARD_CLASS_ADMIN_FAILED,
    payload: error,
  };
}
export function school_dashboard_class_admin_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_class_admin_init());
    axios
      .get(`/opanda/manage-classes/findadmin`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      }).then((response) => {
        dispatch(school_dashboard_class_admin_success(response.data.results));
      }).catch((err) => {
        dispatch(school_dashboard_class_admin_failed(err.message));
      });
  };
}