import PageContent from '../pageContent';
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from '../../../elements/text';
import { Button, GoBackButton } from '../../../elements/button';
import { useState } from 'react';
import CountDown from '../../../elements/CountDown';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import celebration from '../../../../images/celebration.png';
import { FeatureItemCard } from '../../../elements/card';
import { useHistory } from 'react-router-dom';
import { student_dashboard_attempt_a_challenge_start } from '../../../../store/actions/student.attempt.challenges';
import Test from './test/TestTeacherMade';
import Spinner from '../../../elements/spinner';
import TestAuto from './test/TestAuto';
import TestTeacherMade from './test/TestTeacherMade';
import TestResultsChallengesAnswerSheets from './test/testsresults/TestResultsChallengesAnswerSheets';


const Challenge = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const test_params = useParams();
  const userAuth = useSelector((state) => state.auth);
  //---
  const studentAttemptAchallenges = useSelector(
    (state) => state.studentAttemptAchallenges
  );
  const challengeId = test_params.challengeId;
  const challengetype = params.challengetype;
  const [seeAnswerSheet, setSeeAnswerSheet] = useState(true);
  const [unitname, setUnitName] = useState("");
  useEffect(() => {
    //--------------------
    setUnitName(params.unitname)

    if (params.attempt === "true") {
     /* setSeeAnswerSheet(false);
      dispatch(
        student_dashboard_attempt_a_challenge_start({
          token: userAuth.token,
          user_id: userAuth.userId,
          challengeId: challengeId,
          academic_level: userAuth.academic_level
        })
      );*/
    } else {
      setSeeAnswerSheet(true);
      console.log("Viewing answer sheets")
    }
  }, []);

  const queryString_p = (params) => {
    const queryString = Object.keys(params).map(function (key) {
      return key + '=' + params[key]
    }).join('&');
    return queryString;
  }
  return (
    <PageContent>
      {(!seeAnswerSheet && !studentAttemptAchallenges.dashboard_attempt_a_challenge_loading && studentAttemptAchallenges.dashboard_attempt_a_challenge.length > 0) ? <div
        className={`relative w-full p-3 max-h-myscreen-sec md:max-h-screen-sec col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <FeatureTitle name="Challenges" />
        <span onClick={() => {
          if (window.confirm("Are you sure you want to cancel and go back!!??")) {
            history.push("/teacher/features/tests")
          }
        }}><GoBackButton
            link={`?${queryString_p(params)}`}
            action="Back"
            location={"Challenge: " + unitname}
          /></span>
        {(studentAttemptAchallenges.dashboard_attempt_a_challenge.length > 0) ? (studentAttemptAchallenges.dashboard_attempt_a_challenge.length === 5) ? <TestAuto challengeId={challengeId} dashboard_homepage_auto_test={studentAttemptAchallenges.dashboard_attempt_a_challenge} testId={"0"} unit={studentAttemptAchallenges.dashboard_attempt_a_challenge[3]} difficulty={studentAttemptAchallenges.dashboard_attempt_a_challenge[4]} /> : <TestTeacherMade challengeId={challengeId} testId={"1"} dashboard_homepage_teacher_made_test={studentAttemptAchallenges.dashboard_attempt_a_challenge} unit={studentAttemptAchallenges.dashboard_attempt_a_challenge[2]} /> : <span></span>}
      </div> : (!seeAnswerSheet) ? <Spinner size="20" color="blue" /> : <div><FeatureTitle name="Challenges" />
        <span onClick={() => {
          if (window.confirm("Are you sure you want to cancel and go back!!??")) {
            history.push("/teacher/features/tests")
          }
        }}><GoBackButton
            link={`?${queryString_p(params)}`}
            action="Back"
            location={"Test Answer sheet: " + unitname}
          /></span>{((challengetype + "") === ("" + 0)) ? <TestResultsChallengesAnswerSheets useranswerid={challengeId} testid={params.testid} unitname={params.unitname} subject={params.subject} challengeId={challengeId} challengetype={challengetype} /> : <TestResultsChallengesAnswerSheets subject={params.subject} useranswerid={challengeId} testid={params.testid} challengeId={challengeId} challengetype={challengetype} />}</div>}
    </PageContent>
  );
};

export default Challenge;
