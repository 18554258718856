import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { replaceReact } from "replace-react";
import { FcAnswers } from "react-icons/fc";
import parse from "react-html-parser";
import "./test.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as constants from "./constants";
import * as qconstants from "./qconstants";
import { hidesidebar } from "../store/actions/sidebaractiontypes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions";
import { InputTextArea } from "./Algorithms";

export const TeacherTestMultiplechoice = forwardRef((props, ref) => {
  const [questiondata, setquestiondata] = useState("");
  const [answersdata, setanswersdata] = useState("ANSWERS DATA");
  const dispatch = useDispatch();
  const questionobject = {
    panda_drag_and_drop_2020_03_access_code: "CODE",
    panda_drag_and_drop_2020_03_creator: 0,
    panda_drag_and_drop_2020_03_regdate: "2021-01-20T11:22:54.000Z",
    panda_drag_and_drop_2020_03_question_data_answers: answersdata,
    panda_drag_and_drop_2020_03_question_data: questiondata,
    panda_drag_and_drop_2020_03_duration_of_test: 0,
    panda_drag_and_drop_2020_03_test_difficulty: "DIF",
    panda_drag_and_drop_2020_03_unit: "UNit 3",
    panda_drag_and_drop_2020_03_marks: "2",
    panda_drag_and_drop_2020_03_duration: "2",
    panda_drag_and_drop_2020_03_test_id: "47",
  };
  const onSubmit = () => {
    dispatch(actions.registerTeacherDragAndDropQuestions(questionobject));
  };
  return (
    <div className="px-8 py-5 flex flex-col">
      <h5>Teacher Creates Test</h5>
      <div className="flex space-x-4 pt-3">
        <a
          href="/teacher-test"
          className="bg-gray-500 rounded-md text-white text-center px-2"
        >
          Fillin
        </a>
        <a
          href="/teacher-test-matching"
          className="bg-gray-500 rounded-md text-white text-center px-2"
        >
          Matching
        </a>
        <a
          href="/teacher-test-openended"
          className="bg-gray-500 rounded-md text-white text-center px-2"
        >
          Open Ended
        </a>
      </div>
      <div className="flex flex-col pt-8 space-y-3">
        <h4>Matching Question</h4>
        <div className="w-3/5">
          <InputTextArea
            value={questiondata}
            onChange={(event) => {
              setquestiondata(event);
            }}
          />
          <input type={"submit"} value="Submit" onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
});
