import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, CardSubText, CardTitle } from "../../text";
import parse from "react-html-parser";
import { Button } from "../../button";
import {
  student_answer_question_of_the_day_start,
  student_question_of_the_day_start,
} from "../../../../store/actions/student.dashboard.homepage";
import Spinner from "../../spinner";
import { getQuestionOfTheDay } from "../../../../functions/questionOfTheDay";
import { removeItemFromArray } from "../../../../functions/subjectSpecializationUtils";

const Question = (props) => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const memoizedUserAth = useMemo(() => userAth, [userAth]);
  const [loadedOnce, setLoadedOnce] = useState(true);
  const [chosen_answers_pool, setChosen_answers_pool] = useState([]);
  const [chosen_QuestionID, setChosen_QuestionID] = useState([]);
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );
  const question_ofTheDay = useMemo(() => {
    return getQuestionOfTheDay(
      studentDashboardhomePage.questionOfTheDay
        ? studentDashboardhomePage.questionOfTheDay
        : []
    );
  }, [studentDashboardhomePage.questionOfTheDay]);

  const handleChange = (e) => {
    if (e.target.value) {
      setChosen_answers_pool([...chosen_answers_pool, e.target.data]);
    } else {
      const newArr = removeItemFromArray(chosen_answers_pool, e.target.data);
      setChosen_answers_pool(newArr);
    }
  };

  const fetchnotificationData = useCallback(async () => {
    const data = {
      token: memoizedUserAth.token,
      username: memoizedUserAth.username,
      userId: memoizedUserAth.userId,
      day: new Date().getTime(),
      academic_level: memoizedUserAth.academic_level,
    };

    try {
      await dispatch(student_question_of_the_day_start(data));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, memoizedUserAth]);

  useEffect(() => {
    if (loadedOnce) {
      fetchnotificationData();
    }
  }, [loadedOnce, fetchnotificationData]);

  return !studentDashboardhomePage.questionOfTheDay_loading &&
    question_ofTheDay.length > 0 ? (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <CardTitle name={props.title} color={props.titleColor} />
      <div className="card space-x-2 py-2 questionoftheday">
        <CardBody name={parse(question_ofTheDay[0][1])} />

        {question_ofTheDay[1].map((answer, index) => (
          <div key={index} className="flex items-center space-x-2 py-2">
            {question_ofTheDay[2] === "single_answer" ? (
              <input
                disabled={studentDashboardhomePage.questionOfTheDayAnswered}
                type="radio"
                key={question_ofTheDay[0][0]}
                onClick={() => {
                  setChosen_answers_pool([answer[0]]);
                  setChosen_QuestionID(question_ofTheDay[0][0]);
                }}
                name={question_ofTheDay[0][0]}
              />
            ) : (
              <input
                disabled={studentDashboardhomePage.questionOfTheDayAnswered}
                type="checkbox"
                key={question_ofTheDay[0][0]}
                onChange={(e) => {
                  setChosen_QuestionID(question_ofTheDay[0][0]);
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                      data: answer[0],
                    },
                  });
                }}
                name={question_ofTheDay[0][0]}
              />
            )}
            <CardSubText name={parse(answer[1])} />
          </div>
        ))}
      </div>

      <div className="flex justify-end">
        {!studentDashboardhomePage.questionOfTheDayAnswered &&
        question_ofTheDay.length > 0 &&
        !studentDashboardhomePage.answer_questionOfTheDay_loading ? (
          <Button
            name={props.action}
            outline="true"
            color="blue"
            clicked={() => {
              dispatch(
                student_answer_question_of_the_day_start({
                  token: memoizedUserAth.token,
                  username: memoizedUserAth.username,
                  userId: memoizedUserAth.userId,
                  day: new Date().getTime(),
                  academic_level: memoizedUserAth.academic_level,
                  question_id: chosen_QuestionID,
                  answers_ids: chosen_answers_pool.join(","),
                })
              );
            }}
          />
        ) : studentDashboardhomePage.answer_questionOfTheDay_loading &&
          !studentDashboardhomePage.questionOfTheDayAnswered ? (
          <Spinner size="20" color="blue" />
        ) : (
          <div style={{ color: "red" }}>
            {studentDashboardhomePage.answer_questionOfTheDay.results[2] ===
            "FAIL" ? (
              <div
                className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                role="alert"
              >
                <svg
                  className="inline flex-shrink-0 mr-3 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <div>
                  <span className="font-medium">Failed! </span>(
                  {studentDashboardhomePage.answer_questionOfTheDay.results[0]}/
                  {studentDashboardhomePage.answer_questionOfTheDay.results[1]})
                  <div>
                    Right Answer(s):
                    {studentDashboardhomePage.answer_questionOfTheDay.results[3].map(
                      (answer) => {
                        return (
                          <div
                            className="card space-x-2 py-2"
                            style={{ color: "black" }}
                          >
                            {parse(answer)}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ color: "green" }}
                className="flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
                role="alert"
              >
                <svg
                  className="inline flex-shrink-0 mr-3 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <div>
                  <span className="font-medium">Congrats! </span>(
                  {studentDashboardhomePage.answer_questionOfTheDay.results[0]}/
                  {studentDashboardhomePage.answer_questionOfTheDay.results[1]})
                  <div>
                    Right Answer(s):
                    {studentDashboardhomePage.answer_questionOfTheDay.results[3].map(
                      (answer) => {
                        return (
                          <div
                            className="card space-x-2 py-2"
                            style={{ color: "black" }}
                          >
                            {parse(answer)}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default Question;
