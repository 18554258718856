import ProfilePageContent from '../../landing/completeprofile/pageContent';

const Profile = () => {
  return (
    // <PageContent>
      <ProfilePageContent selectedColor="gray" user="school"/>
    // </PageContent>
  );
};

export default Profile;
