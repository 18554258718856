import Layout from '../../../components/common/Layout';
import Pastpaper from '../../../components/pages/teacher/pastpaper/Pastpaper';

const PastpaperContainer = () => {
  return (
    <Layout>
      <Pastpaper />
    </Layout>
  );
};

export default PastpaperContainer;
