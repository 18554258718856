import { updateObject } from '../../shared/utility';

import {
  PAYMENT_ACTION_CLICKED,
  PAYMENT_ACTION_START,
} from '../actions/paymentActionTypes';

const paymentItems = [
  {
    title: 'Package information',
    subTitle: 'Students, teachers & admins',
    completed: false,
    selected: true,
  },
  {
    title: 'Renew package',
    subTitle: 'Renew',
    completed: false,
    selected: false,
  },
  
];

const initialReducer = {
  paymentItems: paymentItems,
  error: null,
  loading: false,
};

const paymentStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};


const paymentClicked = (state, action) => {
  const newPaymentItems = state.paymentItems.map((item) => {
    return {
      title: item.title,
      subTitle: item.subTitle,
      completed: item.completed,
      selected: item.title === action.action ? true : false,
    };
  });
  return updateObject(state, {
    paymentItems: newPaymentItems,
    error: null,
    loading: false,
  });
};



const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case PAYMENT_ACTION_START:
      return paymentStart(state, action);
    
    case PAYMENT_ACTION_CLICKED:
      return paymentClicked(state, action);
    default:
      return state;
  }
};
export default reducer;
