import reactStringReplace from "react-string-replace";
import dotenv from "dotenv";
const fs = require("fs").promises;

dotenv.config();
export const numberWithCommas = (x: any) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  numberWithCommas,
};

export const replaceAllLInksWithRealSource = (data: any) => {
  var div = document.createElement("div");
  div.innerHTML = data;
  var images = div.getElementsByTagName("img");
  var image_src;

  for (var i = 0; i < images.length; i++) {
    image_src = images[i].src;
    //---
    let imageHrefPrefix = images[i].src;

    if (imageHrefPrefix.includes("pastpapers/simulation_thumbnails")) {
      const n =
        process.env.REACT_APP_BACKEND_URL +
        imageHrefPrefix.split("pastpapers")[1];

      images[i].src = n;
    } else {
      continue;
    }
  }

  return div.innerHTML;
};
function encodeURIComponent(text) {
  return text.replace(/\<\/p\>/g, "");
}
// export function beautifyOpandaPostsAndCommentsText(text) {
//   // Convert HTML entities to tags
//   text = text + " ";
//   text = text.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&");

//   // Highlight URLs and make them clickable
//   var regex = /(https?:\/\/[^\s]+)/g;
//   text = text.replace(regex, (match) => {
//     return "<a href='" + encodeURIComponent(match) + "' className='hoverHyperLinks' style='text-decoration:none;color:blue;font-size:12px' target='_blank'>" + encodeURIComponent(match) + "</a>";
//   });

//   return text
// }

export function beautifyOpandaPostsAndCommentsText(text) {
  // console.log("iframe text", text);
  // Convert HTML entities to tags
  text = text + " ";
  text = text
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&");

  // Highlight URLs and make them clickable
  var regex = /(https?:\/\/[^\s]+)/g;
  const iframeregex = /<iframe.*?<\/iframe>/gi;
  /* temporarily replacing all occurrences of `<iframe>`
  tags in the `text` string with the string "<!!!" before further processing */
  const iframeContents = extractIframes(text);
  text = text.replace(iframeregex, "<!!!");
  // console.log("removed iframes", text)
  text = text.replace(regex, (match) => {
    // For other URLs, create the clickable hyperlink
    return (
      "<a href='" +
      encodeURIComponent(match) +
      "' className='hoverHyperLinks' style='text-decoration:none;color:blue;font-size:12px' target='_blank'>" +
      encodeURIComponent(match) +
      "</a>"
    );
  });
  
  return replaceTextWithArrayElements(text,iframeContents);
}

function replaceTextWithArrayElements(text, arr) {
  for (const element of arr) {
    text = text.replace("<!!!", element);
  }
  // console.log("replaced delimiters", text)
  return text;
}
function extractIframes(text) {
  const regex = /<iframe.*?<\/iframe>/gi;
  return text.match(regex) || [];
}
