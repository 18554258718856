import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  profiles: [],
  profile: null, 
  profileError: null,
  profileLoading: false,
  cbccombinations: [],
  cbccombination: null,
  cbccombinationError: null,
  cbccombinationLoading: false,
};

const getProfilesStart = (state, action) => {
  return updateObject(state, {
    profiles: [],
    profilesError: null,
    profilesLoading: true,
    profile: null,
  });
};

const getProfilesSuccess = (state, action) => {
  return updateObject(state, {
    profiles: action.profiles,
    profilesError: null,
    profilesLoading: false,
    profile: null,
  });
};

const getProfilesFail = (state, action) => {
  return updateObject(state, {
    profiles: [],
    profilesError: action.error,
    profilesLoading: false,
    profile: null,
  });
};

const getOneProfilesSuccess = (state, action) => {
  return updateObject(state, {
    profiles: [],
    profilesError: null,
    profilesLoading: false,
    profile: action.profile,
  });
};

const getCBCCombinationsStart = (state, action) => {
  return updateObject(state, {
    cbccombinations: [],
    cbccombinationsError: null,
    cbccombinationsLoading: true,
    cbccombination: null,
  });
};

const getCBCCombinationsSuccess = (state, action) => {
  return updateObject(state, {
    cbccombinations: action.cbccombinations,
    cbccombinationsError: null,
    cbccombinationsLoading: false,
    cbccombination: null,
  });
};

const getCBCCombinationsFail = (state, action) => {
  return updateObject(state, {
    cbccombinations: [],
    cbccombinationsError: action.error,
    cbccombinationsLoading: false,
    cbccombination: null,
  });
};

const getOneCBCCombinationsSuccess = (state, action) => {
  return updateObject(state, {
    cbccombinations: [],
    cbccombinationsError: null,
    cbccombinationsLoading: false,
    cbccombination: action.cbccombination,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_PROFILE_INFORMATION_START:
      return getProfilesStart(state, action);
    case actionsTypes.GET_PROFILE_INFORMATION_SUCCESS:
      return getProfilesSuccess(state, action);
    case actionsTypes.GET_ONE_PROFILE_INFORMATION_SUCCESS:
      return getOneProfilesSuccess(state, action);
    case actionsTypes.GET_PROFILE_INFORMATION_FAIL:
      return getProfilesFail(state, action);
    case actionsTypes.GET_CBC_COMBINATIONS_START:
      return getCBCCombinationsStart(state, action);
    case actionsTypes.GET_CBC_COMBINATIONS_SUCCESS:
      return getCBCCombinationsSuccess(state, action);
    case actionsTypes.GET_ONE_CBC_COMBINATIONS_SUCCESS:
      return getOneCBCCombinationsSuccess(state, action);
    case actionsTypes.GET_CBC_COMBINATIONS_FAIL:
      return getCBCCombinationsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
