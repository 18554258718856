import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { replaceReact } from "replace-react";
import { FcAnswers } from "react-icons/fc";
import parse from "react-html-parser";
import "./test.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as constants from "./constants";
import * as qconstants from "./qconstants";
import { hidesidebar } from "../store/actions/sidebaractiontypes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions/";
import {
  InputText,
  InputTextArea,
  MatchingAnswerCard,
  MatchingResponceCard,
} from "./Algorithms";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as studentsubmittestconsntants from "./studentssubmittestconstants";

export const StudentViewTest = (props) => {
  const testmap = useSelector((state) => state.testmaps.testmap);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(actions.getOneTestMaps(params.testmapId));
  }, [dispatch]);

  const ref = useRef();
  const answersRef = useRef([]);
  answersRef.current = [];

  const addToRefs = (qRef) => {
    if (qRef && !answersRef.current.includes(qRef)) {
      answersRef.current.push(qRef);
    }
  };

  let answersmap = {};
  let studentanswersmap = "";
  const submittest = () => {
    if (window.confirm("Are you sure you want to submit?")) {
      answersRef.current.forEach((element) => {
        answersmap = element.submit();
      });

      if (answersmap) {
        studentanswersmap =
          answersmap.complete_sentence.responsedata +
          "~~#~#~~" +
          answersmap.drag_and_drop.responsedata +
          "~~#~#~~" +
          answersmap.open_ended.responsedata;
      }
      // constring objects to be sent to DB
      // #1 Saving answers map
      const answeringobject = {
        panda_2020_03_03_students_answering_map_user_id: 0,
        panda_2020_03_03_students_answering_map_corrected: 0,
        panda_2020_03_03_students_answering_map_open_ended_marks: "33",
        panda_2020_03_03_students_answering_map_test_id: 39,
        panda_2020_03_03_students_answering_map_marks: studentanswersmap,
        panda_2020_03_03_students_answering_map_names: "Kalisa",
        panda_2020_03_03_students_answering_map_regnumber: "19073",
      };
      dispatch(actions.createStudentsAnsweringMaps(answeringobject));

      // Emptying response after submitting
      studentanswersmap = "";
    } else {
    }
  };

  return (
    <div className="flex flex-col px-10 py-5">
      <label>TEST TITLE: {testmap?.panda_test_map_2020_03_title}</label>
      <label>TEST CODE: {testmap?.panda_test_map_2020_03_access_code}</label>
      <label>TEST SUBJECT: {testmap?.panda_test_map_2020_03_subject}</label>
      <div>
        {testmap?.questionsmap?.map((question, i) => (
          <ViewQuestionInTest
            ref={addToRefs}
            questionnumber={i + constants.ONE}
            question={question}
          />
        ))}
      </div>
      <input
        type={"button"}
        className="bg-gray-500 text-white text-center px-1 w-40"
        value={"Submit"}
        onClick={submittest}
      />
    </div>
  );
};

export const ViewQuestionInTest = forwardRef((props, ref) => {
  const openendedRef = useRef();
  const dragndropRef = useRef();
  const completeRef = useRef();
  const question = props.question;
  useImperativeHandle(ref, () => ({
    submit() {
      let open_ended = openendedRef.current.submitingAnswer();
      let drag_and_drop = dragndropRef.current.submitingAnswer();
      let complete_sentence = completeRef.current.submitingAnswer();
      return {
        open_ended,
        complete_sentence,
        drag_and_drop,
      };
    },
  }));
  return (
    <div className="flex flex-col px-10 py-5">
      <div className="flex justify-between bg-gray-200 px-10">
        <label className="">Question Number: {props.questionnumber}</label>
        <label className="">+</label>
      </div>
      <div className="flex flex-col">
        <div
          className={
            question.questiontype == constants.complete_sentence
              ? "block"
              : "hidden"
          }
        >
          <StudentDisplayCompleteSentence
            ref={completeRef}
            questiondata={question.questiondata}
          />
        </div>
        <div
          className={
            question.questiontype == constants.open_ended ? "block" : "hidden"
          }
        >
          <StudentDisplayOpenEnded
            ref={openendedRef}
            questiondata={question.questiondata}
          />
        </div>
        <div
          className={
            question.questiontype == constants.drag_and_drop
              ? "block"
              : "hidden"
          }
        >
          <StudentDisplayDragAndDrop
            ref={dragndropRef}
            questiondata={question.questiondata}
          />
        </div>
      </div>
    </div>
  );
});

export const StudentDisplayCompleteSentence = forwardRef((props, ref) => {
  const questiondata = props?.questiondata?.panda_fill_in_2020_03_question_data;
  const questionId = props?.questiondata?.panda_drag_and_drop_2020_03_id;
  const answers =
    props?.questiondata?.panda_fill_in_2020_03_question_data_answers;
  let answersArray = [];
  let regWord;
  if (answers != undefined) {
    answersArray = answers.split("~----~");
    regWord = new RegExp(answersArray.join("|"), "i");
  }

  let displayabledata;
  const question =
    questiondata != undefined
      ? parse(questiondata)
      : parse("<p></p>").toString();
  // console.log(question);
  if (question.props != undefined) {
    const quest = question.props.children;
    displayabledata =
      "<p>" +
      quest.replace(
        regWord,
        `<input placeholder="......................" className="fill-in" name="answer"/> `
      ) +
      "</p>";
  }

  const answersRef = useRef([]);
  answersRef.current = [];

  const addToRefs = (qRef) => {
    if (qRef && !answersRef.current.includes(qRef)) {
      answersRef.current.push(qRef);
    }
  };
  let completesentenceresponse = [];
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
      let data = studentsubmittestconsntants.getCompleteSentenceAnswers(
        "divId",
        questionId
      );

      return {
        responsedata: data,
        questiontype: constants.complete_sentence,
      };
    },
  }));

  return (
    <div className="flex px-5 py-3" id={"divId"}>
      <label class="answers-div">
        {displayabledata != undefined
          ? parse(displayabledata)
          : parse("<p></p>")}
      </label>
    </div>
  );
});

export const StudentDisplayDragAndDrop = forwardRef((props, ref) => {
  const questiondata =
    props?.questiondata?.panda_drag_and_drop_2020_03_question_data;
  const questionId = props?.questiondata?.panda_drag_and_drop_2020_03_id;
  const answers =
    props?.questiondata?.panda_drag_and_drop_2020_03_question_data_answers;
  let answersArray = [];
  let regWord;
  if (answers != undefined) {
    answersArray = answers.split("~----~");
    regWord = new RegExp(answersArray.join("|"), "i");
  }

  let displayabledata;
  let questionparts = [];
  const data =
    questiondata != undefined
      ? parse(questiondata)
      : parse("<p></p>").toString();
  if (data.props != undefined) {
    const quest = data.props.children;
    questionparts = quest.split(" ");
  }
  const reversedAnswers = answersArray.slice();
  let counter = -1;

  const draggingRefs = useRef([]);
  draggingRefs.current = [];

  const addToRefs = (qRef) => {
    if (qRef && !draggingRefs.current.includes(qRef)) {
      draggingRefs.current.push(qRef);
    }
  };

  let dragsresponses = [];
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
      draggingRefs.current.forEach((element) => {
        dragsresponses.push(element.submit());
      });
      let uniqueresponse = [...new Set(dragsresponses)];
      let dragsresponsesdata = uniqueresponse.join("~~#~#~~");
      return {
        responsedata: dragsresponsesdata,
        questiontype: constants.drag_and_drop,
      };
    },
  }));
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex px-5 py-3">
        <label>
          {displayabledata != undefined
            ? parse(displayabledata)
            : parse("<p></p>")}
        </label>
        <div className="flex space-x-32 justify-betwee w-4/5">
          <div className="w-2/5 space-y-2 flex flex-col">
            {answersArray.reverse().map((option, id) => (
              <MatchingAnswerCard color={option} id={id} key={id} />
            ))}
          </div>
          <div className="w-3/5 space-y-2 flex">
            <span className="flex">
              {questionparts.map((part, i) => {
                if (part.match(regWord)) {
                  counter = counter + 1;
                }
                return part.match(regWord) ? (
                  <CompleteSentenceResponceCard
                    key={i}
                    ref={addToRefs}
                    listofanswers={answersArray[counter]}
                    index={counter}
                    questionId={questionId}
                  />
                ) : (
                  part + constants.oneEmptySpace
                );
              })}
            </span>
          </div>
        </div>
      </div>
    </DndProvider>
  );
});

export const StudentDisplayOpenEnded = forwardRef((props, ref) => {
  const question = props?.questiondata?.panda_open_ended_2020_03_question_data;
  const questionId = props?.questiondata?.panda_open_ended_2020_03_id;
  const [openanswer, setopenanswer] = useState("");
  let result;
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
      let openresponsesdata = studentsubmittestconsntants.getOpenEndedAnswers(
        questionId,
        openanswer
      );
      return {
        responsedata: openresponsesdata,
        questiontype: constants.open_ended,
      };
    },
  }));
  return (
    <div className="flex flex-col px-5 py-3 space-y-5">
      <div>
        Question
        {question != undefined ? parse(question) : parse("<p></p>")}
      </div>

      <div className="flex flex-col space-y-2">
        Your Answer
        <InputTextArea
          value={openanswer}
          onChange={(event) => {
            setopenanswer(event);
          }}
        />
      </div>
    </div>
  );
});

export const CompleteSentenceResponceCard = forwardRef((props, ref) => {
  const [answer, setanswer] = useState("___________");
  const [showanswers, setshowanswers] = useState(false);
  const [isanswered, setisanswered] = useState(false);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "answer",
    drop: (item) => fillAnswer(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const fillAnswer = (id) => {
    setanswer(id);
    setisanswered(true);
    setshowanswers(!showanswers);
  };
  let questId = props.questId;
  useImperativeHandle(ref, () => ({
    submit() {
      return studentsubmittestconsntants.getDragAndDropAnswers(
        answer,
        props.questionId
      );
    },
  }));
  return (
    <span className="flex">
      <span
        ref={drop}
        className={
          isanswered == false
            ? "bg-gray-500 text-center px-3 ml-1 h-6"
            : "bg-blue text-white text-center px-3 ml-1 h-6"
        }
      >
        <span className="answers">{answer}</span>
      </span>{" "}
    </span>
  );
});
