import { Button, ButtonWithIcon } from "../../../elements/button";
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from "../../../elements/text";
import PageContent from "../pageContent";
import { useEffect, useState } from "react";
import Modal from "../../../elements/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { school_dashboard_users_count_start } from "../../../../store/actions/school.dashboard.users";
import NewUserForm from "./newuserform";
import ImportForm from "./importform";

const Layout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuth = useSelector((state) => state.auth);
  const schoolDashboardUsers = useSelector(
    (state) => state.schoolDashboardUsers
  );

  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const [showNewDropdown_Newstudents, setShowNewDropdown_Newstudents] = useState(false);
  const [showNewDropdown_NewTeachersOrParents, setShowNewDropdown_NewTeachersOrParents] = useState(false);
  const [isForStudentsOnly, setIsForStudentsOnly] = useState(false);

  useEffect(() => {
    dispatch(
      school_dashboard_users_count_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
  }, []);

  return (
    <PageContent>
      <Modal
        medium
        show={showImportModal}
        modalClosed={() => setShowImportModal(false)}
      >
        <div className="flex flex-col">
          <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
            <CardTitle name="Import users from excel" color="blue" />
            <ImportForm isForStudentsOnly={isForStudentsOnly} onCancel={() => setShowImportModal(false)} />
          </div>
        </div>
      </Modal>
      <Modal
        medium
        show={showNewUserModal}
        modalClosed={() => setShowNewUserModal(false)}
      >
        <div className="flex flex-col">
          <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
            <CardTitle name="User's information" color="blue" />
            <NewUserForm isForStudentsOnly={isForStudentsOnly} onCancel={() => setShowNewUserModal(false)} />
          </div>
        </div>
      </Modal>
      <div className="w-full h-full flex flex-col justify-between p-3 md:space-x-4 max-h-myscreen overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <div className="w-full flex flex-row justify-between">
          <FeatureTitle name="Users management" />{" "}
          <div className="relative  px-2 ">
            <div className="md:flex space-x-2 justify-center items-center cursor-pointer">
              <ButtonWithIcon
                outline="true"
                color="blue"
                isSmall="true"
                name="Add new students"
                onClick={() => {
                  setShowNewDropdown_Newstudents(!showNewDropdown_Newstudents)
                  setShowNewDropdown_NewTeachersOrParents(false)
                  setIsForStudentsOnly(true)
                }}
              />
              <ButtonWithIcon
                outline="true"
                color="blue"
                isSmall="true"
                name="Add new teachers/parents"
                onClick={() => {
                  setShowNewDropdown_NewTeachersOrParents(!showNewDropdown_NewTeachersOrParents)
                  setShowNewDropdown_Newstudents(false)
                  setIsForStudentsOnly(false)
                }}
              />
            </div>
            <div
              className={`${showNewDropdown_Newstudents
                ? "mt-2 absolute z-50 bg-white text-main-color lg:right-0 shadow-lg"
                : "hidden"
                }`}
            >  <h6>Students:</h6>
              <ul className="divide-y divide-blue">
                <li
                  className="flex justify-between items-center space-x-2 p-1 cursor-pointer"
                  onClick={() => {
                    setShowImportModal(true);
                    setShowNewDropdown_Newstudents(!showNewDropdown_Newstudents);
                  }}
                >
                  <CardBody
                    name="From excel"
                    color="blue"
                    additional="hover:font-bold"
                  />
                </li>
                <li
                  className="flex justify-between items-center space-x-2 p-1 cursor-pointer"
                  onClick={() => {
                    setShowNewUserModal(true);
                    setShowNewDropdown_Newstudents(!showNewDropdown_Newstudents);
                  }}
                >
                  <CardBody
                    name="New User"
                    color="blue"
                    additional="hover:font-bold"
                  />
                </li>
                {/* <li
                  className="flex justify-between items-center space-x-2 p-1 cursor-pointer"
                  onClick={() => history.push("/school/features/users/importexisting", { userId: props.user_id })}

                >
                  <CardBody
                    name="Existing user"
                    color="blue"
                    additional="hover:font-bold"
                  />
                </li> */}
              </ul>
            </div>
            <div
              className={`${showNewDropdown_NewTeachersOrParents
                ? "mt-2 absolute z-50 bg-white text-main-color lg:right-0 shadow-lg"
                : "hidden"
                }`}
            ><h6>Parents/Teachers:</h6>
              <ul className="divide-y divide-blue">
                <li
                  className="flex justify-between items-center space-x-2 p-1 cursor-pointer"
                  onClick={() => {
                    setShowImportModal(true);
                    setShowNewDropdown_Newstudents(!showNewDropdown_NewTeachersOrParents);
                  }}
                >
                  <CardBody
                    name="From excel"
                    color="blue"
                    additional="hover:font-bold"
                  />
                </li>
                <li
                  className="flex justify-between items-center space-x-2 p-1 cursor-pointer"
                  onClick={() => {
                    setShowNewUserModal(true);
                    setShowNewDropdown_Newstudents(!showNewDropdown_NewTeachersOrParents);
                  }}
                >
                  <CardBody
                    name="New User"
                    color="blue"
                    additional="hover:font-bold"
                  />
                </li>
                {/* <li
                  className="flex justify-between items-center space-x-2 p-1 cursor-pointer"
                  onClick={() => history.push("/school/features/users/importexisting", { userId: props.user_id })}

                >
                  <CardBody
                    name="Existing user"
                    color="blue"
                    additional="hover:font-bold"
                  />
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="pt-2 px-2 space-x-2 w-full flex flex-row items-stretch space-around">
          {props.usersummary}
        </div>
        <div className="md:mx-4 my-4">
          {!schoolDashboardUsers.school_dashboard_users_count.length > 0 ? (
            <div className="text-center space-y-2">
              <SectionTitle
                name="Choose what to manage below"
                color="blue"
                alignment="center"
              />
              <CardTitle name="You do not have any users now" />
              <div className="flex flex-wrap justify-center items-center space-x-2">
                <Button
                  name="Import"
                  outline="true"
                  color="blue"
                  clicked={() => setShowImportModal(true)}
                />
                <Button
                  name="Add new"
                  outline="true"
                  color="blue"
                  clicked={() => setShowNewUserModal(true)}
                />
                <Button name="Existing account" outline="true" color="blue" />
              </div>
            </div>
          ) : (
            <div className="md:mx-4">
              <CardTitle name="Users" />
              <div className="w-full flex flex-wrap">
                <div className="w-full h-full relative overflow-x-auto sm:rounded-lg">
                  {props.userstable}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageContent>
  );
};

export default Layout;
