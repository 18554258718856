import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
import MatchingTypeAutoQuestionItem from './MatchingTypeAutoQuestionItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from 'react-device-detect';
import { useDrop } from 'react-dnd';
import MatchingTypeAutoQuestionDropTargetItem from './MatchingTypeAutoQuestionDropTargetItem';
const MatchingTypeAutoQuestionDropTarget = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false);
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "autoDragAndDrop",
        item: { id: props.name },
        drop: (item) => dropSelectedWord(item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));
    useEffect(() => {

    }, []);

    const dropSelectedWord = (item) => {
        props.callMatchingTypeAuto(item.id, props.currentQuestionNumber, props.questionId, props.i, props.metaClean);
        props.reloadAfterDone(item);
        setReload(!reload);
    }

    const geTMappedWords = () => {
        return props.matchingTypeAuto.map((item, i) => {
            if (parseInt(item.questionId) === parseInt(props.questionId) && parseInt(item.question_number) === parseInt(props.currentQuestionNumber) && parseInt(item.column) === parseInt(props.i)) {

                return <MatchingTypeAutoQuestionDropTargetItem metaClean={props.metaClean} callMatchingTypeAuto={props.callMatchingTypeAuto} reloadAfterDone={props.reloadAfterDone} i={i} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} key={i} data={item.newEntry} />
            }
        });
    }
    return (
        <div ref={drop} className='h-40 p-1 border-2 border-orange-600 overflow-auto'>{geTMappedWords()}</div>
    );

};
export default MatchingTypeAutoQuestionDropTarget;
