import axios from "../../axios-base";
import { getCachedData } from "../../shared/caching";


export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED";

export function student_subjects_by_academic_units_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_INIT
  };
}
export function student_subjects_by_academic_units_success(data) {


  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_SUCCESS,
    payload: data
  };
}
export function student_subjects_by_academic_units_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECTS_BY_ACADEMIC_UNITS_FAILED,
    payload: error
  };
}

export function student_subjects_by_academic_units_start(data) {


  return (dispatch) => {
    dispatch(student_subjects_by_academic_units_init());
    getCachedData('cbcsubject-cache-subjects', `/opanda/summarynotes/cbcsubject`, 'POST', 10800,
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      }, {
      academic_level: data.academic_level,
      subject: data.subject,
      user_id: data.user_id,
      subject: data.subject
    })
      .then((response) => {

        dispatch(student_subjects_by_academic_units_success(response.results));
      })
      .catch((err) => {
        dispatch(student_subjects_by_academic_units_fail(err.message));
      });
  };
}