import Layout from '../../../components/common/Layout';
import SummaryNote from '../../../components/pages/student/summarynotes/SummaryNote';
import SummaryNotes from '../../../components/pages/student/summarynotes/SummaryNotes';

const SummaryNoteContainer = () => {
  return (
    <Layout>
      <SummaryNote />
    </Layout>
  );
};

export default SummaryNoteContainer;
