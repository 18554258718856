import { useEffect, useState } from 'react';
import { FaFlask } from 'react-icons/fa';
import {
  AiFillCloseSquare,
  AiOutlineLeftCircle,
  AiOutlineRightCircle,
} from 'react-icons/ai';
import { MdEditNote, MdMovie } from 'react-icons/md';
import VideosAndSimulations from '../../../common/features/VideosAndSimulations';
import PageContent from '../pageContent';
import { CardBody, FeatureTitle, SmallText } from '../../../elements/text';
import { Button, GoBackButton } from '../../../elements/button';
import Input from '../../../elements/input';
import { useDispatch, useSelector } from 'react-redux';
import { addPageToCourse, setCoursePageInit, setCurrentCoursePage } from '../../../../store/actions/features/summarynotes';
import RichTextEditor from '../../../elements/RichTextEditor';
import * as actions from "../../../../store/actions/"
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Page from './Page';
import { getCachedData_mine } from '../../../../shared/caching';
import Spinner from '../../../elements/spinner';
import VideoPreviewComponent from './VideoPreviewComponent';
import DocumentPreviewComponent from './DocumentSPreviewComponent';
import SimulationPreviewComponent from './SimulationPreviewComponent';
const NewSummaryNote = () => {
  const [showvideos, setShowvideos] = useState(false);
  const [vidSimDoc, setVidSimDoc] = useState('Videos');
  const [subChapterName, setSubChapterName] = useState('');
  const [subChapterContent, setSubChapterContent] = useState('');
  const [published, setPublished] = useState('');
  const courses_params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const user_id = useSelector((state) => state?.auth?.user.id);
  const token = useSelector((state) => state?.auth?.token);

  const [counter, setCounter] = useState(1);
  const [pagedata, setpagedata] = useState("");
  // Set loader
  const [loader, setLoader] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [coursePages, setCoursePages] = useState([]);
  const [classId, setClassId] = useState(0);
  const [isCourseAdditionalResourcesModalVisible, setCourseAdditionalResourcesModalVisible] = useState(false);
  const [courseAdditionalResourcesModalContent, setCourseAdditionalResourcesModalContent] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(
    (state) => state.auth
  );
  const chosenUnit = useSelector(
    (state) => state.features.summarynotes.chosenUnit
  );
  const summarynotes = useSelector(
    (state) => state.features.summarynotes
  );

  const pages = useSelector(
    (state) => state.features.summarynotes.pages
  );
  const chosenSubject = useSelector(
    (state) => state.features.summarynotes.chosenSubject
  );

  const currentPage = useSelector(
    (state) => state.features.summarynotes.currentPage
  );

  const [chapterName, setChapterName] = useState(chosenUnit !== null ? chosenUnit : "");

  const coursestructure = {
    subject: chosenSubject,
    topicarea: subChapterName,
    level: auth.academic_level,
    subtopicarea: subChapterName,
    units: chapterName
  };

  const addingCoursePage = () => {
    if (Object.keys(subChapterContent).length === 0) {
      alert(`don't submit an empty page ${counter}`);
    } else {
      setCounter(counter + 1);
      setSubChapterContent("");
      dispatch(setCurrentCoursePage({}));
      dispatch(addPageToCourse(subChapterContent));
    }
  };

  const submitCourse = () => {
    if (pages.length === 0) {
      alert(`The course has no any page`);
    } else {
      if (coursestructure.subject.length === 0) {
        alert(`Subject is Required`);
      } else if (coursestructure.units.lenght === 0) {
        alert(`Unit/Chapter is Required`);
      } else {
        const course = {
          pages, userId: auth.userId, coursestructure, creatorNames: auth.displayName
        }
        // dispatch(actions.createSummaryNotes(course, auth.token));
        dispatch(setCoursePageInit())
      }
    }
  }

  useEffect(() => {
    if (!chapterName) {
      history.push(`/teacher/features/summarynotes/0`);
    }
  })
  const getCoursePagesData = (coursePages) => {
    const pages = [];
    for (let i = 0; i < coursePages.length; i++) {
      pages.push(coursePages[i].course_pages_data);
    }
    return pages;
  };

  useEffect(() => {
    if (!chapterName) {
      history.push(`/teacher/features/summarynotes/0`);
    } else {
      //-- Load summary note and it's pages
      setLoader(true)
      getCachedData_mine('cbcsubject-cache-subjects', `/opanda/summarynotes/cbcsubject_getmycourse`, 'POST', 10800,
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }, {
        academic_level: auth.academic_level,
        subject: chosenSubject,
        user_id: user_id,
        subject: chosenSubject,
        unitname: chosenUnit
      })
        .then((response) => {
          setLoader(false);
          const { results } = response;
          const summaryNote = results[0];
          const summaryNotePages = results[2];
          setCourseName(summaryNote[0].name);
          setCoursePages([...getCoursePagesData(summaryNotePages)]);
          setPublished(summaryNote[0].accessibiliy);
          setClassId(summaryNote[0].id);
        })
        .catch((err) => {
          console.log(err);
        });

    }
  }, []);

  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  // A component modal to add videos url, images and simulations
  const closePopUpView = () => {
    alert("Simulation(s) added successfully");
    setCourseAdditionalResourcesModalVisible(false);

  }

  const closePopUpViewDocuments = () => {
    alert("Document(s) added successfully");
    setCourseAdditionalResourcesModalVisible(false);

  }

  const closePopUpViewVideos = () => {
    alert("Video(s) added successfully");
    setCourseAdditionalResourcesModalVisible(false);

  }
  const AddVideosAndSimulations = () => {
    return (
      <div className="fixed inset-0 overflow-y-auto z-10" style={{ top: 80, width: "70%", marginLeft: "15%" }}>
        <div
          className="flex items-center justify-center min-h-screen-sec pt-4 px-4 pb-20 text-center sm:block sm:p-0 backdrop"
          onClick={(e) => {
            if (e.target.classList.contains('backdrop')) {
              setCourseAdditionalResourcesModalVisible(false);
              e.stopPropagation();
            }
          }}
        >
          <div>
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen-sec"></span>&#8203;
            <div
              style={{ width: '80%' }}
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h4 className='text-blue'>{"Link " + courseAdditionalResourcesModalContent + " to this content"}</h4>
                {(vidSimDoc === "Videos") ? <VideoPreviewComponent closePopUpViewVideos={closePopUpViewVideos} classId={classId} subject={chosenSubject} key={10} /> : (vidSimDoc === "Documents") ? <DocumentPreviewComponent closePopUpViewDocuments={closePopUpViewDocuments} classId={classId} subject={chosenSubject} key={11} /> : <SimulationPreviewComponent closePopUpView={closePopUpView} classId={classId} subject={chosenSubject} key={11} />}
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <Button
                  name="Close"
                  outline="true"
                  color="red"
                  clicked={() => {
                    setCourseAdditionalResourcesModalVisible(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
  // Video component to add videos with url, preview and delete and a thumbnail(Upload, preview and delete)

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full min-h-screen-sec`}>
        {(isCourseAdditionalResourcesModalVisible) ? <AddVideosAndSimulations classId={classId} key={45} /> : ""}
        <div
          className={`p-3 bg-white md:h-full max-h-myscreen-sec md:max-h-screen-sec col-span-11 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <FeatureTitle name="Summary notes" />
          <button onClick={() => {
            if (window.confirm("Are you sure you want to leave this page?")) {
              history.push(`/teacher/features/summarynotes/0?subject=${summarynotes.chosenSubject}&academic_level=${params.academic_level}`)
            } else {
              history.push(
                `/teacher/features/summarynotes/new?subject=${summarynotes.chosenSubject}&academic_level=${params.academic_level}`
              )
            }
          }}><GoBackButton
              link=""
              action="Back"
              location="Units List"
            /></button>
          {(!loader) ? <div style={{ opacity: 1, pointerEvents: 'none' }} className="flex justify-between items-center">
            <div className='w-80%'>
              <Input
                label="Unit name"
                elementType="input"
                disabled={true}
                elementConfig={{
                  type: 'text',
                  placeholder: 'Enter Unit name',
                }}
                value={chapterName}
                changed={setChapterName}
              />
            </div>
            {/* <Button
              name="Remove chapter"
              isSquare
              outline="false"
              color="red"
              clicked={() => { }}
            /> */}
          </div> : <Spinner size="32" color="blue" />}
          {/* <div className="p-5">
            <Input
              label="Sub-chapter"
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Enter sub-chapter name',
              }}
              value={subChapterName}
              changed={setSubChapterName}
            />
            <RichTextEditor
              value={subChapterContent}
              onChange={(text) => setSubChapterContent(text)}
              placeholder="Add content"
            />
            <div className="flex justify-end py-2">
              <Button
                name="Add sub-chapter"
                isSquare
                outline="false"
                color="blue"
                clicked={() => { }}
              />
            </div>
          </div> */}
          {(!loader) ? <div className="flex space-x-5 mt-10">
            {/* {pages.map((page, index) => (
              <Button key={index}
                name={index + 1}
                outline="false"
                color="blue"
                isSquare
                clicked={() => {
                  dispatch(setCurrentCoursePage(subChapterContent))
                }}
              />
            ))} */}
            <div className=" flex flex-row  w-100%">
              <div className=" flex flex-wrap">
                <Page key={getRandomNumber(1, 100)} classId={classId} published={published} courseName={courseName} coursePages={coursePages} summarynotes={summarynotes} params={params} />
              </div>
              <div className="p-1 my-5 flex justify-center items-right border-t border-gray-500 pl-20">
                <div className="flex flex-col items-center">
                  <CardBody name="Add resources" color="blue" />
                  <div
                    className="flex flex-col items-center cursor-pointer"
                    onClick={() => {
                      if (parseInt(classId) === 0) {
                        alert("Please save the course first");
                        return;
                      }
                      setVidSimDoc('Videos');
                      setShowvideos(!showvideos);
                      setCourseAdditionalResourcesModalVisible(true)
                      setCourseAdditionalResourcesModalContent("Videos");
                    }}
                  >
                    <MdMovie size={36} color="#183F71" />
                    <SmallText name="video" color="blue" />
                  </div>
                  <div
                    className="flex flex-col items-center cursor-pointer"
                    onClick={() => {
                      if (parseInt(classId) === 0) {
                        alert("Please save the course first");
                        return;
                      }
                      setVidSimDoc('Documents');
                      setShowvideos(!showvideos);
                      setCourseAdditionalResourcesModalVisible(true)
                      setCourseAdditionalResourcesModalContent("Documents");
                    }}
                  >
                    <MdEditNote size={36} color="#183F71" />
                    <SmallText name="documents" color="blue" />
                  </div>
                  <div
                    className="flex flex-col items-center cursor-pointer"
                    onClick={() => {
                      if (parseInt(classId) === 0) {
                        alert("Please save the course first");
                        return;
                      }
                      setVidSimDoc('Simulations');
                      setShowvideos(!showvideos);
                      setCourseAdditionalResourcesModalVisible(true)
                      setCourseAdditionalResourcesModalContent("Simulations");
                    }}
                  >
                    <FaFlask size={36} color="#183F71" />
                    <SmallText name="simulations" color="blue" />
                  </div>
                </div>
              </div>
            </div>


            {/* <Button
              name="Add page/chapter"
              outline="false"
              color="blue"
              isSquare
              clicked={addingCoursePage}
            />
            <Button
              name="Submit Course"
              outline="false"
              color="green"
              isSquare
              clicked={submitCourse}
            /> */}
          </div> : <Spinner size="32" color="blue" />}

        </div>

      </div>
    </PageContent>
  );
};

export default NewSummaryNote;
