import SideDrawer from "../../elements/sideDrawer";
import SideBar from "./sidebar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeStudentDashboardMobileVisibility } from "../../../store/actions/sidebaractiontypes";

const PageContent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const studentDashboardMobileSidebar = useSelector(
    (state) => state.reducer.studentDashboardMobileSidebar
  );
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );
  const isAuth = useSelector((state) => state.auth.token !== null);
  if (!isAuth) {
    //--Authentification--paused to work on the dashboard
    history.replace("/");
  }
  return (
    <div className="bg-white-blue md:flex w-full h-full min-h-full">
      <SideDrawer
        show={studentDashboardMobileSidebar === "block"}
        closed={() => {
          dispatch(changeStudentDashboardMobileVisibility("hidden"));
        }}
      >
        <SideBar />
      </SideDrawer>
      <div
        className={`h-full hidden md:block ${
          isSidebarCollapsed ? "w-10%" : "w-20%"
        }`}
      >
        <SideBar />
      </div>
      <div
        className={`h-full w-full overflow-hidden ${
          isSidebarCollapsed ? "md:w-90%" : "md:w-80%"
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default PageContent;
