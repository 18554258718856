export const SET_COMMENT = 'SET_COMMENT';
export const SET_QUESTION = 'SET_QUESTION';
export const CHANGE_COMMENT_MODAL_STATUS = 'CHANGE_COMMENT_MODAL_STATUS';
export const CHANGE_ASK_QUESTION_MODAL_STATUS =
  'CHANGE_ASK_QUESTION_MODAL_STATUS';

export const setComment = (comment) => {
  return {
    type: SET_COMMENT,
    payload: comment,
  };
};

export const setQuestion = (question) => {
  return {
    type: SET_QUESTION,
    payload: question,
  };
};

export const changeCommentModalStatus = (status) => {
  return {
    type: CHANGE_COMMENT_MODAL_STATUS,
    payload: status,
  };
};

export const changeAskQuestionModalStatus = (status) => {
  return {
    type: CHANGE_ASK_QUESTION_MODAL_STATUS,
    payload: status,
  };
};
