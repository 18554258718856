import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useEffect } from 'react';
const DragAndDropItem = (props) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "anwers",
    item: { id: props.name },
    drop: (item) => dropSelectedWord(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const [picked, setPicked] = useState("");
  useEffect(() => {
    const newArr = props.dragAndDrop;
    let dataIn = "******************";
    for (var i = 0; i < newArr.length; i++) {
      if (parseInt(newArr[i]?.question_number) === parseInt(props?.currentQuestionNumber) && parseInt(newArr[i]?.questionId) === parseInt(props.questionId) && parseInt(newArr[i]?.spaceNber) === parseInt(props.spaceNber)) {
        dataIn = newArr[i].newEntry;
      }
    }
    setPicked(dataIn);

  }, []);


  const dropSelectedWord = (item) => {
    props.callDragAndDrop(item.id, props.currentQuestionNumber, props.questionId, props.spaceNber);
    setPicked(item.id);
  };

  return (
    <span ref={drop} className={'bg-blue text-center px-1 py-1 text-white'}>{(picked === "") ? "******************" : picked}</span>
  );
};

export default DragAndDropItem;
