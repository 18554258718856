export const saveToLocalStorage = (key, value) => {
    // Convert the value to a JSON string for storage
    const valueString = (value);
    // Save the JSON string to local storage with the specified key
    localStorage.setItem(key, valueString);
    return "";
}

export const getFromLocalStorage = (key) => {
    // Retrieve the JSON string from local storage
    const valueString = localStorage.getItem(key);
    // Parse the JSON string back into a JavaScript object
    return valueString ? (valueString) : null;
}


