import PageContent from "../pageContent";
//---Action on proposed features--
const Layout = (props) => {
  //---Preload suggested features on the platform--
  return (
    <PageContent>
      <div className="w-full h-full flex flex-col flex-col-reverse md:flex-row justify-between p-3 md:space-x-4 max-h-myscreen overflow-auto scrollbar-thin">
        <div className="md:w-70%">
          <div>
            <div className="flex justify-center md:justify-start items-center space-x-4">
              {props.greetings}
            </div>
            {props.days}
            {props.featureproposed}
          </div>
          <div className="w-full">{props.recommendation}</div>
        </div>
        {props.notification}
      </div>
    </PageContent>
  );
};

export default Layout;
