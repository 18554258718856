import React from 'react'
import Layout from '../../../components/common/Layout';
import { MainBooksMarket } from '../../../components/pages/parent/marketplace';


const MainBooksMarketContainer = () => {
  return (
  <Layout>
    <MainBooksMarket />
  </Layout>
  )
}

export default MainBooksMarketContainer