import DataTable from "react-data-table-component";
import { useMemo, useState } from "react";
import { Button, ButtonWithIcon } from "../button";
import axios from "../../../axios-base";
import UserRegistrationStatus from "./userRegistrationStatus";
import UserRegistrationStatusForPrint from "./userRegistrationStatusForPrint";
import { renderToString } from 'react-dom/server';
import { getFromLocalStorage } from "../../../utils/localstorage";
import { useSelector } from "react-redux";
import TooltipText from "./Tooltiptext/TooltipText";

const Datatable = (props) => {
  const { columns, data } = props;
  const [filterText, setFilterText] = useState("");
  const userAuth = useSelector((state) => state.auth);

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = `export--${new Date().getDay()}-${new Date().getMonth()}-${new Date().getFullYear()}--${new Date().getSeconds()}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const getTokenRegistrationStatusForPrint = (code) => {
    return (<UserRegistrationStatusForPrint key={code} token={code} />);
  }
  let dataToPrint = data.map((item) => {
    const data = { ...item };
    data.status = item.status = "...";
    data.status = getTokenRegistrationStatusForPrint(item.otp);
    return data;
  });
  const findMyStatus = async (otp) => {
    try {
      const response = await axios.post(
        `/opanda/manage-users/registration-status`,
        { otp },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userAuth.token}`
          }
        }
      );

      if (response.data.message === "OTP used") {
        return response.data.message;
      } else {
        return response.data.message;
      }
    } catch (err) {
      console.error("Error fetching status:", err);
      return "Error: OTP status request failed";
    }
  };

  const actionsMemo = useMemo(
    () => (
      <SubHeaderComponent
        onExport={async () => {
          const dataWithStatus = await Promise.all(dataToPrint.map(async (item) => {
            const data = { ...item };
            data.status = await findMyStatus(item.otp);
            return data;
          }));
          downloadCSV(dataWithStatus);
        }}
        filterText={filterText}
        onFilter={(e) => setFilterText(e.target.value)}
      />

    ),
    [data, downloadCSV, filterText]
  );

  const filteredItems = data.filter(
    (item) =>
      (item.names &&
        item.names.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.class &&
        item.class.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.usertype &&
        item.usertype.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.status &&
        item.status.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.otp && item.otp.toLowerCase().includes(filterText.toLowerCase()))
  );

  const getTokenRegistrationStatus = (code) => {
    return <UserRegistrationStatus key={code} token={code} />
  }






  const highLightUserStatus = (users) => {

    return users.map((user) => {
      return { ...user, names: <TooltipText text={user.names} />, status: <span className="text-red-500">{getTokenRegistrationStatus(user.otp)}</span> };
    });

  }

  return (
    <>
      <DataTable
        columns={columns}
        data={highLightUserStatus(filteredItems)}
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
        pointerOnHover
        subHeader
        subHeaderComponent={actionsMemo}
      />
    </>
  );
};

const SubHeaderComponent = ({ onExport, onFilter, filterText }) => (
  <div className="w-full mt-2 flex flex-row justify-between">
    <input
      type="text"
      placeholder="Filter"
      value={filterText}
      onChange={onFilter}
    />

    <Button
      outline="true"
      color="blue"
      isSmall="true"
      name="Print account"
      clicked={(e) => onExport(e.target.value)}
    />
  </div>
);

export default Datatable;
