import { updateObject } from "../../shared/utility";
import {
  SCHOOL_DASHBOARD_CLASS_COUNT_INIT,
  SCHOOL_DASHBOARD_CLASS_COUNT_SUCCESS,
  SCHOOL_DASHBOARD_CLASS_COUNT_FAILED,
  SCHOOL_DASHBOARD_CLASS_LIST_INIT,
  SCHOOL_DASHBOARD_CLASS_LIST_SUCCESS,
  SCHOOL_DASHBOARD_CLASS_LIST_FAILED,
  SCHOOL_DASHBOARD_CLASS_STUDENTS_INIT,
  SCHOOL_DASHBOARD_CLASS_STUDENTS_SUCCESS,
  SCHOOL_DASHBOARD_CLASS_STUDENTS_FAILED,
  SCHOOL_DASHBOARD_CLASS_ADMIN_INIT,
  SCHOOL_DASHBOARD_CLASS_ADMIN_SUCCESS,
  SCHOOL_DASHBOARD_CLASS_ADMIN_FAILED
} from "../actions/school.dashboard.classes";
//--
const initialReducer = {
  school_dashboard_class_count: [
    { user_type: "student", number: 0 },
    { user_type: "teacher", number: 0 },
    { user_type: "goal", number: 0 },
  ],
  school_dashboard_class_count_loading: true,
  school_dashboard_class_list: [],
  school_dashboard_class_list_loading: true,
  school_dashboard_class_students: [],
  school_dashboard_class_students_loading: true,
  message: "",
};

function school_dashboard_class_count_init(state, action) {
  return updateObject(state, {
    school_dashboard_class_count_loading: true,
  });
}
function school_dashboard_class_count_success(state, action) {
  return updateObject(state, {
    school_dashboard_class_count: action.payload,
    school_dashboard_class_count_loading: false,
    message: "success",
  });
}
function school_dashboard_class_count_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_class_count_loading: false,
  });
}

function school_dashboard_class_list_init(state, action) {
  return updateObject(state, {
    school_dashboard_class_list_loading: true,
  });
}
function school_dashboard_class_list_success(state, action) {
  return updateObject(state, {
    school_dashboard_class_list: action.payload,
    school_dashboard_class_list_loading: false,
    message: "success",
  });
}
function school_dashboard_class_list_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_class_list_loading: false,
  });
}

function school_dashboard_class_students_init(state, action) {
  return updateObject(state, {
    school_dashboard_class_students_loading: true,
  });
}
function school_dashboard_class_students_success(state, action) {
  return updateObject(state, {
    school_dashboard_class_students: action.payload,
    school_dashboard_class_students_loading: false,
    message: "success",
  });
}
function school_dashboard_class_students_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_class_students_loading: false,
  });
}


function school_dashboard_class_admin_init(state, action) {
  return updateObject(state, {
    school_dashboard_class_admin_loading: true,
  });
}
function school_dashboard_class_admin_success(state, action) {
  return updateObject(state, {
    school_dashboard_class_admin: action.payload,
    school_dashboard_class_admin_loading: false,
    message: "success",
  });
}
function school_dashboard_class_admin_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_class_admin_loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SCHOOL_DASHBOARD_CLASS_COUNT_INIT:
      return school_dashboard_class_count_init(state, action);
    case SCHOOL_DASHBOARD_CLASS_COUNT_SUCCESS:
      return school_dashboard_class_count_success(state, action);
    case SCHOOL_DASHBOARD_CLASS_COUNT_FAILED:
      return school_dashboard_class_count_failed(state, action);

    case SCHOOL_DASHBOARD_CLASS_LIST_INIT:
      return school_dashboard_class_list_init(state, action);
    case SCHOOL_DASHBOARD_CLASS_LIST_SUCCESS:
      return school_dashboard_class_list_success(state, action);
    case SCHOOL_DASHBOARD_CLASS_LIST_FAILED:
      return school_dashboard_class_list_failed(state, action);

      case SCHOOL_DASHBOARD_CLASS_STUDENTS_INIT:
        return school_dashboard_class_students_init(state, action);
      case SCHOOL_DASHBOARD_CLASS_STUDENTS_SUCCESS:
        return school_dashboard_class_students_success(state, action);
      case SCHOOL_DASHBOARD_CLASS_STUDENTS_FAILED:
        return school_dashboard_class_students_failed(state, action);  

        case SCHOOL_DASHBOARD_CLASS_ADMIN_INIT:
          return school_dashboard_class_admin_init(state, action);
        case SCHOOL_DASHBOARD_CLASS_ADMIN_SUCCESS:
          return school_dashboard_class_admin_success(state, action);
        case SCHOOL_DASHBOARD_CLASS_ADMIN_FAILED:
          return school_dashboard_class_admin_failed(state, action);      

    default:
      return state;
  }
};
export default reducer;
