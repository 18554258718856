import constants from "../../../../constants/constants";

export const getDragAndDropQuestionData = (data) => {
  const selectedwords = data.selectedWords;
  const questionmarks = data.marks ?? 2;
  const questionduration = data.duration ?? 2;
  let questiondata = data.question;
  const wordsjoiner = "~----~";
  let questiontobesaved = {};
  if (questiondata) {
    questiontobesaved = {
      panda_drag_and_drop_2020_03_access_code: "CODE",
      panda_drag_and_drop_2020_03_creator: 0,
      panda_drag_and_drop_2020_03_regdate: "2021-01-20T11:22:54.000Z",
      panda_drag_and_drop_2020_03_question_data_answers:
        selectedwords.join(wordsjoiner),
      panda_drag_and_drop_2020_03_question_data: questiondata,
      panda_drag_and_drop_2020_03_duration_of_test: 20,
      panda_drag_and_drop_2020_03_test_difficulty: "DIFF",
      panda_drag_and_drop_2020_03_unit: "UNit 4",
      panda_drag_and_drop_2020_03_marks: questionmarks,
      panda_drag_and_drop_2020_03_duration: questionduration,
      panda_drag_and_drop_2020_03_test_id: "47",
      questiontype: constants.drag_and_drop,
    };
  }

  return questiontobesaved;
};

export const getCompleteSentenceQuestionData = (data) => {
  const selectedwords = data.selectedWords;
  const questionmarks = data.marks ?? 2;
  const questionduration = data.duration ?? 2;
  const wordsjoiner = "~----~";
  let questiondata = data.question;
  let questiontobesaved = {};
  if (questiondata) {
    questiontobesaved = {
      panda_fill_in_2020_03_access_code: "CODE",
      panda_fill_in_2020_03_creator: 0,
      panda_fill_in_2020_03_regdate: "2021-01-20T11:22:54.000Z",
      panda_fill_in_2020_03_question_data_answers:
        selectedwords.join(wordsjoiner),
      panda_fill_in_2020_03_question_data: questiondata,
      panda_fill_in_2020_03_duration_of_test: 0,
      panda_fill_in_2020_03_test_difficulty: "DIF",
      panda_fill_in_2020_03_unit: "UNit 3",
      panda_fill_in_2020_03_marks: questionmarks,
      panda_fill_in_2020_03_duration: questionduration,
      panda_fill_in_2020_03_test_id: "47",
      questiontype: constants.complete_sentence,
    };
  }
  console.log(questiontobesaved);
  return questiontobesaved;
};

export const getOpenEndedQuestionData = (data) => {
  const questionmarks = data.marks ?? 2;
  const questionduration = data.duration ?? 2;
  let questiondata = data.question;
  let explanation = data.explanation;
  let questiontobesaved = {};
  if (questiondata) {
    questiontobesaved = {
      panda_open_ended_2020_03_marked: "Marked",
      panda_open_ended_2020_03_regdate: "Date",
      panda_open_ended_2020_03_test_id: "Id",
      panda_open_ended_2020_03_teacher_answer: explanation,
      panda_open_ended_2020_03_time_in_minutes: questionduration,
      panda_open_ended_2020_03_marks: questionmarks,
      panda_open_ended_2020_03_question_data: questiondata,
      questiontype: constants.open_ended,
    };
  }
  return questiontobesaved;
};

export const getMultipleChoiceData = (data) => {
  const questiondata = data.question;
  const explanation = data.explanation;
  const answerslist = data.options;
  const questionmarks = data.marks;
  const questionduration = data.duration;
  return {
    question_data: questiondata,
    explanation,
    answerslist,
    creator_id: 2,
    diffculty: 1,
    finalized: 1,
    creator_names: "Jimmy",
    visibility: 1,
    regdate: "regdate",
    auto_answering_technique: 4,
    uploader_names: "Jimmy",
    creator: 33,
    time_assigned_in_seconds: questionduration,
    total_marks: questionmarks,
    academic_units: "Blessed",
    academic_level: 4,
    subject: "religion",
    questiontype: constants.multiple_choice,
  };
};

export const getSelectedOption = (id) => {
  let checked = document.getElementById(id);
  let result;
  if (checked.checked == true) {
    result = constants.ONE; //true
  } else {
    result = constants.ZERO; //false, when not the answer
  }
  return result;
};

export default {
  getDragAndDropQuestionData,
  getCompleteSentenceQuestionData,
  getOpenEndedQuestionData,
  getSelectedOption,
  getMultipleChoiceData,
};
