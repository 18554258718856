// PageNavigation.js
import React from 'react';
import { Button } from '../../../elements/button';
import { CardSubText } from '../../../elements/text';

const PageNavigation = ({ onNext, onPrevious, deleteCurrentPage, addNewPage, pageCount, currentPage }) => {
    return (
        <div className="flex md:flex-row sm:flex-col items-center justify-evenly">
            <Button
                name="Prev"
                outline="false"
                color="blue"
                isSmall={true}
                isSquare
                clicked={onPrevious}
            />
            <CardSubText name={`${currentPage + 1} of ${pageCount}`} color="black" />
            <div className="flex flex-wrap justify-evenly pb-4 p-3">
                <Button
                    name={`Delete Page (${currentPage+1})`}
                    outline="false"
                    color="red"
                    isSmall={true}
                    clicked={async () => {
                        deleteCurrentPage(currentPage)
                    }}
                />

                {/* <Button
                    name="New"
                    outline="false"
                    color="blue"
                    isSmall={true}
                    clicked={async () => {
                        addNewPage()
                    }}
                /> */}
            </div>
            <Button
                name="Next"
                outline="false"
                color="blue"
                isSmall={true}
                isSquare
                clicked={onNext}
            />
        </div>

    );
};

export default PageNavigation;
