import { SectionTitle } from '../../../elements/text';
import { useHistory } from 'react-router-dom';
import { FeatureItemCard } from '../../../elements/card';
import NotFound from '../../../common/features/NotFound';

const ChallengesGroupItem = (props) => {
  const history = useHistory();
  const { challenges, type } = props;

  return (
    <>
      <SectionTitle name={type} color="blue" />
      {challenges.length === 0 ? (
        <NotFound feature="challenge" hideImage />
      ) : (
        <div className="md:grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          {challenges.map((challenge, index) => (
            <FeatureItemCard
              key={index}
              {...challenge}
              feature="challenge"
              clicked={() =>
                history.push(`/student/features/challenges/${challenge.challengeid}?attempt=false&challengetype=${challenge.challengetype}&unitname=${challenge.unitname}`)
              }
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ChallengesGroupItem;
