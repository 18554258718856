import { updateObject } from "../../../shared/utility";
import {
  GET_AUTOMATED_TEST_RESULT_INIT,
  GET_AUTOMATED_TEST_RESULT_SUCCESS,
  GET_AUTOMATED_TEST_RESULT_FAIL,
} from "../../actions/features/test.progress.result";

const initialReducer = {
  autotestresult: 
    {
      id: 22,
      subject: "Biology",
      unit: "Unit2: Human skeleton",
      description:
        "Cillum pariatur cupidatat cupidatat culpa laboris aute aliqua.",
      marks: 70,
      otherwork: [{
        id:23,
        feature: "test",
        title: "Test title",
        level: 6,
        unit: "Unit 1",
        imgsrc: "/images/preview/bio.svg",
        type: "Test",
        due: "Today",
        isMyTest: false,
        subject: "Biology",
      }],
    },
  
  loading: true,
  message: "",
};

const getAutomatedTestResultInit = (state, action) => {
  return updateObject(state, {
    autotestresult: {},
    loading: true,
    message: "",
  });
};

const getAutomatedTestResultSuccess = (state, action) => {
  return updateObject(state, {
    autotestresult: action.payload,
    loading: false,
    message: "success",
  });
};

const getAutomatedTestResultFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_AUTOMATED_TEST_RESULT_INIT:
      return getAutomatedTestResultInit(state, action);
    case GET_AUTOMATED_TEST_RESULT_SUCCESS:
      return getAutomatedTestResultSuccess(state, action);
    case GET_AUTOMATED_TEST_RESULT_FAIL:
      return getAutomatedTestResultFail(state, action);
    default:
      return state;
  }
};

export default reducer;
