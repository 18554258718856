import axios from "../../axios-base";
import ValidatingToken from "../../functions/validatingtoken";

export const CHANGE_PAYMENT_MODAL_STATE = "CHANGE_PAYMENT_MODAL_STATE";
export const CHANGE_PAYMENT_DONE_MODAL_STATE =
  "CHANGE_PAYMENT_DONE_MODAL_STATE";
export const SET_CHOSEN_PACKAGE = "SET_CHOSEN_PACKAGE";
export const CHANGE_CHOSEN_PROGRAM = "CHANGE_CHOSEN_PROGRAM";
export const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS";
export const MAKE_PAYMENT_START = "MAKE_PAYMENT_START";
export const MAKE_PAYMENT_FAIL = "MAKE_PAYMENT_FAIL";
export const PAYMENT_WAITING_RESPONSE = "PAYMENT_WAITING_RESPONSE";

export const GET_EXTRA_PACKAGES_SUCCESS = "GET_EXTRA_PACKAGES_SUCCESS";
export const GET_EXTRA_PACKAGES_START = "GET_EXTRA_PACKAGES_START";
export const GET_EXTRA_PACKAGES_FAIL = "GET_EXTRA_PACKAGES_FAIL";
export const CHANGE_PACKAGE_MODAL_STATE = "CHANGE_PACKAGE_MODAL_STATE";
export const CHANGE_PACKAGE_DONE_MODAL_STATE = "CHANGE_PACKAGE_DONE_MODAL_STATE";
export const SET_CHOSEN_EXTRA_PACKAGE = "SET_CHOSEN_EXTRA_PACKAGE";

export const ACTIVATE_EXTRA_PACKAGES_SUCCESS = "ACTIVATE_EXTRA_PACKAGES_SUCCESS";
export const ACTIVATE_EXTRA_PACKAGES_START = "ACTIVATE_EXTRA_PACKAGES_START";
export const ACTIVATE_EXTRA_PACKAGES_FAIL = "ACTIVATE_EXTRA_PACKAGES_FAIL";
export const changePaymentModalState = (state) => {
  return {
    type: CHANGE_PAYMENT_MODAL_STATE,
    payload: state,
  };
};

export const changePaymentDoneModalState = (state) => {
  return {
    type: CHANGE_PAYMENT_DONE_MODAL_STATE,
    payload: state,
  };
};

export const setChosenPackage = (chosenPackage) => {
  return {
    type: SET_CHOSEN_PACKAGE,
    payload: chosenPackage,
  };
};

export const chengeChosenProgram = (chosenProgram) => {
  return {
    type: CHANGE_CHOSEN_PROGRAM,
    payload: chosenProgram,
  };
};

export const makePaymentSuccess = (payment) => {
  return {
    type: MAKE_PAYMENT_SUCCESS,
    payload: payment,
  };
};

export const makePaymentStart = () => {
  return {
    type: MAKE_PAYMENT_START,
  };
};

export const paymentWaitingResponse = () => {
  return {
    type: PAYMENT_WAITING_RESPONSE,
  };
};

export const makePaymentFail = (error) => {
  return {
    type: MAKE_PAYMENT_FAIL,
    payload: error?.message,
  };
};
export const makePayment = (paymentdata, token) => {
  ValidatingToken(token);
  if (paymentdata.isExtrapackage) {
    return (dispatch) => {
      dispatch(makePaymentStart());
      axios
        .post(`/opanda/payment-call-backs/extras/create`, paymentdata, {
          headers: { authorization: token },
        })
        .then((response) => {
          if (
            response?.data?.message.includes(
              "There are other pending payments"
            ) ||
            response?.data?.message.includes("User has other pending payments")
          ) {
            alert(
              response?.data?.message +
                "! Check pending payments on your mobile money or wait 5 mins and try again!"
            );
            dispatch(makePaymentFail(response?.data?.message));
            setTimeout(() => {
              dispatch(makePaymentFail(""));
            }, 10000);
          } else {
            alert("A payment request has been sent to your phone!");
            dispatch(makePaymentSuccess(response.data.results));
          }
        })
        .catch((err) => {
          dispatch(
            makePaymentFail(
              err?.response?.data ? err.response.data : err.response
            )
          );
          setTimeout(() => {
            dispatch(makePaymentFail(""));
          }, 10000);
        });
    };
  } else {
    return (dispatch) => {
      dispatch(makePaymentStart());
      axios
        .post(`/opanda/payment-call-backs/create`, paymentdata, {
          headers: { authorization: token },
        })
        .then((response) => {
          if (
            response?.data?.message.includes(
              "There are other pending payments"
            ) ||
            response?.data?.message.includes("User has other pending payments")
          ) {
            alert(
              response?.data?.message +
                "! Check pending payments on your mobile money or wait 5 mins and try again!"
            );
            dispatch(makePaymentFail(response?.data?.message));
            setTimeout(() => {
              dispatch(makePaymentFail(""));
            }, 10000);
          } else {
            alert("A payment request has been sent to your phone!");
            dispatch(makePaymentSuccess(response.data.results));
          }
        })
        .catch((err) => {
          dispatch(
            makePaymentFail(
              err?.response?.data ? err.response.data : err?.response
            )
          );
          setTimeout(() => {
            dispatch(makePaymentFail(""));
          }, 10000);
        });
    };
  }
};

export const getExtraPackagesSuccess = (payment) => {
  return {
    type: GET_EXTRA_PACKAGES_SUCCESS,
    payload: payment,
  };
};

export const getExtraPackagesStart = () => {
  return {
    type: GET_EXTRA_PACKAGES_START,
  };
};
export const getExtraPackagesFail = (error) => {
  return {
    type: GET_EXTRA_PACKAGES_FAIL,
    payload: error,
  };
};
export const getExtraPackages = (token) => {
  return (dispatch) => {
    dispatch(getExtraPackagesStart());
    axios
      .get(`/opanda/extra-packages`, null, {
        headers: { authorization: token },
      })
      .then((response) => {
        dispatch(getExtraPackagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getExtraPackagesFail(err.response.data));
      });
  };
};

export const changePackageModalState = (state) => {
  return {
    type: CHANGE_PACKAGE_MODAL_STATE,
    payload: state,
  };
};

export const changePackageDoneModalState = (state) => {
  return {
    type: CHANGE_PACKAGE_DONE_MODAL_STATE,
    payload: state,
  };
};

export const setChosenExtraPackage = (chosenExtraPackage) => {
  return {
    type: SET_CHOSEN_EXTRA_PACKAGE,
    payload: chosenExtraPackage,
  };
};

export const activateExtraPackagesSuccess = (payment) => {
  return {
    type: ACTIVATE_EXTRA_PACKAGES_SUCCESS,
    payload: payment,
  };
};

export const activateExtraPackagesStart = () => {
  return {
    type: ACTIVATE_EXTRA_PACKAGES_START,
  };
};
export const activateExtraPackagesFail = (error) => {
  return {
    type: ACTIVATE_EXTRA_PACKAGES_FAIL,
    payload: error.message,
  };
};
export const activateExtraPackage = (data,token) => {
  return (dispatch) => {
    dispatch(activateExtraPackagesStart());
    axios
    .post(`/opanda/extra-packages/activate`, data, {
      headers: { authorization: token },
    })
    .then((response) => {
      dispatch(activateExtraPackagesSuccess(response.data.results));
    })
    .catch((err) => {
      dispatch(activateExtraPackagesFail(err.response.data));
    });
  };
};

// const logout = () => {
//   const dispatch = useDispatch();
//   const history = useHistory();
//   dispatch(actionTypes.logout());
//   history.push("/");
//   setTimeout(() => {
//     window.location.reload();
//   }, 2000);
// };