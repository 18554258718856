import PageContent from '../../pageContent';
const Feedback = () => {
  return (
    <PageContent>
      
    </PageContent>
  );
};

export default Feedback;
