import axios from "../../axios-base";

export const SCHOOL_USERS_SEND_FEEDBACK_INIT =
  "SCHOOL_USERS_SEND_FEEDBACK_INIT";
export const SCHOOL_USERS_SEND_FEEDBACK_SUCCESS =
  "SCHOOL_USERS_SEND_FEEDBACK_SUCCESS";
export const SCHOOL_USERS_SEND_FEEDBACK_FAILED =
  "SCHOOL_USERS_SEND_FEEDBACK_FAILED";

export function school_users_send_feedback_init() {
  return {
    type: SCHOOL_USERS_SEND_FEEDBACK_INIT,
  };
}
export function school_users_send_feedback_success(data) {
  return {
    type: SCHOOL_USERS_SEND_FEEDBACK_SUCCESS,
    payload: data,
  };
}
export function school_users_send_feedback_failed(error) {
  return {
    type: SCHOOL_USERS_SEND_FEEDBACK_FAILED,
    payload: error,
  };
}

export function school_users_send_feedback_start(data) {
  return (dispatch) => {
    dispatch(school_users_send_feedback_init());
    axios
      .post(`/opanda/feedbacks/create`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(
          school_users_send_feedback_success(response.data.response)
        );
      })
      .catch((err) => {
        dispatch(school_users_send_feedback_failed(err.message));
      });
  };
}