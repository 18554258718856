import Settings from '../../../components/pages/parent/user/settings/index';
import Layout from '../../../components/common/Layout';

const ParentSettingsContainer = () => {
  return (
    <Layout>
      <Settings />
    </Layout>
  );
};

export default ParentSettingsContainer;