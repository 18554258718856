import { useState, useEffect } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { FaLock, FaLockOpen } from "react-icons/fa";
// import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import GetStarted from "../../../common/features/GetStarted";
import PageContent from "../pageContent";
import TabSelector from "../../../elements/tab/TabSelector";
import NotFound from "../../../common/features/NotFound";
import { FeatureItemCard } from "../../../elements/card";
import { CardBody, FeatureTitle } from "../../../elements/text";
import Input from "../../../elements/input";
import { changeChosenUnitSummarynotes } from "../../../../store/actions/features/summarynotes";
import { useDispatch, useSelector } from "react-redux";
import { student_subjects_by_academic_units_start } from "../../../../store/actions/student.dashboard.curriculum.cbc";
import {
  getAllTheSubjects,
  GetUnitsFromSelectedSubjects,
  GetUnitsFromSelectedSubjectsFilterByUnit,
} from "../../../../functions/curriculumcbcsubjectsunits";
import Spinner from "../../../elements/spinner";
// import { MdEditNote, MdMovie } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

const SummaryNotes = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  //---
  const history = useHistory();
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const studentDashboardCurriculumCbc = useSelector(
    (state) => state.studentDashboardCurriculumCbc
  );

  const [searchterm, setSearchterm] = useState("");
  // const units = [
  //   {
  //     name: "Deserunt laboris",
  //     viewed: true,
  //     notes: [
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "opanda",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "community",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "community",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "community",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Adipisicing ",
  //     viewed: false,
  //     notes: [
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "opanda",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "community",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Dolore minim",
  //     viewed: false,
  //     notes: [
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "opanda",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Dolore ex ",
  //     viewed: false,
  //     notes: [
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "opanda",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Sint eiusmod fugiat.",
  //     viewed: false,
  //     notes: [
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "opanda",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Adipisicing occaecat",
  //     viewed: false,
  //     notes: [
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "opanda",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Dolore minimum",
  //     viewed: false,
  //     notes: [
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "opanda",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Dolore ex enim",
  //     viewed: false,
  //     notes: [
  //       {
  //         title: "Note Title",
  //         author: "John",
  //         type: "opanda",
  //         imgsrc: "/images/preview/bio.svg",
  //       },
  //     ],
  //   },
  // ];

  const [showUnits, setShowUnits] = useState(false);
  const [subject, setSubject] = useState("BIOLOGY");

  const [selectedTab, setSelectedTab] = useTabs(
    ["All", "O'Genius Panda", "Community"],
    "O'Genius Panda"
  );
  useEffect(() => {
    if (params.subject) {
      dispatch(
        student_subjects_by_academic_units_start({
          token: userAth.token,
          subject: params.subject,
          academic_level: userAth.academic_level,
          user_id: userAth.userId,
        })
      );
    } else {
      dispatch(
        student_subjects_by_academic_units_start({
          token: userAth.token,
          subject: "BIOLOGY",
          academic_level: userAth.academic_level,
          user_id: userAth.userId,
        })
      );
    }
    //----
    setTimeout(() => {
      document.getElementById("Features").click();
    }, 300);
  }, []);

  //-----
  const getAllSubjects = getAllTheSubjects(
    studentDashboardCurriculumCbc.cbc_subjects_units
  );
  //---
  const academic_level_data = ["one", "two", "three", "four", "five", "six"];

  const getUnitsFromSelectedSubjects = GetUnitsFromSelectedSubjects(
    studentDashboardCurriculumCbc.cbc_subjects_units
  )[0];
  const getUnitsFromSelectedSubjects_Unfiltered = GetUnitsFromSelectedSubjects(
    studentDashboardCurriculumCbc.cbc_subjects_units
  )[1];

  const tabs = getUnitsFromSelectedSubjects.map((unit) => unit.name);
  tabs.unshift("initial page");
  const chosenUnit = useSelector(
    (state) => state.features.summarynotes.chosenUnit
  );
  const [selectedUnit, setSelectedUnit] = useTabs(
    tabs,
    chosenUnit ? chosenUnit : "initial page"
  );
  const loadNewSubjectunits = (thesubject) => {
    setSubject(thesubject);
    setSelectedUnit("initial page");
    history.push(`/student/features/summarynotes?subject=${thesubject}`);
    dispatch(
      student_subjects_by_academic_units_start({
        token: userAth.token,
        subject: thesubject,
        academic_level: userAth.academic_level,
        user_id: userAth.userId,
      })
    );
  };
  ///--
  const getUnitsFromSelectedSubjectsFilterByUnit =
    GetUnitsFromSelectedSubjectsFilterByUnit(
      selectedUnit,
      GetUnitsFromSelectedSubjects(
        studentDashboardCurriculumCbc.cbc_subjects_units
      )[1]
    );

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full h-full`}>
        <div
          className={`px-4 pb-2 pb-8 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 ${
            showUnits ? "h-screen-sec" : ""
          } md:h-full col-span-3`}
        > 
          <div className="flex justify-between items-center">
            <div className="w-50%">
              {!studentDashboardCurriculumCbc.cbc_subjects_units_loading &&
              getAllSubjects.length > 0 ? (
                <Input
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: [...getAllSubjects],
                  }}
                  value={
                    studentDashboardCurriculumCbc.cbc_subjects_units[0][0]
                      .subject
                  }
                  changed={setSubject}
                  loadNewSubjectunits={loadNewSubjectunits}
                  validation={{ required: true }}
                  shouldValidate
                  error="Subject is required"
                />
              ) : studentDashboardCurriculumCbc.cbc_subjects_units_loading ? (
                <Spinner size="10" color="blue" />
              ) : (
                <div></div>
              )}
            </div>
            <CardBody
              name={`Senior ${
                academic_level_data[parseInt(userAth.academic_level) - 1]
              }`}
              color="blue"
            />
          </div>
          <div className={`${showUnits ? "" : "hidden"} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Search...",
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            <ul className="py-5 h-100">
              {!studentDashboardCurriculumCbc.cbc_subjects_units_loading &&
              getUnitsFromSelectedSubjects.length > 0 ? (
                getUnitsFromSelectedSubjects.map((unit, index) =>
                  searchterm === "" ||
                  unit.name.toLowerCase().includes(searchterm.toLowerCase()) ? (
                    <li
                      key={index}
                      className="flex items-center space-around py-2 cursor-pointer"
                      onClick={() => {
                        dispatch(changeChosenUnitSummarynotes(unit.name));
                        setSelectedUnit(unit.name);
                        setShowUnits(false);
                        //---load the units For O'Genius Panda--

                        setSelectedUnit(unit.name);
                      }}
                    >
                      <CardBody

                        size={14}
                        name={`${unit.name}`}
                        color="blue"
                        additional={
                          unit.name === selectedUnit ? "font-bold flex-1" : "flex-1"
                        }
                      />
                      {unit.viewed ? (
                        <FaLockOpen color="#183F71" className="shrink-0 w-16px h-16px" />
                      ) : (
                        <FaLock color="#183F71"  className="shrink-0 w-16px h-16px" />
                      )}
                    </li>
                  ) : (
                    <li></li>
                  )
                )
              ) : studentDashboardCurriculumCbc.cbc_subjects_units_loading ? (
                <Spinner size="10" color="blue" />
              ) : (
                <div></div>
              )}
            </ul>
          </div>
        </div>
        <div
          className={`${
            showUnits ? "hidden md:block" : ""
          }  md:relative p-3 bg-white md:h-full max-h-myscreen-sec min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
          >
          {selectedUnit === "initial page" ? (
            <TabPanel
              hidden={selectedUnit !== "initial page"}
              className="transform transition duration-500 ease-in-out"
            >
              <FeatureTitle name="Summary notes" />
              <>
                <nav className="flex justify-between md:justify-start space-x-4">
                  <TabSelector
                    isActive={selectedTab === "All"}
                    title="All"
                    onClick={() => setSelectedTab("All")}
                  />
                  <TabSelector
                    isActive={selectedTab === "O'Genius Panda"}
                    title="O'Genius Panda"
                    onClick={() => setSelectedTab("O'Genius Panda")}
                  />
                  <TabSelector
                    isActive={selectedTab === "Community"}
                    title="Community"
                    onClick={() => setSelectedTab("Community")}
                  />
                </nav>
                <TabPanel hidden={false}>
                  <GetStarted type="a unit" feature="summary notes" />
                </TabPanel>
              </>
            </TabPanel>
          ) : (
            <div></div>
          )}
          {selectedUnit !== "initial page" &&
          getUnitsFromSelectedSubjects_Unfiltered &&
          getUnitsFromSelectedSubjects_Unfiltered.length > 0 ? (
            <TabPanel className="transform transition duration-500 ease-in-out">
              <FeatureTitle name="Summary notes" />
              <>
                <nav className="flex justify-between md:justify-start space-x-4">
                  <TabSelector
                    isActive={selectedTab === "All"}
                    title="All"
                    onClick={() => setSelectedTab("All")}
                  />
                  <TabSelector
                    isActive={selectedTab === "O'Genius Panda"}
                    title="O'Genius Panda"
                    onClick={() => setSelectedTab("O'Genius Panda")}
                  />
                  <TabSelector
                    isActive={selectedTab === "Community"}
                    title="Community"
                    onClick={() => setSelectedTab("Community")}
                  />
                </nav>
                <TabPanel hidden={selectedTab !== "All"}>
                  {getUnitsFromSelectedSubjectsFilterByUnit &&
                  getUnitsFromSelectedSubjectsFilterByUnit.length === 0 ? (
                    <NotFound feature="summary note" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {getUnitsFromSelectedSubjectsFilterByUnit &&
                        getUnitsFromSelectedSubjectsFilterByUnit.map(
                          (note, index) => (
                            <FeatureItemCard
                              key={index}
                              {...note}
                              unit={note.unit}
                              unitNumber={note.id}
                              feature="summary note"
                              clicked={() => {
                                //----MArk the unit as viewed(Push to the server livevisibility)
                                setSelectedUnit(note.unit);
                                history.push(
                                  `/student/features/summarynotes/${note.id}?subject=${note.subject}&unit=${note.unit}`
                                );
                              }}
                            />
                          )
                        )}
                    </div>
                  )}
                </TabPanel>
                <TabPanel hidden={selectedTab !== "O'Genius Panda"}>
                  {getUnitsFromSelectedSubjectsFilterByUnit &&
                  getUnitsFromSelectedSubjectsFilterByUnit.filter(
                    (note) => note.type === "opanda"
                  ).length === 0 ? (
                    <NotFound feature="summary note" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {getUnitsFromSelectedSubjectsFilterByUnit &&
                        getUnitsFromSelectedSubjectsFilterByUnit
                          .filter((note) => note.type === "opanda")
                          .map((note, index) => (
                            <FeatureItemCard
                              key={index}
                              {...note}
                              unit={note.unit}
                              unitNumber={note.id}
                              feature="summary note"
                              clicked={() => {
                                //----MArk the unit as viewed(Push to the server livevisibility)
                                setSelectedUnit(note.unit);
                                history.push(
                                  `/student/features/summarynotes/${note.id}?subject=${note.subject}&unit=${note.unit}`
                                );
                              }}
                            />
                          ))}
                    </div>
                  )}
                </TabPanel>
                <TabPanel hidden={selectedTab !== "Community"}>
                  {getUnitsFromSelectedSubjectsFilterByUnit &&
                  getUnitsFromSelectedSubjectsFilterByUnit.filter(
                    (note) => note.type === "community"
                  ).length === 0 ? (
                    <NotFound feature="summary note" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {getUnitsFromSelectedSubjectsFilterByUnit &&
                        getUnitsFromSelectedSubjectsFilterByUnit
                          .filter((note) => note.type === "community")
                          .map((note, index) => (
                            <FeatureItemCard
                              key={index}
                              {...note}
                              unit={note.unit}
                              unitNumber={note.id}
                              feature="summary note"
                              clicked={() => {
                                //----MArk the unit as viewed(Push to the server livevisibility)
                                setSelectedUnit(note.unit);
                                history.push(
                                  `/student/features/summarynotes/${note.id}?subject=${note.subject}&unit=${note.unit}`
                                );
                              }}
                            />
                          ))}
                    </div>
                  )}
                </TabPanel>
              </>
            </TabPanel>
          ) : (
            <div></div>
          )}
        </div>
        <div
          style={{ zIndex: "10" }}
          className={`${
            showUnits ? "block" : ""
          } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showUnits ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowUnits(!showUnits)}
              />
            ) : (
              <GiHamburgerMenu
                className={`md:hidden ${localStorage.getItem('h_xtra_units_btn') && ("animate-pulse")}`}
                size={28}
                color="#183F71"
                onClick={() => {setShowUnits(!showUnits); localStorage.getItem('h_xtra_units_btn', 1) }}
              />
              
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default SummaryNotes;
