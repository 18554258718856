import { SectionTitle } from "../../../elements/text";
import { ButtonLink } from "../../../elements/button";
import { Homevideo } from "../../../elements/video";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Spinner from "../../../elements/spinner";

const Howitworks = (props) => {
  const { t } = useTranslation();
  const howitwork = useSelector((state) => state.home.howitwork);
  const howitworkLoading = useSelector((state) => state.home.howitworksLoading);
  return (
    <div className="bg-white my-4">
      {howitworkLoading ? (
        <div className="flex flex-row justify-center">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <div className="w-full flex flex-col pt-8 pb-4 max-w-7xl space-y-6 mx-auto px-8">
          <SectionTitle
            name={t("How it works")}
            color="blue"
            alignment="center"
          />
          <Homevideo
            src={`${process.env.REACT_APP_BACKEND_URL}/opanda/${howitwork?.videourl}`}
          />
          <br />
          <div className="mx-auto">
            <ButtonLink
              page="whoisitfor"
              name={t("Preview Panda")}
              outline="true"
              color="red"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Howitworks;
