import { useState } from "react";
import Input from "../../../elements/input";
import PageContent from "../pageContent";
import { useTabs } from "react-headless-tabs";
import { FeatureTitle } from "../../../elements/text";
import { TabPanel } from "react-headless-tabs";
import NotFound from "../../../common/features/NotFound";
import { useHistory } from "react-router-dom";
import TabSelector from "../../../elements/tab/TabSelector";
import { Button, GoBackButton } from "../../../elements/button";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllTests } from "../../../../functions/getAllTests";
import Spinner from "../../../elements/spinner";
import { student_dashboard_assessments_stats_start } from "../../../../store/actions/student.dashboard.tests.subjects.units";
import Modal from "../../../elements/modal/modal";
import ProgressTable from "./ProgressTable";
import AutoTestForm from "./autotestform";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";

const ProgressContainer = () => {
  // const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState("S1");
  // const [subject, setSubject] = useState("");
  const [showState, setShowState] = useState(false);
  // const [unit, setUnit] = useState("");
  // const [difficulityLevel, setDifficulityLevel] = useState("");
  const [filterBy, setFilterBy] = useState("SELECT");
  const [showlist, setShowlist] = useState(true);
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const history = useHistory();
  // const autoTestProgressList = useSelector(
  //   (state) => state.autoTestProgressList
  // );
  const studentDashboardTests = useSelector(
    (state) => state.studentDashboardTests
  );
  const studentDashboardTestsSubjectsUnits = useSelector(
    (state) => state.studentDashboardTestsSubjectsUnits
  );
  const userAuth = useSelector((state) => state.auth);

  const [showSelectBox, setShowSelectBox] = useState(true);
  useEffect(() => {
    /*dispatch(
      getAutomatedTestListStart({
        userid: userAth.userId,
      })
    );
    //---Get all in app activities
    dispatch(
      student_dashboard_homepage_tests_notifs_start({
        academic_level: userAth.academic_level,
      })
    );
    //----Get Subjects and units--
    dispatch(
      student_dashboard_homepage_tests_subjects_units_start({
        academic_level: userAth.academic_level,
      })
    );*/
    setLevel(userAth.academic_level);
    //-----------Progress stats--
    dispatch(
      student_dashboard_assessments_stats_start({
        token: userAuth.token,
        user_id: userAuth.userId,
        academic_level: userAuth.academic_level
      })
    );

  }, []);
  //---
  // const tests = getAllTests(
  //   studentDashboardTests.dashboard_homepage_tests_notifs
  // );
  const getTestsProgressStats = (data) => {
    if (data.length == 0) {
      return [];
    }
    const autoMade = [];
    for (var i = 0; i < data[0].length; i++) {
      autoMade.push({
        id: data[0][i].panda_test_ai_users_answers_id,
        name: data[0][i].unit_text,
        subject: data[0][i].subject,
        status: (parseFloat(data[0][i].marks_percent) > 50) ? "Passed!!" : "Failed!?!",
        creator: "O'Genius Panda",
        marks: parseFloat(data[0][i].marks_percent).toFixed(2) + "%",
        type: "Automated",
        units: data[0][i].unit_text,
        difficulty: data[0][i].difficulty,
        testid: data[0][i].panda_test_ai_users_answers_random_test_id
      });
    }
    const teacherMade = [];
    for (var i = 0; i < data[1].length; i++) {
      teacherMade.push({
        id: data[1][i].panda_2020_03_03_students_answering_map,
        name: data[1][i].unit,
        subject: data[1][i].subject,
        status: (parseFloat(data[1][i].percentMarks) > 50) ? "Passed!!" : "Failed!?!",
        creator: data[1][i].creator,
        marks: parseFloat(data[1][i].percentMarks) + "%",
        type: "Community",
        testid: data[1][i].panda_2020_03_03_students_answering_map_test_id
      });
    }
    const finalOutput = [...autoMade, ...teacherMade];

    return finalOutput;

  }
  const getSubjectsFromTestsProgressStats = (data) => {
    if (data.length == 0) {
      return [];
    }
    const autoMade = [];
    let subjects = [];
    for (var i = 0; i < data[0].length; i++) {
      autoMade.push({
        id: data[0][i].panda_test_ai_users_answers_id,
        name: data[0][i].unit_text,
        subject: data[0][i].subject,
        status: (parseFloat(data[0][i].marks_percent) > 50) ? "Passed!!" : "Failed!?!",
        creator: "O'Genius Panda",
        marks: parseFloat(data[0][i].marks_percent) + "%",
        type: "Automated"
      });
      //--
      subjects.push(data[0][i].subject);
    }
    const teacherMade = [];
    for (var i = 0; i < data[1].length; i++) {
      teacherMade.push({
        id: data[1][i].panda_2020_03_03_students_answering_map,
        name: data[1][i].unit,
        subject: data[1][i].subject,
        status: (parseFloat(data[1][i].percentMarks) > 50) ? "Passed!!" : "Failed!?!",
        creator: data[1][i].creator,
        marks: parseFloat(data[1][i].percentMarks) + "%",
        type: "Community"
      });
      //--
      subjects.push(data[1][i].subject);
    }

    subjects = Array.from(new Set([...subjects]));
    const subjects_objects = [];
    for (var i = 0; i < subjects.length; i++) {
      subjects_objects.push({ value: subjects[i], displayValue: subjects[i] });
    }
    return subjects_objects;

  }

  // const tests1 = [
  //   {
  //     id: "1",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "Community",
  //   },
  //   {
  //     id: "2",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "Community",
  //   },
  //   {
  //     id: "3",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "My Class",
  //   },
  //   {
  //     id: "4",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "My Class",
  //   },
  //   {
  //     id: "5",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "My Class",
  //   },
  //   {
  //     id: "6",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "My Class",
  //   },
  //   {
  //     id: "7",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "My Class",
  //   },
  //   {
  //     id: "8",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "Automated",
  //   },
  //   {
  //     id: "9",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "Automated",
  //   },
  //   {
  //     id: "10",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "Automated",
  //   },
  //   {
  //     id: "11",
  //     name: "Test title",
  //     subject: 1,
  //     status: "Unit 3",
  //     creator: "/images/preview/bio.svg",
  //     marks: "Test",
  //     type: "Automated",
  //   },
  // ];

  const [selectedTab, setSelectedTab] = useTabs(
    ["My Class", "Automated", "Community"],
    "Automated"
  );

  return (
    <PageContent>
      <Modal small show={showState} modalClosed={() => setShowState(false)}>
        <p>One of the fields is empty!!??</p>
        <Button>Ok</Button>
      </Modal>
      <div
        className={`md:grid grid-cols-12 w-full md:h-full  min-h-screen-sec`}
      >
        {showSelectBox ? <AutoTestForm /> : ""}
        <div
          className={`${showSelectBox ? "hidden md:block" : ""
            } md:relative p-3 bg-white md:h-full h-screen-second mb-16 pb-16 md:max-h-screen-sec col-span-8 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <FeatureTitle name="Tests & Assessments" />
          <span onClick={() => {
            if (window.confirm("Are you sure, you want to leave this page?")) {
              history.push(`/teacher/features/tests`);
            }
          }}><GoBackButton
              link="#"
              action="Back"
              location="Test: Progress"
            /></span>
          {!studentDashboardTests.dashboard_homepage_tests_notifs_loading ? (
            <>
              <div className="md:flex justify-between items-center">
                <nav className="flex justify-start space-x-4">
                  <TabSelector
                    isActive={selectedTab === "Automated"}
                    title="Automated"
                    onClick={() => setSelectedTab("Automated")}
                  />
                  <TabSelector
                    isActive={selectedTab === "My Class"}
                    title="My Class"
                    onClick={() => setSelectedTab("My Class")}
                  />
                  <TabSelector
                    isActive={selectedTab === "Community"}
                    title="Community"
                    onClick={() => setSelectedTab("Community")}
                  />
                </nav>
                <div className="md:w-1/4">
                  <Input
                    elementType="select"
                    elementConfig={{
                      startingValue: "SELECT",
                      options: [...getSubjectsFromTestsProgressStats(studentDashboardTestsSubjectsUnits.dashboard_assessments_stats)
                        /*{ value: "due", displayValue: "Filter By Due date" },
                        { value: "subject", displayValue: "Filter By Subject" },
                        { value: "type", displayValue: "Filter By Type" },*/
                      ],
                    }}
                    value={filterBy}
                    changed={setFilterBy}
                    validation={{ required: true }}
                    shouldValidate
                    error="Invalid filter"
                  />
                </div>
              </div>
              {(!studentDashboardTestsSubjectsUnits.dashboard_assessments_stats_loading) ? <><TabPanel hidden={selectedTab !== "Automated"}>
                {getTestsProgressStats(studentDashboardTestsSubjectsUnits.dashboard_assessments_stats).filter((test) => test.type === "Automated").length ===
                  0 ? (
                  <NotFound feature="tests" />
                ) : (
                  <ProgressTable
                    data={getTestsProgressStats(studentDashboardTestsSubjectsUnits.dashboard_assessments_stats).filter((test) => test.type === "Automated" && (filterBy === test.subject || filterBy === "SELECT"))}
                    show={showlist}
                  />
                )}
              </TabPanel>
                <TabPanel hidden={selectedTab !== "Community"}>
                  {getTestsProgressStats(studentDashboardTestsSubjectsUnits.dashboard_assessments_stats).filter((test) => test.type === "Community").length ===
                    0 ? (
                    <NotFound feature="tests" />
                  ) : (
                    <ProgressTable
                      data={getTestsProgressStats(studentDashboardTestsSubjectsUnits.dashboard_assessments_stats).filter((test) => test.type === "Community" && (filterBy === test.subject || filterBy === "SELECT"))}
                      show={showlist}
                    />
                  )}
                </TabPanel>
                <TabPanel hidden={selectedTab !== "My Class"}>
                  {getTestsProgressStats(studentDashboardTestsSubjectsUnits.dashboard_assessments_stats).filter((test) => test.type === "My Class").length ===
                    0 ? (
                    <NotFound feature="tests" />
                  ) : (
                    <ProgressTable
                      data={getTestsProgressStats(studentDashboardTestsSubjectsUnits.dashboard_assessments_stats).filter((test) => test.type === "My Class" && (filterBy === test.subject || filterBy === "SELECT"))}
                      show={showlist}
                    />
                  )}
                </TabPanel></> : <Spinner size="12" color="blue" />}
            </>
          ) : (
            <Spinner size="12" color="blue" />
          )}
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${showSelectBox ? "block" : ""
            } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSelectBox ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden animate-pulse"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default ProgressContainer;
