import Layout from '../../components/common/Layout';
import RenewPayment from '../../components/pages/parent/renewpayment/RenewPayment';

const ParentRenewPaymentContainer = () => {
  return (
    <Layout>
      <RenewPayment />
    </Layout>
  );
};

export default ParentRenewPaymentContainer;
