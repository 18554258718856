import axios from "../../../../axios-base";

export const GET_CLASSES_ACTIVE_TAB = "GET_CLASSES_ACTIVE_TAB";
export const SET_CLASSES_ACTIVE_TAB = "SET_CLASSES_ACTIVE_TAB";

export const GET_TEACHER_SUBJECT_NAMES_START =
  "GET_TEACHER_SUBJECT_NAMES_START";
export const GET_TEACHER_SUBJECT_NAMES_SUCCESS =
  "GET_TEACHER_SUBJECT_NAMES_SUCCESS";
export const GET_ONE_TEACHER_SUBJECT_NAMES_SUCCESS =
  "GET_ONE_TEACHER_SUBJECT_NAMES_SUCCESS";
export const GET_TEACHER_SUBJECT_NAMES_FAIL = "GET_TEACHER_SUBJECT_NAMES_FAIL";

export const setClassesActiveTab = (tab) => {
  return {
    type: SET_CLASSES_ACTIVE_TAB,
    tab: tab,
  };
};

export const getClassesActiveTab = () => {
  return {
    type: GET_CLASSES_ACTIVE_TAB,
  };
};

export const getTeacherSubjectNamesStart = () => {
  return {
    type: GET_TEACHER_SUBJECT_NAMES_START,
  };
};

export const getTeacherSubjectNamesSuccess = (subjectnames) => {
  return {
    type: GET_TEACHER_SUBJECT_NAMES_SUCCESS,
    payload: subjectnames,
  };
};

export const getOneTeacherSubjectNamesSuccess = (subjectname) => {
  return {
    type: GET_ONE_TEACHER_SUBJECT_NAMES_SUCCESS,
    payload: subjectname,
  };
};

export const getTeacherSubjectNamesFail = (error) => {
  return {
    type: GET_TEACHER_SUBJECT_NAMES_FAIL,
    error: error,
  };
};

export const getTeacherSubjectNames = (token) => {
  return (dispatch) => {
    dispatch(getTeacherSubjectNamesStart());
    axios
      .get(`/opanda/classes/subjects-names`,null, {headers:{Authorization:token}})
      .then((response) => {
        dispatch(getTeacherSubjectNamesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherSubjectNamesFail(err.message));
      });
  };
};

export const GET_SUMMARY_NOTES_START = "GET_SUMMARY_NOTES_START";
export const GET_SUMMARY_NOTES_SUCCESS = "GET_SUMMARY_NOTES_SUCCESS";
export const GET_ONE_SUMMARY_NOTES_SUCCESS =
  "GET_ONE_SUMMARY_NOTES_SUCCESS";
export const GET_SUMMARY_NOTES_FAIL = "GET_SUMMARY_NOTES_FAIL";

export const getSummaryNotesStart = () => {
  return {
    type: GET_SUMMARY_NOTES_START,
  };
};

export const getSummaryNotesSuccess = (classecourses) => {
  return {
    type: GET_SUMMARY_NOTES_SUCCESS,
    payload: classecourses,
  };
};

export const getOneSummaryNotesSuccess = (classecourse) => {
  return {
    type: GET_ONE_SUMMARY_NOTES_SUCCESS,
    payload: classecourse,
  };
};

export const getSummaryNotesFail = (error) => {
  return {
    type: GET_SUMMARY_NOTES_FAIL,
    error: error,
  };
};

export const createSummaryNotes = (course, token)=>{
  return (dispatch) => {
    dispatch(getSummaryNotesStart());
    axios
      .post(`/opanda/classes/create`, course, {headers:{
        authorization:`Bearer ${token}`
      }})
      .then((response) => {
        dispatch(getOneSummaryNotesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getSummaryNotesFail(err.message));
      });
  };
}
