import React from 'react'
import PageContent from "../../pageContent";
import Sidebar  from '../Sidebar/Sidebar';
import { 
  CardBody, CardTitle, 
  SectionTitle, CardSubText, 
  PageTitle, PageBody
} from '../../../../elements/text';
import { DynamicCard } from '../../../../elements/card'

const ExtraCurricular = () => {
  const subjects =[
    {id: 1, name: 'Module 1'},
    {id: 2, name: 'Module 2'},
    {id: 3, name: 'Module 1'},
    {id: 4, name: 'Module 2'},
    {id: 5, name: 'Module 1'},
  ]
  return (
    <PageContent>
   <div className="flex flex-row h-full bg-white">
     <Sidebar />
     <div className='w-full lg:w-3/4 flex flex-col p-4 overflow-x-auto'>
      <div>
      <SectionTitle name="Digital Ambassador Program (DAP)" color="blue" />
       <PageBody name="
        Officia pariatur consectetur esse duis fugiat nulla qui est. Cillum quis ipsum in id commodo dolor dolore 
        adipisicing magna. Aliqua nulla velit laboris et proident pariatur excepteur est irure fugiat veniam aliqua 
        proident proident.Adipisicing deserunt cupidatat labore in aliqua incididunt eiusmod minim ipsum veniam laboris. 
        Eiusmod ea sunt exercitation minim. Duis culpa aute veniam eu quis. Quis est cillum dolore culpa exercitation ipsum 
        enim nisi commodo. Elit eu fugiat et excepteur dolor Lorem ad veniam. Ex laborum in labore non cillum aute excepteur 
        est sit labore deserunt. Ea ea laboris est quis ea aliqua duis.
         " />
      </div>
       <div className="mt-9 w-full">
         <SectionTitle name="Modules" color="blue" />
         <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
           {subjects.map((item)=> (
           <DynamicCard 
           key={item.id} 
           color="white"
           additionalStyles='items-center justify-center w-50'
           >
             <CardBody name={item.name} color="blue" />
           </DynamicCard>
           )
           )}
           <DynamicCard 
           color="white" 
           additionalStyles='items-center justify-center'
           >
             <CardBody name='Module NAME' color="blue" />
           </DynamicCard>
         </div>
       </div>
     </div>
   </div>
 </PageContent>
  )
}

export default ExtraCurricular