import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "./progressBar";
import SideBarItem from "./SideBarItem";
import { completeSignupClicked } from "../../../../store/actions";
import * as constants from "../../../../constants/constants";


const SideBar = (props) => {
  let completeSignUpItems = useSelector(
    (state) => state.completeSignup.completeSignUpItems
  );
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // const filteredSignupItems = (arr, key, value) => {
  //   const newArray = [];
  //   for(let i=0, arrLength=arr.length; i<arrLength; i++) {
  //     if(arr[i][key] !== value) {
  //       newArray.push(arr[i]);
  //     }
  //   }
  //  return newArray;
  // }
  completeSignUpItems = completeSignUpItems.filter((obj) => {
    return ((obj.title !== "Academic Information" && (user?.user_type === constants.parentusertype || user?.user_type === constants.schoolusertype)) || (user?.user_type !== constants.parentusertype && user?.user_type !== constants.schoolusertype));
  });


  const itemClickedHandler = (actionName) => {
    dispatch(completeSignupClicked(actionName));
    // props.setType(actionName);
    props.closed();
  };
  const getPercentage = () => {
    const completed = completeSignUpItems.filter(
      (item) => item.completed === true
    );
    return completed.length * 100 / completeSignUpItems.length;
  };

  // if(user?.user_type === constants.parentusertype){
  //    completeSignUpItems = filteredSignupItems(completeSignUpItems, "title", 'Academic Information')
  // }
  return (
    <Fragment>
      <ProgressBar progress={getPercentage()} />
      {completeSignUpItems.map((item, index) => (
        <SideBarItem
          key={index}
          title={item.title}
          subTitle={item.subTitle}
          complete={item.completed}
          selected={item.selected}
          selectedColor={props.selectedColor}
          clicked={() => {/*itemClickedHandler(item.title)*/ }}
        />
      ))
      }
    </Fragment>
  );
};

export default SideBar;
