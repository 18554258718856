import Layout from '../../../components/common/Layout';
import ScheduleContainer from './../../../components/pages/teacher/schedule/dashboard';

const Schedule = () => {
  return (
    <Layout>
      <ScheduleContainer />
    </Layout>
  );
};

export default Schedule; 
