import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import FillinTypeAutoQuestionItemOneViewPopOverItem from './FillinTypeAutoQuestionItemOneViewPopOverItem';

const FillinTypeAutoQuestionItemOneViewPopOver = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const target = useRef(null);
    const [text, setText] = useState("");


    useEffect(() => {

    }, []);
    const renderTooltip = (data) => (
        <Tooltip data={data} id="button-tooltip" {...props}>
            {poolAnswer(props.data)}
        </Tooltip>
    );
    const setAnswerChoice = (choice) => {
        props.callFillinTypeAuto(choice, props.currentQuestionNumber, props.questionId, props.questionPlace);
        setText(choice);
    }
    const poolAnswer = (data) => {
        return <FillinTypeAutoQuestionItemOneViewPopOverItem questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} callFillinTypeAuto={props.callFillinTypeAuto} setHideme={setHideme} data={data} setAnswerChoice={setAnswerChoice} />
    }
    const setHideme = (check) => {
        target.current.click();

    }
    const renderPickedAnswer = (data) => {
        const newArr = data;
        let textw = "";
        for (var i = 0; i < newArr?.length; i++) {
            if (parseInt(newArr[i].question_number) === parseInt(props.currentQuestionNumber) && parseInt(newArr[i].questionId) === parseInt(props.questionId) && parseInt(newArr[i].questionPlace) === parseInt(props.questionPlace)) {
                textw = newArr[i].newEntry;
            }
        }
        return ((textw === "") ? "...................." : textw);
    };

    return (
        <span className='text-black'>
            <OverlayTrigger
                placement={(isMobile) ? "right" : "left"}
                delay={{ show: 250, hide: 400 }}
                data={props.data}
                overlay={renderTooltip()}
                trigger="click"
            >
                <Button ref={target} variant="success" > <span className='text-white'><span className='text-black'><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z" clip-rule="evenodd" />
                </svg></span>{renderPickedAnswer(props?.fillinTypeAuto)}</span></Button>
            </OverlayTrigger>
        </span >
    );
};
export default FillinTypeAutoQuestionItemOneViewPopOver;
