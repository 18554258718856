import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  modules: [],
  modulesError: null,
  modulesLoading: false,
  module: null,
  extracurriculars: [],
  extracurricularsError: null,
  extracurricularsLoading: false,
  extracurricular: null,
  learningresources: [],
  learningresourcesError: null,
  learningresourcesLoading: false,
  learningresource: null,
  books: [],
  booksError: null,
  booksLoading: false,
  book: null,
  coursestructures: [],
  coursestructuresLoading: false,
  coursestructuresError: null,
  coursestructure: null,
  tests: [],
  testsError: null,
  testsLoading: false,
  test: null,
  classcourses: [],
  classcoursesError: null,
  classcoursesLoading: false,
  classcourse: null,
  subjectsnames: [],
  subjectsnamesError: null,
  subjectsnamesLoading: false,
  subjectsname: null,
  coursepages: [],
  coursepagesError: null,
  coursepagesLoading: false,
  coursepage: null,
  paymentoptions: [],
  paymentoptionsError: null,
  paymentoptionsLoading: false,
  paymentoption: null,  
  paymentmethods: [],
  paymentmethodsError: null,
  paymentmethodsLoading: false,
  paymentmethod: null,
};

const getOGeniusModulesStart = (state, action) => {
  return updateObject(state, {
    modules: [],
    modulesError: null,
    modulesLoading: true,
    module: null,
  });
};

const getOGeniusModulesSuccess = (state, action) => {
  return updateObject(state, {
    modules: action.modules,
    modulesError: null,
    modulesLoading: false,
    module: null,
  });
};

const getOGeniusModulesFail = (state, action) => {
  return updateObject(state, {
    modules: [],
    modulesError: action.error,
    modulesLoading: false,
    module: null,
  });
};

const getOneOGeniusModulesSuccess = (state, action) => {
  return updateObject(state, {
    modules: [],
    modulesError: null,
    modulesLoading: false,
    module: action.module,
  });
};

const getExtraCurricularsStart = (state, action) => {
  return updateObject(state, {
    extracurriculars: [],
    extracurricularsError: null,
    extracurricularsLoading: true,
    extracurricular: null,
  });
};

const getExtraCurricularsSuccess = (state, action) => {
  return updateObject(state, {
    extracurriculars: action.extracurriculars,
    extracurricularsError: null,
    extracurricularsLoading: false,
    extracurricular: null,
  });
};

const getExtraCurricularsFail = (state, action) => {
  return updateObject(state, {
    extracurriculars: [],
    extracurricularsError: action.error,
    extracurricularsLoading: false,
    extracurricular: null,
  });
};

const getOneExtraCurricularsSuccess = (state, action) => {
  return updateObject(state, {
    extracurriculars: [],
    extracurricularsError: null,
    extracurricularsLoading: false,
    extracurricular: action.extracurricular,
  });
};

const getLearningResourcesStart = (state, action) => {
  return updateObject(state, {
    learningresources: [],
    learningresourcesError: null,
    learningresourcesLoading: true,
    learningresource: null,
  });
};

const getLearningResourcesSuccess = (state, action) => {
  return updateObject(state, {
    learningresources: action.learningresources,
    learningresourcesError: null,
    learningresourcesLoading: false,
    learningresource: null,
  });
};

const getLearningResourcesFail = (state, action) => {
  return updateObject(state, {
    learningresources: [],
    learningresourcesError: action.error,
    learningresourcesLoading: false,
    learningresource: null,
  });
};

const getOneLearningResourcesSuccess = (state, action) => {
  return updateObject(state, {
    learningresources: [],
    learningresourcesError: null,
    learningresourcesLoading: false,
    learningresource: action.learningresource,
  });
};

const getBooksStart = (state, action) => {
  return updateObject(state, {
    books: [],
    booksError: null,
    booksLoading: true,
    book: null,
  });
};

const getBooksSuccess = (state, action) => {
  return updateObject(state, {
    books: action.books,
    booksError: null,
    booksLoading: false,
    book: null,
  });
};

const getBooksFail = (state, action) => {
  return updateObject(state, {
    books: [],
    booksError: action.error,
    booksLoading: false,
    book: null,
  });
};

const getOneBooksSuccess = (state, action) => {
  return updateObject(state, {
    books: [],
    booksError: null,
    booksLoading: false,
    book: action.book,
  });
};

const getCourseStructuresStart = (state, action) => {
  return updateObject(state, {
    coursestructures: [],
    coursestructuresError: null,
    coursestructuresLoading: true,
    coursestructure: null,
  });
};

const getCourseStructuresSuccess = (state, action) => {
  return updateObject(state, {
    coursestructures: action.coursestructures,
    coursestructuresError: null,
    coursestructuresLoading: false,
    coursestructure: null,
  });
};

const getCourseStructuresFail = (state, action) => {
  return updateObject(state, {
    coursestructures: [],
    coursestructuresError: action.error,
    coursestructuresLoading: false,
    coursestructure: null,
  });
};

const getOneCourseStructuresSuccess = (state, action) => {
  return updateObject(state, {
    coursestructures: [],
    coursestructuresError: null,
    coursestructuresLoading: false,
    coursestructure: action.coursestructure,
  });
};

const getTestsStart = (state, action) => {
  return updateObject(state, {
    tests: [],
    testsError: null,
    testsLoading: true,
    test: null,
  });
};

const getTestsSuccess = (state, action) => {
  return updateObject(state, {
    tests: action.tests,
    testsError: null,
    testsLoading: false,
    test: null,
  });
};

const getTestsFail = (state, action) => {
  return updateObject(state, {
    tests: [],
    testsError: action.error,
    testsLoading: false,
    test: null,
  });
};

const getOneTestsSuccess = (state, action) => {
  return updateObject(state, {
    tests: [],
    testsError: null,
    testsLoading: false,
    test: action.test,
  });
};

const getClassCoursesStart = (state, action) => {
  return updateObject(state, {
    classcourses: [],
    classcoursesError: null,
    classcoursesLoading: true,
    classcourse: null,
  });
};

const getClassCoursesSuccess = (state, action) => {
  return updateObject(state, {
    classcourses: action.classcourses,
    classcoursesError: null,
    classcoursesLoading: false,
    classcourse: null,
  });
};

const getClassCoursesFail = (state, action) => {
  return updateObject(state, {
    classcourses: [],
    classcoursesError: action.error,
    classcoursesLoading: false,
    classcourse: null,
  });
};

const getOneClassCoursesSuccess = (state, action) => {
  return updateObject(state, {
    classcourses: [],
    classcoursesError: null,
    classcoursesLoading: false,
    classcourse: action.classcourse,
  });
};

const getClassSubjectsNamesStart = (state, action) => {
  return updateObject(state, {
    subjectsnames: [],
    subjectsnamesError: null,
    subjectsnamesLoading: true,
    subjectsname: null,
  });
};

const getClassSubjectsNamesSuccess = (state, action) => {
  return updateObject(state, {
    subjectsnames: action.subjectsnames,
    subjectsnamesError: null,
    subjectsnamesLoading: false,
    subjectsname: null,
  });
};

const getClassSubjectsNamesFail = (state, action) => {
  return updateObject(state, {
    subjectsnames: [],
    subjectsnamesError: action.error,
    subjectsnamesLoading: false,
    subjectsname: null,
  });
};

const getOneClassSubjectsNamesSuccess = (state, action) => {
  return updateObject(state, {
    subjectsnames: [],
    subjectsnamesError: null,
    subjectsnamesLoading: false,
    subjectsname: action.subjectsname,
  });
};

const getCoursePagesStart = (state, action) => {
  return updateObject(state, {
    coursepages: [],
    coursepagesError: null,
    coursepagesLoading: true,
    coursepage: null,
  });
};

const getCoursePagesSuccess = (state, action) => {
  return updateObject(state, {
    coursepages: action.coursepages,
    coursepagesError: null,
    coursepagesLoading: false,
    coursepage: null,
  });
};

const getCoursePagesFail = (state, action) => {
  return updateObject(state, {
    coursepages: [],
    coursepagesError: action.error,
    coursepagesLoading: false,
    coursepage: null,
  });
};

const getOneCoursePagesSuccess = (state, action) => {
  return updateObject(state, {
    coursepages: [],
    coursepagesError: null,
    coursepagesLoading: false,
    coursepage: action.coursepage,
  });
};

const getPaymentOptionsStart = (state, action) => {
  return updateObject(state, {
    paymentoptions: [],
    paymentoptionsError: null,
    paymentoptionsLoading: true,
    paymentoption: null,
  });
};

const getPaymentOptionsSuccess = (state, action) => {
  return updateObject(state, {
    paymentoptions: action.paymentoptions,
    paymentoptionsError: null,
    paymentoptionsLoading: false,
    paymentoption: null,
  });
};

const getPaymentOptionsFail = (state, action) => {
  return updateObject(state, {
    paymentoptions: [],
    paymentoptionsError: action.error,
    paymentoptionsLoading: false,
    paymentoption: null,
  });
};

const getOnePaymentOptionsSuccess = (state, action) => {
  return updateObject(state, {
    paymentoptions: [],
    paymentoptionsError: null,
    paymentoptionsLoading: false,
    paymentoption: action.paymentoption,
  });
};

const getPaymentMethodsStart = (state, action) => {
  return updateObject(state, {
    paymentmethods: [],
    paymentmethodsError: null,
    paymentmethodsLoading: true,
    paymentmethod: null,
  });
};

const getPaymentMethodsSuccess = (state, action) => {
  return updateObject(state, {
    paymentmethods: action.paymentmethods,
    paymentmethodsError: null,
    paymentmethodsLoading: false,
    paymentmethod: null,
  });
};

const getPaymentMethodsFail = (state, action) => {
  return updateObject(state, {
    paymentmethods: [],
    paymentmethodsError: action.error,
    paymentmethodsLoading: false,
    paymentmethod: null,
  });
};

const getOnePaymentMethodsSuccess = (state, action) => {
  return updateObject(state, {
    paymentmethods: [],
    paymentmethodsError: null,
    paymentmethodsLoading: false,
    paymentmethod: action.paymentmethod,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_OGENIUS_MODULES_START:
      return getOGeniusModulesStart(state, action);
    case actionsTypes.GET_OGENIUS_MODULES_SUCCESS:
      return getOGeniusModulesSuccess(state, action);
    case actionsTypes.GET_ONE_OGENIUS_MODULES_SUCCESS:
      return getOneOGeniusModulesSuccess(state, action);
    case actionsTypes.GET_OGENIUS_MODULES_FAIL:
      return getOGeniusModulesFail(state, action);
    case actionsTypes.GET_EXTRA_CURRICULARS_START:
      return getExtraCurricularsStart(state, action);
    case actionsTypes.GET_EXTRA_CURRICULARS_SUCCESS:
      return getExtraCurricularsSuccess(state, action);
    case actionsTypes.GET_ONE_EXTRA_CURRICULARS_SUCCESS:
      return getOneExtraCurricularsSuccess(state, action);
    case actionsTypes.GET_EXTRA_CURRICULARS_FAIL:
      return getExtraCurricularsFail(state, action);
    case actionsTypes.GET_LEARNING_RESOURCES_START:
      return getLearningResourcesStart(state, action);
    case actionsTypes.GET_LEARNING_RESOURCES_SUCCESS:
      return getLearningResourcesSuccess(state, action);
    case actionsTypes.GET_ONE_LEARNING_RESOURCES_SUCCESS:
      return getOneLearningResourcesSuccess(state, action);
    case actionsTypes.GET_LEARNING_RESOURCES_FAIL:
      return getLearningResourcesFail(state, action);
    case actionsTypes.GET_OPANDA_BOOKS_START:
      return getBooksStart(state, action);
    case actionsTypes.GET_OPANDA_BOOKS_SUCCESS:
      return getBooksSuccess(state, action);
    case actionsTypes.GET_ONE_OPANDA_BOOKS_SUCCESS:
      return getOneBooksSuccess(state, action);
    case actionsTypes.GET_OPANDA_BOOKS_FAIL:
      return getBooksFail(state, action);
    case actionsTypes.GET_COURSE_STRUCTURES_START:
      return getCourseStructuresStart(state, action);
    case actionsTypes.GET_COURSE_STRUCTURES_SUCCESS:
      return getCourseStructuresSuccess(state, action);
    case actionsTypes.GET_ONE_COURSE_STRUCTURES_SUCCESS:
      return getOneCourseStructuresSuccess(state, action);
    case actionsTypes.GET_COURSE_STRUCTURES_FAIL:
      return getCourseStructuresFail(state, action);
    case actionsTypes.GET_TESTS_START:
      return getTestsStart(state, action);
    case actionsTypes.GET_TESTS_SUCCESS:
      return getTestsSuccess(state, action);
    case actionsTypes.GET_ONE_TESTS_SUCCESS:
      return getOneTestsSuccess(state, action);
    case actionsTypes.GET_TESTS_FAIL:
      return getTestsFail(state, action);
    case actionsTypes.GET_CLASS_COURSES_START:
      return getClassCoursesStart(state, action);
    case actionsTypes.GET_CLASS_COURSES_SUCCESS:
      return getClassCoursesSuccess(state, action);
    case actionsTypes.GET_ONE_CLASS_COURSES_SUCCESS:
      return getOneClassCoursesSuccess(state, action);
    case actionsTypes.GET_CLASS_COURSES_FAIL:
      return getClassCoursesFail(state, action);
    case actionsTypes.GET_CLASS_SUBJECTS_NAMES_START:
      return getClassSubjectsNamesStart(state, action);
    case actionsTypes.GET_CLASS_SUBJECTS_NAMES_SUCCESS:
      return getClassSubjectsNamesSuccess(state, action);
    case actionsTypes.GET_ONE_CLASS_SUBJECTS_NAMES_SUCCESS:
      return getOneClassSubjectsNamesSuccess(state, action);
    case actionsTypes.GET_CLASS_SUBJECTS_NAMES_FAIL:
      return getClassSubjectsNamesFail(state, action);
      case actionsTypes.GET_COURSE_PAGES_START:
        return getCoursePagesStart(state, action);
      case actionsTypes.GET_COURSE_PAGES_SUCCESS:
        return getCoursePagesSuccess(state, action);
      case actionsTypes.GET_ONE_COURSE_PAGES_SUCCESS:
        return getOneCoursePagesSuccess(state, action);
      case actionsTypes.GET_COURSE_PAGES_FAIL:
        return getCoursePagesFail(state, action);
        case actionsTypes.GET_PAYMENT_OPTIONS_START:
        return getPaymentOptionsStart(state, action);
      case actionsTypes.GET_PAYMENT_OPTIONS_SUCCESS:
        return getPaymentOptionsSuccess(state, action);
      case actionsTypes.GET_ONE_PAYMENT_OPTIONS_SUCCESS:
        return getOnePaymentOptionsSuccess(state, action);
      case actionsTypes.GET_PAYMENT_OPTIONS_FAIL:
        return getPaymentOptionsFail(state, action);
        case actionsTypes.GET_PAYMENT_METHODS_START:
          return getPaymentMethodsStart(state, action);
        case actionsTypes.GET_PAYMENT_METHODS_SUCCESS:
          return getPaymentMethodsSuccess(state, action);
        case actionsTypes.GET_ONE_PAYMENT_METHODS_SUCCESS:
          return getOnePaymentMethodsSuccess(state, action);
        case actionsTypes.GET_PAYMENT_METHODS_FAIL:
          return getPaymentMethodsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
