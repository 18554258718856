import { CardBody, SectionTitle } from "../../../elements/text";
import { PricingCard } from "../../../elements/card";
import StudentIcon from "../../../../images/icons/students.svg";
import TeacherIcon from "../../../../images/icons/teachers.svg";
import ParentIcon from "../../../../images/icons/parents.svg";
import SchoolIcon from "../../../../images/icons/schools.svg";
import BooksIcon from "../../../../images/icons/books.png";
import LabsIcon from "../../../../images/icons/labs.png";
import LearningPanelIcon from "../../../../images/icons/learningpanel.png";
import TestsIcon from "../../../../images/icons/tests.png";
import MorePurchaseModal from "../../../elements/modal/morepurchasemodal";
import { connect, useDispatch, useSelector } from "react-redux";
import { store } from "../../../../store/store";
import { useEffect } from "react";
import * as actions from "../../../../store/actions/";
import { useTranslation } from "react-i18next";
import Spinner from "../../../elements/spinner";

const MoreInfo = (props) => {
  const { t } = useTranslation();
  const whoisfor = useSelector((state) => state.home.whoisopandafor);
  const whoisforLoading = useSelector(
    (state) => state.home.whoisopandaforLoading
  );
  const features = useSelector((state) => state.features.features.features);
  const featuresLoading = useSelector(
    (state) => state.features.features.featuresLoading
  );
  const dispatch = useDispatch();
  const { showmodal } = props;
  const pricingobjects = whoisfor?.concat(features);
  useEffect(() => {
    dispatch(actions.getHomeWhoIsOpandaFor());
    dispatch(actions.getFeatures());
    dispatch(actions.getPaymentOptions());
    dispatch(actions.getPaymentMethods());
  }, []);
  return (
    <div className="bg-white my-6">
      <MorePurchaseModal />
      {whoisforLoading && featuresLoading ? (
        <div className="flex flex-row justify-center">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <div className="max-w-7xl w-full mx-auto px-8">
          <SectionTitle
            name={t("Need more information before you purchase?")}
            alignment="center"
            color="blue"
          />
          <CardBody
            alignment="center"
            color="center"
            name={t(
              "Select one of the following options to be shown more information about what we offer, who can use our product and why O’Genius Panda is the perfect education solution for you."
            )}
          />
          <div className="grid grid-cols-2  md:grid-cols-4 gap-4 content-start justify-items-start">
            {pricingobjects?.map((data, key) => (
              <PricingCard
                key={key}
                title={
                  data?.usertype?.charAt(0).toUpperCase() +
                    data?.usertype?.slice(1) || data.name
                }
                imgsrc={
                  data.usersthumbnail !== undefined
                    ? `${process.env.REACT_APP_BACKEND_URL}/opanda/${data?.usersthumbnail}`
                    : `${process.env.REACT_APP_BACKEND_URL}/opanda/${data?.thumbnail}`
                }
                onClick={() => {
                  showmodal("block", data);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// export default SideBar;
const mapStateToProps = (state) => {
  return {
    modalstatus: store.getState().contentmodalreducer.morepurchasemodalstatus,
    // modalcontent: state.modalcontent,
  };
};
const mapDispatchToProps = () => {
  return {
    showmodal: (status, data) =>
      store.dispatch({
        type: "SHOW_MORE_PURCHASE_MODAL",
        payload: status,
        modalcontent: data,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MoreInfo);
