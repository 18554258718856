import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getTeacherFillinQuestionsStart = () => {
  return {
    type: actionTypes.GET_FILLIN_TEACHER_QUESTIONS_START,
  };
};

export const getTeacherFillinQuestionsSuccess = (questions) => {
  return {
    type: actionTypes.GET_FILLIN_TEACHER_QUESTIONS_SUCCESS,
    questions: questions,
  };
};

export const getOneTeacherFillinQuestionsSuccess = (onequestion) => {
  return {
    type: actionTypes.GET_FILLIN_TEACHER_QUESTIONS_SUCCESS,
    onequestion: onequestion,
  };
};

export const getTeacherFillinQuestionsFail = (error) => {
  return {
    type: actionTypes.GET_FILLIN_TEACHER_QUESTIONS_FAIL,
    error: error,
  };
};

export const getTeacherFillinQuestions = () => {
  return (dispatch) => {
    dispatch(getTeacherFillinQuestionsStart());
    axios
      .get(`/opanda/panda-teacher-fillin-questions`)
      .then((response) => {
        dispatch(getTeacherFillinQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherFillinQuestionsFail(err.message));
      });
  };
};

export const registerTeacherFillinQuestions = (object) => {
  return (dispatch) => {
    dispatch(getTeacherFillinQuestionsStart());
    axios
      .post(`/opanda/panda-teacher-fillin-questions/register-questions`, object)
      .then((response) => {
        dispatch(getTeacherFillinQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherFillinQuestionsFail(err.message));
      });
  };
};
