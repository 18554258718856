import { CardSubText } from "../../../elements/text";
import { AnchorButton } from "../../../elements/button";

const NotificationBar = (props) => {
  return (
    <div className="bg-red w-full sticky">
      <div className="mx-auto flex justify-center py-1">
        <CardSubText
          name="You are currently in Preview mode. To gain full access, "
          alignment="center"
          mobileAlignment="left"
          color="white"
        />
        <AnchorButton
          to="register"
          name="Register today"
          color="white"
          alignment="center"
        />&nbsp;
        
      </div>
    </div>
  );
};

export const NotificationBarCompetition = (props) => {
  return (
    <div className="bg-red w-full sticky">
      <div className="mx-auto flex justify-center py-1 animate-bounce">
        <CardSubText
          name="Win prizes by doing the most tests and having the best marks! "
          alignment="center"
          mobileAlignment="left"
          color="white"
        /> 
        <AnchorButton
          to="register"
          name="Register today"
          color="white"
          alignment="center"
        />&nbsp;
        
      </div>
    </div>
  )
}
export default NotificationBar;
