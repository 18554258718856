import Layout from '../../../../../common/Layout';
import CreateForm from './create';

const CreateSubjectsContainer = () => {
  return (
    <Layout>
      <CreateForm />
    </Layout>
  );
};

export default CreateSubjectsContainer;
