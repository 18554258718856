import Logo from "../../../images/logo.svg";
import click from "../../../images/click.svg";
import unclick from "../../../images/unclick.svg";
import { FaGlobe } from "react-icons/fa";
import { ButtonLink } from "../../elements/button";
import { Link } from "./links";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/i18n";
import * as actionTypes from "../../../store/actions";
import {
  changeSideBarCollapseState,
  changeStudentDashboardMobileVisibility,
} from "../../../store/actions/sidebaractiontypes";
import { MdLogout, MdNotifications } from "react-icons/md";
import { CardBody, CardBodyWithExtras } from "../../elements/text";
import { CustomModal } from "../../elements/modal/CustomModal";
import MainNotification from "../../elements/notification/mainNotifications/MainNotification";
import { Link as Mylink, useHistory } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
const Header = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [showMobileNavBar, setShowMobileNavBar] = useState(false);
  const [showChooseLng, setShowChooseLng] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [selectLng, setSelectLng] = useState(
    localStorage.getItem("i18nextLng")
  );
  const languages = useSelector((state) => state.languages.languages);
  const notificationsCount = useSelector(
    (state) => state.notificationsCountUpdates.notificationsCount
  );
  const { t } = useTranslation();
  const handleLang = (value) => {
    i18n.changeLanguage(value);
    setSelectLng(value);
    setShowChooseLng(false);
  };
  const selectedLanguage = languages.filter(
    (language) => language.abbreviation === selectLng
  );

  const showMainNotifications = useSelector(
    (state) => state.notifications.showMainnotifications
  );

  const logout = () => {
    dispatch(actionTypes.logout());
    history.push("/");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    dispatch(actionTypes.changeLanguage(selectLng));
    const checkIfClickedOutside = (e) => {
      if (showChooseLng && ref.current && !ref.current.contains(e.target)) {
        setShowChooseLng(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [
    showChooseLng,
    selectLng,
    dispatch,
    localStorage.getItem("notificationcount"),
  ]);

  const isAuth = useSelector((state) => state.auth.token !== null);
  const displayName = useSelector((state) => state.auth.displayName);
  const user = useSelector((state) => state.auth.user);
  // const userAth = useSelector((state) => state.auth);
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );
  const studentDashboardMobileSidebar = useSelector(
    (state) => state.reducer.studentDashboardMobileSidebar
  );
  // const studentDashboardMobileSidebar = []
  if (isAuth) {
    return (
      <>
        <CustomModal
          show={showMainNotifications}
          top={70}
          right={10}
          width={"40%"}
          modalClosed={() => {
            dispatch(actionTypes.setShowMainNotification(false));
          }}
        >
          {user?.isprofilecompleted ? <MainNotification /> : ""}
        </CustomModal>
        <nav className="bg-white dark:bg-gray-800 shadow sticky top-0 z-50 font-poppins ">
          <div className="max-w-7xl mx-auto px-2 md:px-8">
            <div className="flex justify-between items-center h-16">
              <div className="flex space-x-4 flex-shrink-0">
                <div
                  className="hidden md:block"
                  onClick={() => {
                    dispatch(changeSideBarCollapseState(!isSidebarCollapsed));
                  }}
                >
                  {isSidebarCollapsed ? (
                    <img
                      className="h-8 w-auto cursor-pointer"
                      src={click}
                      alt="Click"
                    />
                  ) : (
                    <img
                      className="h-8 w-auto cursor-pointer"
                      src={unclick}
                      alt="Click"
                    />
                  )}
                </div>
                <Mylink to="/">
                  <img className="h-8 w-auto" src={Logo} alt="O'Genius Panda" />
                </Mylink>
              </div>
              <div className="flex space-x-5 items-center">
                {user.isprofilecompleted ? (
                  <div
                    className="relative"
                    onClick={() => {
                      dispatch(actionTypes.setShowMainNotification(true));
                    }}
                  >
                    <MdNotifications size="36" color="#183F71" />
                    {notificationsCount > 0 ? (
                      <span
                        style={{ fontWeight: "bold !important" }}
                        className="absolute top-2 -right-1 inline-flex items-center justify-center px-1 py-px text-xs font-bold leading-none text-white bg-red rounded-full"
                      >
                        {notificationsCount}
                        <span style={{ fontSize: 10 }}></span>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="relative"
                  onClick={() => setShowLogout(!showLogout)}
                >
                  <div className="md:flex space-x-2 justify-center items-center cursor-pointer">
                    <img
                      className="inline object-cover w-8 h-8 rounded-full"
                      src={
                        user?.avatarimage !== null
                          ? user?.avatarimage
                          : "/images/avatars/profileavatar.png"
                      }
                      alt={"Profile"}
                    />
                    <CardBodyWithExtras
                      name={displayName ?? ""}
                      color="gray-500"
                    />
                  </div>
                  <div
                    className={`${
                      showLogout
                        ? "mt-2 absolute bg-white text-main-color lg:right-0 shadow-lg"
                        : "hidden"
                    }`}
                  >
                    <ul className="divide-y divide-blue">
                      {user?.isprofilecompleted === 1 &&
                      user?.paymentvalid === true &&
                      (user?.user_type !== "teacher" ||
                        user?.user_type !== "parent") ? (
                        <li
                          className="flex justify-between items-center space-x-2 p-3 cursor-pointer"
                          onClick={() =>
                            history.push(`/${user.user_type}/user/profile`)
                          }
                        >
                          <CgProfile color="#183F71" />
                          <CardBody
                            name="Profile"
                            color="blue"
                            additional="hover:font-bold"
                          />
                        </li>
                      ) : (
                        ""
                      )}
                      {user?.isprofilecompleted === 1 &&
                      (user?.user_type === "teacher" ||
                        user?.user_type === "parent") ? (
                        <li
                          className="flex justify-between items-center space-x-2 p-3 cursor-pointer"
                          onClick={() => history.push(`/login`)}
                        >
                          <CgProfile color="#183F71" />
                          <CardBody
                            name="Profile"
                            color="blue"
                            additional="hover:font-bold"
                          />
                        </li>
                      ) : (
                        ""
                      )}

                      <li
                        className="flex justify-between items-center space-x-2 p-3 cursor-pointer"
                        onClick={logout}
                      >
                        <MdLogout color="#183F71" />
                        <CardBody
                          name="Logout"
                          color="blue"
                          additional="hover:text-red"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="relative"
                  onClick={() => {
                    setShowChooseLng(!showChooseLng);
                  }}
                >
                  <div className="flex justify-center items-center cursor-pointer ">
                    <FaGlobe size={24} />
                    <img
                      className="w-10 h-5"
                      src={selectedLanguage[0].flag}
                      alt=""
                    />
                  </div>
                  <div
                    className={`${
                      showChooseLng
                        ? "mt-2 absolute bg-white text-main-color lg:right-0 shadow-lg"
                        : "hidden"
                    }`}
                  >
                    <ul className="divide-y divide-blue">
                      {languages.map((language, index) => (
                        <li
                          key={index}
                          className={`p-3 cursor-pointer hover:border-b-2 border-main-color ${
                            language.abbreviation === selectLng
                              ? "text-blue"
                              : ""
                          } hover:text-blue font-semibold`}
                          onClick={() => handleLang(language.abbreviation)}
                        >
                          {language.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  <button
                    className="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
                    onClick={() => {
                      dispatch(
                        changeStudentDashboardMobileVisibility(
                          studentDashboardMobileSidebar === "hidden"
                            ? "block"
                            : "hidden"
                        )
                      );
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="h-8 w-8"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }

  return (
    <nav className="bg-white dark:bg-gray-800 shadow sticky top-0 z-50 font-poppins ">
      <div className="max-w-7xl mx-auto px-8">
        <div className="flex justify-between items-center h-16">
          <div className="w-full justify-between flex items-center">
            <Mylink className="flex-shrink-0" to="/">
              <img className="h-8 w-auto" src={Logo} alt="O'Genius Panda" />
            </Mylink>
            <div className="hidden lg:block">
              <div className="ml-10 flex items-center space-x-2">
                <Link
                  page="/previewpanda"
                  indicator={props.pageLink}
                  name={t("Preview panda")}
                />
                <Link page="/pricing" name={t("Pricing")} />
                <Link page="/whoisitfor" name={t("Who is it for?")} />
                <Link page="/features" name={t("Features")} />
                <Link page="/faq" name="FAQ" />
                <Link page="/teacherquiz" name="Quiz" />
                {/*<Link page="/donate" name={t('Donate')} />*/}
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-center space-x-2">
                <ButtonLink
                  page="/enroll"
                  name={t("Enroll")}
                  outline="true"
                  color="red"
                />
                <ButtonLink
                  page="/login"
                  name={t("Login")}
                  outline="false"
                  color="red"
                />
                <div
                  className="relative"
                  onClick={() => {
                    setShowChooseLng(!showChooseLng);
                  }}
                >
                  <div className="flex justify-center items-center cursor-pointer ">
                    <FaGlobe size={24} />
                    <img
                      className="w-10 h-5"
                      src={selectedLanguage[0].flag}
                      alt=""
                    />
                  </div>
                  <div
                    className={`${
                      showChooseLng
                        ? "mt-2 absolute bg-white text-main-color lg:right-0 shadow-lg"
                        : "hidden"
                    }`}
                  >
                    <ul className="divide-y divide-blue">
                      {languages.map((language, index) => (
                        <li
                          key={index}
                          className={`p-3 cursor-pointer hover:border-b-2 border-main-color ${
                            language.abbreviation === selectLng
                              ? "text-blue"
                              : ""
                          } hover:text-blue font-semibold`}
                          onClick={() => handleLang(language.abbreviation)}
                        >
                          {language.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="ml-4 flex items-center md:ml-6"></div>
          </div>
          <div className="-mr-2 flex lg:hidden">
            <button
              className="text-gray-800 hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
              onClick={() => setShowMobileNavBar((prevState) => !prevState)}
            >
              <svg
                width="20"
                height="20"
                fill="currentColor"
                className="h-8 w-8"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`${showMobileNavBar ? "lg:hidden" : "hidden"}`}>
        <div className="flex flex-col mx-auto space-y-2 py-3">
          <Link
            page="/previewpanda"
            indicator={props.pageLink}
            name="Preview panda"
          />
          <Link page="/whoisitfor" name="Who is it for?" />
          <Link page="/features" name="Features" />
          <Link page="/pricing" name="Pricing" />
          <Link page="/faq" name="FAQ" />
          <Link page="/teacherquiz" name="Quiz" />
          <div className="flex mx-auto space-x-4">
            <ButtonLink
              page="/enroll"
              name={t("Enroll")}
              outline="true"
              color="red"
            />
            <ButtonLink
              page="/login"
              name={t("Login")}
              outline="false"
              color="red"
            />
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Header;
