import { TabPanel, useTabs } from 'react-headless-tabs';
import { useHistory } from 'react-router-dom';
import { ParentPagesNavList } from '../../../../elements/sidebar/auth/Navlist';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion/dist/framer-motion'

import PageContent from '../../pageContent'
import {
    FeatureTitle,
  } from '../../../../elements/text';
  import {
    changeStudentDashboardMobileVisibility,
    changeStudentDashboardtab,
    changeStudentDashboardMenu,
  } from '../../../../../store/actions/sidebaractiontypes';
  import { ParentNavSubItem } from '../../../../elements/sidebar/auth/NavSubItem';
  import Spinner from '../../../../elements/spinner';
import { useState } from 'react';
import { MdOutlineArrowBackIosNew, MdOutlineNavigateNext } from 'react-icons/md';

  export const tabs = [
    {
      name: 'Recipients',
      tab: 'recipients',
      mainPanel: 'recipients',
      mainPath: '/teacher/communication/recipients',
      subTabs: [],
    },
    {
      name: 'Groups',
      tab: 'groups',
      mainPanel: 'groups',
      mainPath: '/teacher/communication/',
      subTabs: [
          {
              name: 'All',
              tabName: 'all',
              unread: 0,
              path: '/teacher/communication/',
            },
          {
              name: 'Teachers',
              tabName: 'teachers',
              unread: 1,
              path: '/teacher/communication/',
            },
          {
              name: 'School',
              tabName: 'school',
              unread: 0,
              path: '/teacher/communication/',
            },
      ],
    },
    {
      name: 'Emails',
      tab: 'emails',
      mainPanel: 'emails',
      mainPath: '/teacher/communication/',
      subTabs: [],
    },
    {
      name: 'SMS',
      tab: 'sms',
      mainPanel: 'sms',
      mainPath: '/teacher/communication/',
      subTabs: [],
    },
  ]; 

const CommunicationLayout = (props) => {
    const history = useHistory(); 
    const [toggle, setToggle] = useState(false);

    const studentdashboardMenuselected = useSelector(
      (state) => state.reducer.studentdashboardMenuselected
    );
    const [selectedTab, setSelectedTab] = useTabs(
      ['recipients', 'groups', 'emails', 'sms'],
      studentdashboardMenuselected
    );
    const studentDashboardPrograms = useSelector(
      (state) => state.studentDashboardPrograms
    );
    const changetab = (tab) => {
      dispatch(changeStudentDashboardtab(tab));
    };
    const dispatch = useDispatch();
  
  return (
    <PageContent>
    <div className="bg-white-blue flex h-full w-full relative">
            <div className="w-1/5 h-full relative bg-white shadow flex-col justify-between overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 hidden lg:block ">
              <div className="p-2">
                <div className="py-5 bg-white h-full rounded">
              <FeatureTitle name="Communication" />
              {(!studentDashboardPrograms.programs_loading) ? tabs.map((item, index) => (
                <ParentPagesNavList
                  key={index}
                  name={item.name}
                  color="blue"
                  isActive={selectedTab === item.tab}
                  subTabs={item.subTabs}
                  onClick={() => {
                    dispatch(changeStudentDashboardMenu(item.tab));
                    dispatch(changeStudentDashboardMobileVisibility('hidden'));
                    setSelectedTab(item.tab);
                    changetab(item.mainPanel);
                    if (item.subTabs.length === 0) {
                      history.push(item.mainPath);
                    }
                  }}
                >
                  <TabPanel
                    hidden={selectedTab !== item.tab}
                    className="transform transition duration-500 ease-in-out"
                  >
                    {item.subTabs.map((tab, index) => (
                      <ParentNavSubItem
                        key={index}
                        name={tab.name}
                        tab={tab.tabName}
                        color="blue"
                        new={tab.unread}
                        onClick={() => {
                          changetab(tab.tabName);
                          dispatch(changeStudentDashboardMobileVisibility('hidden'));
                          history.push(tab.path); 
                        }}
                      />
                    ))}
                  </TabPanel>
                </ParentPagesNavList>
              )) : <Spinner size="20" color="blue" />}
                </div>
              </div>
            </div>
            <div className='lg:hidden h-full'>
            {toggle ? '' : (
                <motion.div 
                whileInView={{ x: [-20 , 0] }} 
                whileHover={{ scale: 1.1 }}
                className="w-15 h-15 absolute bg-blue rounded-r-full"> 
                <MdOutlineNavigateNext color="white" size={30} onClick={() => setToggle(true)} />
                </motion.div>
                )}
            {toggle ? (
              <motion.div
              whileInView={{ x: [-100, 0] }}
              transition={{ duration: 0.85, ease:'easeOut'}}
              className="w-full h-full absolute"
              >
                <motion.div 
                  whileInView={{ x: [-20 , 0] }}
                  whileHover={{ scale: 1.1 }}
                  className="w-15 h-15 absolute bg-blue rounded-r-full"> 
                  <MdOutlineArrowBackIosNew color="white" size={30} onClick={() => setToggle(false)} className="p-2"/> 
                </motion.div>
                <div className="py-5 px-4 bg-white h-full rounded">
              <FeatureTitle name="Communication" />
              {(!studentDashboardPrograms.programs_loading) ? tabs.map((item, index) => (
                <ParentPagesNavList
                  key={index}
                  name={item.name}
                  color="blue"
                  isActive={selectedTab === item.tab}
                  subTabs={item.subTabs}
                  onClick={() => {
                    dispatch(changeStudentDashboardMenu(item.tab));
                    dispatch(changeStudentDashboardMobileVisibility('hidden'));
                    setSelectedTab(item.tab);
                    changetab(item.mainPanel);
                    if (item.subTabs.length === 0) {
                      setToggle(false);
                      history.push(item.mainPath);
                    }
                  }}
                >
                  <TabPanel
                    hidden={selectedTab !== item.tab}
                    className="transform transition duration-500 ease-in-out"
                  >
                    {item.subTabs.map((tab, index) => (
                      <ParentNavSubItem
                        key={index}
                        name={tab.name}
                        tab={tab.tabName}
                        color="blue"
                        new={tab.unread}
                        onClick={() => {
                          setToggle(false);
                          changetab(tab.tabName);
                          dispatch(changeStudentDashboardMobileVisibility('hidden'));
                          history.push(tab.path); 
                        }}
                      />
                    ))}
                  </TabPanel>
                </ParentPagesNavList>
              )) : <Spinner size="20" color="blue" />}
                </div>
                </motion.div>
            ): ''}
            </div> 
                 <div className="w-full h-full"> {props.children} </div>
        </div>
    </PageContent>
  )
}

export default CommunicationLayout