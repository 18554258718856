// Page.js
import React, { useState, useEffect } from 'react';
import RichTextEditor from './RichTextEditor';
import PageNavigation from './PageNavigation';
import SubmitButton from './SubmitButton';
import { Button } from '../../../elements/button';
import RadioOptions from './RadioOptions';
import axios from "../../../../axios-base";
import { useDispatch, useSelector } from "react-redux";
import Input from '../../../elements/input';
import Spinner from '../../../elements/spinner';
import { teacher_subjects_by_academic_units_no_cache_start, teacher_subjects_by_academic_units_start } from '../../../../store/actions/student.dashboard.curriculum.cbc';
const Page = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pageContent, setPageContent] = useState([...props.coursePages]); // Initial content for one page
    const [selectedOption, setSelectedOption] = useState(props.published);
    const user = useSelector((state) => state?.auth);
    const user_id = useSelector((state) => state?.auth?.user.id);
    const token = useSelector((state) => state?.auth?.token);
    const [unitName, setUnitName] = useState(props.summarynotes.chosenUnit);
    const [subject, setSubject] = useState(props.summarynotes.chosenSubject);
    const [academic_level, setAcademic_level] = useState(props.params.academic_level);
    const [courseName, setCourseName] = useState(props.courseName);
    const [classId, setClassId] = useState(props.classId);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const userAth = useSelector((state) => state.auth);






    const handleOptionChange = (option) => {
        setSelectedOption(option);
        // Perform any other actions upon option change if needed
    };

    const handleContentChange = (html) => {
        setPageContent((prevContent) => {
            const updatedContent = [...prevContent];
            updatedContent[currentPage] = html;
            return updatedContent;
        });
    };

    const handleNext = () => {
        if (currentPage < pageContent.length - 1) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleSubmit = () => {
        // Logic for submitting all page contents
        console.log('All page contents submitted:', pageContent);

    };

    useEffect(() => {
        const subject = props?.summmarynotes?.chosenSubject;
        const unitname = props?.summmarynotes?.chosenUnit;
        const academic_level = props?.params?.academic_level;
    }, []);

    const getAllImages = (htmls) => {
        const regex = /<img[^>]+src="([^">]+)"/g;
        const images = [];
        let match;
        for (var i = 0; i < htmls.length; i++) {
            const html = htmls[i];
            while ((match = regex.exec(html))) {
                const imageUrl = match[1];
                if (imageUrl.startsWith('data:image')) {
                    images.push(imageUrl);
                }
            }
        }
        return images;
    };



    // Function to convert base64 to Blob
    function base64ToBlob(base64Data) {
        const byteString = atob(base64Data.split(',')[1]);
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    // Function to upload base64 images as JPGs with enumerated names
    async function uploadImagesWithNames(images) {
        const uploadPromises = images.map(async (base64Data, index) => {
            const blob = base64ToBlob(base64Data);

            // Create a unique filename with timestamp and index
            const timestamp = Date.now();
            const fileName = `${timestamp}-summary_note_${Date.now()}_${index}.jpg`;

            // Create a FormData object and append the blob
            const formData = new FormData();
            formData.append('image', blob, fileName);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/opanda/uploadImages`, {
                    method: 'POST',
                    body: formData
                    // Add headers if needed, like authorization or content-type
                });

                if (response.ok) {
                    // Once the image is uploaded, return an object with base64 data and filename
                    return { base64Data, fileName };
                } else {
                    throw new Error('Upload failed');
                }
            } catch (error) {
                console.error('Error:', error);
                return null;
            }
        });

        return Promise.all(uploadPromises);
    }

    const uploadMyImages = async (images_data) => {
        // Call the function to upload images and save key-value pairs after upload
        setLoader(true);
        uploadImagesWithNames(images_data)
            .then(results => {
                const imageKeyValuePairs = {}; // Object to store image base64 and filenames

                results.forEach(result => {
                    // Process each result from the backend
                    if (result) {
                        imageKeyValuePairs[result.fileName] = [result.base64Data, result.fileName];

                    }
                });

                //--Replace all images
                const pageContentPool = pageContent;
                for (var i = 0; i < pageContentPool.length; i++) {
                    let newPageContent = pageContentPool[i];
                    Object.keys(imageKeyValuePairs).forEach(key => {
                        newPageContent = newPageContent.replaceAll(imageKeyValuePairs[key][0], `${process.env.REACT_APP_BACKEND_URL}/simulation_thumbnails/${key}`);
                    });
                    pageContentPool[i] = newPageContent;
                }
                const allPageContent = [...pageContentPool];
                setPageContent([...allPageContent]);
                if (courseName === '' || courseName === null || courseName === undefined || allPageContent[0] === '' || allPageContent[0] === null || allPageContent[0] === undefined) {
                    alert("Make sure you have course name and at least one page");
                    setLoader(false);
                    return;
                }
                axios
                    .post(
                        `/opanda/summarynotes/cbcsummarynotescreate`,
                        {
                            user_id: user_id,
                            unitName: unitName,
                            subject: subject,
                            academic_level: academic_level,
                            pagesData: allPageContent,
                            courseName: courseName

                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    )
                    .then((response) => {
                        dispatch(
                            teacher_subjects_by_academic_units_no_cache_start({
                                token: userAth.token,
                                subject: subject,
                                academic_level: academic_level,
                                user_id: user_id,
                            })
                        );
                        alert("Course created successfully");
                        if (response?.data?.data?.id) {
                            setClassId(response?.data?.data?.id);
                        } else {
                            setClassId(response.data.id);
                        }
                        setLoader(false);
                    })
                    .catch((err) => {
                    });



            })
            .catch(error => {
                console.error('Error:', error);
                setLoader(false);
                return {};
            });

    }

    const uploadMyImagesDraft = async (images_data) => {
        // Call the function to upload images and save key-value pairs after upload

        uploadImagesWithNames(images_data)
            .then(results => {
                const imageKeyValuePairs = {}; // Object to store image base64 and filenames

                results.forEach(result => {
                    // Process each result from the backend
                    if (result) {
                        imageKeyValuePairs[result.fileName] = [result.base64Data, result.fileName];

                    }
                });

                //--Replace all images
                const pageContentPool = pageContent;
                for (var i = 0; i < pageContentPool.length; i++) {
                    let newPageContent = pageContentPool[i];
                    Object.keys(imageKeyValuePairs).forEach(key => {
                        newPageContent = newPageContent.replaceAll(imageKeyValuePairs[key][0], `${process.env.REACT_APP_BACKEND_URL}/simulation_thumbnails/${key}`);
                    });
                    pageContentPool[i] = newPageContent;
                }
                const allPageContent = [...pageContentPool];
                setPageContent([...allPageContent]);
                if (courseName === '' || courseName === null || courseName === undefined || allPageContent[0] === '' || allPageContent[0] === null || allPageContent[0] === undefined) {

                    return;
                }
                axios
                    .post(
                        `/opanda/summarynotes/cbcsummarynotescreate`,
                        {
                            user_id: user_id,
                            unitName: unitName,
                            subject: subject,
                            academic_level: academic_level,
                            pagesData: allPageContent,
                            courseName: courseName

                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    )
                    .then((response) => {

                    })
                    .catch((err) => {
                    });



            })
            .catch(error => {
                console.error('Error:', error);
                setLoader(false);
                return {};
            });

    }

    const deleteCurrentPage = (currentPageToDelete) => {
        if (window.confirm("Are you sure you want to delete this page?")) {
            setPageContent((prevContent) => {
                const updatedContent = [...prevContent];
                updatedContent.splice(currentPageToDelete, 1);
                if (currentPageToDelete > 0) {
                    setCurrentPage((prevPage) => prevPage - 1);
                }
                return updatedContent;
            });
        }
    }

    const addNewPage = () => {
        setPageContent((prevContent) => {
            const updatedContent = [...prevContent, ""];
            if (currentPage < updatedContent.length - 1) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
            return updatedContent;
        });
    };
    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const clearCache = () => {
        dispatch(
            teacher_subjects_by_academic_units_no_cache_start({
                token: userAth.token,
                subject: subject,
                academic_level: academic_level,
                user_id: user_id,
            })
        );
    }




    return (
        (!loader) ? <div className="container mx-auto" style={{ display: 'grid', gridTemplateColumns: '90% 10%', zIndex: 1 }}>

            <div>
                <div className='w-70%'><Input
                    label="Course Name"
                    elementType="input"
                    elementConfig={{
                        type: "text",
                        placeholder: "Answer here",
                    }}
                    value={courseName}
                    changed={setCourseName}
                /></div>
                <div><PageNavigation pageCount={(pageContent.length === 0) ? 1 : pageContent.length} currentPage={currentPage} deleteCurrentPage={deleteCurrentPage} addNewPage={addNewPage} onNext={handleNext} onPrevious={handlePrevious} />
                    <RichTextEditor
                        handleContentChange={handleContentChange}
                        initialContent={pageContent[currentPage]}
                        content={pageContent[currentPage]}
                        key={1}
                    /></div>
            </div>
            <div className="mt-10">

                <Button
                    name="Save/Update"
                    outline="false"
                    color="green"
                    isSmall={true}
                    isSquare
                    clicked={async () => {
                        //console.log(process.env.REACT_APP_BACKEND_URL);
                        await uploadMyImages(getAllImages(pageContent))
                    }}
                />

                <div className='m-3'><RadioOptions key={getRandomNumber(1, 100)} classId={classId} selectedOption={selectedOption} onOptionChange={handleOptionChange} clearCache={clearCache} />
                </div>
            </div>
        </div> : <Spinner size="32" color="blue" />

    );
};

export default Page;
