import { useState } from 'react';
import Input from '../../../elements/input';
import { CardBody } from '../../../elements/text';
import PrevNext from '../enroll/PrevNext';
import countryOptions from '../../../../utils/countries';
import { Button } from '../../../elements/button';

const PageContent = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [location, setLocation] = useState('');
  const [school, setSchool] = useState('');
  const [classLevel, setClassLevel] = useState('');
  const [amount, setAmount] = useState('');
  const [whyApplying, setWhyApplying] = useState('');
  const [howWillHelp, setHowWillHelp] = useState('');
  const [checked, setChecked] = useState(false);

  return (
    <div className="bg-white-blue">
      <div className="md:w-80% m-auto">
        <PrevNext
          px="1"
          previousLink="pricing"
          previousName="Pricing Package"
          pageName="Apply for sponsorship"
        />
        <div className="py-5 px-2">
          <CardBody
            name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet lectus sit amet ex finibus, at eleifend enim lobortis. Donec egestas elementum mauris sit amet varius."
            color="black"
          />
        </div>
        <div className="px-2">
          <div className="md:grid grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-2">
            <Input
              label="First Name"
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Kevin',
              }}
              value={firstName}
              changed={setFirstName}
              validation={{ required: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Last Name"
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Kevin',
              }}
              value={lastName}
              changed={setLastName}
              validation={{ required: true }}
              shouldValidate
              error="Last Name is required"
            />
            <Input
              label="Gender"
              elementType="select"
              elementConfig={{
                startingValue: 'SELECT',
                options: [
                  { value: 'M', displayValue: 'Male' },
                  { value: 'F', displayValue: 'Female' },
                ],
              }}
              value={gender}
              changed={setGender}
              validation={{ required: true }}
              shouldValidate
              error="Gender is required"
            />
            <Input
              label="Phone Number"
              elementType="phone"
              value={phoneNumber}
              changed={setPhoneNumber}
            />
            <Input
              label="Country"
              elementType="select"
              elementConfig={{
                startingValue: 'SELECT',
                options: countryOptions,
              }}
              value={country}
              changed={setCountry}
              validation={{ required: true }}
              shouldValidate
              error="Country is required"
            />
            <Input
              label="Home Location"
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Home Location',
              }}
              value={location}
              changed={setLocation}
              validation={{ required: true }}
              shouldValidate
              error="Home Location is required"
            />
            <Input
              label="School"
              elementType="select"
              elementConfig={{
                startingValue: 'SELECT',
                options: [
                  { value: '1', displayValue: 'School 1' },
                  { value: '2', displayValue: 'School 2' },
                  { value: '3', displayValue: 'School 3' },
                  { value: '4', displayValue: 'School 4' },
                ],
              }}
              value={school}
              changed={setSchool}
              validation={{ required: true }}
              shouldValidate
              error="School is required"
            />
            <Input
              label="Class Level"
              elementType="select"
              elementConfig={{
                startingValue: 'SELECT',
                options: [
                  { value: '1', displayValue: 'Level 1' },
                  { value: '2', displayValue: 'Level 2' },
                  { value: '3', displayValue: 'Level 3' },
                  { value: '4', displayValue: 'Level 4' },
                  { value: '5', displayValue: 'Level 5' },
                  { value: '6', displayValue: 'Level 6' },
                ],
              }}
              value={classLevel}
              changed={setClassLevel}
              validation={{ required: true }}
              shouldValidate
              error="Class Level is required"
            />
            <Input
              label="How much can you afford to pay?"
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Amount in Rwf',
              }}
              value={amount}
              changed={setAmount}
            />
          </div>
          <Input
            label="Why are you applying for a financial aid?"
            elementType="textarea"
            elementConfig={{
              type: 'text',
              placeholder: '150 words minimum',
            }}
            value={whyApplying}
            changed={setWhyApplying}
            validation={{ required: true }}
            shouldValidate
            error="Minimum 150 words"
          />
          <Input
            label="How will using O'Genius Panda help your educational goals?"
            elementType="textarea"
            elementConfig={{
              type: 'text',
              placeholder: '150 words minimum',
            }}
            value={howWillHelp}
            changed={setHowWillHelp}
            validation={{ required: true }}
            shouldValidate
            error="Minimum 150 words"
          />
        </div>
        <div className="py-5 px-2">
          <CardBody
            name="By applying to this financial 
          sponsorship, you accept all terms of 
          use and commit yourself to using the
          platform regularly until the period of 
          the sponsorship is concluded"
            color="black"
          />
        </div>
        <div className="px-2 pb-5 flex items-center space-x-2">
          <input
            value={checked}
            onChange={(event) => setChecked(event.target.checked)}
            type="checkbox"
          />
          <CardBody
            name="All the information I provided is true"
            color="black"
          />
        </div>
        <div className="w-full flex justify-center md:justify-start pb-5">
          <Button name="Apply" outline="true" color="blue" />
        </div>
      </div>
    </div>
  );
};

export default PageContent;
