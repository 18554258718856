import { CardBody } from '../text';

const TabSelector = (props) => {
  return (
    <button onClick={props.onClick} className={props.isforMobile ? 'block md:hidden':''}>
      <CardBody
        name={props.title}
        color={props.color ? props.color: "blue"}
        additional={props.isActive ? 'font-bold' : ''}
      />
    </button>
  );
};
export default TabSelector;
