import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { MdWarning } from "react-icons/md";
import { CardSubText } from "./text";
import { useState } from "react";
import { checkValidity } from "../../utils/checkValidity";
import ReactSelect from "react-select";
import fs from "fs";
import { constants } from "buffer";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";

const Input = (props) => {
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);
  let inputElement = null;
  let inputClasses =
    "w-full outline-none border-px border-solid border-blue rounded focus:border-blue focus:ring-transparent focus:outline-0 focus:outline-transparent " +
    props.additional;
  let errorClasses = "hidden";
  if (!valid && props.shouldValidate && touched) {
    inputClasses =
      "w-full outline-none border-px border-solid border-red rounded focus:border-red focus:ring-transparent focus:outline-0 focus:outline-transparent ";
    errorClasses = "flex items-center text-red";
  }

  inputClasses.concat(
    "elementConfig" in props ? props?.elementConfig?.classes : ""
  );

  const onChangeHandler = (event) => {
    let value;
    if (props.elementType !== "fileupload") {
      if (event.target) {
        value = event.target.value;
      } else {
        value = event;
      }
    } else {
      value = event.target.files[0];
    }
    props.changed(value);
    if (props.shouldValidate) {
      try {
        setValid(checkValidity(value, props.validation));
        setTouched(true);
        //--
        if (props.isSubmitted === true && value === "") {
          setValid(checkValidity(value, { notEmpty: true }));
          setTouched(true);
        }
      } catch (err) { }
      //---
      try {
        props.load_modules(value);
      } catch (error) { }
      //--
      try {
        props.loadNewSubjectunits(value);
      } catch (error) { }

      try {
        props.getMultiselectedUnits(value);
      } catch (error) { }
      try {
        props.load_content(value);
      } catch (error) { }

      try {
        props.chooseAnotherSubject(value);
      } catch (error) {

      }
      
    }
    try {
      props.entryLimitUpdated(value);
    } catch (error) {

    }
    try {
      props.searchTagFilterUpdated(value);
    } catch (error) {

    }
    try {
      props.checkboxValuePick(value);
    } catch (error) {

    }
    try {
      props.setSelectedAcademiclevel(value);
    } catch (error) {

    }    
    try{
      props.setTheTightUrl(value);
    }catch(error){

    }
  };
  
  switch (props.elementType) {
    case "input":
      if (props.withIcon) {
        inputElement = (
          <div className="relative">
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              {props.children}
            </div>
            <input
              className={inputClasses}
              {...props.elementConfig}
              value={props.value}
              onChange={onChangeHandler}
              onClick={props.inputClicked}
              ref={props.ref}
            />
          </div>
        );
      } else {
        inputElement = (
          <input
            className={inputClasses}
            {...props.elementConfig}
            value={props.value}
            onChange={onChangeHandler}
            onClick={props.inputClicked}
            ref={props.ref}
          />
        );
      }
      break;

    case "textarea":
      inputElement = (
        <textarea
          className={inputClasses}
          {...props.elementConfig}
          value={props.value}
          onChange={onChangeHandler}
        />
      );
      break;
    case "checkbox":
      inputElement = (
        <input
          className={`${inputClasses} w-24px`}
          {...props.elementConfig}
          type="checkbox"
          checked={props.value}
          onChange={onChangeHandler}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className={inputClasses}
          value={props.value}
          onChange={onChangeHandler}
          disabled={props?.elementConfig?.disabled}
          ref={props.ref}
        >
          <option>{props?.elementConfig?.startingValue}</option>
          {(
            props?.elementConfig?.hasOwnProperty("optionsType")
              ? props?.elementConfig?.optionsType === "minimal"
              : false
          )
            ? props?.elementConfig?.options?.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))
            : props?.elementConfig?.options.map((option, index) => (
              <option
                value={
                  option.value ||
                  option?.abbreviatedname?.toLowerCase() ||
                  option.abbreviation ||
                  option.subject ||
                  option.id
                }
                key={index}
              >
                {option.displayValue ||
                  option.question ||
                  option.abbreviatedname ||
                  option.name ||
                  option.fullname ||
                  option.abbreviation ||
                  option.subject}
              </option>
            ))}
        </select>
      );
      break;
    case "selectschool":
      inputElement = (
        <CreatableSelect
          isClearable
          isDisabled={false}
          isLoading={false}
          className={inputClasses}
          onChange={props.changed}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e.id}
          onCreateOption={props.onCreateOption}
          options={props.elementConfig.options}
          value={props.value}
        />
      );
      break;
    case "multipleselect":
      inputElement = (
        <ReactSelect
          isMulti
          name="colors"
          options={props?.elementConfig?.options}
          onChange={onChangeHandler}
          className={`basic-multi-select w-full ${inputClasses}`}
          classNamePrefix="select"
          isDisabled={props?.elementConfig?.disabled}
        />
      );
      break;
    case "singleselect":
      inputElement = (
        <ReactSelect
          defaultValue={[...props.elementConfig.options]}
          name="colors"
          options={props.elementConfig.options}
          onChange={onChangeHandler}
          className={`basic-multi-select w-full ${inputClasses}`}
          classNamePrefix="select"
          disabled={props.elementConfig.disabled}
        />
      );
      break;
    case "phone":
      inputElement = (
        <PhoneInput
          className={inputClasses}
          placeholder="Enter phone number"
          international
          countryCallingCodeEditable={false}
          defaultCountry={props?.defaultCountry ? props.defaultCountry : "RW"} 
          country={props?.defaultCountry ? props.defaultCountry : "RW"} 
          value={props.value}
          onChange={(value = "") => onChangeHandler(value)}
          error={
            props.value
              ? isValidPhoneNumber(props.value)
                ? undefined
                : "Invalid phone number"
              : "Phone number required"
          }
        />
      );
      break;
    case "fileupload":
      inputElement = (
        <input
          className={inputClasses}
          {...props.elementConfig}

          onChange={onChangeHandler}
        />
      );
      break;
    default:
      inputElement = (
        <input
          className={inputClasses}
          {...props.elementConfig}
          value={props.value}
          onChange={onChangeHandler}
        />
      );
  }
  return (
    <div
      className={`w-full box-border mt-2 flex flex-col justify-center align-start`}
    >
      <CardSubText name={props.label} color="black" />
      {inputElement}
      <span className={errorClasses}>
        <MdWarning size="14" color="#BE1E2D" />
        <CardSubText name={props.error} color="red" alignment="center" />
      </span>
    </div>
  );
};

export default Input;
