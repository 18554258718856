import Layout from '../../../components/common/Layout';
import Details from '../../../components/pages/school/users/details';

const Users = () => {
  return (
    <Layout>
      <Details />
    </Layout>
  );
};
 
export default Users; 
