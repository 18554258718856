import { updateObject } from "../../../shared/utility";
import {
  ADD_MULTIPLE_QUESTION_OPTION,
  ADD_QUESTION,
  REMOVE_MULTIPLE_QUESTION_OPTION,
  CHANGE_MULTIPLE_QUESTION_OPTION,
  SET_QUESTION,
  SUBMIT_QUESTIONS,
  SET_MULTIPLE_QUESTION_OPTION,
  GET_TEACHER_TEST_MAPS_START,
  GET_TEACHER_TEST_MAPS_SUCCESS,
  GET_ONE_TEACHER_TEST_MAPS_SUCCESS,
  GET_TEACHER_TEST_MAPS_FAIL,
} from "../../actions/features/tests";

const initialReducer = {
  multipleQuestionOptions: [{ id: 0 }],
  currentQuestion: {},
  questions: [],
  testmaps: [],
  testmapsLoading: null,
  testmapsError: null,
  testmap: null,
};

const addMultipleQuestionOption = (state, action) => {
  return updateObject(state, {
    multipleQuestionOptions: state.multipleQuestionOptions.concat(
      action.payload
    ),
  });
};

const removeMultipleQuestionOption = (state, action) => {
  return updateObject(state, {
    multipleQuestionOptions: state.multipleQuestionOptions.filter(
      (option) => option.id !== action.payload
    ),
  });
};

const changeMultipleQuestionOption = (state, action) => {
  const newOptions = state.multipleQuestionOptions
    .filter((option) => option.id !== action.payload.id)
    .concat(action.payload);
  return updateObject(state, {
    multipleQuestionOptions: newOptions,
  });
};

const setCurrentQuestion = (state, action) => {
  return updateObject(state, {
    currentQuestion: action.payload,
  });
};

const addQuestion = (state, action) => {
  return updateObject(state, {
    questions: state.questions.concat(action.payload),
  });
};

const submitQuestions = (state, action) => {
  return updateObject(state, {
    questions: [],
  });
};

const setMultipleQuestionOptions = (state, action) => {
  return updateObject(state, {
    multipleQuestionOptions: action.payload,
  });
};

const getTeacherTestMapsStart = (state, action) => {
  return updateObject(state, {
    testmaps: [],
    testmapsError: null,
    testmapsLoading: true,
    testmap: null,
  });
};

const getTeacherTestMapsSuccess = (state, action) => {
  return updateObject(state, {
    testmaps: action.payload,
    testmapsError: null,
    testmapsLoading: false,
    testmap: null,
  });
};

const getOneTeacherTestMapsSuccess = (state, action) => {
  return updateObject(state, {
    testmaps: [],
    testmapsError: null,
    testmapsLoading: false,
    testmap: action.payload,
  });
};

const getTeacherTestMapsFail = (state, action) => {
  return updateObject(state, {
    testmaps: [],
    testmapsError: action.error,
    testmapsLoading: false,
    testmap: null,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case ADD_QUESTION:
      return addQuestion(state, action);
    case SET_QUESTION:
      return setCurrentQuestion(state, action);
    case ADD_MULTIPLE_QUESTION_OPTION:
      return addMultipleQuestionOption(state, action);
    case REMOVE_MULTIPLE_QUESTION_OPTION:
      return removeMultipleQuestionOption(state, action);
    case CHANGE_MULTIPLE_QUESTION_OPTION:
      return changeMultipleQuestionOption(state, action);
    case SET_MULTIPLE_QUESTION_OPTION:
      return setMultipleQuestionOptions(state, action);
    case SUBMIT_QUESTIONS:
      return submitQuestions(state, action);
    case GET_TEACHER_TEST_MAPS_START:
      return getTeacherTestMapsStart(state, action);
    case GET_TEACHER_TEST_MAPS_SUCCESS:
      return getTeacherTestMapsSuccess(state, action);
    case GET_ONE_TEACHER_TEST_MAPS_SUCCESS:
      return getOneTeacherTestMapsSuccess(state, action);
    case GET_TEACHER_TEST_MAPS_FAIL:
      return getTeacherTestMapsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
