import { updateObject } from '../../shared/utility';

import {
  COMPLETE_SIGNUP_ACTION_CLICKED,
  COMPLETE_SIGNUP_ACTION_START,
  COMPLETE_SIGNUP_ACTION_SUCCESS,
  COMPLETE_SIGNUP_ACTION_FAIL,
} from '../actions/completeSignUpActionTypes';

const completeSignUpItems = [
  {
    title: 'Personal Information',
    subTitle: 'Basic info about yourself',
    completed: false,
    selected: true,
  },
  {
    title: 'Recovery Information',
    subTitle: 'Regarding your account',
    completed: false,
    selected: false,
  },
  {
    title: 'Academic Information',
    subTitle: 'About your school and studies',
    completed: false,
    selected: false,
  },
  {
    title: 'Baseline Information',
    subTitle: 'About your previous academic achievement',
    completed: false,
    selected: false,
  },
  {
    title: 'Sales Information',
    subTitle: 'Billing information',
    completed: false,
    selected: false,
  },
];

const initialReducer = {
  completeSignUpItems: completeSignUpItems,
  error: null,
  loading: false,
  avatars: [
    { name: 'Asset 26.svg', imgSrc: '/images/avatars/Asset 26.svg' },
    { name: 'Asset 27.svg', imgSrc: '/images/avatars/Asset 27.svg' },
    { name: 'Asset 28.svg', imgSrc: '/images/avatars/Asset 28.svg' },
    { name: 'Asset 29.svg', imgSrc: '/images/avatars/Asset 29.svg' },
    { name: 'Asset 30.svg', imgSrc: '/images/avatars/Asset 30.svg' },
    { name: 'Asset 31.svg', imgSrc: '/images/avatars/Asset 31.svg' },
    { name: 'Asset 32.svg', imgSrc: '/images/avatars/Asset 32.svg' },
    { name: 'Asset 33.svg', imgSrc: '/images/avatars/Asset 33.svg' },
    { name: 'Asset 34.svg', imgSrc: '/images/avatars/Asset 34.svg' },
    { name: 'Asset 35.svg', imgSrc: '/images/avatars/Asset 35.svg' },
    { name: 'Asset 36.svg', imgSrc: '/images/avatars/Asset 36.svg' },
    { name: 'Asset 37.svg', imgSrc: '/images/avatars/Asset 37.svg' },
    { name: 'Asset 38.svg', imgSrc: '/images/avatars/Asset 38.svg' },
    { name: 'Asset 39.svg', imgSrc: '/images/avatars/Asset 39.svg' },
    { name: 'Asset 40.svg', imgSrc: '/images/avatars/Asset 40.svg' },
    { name: 'Asset 41.svg', imgSrc: '/images/avatars/Asset 41.svg' },
    { name: 'Asset 42.svg', imgSrc: '/images/avatars/Asset 42.svg' },
    { name: 'Asset 43.svg', imgSrc: '/images/avatars/Asset 43.svg' },
    { name: 'Asset 44.svg', imgSrc: '/images/avatars/Asset 44.svg' },
    { name: 'Asset 45.svg', imgSrc: '/images/avatars/Asset 45.svg' },
    { name: 'Asset 46.svg', imgSrc: '/images/avatars/Asset 46.svg' },
    { name: 'Asset 47.svg', imgSrc: '/images/avatars/Asset 47.svg' },
    { name: 'Asset 48.svg', imgSrc: '/images/avatars/Asset 48.svg' },
    { name: 'Asset 49.svg', imgSrc: '/images/avatars/Asset 49.svg' },
    { name: 'Asset 50.svg', imgSrc: '/images/avatars/Asset 50.svg' },
    { name: 'Asset 51.svg', imgSrc: '/images/avatars/Asset 51.svg' },
    { name: 'Asset 52.svg', imgSrc: '/images/avatars/Asset 52.svg' },
    { name: 'Asset 53.svg', imgSrc: '/images/avatars/Asset 53.svg' },
    { name: 'Asset 54.svg', imgSrc: '/images/avatars/Asset 54.svg' },
    { name: 'Asset 55.svg', imgSrc: '/images/avatars/Asset 55.svg' },
    { name: 'Asset 56.svg', imgSrc: '/images/avatars/Asset 56.svg' },
    { name: 'Asset 57.svg', imgSrc: '/images/avatars/Asset 57.svg' },
    { name: 'Asset 58.svg', imgSrc: '/images/avatars/Asset 58.svg' },
    { name: 'Asset 59.svg', imgSrc: '/images/avatars/Asset 59.svg' },
    { name: 'Asset 60.svg', imgSrc: '/images/avatars/Asset 60.svg' },
    { name: 'Asset 61.svg', imgSrc: '/images/avatars/Asset 61.svg' },
    { name: 'Asset 62.svg', imgSrc: '/images/avatars/Asset 62.svg' },
    { name: 'Asset 63.svg', imgSrc: '/images/avatars/Asset 63.svg' },
    { name: 'Asset 64.svg', imgSrc: '/images/avatars/Asset 64.svg' },
    { name: 'Asset 65.svg', imgSrc: '/images/avatars/Asset 65.svg' },
    { name: 'Asset 66.svg', imgSrc: '/images/avatars/Asset 66.svg' },
    { name: 'Asset 67.svg', imgSrc: '/images/avatars/Asset 67.svg' },
    { name: 'Asset 68.svg', imgSrc: '/images/avatars/Asset 68.svg' },
    { name: 'Asset 69.svg', imgSrc: '/images/avatars/Asset 69.svg' },
    { name: 'Asset 70.svg', imgSrc: '/images/avatars/Asset 70.svg' },
    { name: 'Asset 71.svg', imgSrc: '/images/avatars/Asset 71.svg' },
    { name: 'Asset 72.svg', imgSrc: '/images/avatars/Asset 72.svg' },
    { name: 'Asset 73.svg', imgSrc: '/images/avatars/Asset 73.svg' },
    { name: 'Asset 74.svg', imgSrc: '/images/avatars/Asset 74.svg' },
    { name: 'Asset 75.svg', imgSrc: '/images/avatars/Asset 75.svg' },
    { name: 'Asset 76.svg', imgSrc: '/images/avatars/Asset 76.svg' },
    { name: 'Asset 77.svg', imgSrc: '/images/avatars/Asset 77.svg' },
    { name: 'Asset 78.svg', imgSrc: '/images/avatars/Asset 78.svg' },
    { name: 'Asset 79.svg', imgSrc: '/images/avatars/Asset 79.svg' },
    { name: 'Asset 80.svg', imgSrc: '/images/avatars/Asset 80.svg' },
    { name: 'Asset 81.svg', imgSrc: '/images/avatars/Asset 81.svg' },
    { name: 'Asset 82.svg', imgSrc: '/images/avatars/Asset 82.svg' },
    { name: 'Asset 83.svg', imgSrc: '/images/avatars/Asset 83.svg' },
    { name: 'Asset 84.svg', imgSrc: '/images/avatars/Asset 84.svg' },
    { name: 'Asset 85.svg', imgSrc: '/images/avatars/Asset 85.svg' },
    { name: 'Asset 86.svg', imgSrc: '/images/avatars/Asset 86.svg' },
    { name: 'Asset 87.svg', imgSrc: '/images/avatars/Asset 87.svg' },
    { name: 'Asset 88.svg', imgSrc: '/images/avatars/Asset 88.svg' },
    { name: 'Asset 89.svg', imgSrc: '/images/avatars/Asset 89.svg' },
    { name: 'Asset 90.svg', imgSrc: '/images/avatars/Asset 90.svg' },
    { name: 'Asset 91.svg', imgSrc: '/images/avatars/Asset 91.svg' },
    { name: 'Asset 92.svg', imgSrc: '/images/avatars/Asset 92.svg' },
    { name: 'Asset 93.svg', imgSrc: '/images/avatars/Asset 93.svg' },
    { name: 'Asset 94.svg', imgSrc: '/images/avatars/Asset 94.svg' },
    { name: 'Asset 95.svg', imgSrc: '/images/avatars/Asset 95.svg' },
    { name: 'Asset 96.svg', imgSrc: '/images/avatars/Asset 96.svg' },
    { name: 'Asset 97.svg', imgSrc: '/images/avatars/Asset 97.svg' },
    { name: 'Asset 98.svg', imgSrc: '/images/avatars/Asset 98.svg' },
    { name: 'Asset 99.svg', imgSrc: '/images/avatars/Asset 99.svg' },
    { name: 'Asset 100.svg', imgSrc: '/images/avatars/Asset 100.svg' },
    { name: 'Asset 101.svg', imgSrc: '/images/avatars/Asset 101.svg' },
    { name: 'Asset 102.svg', imgSrc: '/images/avatars/Asset 102.svg' },
    { name: 'Asset 103.svg', imgSrc: '/images/avatars/Asset 103.svg' },
    { name: 'Asset 104.svg', imgSrc: '/images/avatars/Asset 104.svg' },
    { name: 'Asset 105.svg', imgSrc: '/images/avatars/Asset 105.svg' },
    { name: 'Asset 106.svg', imgSrc: '/images/avatars/Asset 106.svg' },
    { name: 'Asset 107.svg', imgSrc: '/images/avatars/Asset 107.svg' },
    { name: 'Asset 108.svg', imgSrc: '/images/avatars/Asset 108.svg' },
    { name: 'Asset 109.svg', imgSrc: '/images/avatars/Asset 109.svg' },
    { name: 'Asset 110.svg', imgSrc: '/images/avatars/Asset 110.svg' },
    { name: 'Asset 111.svg', imgSrc: '/images/avatars/Asset 111.svg' },
    { name: 'Asset 112.svg', imgSrc: '/images/avatars/Asset 112.svg' },
    { name: 'Asset 113.svg', imgSrc: '/images/avatars/Asset 113.svg' },
    { name: 'Asset 114.svg', imgSrc: '/images/avatars/Asset 114.svg' },
    { name: 'Asset 115.svg', imgSrc: '/images/avatars/Asset 115.svg' },
    { name: 'Asset 116.svg', imgSrc: '/images/avatars/Asset 116.svg' },
    { name: 'Asset 117.svg', imgSrc: '/images/avatars/Asset 117.svg' },
    { name: 'Asset 118.svg', imgSrc: '/images/avatars/Asset 118.svg' },
    { name: 'Asset 119.svg', imgSrc: '/images/avatars/Asset 119.svg' },
    { name: 'Asset 120.svg', imgSrc: '/images/avatars/Asset 120.svg' },
    { name: 'Asset 121.svg', imgSrc: '/images/avatars/Asset 121.svg' },
    { name: 'Asset 122.svg', imgSrc: '/images/avatars/Asset 122.svg' },
    { name: 'Asset 123.svg', imgSrc: '/images/avatars/Asset 123.svg' },
    { name: 'Asset 124.svg', imgSrc: '/images/avatars/Asset 124.svg' },
    { name: 'Asset 125.svg', imgSrc: '/images/avatars/Asset 125.svg' },
    { name: 'Asset 126.svg', imgSrc: '/images/avatars/Asset 126.svg' },
    { name: 'Asset 127.svg', imgSrc: '/images/avatars/Asset 127.svg' },
    { name: 'Asset 128.svg', imgSrc: '/images/avatars/Asset 128.svg' },
    { name: 'Asset 129.svg', imgSrc: '/images/avatars/Asset 129.svg' },
  ],
};

const filteredSignupItems = (arr, key, value) => {
  const newArray = [];
  for (let i = 0, arrLength = arr.length; i < arrLength; i++) {
    if (arr[i][key] !== value) {
      newArray.push(arr[i]);
    }
  }
  return newArray;
}

const completeSignupStart = (state, action) => {
  if (action?.payload?.isParent === true) {
    const newCompleteSignUpItems = filteredSignupItems(state.completeSignUpItems, "title", 'Academic Information')
    const newState = state;
    newState.completeSignUpItems = newCompleteSignUpItems;
    return updateObject(newState, {
      error: null,
      loading: true,
    });
  } else {
    return updateObject(state, {
      error: null,
      loading: true,
    });
  }
};

const completeSignupSuccess = (state, action) => {
  let indexOfCompleted = 0;
  const newCompleteSignUpItems = state.completeSignUpItems.map(
    (item, index) => {
      if (action.action === item.title) {
        indexOfCompleted = index;
      }
      return {
        title: item.title,
        subTitle: item.subTitle,
        completed: item.title === action.action ? true : item.completed,
        selected: false,
      };
    }
  );
  const indexOfNextItem = indexOfCompleted === state.completeSignUpItems.length - 1 ? 0 : indexOfCompleted + 1;
  newCompleteSignUpItems[indexOfNextItem].selected = true;
  return updateObject(state, {
    completeSignUpItems: newCompleteSignUpItems,
    error: null,
    loading: false,
  });
};

const completeSignupClicked = (state, action) => {
  const newCompleteSignUpItems = state.completeSignUpItems.map((item) => {
    return {
      title: item.title,
      subTitle: item.subTitle,
      completed: item.completed,
      selected: item.title === action.action ? true : false,
    };
  });
  return updateObject(state, {
    completeSignUpItems: newCompleteSignUpItems,
    error: null,
    loading: false,
  });
};

const completeSignupFail = (state, action) => {
  return updateObject(state, {
    completeSignUpItems: completeSignUpItems,
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case COMPLETE_SIGNUP_ACTION_START:
      return completeSignupStart(state, action);
    case COMPLETE_SIGNUP_ACTION_SUCCESS:
      return completeSignupSuccess(state, action);
    case COMPLETE_SIGNUP_ACTION_FAIL:
      return completeSignupFail(state, action);
    case COMPLETE_SIGNUP_ACTION_CLICKED:
      return completeSignupClicked(state, action);
    default:
      return state;
  }
};
export default reducer;
