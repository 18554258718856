import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getTestAlgoV2AnswersStart = () => {
  return {
    type: actionTypes.GET_TEST_ALGOV2_USER_ANSWERS_START,
  };
};

export const getTestAlgoV2AnswersSuccess = (algov2answers) => {
  return {
    type: actionTypes.GET_TEST_ALGOV2_USER_ANSWERS_SUCCESS,
    algov2answers: algov2answers,
  };
};

export const getOneTestAlgoV2AnswersSuccess = (algov2answer) => {
  return {
    type: actionTypes.GET_ONE_TEST_ALGOV2_USER_ANSWERS_SUCCESS,
    algov2answer: algov2answer,
  };
};

export const getTestAlgoV2AnswersFail = (error) => {
  return {
    type: actionTypes.GET_TEST_ALGOV2_USER_ANSWERS_FAIL,
    error: error,
  };
};

export const getTestAlgoV2Answers = () => {
  return (dispatch) => {
    dispatch(getTestAlgoV2AnswersStart());
    axios
      .get(`/opanda/panda-test-algov2-users-answers`)
      .then((response) => {
        dispatch(getTestAlgoV2AnswersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestAlgoV2AnswersFail(err.message));
      });
  };
};

export const submitTestAlgoV2UsersAnswers = (object) => {
  return (dispatch) => {
    dispatch(getTestAlgoV2AnswersStart());
    axios
      .post(
        `/opanda/panda-test-algov2-users-answers/submit-algov2-users-answers`,
        object
      )
      .then((response) => {
        dispatch(getTestAlgoV2AnswersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestAlgoV2AnswersFail(err.message));
      });
  };
};
