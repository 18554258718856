import { Button } from '../../elements/button';
import Input from '../../elements/input';
import { useState } from 'react';

const Paypal = () => {
  const [email, setEmail] = useState('');
  return (
    <div className="p-2">
      <Input
        elementType="input"
        elementConfig={{
          type: 'email',
          placeholder: 'Email',
        }}
        value={email}
        changed={setEmail}
        validation={{ isEmail: true }}
        shouldValidate
        error="Valid email is required"
      />
      <Button name="Buy" outline="true" color="blue" additional="my-2" />
    </div>
  );
};

export default Paypal;
