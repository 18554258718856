import Settings from '../../../components/pages/student/user/settings/index';
import Layout from '../../../components/common/Layout';

const StudentSettingsContainer = () => {
  return (
    <Layout>
      <Settings />
    </Layout>
  );
};

export default StudentSettingsContainer;