import { useState } from "react";
import PageContent from "../pageContent";
import { CardBody } from "../../../elements/text";
import { Button, GoBackButton } from "../../../elements/button";
import Input from "../../../elements/input";
import MarkQuestion from "../../../common/features/tests/MarkQuestion";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

const testingAnswers = [
  {
    type: "Multiple question",
    marks: 2,
    duration: 2,
    question: "<p>This is just for testing the question ?</p>",
    explanation: "<p>My explanation goes here</p>",
    options: [
      { value: "option 1" },
      { value: "option 2", isSelected: true },
      { value: "option 3" },
      { value: "option 4" },
    ],
  },
  {
    type: "Complete sentence",
    marks: "2",
    duration: "2",
    question: "<p>Testing complete sentence question</p>",
    answer: "<p>Testing complete</p>",
  },
  {
    type: "Open ended",
    marks: "2",
    duration: "2",
    question: "<p>Testing open-ended question ?</p>",
    explanation: "<p>my explanation here</p>",
    answer: "<p>This is the provided answer</p>",
  },
  {
    type: "Drag and drop",
    marks: "2",
    duration: "2",
    question: "<p>Testing drag and drop</p>",
    answer: "<p>Testing drag drop and</p>",
  },
];

const MarkTest = () => {
  const [level, setLevel] = useState("S1");
  const [subject, setSubject] = useState("");
  const [unit, setUnit] = useState("");

  const [showSelectBox, setShowSelectBox] = useState(true);
  const questions = testingAnswers;

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full md:h-full min-h-screen-sec`}>
        {showSelectBox ? (
          <>
            <div className="p-3 bg-gray-200 md:h-full col-span-4 border-l border-gray-500">
              <div>
                <CardBody
                  name="Select a subject, academic level and unit to start browser through question bank"
                  alignment="center"
                />
                <div className="flex flex-col md:flex-row md:justify-between md:items-center md:space-x-2">
                  <Input
                    label="Subject"
                    elementType="select"
                    elementConfig={{
                      startingValue: "SELECT",
                      options: [
                        { value: "Biology", displayValue: "Biology" },
                        { value: "Chemistry", displayValue: "Chemistry" },
                        { value: "Physics", displayValue: "Physics" },
                        { value: "Math", displayValue: "Math" },
                        { value: "Geography", displayValue: "Geography" },
                      ],
                    }}
                    value={subject}
                    changed={setSubject}
                    validation={{ required: true }}
                    shouldValidate
                    error="Subject is required"
                  />
                  <Input
                    label="Level"
                    elementType="select"
                    elementConfig={{
                      startingValue: "SELECT",
                      options: [
                        { value: "S1", displayValue: "Senior 1" },
                        { value: "S2", displayValue: "Senior 2" },
                        { value: "S3", displayValue: "Senior 3" },
                        { value: "S4", displayValue: "Senior 4" },
                        { value: "S5", displayValue: "Senior 5" },
                        { value: "S6", displayValue: "Senior 6" },
                      ],
                    }}
                    value={level}
                    changed={setLevel}
                    validation={{ required: true }}
                    shouldValidate
                    error="Level is required"
                  />
                </div>
              </div>
              <Input
                label="Unit"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [
                    { value: "Unit 1", displayValue: "Unit 1" },
                    { value: "Unit 2", displayValue: "Unit 2" },
                    { value: "Unit 3", displayValue: "Unit 3" },
                    { value: "Unit 4", displayValue: "Unit 4" },
                  ],
                }}
                value={unit}
                changed={setUnit}
                validation={{ required: true }}
                shouldValidate
                error="Unit is required"
              />
              <Button
                name="Find"
                outline="true"
                color="blue"
                additional="my-5"
                clicked={() => {}}
              />
            </div>
          </>
        ) : (
          ""
        )}
        <div
          className={`${
            showSelectBox ? "hidden md:block" : ""
          } md:relative p-3 bg-white md:h-full h-screen-second mb-16 pb-16 md:max-h-screen-sec col-span-8 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <GoBackButton
            link="/teacher/features/tests"
            action="Back"
            location="Tests & Assessments"
          />
          {questions.map((question, index) => (
            <MarkQuestion {...question} questionNumber={index + 1} />
          ))}
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${
            showSelectBox ? "block" : ""
          } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSelectBox ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default MarkTest;
