import Profile from '../../components/pages/parent/complete/profile';
import Layout from '../../components/common/Layout';

const ParentProfileContainer = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};

export default ParentProfileContainer;