import { useState } from "react";
import Input from "../../../elements/input";
import PageContent from "../pageContent";
import { useTabs } from "react-headless-tabs";
import { FeatureTitle } from "../../../elements/text";
import { TabPanel } from "react-headless-tabs";
import NotFound from "../../../common/features/NotFound";
// import { useHistory } from "react-router-dom";
import TabSelector from "../../../elements/tab/TabSelector";
import TestsGroupItems from "./TestsGroupItems";
import { Button } from "../../../elements/button";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { student_dashboard_homepage_tests_notifs_start } from "../../../../store/actions/student.dashboard.tests";
import { getAllTests } from "../../../../functions/getAllTests";
import Spinner from "../../../elements/spinner";
import { student_dashboard_homepage_tests_subjects_units_start } from "../../../../store/actions/student.dashboard.tests.subjects.units";
import Modal from "../../../elements/modal/modal";
import AutoTestForm from "./autotestform";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";

const Tests = () => {
  // const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState("S1");
  const [showState, setShowState] = useState(false);
  const [filterBy, setFilterBy] = useState("due");
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const studentDashboardTests = useSelector(
    (state) => state.studentDashboardTests
  );

  const [showSelectBox, setShowSelectBox] = useState(true);
  useEffect(() => {
    //---Get all in app activities
    dispatch(
      student_dashboard_homepage_tests_notifs_start({
        academic_level: userAth.academic_level,
      })
    );
    //----Get Subjects and units--
    dispatch(
      student_dashboard_homepage_tests_subjects_units_start({
        academic_level: userAth.academic_level,
      })
    );
    setLevel(userAth.academic_level);
  }, []);
  //---
  const tests = getAllTests(
    studentDashboardTests.dashboard_homepage_tests_notifs
  );

  // const tests1 = [
  //   {
  //     title: "Test title",
  //     level: 1,
  //     unit: "Unit 3",
  //     imgsrc: "/images/preview/bio.svg",
  //     type: "Test",
  //     due: "Today",
  //     isMyTest: true,
  //     subject: "Biology",
  //   },
  //   {
  //     title: "Test title",
  //     level: 2,
  //     unit: "Unit 4",
  //     imgsrc: "/images/preview/math.svg",
  //     type: "Quiz",
  //     due: "Tomorrow",
  //     isMyTest: true,
  //     subject: "Math",
  //   },
  //   {
  //     title: "Test title",
  //     level: 1,
  //     unit: "Unit 1",
  //     imgsrc: "/images/preview/bio.svg",
  //     type: "Test",
  //     due: new Date(
  //       new Date().getTime() + 24 * 60 * 60 * 1000 * 2
  //     ).toLocaleDateString(undefined, {
  //       year: "numeric",
  //       month: "long",
  //       day: "numeric",
  //     }),
  //     isMyTest: true,
  //     subject: "Biology",
  //   },
  //   {
  //     title: "Test title",
  //     level: 3,
  //     unit: "Unit 1",
  //     imgsrc: "/images/preview/bio.svg",
  //     type: "Test",
  //     due: "Today",
  //     isMyTest: false,
  //     subject: "Math",
  //   },
  //   {
  //     title: "Test title",
  //     level: 1,
  //     unit: "Unit 1",
  //     imgsrc: "/images/preview/bio.svg",
  //     type: "Quiz",
  //     due: "Tomorrow",
  //     isMyTest: false,
  //     subject: "Biology",
  //   },
  //   {
  //     title: "Test title",
  //     level: 1,
  //     unit: "Unit 1",
  //     imgsrc: "/images/preview/math.svg",
  //     type: "Quiz",
  //     due: new Date(
  //       new Date().getTime() + 24 * 60 * 60 * 1000 * 2
  //     ).toLocaleDateString(undefined, {
  //       year: "numeric",
  //       month: "long",
  //       day: "numeric",
  //     }),
  //     isMyTest: false,
  //     subject: "Physics",
  //   },
  //   {
  //     title: "Test title",
  //     level: 5,
  //     unit: "Unit 2",
  //     imgsrc: "/images/preview/bio.svg",
  //     type: "Test",
  //     due: "Today",
  //     isMyTest: true,
  //     subject: "Biology",
  //   },
  //   {
  //     title: "Test title",
  //     level: 4,
  //     unit: "Unit 3",
  //     imgsrc: "/images/preview/bio.svg",
  //     type: "Test",
  //     due: "Today",
  //     isMyTest: false,
  //     subject: "Biology",
  //   },
  //   {
  //     title: "Test title",
  //     level: 4,
  //     unit: "Unit 3",
  //     imgsrc: "/images/preview/bio.svg",
  //     type: "Quiz",
  //     due: "Tomorrow",
  //     isMyTest: true,
  //     subject: "Physics",
  //   },
  //   {
  //     title: "Test title",
  //     level: 6,
  //     unit: "Unit 1",
  //     imgsrc: "/images/preview/math.svg",
  //     type: "Test",
  //     due: new Date(
  //       new Date().getTime() + 24 * 60 * 60 * 1000 * 2
  //     ).toLocaleDateString(undefined, {
  //       year: "numeric",
  //       month: "long",
  //       day: "numeric",
  //     }),
  //     isMyTest: true,
  //     subject: "Math",
  //   },
  //   {
  //     title: "Test title",
  //     level: 6,
  //     unit: "Unit 1",
  //     imgsrc: "/images/preview/bio.svg",
  //     type: "Quiz",
  //     due: "Today",
  //     isMyTest: false,
  //     subject: "Biology",
  //   },
  // ];

  const [selectedTab, setSelectedTab] = useTabs(
    ["Auto tests", "My tests", "Community"],
    "My tests"
  );

  return (
    <PageContent>
      <Modal small show={showState} modalClosed={() => setShowState(false)}>
        <p>One of the fields is empty!!??</p>
        <Button>Ok</Button>
      </Modal>
      <div className={`md:grid grid-cols-12 w-full md:h-full min-h-screen-sec`}>
        {/* <AutoTestForm /> */}
        {showSelectBox ? <AutoTestForm /> : ""}
        <div
          className={`${
            showSelectBox ? "hidden md:block" : ""
          } md:relative p-3 bg-white md:h-full h-screen-second mb-16 pb-16 md:max-h-screen-sec col-span-8 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <FeatureTitle name="Tests & Assessments" />
          {!studentDashboardTests.dashboard_homepage_tests_notifs_loading ? (
            <>
              <div className="md:flex justify-between items-center">
                <nav className="flex justify-start space-x-4">
                  <TabSelector
                    isActive={selectedTab === "My tests"}
                    title="My tests"
                    onClick={() => setSelectedTab("My tests")}
                  />
                  <TabSelector
                    isActive={selectedTab === "Community"}
                    title="Community"
                    onClick={() => setSelectedTab("Community")}
                  />
                  <TabSelector
                    title="Practice"
                    onClick={() => setShowSelectBox(!showSelectBox)}
                    color="red"
                    isActive={true}
                    isforMobile={true}
                  />
                </nav>
                <div className="md:w-1/4">
                  <Input
                    elementType="select"
                    elementConfig={{
                      startingValue: "SELECT",
                      options: [
                        { value: "due", displayValue: "Filter By Due date" },
                        { value: "subject", displayValue: "Filter By Subject" },
                        { value: "type", displayValue: "Filter By Type" },
                      ],
                    }}
                    value={filterBy}
                    changed={setFilterBy}
                    validation={{ required: true }}
                    shouldValidate
                    error="Invalid filter"
                  />
                </div>
              </div>

              <TabPanel hidden={selectedTab !== "My tests"}>
                {tests.filter((test) => test.isMyTest).length === 0 ? (
                  <NotFound feature="tests" />
                ) : (
                  <TestsGroupItems
                    filterBy={filterBy}
                    tests={tests.filter((test) => test.isMyTest)}
                  />
                )}
              </TabPanel>
              <TabPanel hidden={selectedTab !== "Community"}>
                {tests.filter((test) => !test.isMyTest).length === 0 ? (
                  <NotFound feature="tests" />
                ) : (
                  <TestsGroupItems
                    filterBy={filterBy}
                    tests={tests.filter((test) => !test.isMyTest)}
                  />
                )}
              </TabPanel>
            </>
          ) : (
            <Spinner size="12" color="blue" />
          )}
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${
            showSelectBox ? "block" : ""
          } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSelectBox ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden animate-pulse"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Tests;
