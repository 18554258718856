import { Button, TextButton } from "../../../../../elements/button";
import Table from "../../../../../elements/table/table";
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
  SmallText,
} from "../../../../../elements/text";
import PageContent from "../../../pageContent";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PrevNext from "../../../../landing/enroll/PrevNext";
import Modal from "../../../../../elements/modal/modal";
import { useHistory } from "react-router-dom";
// import { PaymentOptionsCard } from "../../../../elements/card";
import {
  setChosenPackage,
  changePaymentModalState,
} from "../../../../../../store/actions/payment";
import { school_dashboard_extra_package_start } from "../../../../../../store/actions/extrapackage";

const Payment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableheader1 = ["Name", "Amount", "Description", "Action"];
  const activePackagePrice = useSelector(
    (state) => state.auth.user.activepackage[0]?.panda_user_payments_amount_paid
  );
  const activePackageId = useSelector(
    (state) => state.auth.user.activepackage[0].panda_user_payments_id
  );
  const userId = useSelector((state) => state.auth.userId);

  const extrapackages = useSelector((state) => state.auth.user.extras);
  const chosenProgram = useSelector((state) => state.payment.chosenProgram);
  const chosenPackage = useSelector((state) => state.payment.chosenPackage);
  const showPaymentModal = useSelector(
    (state) => state.payment.showPaymentModal
  );
  const currentLevel = useSelector((state) => state.auth.user.academic_level);
  const whoisitfor = useSelector((state) => state.packageExtras.extras);
  const [tbody1, setTBody1] = useState([]);
  const tablebody1Ref = useRef([]);

  useEffect(() => {
    dispatch(school_dashboard_extra_package_start(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    for (var i = 0; i < whoisitfor?.length; i++) {
      if (whoisitfor[i]?.usertype?.toLowerCase() === "student") {
        tablebody1Ref.current = [];
        if (whoisitfor[i].packages) {
          Object.values(whoisitfor[i].packages)?.forEach((value, index) => {
            
            if (value.level != currentLevel) {
              // console.log(extrapackages, value.level);
              let bool;
              for(let j = 0; j < extrapackages.length; j++) {
                console.log("level__",extrapackages[j].level);
                if(extrapackages[j].level !== value.level){
                  bool = true;
                }else{
                  bool = false;
                }
              }
              if(bool){
                const { key1, ...rest } = value;
                const updatedValue = {
                  ...rest,
                  amount: activePackagePrice * value.discount_percentage,
                  activepackageid: activePackageId,
                  pricingname: value.name,
                  pricingcurrency: value.currency,
                  pricingfrequency: value.frequency,
                  totalMonthlyPlan:
                    activePackagePrice * value.discount_percentage,
                };
                const actionbutton = (
                  <TextButton
                    name="Purchase"
                    color="blue"
                    clicked={() => {
                      dispatch(
                        setChosenPackage({
                          ...updatedValue,
                          number: index + 1,
                        })
                      );
                      dispatch(changePaymentModalState(true));
                    }}
                  />
                );
                tablebody1Ref.current.push({
                  td1: { data: updatedValue.name, type: "text" },
                  td2: {
                    data:
                      updatedValue.amount +
                      " " +
                      updatedValue.currency +
                      " " +
                      updatedValue.frequency,
                    type: "text",
                  },
                  td4: { data: updatedValue.features, type: "text" },
                  td6: {
                    data: <>{actionbutton}</>,
                    type: "component",
                  },
                });
              }
            }
          });
          setTBody1(tablebody1Ref.current);
        }
      }
    }
  }, [
    activePackageId,
    activePackagePrice,
    currentLevel,
    dispatch,
    extrapackages,
    whoisitfor,
  ]);

  // console.log("Rendering...");
  return (
    <PageContent>
      <Modal
        medium
        show={showPaymentModal}
        modalClosed={() => {
          dispatch(setChosenPackage(null));
          dispatch(changePaymentModalState(false));
        }}
      >
        <div className="w-80% m-auto py-5">
          <SectionTitle name="Here's what you selected" alignment="center" />
          <div className="flex justify-between items-center py-3">
            <div className="text-center">
              <CardTitle name={chosenProgram} color="blue" />
              <div className="flex space-x-2">
                <CardTitle name={`Package ${chosenPackage?.number}`} />
                <CardBody name={chosenPackage?.name} />
              </div>
              <div className="flex space-x-2">
                <CardTitle
                  name={`${
                    chosenPackage?.amount
                  } ${chosenPackage?.currency?.toUpperCase()}`}
                />
                <CardBody
                  name={
                    chosenPackage?.frequency
                      ? chosenPackage?.pricingfrequency
                      : chosenPackage?.frequency
                  }
                />
              </div>
            </div>
            <div className="flex flex-col">
              {chosenPackage?.features?.split(",").map((feature, index) => (
                <SmallText name={feature} color="blue" key={index} />
              ))}
            </div>
          </div>
          <div className="flex justify-around">
            <Button
              name={"Cancel"}
              outline="true"
              color="blue"
              clicked={() => {
                dispatch(setChosenPackage(null));
                dispatch(changePaymentModalState(false));
              }}
            />
            <Button
              name={"Continue"}
              outline="false"
              color="blue"
              clicked={() =>
                history.push("/student/user/payment/extras/options")
              }
            />
          </div>
        </div>
      </Modal>

      <div className="bg-white-blue md:flex w-full">
        <div className="w-full p-3 bg-white-blue">
          <FeatureTitle name="Payment" alignment="right" />
          <div className="relative overflow-x-auto sm:rounded-lg my-4">
            <PrevNext
              px="5"
              previousLink="/student/user/payment"
              previousName="Payment"
              pageName="Extra packages"
            />
            <div className="flex flex-row justify-between items-center">
              <CardTitle name="Extras subscription" color="blue" />{" "}
              {/* <PrevNext px="5" pageName=" " showProgram="false" /> */}
            </div>
            <Table thead={tableheader1} tbody={tbody1} />
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Payment;
