import { useState } from 'react';
import PageContent from "../pageContent";
import Notification from "../../../elements/notification/notification";
import { useHistory } from "react-router-dom";
import {
  CardTitle,
  FeatureTitle,
  CardBody,
} from "../../../elements/text";
import Table, {PaymentsTable} from "../../../elements/table/table";
import { Button, TextButton } from "../../../elements/button";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { PopupWindow } from '../../../elements/popupWindow';

const RenewPayment = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const sponsoredAccountsHeader = ["Name", "Package", "Status", "Duration", "Action"];
  const paymentsHeader = ["Duration", "Package", "status", "Token", "Action"];
  const users = [
    {
      id: 1,
      name: "Child Name",
      type: "Daily",
      status: "linked",
      addedon: "120 days",
    },
    {
      id: 2,
      name: "Child Name",
      type: "Basic",
      status: "linked",
      addedon: "20 days",
    },
    {
      id: 3,
      name: "Child Name",
      type: "Basic",
      status: "linked",
      addedon: "20 days",
    },
    {
      id: 4,
      name: "MANZI Kevin",
      type: "Student",
      status: "pending",
      linkedon: "a week ago",
    },
  ];
  const sponsoredAccounts = [
    {
      id: 1,
      name: "Child Name",
      type: "Daily",
      status: "Active",
      addedon: "120 days",
    },
    {
      id: 2,
      name: "Child Name",
      type: "Basic",
      status: "Halted",
      addedon: "20 days",
    },
    {
      id: 3,
      name: "Child Name",
      type: "Basic",
      status: "Active",
      addedon: "20 days",
    },
  ];



  const allPayments = [
    {
      id: 1,
      duration: '30 days',
      package: 'School',
      status: 'USED',
      token: 'TOKEN_FGhjK',
    },
    {
      id: 2,
      duration: '10 days',
      package: 'BASIC',
      status: 'USED',
      token: 'TOKEN_FGhjK',
    },
    {
      id: 3,
      duration: '90 days',
      package: 'BASIC',
      status: 'NOT USED',
      token: 'TOKEN_FGhjK',
    },
  ]

  const sponsoredAccountsData = [];
  const allPaymentsData = [];

  Object.values(sponsoredAccounts.filter((user) => user.status !== "")).forEach(
    (value) => {
      const activateButton = (
        <>
          <TextButton
            name="Activate"
            color="blue"
            clicked={() => {
              history.push(`/parent/renewpayment/${keys[0]}`); 
            }}
          />
        </>
      );
      const haltButton = (
        <>
          <TextButton
            name="Halt"
            color="red"
            clicked={() => {
              history.push(`/parent/renewpayment/${keys[0]}`); 
            }}
          />
        </>
      );
      
      const keys = Object.values(value);
      sponsoredAccountsData.push({
        td1: { data: keys[1], type: "text" },
        td2: { data: keys[2], type: "text" },
        td3: { data: keys[3], type: "text" },
        td4: { data: keys[4], type: "text" },
        td5: {
          data: <>{activateButton} | {haltButton}</>,
          type: "component",
         },
      });
    }
  );

  Object.values(allPayments.filter((user) => user.status !=="")).forEach(
    (value) => {
      const keys = Object.values(value);
      const activateButton = (
        <>
          <TextButton
            name="Activate"
            color="blue"
            clicked={() => {
              history.push(`/parent/renewpayment/${keys[0]}`); 
            }}
          />
        </>
      );
      allPaymentsData.push({
        td1: { data: keys[1], type: "text" },
        td2: { data: keys[2], type: "text" },
        td3: { data: keys[3], type: "text" },
        td4: { data: keys[4], type: "text" },
        td5: {
          data: <>{activateButton}</>,
          type: "component",
        },
      });
    }
  );

  return (
    <PageContent>
      <div
        className={`flex bg-white-blue  w-full h-full overflow-x-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full w-full lg:w-80%`}>
          <FeatureTitle name="Renew payment" />
          <div className="flex justify-end items-right p-3 space-y-4">
              <Button
                name="Add package"
                outline="false"
                color="blue"
                isSquare
                clicked={() => {
                  history.push(`/parent/renewpayment/addpackage`);
              }}
              />
          </div>
          <div className="space-y-4">
            <CardBody name="Sponsored accounts" color="blue" />
            <div className="overflow-x-auto rounded-lg mb-2 h-40">
              {sponsoredAccountsData.length > 0 ? (
                <PaymentsTable thead={sponsoredAccountsHeader} tbody={sponsoredAccountsData} />
                ):(
                  ''
                )}
            </div>

            <CardBody name="Payments" color="blue" />
            <div className="overflow-x-auto rounded-lg mb-2 h-40">
            {allPayments.length > 0 ? (
              <PaymentsTable thead={paymentsHeader} tbody={allPaymentsData} />
              
            ):(
                  ''
                )}
            </div>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default RenewPayment;
