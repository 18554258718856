import { useState } from 'react';
import PageContent from '../pageContent';
import { FeatureTitle } from '../../../elements/text';
import { Button, GoBackButton } from '../../../elements/button';
import Input from '../../../elements/input';
import Page from './Page';
import { useDispatch, useSelector } from "react-redux";

const NewBook = () => {
  // Set book pages
  const [bookPages, setBookPages] = useState([]);

  const teacherDashboardLibrary = useSelector((state) => state.teacherDashboardLibrary);



  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  return (
    <PageContent>
      <div className={`w-full h-full`}>
        <div
          className={`md:relative p-3 bg-white h-full min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <FeatureTitle name="Library" />
          <GoBackButton
            link="/teacher/features/library"
            action="Back"
            location=""
          />
          {/* <div className="flex justify-between items-center">
            <div>
              <Input
                label="Chapter Name"
                elementType="input"
                elementConfig={{
                  type: 'text',
                  placeholder: 'Enter Chapter name',
                }}
                value={chapterName}
                changed={setChapterName}
              />
            </div>
          </div> */}
          <div className="p-5">
            <Page key={getRandomNumber(1, 100)} teacherDashboardLibrary={teacherDashboardLibrary} bookPages={bookPages}  published={"unpublished"} />


          </div>
          <div className="flex space-x-5">
            {bookPages.map((page, index) => (
              <Button
                name={index + 1}
                outline="false"
                color="blue"
                isSquare
                clicked={() => { }}
              />
            ))}

          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default NewBook;
