// PurchasedBooks
import React from 'react'
import Layout from '../../../components/common/Layout';
import { PurchasedBooks } from '../../../components/pages/parent/marketplace';


const PurchasedBooksContainer = () => {
  return (
  <Layout>
    <PurchasedBooks />
  </Layout>
  )
}

export default PurchasedBooksContainer