import { ContentContainer } from "../../../common/content/container";
import Days from "../../../elements/days/days";
import {
  CardBody,
  CardTitle,
  SectionTitle,
  CardSubText,
  FeatureTitle,
} from "../../../elements/text";
import Notification from "../../../elements/notification/notification";
import Item from "./item";
import { ImLab } from "react-icons/im";
import { BiBook } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FaCalendarAlt } from "react-icons/fa";
import { BsCardList } from "react-icons/bs";
import PageContent from "../pageContent";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../../axios-base";
import today from "../../../../functions/greetingsOnDay";
import giveNameRespect from "../../../../functions/giveNameRespect";
import Spinner from "../../../../components/elements/spinner";
import {
  student_suggested_features_start,
  student_subject_specialization_start,
  student_trending_subjects_start,
  student_new_subjects_start,
  student_recommended_subjects_start,
  student_question_of_the_day_start,
} from "../../../../store/actions/student.dashboard.homepage";
import { shuffleSuggestedFeatures } from "../../../../functions/suggestedFeaturesUtils";
import { subjectSpecialization } from "../../../../functions/subjectSpecializationUtils";
import { trendingSubjects } from "../../../../functions/trendingSubjects";
import { getQuestionOfTheDay } from "../../../../functions/questionOfTheDay";
import * as actions from "../../../../store/actions/";
import { useParams, useHistory } from "react-router-dom";
import {
  newSubjects,
  recommendedSubjects,
} from "../../../../functions/newSubjects";
import { Button } from "../../../elements/button";
import ClassList from "../children/classlist";
import ClassSummary, {
  ActivityChart as ClassActivityChart,
  StudentAssessmentSummary as AssessmentSummary,
  GoalSummaryVertical as ClassGoalSummary,
  StudentSession,
} from "../children/classsummary";
import SuggestClass from "../children/suggestclass";
import RemindClass from "../children/remindadmin";
import { ParentInfoCard } from "../../../elements/card";

//---Action on proposed features--
const Dashboard = () => {
  //---Preload suggested features on the platform--
  const userAth = useSelector((state) => state.auth);
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  history.push('/login');
  const isAuthenticated = useSelector((state) => state.auth.token != null);
  const [students, setStudents] = useState([
    ["1", "S1 A", "Mucyo Eric"],
    ["2", "S2 A", "ISHIMWE Diane"],
    ["3", "S1 B", "MANZI Kevin"],
    ["4", "S2 B", "INEMA Jane"],
  ]);

  const [subjects, setSubjects] = useState([["Math"], ["Biology"]]);
  const [child, setChild] = useState([
    ["1", "S2 A", "ISHIMWE Diane"],
    ["2", "S1 B", "MANZI Kevin"],
  ]);
  const performance = [
    { key: "In latest Mathematics quiz", number: "70%", color: "green" },
    { key: "Assessments due next week", number: 3, color: "yellow" },
    {
      key: "Your child has low performance in chemistry",
      number: 0,
      color: "blue",
    },
  ];
  const [activeclass, setactiveClass] = useState([
    "ISHIMWE Diane",
    "MANZI Kevin",
  ]);
  const [works, setWorks] = useState(["Work 1", "Work 1"]);
  const [suggests, setSuggests] = useState(["Suggests 1", "Suggests 2"]);
  const [showlist, setShowlist] = useState(false);

  useEffect(() => {}, [isAuthenticated, dispatch]);

  return (
    <PageContent>
      <div className="w-full h-full flex flex-col flex-col-reverse md:flex-row justify-between p-3 md:space-x-4 max-h-myscreen overflow-auto scrollbar-thin">
        <div className="md:w-70%">
          <div>
            <div className="flex justify-center md:justify-start items-center space-x-4">
              <div className="w-full flex flex-col items-start ">
                <FeatureTitle name="Dashboard" />
                <div>
                  <SectionTitle
                    name={`${today()}, ${giveNameRespect(userAth?.username)}`}
                  />
                </div>
                {child.length > 0 ? (
                  <div className="w-full flex flex-col items-start">
                    <div className="space-y-2 w-full">
                      <div className="float-left flex flex-col justify-center w-full">
                        <div className="float-right flex flex-row justify-end items-center space-x-4">
                          <SuggestClass
                            startingValue={"My children"}
                            element={activeclass}
                            setElement={setactiveClass}
                          />
                          <Button
                            isSquare={true}
                            name="Link new"
                            outline="false"
                            color="blue"
                            clicked={() => {
                              history.push(`/parent/user/linkaccount`);
                            }}
                            isSmall="true"
                          />
                        </div>
                        {activeclass.length > 0 ? (
                          <>
                            <ClassSummary data={performance} show={!showlist} />
                            <div className="float-left flex flex-row flex-wrap p-5 space-x-4">
                              <SuggestClass
                                startingValue={"Works"}
                                element={works}
                                setElement={setWorks}
                              />
                              <SuggestClass
                                startingValue={"Subjects"}
                                element={subjects}
                                setElement={setSubjects}
                              />
                            </div>
                            <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                              <ClassGoalSummary
                                title="Activities"
                                color="blue"
                                titleColor="black"
                                goaldata={[
                                  {
                                    name: "Simulation",
                                    uv: 15,
                                    color: "#be1e2d",
                                  },
                                  {
                                    name: "Assessments",
                                    uv: 53,
                                    color: "#1a3f71",
                                  },
                                  {
                                    name: "Summary notes",
                                    uv: 63,
                                    color: "#e8ad25",
                                  },
                                  {
                                    name: "Forum",
                                    uv: 42,
                                    color: "#af8f39",
                                  },
                                  {
                                    name: "Library",
                                    uv: 50,
                                    color: "#1f78b4",
                                  },
                                ]}
                                time="Last week"
                              />

                              <AssessmentSummary
                                title="Assessments"
                                color="blue"
                                titleColor="black"
                                assessments={[
                                  {
                                    name: "English",
                                    subject: "English",
                                    submissions: 0,
                                    value: 3,
                                    duedate: "Due Today",
                                  },
                                  {
                                    name: "Math",
                                    subject: "Math",
                                    submissions: 3,
                                    value: 3,
                                    duedate: "Due Today",
                                  },
                                  {
                                    name: "English",
                                    subject: "English",
                                    submissions: 1,
                                    value: 3,
                                    duedate: "2021-06-01",
                                  },
                                ]}
                                time="Last week"
                              />

                              <StudentSession
                                title="Time spent on the platform"
                                color="blue"
                                titleColor="black"
                                data={[
                                  {
                                    name: "Monday",
                                    uv: 70,
                                    pv: 2400,
                                    amt: 2400,
                                  },
                                  {
                                    name: "Tuesday",
                                    uv: 150,
                                    pv: 2400,
                                    amt: 2400,
                                  },
                                  {
                                    name: "Wednesday",
                                    uv: 100,
                                    pv: 2400,
                                    amt: 2400,
                                  },
                                  {
                                    name: "Thursday",
                                    uv: 200,
                                    pv: 2400,
                                    amt: 2400,
                                  },
                                  {
                                    name: "Friday",
                                    uv: 250,
                                    pv: 2400,
                                    amt: 2400,
                                  },
                                ]}
                                time="This week"
                              />
                            </div>
                          </>
                        ) : (
                          <RemindClass />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex flex-col items-center space-y-6">
                    <div className="w-64 h-64 mx-auto mt-20 space-y-6">
                      <img
                        className="w-full h-full object-cover object-center"
                        src="/images/noclass.png"
                        alt="img"
                      />
                    </div>
                    <div className="text-center space-y-6">
                      <SectionTitle
                        name="You do not have any information"
                        color="blue"
                      />
                      <CardSubText
                        name="You can start by adding your child"
                        color="blue"
                      />
                      <div>
                        <Button
                          name="+ Add"
                          outline="true"
                          color="blue"
                          clicked={() => {
                            history.push(`/parent/user/linkaccount`);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-30%">
          <ParentInfoCard />
        </div>
      </div>
    </PageContent>
  );
};

export default Dashboard;
