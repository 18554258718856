import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
import FillinTypeAutoQuestionItem from './FillinTypeAutoQuestionItem';
import { ConsoleView } from 'react-device-detect';
import { stringSimilarity } from "string-similarity-js";

const FillinTypeAutoQuestion = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    useEffect(() => {

    }, []);

    const generateCleanArrays = (questionId, answerPool) => {

        console.log(questionId, answerPool);
        return "";

    }

    const getUserAnswer = (questionId, answerPool, counter, answer_data, signal, realAnswer) => {
        //console.log(questionId, answerPool);
        //console.log("------------", signal);
        for (var i = 0; i < answerPool.length; i++) {
            if (parseInt(questionId) === parseInt(answerPool[i].questionId) && parseInt(answerPool[i].nber) === parseInt(signal)) {
                return [answerPool[i].answer, stringSimilarity(realAnswer, answerPool[i].answer) >= 0.85]
            }
        }
        return ["", false];
    }

    const getTheQuestion = (questionMetadata) => {
        let questionData = questionMetadata.split("#O.G.S")[1];
        const answersMap = questionMetadata.split("#O.G.S")[2];
        const Exlanations = questionMetadata.split("#O.G.S")[3];
        questionData = questionData;
        //--Each answer piece--
        const wordsCol = [];
        const answers = answersMap.split("#O.G");
        //---
        const answer_data = [];
        for (var i = 1; i < answers?.length; i++) {
            const wordsPool = answers[i].split("#.#.#");
            const wordsPool_ = [];
            for (var n = 1; n < wordsPool.length; n++) {
                if (wordsPool[n].includes("#A.N.S")) {
                    const theWord = wordsPool[n].split("#A.N.S")[0].trim();
                    answer_data.push(theWord);
                    wordsPool_.push(theWord);
                    questionData = questionData.replace(theWord, "------------");
                } else {
                    wordsPool_.push(wordsPool[n].trim());
                }
            }
            wordsCol.push(wordsPool_)
        }
        //----Word snippets-
        const words = questionData.split(" ");
        let wordsCleaned = [];
        let m = 0;
        for (var i = 0; i < words.length; i++) {
            if (words[i].includes("------------")) {
                wordsCleaned.push(["------------", wordsCol[m]]);
                m++;
            } else {
                wordsCleaned.push([words[i], 0]);
            }
        }
        const wordsCleanedNew = [];
        let counter = 0;
        for (var b = 0; b < wordsCleaned.length; b++) {
            if (wordsCleaned[b][0] === "------------") {
                //wordsCleanedNew.push(<FillinTypeAutoQuestionItem user_data={props.user_data} b={b} wordsCleaned={wordsCleaned} fillinTypeAuto={props.fillinTypeAuto} questionPlace={b} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} callFillinTypeAuto={props.callFillinTypeAuto} key={b} wordsPool={wordsCleaned[b][1]} />);
                wordsCleanedNew.push(<span style={{ margin: 4, padding: 4, background: "white", border: "0px solid black", color: "#18191A" }}>{answer_data[counter]} | <span style={{ textDecoration: (getUserAnswer(props.questionId, props.user_data, counter, answer_data, b, answer_data[counter])[1]) ? "none" : "line-through" }}>{getUserAnswer(props.questionId, props.user_data, counter, answer_data, b, answer_data[counter])[0]}</span></span>);
                counter++;
            } else {
                wordsCleanedNew.push(" " + wordsCleaned[b][0] + " ")
            }

        }
        return wordsCleanedNew;
    }

    return (
        <div>
            {getTheQuestion(props.questionMetadata)}
        </div>
    );
};
export default FillinTypeAutoQuestion;
