import { NavLink, Link as NormalLink } from "react-router-dom";
import { GrFormPrevious } from "react-icons/gr";
import { CardBody, SmallText } from "./text";
import { Link } from "../common/header/links";

export const ButtonLink = function (props) {
  let classes = "";
  if (props.outline === "true" && props.color === "red") {
    classes =
      "cursor-pointer   px-6 py-1 transition ease-in duration-200 rounded-full text-center text-red hover:bg-red hover:text-white border-2 border-red focus:outline-none";
  } else if (props.outline === "false" && props.color === "red") {
    classes =
      " cursor-pointer  px-6 py-1 transition ease-in duration-200 rounded-full text-center hover:text-red bg-red hover:bg-white text-white hover:text-red border-2 border-red hover:border-red focus:outline-none";
  } else if (props.outline === "true" && props.color === "blue") {
    classes =
      " cursor-pointer  px-6 py-1 transition ease-in duration-200 rounded-full text-center text-blue hover:bg-blue hover:text-white border-2 border-blue focus:outline-none";
  } else if (props.outline === "false" && props.color === "blue") {
    classes =
      " cursor-pointer  px-6 py-1 transition ease-in duration-200 rounded-full text-center hover:text-red bg-blue hover:bg-white text-white hover:text-blue border-2 border-blue hover:border-blue focus:outline-none";
  }

  if (props.outline === "true" && props.color === "white") {
    classes =
      " cursor-pointer  px-6 py-1 transition ease-in duration-200 rounded-full text-center text-white hover:bg-white hover:text-red border-2 border-white focus:outline-none";
  } else if (props.outline === "false" && props.color === "white") {
    classes =
      " cursor-pointer px-6 py-1 transition ease-in duration-200 rounded-full text-center hover:text-white bg-white hover:bg-transparent text-red hover:text-white border-2 border-white hover:border-white focus:outline-none";
  }

  if (props.isSmall === true) {
    classes += " text-xs";
  }

  if (props.isActive === true) {
  }
  return (
    <button className={classes}>
      <Link page={props.page} name={props.name} />
    </button>
  );
};

export const ButtonNav = function (props) {
  let classes = "";
  if (props.outline === "true" && props.color === "red") {
    classes =
      "cursor-pointer   px-6 py-1 transition ease-in duration-200 rounded-full text-center text-red hover:bg-red hover:text-white border-2 border-red focus:outline-none";
  } else if (props.outline === "false" && props.color === "red") {
    classes =
      " cursor-pointer  px-6 py-1 transition ease-in duration-200 rounded-full text-center hover:text-red bg-red hover:bg-white text-white hover:text-red border-2 border-red hover:border-red focus:outline-none";
  } else if (props.outline === "true" && props.color === "blue") {
    classes =
      " cursor-pointer  px-6 py-1 transition ease-in duration-200 rounded-full text-center text-blue hover:bg-blue hover:text-white border-2 border-blue focus:outline-none";
  } else if (props.outline === "false" && props.color === "blue") {
    classes =
      " cursor-pointer  px-6 py-1 transition ease-in duration-200 rounded-full text-center hover:text-red bg-blue hover:bg-white text-white hover:text-blue border-2 border-blue hover:border-blue focus:outline-none";
  }

  if (props.outline === "true" && props.color === "white") {
    classes =
      " cursor-pointer  px-6 py-1 transition ease-in duration-200 rounded-full text-center text-white hover:bg-white hover:text-red border-2 border-white focus:outline-none";
  } else if (props.outline === "false" && props.color === "white") {
    classes =
      " cursor-pointer px-6 py-1 transition ease-in duration-200 rounded-full text-center hover:text-white bg-white hover:bg-transparent text-red hover:text-white border-2 border-white hover:border-white focus:outline-none";
  }

  if (props.isSmall === true) {
    classes += " text-xs";
  }

  if (props.isActive === true) {
  }
  return (
    <button className={classes}>
      <Link page={props.page} name={props.name} />
    </button>
  );
};

export const ButtonWithIcon = function (props) {
  let classes =
    "px-6 py-1 flex items-center transition ease-in duration-200 hover:text-red focus:outline-none";

  if (props.outline === "true" && props.color === "red") {
    classes =
      " flex items-center  px-6 py-1 transition ease-in duration-200 text-center text-red hover:bg-red hover:text-white border-2 border-red focus:outline-none";
  } else if (props.outline === "false" && props.color === "red") {
    classes =
      " flex items-center  px-6 py-1 transition ease-in duration-200 text-center hover:text-red bg-red hover:bg-white text-white hover:text-red border-2 border-red hover:border-red focus:outline-none";
  } else if (props.outline === "true" && props.color === "blue") {
    classes =
      " flex items-center  px-6 py-1 transition ease-in duration-200 text-center text-blue hover:bg-blue hover:text-white border-2 border-blue focus:outline-none";
  } else if (props.outline === "false" && props.color === "blue") {
    classes =
      "flex items-center   px-6 py-1 transition ease-in duration-200 text-center hover:text-red bg-blue hover:bg-white text-white hover:text-blue border-2 border-blue hover:border-blue focus:outline-none";
  }

  if (props.outline === "true" && props.color === "white") {
    classes =
      " flex items-center  px-6 py-1 transition ease-in duration-200 text-center text-white hover:bg-white hover:text-red border-2 border-white focus:outline-none";
  } else if (props.outline === "false" && props.color === "white") {
    classes =
      " flex items-center  px-6 py-1 transition ease-in duration-200 text-center hover:text-white bg-white hover:bg-transparent text-red hover:text-white border-2 border-white hover:border-white focus:outline-none";
  }
  if (props.isSmall === true) {
    classes += " text-xs";
  }

  if (props.isActive === true) {
  }
  return (
    <button
      type="button"
      className={`${classes} ${props.isSquare ? "rounded" : "rounded-full"}`}
      onClick={props.onClick}>
      {props.src ? <img src={props.src} alt={props.name}/> : props.children}
      &nbsp;
      {props.name}
    </button>
  );
};

export const AnchorButton = function (props) {
  let classes =
    "transition ease-in text-sm  duration-200 hover:no-underline hover:text-red text-" +
    props.color+" "+props.additional;

  return (
    <NavLink to={props.to} className={classes}>
      {props.name}
    </NavLink>
  );
};

export const ButtonClose = function (props) {
  let classes =
    "h-8 w-8 flex items-center justify-center transition ease-in duration-200 rounded-full text-2xl text-center text-red hover:bg-red hover:text-white border-2 border-red focus:outline-none";

  return (
    <button className={classes} onClick={props.onClick}>
      &times;
    </button>
  );
};

export const LoginButtonLink = function (props) {
  let classes =
    "w-full md:w-2/3 mx-auto transition ease-in text-sm no-underline duration-200 rounded-full text-center hover:text-" +
    props.color +
    " bg-" +
    props.color +
    " hover:bg-transparent text-white hover:text-" +
    props.color +
    " border-2 border-" +
    props.color +
    " hover:border-" +
    props.color +
    " focus:outline-none";

  return (
    <NormalLink to="#login" className={classes}>
      {props.name}
    </NormalLink>
  );
};

export const OtherLinkButton = function (props) {
  let classes =
    `font-bold transition ease-in text-${props.size} duration-200 no-underline hover:text-red text-` +
    props.color;

  return (
    <NormalLink to={props.to} className={classes} onClick={props.onClick}>
      {props.name}
    </NormalLink>
  );
};

export const Button = function (props) {
  let classes = "";
  if (props.outline === "true" && props.color === "red") {
    classes =
      props.additional +
      " cursor-pointer   px-4 py-1 transition ease-in duration-200 text-center text-red hover:bg-red hover:text-white border-2 border-red focus:outline-none";
  } else if (props.outline === "false" && props.color === "red") {
    classes =
      props.additional +
      " cursor-pointer  px-4 py-1 transition ease-in duration-200 text-center hover:text-red bg-red hover:bg-white text-white hover:text-red border-2 border-red hover:border-red focus:outline-none";
  } else if (props.outline === "true" && props.color === "blue") {
    classes =
      props.additional +
      " cursor-pointer  px-4 py-1 transition ease-in duration-200 text-center text-blue hover:bg-blue hover:text-white border-2 border-blue focus:outline-none";
  } else if (props.outline === "false" && props.color === "blue") {
    classes =
      props.additional +
      " cursor-pointer  px-4 py-1 transition ease-in duration-200 text-center hover:text-red bg-blue hover:bg-white text-white hover:text-blue border-2 border-blue hover:border-blue focus:outline-none";
  }else if (props.outline === "true" && props.color === "green") {
    classes =
      props.additional +
      " cursor-pointer  px-4 py-1 transition ease-in duration-200 text-center text-green hover:bg-green hover:text-white border-2 border-green focus:outline-none";
  } else if (props.outline === "false" && props.color === "green") {
    classes =
      props.additional +
      " cursor-pointer  px-4 py-1 transition ease-in duration-200 text-center hover:text-red bg-green hover:bg-white text-white hover:text-green border-2 border-green hover:border-green focus:outline-none";
  }

  if (props.outline === "true" && props.color === "white") {
    classes =
      props.additional +
      " cursor-pointer  px-4 py-1 transition ease-in duration-200 text-center text-white hover:bg-white hover:text-red border-2 border-white focus:outline-none";
  } else if (props.outline === "false" && props.color === "white") {
    classes =
      props.additional +
      " cursor-pointer px-4 py-1 transition ease-in duration-200 text-center hover:text-white bg-white hover:bg-transparent text-red hover:text-white border-2 border-white hover:border-white focus:outline-none";
  }

  if (props.isSmall === true) {
    classes += " text-xs";
  }

  if (props.isActive === true) {
  }
  if (props.type === "submitbtn") {
    return (
      <button
        className={`${classes} ${props.isSquare ? "rounded" : "rounded-full"}`}
        onClick={props.clicked}
        disabled={props.disabled}
        type="submit"
        ref={props.ref !== null ? props.ref : null}
      >
        {props.name}
      </button>
    );
  } else {
    return (
      <button
        className={`${classes} ${props.isSquare ? "rounded" : "rounded-full"}`}
        onClick={props.clicked}
        disabled={props.disabled}
      >
        {props.name}
      </button>
    );
  }
};

export const TextButton = (props) => {
  return (
    <button
      className={`${props.additional} text-${props.color}`}
      onClick={props.clicked}
    >
      {props.name}
    </button>
  );
};

export const GoBackButton = (props) => {
  return (
    <NormalLink className={`flex flex-col px-${props.px}`} to={props.link}>
      <div className="flex flex-row items-center">
        <GrFormPrevious />
        <CardBody name={props.action} color="blue" />
      </div>
      <SmallText name={props.location} color="gray-500" />
    </NormalLink>
  );
};
