import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import constants from '../../../../../../constants/constants';
import { setCurrentQuestion } from '../../../../../../store/actions/features/tests';
import Input from '../../../../../elements/input';
import RichTextEditor from '../../../../../elements/RichTextEditor';
import { Button } from '../../../../../elements/button';
import { CardBody, CardTitle } from '../../../../../elements/text';
import Modal from '../../../../../elements/modal/modal';

const AddOpenEndedQuestion = (props) => {
  const dispatch = useDispatch();
  const [duration, setDuration] = useState(2);
  const [question, setQuestion] = useState('');
  const [explanation, setExplanation] = useState('');

  // useEffect(() => {
  //   dispatch(
  //     setCurrentQuestion({
  //       type: constants.open_ended,
  //       marks: marks,
  //       duration: duration,
  //       question: question,
  //       explanation: explanation,
  //     })
  //   );
  // }, [dispatch, marks, duration, question, explanation]);

  return (
    <div className="w-70% border-t border-gray-500">
      <div className="flex space-x-2">
        <Input
          label="Marks"
          elementType="input"
          elementConfig={{
            type: 'text',
            placeholder: 'Enter question marks here',
          }}
          value={props.question.marks}
          changed={(mark) => {
            props.updateOpenEndedQuestionMarks(props.questionNber, mark);
          }}
        />
        <Input
          label="Duration"
          elementType="input"
          elementConfig={{
            type: 'text',
            placeholder: 'Enter test duration here',
          }}
          value={props.question.duration}
          changed={(time) => {
            props.updateOpenEndedQuestionDuration(props.questionNber, time);
          }}
        />
      </div>
      <RichTextEditor
        label="Question"
        value={props.question.question}
        onChange={(text) => {
          props.updateOpenEndedQuestionData(props.questionNber, text);
        }}
        placeholder="Write your question here"
      />
      <RichTextEditor
        label="Explanation"
        value={props.question.explanation}
        onChange={(text) => {
          props.updateOpenEndedExplanationData(props.questionNber, text);
        }}
        placeholder="Write your explanation here"
      />
    </div>
  );
};

export default AddOpenEndedQuestion;
