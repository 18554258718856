import StudentSalesInformation from "./student/salesInformation";
import TeacherSalesInformation from "./teacher/salesInformation";
import SchoolSalesInformation from "./school/salesInformation";
import ParentSalesInformation from "./parent/salesInformation";

const SalesInformation = (props) => {
  let userType = props.user;
  let Tab = StudentSalesInformation;
  switch (userType) {
    case "student":
      Tab = StudentSalesInformation;
      break;
    case "teacher":
      Tab = TeacherSalesInformation;
      break;
    case "school":
      Tab = SchoolSalesInformation;
      break;
    case "parent":
      Tab = ParentSalesInformation;
      break;
    default:
      Tab = StudentSalesInformation;
      break;
  }
  return (
    <div>
      <Tab />
    </div>
  );
};

export default SalesInformation;
