import { useEffect, useRef, useState, useCallback } from 'react';
import { Button } from './button';
import { useDispatch } from 'react-redux';
import { changeSelectedCommentText } from '../../store/actions/languages';

const TextSelector = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  // const [showButton, setShowButton] = useState(false);
  // const [pageX, setPageX] = useState(0);
  // const [pageY, setPageY] = useState(0);

  const getSelectedText = useCallback(
    (event) => {
      const text = window.getSelection().toString().trim();
      if (text.length > 0) {
        props.onSelection();
        // setPageX(event.pageX);
        // setPageY(event.pageY);
        // setShowButton(true);
        dispatch(changeSelectedCommentText(text));
      }
    },
    [props.onSelection, dispatch]
  );

  useEffect(() => {
    const unmountRef = ref.current;
    ref.current.addEventListener('mouseup', getSelectedText);
    return () => {
      unmountRef.removeEventListener('mouseup', getSelectedText);
    };
  }, [getSelectedText]);

  // <div className="relative">
  //     <div ref={ref}>{props.children}</div>
  //     {showButton && (
  //       <div className={`absolute top-${pageY} left-${pageX}`}>
  //         <Button
  //           name="Comment"
  //           outline="false"
  //           color="blue"
  //           clicked={() => console.log(selectedText)}
  //         />
  //       </div>
  //     )}
  //   </div>

  return <div ref={ref}>{props.children}</div>;
};

export default TextSelector;
