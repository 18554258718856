import { TabIcon } from "../../../elements/tab/tabicon";
// import { TabContent, TabContentItem } from "../../../elements/tab/tabcontent";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "./Tabselector";
import { PricingCard } from "./pricingcard";
import { PricingCardGroup } from "./pricingcardgroup";
import { Button, ButtonLink } from "../../../elements/button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as actions from "../../../../store/actions/";
import {
  changePaymentModalState,
  setChosenPackage,
} from "../../../../store/actions/payment";
import {
  CardBody,
  CardTitle,
  SectionTitle,
  SmallText,
} from "../../../elements/text";
import Modal from "../../../elements/modal/modal";
import { useTranslation } from "react-i18next";
import Spinner from "../../../elements/spinner";

const PageContent = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useTabs([
    "student",
    "teacher",
    "parent",
    "school",
    "creator",
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const whoisitfor = useSelector((state) => state.home.whoisopandafor);
  const whoisitforLoading = useSelector(
    (state) => state.home.whoisopandaforLoading
  );
  const showPaymentModal = useSelector(
    (state) => state.payment.showPaymentModal
  );
  const chosenPackage = useSelector((state) => state.payment.chosenPackage);
  const chosenProgram = useSelector((state) => state.payment.chosenProgram);

  let studentspackages = [];
  let parentspackages = [];
  let teacherspackages = [];
  let schoolspackages = [];
  for (var i = 0; i < whoisitfor?.length; i++) {
    switch (whoisitfor[i]?.usertype) {
      case "student":
        studentspackages = whoisitfor[i]?.userspricings;
        break;
      case "teacher":
        teacherspackages = whoisitfor[i]?.userspricings;
        break;
      case "parent":
        parentspackages = whoisitfor[i]?.userspricings;
        break;
      case "school":
        schoolspackages = whoisitfor[i]?.userspricings;
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    dispatch(actions.getHomeWhoIsOpandaFor());
  }, []);
  return (
    <div className="bg-white my-6">
      <Modal
        medium
        show={showPaymentModal}
        modalClosed={() => {
          dispatch(setChosenPackage(null));
          dispatch(changePaymentModalState(false));
        }}
      >
        <div className="w-80% m-auto py-5">
          <SectionTitle
            name={t("Here's what you selected")}
            alignment="center"
          />
          <div className="flex justify-between items-center py-3">
            <div className="text-center">
              <CardTitle name={chosenProgram} color="blue" />
              <div className="flex space-x-2">
                <CardTitle name={`Package ${chosenPackage?.number}`} />
                <CardBody name={chosenPackage?.pricingname} />
              </div>
              <div className="flex space-x-2">
                <CardTitle
                  name={`${chosenPackage?.pricingamount
                    } ${chosenPackage?.pricingcurrency?.toUpperCase()}`}
                />
                <CardBody name={chosenPackage?.pricingfrequency} />
              </div>
            </div>
            <div className="flex flex-col">
              {chosenPackage?.pricingfeatures
                ?.split(",")
                .map((feature, index) => (
                  <SmallText name={feature} color="blue" key={index} />
                ))}
            </div>
          </div>
          <div className="flex justify-around">
            <Button
              name={t("Cancel")}
              outline="true"
              color="blue"
              clicked={() => {
                alert(`helloe`);
                dispatch(setChosenPackage(null));
                dispatch(changePaymentModalState(false));
              }}
            />
            <Button
              name={t("Continue")}
              outline="false"
              color="blue"
              clicked={() => history.push("/payment/options")}
            />
          </div>
        </div>
      </Modal>
      {whoisitforLoading ? (
        <div className="flex flex-row justify-center">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <div className="max-w-7xl w-full mx-auto px-0" id="whoisitfor-tabs">
          <nav className="flex justify-center overflow-y-auto scrollbar-hidden">
            <TabSelector
              isActive={selectedTab === "student"}
              onClick={() => setSelectedTab("student")}
              tab="green"
            >
              <TabIcon redirecttabs={`whoisitfor-tabs`}>Student</TabIcon>
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "teacher"}
              onClick={() => {
                setSelectedTab("teacher");
              }}
              tab="yellow"
            >
              <TabIcon redirecttabs={`whoisitfor-tabs`}>Teacher</TabIcon>
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "parent"}
              onClick={() => setSelectedTab("parent")}
              tab="gray-500"
            >
              <TabIcon redirecttabs={`whoisitfor-tabs`}>Parent</TabIcon>
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "school"}
              onClick={() => setSelectedTab("school")}
              tab="blue"
              redirecttabs={`whoisitfor-tabs`}
            >
              <TabIcon redirecttabs={`whoisitfor-tabs`}>School</TabIcon>
            </TabSelector>
            {/*<TabSelector
              isActive={selectedTab === "creator"}
              onClick={() => setSelectedTab("creator")}
              tab="red"
              redirecttabs={`whoisitfor-tabs`}
            >
              <TabIcon redirecttabs={`whoisitfor-tabs`}>Creator</TabIcon>
            </TabSelector>*/}
          </nav>
          {/* <div className="p-0"> */}
            <TabPanel hidden={selectedTab !== "student"}>
              {/* <PricingCardGroup> */}
                <PricingCard>{studentspackages}</PricingCard>
              {/* </PricingCardGroup> */}
            </TabPanel>
            <TabPanel hidden={selectedTab !== "teacher"}>
              {/* <PricingCardGroup> */}
                <PricingCard>{teacherspackages}</PricingCard>
              {/* </PricingCardGroup> */}
            </TabPanel>
            <TabPanel hidden={selectedTab !== "parent"}>
              {/* <PricingCardGroup> */}
                <PricingCard>{parentspackages}</PricingCard>
              {/* </PricingCardGroup> */}
            </TabPanel>
            <TabPanel hidden={selectedTab !== "school"}>
              {/* <PricingCardGroup> */}
                <PricingCard>{schoolspackages}</PricingCard>
              {/* </PricingCardGroup> */}
            </TabPanel>
            <TabPanel hidden={selectedTab !== "creator"}>
              {/* <PricingCardGroup> */}
                <PricingCard>{parentspackages}</PricingCard>
              {/* </PricingCardGroup> */}
            </TabPanel>
          {/* </div> */}
          {/* <div style={{display:"none"}}  className="flex justify-center">
            <ButtonLink
              page="apply-for-sponsorship"
              outline="true"
              color="red"
              isSmall={true}
              name={t("Apply for a sponsorship")}
            />
          </div> */}
          <hr className="mt-12 mb-4 text-gray text-md" />
        </div>
      )}
    </div>
  );
};

export default PageContent;
