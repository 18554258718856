import LinkAccount from '../../../components/pages/parent/user/linkaccount';
import Layout from '../../../components/common/Layout';

const ParentLinkAccountContainer = () => {
  return (
    <Layout>
      <LinkAccount />
    </Layout>
  );
};

export default ParentLinkAccountContainer;