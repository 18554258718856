import {
    CardTitle,
    CardBody,
    SectionTitle,
    CardSubText,
    SmallText,
    PageBody,
    PageTitle
  } from "./text";
import { Button } from './button';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { useHistory } from "react-router-dom";

export const PopupWindow = (props) => {
    const history = useHistory();

return(
<>
<div id="popup-modal" tabindex="-1"
    class={`${props.showPopup} flex flex-col justify-center items-center bg-black bg-opacity-75 w-full overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full`}
    aria-hidden="true">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <div class="relative bg-white rounded-lg shadow">
            <div class="flex flex-col items-center justify-between p-6 text-center">
                <CardTitle name="Account is linked successfully"/>
                <CardBody name="You will be able to monitor activities made with your child account" />
              <div className="p-6"> 
              <BsFillCheckCircleFill color="#E8AD26" size={60} />
              </div>  
                <Button
                name="Close"
                outline="false"
                color="blue"
                isSmall
                clicked={() => {
                    props.showPopup='hidden';
                }}
              />
            </div>
        </div>
    </div>
</div>

</>
)
}