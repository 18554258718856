import Layout from '../../components/common/Layout';
import Community from '../../components/pages/student/community/Community';

const CommunityContainer = () => {

  return (
    <Layout>
      <Community />
    </Layout>
  );
};

export default CommunityContainer;
