import { updateObject } from '../../shared/utility';

import {
  SETTINGS_ACTION_CLICKED,
  SETTINGS_ACTION_START,
} from '../actions/settingsActionTypes';

const settingsItems = [
  {
    title: 'Notifications',
    subTitle: 'Notifications channels',
    completed: false,
    selected: true,
  },
  {
    title: 'Account information',
    subTitle: 'Email, password, tokens & account deletion',
    completed: false,
    selected: false,
  },
  
];

const initialReducer = {
  settingsItems: settingsItems,
  error: null,
  loading: false,
};

const settingsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};


const settingsClicked = (state, action) => {
  const newSettingsItems = state.settingsItems.map((item) => {
    return {
      title: item.title,
      subTitle: item.subTitle,
      completed: item.completed,
      selected: item.title === action.action ? true : false,
    };
  });
  return updateObject(state, {
    settingsItems: newSettingsItems,
    error: null,
    loading: false,
  });
};



const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SETTINGS_ACTION_START:
      return settingsStart(state, action);
    
    case SETTINGS_ACTION_CLICKED:
      return settingsClicked(state, action);
    default:
      return state;
  }
};
export default reducer;
