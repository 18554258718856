import { connect } from "react-redux";

import { CardBody, CardSubText, CardTitle } from "../../elements/text";

import { store } from "../../../store/store";
import { useHistory } from "react-router-dom";
import { calculateTimeFromDate } from "./../../../shared/utility";
import {
  BsCheckCircleFill,
  BsFillLockFill,
  BsFillUnlockFill,
  BsQuestionCircleFill,
} from "react-icons/bs";
import { SmallText } from "./../../elements/text";
import { bring_subject_image_src } from "../../../functions/programs";
import { useSelector } from "react-redux";
import { useState } from "react";
export const ContentCard = function (props) {
  const previewpanda = useSelector((state) => state?.reducer);
  const history = useHistory();
  if (props.type === "one") {
    let {
      id,
      unit,
      subject,
      topicarea,
      imgsrc,
      tag,
      created_at,
      name,
      chapter,
      description,
      panda_test_map_2020_03_subject,
      panda_test_map_2020_03_test_type,
      panda_test_map_2020_03_unit,
      panda_test_map_2020_03_id,
      level,
      opandamoduleid,
      status,
      subtopicarea,
      units,
      uuid,
    } = props.data;
    const datasubject = subject || panda_test_map_2020_03_subject;
    let filesrc = imgsrc;
    if (props.page === `previewpanda`) {
      filesrc = `${process.env.REACT_APP_BACKEND_URL}/opanda/${imgsrc}`;
    }
    const { showcontentmodal } = props;
    const handleClick = () => {
      // if (props.guest) {
      //   history.push(`/previewpanda/guest/${id}`);
      // }
      if (props.index === 0) {
        if (props.isCourse === "true") {
          history.push(`/previewpanda/summarynotes/${id}`);
        } else {
          history.push(`/previewpanda/tests/${id}`);
        }
      } else {
        showcontentmodal("block", props.data);
      }
    };

    return (
      <div
        className="flex flex-row-reverse align-start justify-between bg-white h-full filter drop-shadow-md rounded-2xl cursor-pointer"
        onClick={handleClick}
      >
        <div className={`w-96px relative rounded-2xl`}>
          <img
            src={bring_subject_image_src(datasubject)?.imgsrc}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          />
          {props.index !== 0 ? (
            <div className="absolute top-2 right-2 z-10 bg-white p-2 text-center rounded-full">
              <BsFillLockFill color="#BE1E2D" />
            </div>
          ) : (
            <div className="absolute top-2 right-2 z-10 bg-white p-2 text-center rounded-full">
              <BsFillUnlockFill color="#183F71" className="" />
            </div>
          )}
        </div>
        <form className="p-6 flex flex-col justify-between">
          <div className="flex flex-col flex-wrap">
            <CardTitle
              name={topicarea || units}
              color="blue"
              alignment="left"
              additional="w-32"
            />
            <CardBody
              name={subject || panda_test_map_2020_03_subject}
              color="black"
              alignment="left"
            />
            <CardSubText
              name={(units || panda_test_map_2020_03_test_type) + " "}
              color="red"
              alignment="left"
            />
          </div>

          {/* {props.isCourse === "true" && (
            <p className="text-sm text-gray-500">{`${calculateTimeFromDate(
              created_at
            )} ago`}</p>
          )} */}
        </form>
      </div>
    );
  } else if (props.type === "two") {
    let {
      panda_books_name,
      panda_books_author,
      price,
      panda_books_book_type,
      panda_books_date,
    } = props.data;
    let bookdata = props.data;
    const { showcontentmodal } = props;
    return (
      <div
        className="bg-gray-100  filter drop-shadow-md rounded-2xl cursor-pointer"
        onClick={() => {
          showcontentmodal("block", bookdata);
        }}
      >
        {" "}
        <div className="flex flex-col flex-wrap m-4 space-y-4">
          <div className="flex flex-col flex-wrap">
            <CardTitle
              name={bookdata.panda_books_name}
              color="blue"
              alignment="left"
            />
            <CardSubText
              name={bookdata.panda_books_author}
              color="black"
              alignment="left"
            />
            <CardSubText
              name={
                bookdata.panda_books_book_type + " " + bookdata.panda_books_date
              }
              color="gray-500"
              alignment="left"
            />
          </div>
          <CardTitle name={"Rwf 5,000"} color="blue" alignment="left" />
        </div>
      </div>
    );
  } else if (props.type === "three") {
    let moduledata = props.data;
    const { showcontentmodal } = props;
    return (
      <div
        className="flex flex-col items-center justify-center flex-wrap bg-gray-100 h-36 filter drop-shadow-md rounded-2xl overflow-ellipsis overflow-hidden cursor-pointer"
        onClick={() => {
          showcontentmodal("block", moduledata);
        }}
      >
        <CardBody
          name={moduledata?.name}
          color="black"
          alignment="center"
          additional="overflow-ellipsis overflow-hidden"
        />
      </div>
    );
  } else if (props.type === "endofrsc") {
    let { title, unit, imgsrc, createdAt, type, watched } = props.data;
    return (
      <div className="m-2 h-28 flex align-start justify-between filter drop-shadow-md rounded-2xl cursor-pointer bg-white">
        <div className="p-2 flex flex-col justify-between">
          <div>
            <CardTitle name={title} additional="w-20" />
            <CardBody name={`Unit ${unit}`} />
            <CardBody name={type} color="red" />
          </div>
          {type !== "Test" && (
            <SmallText
              name={`${calculateTimeFromDate(createdAt)} ago`}
              color="red"
            />
          )}
        </div>
        <div className="relative rounded-2xl w-20">
          <img
            className="w-full h-full object-cover object-center rounded-2xl"
            src={`${process.env.REACT_APP_BACKEND_URL}/opanda/${imgsrc}`}
            alt="img"
          />
          {watched ? (
            <BsCheckCircleFill
              color="#BE1E2D"
              className="absolute top-2 right-2 z-10 bg-white rounded-full"
            />
          ) : (
            <BsQuestionCircleFill
              color="#183F71"
              className="absolute top-2 right-2 z-10 bg-white rounded-full"
            />
          )}
        </div>
      </div>
    );
  }
};

// export default SideBar;
const mapStateToProps = (state) => {
  return {
    visibility: state.mobilesidebar,
    tabselected: state.tabselected,
    contentmodalstatus: state.contentmodalstatus,
    modalcontent: state.modalcontent,
  };
};
const mapDispatchToProps = () => {
  return {
    showcontentmodal: (status, modalcontent) =>
      store.dispatch({
        type: "SHOW_CONTENT_MODAL",
        payload: status,
        modalcontent: modalcontent,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContentCard);
