import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useEffect } from 'react';
const CompleteSentenceItem = (props) => {

  const [data, setData] = useState("");
  useEffect(() => {
    const newArr = [];
    let dataIn = "";
    for (var i = 0; i < newArr.length; i++) {
      if (parseInt(newArr[i]?.question_number) === parseInt(props?.currentQuestionNumber) && parseInt(newArr[i]?.questionId) === parseInt(props.questionId) && parseInt(newArr[i]?.spaceNber) === parseInt(props.spaceNber)) {
        dataIn = newArr[i].newEntry;
      }
    }
    const cleanIn = ((props.dataIn).replace("<p>", "") !== undefined) ? (props.dataIn).replace("<p>", "") : props.dataIn;
    const cleanIn1 = (cleanIn !== undefined && (cleanIn).replace("</p>", "") !== undefined) ? (cleanIn).replace("</p>", "") : cleanIn;
    setData(cleanIn1);
  }, []);
  const getTypedInData = (e) => {

    setData(e.target.value);
  };
  const mapUserPick = (answer_by_user, spaceNber, dataIn) => {
    for (var i = 0; i < answer_by_user.length; i++) {
      if (parseInt(answer_by_user[i][0]) === spaceNber) {
        if (dataIn.toLowerCase().trim().includes((answer_by_user[i][1].toLowerCase().trim()).trim())) {
          return answer_by_user[i][1].trim();
        } else {
          return <span style={{ textDecoration: "line-through" }}>{answer_by_user[i][1].trim()}</span>;
        }

      } else {

      }

    }
    return "";
  };


  return (
    <span className={'bg-white text-center px-1 py-1 text-blue'}>{data} | {mapUserPick(props.answer_by_user, props.spaceNber, props.dataIn)}</span>
  );
};

export default CompleteSentenceItem;
