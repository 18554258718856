import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Store } from 'react-notifications-component';

export const errorNotification = (msg)=>{
Store.addNotification({
    title: "Error",
    message: msg,
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2500,
      onScreen: true
    }
  })}
  export default {
    errorNotification
  }