import Footer from '../components/common/footer';
import Header from '../components/common/header/header';
import Hero from '../components/common/hero';
import PageContent from '../components/pages/landing/pricing/pagecontent';
import MoreInfo from '../components/pages/landing/pricing/moreinfo';
import ScrollToTop from '../components/common/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import * as actions from "../store/actions/"

export const Pricing = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
      <Header />
      <Hero
        page="pricing"
        title={t('Our Pricing')}
        body="O'Genius Panda is an edutech platform that offers a wide variety of packages to cater to different needs and budgets of its customers. Here are some of the pricing options available on O'Genius Panda. Whether you are a beginner or an advanced learner, there is a package that can meet your needs and budget."
      />
      <PageContent />
      {/* <MoreInfo /> */}
      <Footer />
      <ScrollToTop />
    </div>
  );
};
