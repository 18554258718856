import SideDrawer from "../../elements/sideDrawer";
import SideBar from "./sidebar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeStudentDashboardMobileVisibility } from "../../../store/actions/sidebaractiontypes";

const PageContent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const studentDashboardMobileSidebar = useSelector(
    (state) => state.reducer.studentDashboardMobileSidebar
  );
  const isAuth = useSelector((state) => state.auth.token !== null);
  if (!isAuth) {
    history.replace("/");
  }else{
    
  }
  return (
    <div className="bg-white-blue md:flex w-full h-full min-h-full">
      <SideDrawer
        show={studentDashboardMobileSidebar === "block"}
        closed={() => {
          dispatch(changeStudentDashboardMobileVisibility("hidden"));
        }}
      >
        <SideBar />
      </SideDrawer>

      <div className="hidden md:block">
        <SideBar />
      </div>
      <div className={`h-full w-full overflow-hidden`}>{props.children}</div>
    </div>
  );
};

export default PageContent;
