import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const FillinTypeAutoQuestionItemOneViewPopOverAnswer = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    useEffect(() => {

    }, []);

    return (
        <div onClick={()=>{
            props.setAnswerChoice(props.item)
        }} className='cursor-default border-gray-100 hover:text-blue hover:bg-black-600 hover:border-gray-300 border-2 rounded-sm p-3 p-2 bg-white text-center px-1 py-1 text-black m-1 text-sm '>{"   " + props.item}</div>
    );
};
export default FillinTypeAutoQuestionItemOneViewPopOverAnswer;
