import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonClose, TextButton } from "../../../../elements/button";

import Input from "../../../../elements/input";
import Spinner from "../../../../elements/spinner";
import { CardTitle } from "../../../../elements/text";

import Checkbox from "../../../../elements/checkbox";

const EventEditForm = (props) => {
  const loading = useSelector((state) => state.completeSignup.loading);
  const [title, setTitle] = useState(props.editdata.title);
  const [startDate, setStartDate] = useState(props.editdata.from);
  const [endDate, setEndDate] = useState(props.editdata.to);
  const [eventtype, setEventtype] = useState(props.editdata.type);
  const [description, setDescription] = useState(props.editdata.description);
  const [classname, setclassname] = useState(props.editdata.class);
  const [subject, setSubject] = useState(props.editdata.subject);
  const [unit, setUnit] = useState(props.editdata.unit);
  const [online, setOnline] = useState(props.editdata.online);
  const [activeclasses, setActiveclass] = useState(props.activeclass);
  const [activesubjects, setActivesubjects] = useState(props.activesubject);
  // console.log("event type",eventtype);
  const onOnlineChange = () => {
    setOnline(!online);
  };

  const [meetingPassword, setMeetingPassword] = useState(
    props.editdata?.meetingPassword
  );
  const [meetingCode, setMeetingCode] = useState(props.editdata?.meetingCode);
  const [meetingURL, setMeetingURL] = useState(props.editdata?.meetingURL);
  const [resourcesList, setResourcesList] = useState(props.editdata?.resources);
  const [resourceInputList, setResourceInputList] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState( [
    { value: "1", displayValue: "Unit 1: sfsdf" },
    { value: "2", displayValue: "Unit 2: sfsdf" },
  ]);
  const onAddChild = (event) => {
    setResourceInputList(
      resourceInputList.concat([
        <ResourceInputElement
          key={resourceInputList.length}
          onClick={() => onRemoveChild(resourceInputList.length)}
        />,
      ])
    );
  };
  const onRemoveChild = (start) => {
    setResourceInputList(resourceInputList.splice(start, 1));
  };

  const onClassChange = (value) =>{
    setclassname(value);
    changeSubjectOptions(value);
  };

  const changeSubjectOptions = (v) =>{
    // fetch subject corresponding to the class name ($v)
    const options = ['biology','chemistry','mathematics', 'physics'];
    // End of fetch
    setActivesubjects(options);
  };
  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Edit event" color="blue" />
          <div className="md:grid grid-cols-2 gap-2">
            <Input
              label="Event type"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "class", displayValue: "Class session" },
                  { value: "custom", displayValue: "Custom" },
                ],
              }}
              value={eventtype}
              changed={setEventtype}
              validation={{ required: false }}
              error="Event type is required"
            />
            <Input
              label="Title"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Event title",
              }}
              value={title}
              changed={setTitle}
              validation={{ required: true }}
              shouldValidate
              error="Event title is required"
            />
          </div>
          <div
            className={`${
              eventtype === "class" ? "md:grid" : "hidden"
            } grid-cols-2 gap-2`}
          >
            <Input
              label="Class level"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: activeclasses.map((class_) => ({value: class_, displayValue: class_.toUpperCase()}))
              }}
              value={classname}
              changed={onClassChange}
              validation={{ required: false }}
              error="Class is required"
            />
            <Input
              label="Subject"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options:  activesubjects.map((subject_) => ({value: subject_, displayValue: subject_.toUpperCase()}))
                ,
              }}
              value={subject}
              changed={setSubject}
              validation={{ required: false }}
              error="Subject is required"
            />

            <Input
              label="Unit"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "1", displayValue: "Unit 1: sfsdf" },
                  { value: "2", displayValue: "Unit 2: sfsdf" },
                ],
              }}
              value={unit}
              changed={setUnit}
              validation={{ required: false }}
              error="Unit is required"
            />
          </div>
          <div className="md:grid grid-cols-2 gap-2">
            <Input
              label="From"
              elementType="input"
              elementConfig={{
                type: "datetime-local",
                placeholder: "dd-mm-yyyy HH:MM",
                max: new Date(),
              }}
              value={startDate}
              changed={setStartDate}
              validation={{ required: true, olderThan10: true }}
              shouldValidate
              error="Date and time is required"
            />
            <Input
              label="To"
              elementType="input"
              elementConfig={{
                type: "datetime-local",
                placeholder: "dd-mm-yyyy HH:MM",
                max: new Date(),
              }}
              value={endDate}
              changed={setEndDate}
              validation={{ required: true, olderThan10: true }}
              shouldValidate
              error="Date and time is required"
            />
            <Input
              label="Description"
              elementType="textarea"
              value={description}
              changed={setDescription}
            />

            <Checkbox
              label="The session shall be conducted online"
              elementType="checkbox"
              value={online}
              onChange={onOnlineChange}
            />
          </div>
          <div className={`${online ? "md:grid" : "hidden"} grid-cols-1`}>
            <CardTitle name="Meeting link" color="blue" alignment="left" />
            <div className="md:grid grid-cols-2 gap-2">
              <Input
                label="Meeting code"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter code",
                }}
                value={meetingCode}
                changed={setMeetingCode}
                validation={{ required: false }}
              />
              <Input
                label="Meeting password"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter code",
                }}
                value={meetingPassword}
                changed={setMeetingPassword}
                validation={{ required: false }}
              />
              <Input
                label="Meeting URL"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "Enter URL",
                }}
                value={meetingURL}
                changed={setMeetingURL}
                validation={{ required: false }}
              />
            </div>
          </div>
          <CardTitle name="Resources" color="blue" alignment="left" />
          <div className="md:grid grid-cols-1" id="resourceDiv">
            {resourcesList.map((resource, index) => (
              <ResourceInputElement
                key={index}
                {...resource}
                onClick={() => onRemoveChild(index)}
              />
            ))}
          </div>
          <TextButton
            name="+ Add a resource"
            additional="float-right"
            color="blue"
            clicked={() => {
              onAddChild();
            }}
          />

          <div className="md:hidden">
            <div className="flex space-x-2"></div>
            <div className="flex space-x-2"></div>
          </div>
          <div className="hidden md:grid grid-cols-3 gap-2">
            <div></div>
          </div>
          <div className="flex space-x-2 justify-center py-5">
            <Button name="Cancel" outline="true" color="blue" />
            <Button name="Save" outline="false" color="blue" />
          </div>
        </div>
      )}
    </>
  );
};

export default EventEditForm;

const ResourceInputElement = (props) => {
  const [name, setName] = useState(props.name);
  const [file, setFile] = useState(props.resourcelink);

  return (
    <div className="md:grid grid-cols-2 gap-2">
      <Input
        label="Resource name"
        elementType="input"
        elementConfig={{
          type: "text",
          placeholder: "Enter name",
        }}
        value={name}
        changed={setName}
      />
      <div className="flex flex-col justify-end">
        <ButtonClose onClick={props.onClick} />
        {/* <Input
          label="Attach resource"
          elementType="input"
          elementConfig={{
            type: "file",
            placeholder: "Attach",
          }}
          value={file}
          changed={setFile}
        /> */}
        {/* <ButtonWithIcon
          name="Attach"
          outline="true"
          color="blue"
          onClick={() => {}}
        >
          <MdAttachment size={26} />
        </ButtonWithIcon> */}
      </div>
    </div>
  );
};
