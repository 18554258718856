import { useSelector, useDispatch } from "react-redux";
import { updateObject } from "../../shared/utility";
import {
  PACY_INIT,
  PACY_SUCCESS,
  PACY_FAIL,
  PACY_PRINT
} from "../actions/testactionsbyPacy";
const initialReducer = {
  proposedFeatures: [],
  message: "",
  printMessage: ""
};

function pacy_start(state, action) {
  return updateObject(state);
}
function pacy_success(state, action) {
  return updateObject(state, { message: "Greetings" });
}
function pacy_fail(state, action) {
  return updateObject(state, { message: "Error" + action.payload });
}

function pacy_print(state, action) {
  return updateObject(state, { printMessage: action.payload });
}
const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case PACY_INIT:
      return pacy_start(state, action);

    case PACY_SUCCESS:
      return pacy_success(state, action);
    case PACY_FAIL:
      return pacy_fail(state, action);
    case PACY_PRINT:
      return pacy_print(state, action);
    default:
      return state;
  }
};
export default reducer;
