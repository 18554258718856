import { useSelector } from 'react-redux';
import { CardBody } from '../../text';

export const NavSubItem = function ({ name, to, onClick, tab}) {
  const studentdashboardtabselected = useSelector(
    (state) => state.reducer.studentdashboardtabselected
  );
  return (
    <li onClick={onClick}  className="block ml-2 px-4 py-2">
      <CardBody
        name={name}
        color="white"
        additional={` ${
          studentdashboardtabselected === tab ? 'font-bold' : ''
        } hover:font-bold`}
      />
    </li>
  );
};
export const ParentNavSubItem = function (props, { name, to, onClick, tab}) {
  const studentdashboardtabselected = useSelector(
    (state) => state.reducer.studentdashboardtabselected
  );
  return (
    <li onClick={props.onClick}  className="flex justify-between px-4 py-2">
      <CardBody
        name={props.name}
        color="blue"
        additional={` ${
          studentdashboardtabselected === props.tab ? 'font-bold' : ''
        } hover:font-bold`}
      />
        {props.new > 0 ? (
        <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none bg-red text-white rounded-lg">
          {props.new}
        </span>
      ) : null}
    </li>
  );
};
