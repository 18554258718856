import { CardTitle, PageBody } from "../../elements/text";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
import ContentCard from "./contentcard2";
import { useSelector } from "react-redux";
import NotFound from "../features/NotFound";
// install Swiper modules
SwiperCore.use([Navigation]);

export const ContentContainer = function (props) {
  const previewpanda = useSelector((state) => state?.reducer);
  const datajson = props.data;
  if (props.slider) {
    return (
      <div className="w-full flex flex-col align-start justify-center my-4 space-y-4">
        <CardTitle name={props.name} color="blue" />
        {datajson.length > 0 ? <Swiper
          navigation={true}
          slidesPerView={1}
          spaceBetween={5}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          className="w-full threeSwiper my-10"
        >
          {datajson
            ?.filter((carddetails, key) => {
              return (
                previewpanda?.previewpanda_search_keyword === "" ||
                carddetails?.subject
                  .toLowerCase()
                  .includes(
                    previewpanda?.previewpanda_search_keyword?.toLowerCase()
                  ) ||
                carddetails?.units
                  .toLowerCase()
                  .includes(
                    previewpanda?.previewpanda_search_keyword?.toLowerCase()
                  )
              );
            })
            ?.map((carddetails, key) => (
              <SwiperSlide key={carddetails.id}>
                {(props.kind == "Courses" &&
                  carddetails?.subject &&
                  carddetails?.id > 0 &&
                  (previewpanda?.previewpanda_search_keyword === "" ||
                    carddetails?.subject
                      ?.toLowerCase()
                      ?.includes(
                        previewpanda?.previewpanda_search_keyword?.toLowerCase()
                      ) ||
                    carddetails?.units
                      ?.toLowerCase()
                      .includes(
                        previewpanda?.previewpanda_search_keyword?.toLowerCase()
                      ))) ||
                carddetails?.topicarea
                  ?.toLowerCase()
                  .includes(
                    previewpanda?.previewpanda_search_keyword?.toLowerCase()
                  ) ? (
                  <ContentCard
                    index={key}
                    page={props.page}
                    guest={props.guest}
                    data={carddetails}
                    type={props.type}
                    isCourse={props.isCourse}
                  />
                ) : (
                  <ContentCard
                    index={key}
                    page={props.page}
                    guest={props.guest}
                    data={carddetails}
                    type={props.type}
                    isCourse={props.isCourse}
                  />
                )}
              </SwiperSlide>
            ))}
        </Swiper> : <div className="grid grid-cols-1 gap-4 text-center"> <PageBody name="Select a class level and subject" color="blue"/></div>}
        
      </div>
    );
  } else {
    return (
      <div className="w-full my-4 space-y-4">
        <CardTitle name={props.name} color="blue" nosubtitle={false} />
        <div className="grid grid-cols-4 gap-4">
        {datajson.length > 0 ?
          datajson?.map((carddetails, index) => (
            <ContentCard
              key={index}
              data={carddetails}
              type={props.type}
              page={props.page}
              isCourse={props.isCourse}
            />
          )) : <div className="grid grid-cols-1 gap-4 text-center"> <PageBody name="Select a class level and subject" color="blue"/></div>}
        </div>
      </div>
    );
  }
};
