import { Button } from "../../elements/button";
import Input from "../../elements/input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/";
import { useHistory } from "react-router-dom";
import * as constants from "../../../constants/constants";
import { Message } from "../messages";
import Spinner from "../../elements/spinner";
import { authSuccess } from "../../../store/actions/auth";
import axios from "../../../axios-base";
import AlertModal from "../../elements/modal/AlertModal";
import ConfirmModal from "../../elements/modal/ConfirmModal";
const MobileMoney = (props) => {
  const [phone, setPhone] = useState("");
  const chosenPackage = props.chosenPackage;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const payment = useSelector((state) => state.payment.payment);
  const paymentLoading = useSelector((state) => state.payment.paymentLoading);
  const userAuth = useSelector((state) => state.auth);
  const _dashboard = props._dashboard;
  let error = useSelector((state) => state.payment.paymentFail);

  const momopayment = () => {
    if (phone.length < 10) {
      error = "Phone number length incorrect";
    } else {
      const paymentObject = {
        phone,
        statusDescription: "PASSED",
        spTransactionId: "TRNS ID",
        walletTransactionId: "WALLET",
        chargedCommission: 100,
        currency: chosenPackage?.pricingcurrency,
        paidAmount: chosenPackage?.totalMonthlyPlan,
        userID: user.id,
        usertype: chosenPackage.usertype,
        pricingfrequencyindays: chosenPackage.pricingfrequencyindays,
        extrapayment: false
      };
      dispatch(actions.makePayment(paymentObject, token));
    }
  };

  useEffect(() => {
    //---
    if (user !== null) {
      if (user?.paymentvalid !== true) {
      } else if (user?.paymentvalid === true) {
        if (user?.user_type === constants.studentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/student");
          } else {
            history.push("/complete");
          }
        } else if (user?.user_type === constants.teacherusertype) {
          if (user?.isprofilecompleted === 1) {
            //history.push("/teacher");
            history.push("/");
          } else {
            history.push("/teacher/user/profile");
          }
        } else if (user?.user_type === constants.schoolusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/school");
          } else {
            history.push("/school/complete/profile");
          }
        } else if (user?.user_type === constants.parentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/parent");
          } else {
            history.push("/parent/complete/profile");
          }
        }
      }
    }
    //-------
    if (payment?.status === "SUCCESS") {
      if (
        user.user_type === constants.studentusertype ||
        chosenPackage.usertype === constants.studentusertype
      ) {
        history.push(`/complete`);
      }
      if (
        user.user_type === constants.teacherusertype ||
        chosenPackage.usertype === constants.teacherusertype
      ) {
        history.push(`/teacher/user/profile`);
      }
    } else {
    }
    //--Sync with payment api--
    const timer = setInterval(() => {
      axios
        .post(
          `/opanda/payment-call-backs/check_linked_payments`,
          { userId: userAuth.user.id },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          dispatch(authSuccess(response.data.results));
          if (response.data.results?.user?.paymentvalid === true) {
            clearInterval(timer);
            if (
              response.data.results?.user?.user_type ===
              constants.studentusertype
            ) {
              if (response.data.results?.user?.isprofilecompleted === 1) {
                history.push("/student");
              } else {
                history.push("/complete");
              }
            } else if (
              response.data.results?.user?.user_type ===
              constants.teacherusertype
            ) {
              if (response.data.results?.user?.isprofilecompleted === 1) {
                history.push("/");
                //history.push("/teacher");
              } else {
                history.push("/teacher/user/profile");
              }
            } else if (
              response.data.results?.user?.user_type ===
              constants.schoolusertype
            ) {
              if (response.data.results?.user?.isprofilecompleted === 1) {
                history.push("/school");
              } else {
                history.push("/school/complete/profile");
              }
            } else if (
              response.data.results?.user?.user_type ===
              constants.parentusertype
            ) {
              if (response.data.results?.user?.isprofilecompleted === 1) {
                history.push("/parent");
              } else {
                history.push("/parent/complete/profile");
              }
            }
          }
        })
        .catch((err) => {});
    }, 8000);
  }, [payment]);

  return !paymentLoading ? (
    <div className="p-2">
      <Input
        elementType="phone"
        value={phone}
        changed={setPhone}
        validation={{ required: true, isPhone: true }}
        shouldValidate
        error={"Valid phone number is required"}
      />
      <Message
        type={"error"}
        viewable={true}
        message={error && error.toString().length <= 200 ? error : ""}
      />
      {phone.length >= 10 ? (
        <Button
          name="Buy"
          outline="true"
          color="blue"
          additional="my-2"
          clicked={momopayment}
        />
      ) : (
        ""
      )}
    </div>
  ) : (
    <Spinner size="32" color="blue" />
  );
};

export const MobileMoneyExtra = (props) => {
  const [phone, setPhone] = useState("");
  const chosenPackage = props.chosenPackage;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const payment = useSelector((state) => state.payment.payment);
  const paymentLoading = useSelector((state) => state.payment.paymentLoading);
  const userAuth = useSelector((state) => state.auth);
  let error = useSelector((state) => state.payment.paymentFail);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleCloseAlert = () => {
    history.push(
      `/student/user/payment`  )
    setShowAlert(false);
  };

  const momopayment = () => {
    if (phone.length < 10) {
      error = "Phone number length incorrect";
    } else {
      const paymentObject = {
        phone,
        statusDescription: "PASSED",
        spTransactionId: "TRNS ID",
        walletTransactionId: "WALLET",
        chargedCommission: 100,
        currency: chosenPackage?.pricingcurrency,
        paidAmount: chosenPackage?.totalMonthlyPlan,
        userID: user.id,
        usertype: chosenPackage.usertype,
        pricingfrequencyindays: chosenPackage.pricingfrequencyindays,
        isExtrapackage: true,
        activepackageid: chosenPackage.activepackageid,
        extrapackageid: chosenPackage.id
      };
      dispatch(actions.makePayment(paymentObject, token));
    }
  };

  useEffect(() => {
    //--Sync with payment api--
    const timer = setInterval(() => {
      axios
        .post(
          `/opanda/payment-call-backs/check_linked_extra_payments`,
          { userId: userAuth.user.id, activepackageid: chosenPackage.activepackageid,
            extrapackageid: chosenPackage.id  },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if(response.data.results?.length > 0){
            clearInterval(timer);
            setAlertMessage("Payment successful!, You will need to log out and login again for the package to take effect");
            // console.log(response.data.message, response.data.results);
          }else{
            setAlertMessage("Payment pending");
            // console.log(response.data.message);
            // alert(response.data.message);
          }
          // if (response.data.results?.payment?.status === true) {
            
          //   // if (
          //   //   response.data.results?.user?.user_type ===
          //   //   constants.studentusertype
          //   // ) {
              
          //   // }
          // }
        })
        .catch((err) => {
          setAlertMessage(`Error occured! ${err}`);
        });
    }, 8000);
  }, [chosenPackage.activepackageid, chosenPackage.id, payment, token, userAuth.user.id]);

  return !paymentLoading ? (
    <>
    {/* <button className="btn btn-blue" onClick={handleShowAlert}>Show Alert</button>
    <button className="btn btn-blue" onClick={handleShowConfirm}>Show Confirm</button> */}

{showAlert && (
      <AlertModal message={alertMessage} onClose={handleCloseAlert} />
    )}

    
    <div className="p-2">
      <Input
        elementType="phone"
        value={phone}
        changed={setPhone}
        validation={{ required: true, isPhone: true }}
        shouldValidate
        error={"Valid phone number is required"}
      />
      <Message
        type={"error"}
        viewable={true}
        message={error && error.toString().length <= 200 ? error : ""}
      />
      {phone.length >= 10 ? (
        <Button
          name="Buy"
          outline="true"
          color="blue"
          additional="my-2"
          clicked={momopayment}
        />
      ) : (
        ""
      )}
    </div>
    </>) : (
    <Spinner size="32" color="blue" />
  );
};

export default MobileMoney;
