import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { OtherLinkButton } from "../../../elements/button";
import { ClassDataCard } from "../../../elements/card";
import {
  CardBody,
  CardTitle,
  SectionTitle,
  SmallText,
} from "../../../elements/text";

const ClassSummary = (props) => {
  return (
    <div
      className={`${
        props.show ? `block` : `hidden`
      } pt-2 px-2 space-x-2 w-full flex flex-row items-stretch space-around`}
    >
      {props.data.map((value, index) => (
        <ClassDataCard number={value.number} label={value.key} />
      ))}
    </div>
  );
};

export default ClassSummary;

export const FeaturesSummary = function (props) {
  return (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <div className="flex justify-between">
        <CardTitle name={props.title} color={props.titleColor} />
        <CardBody name={props.time} />
      </div>
      <div className="w-full my-2">
        <div>
          {props.activities.map((activity, index) => (
            <div key={index} className="flex items-center space-x-2">
              <div
                className={`h-5 w-5 rounded-full bg-${activity.color}`}
              ></div>
              <CardBody name={`${activity.value} ${activity.name}`} />
            </div>
          ))}
        </div>
        <PieChart width={100} height={100}>
          <Pie
            data={props.activities.length > 0 ? props.activities : []}
            cx={45}
            cy={45}
            innerRadius={30}
            outerRadius={50}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
          >
            {props.activities.length > 0 ? (
              props.activities.map((activity, index) => (
                <Cell key={`cell-${index}`} fill={activity.hexColor} />
              ))
            ) : (
              <div></div>
            )}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export const ActivitySummary = function (props) {
  return (
    <div className="bg-white px-3 py-3 filter drop-shadow-md rounded-2xl w-full h-full">
      <div className="flex px-3 justify-between">
        <CardTitle name={props.title} color={props.titleColor} />
        <CardBody name={props.time} />
      </div>
      {props.data.length === 0 ? (
        <div className="bg-white px-3 py-3 rounded-2xl w-full flex justify-center align-center">
          <div className="w-full h-full my-2 overflow-auto flex justify-center align-center">
            <SectionTitle name={`No activity found`} color="blue" />
          </div>
        </div>
      ) : (
        <div className="w-full my-2 overflow-auto">
          <AreaChart
            width={730}
            height={250}
            data={props.data}
            margin={{ top: 5, right: 20, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="amt" stroke="#82ca9d" />
            {/* <ReferenceLine x="Page C" stroke="green" label="Min PAGE" /> */}
            <ReferenceLine
              y={4000}
              label="Max"
              stroke="red"
              strokeDasharray="3 3"
            />
            <Area
              type="monotone"
              dataKey="amt"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </div>
      )}
    </div>
  );
};

export const GoalSummary = function (props) {
  return (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <div className="flex justify-between">
        <CardTitle name={props.title} color={props.titleColor} />
        <CardBody name={props.time} />
      </div>
      <div className="flex flex-col items-start w-full divide-y space-y-2">
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={props.goaldata}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="uv" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export const StudentSession = function (props) {
  const data = props.data;
  return (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <div className="flex justify-between">
        <CardTitle name={props.title} color={props.titleColor} />
        <CardBody name={props.time} />
      </div>
      <div className="flex flex-col items-start w-full divide-y space-y-2">
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            width={600}
            height={300}
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line type="monotone" dataKey="uv" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
