import { updateObject } from "../../shared/utility";
import {
  SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT,
  SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS,
  SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED,
  SCHOOL_USERS_LAST_SEEN_DATE_INIT,
  SCHOOL_USERS_LAST_SEEN_DATE_SUCCESS,
  SCHOOL_USERS_LAST_SEEN_DATE_FAILED

} from "../actions/school.dashboard.user.details";
//--
const initialReducer = {
  school_user_session_loading: true,
  school_user_session_duration: [],

  school_user_last_seen_loading: true,
  school_user_last_seen_date: "",
  message: "",
};

function school_users_lastweek_sessionduration_init(state, action) {
  return updateObject(state, {
    school_user_session_loading: true,
  });
}
function school_users_lastweek_sessionduration_success(state, action) {
  console.log(action.payload)
  return updateObject(state, {
    school_user_session_loading: false,
    school_user_session_duration: action.payload,
    message: "success",
  });
}
function school_users_lastweek_sessionduration_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_user_session_loading: false,
  });
}

function school_users_last_seen_date_init(state, action) {
  return updateObject(state, {
    school_user_last_seen_loading: true,
  });
}
function school_users_last_seen_date_success(state, action) {
  return updateObject(state, {
    school_user_last_seen_loading: false,
    school_user_last_seen_date: action.payload,
    message: "success",
  });
}
function school_users_last_seen_date_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_user_last_seen_loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT:
      return school_users_lastweek_sessionduration_init(state, action);
    case SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS:
      return school_users_lastweek_sessionduration_success(state, action);
    case SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED:
      return school_users_lastweek_sessionduration_failed(state, action);
      case SCHOOL_USERS_LAST_SEEN_DATE_INIT:
        return school_users_last_seen_date_init(state, action);
      case SCHOOL_USERS_LAST_SEEN_DATE_SUCCESS:
        return school_users_last_seen_date_success(state, action);
      case SCHOOL_USERS_LAST_SEEN_DATE_FAILED:
        return school_users_last_seen_date_failed(state, action);
  
    default:
      return state;
  }
};
export default reducer;
