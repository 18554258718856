import { CardBody, CardTitle, FeatureTitle } from "../../../../elements/text";

import { Button, ButtonLink } from "../../../../elements/button";
import Table from "../../../../elements/table/table";
import Input from "../../../../elements/input";
import { useSelector } from "react-redux";
import { useState } from "react";

const PackageInformation = () => {
  const userEmail = useSelector((state) => state.auth.user);
  const [email, setEmail] = useState(userEmail);
  return (
    <div className="pt-2 px-2 bg-white md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
      <FeatureTitle name="Package Information" color="black" />

      <div className="relative overflow-x-auto sm:rounded-lg">
        <CardTitle name="Students" color="black" />
        <div class="grid md:grid-cols-4 gap-4 mb-2">
          <div>
            <Input
              label="Allowed students"
              elementType="input"
              elementConfig={{
                type: "number",
                placeholder: "Number",
              }}
              value={null}
              
            />
          </div>
          <div>
            <Input
              label="Currently registered"
              elementType="password"
              elementConfig={{
                type: "number",
                placeholder: "Number",
              }}
              value={null}
             
            />
          </div>

          
        </div>

        <CardTitle name="Teachers" color="black" />
        <div class="grid md:grid-cols-4 gap-4 mb-2">
          <div>
            <Input
              label="Allowed teachers"
              elementType="input"
              elementConfig={{
                type: "number",
                placeholder: "Number",
              }}
              value={null}
           
            />
          </div>
          <div>
            <Input
              label="Currently registered"
              elementType="input"
              elementConfig={{
                type: "number",
                placeholder: "Number",
              }}
              value={null}
          
            />
          </div>

          
        </div>

        <CardTitle name="Admins" color="black" />
        <div class="grid md:grid-cols-4 gap-4 mb-2">
          <div>
            <Input
              label="Allowed admins"
              elementType="input"
              elementConfig={{
                type: "number",
                placeholder: "Number",
              }}
              value={null}
          
            />
          </div>
          <div>
            <Input
              label="Currently registered"
              elementType="input"
              elementConfig={{
                type: "number",
                placeholder: "Number",
              }}
              value={null}
            
            />
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default PackageInformation;
