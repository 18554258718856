import { Switch, Route } from "react-router-dom";
import UserRoute from "./UserRoute";

// PAYMENT ROUTES IMPORTS

import Completeprofile from "../landingpages/completeprofile";
import { EnrollPaymentOptions, PaymentSuccess } from "../landingpages/enroll";
import { EnrollPayment } from "../landingpages/enroll";

// STUDENT ROUTES IMPORTS

import StudentHome from "../studentpages/home";
import CbcCurriculum from "../studentpages/curriculum/cbc";
import CambridgeCurriculum from "../studentpages/curriculum/cambridge";
import SimulationContainer from "../studentpages/features/simulations/simulation";
import LibraryContainer from "../studentpages/features/library/library";
import BookContainer from "../studentpages/features/library/book";
import PastpapersContainer from "../studentpages/features/pastpaper/pastpapers";
import PastpaperContainer from "../studentpages/features/pastpaper/pastpaper";
import SummarynotesContainer from "../studentpages/features/summurynotes/summarynotes";
import SummaryNoteContainer from "../studentpages/features/summurynotes/summarynote";
import SimulationsContainer from "../studentpages/features/simulations/simulations";
import TestsContainer from "../studentpages/features/tests/tests";
import TestContainer from "../studentpages/features/tests/test";
import TestProgressContainer from "../studentpages/features/tests/progress";
import SingleTestProgressContainer from "../studentpages/features/tests/SingleTestProgress";

import TestProgressContainerTeacher from "../teacherpages/features/tests/progress";
import SingleTestProgressContainerTeacher from "../teacherpages/features/tests/SingleTestProgress";
import MyclassesContainer from "../studentpages/features/myclasses/myclasses";
import MyclassContainer from "../studentpages/features/myclasses/myclass";
import ChallengesContainer from "../studentpages/features/challenge/challenges";
import ChallengeContainer from "../studentpages/features/challenge/challenge";
import CommunityContainer from "../studentpages/community/community";
import StudentProfileContainer from "../studentpages/user/profile";
import StudentSettingsContainer from "../studentpages/user/settings";
import StudentFeedbackContainer from "../studentpages/user/feedback/index";
import StudentHelpContainer from "../studentpages/user/help/index";
import StudentLinkAccountContainer from "../studentpages/user/linkaccount/index";
import StudentPaymentContainer from "../studentpages/user/payment/index";
import StudentCreatePaymentContainer from "../studentpages/user/payment/create";
import StudentPaymentOptionsContainer from "../studentpages/user/payment/options";
import StudentCreatePaymentExtrasContainer from "../studentpages/user/payment/extras/create";
import StudentPaymentExtrasOptionsContainer from "../studentpages/user/payment/extras/options";
//--

import TestSProgressAnswerSheet from "../studentpages/features/challenge/challengeComponentReuse";

// TEACHER ROUTES IMPORTS
import TestSProgressAnswerSheetTeacher from "../teacherpages/features/challenge/challengeComponentReuse";
import TeacherHome from "../teacherpages/home";
import ClassesContainer from "../teacherpages/features/classes/classes";
import CreateClassesContainer from "../components/pages/teacher/classes/create/index";
import CompleteClassesContainer from "../components/pages/teacher/classes/complete/addstudents";
import AddAdminClassesContainer from "../components/pages/teacher/classes/complete/addadmins";
import AddGoalsClassesContainer from "../components/pages/teacher/classes/complete/goals";
import EditClassesContainer from "../components/pages/teacher/classes/edit/edit";
import SubjectsContainer from "../teacherpages/features/classes/classes";
import CreateSubjectsContainer from "../components/pages/teacher/classes/subjects/create/index";
import CompleteSubjectsContainer from "../components/pages/teacher/classes/subjects/complete/addstudents";
import AddAdminSubjectsContainer from "../components/pages/teacher/classes/subjects/complete/addadmins";
import AddGoalsSubjectsContainer from "../components/pages/teacher/classes/subjects/complete/goals";
import EditSubjectsContainer from "../components/pages/teacher/classes/subjects/edit/edit";
import CBCContainer from "../teacherpages/programs/cbc";
import CBCContainerSchool from "../schoolpages/programs/cbc";
import DAPContainer from "../teacherpages/programs/dap";
import TeacherSummarynotesContainer from "../teacherpages/features/summarynotes/courses";
import TeacherSummarynoteContainer from "../teacherpages/features/summarynotes/course";
import TeacherSimulationsContainer from "../teacherpages/features/simulations/simulations";
import TeacherSimulationContainer from "../teacherpages/features/simulations/simulation";
import TeacherTestsContainer from "../teacherpages/features/tests/tests";
import TeacherTestContainer from "../teacherpages/features/tests/test";
import TeacherPastpapersContainer from "../teacherpages/features/pastpaper/pastpapers";
import TeacherPastpaperContainer from "../teacherpages/features/pastpaper/pastpaper";
import TeacherLibraryContainer from "../teacherpages/features/library/library";
import TeacherBookContainer from "../teacherpages/features/library/book";
import TeacherChallengesContainer from "../teacherpages/features/challenges/challenges";
import TeacherChallengeContainer from "../teacherpages/features/challenges/challenge";
import TeacherProfileContainer from "../teacherpages/user/profile/index";
import TeacherSettingsContainer from "../teacherpages/user/settings/index";
import NewCourse from "../teacherpages/features/summarynotes/newcourse";
import NewBookContainer from "../teacherpages/features/library/newbook";
import NewPaperContainer from "../teacherpages/features/pastpaper/newpastpaper";
import TeacherCommunityContainer from "../teacherpages/community/community";
import NewTestContainer from "../teacherpages/features/tests/newtest";
import TestStatsContainer from "../teacherpages/features/tests/teststats";
import MarkTestContainer from "../teacherpages/features/tests/marktest";
import TeacherScheduleContainer from "../teacherpages/features/schedule/schedule";
import TeacherViewScheduleContainer from "../teacherpages/features/schedule/event";
import NewTeacherScheduleContainer from "../components/pages/teacher/schedule/dashboard";
import TeacherCommunicationContainer from "../teacherpages/communication/communication";
import ComposeMessageContainer from "../teacherpages/communication/composemessage";
import PageNotFound from "../components/common/PageNotFound";
import PaymentRoute from "./PaymentRoute";
import TeacherFeedbackContainer from "../teacherpages/user/feedback/index";
import TeacherHelpContainer from "../teacherpages/user/help/index";
// import TeacherLinkAccountContainer from "../teacherpages/user/linkaccount/index";

import TeacherPaymentContainer from "../teacherpages/user/payment/index";

// SCHOOL ROUTES IMPORTS

import SchoolHome from "../schoolpages/home";
import SchoolProfileContainer from "../schoolpages/user/profile";
import SchoolCompleteContainer from "../schoolpages/complete/profile";
import SchoolUsersContainer from "../schoolpages/features/users";
import SchoolUserDetailsContainer from "../schoolpages/features/users/details";
import SchoolUserInfoContainer from "../schoolpages/features/users/info";
import SchoolUserImportExistingContainer from "../schoolpages/features/users/existingaacount";
import SchoolClassesContainer from "../schoolpages/features/classes";
import CreateSchoolClassesContainer from "../components/pages/school/classes/create/index";
import CompleteSchoolClassesContainer from "../components/pages/school/classes/complete/addstudents";
import CompleteSchoolAdminsContainer from "../components/pages/school/classes/complete/addadmins";
import CompleteSchoolGoalsContainer from "../components/pages/school/classes/complete/goals";
import EditSchoolClassesContainer from "../components/pages/school/classes/edit/edit";
import EditSchoolStudentsContainer from "../components/pages/school/classes/edit/editstudents";
import EditSchoolAdminsContainer from "../components/pages/school/classes/edit/editadmins";
import EditSchoolGoalsContainer from "../components/pages/school/classes/edit/editgoals";
import CreateSchoolSubjectsContainer from "../components/pages/school/classes/subjects/create/index";
import CompleteSubjectStudentsContainer from "../components/pages/school/classes/subjects/complete/addstudents";
import CompleteSubjectAdminsContainer from "../components/pages/school/classes/subjects/complete/addadmins";
import CompleteSubjectGoalsContainer from "../components/pages/school/classes/subjects/complete/goals";
import EditSubjectStudentsContainer from "../components/pages/school/classes/subjects/edit/editstudents";
import EditSubjectAdminsContainer from "../components/pages/school/classes/subjects/edit/editadmins";
import EditSubjectGoalsContainer from "../components/pages/school/classes/subjects/edit/editgoals";
import SchoolScheduleContainer from "../schoolpages/features/schedule";

import SchoolViewScheduleContainer from "../schoolpages/features/schedule/event";
import SchoolCommunicationContainer from "../schoolpages/features/communication";
import SchoolComposeMessageContainer from "../schoolpages/features/communication/composemessage";

import SchoolCommunityContainer from "../schoolpages/community/community";
import SchoolFeedbackContainer from "../schoolpages/user/feedback/index";
import SchoolHelpContainer from "../schoolpages/user/help/index";
import SchoolLinkAccountContainer from "../schoolpages/user/linkaccount/index";
import SchoolPaymentContainer from "../schoolpages/user/payment/index";
import SchoolSettingsContainer from "../schoolpages/user/settings/index";

// PARENT ROUTES IMPORTS
import ParentHome from "../parentpages/home";
import ParentProfileContainer from "../parentpages/user/profile";
import ParentCompleteContainer from "../parentpages/complete/profile";
import { ParentLinkAccountContainer, ParentLinkNewAccountContainer } from '../parentpages/user/linkaccount/'
import {
  ExtraCurricularContainer,
  MainBooksMarketContainer,
  PurchasedBooksContainer,
  ParentBookPaymentContainer,
} from '../parentpages/marketplace/'
import ParentCommunicationContainer from "../parentpages/communication/communication";
import ParentNewRecipientContainer from "../parentpages/communication/NewReciptient";
import ParentCommunityContainer from "../parentpages/community/community";
import {
  ParentRenewPaymentContainer,
  ParentAddPackageContainer,
  ParentPackagePaymentContainer,
} from "../parentpages/renewpayment";
import ParentSettingsContainer from '../parentpages/user/settings'

// END OF PARENT ROUTES

const ProtectedRoutes = () => {
  return (
    <Switch>
      {/* PAYMENT ROUTES */}
      <PaymentRoute
        exact
        path="/payment/done/:transaction?"
        component={PaymentSuccess}
      />
      <PaymentRoute exact path="/payment/payment" component={EnrollPayment} />
      <PaymentRoute
        exact
        path="/payment/options"
        component={EnrollPaymentOptions}
      />
      <PaymentRoute
        exact
        path="/payment/done/:transaction?"
        component={PaymentSuccess}
      />
      <PaymentRoute exact path="/complete" component={Completeprofile} />

      {/* STUDENT ROUTES */}

      <UserRoute
        allowedUser="student"
        exact
        path="/student/"
        component={StudentHome}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/curriculum/cbc"
        component={CbcCurriculum}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/curriculum/cambridge"
        component={CambridgeCurriculum}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/summarynotes"
        component={SummarynotesContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/summarynotes/:noteId"
        component={SummaryNoteContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/simulations"
        component={SimulationsContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/simulations/:simulationId"
        component={SimulationContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/library"
        component={LibraryContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/library/:bookId"
        component={BookContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/pastpapers"
        component={PastpapersContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/pastpapers/:paperId"
        component={PastpaperContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/tests"
        component={TestsContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/tests/:testId"
        component={TestContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/testprogress/"
        component={TestProgressContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/testprogress/:testID"
        component={SingleTestProgressContainer}
      />

      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/testprogress/"
        component={TestProgressContainerTeacher}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/testprogress/:testID"
        component={SingleTestProgressContainerTeacher}
      />

      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/myclasses"
        component={MyclassesContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/myclasses/:testId"
        component={MyclassContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/challenges"
        component={ChallengesContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/challenges/:challengeId"
        component={ChallengeContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/community"
        component={CommunityContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/user/profile"
        component={StudentProfileContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/user/settings"
        component={StudentSettingsContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/user/feedback"
        component={StudentFeedbackContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/user/helpcenter"
        component={StudentHelpContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/user/linkaccount"
        component={StudentLinkAccountContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/features/testsprogresanswers/:challengeId"
        component={TestSProgressAnswerSheet}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/testsprogresanswers/:challengeId"
        component={TestSProgressAnswerSheetTeacher}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/user/payment"
        component={StudentPaymentContainer}
      />
      <UserRoute
        allowedUser="student"
        exact
        path="/student/user/payment/create"
        component={StudentCreatePaymentContainer}
      />
      <UserRoute
        allowedUser="student"
        path="/student/user/payment/options"
        component={StudentPaymentOptionsContainer}
      />

      <UserRoute
        allowedUser="student"
        exact
        path="/student/user/payment/extras/create"
        component={StudentCreatePaymentExtrasContainer}
      />
      <UserRoute
        allowedUser="student"
        path="/student/user/payment/extras/options"
        component={StudentPaymentExtrasOptionsContainer}
      />
      {/* <StudentRoute
        exact
        path="/student/user/feedback"
        component={TeacherFeedbackContainer}
      />
      <StudentRoute
        exact
        path="/student/user/help"
        component={TeacherHelpContainer}
      /> */}

      {/* TEACHER ROUTES */}

      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher"
        component={TeacherHome}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/classes"
        component={ClassesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/classes/create"
        component={CreateClassesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/classes/complete/:classId"
        component={CompleteClassesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/classes/edit/:classId"
        component={EditClassesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/classes/complete/:classId/students"
        component={CompleteClassesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/classes/complete/:classId/admins"
        component={AddAdminClassesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/classes/complete/:classId/goals"
        component={AddGoalsClassesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/subjects"
        component={SubjectsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/subjects/create"
        component={CreateSubjectsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/subjects/complete/:subjectId"
        component={CompleteSubjectsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/subjects/edit/:subjectId"
        component={EditSubjectsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/subjects/complete/:subjectId/students"
        component={CompleteSubjectsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/subjects/complete/:subjectId/admins"
        component={AddAdminSubjectsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/subjects/complete/:subjectId/goals"
        component={AddGoalsSubjectsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/curriculum/cbc"
        component={CBCContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/curriculum/cbc"
        component={CBCContainerSchool}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/curriculum/dap"
        component={DAPContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/summarynotes"
        component={TeacherSummarynotesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/summarynotes/new"
        component={NewCourse}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/summarynotes/update/:bookId"
        component={NewCourse}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/summarynotes/:bookId"
        component={TeacherSummarynoteContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/simulations"
        component={TeacherSimulationsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/simulations/:simulationId"
        component={TeacherSimulationContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/tests"
        component={TeacherTestsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/tests/new"
        component={NewTestContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/tests/statistics/:testId"
        component={TestStatsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/tests/mark/:testId"
        component={MarkTestContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/tests/:testId"
        component={TeacherTestContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/pastpapers"
        component={TeacherPastpapersContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/pastpapers/new/:paperid?"
        component={NewPaperContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/pastpapers/:paperId"
        component={TeacherPastpaperContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/library"
        component={TeacherLibraryContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/library/new"
        component={NewBookContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/library/:bookId"
        component={TeacherBookContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/summarynotes"
        component={TeacherSummarynotesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/summarynotes/new"
        component={NewCourse}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/summarynotes/update/:bookId"
        component={NewCourse}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/summarynotes/:bookId"
        component={TeacherSummarynoteContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/simulations"
        component={TeacherSimulationsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/simulations/:simulationId"
        component={TeacherSimulationContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/tests"
        component={TeacherTestsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/tests/:testId"
        component={TeacherTestContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/pastpapers"
        component={TeacherPastpapersContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/pastpapers/new/:paperid?"
        component={NewPaperContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/pastpapers/:paperId"
        component={TeacherPastpaperContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/library"
        component={TeacherLibraryContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/library/new"
        component={NewBookContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/library/:bookId"
        component={TeacherBookContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/schedule"
        component={TeacherScheduleContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/schedule/new"
        component={NewTeacherScheduleContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/schedule/:scheduleId"
        component={TeacherViewScheduleContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/challenges"
        component={TeacherChallengesContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/features/challenges/:challengeId"
        component={TeacherChallengeContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/community"
        component={TeacherCommunityContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/communication"
        component={TeacherCommunicationContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/communication/compose"
        component={ComposeMessageContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/user/profile/:userId?"
        component={TeacherProfileContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/user/settings"
        component={TeacherSettingsContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/user/feedback"
        component={TeacherFeedbackContainer}
      />
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/user/helpcenter"
        component={TeacherHelpContainer}
      />
      {/* <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/user/linkaccount"
        component={TeacherLinkAccountContainer}
      /> */}
      <UserRoute
        allowedUser="teacher"
        exact
        path="/teacher/user/payment"
        component={TeacherPaymentContainer}
      />

      {/* <UserRoute allowedUser="teacher" exact path="/teacher/user/feedback" component={TeacherFeedbackContainer}/>
      <UserRoute allowedUser="teacher" exact path="/teacher/user/help" component={TeacherHelpContainer}/> */}

      {/* SCHOOL ROUTES */}

      <UserRoute
        allowedUser="school"
        exact
        path="/school"
        component={SchoolHome}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/user/profile"
        component={SchoolProfileContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/complete/profile"
        component={SchoolCompleteContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/users"
        component={SchoolUsersContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/users/details"
        component={SchoolUserDetailsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/users/importexisting"
        component={SchoolUserImportExistingContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/users/info"
        component={SchoolUserInfoContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes"
        component={SchoolClassesContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/schedule"
        component={SchoolScheduleContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/schedule/:scheduleId"
        component={SchoolViewScheduleContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/communication"
        component={SchoolCommunicationContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/communication/compose"
        component={SchoolComposeMessageContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes/create"
        component={CreateSchoolClassesContainer}
      />

      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes/complete/:classId"
        component={CompleteSchoolClassesContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes/complete/:classId/admins"
        component={CompleteSchoolAdminsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes/complete/:classId/goals"
        component={CompleteSchoolGoalsContainer}
      />

      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes/edit/:classId"
        component={EditSchoolClassesContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes/edit/:classId/students"
        component={EditSchoolStudentsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes/edit/:classId/admins"
        component={EditSchoolAdminsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/classes/edit/:classId/goals"
        component={EditSchoolGoalsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/subjects/create"
        component={CreateSchoolSubjectsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/subjects/complete/:classId/admins"
        component={CompleteSubjectAdminsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/subjects/complete/:classId/students"
        component={CompleteSubjectStudentsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/subjects/complete/:classId/goals"
        component={CompleteSubjectGoalsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/subjects/edit/:classId/admins"
        component={EditSubjectAdminsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/subjects/edit/:classId/students"
        component={EditSubjectStudentsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/features/subjects/edit/:classId/goals"
        component={EditSubjectGoalsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/community"
        component={SchoolCommunityContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/user/settings"
        component={SchoolSettingsContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/user/feedback"
        component={SchoolFeedbackContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/user/helpcenter"
        component={SchoolHelpContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/user/linkaccount"
        component={SchoolLinkAccountContainer}
      />
      <UserRoute
        allowedUser="school"
        exact
        path="/school/user/payment"
        component={SchoolPaymentContainer}
      />

      {/* PARENT ROUTES */}

      <UserRoute
        allowedUser="parent"
        exact
        path="/parent"
        component={ParentHome}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/user/profile"
        component={ParentProfileContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/complete/profile"
        component={ParentCompleteContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/user/linkedaccounts"
        component={ParentLinkAccountContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/user/linkaccount"
        component={ParentLinkNewAccountContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/marketplace/extracurricular/"
        component={ExtraCurricularContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/marketplace/extracurricular/dap"
        component={ExtraCurricularContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/marketplace/books"
        component={MainBooksMarketContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/marketplace/books/purchasedbooks"
        component={PurchasedBooksContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/marketplace/books/payment"
        component={ParentBookPaymentContainer}
      />
      {/* <UserRoute
        allowedUser="parent"
        exact
        path="/parent/communication"
        component={AllMessagesContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/communication/groups/Allmessages"
        component={AllMessagesContainer}
      /> */}
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/communication"
        component={ParentCommunicationContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/communication/recipients"
        component={ParentNewRecipientContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/community"
        component={ParentCommunityContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/renewpayment"
        component={ParentRenewPaymentContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/renewpayment/addpackage"
        component={ParentAddPackageContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/renewpayment/addpackage/payment"
        component={ParentPackagePaymentContainer}
      />
      <UserRoute
        allowedUser="parent"
        exact
        path="/parent/user/settings"
        component={ParentSettingsContainer}
      />

      {/* PAGE NOT FOUND */}
      <Route path="*" exact component={PageNotFound} />
    </Switch>
  );
};

export default ProtectedRoutes;
