import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";
import date from "date-and-time";

export const accounttypes = [
  { value: "student", displayValue: "Student Account" },
  { value: "parent", displayValue: "Parent Account" },
  { value: "teacher", displayValue: "Teacher Account" },
  { value: "school", displayValue: "School Account" },
];

export const emaillogintype = "email";

export const cbclevels = [
  { value: 1, displayValue: "Level 1" },
  { value: 2, displayValue: "Level 2" },
  { value: 3, displayValue: "Level 3" },
  { value: 4, displayValue: "Level 4" },
  { value: 5, displayValue: "Level 5" },
  { value: 6, displayValue: "Level 6" },
];

export const cbcsection = [
  { value: "olevel", displayValue: "Ordinary level" },
  { value: "alevel", displayValue: "Advanced level" },
];

export const cbcsectionlevels =
{
  olevel: [
    { value: 1, displayValue: "Level 1" },
    { value: 2, displayValue: "Level 2" },
    { value: 3, displayValue: "Level 3" },
  ],

  alevel: [
    { value: 4, displayValue: "Level 4" },
    { value: 5, displayValue: "Level 5" },
    { value: 6, displayValue: "Level 6" },
  ],
};

export const studentusertype = "student";
export const teacherusertype = "teacher";
export const schoolusertype = "school";
export const parentusertype = "parent";

export const validationNotification = () => {
  store.addNotification({
    title: `FIELDS VALIDATION`,
    message: `Some fields are empty, fill them to proceed`,
    type: "warning",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      onScreen: true,
    },
    width: 450,
  });
};

export const yearsofstudy = [
  { value: 1, displayValue: "Year 1" },
  { value: 2, displayValue: "Year 2" },
  { value: 3, displayValue: "Year 3" },
  { value: 4, displayValue: "Year 4" },
  { value: 5, displayValue: "Year 5" },
  { value: 6, displayValue: "Year 6" },
];

export const multiple_choice = "multiple_choice";
export const complete_sentence = "complete_sentence";
export const drag_and_drop = "drag_and_drop";
export const open_ended = "open_ended";
export const questiontypes = [
  {
    value: multiple_choice,
    displayValue: "Multiple Choice",
  },
  { value: complete_sentence, displayValue: "Complete Sentence" },
  { value: open_ended, displayValue: "Open Ended" },
  { value: drag_and_drop, displayValue: "Drag and Drop" },
];

export const lastYearsList = () => {
  const now = new Date();
  let years = [];
  for (var i = 0; i < 5; i++) {
    years.push({
      value: date.format(now, "YYYY") - i,
      displayValue: date.format(now, "YYYY") - i,
    });
  }
  return years;
};

export const lastYearsListPastPapers = () => {
  const now = new Date();
  const currentYear = now.getFullYear(); // Get the current year
  let years = [];

  for (let i = 0; i < 50; i++) { // Adjusted loop to 15 for reasons explained previously
    const year = currentYear - i;
    const nextYear = year + 1;

    // Push the single year
    years.push({
      value: year.toString(),
      displayValue: year.toString(),
    });

    // Check if not the first iteration to avoid future year range
    if (i > 0) {
      // Push the year range
      years.push({
        value: `${year}${nextYear}`,
        displayValue: `${year}-${nextYear}`,
      });
    }
  }
  return years;
};


function separateTextAndFiles(givenId) {
  let id = "quill-editor";
  if (givenId !== "") {
    id = id + "-" + givenId;
  }
  let editorValue;
  var image_name;
  let files = [];
  const filesFolder =
    "${process.env.REACT_APP_BACKEND_URL}/opanda/simulation_thumbnails/";
  var editorData = document
    .getElementById(givenId)
    .querySelector(".ql-editor").innerHTML;
  var editorData_ = editorData;
  var data_form = new FormData();
  var images = document.getElementById(givenId).getElementsByTagName("img");
  for (var i = 0; i < images.length; i++) {
    image_name = "img_" + new Date().getTime();
    image_name = image_name + ".jpg";
    //   //---
    let b64data = images[i].src;
    if (b64data.startsWith("data")) {
    } else {
      continue;
    }
    if (editorData_) {
      editorData_ = editorData_.replaceAll(b64data, filesFolder + image_name);
    }
    let fileToUpload = dataURLtoFile(b64data, image_name);
    const fd = new FormData();
    fd.append("file", fileToUpload);
    files.push(fd);
  }
  editorValue = "`" + editorData_ + "`";
  return { text: editorValue, files };
}

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime,
  });
}

export default {
  accounttypes,
  emaillogintype,
  cbclevels,
  cbcsection,
  cbcsectionlevels,
  studentusertype,
  parentusertype,
  schoolusertype,
  teacherusertype,
  validationNotification,
  yearsofstudy,
  questiontypes,
  multiple_choice,
  complete_sentence,
  drag_and_drop,
  open_ended,
  lastYearsList,
  separateTextAndFiles,
  lastYearsListPastPapers
};
