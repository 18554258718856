import React from 'react';

const AlertModal = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Full-screen backdrop */}
      <div className="fixed inset-0 bg-black bg-opacity-50"></div>

      <div className="fixed inset-0 flex items-center justify-center">
        {/* Modal content */}
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Alert</h2>
            <button
              style={{fontSize: 32}}
              className="text-gray-600 hover:text-gray-800 transition duration-300 text-xl"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
          <div className="mb-4">
            <p>{message}</p>
          </div>
          <div className="flex justify-end">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
              onClick={onClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
