import Layout from '../../../components/common/Layout';
import Challenges from '../../../components/pages/teacher/challenge/Challenges';

const ChallengesContainer = () => {
  return (
    <Layout>
      <Challenges />
    </Layout>
  );
};

export default ChallengesContainer;
